import { helper as buildHelper } from '@ember/component/helper';

export function teamMemberHasOrdered([teamMember = null, gomMeta = null]) {
  if (teamMember && gomMeta) {
    const teamMemberId = teamMember.get('id');

    return gomMeta['membersWithOrders'].indexOf(parseInt(teamMemberId)) > -1;
  }
}

export default buildHelper(teamMemberHasOrdered);
