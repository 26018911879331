import EmberRouter from '@ember/routing/router';
import config from 'star-fox/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.routerRootURL;
}

Router.map(function () {
  this.route('login');
  this.route('logout');
  this.route('forgot-password');
  this.route('password-reset', { path: '/password-reset/:token' });
  this.route('user-invite', { path: '/user-invite/:token' });

  this.route('logged-in', { path: '/' }, function () {
    this.route('accounting', function () {
      this.route('invoicing', function () {
        this.route('client', { path: '/:client_id' }, function () {
          this.route('configuration', { path: '/config/:configuration_id' }, function () {
            this.route('run', { path: '/run/:run_id' }, function () {});
          });
        });
      });
    });

    this.route('areas', function () {
      this.route('edit', { path: '/:area_id' }, function () {
        this.route('index', { path: '/' }, function () {
          this.route('new');
        });
        this.route('details');
        this.route('top-cuisines');
        this.route('feed');

        this.route('rules', function () {
          this.route('show', { path: '/:rule_id' });
        });
      });
      this.route('new');
    });

    this.route('clients', function () {
      this.route('edit', { path: '/:client_id' }, function () {
        this.route('accounting');
        this.route('subscription');
        this.route('giftbit');
        this.route('meal-plan');
        this.route('meal-planning-template');
        this.route('meal-planning-instance', function () {
          this.route('show', { path: '/:meal_planning_instance_id' });
        });
        this.route('meal-planning-reservation');
        this.route('meal-planning-preference-profile');
        this.route('internal');
        this.route('feed');
        this.route('orders');
        this.route('import-orders');

        this.route('rules', function () {
          this.route('show', { path: '/:rule_id' });
        });

        this.route('users', function () {
          this.route('show', { path: '/:user_id' }, function () {
            this.route('payment-cards');
            this.route('meal-planning-preference-profile');
            this.route('payroll');
            this.route('internal');
            this.route('communications');
            this.route('feed');
          });
        });
      });
    });

    this.route('planning', function () {
      this.route('calendar');
      this.route('index', { path: '/' }, function () {
        this.route('edit-order', { path: '/:order_id' }, function () {
          this.route('index', { path: '/' });
          this.route('accounting', function () {
            this.route('legacy-ledger', { path: '/legacy-ledger/:ledger_id' });
            this.route('ledger', { path: '/ledger/:ledger_id' });
          });
          this.route('dev');
          this.route('manage-teams');
          this.route('cart');
          this.route('feed');
          this.route('meal-feedback');
        });
      });
    });

    this.route('logistics', function () {
      this.route('index', { path: '/' }, function () {
        this.route('edit-order', { path: '/:order_id' }, function () {
          this.route('index', { path: '/' });
          this.route('accounting', function () {
            this.route('legacy-ledger', { path: '/legacy-ledger/:ledger_id' });
            this.route('ledger', { path: '/ledger/:ledger_id' });
          });
          this.route('dev');
          this.route('manage-teams');
          this.route('cart');
          this.route('feed');
          this.route('meal-feedback');
        });
      });
      this.route('deliveries', function () {
        this.route('index', { path: '/' }, function () {
          this.route('edit-order', { path: '/:order_id' }, function () {
            this.route('index', { path: '/' });
            this.route('accounting', function () {
              this.route('legacy-ledger', { path: '/legacy-ledger/:ledger_id' });
              this.route('ledger', { path: '/ledger/:ledger_id' });
            });
            this.route('dev');
            this.route('manage-teams');
            this.route('cart');
            this.route('feed');
            this.route('meal-feedback');
          });
        });
      });
      this.route('archive');
      this.route('couriers', function () {
        this.route('show', { path: '/:courier_id' });
      });
      this.route('third-party-logistics');
    });

    this.route('bulk-orders', { path: '/areas/:area_id/orders/new' });
    this.route('new-order', { path: 'orders/new' });

    this.route('orders', function () {
      this.route('edit', { path: '/:order_id' }, function () {
        this.route('accounting', function () {
          this.route('legacy-ledger', { path: '/legacy-ledger/:ledger_id' });
          this.route('ledger', { path: '/ledger/:ledger_id' });
        });
        this.route('dev');
        this.route('manage-teams');
        this.route('cart');
        this.route('feed');
        this.route('notification-logs');
        this.route('meal-feedback');
      });
    });

    this.route('bookmarks', function () {
      this.route('edit', { path: '/:order_id' });
      this.route('new');
    });

    this.route('profile');

    this.route('promo-codes', function () {
      this.route('new');
    });

    this.route('restaurants', function () {
      this.route('show', { path: '/:restaurant_id' }, function () {
        this.route('menus', function () {
          this.route('show', { path: ':menu_id' });
          this.route('edit', { path: '/:menu_id/edit' });
        });

        this.route('accounting');
        this.route('giftbit');

        this.route('capacities', function () {
          this.route('edit-order', { path: '/:order_id' }, function () {
            this.route('index', { path: '/' });
            this.route('accounting', function () {
              this.route('legacy-ledger', { path: '/legacy-ledger/:ledger_id' });
              this.route('ledger', { path: '/ledger/:ledger_id' });
            });
            this.route('dev');
            this.route('manage-teams');
            this.route('cart');
            this.route('feed');
            this.route('meal-feedback');
          });
        });

        this.route('checklist');
        this.route('closures');
        this.route('story');
        this.route('service-times');
        this.route('masterfox');
        this.route('feed');
        this.route('orders');
        this.route('communications');

        this.route('rules', function () {
          this.route('show', { path: '/:rule_id' });
        });

        this.route('users', function () {
          this.route('show', { path: '/:user_id' }, function () {
            this.route('payment-cards');
            this.route('meal-planning-preference-profile');
            this.route('payroll');
            this.route('internal');
            this.route('communications');
            this.route('feed');
          });
        });
      });
    });

    this.route('admins', function () {
      this.route('salesforce', function () {
        this.route('show', {
          path: '/:salesforce_sync_error_id'
        });
      });
      this.route('giftbit');

      this.route('giftbit-errors', function () {
        this.route('show', {
          path: '/:giftbit_error_id'
        });
      });

      this.route('app-configurations', function () {
        this.route('show', { path: '/:app_configuration_id' });
      });

      this.route('capabilities');

      this.route('tax-rates', function () {
        this.route('new');
      });
      this.route('companies', function () {
        this.route('show', { path: '/:company_id' });
      });

      this.route('restaurant-brands', function () {
        this.route('show', { path: '/:restaurant_brand_id' });
      });

      this.route('subscription-plans', function () {
        this.route('edit', { path: '/:id' });
        this.route('new');
      });

      this.route('rules', function () {
        this.route('show', { path: '/:rule_id' });
      });

      this.route('twilio', function () {
        this.route('show', { path: '/:message_id' });
      });
    });

    this.route('rules', function () {
      this.route('rule');
    });

    this.route('tags', function () {
      this.route('restaurant', function () {
        this.route('new');
      });
      this.route('diversity', function () {
        this.route('new');
      });
      this.route('dietary', function () {
        this.route('new');
      });

      this.route('meal-type', function () {
        this.route('new');
      });

      this.route('food-type', function () {
        this.route('new');
      });

      this.route('top-cuisine', function () {
        this.route('new', { path: '/:top_cuisine_id' });
      });
    });

    this.route('users', function () {
      this.route('show', { path: '/:user_id' }, function () {
        this.route('payment-cards');
        this.route('internal');
        this.route('payroll');
        this.route('communications');
        this.route('feed');
        this.route('meal-planning-preference-profile');
      });
    });

    this.route('dev-team', function () {
      this.route('components', function () {
        this.route('form-for');
        this.route('controls');
        this.route('forms');
        this.route('filters');
        this.route('layouts');
        this.route('logistics');
        this.route('lists');
        this.route('misc');
        this.route('menus');
        this.route('tables');
        this.route('rules');
      });
      this.route('release-notes');
      this.route('feature-flags');
    });

    this.route('overrides', { path: '/overrides/:utilizer_model/:utilizer_id/:user_id' });

    this.route('integrations-health-monitoring', function () {
      this.route('index', { path: '/' }, function () {});
      this.route('show', { path: '/:integration_name' });
    });
  });
});
