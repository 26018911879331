import moment from 'moment-timezone';

/**
 * Given a local time this function returns a new date that is that
 * same time but in the TZ you specify.
 *
 * For instance if I have 2:00pm PST and I pass in 'America/Chicago'
 * I will get back a 12:00pm PST which is 2:00pm in chicago
 *
 * @param {date} dateTime to date you want to transform
 * @param {string} tz the timezone you want to use
 * @returns {Date}
 */
export function toLocalTimeIn(dateTime, tz) {
  const dm = moment(dateTime);

  return moment(moment.tz(dm, tz).format('YYYY-MM-DDTHH:mm:00')).toDate();
}

/**
 * This function undoes the toLocalTimeIn function above
 *
 * @param {date|string|moment} dateTime
 * @param {string} tz the timezone you want to use
 * @returns {Date}
 */
export function fromLocalTimeIn(dateTime, tz) {
  const dm = moment(dateTime);

  return moment.tz(dm.format('YYYY-MM-DDTHH:mm:00'), tz).toDate();
}
