import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import AdminsRulesRoute from 'star-fox/features/logged-in/admins/rules/route';

@classic
export default class RulesRoute extends AdminsRulesRoute {
  templateName = 'logged-in.admins.rules';

  @computed
  get owner() {
    return this.modelFor('logged-in.restaurants.show').restaurant;
  }
}
