import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';

@classic
export default class PasswordResetController extends Controller {
  /** @type {Service} */
  @service('session')
  session;

  /** @type {boolean} canLogin  */
  @computed('model.password', 'model.passwordConfirmation')
  get canReset() {
    return (
      !!this.get('model.password') &&
      this.get('model.password') === this.get('model.passwordConfirmation')
    );
  }

  @action
  login() {
    // unload our user so the normal login logic runs
    this.store.unloadRecord(this.get('model'));
    this.get('session').authenticate(
      'authenticator:devise',
      this.get('model.email'),
      this.get('model.password')
    );
  }
}
