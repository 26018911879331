import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@tagName('')
export default class MealPlanDayMealSlotFormFor extends Component {
  /** @type {Slot} */
  slot = null;

  /** @type {Meal} */
  meal = null;

  /** @type {?Function} */
  removeSlot() {}
}
