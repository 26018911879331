import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { mapBy, max } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@classNames('fde-search-results-results-histogram')
export default class ResultsHistogram extends Component {
  title = null;
  results = null;

  @mapBy('results', 'count')
  counts;

  @max('counts')
  maxCount;

  @computed('maxCount')
  get bars() {
    const max = this.get('maxCount');

    return (this.get('results') || []).map((result) => ({
      name: result.name,
      count: result.count,
      width: (result.count / max) * 100,
      color: result.color
    }));
  }
}
