import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class IndexRoute extends Route.extend(LoadingRouteMixin, CapabilityRouteMixin) {
  capability = 'CLIENTS_INDEX';

  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    searchText: { refreshModel: true },
    sort: { refreshModel: true },
    areas: { refreshModel: true }
  };

  model(params) {
    const { pageLimit, pageOffset, searchText, sort, areas } = params;

    return RSVP.hash({
      areas: this.store.query('area', {
        page: {
          limit: 100,
          offset: 0
        }
      }),
      clients: this.store.query('client', {
        page: {
          limit: pageLimit,
          offset: pageOffset
        },
        filter: {
          search: searchText,
          area: areas
        },
        sort: sort,
        include: 'areas,owner'
      })
    });
  }
}
