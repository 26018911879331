import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

import { action } from '@ember/object';

/**
 * Row component as the
 */
@classic
@tagName('')
export default class RowFormSwitcher extends Component {
  /** @type {boolean} */
  isSelected = false;

  /** @type {boolean} */
  isEditing = false;

  /** @type {Model} */
  value = null;

  /** @type {boolean} */
  isRowSelectable = false;

  /** @type {String} */
  selectableRowCursor = 'pointer';

  onDidSubmit() {}
  onDidReset() {}
  onRowViewClick() {}
  onRowFormClick() {}

  @action
  handleTrClick(clickEvent) {
    this.onRowViewClick(this.get('value'), clickEvent);
  }

  @action
  handleFormClick(clickEvent) {
    this.onRowFormClick(this.get('value'), clickEvent);
  }
}
