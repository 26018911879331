import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { run } from '@ember/runloop';
import { guidFor } from '@ember/object/internals';

@classic
@classNames('fde-basic-modal')
export default class BasicModal extends Component {
  _isModalShowing = false;
  _hasShownModal = false;
  _isCompletelyHidden = true;

  @computed
  get _semanticModalId() {
    return `fde-semantic-modal_${guidFor(this)}`;
  }

  isShowing = true;
  focusOnFirstInput = false;
  modalClasses = '';
  context = '.ember-application';
  useFlex = 'auto';
  autofocus = true;
  closable = true;
  resetOnHidden = true;
  modalInitialized = false;
  keyboardShortcuts = true;
  duration = 400;
  transition = 'scale';
  offset = 0;

  @computed('_isCompletelyHidden', 'resetOnHidden')
  get shouldRenderModal() {
    return this.get('resetOnHidden') ? !this.get('_isCompletelyHidden') : true;
  }

  onApprove() {}
  onDeny() {}
  onVisible() {}
  onHide() {}
  onHidden() {}

  initModal() {
    this.set('modalInitialized', true);

    const _modal = this.$('.ui.modal').modal({
      context: this.get('context'),
      closable: this.get('closable'),
      autofocus: this.get('autofocus'),
      keyboardShortcuts: this.get('keyboardShortcuts'),
      allowMultiple: true,
      offset: 0,
      onVisible: () => {
        this.onVisible();
      },
      onHide: () => {
        this.onHide();
      },
      onApprove: () => {
        this.onApprove();
      },
      onDeny: () => {
        this.onDeny();
      },
      onHidden: () => {
        if (this.get('isDestroyed')) {
          return;
        }

        this.setProperties({
          _isModalShowing: false,
          _isCompletelyHidden: true
        });

        this.onHidden();

        if (this.get('resetOnHidden')) {
          this.destroySemanticModal();
        }
      }
    });

    this.set('_modal', _modal);
  }

  destroySemanticModal() {
    if (!this.get('isDestroyed') && this.get('_modal')) {
      this.get('_modal').modal('destroy');
      this.set('modalInitialized', false);
      this.set('_modal', null);

      const modalEl = document.querySelector(`#${this.get('_semanticModalId')}`);
      modalEl.remove();
      const modalsEl = document.querySelector(`.ui.modals`);
      modalsEl.remove();
    }
  }

  _showSemanticModal() {
    if (this.get('isDestroyed')) {
      return;
    }

    if (!this.get('modalInitialized')) {
      this.initModal();
    }

    const modal = this.get('_modal');

    modal.modal('show');
    modal.modal('refresh');
  }

  _hideSemanticModal() {
    this.get('_modal').modal('hide');
  }

  showModal() {
    if (!this.get('_isModalShowing')) {
      this.setProperties({
        _isModalShowing: true,
        _isCompletelyHidden: false
      });

      if (this.get('resetOnHidden')) {
        //Have to wait for the dom to render. Call show semantic on next run loop after dom has rendered .ui.modal
        run.next((_) => {
          !this.get('isDestroyed') && this._showSemanticModal();
        });
      } else {
        //.ui.modal is always showing after render. Can safely call show semantic modal.
        this._showSemanticModal();
      }
    }
  }

  hideModal() {
    if (this.get('_isModalShowing')) {
      this.set('_isModalShowing', false);
      this._hideSemanticModal();
    }
  }

  driveVisibility() {
    if (this.get('isShowing')) {
      this.showModal();
    } else {
      this.hideModal();
    }
  }

  didInsertElement() {
    super.didInsertElement(...arguments);

    this.set('_elementInserted', true);
    this.driveVisibility();
  }

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    if (this.get('_elementInserted')) {
      this.driveVisibility();
    }
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.hideModal();
    this.destroySemanticModal();
  }

  @action
  handleCloseClick() {
    this.hideModal();
  }
}
