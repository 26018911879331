import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';

import { not } from '@ember/object/computed';
import { computed } from '@ember/object';
import MenuItemValidator from 'star-fox/models/validators/menu-item';
import { priceGetterAndSetter } from 'renard/utils/money';
import { ceilToQuarter } from 'renard/utils/numbers';

export default Model.extend(MenuItemValidator, {
  /*
   * Attributes
   */
  active: attr('boolean', { defaultValue: false }),
  budgetPrice: attr('number', { defaultValue: 0 }),
  balancedPrice: attr('number', { defaultValue: 0 }),
  bountifulPrice: attr('number', { defaultValue: 0 }),
  createdAt: attr('date'),
  description: attr('string'),
  internalDescription: attr('string'),
  maximumQuantity: attr('number'),
  minimumQuantity: attr('number'),
  maximumServing: attr('number'),
  minimumServing: attr('number'),
  actualMaximumQuantity: attr('number'),
  actualMinimumQuantity: attr('number'),
  actualMaximumServing: attr('number'),
  actualMinimumServing: attr('number'),
  name: attr('string'),
  capacityUnits: attr('number', { defaultValue: 1 }),
  clientPriceCents: attr('number', { defaultValue: 0 }),
  restaurantPriceCents: attr('number', { defaultValue: 0 }),
  retailPriceCents: attr('number', { defaultValue: 0 }),

  /*
   * Relationships
   */
  dietaryTags: hasMany('dietary-tag', { async: false }),
  foodTypes: hasMany('food-type', { async: false, inverse: 'menuItems' }),
  mealTypes: hasMany('meal-type', { async: false, inverse: 'menuItems' }),
  menuGroup: belongsTo('menu-group', { async: false, inverse: 'menuItems' }),
  menuOptionGroups: hasMany('menu-option-group', { async: false, inverse: 'menuItem' }),
  taxRate: belongsTo('invoicing-tax-rate'),
  /*
   * Computed Properties
   */
  clientPrice: computed(
    'clientPriceCents',
    priceGetterAndSetter('clientPriceCents', ceilToQuarter)
  ),
  external: not('internal'),
  restaurantPrice: computed('restaurantPriceCents', priceGetterAndSetter('restaurantPriceCents')),
  retailPrice: computed('retailPriceCents', priceGetterAndSetter('retailPriceCents')),

  /** @type {MealType[]} */
  servingDescription: computed('actualMinimumServing', 'actualMaximumServing', function () {
    const minimumServing = this.get('actualMinimumServing');
    const maximumServing = this.get('actualMaximumServing');

    return minimumServing === maximumServing
      ? minimumServing
      : `${minimumServing}-${maximumServing}`;
  }),

  quantityDescription: computed('actualMinimumQuantity', 'actualMaximumQuantity', function () {
    const minimumQuantity = this.get('actualMinimumQuantity') || '0';
    const maximumQuantity = this.get('actualMaximumQuantity') || '∞';

    return `${minimumQuantity} - ${maximumQuantity}`;
  }),

  /** @type {MealType[]} */
  allMealTypes: computed('mealTypes.[]', 'menuGroup.mealTypes.[]', function () {
    return this.get('mealTypes')
      .sortBy('name')
      .toArray()
      .concat(this.get('menuGroup.mealTypes').sortBy('name').toArray());
  }),

  /** @type {FoodType[]} */
  allFoodTypes: computed('foodTypes.[]', 'menuGroup.foodTypes.[]', function () {
    return this.get('foodTypes')
      .sortBy('name')
      .toArray()
      .concat(this.get('menuGroup.foodTypes').sortBy('name').toArray());
  }),

  /** @type {FoodType[]} */
  menuOptionItemDietaryTags: computed('menuOptionGroups.@each.dietaryTags', function () {
    return this.get('menuOptionGroups')
      .mapBy('dietaryTags')
      .reduce((acc, tags) => acc.concat(tags.toArray()), [])
      .uniq();
  }),

  /** @type {MenuOptionItem[]} */
  menuOptionItems: computed('menuOptionGroups.@each.menuOptionItems', function () {
    return this.get('menuOptionGroups')
      .mapBy('menuOptionItems')
      .reduce((acc, mois) => acc.concat(mois.toArray()), []);
  }),

  humanize: computed('code', function () {
    return `Menu Item: '${this.get('name')}'`;
  }),

  /** @type {Number} */
  averageServing: computed(
    'clientPriceCents',
    'actualMinimumServing',
    'actualMaximumServing',
    function () {
      return (this.get('actualMinimumServing') + this.get('actualMaximumServing')) / 2;
    }
  ),

  /*
   * Functions
   */

  matches(requirement) {
    return !requirement || requirement.apply(this);
  },

  /**
   * Duplicates the MenuItem that this method is called on
   * @return {MenuItem} Returns the newly made MenuItem
   */
  duplicate() {
    const modelName = this.constructor.modelName;
    const adapter = this.store.adapterFor(modelName);

    this.set('isDuplicating', true);

    return adapter
      .duplicate(this, 'dietary-tags,menu-option-groups.menu-option-items.dietary-tags')
      .then((_) => {
        const menuGroup = this.get('menuGroup');
        const menuItems = menuGroup.get('menuItems').toArray();
        const index = menuItems.indexOf(this);

        menuItems.removeObject(_);
        menuItems.insertAt(index + 1, _);

        menuGroup.set('menuItems', menuItems);
        menuGroup.save();

        return _;
      })
      .then((_) => _.reload())
      .finally((_) => this.set('isDuplicating', false));
  },

  /**
   * Creates an order item
   * @return {OrderItem} OrderItem object to be saved later
   */
  orderItemFor(order) {
    return this.store.createRecord('order-item', {
      order: order,
      menuItem: this
    });
  },

  /**
   * Creates a custom order item
   * @param {Order} order
   * @param {GroupOrderMember|null} groupOrderMember
   * @return {OrderItem} Custom OrderItem object to be saved later
   */
  customOrderItemFor(order, groupOrderMember = null) {
    return this.store.createRecord('order-item', {
      order,
      menuItem: this,
      clientPriceCents: this.get('clientPriceCents'),
      restaurantPriceCents: this.get('restaurantPriceCents'),
      groupOrderMember
    });
  },

  combinations: computed('menuOptionGroups.[]', function () {
    // remove all non zero combinations
    const mogs = this.get('menuOptionGroups').filter((_) => _.get('combinations.length') > 0);

    const [head, ...tail] = mogs;

    if (!head) {
      return [];
    }

    return tail.reduce((acc, item) => {
      return item.get('combinations').reduce(
        (newAcc, c) =>
          // concat the existing combinations onto each of our combinations
          newAcc.concat(acc.map((a) => a.concat(c))),
        []
      );
    }, head.get('combinations'));
  }),

  buildOptionsForTags(dietaryTags) {
    const myDietaryTags = this.get('dietaryTags');
    const missingTags = dietaryTags.filter((dietaryTag) => !myDietaryTags.includes(dietaryTag));
    const combinations = this.get('combinations');

    // If there aren't any combinations on this this menu item
    // or this menu item's base configuration matches the tag list
    // return a base configuration
    if (combinations.length === 0 || missingTags.length === 0) {
      return [
        {
          id: this.get('id'),
          price: this.get('clientPriceCents')
        }
      ];
    }

    const validCombinations = combinations.reduce((acc, combination) => {
      const combinationTags = combination.reduce(
        (acc, moi) => moi.get('dietaryTags').toArray().concat(acc),
        []
      );
      const everyChoiceIsRequired = combination.every((moi) =>
        moi.get('dietaryTags').find((tag) => missingTags.includes(tag))
      );
      const isSatisfied = missingTags.every((tag) => combinationTags.includes(tag));

      if (isSatisfied && everyChoiceIsRequired) {
        acc.push(combination);
      }

      return acc;
    }, []);

    return validCombinations.map((combination) => ({
      id: `${this.get('id')}_${combination.mapBy('id').join('_')}`,
      price: combination
        .mapBy('clientPriceCents')
        .reduce((a, b) => a + b, this.get('clientPriceCents'))
    }));
  }
});
