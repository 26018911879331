import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { guidFor } from '@ember/object/internals';
import Quill from 'quill';

@classic
export default class RichTextControl extends Component {
  value = null;

  /** @type {string} */
  @computed
  get guid() {
    return guidFor(this);
  }

  /** @type {string} */
  @computed('guid')
  get editorId() {
    return guidFor(this) + '-editor';
  }

  /** @type {string} */
  @computed('guid')
  get toolbarId() {
    return guidFor(this) + '-toolbar';
  }

  init() {
    super.init();
    this.set('initialText', this.get('value'));
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.editor = new Quill(`#${this.get('editorId')}`, {
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['link'],
          [{ color: [] }, { background: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ align: [] }],
          ['image', 'code-block']
        ]
      },
      placeholder: `What's new?`,
      theme: 'snow' // or 'bubble'
    });

    this.handler = () => {
      this.onChange(this.$('.ql-editor').html());
    };

    this.editor.on('text-change', this.handler);
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.editor.off('text-change', this.handler);
  }
}
