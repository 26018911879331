import Fixture, {
  element,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class RestaurantsMultiRestaurantAddUserFormFixture extends Fixture {
  constructor() {
    super('data-test-sf-modal="add-user-to-restaurant');
  }

  @element({ selector: 'data-test-sf-modal-header' }) header;
  @element({ selector: 'data-test-sf-modal-content' }) content;
  @element({ selector: 'data-test-sf-modal-actions' }) actions;
}

export const restaurantsMultiRestaurantAddUserForm = getElementDecoratorFor(
  RestaurantsMultiRestaurantAddUserFormFixture
);
export const restaurantsMultiRestaurantAddUserFormWithValue = getElementWithValueDecoratorFor(
  RestaurantsMultiRestaurantAddUserFormFixture
);
