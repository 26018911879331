import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class SfRestaurantsMultiRestaurantModalsSearchUserComponent extends Component {
  @service store;
  @service notify;

  @tracked selected = undefined;

  @action
  async updateSearch(search) {
    try {
      const results = await this.store.query('user', { filter: { search }, include: 'roles' });
      const asArray = results.toArray();

      // We only care about restaurant users
      return asArray.filter((u) => u.roles.filter((r) => r.name === 'restaurant').length > 0);
    } catch (err) {
      console.error('Error performing search:', err);
    }
  }

  @action
  async select(user) {
    this.selected = user;
  }
}
