import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';

/**
 * Simple component for committing fields
 */
@classic
@classNames('ui buttons form-control-commit-buttons')
@classNameBindings('isVertical:vertical')
export default class CommitButtons extends Component {
  isVertical = false;
  isActing = false;

  /** @property {function} handler for when the commit button is pressed **/
  commit() {}

  /** @property {function} handler for when the cancel button is pressed **/
  cancel() {}
}
