import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { action } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('fde-logistics-salesforce-case')
export default class SalesforceCase extends Component {
  @service('session')
  session;

  @service
  store;

  /** @type {Service} */
  @service
  appConfiguration;

  @alias('appConfiguration.salesforce.preferences.courtesyCalls')
  salesforceConfiguration;

  /** @type {Order} Order obj that's passed in from the expanded-row */
  order = null;

  /** @type {?} POJO to gather the appropriate info for creating a salesforce case */
  salesforceCase = null;

  @alias('salesforceConfiguration.types')
  caseTypes;

  @alias('salesforceConfiguration.caseLateEstimates')
  caseEstimates;

  @alias('salesforceConfiguration.caseReasons')
  caseReasons;

  newCase = {};
  onCaseAdded = {};
  showModal = null;

  _resetNewCase() {
    this.set('newCase', {
      caseType: null,
      reason: null,
      estimate: null,
      description: ''
    });
  }

  _generateCaseSubject() {
    const order = this.get('order');
    const newCase = this.get('newCase');
    const prefix = newCase.caseType.includes('Customer') ? '[CC]' : '[CR]';

    // We're looking to create a subject line like this:
    // [CC] - 5 minutes late - VAN-123456
    return `${prefix} - ${newCase.estimate} - ${order.get('identifier')}`;
  }

  _generateBoilerPlateCaseDescription() {
    const order = this.get('order');
    const contact = order.get('contact');

    return `
      ${order.get('monitoringNotes') || ''}
      Order URL: https://concierge.food.ee/starfox/orders/${order.get('orderId')}
      Starfox User Profile: https://concierge.food.ee/starfox/users/${contact.get('id')}

      On-site contact:
      ${contact.get('name')}
      ${contact.get('phoneNumber')}
      ${contact.get('email')}
    `;
  }

  init() {
    super.init(...arguments);
    this._resetNewCase();
    this.set('salesforceCase', this.get('store').createRecord('salesforceCase'));
    this.set('newCase.description', this._generateBoilerPlateCaseDescription());
  }

  @action
  async submitSalesforceCase() {
    const salesforceCase = this.get('salesforceCase');
    const newCase = this.get('newCase');
    // If we're a deliverable, lets find the order, since that's how cases work right now
    const order = await this.get('store').find('order', this.get('order.id'));

    salesforceCase.setProperties({
      caseType: newCase.caseType,
      caseReason: `${newCase.estimate} - ${newCase.reason || 'None'}`,
      status: 'New',
      subject: this._generateCaseSubject(),
      description: newCase.description,
      origin: 'Starfox',
      order: order
    });

    return salesforceCase
      .save()
      .then(() => this.onCaseAdded(order))
      .then(() => this.set('showModal', false))
      .catch((error) => console.error(error));
  }
}
