import { inject as service } from '@ember/service';
import { notEmpty, oneWay } from '@ember/object/computed';
import Controller from '@ember/controller';
import EmberObject, { action, computed } from '@ember/object';
import RSVP from 'rsvp';
import macros from 'star-fox/utils/computed';
import moment from 'moment-timezone';
import { flashSet } from 'star-fox/utils/ember-object-setters';

export default Controller.extend({
  notify: service(),

  /** @override */
  queryParams: [
    'managedByMe',
    'showExisting',
    'startDeliverOn',
    'endDeliverOn',
    'clientId',
    'pageLimit',
    'pageOffset',
    'sort'
  ],

  /*
   * Start filters
   */

  /** @type {boolean} */
  managedByMe: false,

  /** @type {boolean} */
  showExisting: true,

  deliverOnDateRange: EmberObject.create({
    startDate: new Date(),
    endDate: moment().add(7, 'days').toDate()
  }),

  /** @type {Date} */
  startDeliverOn: macros.date('deliverOnDateRange.startDate'),

  /** @type {Date} */
  endDeliverOn: macros.date('deliverOnDateRange.endDate'),

  /** @type {?String} */
  clientId: null,

  /** @type {?String} */
  groupBy: undefined,

  /** @type {{icon: String, label: String, id: String}[]} */
  groupByValues: [
    {
      icon: 'calendar',
      label: 'Delivery Date',
      id: 'deliverOnFormatted'
    },
    {
      icon: 'building',
      label: 'Client',
      id: 'client.accountName'
    },
    {
      icon: 'utensils',
      label: 'Restaurant',
      id: 'restaurant.name'
    }
  ],

  /*
   * End filters
   */

  /** @type {String} */
  sort: 'id',

  /*
   * Start Pagination
   */
  recordCount: oneWay('model.orders.meta.recordCount'),

  /** @type {Number} */
  pageOffset: 0,

  /** @type {Number} */
  pageLimit: 40,

  /*
   * End Pagination
   */

  newOrders: {
    title: 'New',
    values: []
  },

  /** {Order[]} */
  orders: macros.rejectBy('model.orders', 'isNew', true),

  /** @type {boolean} */
  hasOrders: notEmpty('orders'),

  /** {Order[]} */
  groupedModelOrders: macros.arrayGroupedBy('orders', 'groupBy.id'),

  /** @type {Client[]} */
  searchedClients: null,

  /** @type {Restaurant[]} */
  searchedRestaurants: null,

  /** @type {Order[]} */
  selectedOrders: null,

  /** @type {boolean} */
  allSelected: computed('selectedOrders.[]', 'orders.[]', function () {
    return this.get('selectedOrders.length') === this.get('orders.length');
  }),

  /**
   * Concatenate all of the New orders so they end up showing up twice, but its sort
   * of like a, this is what you made this session array
   */
  groupedOrders: computed('newOrders.values.[]', 'groupedModelOrders', function () {
    return [this.get('newOrders')].concat(this.get('groupedModelOrders'));
  }),

  handleOrderRestaurantChange: action(function (order) {
    order.get('restaurant').applyDefaultsTo(order);
  }),

  handleDidSubmit: action(function (order) {
    this.get('newOrders.values').pushObject(order);
  }),

  selectFilterClient: action(function (client) {
    this.setProperties({
      'model.client': client,
      pageOffset: 0
    });

    if (client) {
      this.set('clientId', client.get('id'));
    } else {
      this.set('clientId', null);
    }
  }),

  publishSelected: action(function () {
    if (
      this.get('allSelected') &&
      !confirm('You have all selected: Are you sure you want to Publish all of them?')
    ) {
      return;
    }

    this.set('isSaving', true);

    const allPromises = this.get('selectedOrders')
      .filter((_) => _.get('state.isDraft'))
      .map((_) => {
        _.set('stateEvent', 'publish');
        return _.save();
      });

    RSVP.all(allPromises).finally((_) => this.set('isSaving', false));
  }),

  quoteSelected: action(function () {
    if (
      this.get('allSelected') &&
      !confirm('You have all selected: Are you sure you want to Quote all of them?')
    ) {
      return;
    }

    this.set('isSaving', true);

    const allPromises = this.get('selectedOrders')
      .filter((_) => _.get('state.isPreQuote'))
      .map((_) => {
        _.set('stateEvent', 'quote');
        return _.save();
      });

    RSVP.all(allPromises).finally((_) => this.set('isSaving', false));
  }),

  submitAsapSelected: action(function () {
    if (
      this.get('allSelected') &&
      !confirm('You have all selected: Are you sure you want to Submit As ASAP on all of them?')
    ) {
      return;
    }

    this.set('isSaving', true);

    const allPromises = this.get('selectedOrders')
      .filter((_) => _.get('state.isPreQuote'))
      .map((_) => {
        _.set('stateEvent', 'submit_as_asap');
        return _.save();
      });

    RSVP.all(allPromises).finally((_) => this.set('isSaving', false));
  }),

  showTeamOrdersOnEnterprise: action(function () {
    if (
      this.get('allSelected') &&
      !confirm('You have all selected: Are you sure you want to show them all on Team Enterprise?')
    ) {
      return;
    }

    this.set('isSaving', true);

    const allPromises = this.get('selectedOrders')
      .filter((_) => {
        return (
          (_.get('orderType') === 'group' || _.get('orderType') === 'team') &&
          _.get('client.plan') === 'enterprise'
        );
      })
      .map((_) => {
        _.set('isMealPlanOrder', true);
        return _.save();
      });

    RSVP.all(allPromises).finally((_) => this.set('isSaving', false));
  }),

  hideTeamOrdersOnEnterprise: action(function () {
    if (
      this.get('allSelected') &&
      !confirm(
        'You have all selected: Are you sure you want to hide them all from Team Enterprise?'
      )
    ) {
      return;
    }

    this.set('isSaving', true);

    const allPromises = this.get('selectedOrders')
      .filter((_) => {
        return (
          (_.get('orderType') === 'group' || _.get('orderType') === 'team') &&
          _.get('client.plan') === 'enterprise'
        );
      })
      .map((_) => {
        _.set('isMealPlanOrder', false);
        return _.save();
      });

    RSVP.all(allPromises).finally((_) => this.set('isSaving', false));
  }),

  cancelSelected: action(function () {
    if (
      this.get('allSelected') &&
      !confirm('You have all selected: Are you sure you want to Cancel them all?!?')
    ) {
      return;
    }

    if (
      !confirm(
        'Are you sure you want to cancel the orders you have selected? This is irreversible.'
      )
    ) {
      return;
    }

    this.set('isSaving', true);

    const allPromises = this.get('selectedOrders')
      .filter((_) => !_.get('isNew') && !_.get('state.isCancelled'))
      .map((_) => {
        _.set('stateEvent', 'cancel');
        return _.save();
      });

    RSVP.all(allPromises).finally((_) => this.set('isSaving', false));
  }),

  showOrdersInIndex: action(function () {
    if (this.get('selectedOrders.length')) {
      this.transitionToRoute('logged-in.orders', {
        queryParams: {
          id: this.get('selectedOrders')
            .map((_) => `${_.get('id')}::${_.get('identifier')}`)
            .join(';')
        }
      });
    }
  }),

  handleNewSelection: action(function (newSelection) {
    this.set('selectedOrders', newSelection);
  }),

  handleEnterpriseClick: action(function (order) {
    order.toggleProperty('isMealPlanOrder');
    order.save();
  }),

  handleDeliverAtCommit: action(function (order, deliverAt, prevDeliverAt) {
    const diff = prevDeliverAt ? moment(deliverAt).diff(moment(prevDeliverAt), 'minutes') : null;
    order.adjustTimesRelativeToDeliverAt(diff);
    flashSet(this, 'adjustedDeadlineAt', true, 2000, false);
  }),

  handleOnlyMyAccountsChange: action(function (managedByMe) {
    this.setProperties({
      managedByMe,
      pageOffset: 0
    });
  }),

  handleDeliverOnDateRangeChange: action(function (deliverOnDateRange) {
    this.setProperties({
      deliverOnDateRange,
      pageOffset: 0
    });
  }),

  handleOrderEdit: action(function (order) {
    order.get('client').reloadWith('admin-contacts');
  })
});
