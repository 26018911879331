import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class MealPlanningReservation extends Application {
  attrs = {
    client: {
      serialize: true
    },
    restaurant: {
      serialize: true
    },
    area: {
      serialize: true
    },
    mealPlanningInstance: {
      serialize: true
    },
    requestedCapacity: {
      serialize: false
    }
  };
}
