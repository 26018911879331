import RSVP from 'rsvp';
import { observer } from '@ember/object';
import { run } from '@ember/runloop';

/**
 * This helper builds an observer that connects composite locations with their actual location objects
 * @param {string} valueToSetPath path to where we set the composite location that drives the location drop down deliveryLocation || pickupLocation
 * @param {string} observeValuePath a path to a value to observe and re run the observer ie: order.restaurant || order.client these would both trigger a new location
 * @param {string} currentLocationPath a path to the currently selected actual location ie order.restaurantLocation || order.pickupLocation
 * @param {string} compositeLocationsPath path to where the composite locations live. ie: client.deliveryLocations or restaurant.pickupLocation
 * @return {observer}
 */
export function observeLocation(
  valueToSetPath,
  observeValuePath,
  currentLocationPath,
  compositeLocationsPath
) {
  return observer(observeValuePath, currentLocationPath, compositeLocationsPath, function () {
    console.debug(
      `[observe-location] ${observeValuePath}||${currentLocationPath}||${compositeLocationsPath} did change`
    );

    RSVP.hash({
      currentLocation: this.get(currentLocationPath),
      compositeLocations: this.get(compositeLocationsPath)
    })
      .then(({ currentLocation, compositeLocations }) =>
        (compositeLocations || []).find(
          (compositeLocation) =>
            compositeLocation &&
            currentLocation &&
            compositeLocation.get('locationId') == currentLocation.get('id')
        )
      )
      .then(
        (compositeLocation) =>
          // uggh this appears to make things work
          compositeLocation &&
          run.next((_) => {
            console.debug(
              `[observe-location] Setting ${valueToSetPath} : Location ID: ${compositeLocation.get(
                'id'
              )}`
            );
            this.set(valueToSetPath, compositeLocation);
          })
      );
  });
}
