import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import Helper from '@ember/component/helper';
import { get, set } from '@ember/object';

/**
 * This helper is specifically for dealing with errors that come out of value objects / nested objects
 * because of a short coming in ember-data, JSON pointers are not applied to nested objects.
 *
 * Example:
 *
 * rules-action: {
 *   configuration: {
 *     value: null
 *   }
 * }
 *
 * The back end requires that a configuration for a particular action has a value path, if it does not
 * it will return the following error:
 *
   {
      code: "100"
      detail: "configuration/value - can't be blank"
      source: {pointer: "/data/attributes/configuration/value"}
      pointer: "/data/attributes/configuration/value"
      status: "422"
      title: "can't be blank"
    }
 *
 * Sadly ember data will not understand the JSON-Pointer and apply it correctly to
 * nested attribute.
 *
 * This helper resolves that issue and correctly binds the errors collection for
 * fields as follows:

  {{form.field-for 'configuration.value'
                   using='value-select'
                   errors=(coerce-value-errors action 'configuration.value')
  }}
 *
 */
@classic
export default class CoerceValueErrors extends Helper {
  compute([root, path]) {
    set(this, 'root', root);

    // replace all dots with slashes
    return get(root, `errors.${path.replace(/\./g, '/')}`);
  }

  @observes('root.errors.@each.length')
  rootErrorsDidChange() {
    this.recompute();
  }
}
