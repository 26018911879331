import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import moment from 'moment-timezone';

@classic
export default class CalendarRoute extends Route {
  async model(_params) {
    const params = this.paramsFor('logged-in.planning');

    let { area, monday } = params;

    if (!area) {
      return null;
    }

    monday = moment(monday).format('YYYY-MM-DD');

    const reservations = area
      ? await this.store.query('meal-planning-reservation', {
          include: ['client', 'restaurant', 'meal-planning-instance'].join(','),
          fields: {
            clients: 'account-name,plan',
            restaurants: 'active,name',
            areas: 'city'
          },
          filter: { area_id: area, monday: monday, reject_state: 'converted' },
          page: { limit: 300 }
        })
      : [];

    const orders = area
      ? await this.store.query('order', {
          include: [
            'driver',
            'client',
            'restaurant',
            'client-location',
            'restaurant-location'
          ].join(','),
          fields: {
            clients: 'account-name,plan',
            restaurants: 'active,name',
            areas: 'city'
          },
          filter: { area_id: area, order_week: monday, reject_state: ['cancelled', 'draft'] },
          page: { limit: 300 }
        })
      : [];

    return RSVP.hash({
      area: area,
      records: reservations.toArray().concat(orders.toArray())
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setupPusher();
  }

  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);
    controller.teardownPusher();
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
