import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';

@classic
export default class DetailsController extends Controller {
  /** @type {InvoicingTaxRate[]} */
  invoicingTaxRates = null;

  /** @type {string} */
  @computed('model.country')
  get regionLabel() {
    return {
      US: 'State',
      CA: 'Province',
      UK: 'County'
    }[this.get('model.country')];
  }

  /** @type {string} */
  @computed('model.country')
  get moneySymbol() {
    return {
      US: 'dollar',
      CA: 'dollar',
      UK: 'pound'
    }[this.get('model.country')];
  }

  @action
  onCountryChange(value) {
    let oldValue = this.get('model.country');

    if (oldValue !== value) {
      this.get('model').setProperties({
        province: '',
        country: value
      });
    }
  }

  @action
  handleCancel() {
    this.transitionToRoute('logged-in.areas');
  }
}
