import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import sanitizeSVG from '@mattkrick/sanitize-svg';

@classic
@classNames('fde-svg-control')
export default class SvgControl extends Component {
  /** @type {string} */
  value = null;

  /** @type {Number} size in bytes (default 50kb) */
  size = 51_200;

  /** @type {Number} width in pixels */
  widthConstraint = 72;

  /** @type {Number} height in pixels */
  heightConstraint = 72;

  @service
  notify;

  /** @type {function} callback function called when the file errored uploading */
  onError(errorMessage) {
    this.notify.error(errorMessage);
  }

  /** @type {function} callback function called when the file has been uploaded */
  onChange() {}

  @action
  async handleChange(event) {
    const file = event?.target?.files[0];

    if (!file) {
      this.onError('No file selected');
      return;
    }

    if (file.type !== 'image/svg+xml') {
      this.onError('Selected file is not an SVG');
      return;
    }

    if (file.size > this.size) {
      this.onError(`Selected file is larger than ${this.size} bytes`);
      return;
    }

    if (!(await sanitizeSVG(file))) {
      this.onError('Security risk was detected in the selected file');
      return;
    }

    const reader = new FileReader();

    reader.onload = function (event) {
      if (event?.target?.result) {
        this.onChange(event.target.result);
      }
    }.bind(this);
    await reader.readAsDataURL(file);
  }
}
