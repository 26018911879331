import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('fde-giftbit-gift-cards ui grid')
export default class GiftCards extends Component {
  /** @type {GiftbitGifts[]} **/
  gifts = [];

  /** @type {number[]} **/
  selectedIds = [];

  /** @type {function} **/
  onCardClick() {}

  /** @type {nuber} **/
  filterAmount = null;

  /** @type {GiftbitGifts[]} **/
  @computed('gifts', 'filterAmount')
  get filteredGifts() {
    let filterAmount = this.get('filterAmount');

    if (filterAmount !== null) {
      filterAmount *= 100;
      return this.get('gifts').filter(
        (gift) =>
          gift.get('isActive') &&
          gift.get('minFoodeePriceInCents') <= filterAmount &&
          gift.get('maxFoodeePriceInCents') >= filterAmount
      );
    }

    return this.get('gifts');
  }
}
