import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { isEmpty } from '@ember/utils';
import RSVP from 'rsvp';
import { run } from '@ember/runloop';
import { getOwner } from '@ember/application';
import { dasherize } from '@ember/string';

import generateGuid from 'star-fox/utils/generate-guid';

/**
 * @typedef {Object} SearchableFilterResultSection
 * @property {String} title
 * @property {SearchableFilterResult[]}
 */

/**
 * @typedef {Object} SearchableFilterResult
 * @property {String} id ID or value of the filter result
 * @property {String} filterId Value key of the filter that the result could represent
 * @property {String} label Label string to display to the user in the result set
 */

@classic
@classNames('fde-filters-filter-bar')
export default class FilterBar extends Component {
  /** @type {FilterCollection} */
  filterCollection = null;

  /** @type {?Filter} */
  openFilter = null;

  /** @type {?Filter} */
  newFilter = null;

  /** @type {boolean} Will be true if showing callout pane to add new */
  isAddingNew = false;

  /** @type {string} */
  searchPlaceholder = 'Search';

  /** @type {boolean} */
  disableFiltersOnSearch = true;

  /** @type {?String} A space separated list of searchable filters based on id */
  searchableFilterIds = '';

  /** @type {SearchableFilterResultSection[]} An array of SearchableFilterResultSection objects */
  searchableFilterResultSections = [];

  /** @type {boolean} */
  isSearchLoading = false;

  debounce = 300;

  /** @type {boolean} */
  showSearch = true;

  /** @type {Filter[]} An array of filters from the FilterCollection that are searchable */
  @computed('filterCollection.filters', 'searchableFilterIds')
  get searchableFilters() {
    const searchableFilterIdsArray = this.get('searchableFilterIds').split(' ');
    return this.get('filterCollection.filters').filter((_) => {
      return searchableFilterIdsArray.includes(_.get('id'));
    });
  }

  /** @type {Filter[]} An array of filters which don't include those with keys already added to the filter collection */
  @computed('filterCollection.selectedFilters.[]', 'filterCollection.filters.[]')
  get unusedFilters() {
    return (this.get('filterCollection.filters') || [])
      .filter((_) => !this.get('filterCollection').hasSelectedFilterForKey(_.get('key')))
      .toArray();
  }

  /**
   * Search filters debounce handler
   * @private
   *
   * @param {string} query
   * @return {Promise}
   */
  searchFilters(query) {
    if (!query || query === '') {
      this.setProperties({
        searchableFilterResultSections: [],
        isSearchLoading: false
      });
      return;
    }

    const searchableFilters = this.get('searchableFilters');
    const searchableFilterResultSections = this.get('searchableFilterResultSections');

    searchableFilterResultSections.length = searchableFilters.length;

    const searchActionPromises = searchableFilters.map((filter) => {
      const searchAction = getOwner(this).lookup(`search-action:${filter.get('resourceName')}`);

      const requiredFieldNames =
        filter.get('resourceRequiredFields') || filter.get('resourceValueLabel');

      //Limit fields to fetch by label required
      const fields = {};
      fields[searchAction.get('modelName')] = dasherize(requiredFieldNames);

      return searchAction.search(query, { page: { limit: 5 }, fields }, null);
    });

    const searchGuid = generateGuid();
    this.set('_lastSearchGuid', searchGuid);

    return RSVP.all(searchActionPromises)
      .then((filtersResults) => {
        filtersResults.forEach((results, i) => {
          //Cancel updates of any overlapping searches. Prevents out of order results.
          if (searchGuid !== this.get('_lastSearchGuid')) {
            return;
          }

          const searchableFilterResultSections = this.get('searchableFilterResultSections');
          const searchableFilterForThisSet = searchableFilters[i];

          const sectionTitle = searchableFilterForThisSet && searchableFilterForThisSet.label;

          const filterResults = results.map((result) => {
            return {
              filterId: searchableFilterForThisSet.get('id'),
              id: result.get(searchableFilterForThisSet.get('resourceValueKey')),
              label: result.get(searchableFilterForThisSet.get('resourceValueLabel'))
            };
          });

          searchableFilterResultSections[i] = {
            title: sectionTitle,
            data: filterResults
          };

          this.set('searchableFilterResultSections', searchableFilterResultSections.toArray());
        });
      })
      .finally((_) => this.set('isSearchLoading', false));
  }

  /** @type {Function} */
  onSearchChange(_query) {}

  /** @type {Function} */
  onSearchInputKeyDown() {}

  /** @type {Function} */
  onFilterClose() {}

  /** @type {Function} */
  onFilterApply() {}

  /** @type {String} */
  @computed
  get guid() {
    return guidFor(this);
  }

  /** @type {String} */
  barContentId = 'fde-filter-bar_bar-content-default';

  /** @type {String} Unique ID for target and control of the search bar in this component */
  @computed
  get searchBarId() {
    return `${guidFor(this)}_search-bar`;
  }

  /** Resets the results to an empty array */
  resetSearchableFilterResults() {
    this.set('searchableFilterResultSections', []);
  }

  @action
  handleFilterClick(filter) {
    console.debug(filter);
    this.set('openFilter', filter);
  }

  @action
  handleFilterDelete(filter, event) {
    console.debug(arguments);
    event.stopPropagation();
    this.get('filterCollection').removeFilter(filter);
  }

  @action
  handleNewFilterSelectChange(filter) {
    const newFilter = this.get('filterCollection').createFilter(
      filter.getProperties('id', 'key', 'value', 'valueText')
    );

    this.set('newFilter', newFilter);
  }

  @action
  handleApplyFilter(filter) {
    if (this.get('openFilter')) {
      this.set('openFilter', null);
      this.onFilterClose(filter);
    }

    this.onFilterApply(filter);
  }

  @action
  addNewFilter() {
    const newFilter = this.get('newFilter');
    this.get('filterCollection').addFilter(newFilter);

    this.setProperties({
      newFilter: null,
      isAddingNew: false
    });
  }

  @action
  handleRemoveFilter(filter) {
    this.get('filterCollection').removeFilter(filter);
  }

  @action
  handleSearchChange(searchText) {
    console.debug(searchText);
    this.set('disableFilterControls', this.get('disableFiltersOnSearch') && !isEmpty(searchText));
    this.onSearchChange(searchText);

    if (this.get('searchableFilterIds')) {
      this.set('isSearchLoading', true);
      run.debounce(this, this.searchFilters, searchText, 300);
    }
  }

  @action
  handleItemAction(item) {
    console.debug(item);

    const filterCollection = this.get('filterCollection');
    const selectedFilters = this.get('filterCollection.selectedFilters');
    const aSelectedFilter = selectedFilters.findBy('key', item.filterId);

    if (aSelectedFilter) {
      aSelectedFilter.addSingleValue(item.id, item.label);
      this.onFilterApply(aSelectedFilter);
    } else {
      const newFilter = filterCollection.createFilter({
        key: item.filterId,
        value: [item.id],
        valueText: [item.label]
      });

      filterCollection.addFilter(newFilter);
    }
    this.resetSearchableFilterResults();
  }

  @action
  handleSearchInputKeyDown(e) {
    this.onSearchInputKeyDown(e);
  }
}
