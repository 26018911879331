import Component from '@ember/component';

/**
 * Repeats n times a (block) operation.
 */
export default Component.extend({
  /** @type {string} removes Ember wrapping container so you can use this with <td> etc. */
  tagName: '',

  value: Promise.resolve(true),

  oldValue: null,

  _value: null,

  emptyText: '',

  isLoading: false,

  didReceiveAttrs() {
    this._super(...arguments);

    if (this.value !== this.oldValue) {
      this.set('isLoading', true);
      this.set('_value', null);

      if (this.value) {
        this.value.then((_) => {
          this.set('isLoading', false);
          return this.set('_value', _);
        });
      }

      this.set('oldValue', this.value);
    }
  }
});
