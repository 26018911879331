import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

@classic
export default class MealPlanningPreferenceProfileController extends Controller {
  /** @type {Object} */
  model = null;

  /** @type {task} */
  @(task(function* () {
    yield this.get('model.user').createPreferenceProfile();
    yield this.get('model.user').belongsTo('preferenceProfile').reload();

    this.set('model.preferenceProfile', this.get('model.user.preferenceProfile.content'));
  }).drop())
  createPreferenceProfileTask;

  @action
  handleCreatePreferenceProfileClick() {
    this.get('createPreferenceProfileTask').perform();
  }
}
