import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';

export const PredicateTypeConversionMap = Object.freeze({
  and: 'Rules::Predicates::And',
  or: 'Rules::Predicates::Or',
  not: 'Rules::Predicates::Not'
});

@classic
export default class RulesPredicate extends Model {
  /*
   * Attributes
   */
  @attr('predicate-type', { defaultValue: 'and' })
  predicateType;

  @attr('string')
  intentionDescription;

  /*
   * Relationships
   */
  @belongsTo('rules-rule', { inverse: 'predicates' })
  rule;

  @belongsTo('rules-predicate', { inverse: 'predicates' })
  predicate;

  @hasMany('rules-predicate', { inverse: 'predicate' })
  predicates;

  @hasMany('rules-fact', { inverse: 'predicate' })
  facts;
}
