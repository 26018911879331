import classic from 'ember-classic-decorator';
import FeedItemBuilder from './base';
import { capitalize } from '@ember/string';

@classic
export default class EmailLogsMessagesOrderBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);

    const emailType =
      version.getValue('messageType') || capitalize(version.getValue('type')).replace(/_/g, ' ');
    const recipients = version.getValue('recipients');
    const subject = version.getValue('subject');

    feedItem.icon = 'paper plane';
    feedItem.subject = `Foxee sent a '${emailType}' email`;
    feedItem.meta = [
      `Subject: ${subject}`,
      `To: ${recipients ? recipients.join(', ') : '(No recipients found...?)'}`
    ];
    feedItem.details = '';

    return feedItem;
  }
}
