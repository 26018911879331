import classic from 'ember-classic-decorator';
import { classNameBindings } from '@ember-decorators/component';
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { dasherize } from '@ember/string';

@classic
@classNameBindings(
  'stateCssClass',
  'fde-form-table-row',
  'showInline:fde-orders-order-state-display_inline'
)
export default class OrderStateDisplay extends Component {
  /** @type {string} Order state name*/
  state = null;

  /** @type {boolean} Whether to represent order state as a coloured square*/
  showBullet = false;

  /** @type {boolean} Whether to represent order state as a coloured name*/
  showName = false;

  /** @type {boolean} Whether to represent order state as a coloured label*/
  showLabel = false;

  /** @type {boolean} Whether to put order inline */
  showInline = false;

  /** @type {function} Builds a css class based on order state*/
  @computed('state')
  get stateCssClass() {
    return `fde-order-state-color_${dasherize(get(this, 'state') ?? '')}`;
  }
}
