import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';
import { next } from '@ember/runloop';

import { modelAction } from 'ember-custom-actions';
import { task } from 'ember-concurrency';
import { camelizeObject } from 'renard/transforms/object';

const NAMES = {
  ge: 'At least',
  le: 'At most',
  eq: 'Exactly'
};

@classic
export default class MealPlanningRestaurantConstraint extends Model {
  /*
   * Attributes
   */
  @attr('string')
  name;

  @attr('number', { defaultValue: 1 })
  value;

  @attr('string', { defaultValue: 'ge' })
  constraintType;

  // TODO refactor to polymorphic owner
  @belongsTo('meal-planning-template', { inverse: 'restaurantConstraints' })
  template;

  @belongsTo('meal-planning-instance', { inverse: 'restaurantConstraints' })
  instance;

  @belongsTo('meal-planning-event', { inverse: 'restaurantConstraints' })
  event;

  @belongsTo('meal-planning-requirement', { inverse: 'restaurantConstraint' })
  requirement;

  @hasMany('restaurant')
  restaurants;

  /** @Creates the requirement group */
  createRequirement = modelAction('create-requirement', {
    method: 'POST',
    pushToStore: true,
    responseType: 'object'
  });

  @computed('constraintType', 'name', 'value', 'restaurants.[]', 'requirement.description')
  get description() {
    const requirementDescription = this.get('requirement.description');
    const description = requirementDescription
      ? `that serves: ${requirementDescription}`
      : `from: ${(this.get('restaurants') || []).mapBy('name')}`;

    return `${NAMES[this.get('constraintType')]} ${this.get('value')} restaurant(s) ${description}`;
  }

  @computed('restaurants.[]', 'requirement')
  get isNotConfigured() {
    return this.get('restaurants.length') === 0 && this.get('requirement.content') === null;
  }

  get isEventConstraint() {
    return !this.get('instance.mealPlanningReservations');
  }

  @computed('searchResults')
  get isSatisfied() {
    if (!this.searchResults) {
      return false;
    }

    // The search results returns two different types - an object an array - so we need to normalize them
    let queryIds;

    if (this.searchResults.restaurants) {
      queryIds = this.searchResults.restaurants.map((r) => r.id);
    } else {
      queryIds = this.searchResults.map((r) => r.get('id')) ?? [];
    }

    const resos =
      this.get('instance.mealPlanningReservations') ?? this.get('event.reservations') ?? [];
    const restaurantIds = resos.map((r) => r.restaurant.get('id'));
    const intersection = this.calculateInterSection(restaurantIds, queryIds);

    return intersection.length >= this.get('value');
  }

  @task
  async searchTask(area) {
    const requirement = await this.requirement;
    next(() => this.notifyPropertyChange('searchResults'));

    if (requirement) {
      let results = await requirement.search({ area });
      results = camelizeObject(results);
      results.restaurants.forEach((r) => (r.id = r.key.toString()));
      return results;
    } else {
      return this.restaurants;
    }
  }

  calculateInterSection(first, second) {
    return first.filter((f) => second.includes(f));
  }

  /** @type {{restaurants: []}} */
  @alias('searchTask.lastSuccessful.value')
  searchResults;

  validations = {
    restaurants: {
      custom: {
        validation(key, value, model) {
          return !model.get('isNotConfigured');
        },
        message(_key, _value, _model) {
          return `A restaurant requirement must either have some number of restaurants, or a requirement.`;
        }
      }
    }
  };
}
