import classic from 'ember-classic-decorator';
import { equal } from '@ember/object/computed';
import Model, { attr, hasMany } from 'renard/models/foodee';
import { modelAction } from 'ember-custom-actions';

@classic
export default class MealPlanningPreferenceProfile extends Model {
  /*
   * Attributes
   */

  @attr('number')
  searchBudget;

  @attr('boolean')
  searchBudgetEnabled;

  /*
   * Relationships
   */
  @hasMany('restaurant', { async: false })
  lovedRestaurants;

  @hasMany('restaurant', { async: false })
  hatedRestaurants;

  @hasMany('tag', { async: false })
  lovedCuisineTypes;

  @hasMany('tag', { async: false })
  hatedCuisineTypes;

  @hasMany('food-type')
  foodTypes;

  @hasMany('dietary-tags')
  dietaryTags;

  @hasMany('dietary-tags')
  allergyTags;

  @hasMany('meal-planning-requirement')
  requirements;

  @hasMany('meal-planning-requirement-group')
  requirementGroups;

  menuItems = null;

  @equal('profileType', 'team')
  isTeamType;

  @equal('profileType', 'regular')
  isRegularType;

  /** @Creates the requirement group */
  createRequirementGroup = modelAction('create-requirement-group', {
    method: 'POST',
    pushToStore: true,
    responseType: 'object'
  });

  /** @Creates the requirement group */
  createRequirement = modelAction('create-requirement', {
    method: 'POST',
    pushToStore: true,
    responseType: 'object'
  });

  search = modelAction('search', {
    method: 'GET',
    pushToStore: false
  });
}
