import classic from 'ember-classic-decorator';
import { mapBy, alias } from '@ember/object/computed';
import { attr, belongsTo, hasMany, fragment } from 'renard/models/foodee';
import EmberObject, { computed } from '@ember/object';
import { modelAction } from 'ember-custom-actions';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import Planable from './meal-planning-planable';
import { ReservationState } from 'renard/transforms/reservation-state';

const DAYS = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday'
};

@classic
class DayAdapter extends EmberObject {
  id = null;
  events = [];

  // isEmpty: computed.empty('events'),

  isTemplate = false;

  getDate(startOfWeek) {
    return moment(startOfWeek).add(this.get('id'), 'days').toDate();
  }

  @computed('events.@each.size')
  get reservations() {
    return this.get('events')
      .map((_) => _.get('reservations').toArray())
      .flat();
  }

  /** @type {boolean} */
  @computed('events.[]', 'reservations.@each.state')
  get counts() {
    return this.get('reservations').reduce((acc, reservation) => {
      const key = reservation.get('state') || 'unreserved';

      acc[key] = (acc[key] || 0) + 1;

      return acc;
    }, {});
  }

  /** @type {String} */
  @computed('counts')
  get countsLabel() {
    if (this.get('reservations.length')) {
      return Object.entries(this.get('counts'))
        .map(([k, v]) => `${k}: ${v}`)
        .join(' ');
    } else {
      return 'none';
    }
  }
}

@classic
export default class MealPlanningInstance extends Planable {
  @service ajax;
  @service modals;

  /*
   * Attributes
   */
  @attr('date')
  createdAt;

  @attr('date')
  updatedAt;

  @attr('string')
  name;

  // plan: fragment('fragments/meal-planning/plan', {defaultValue: {days: []}}),
  @attr('date-only')
  startOfWeek;

  @attr('boolean')
  buildGroupOrders;

  @attr('number')
  newWeight;

  @attr('number')
  popularityWeight;

  @attr('number')
  favWeight;

  @attr('number')
  lastWeekWeight;

  @attr('number')
  lastWeekOffset;

  @attr('number')
  maxUsesPerWeek;

  @attr('number')
  maxUsesPerDay;

  @attr('number')
  maxUsesPerMeal;

  @attr('number')
  smallestLeadTime;

  @attr('number')
  teamMembersCount;

  @attr('boolean')
  allowRepeats;

  @attr('boolean')
  useTeamProfiles;

  @attr('boolean')
  ignoreEmptyConstraints;

  @attr('boolean')
  isRecurring;

  @attr('string')
  jid;

  @attr('boolean')
  allowNewRecommendations;

  @attr('boolean')
  allowInternal;

  @attr('number')
  warningCount;

  activeTab = 'Plan';

  /*
   * Relationships
   */
  @belongsTo('client')
  client;

  @belongsTo('area')
  area;

  @belongsTo('meal-planning-template')
  mealPlanningTemplate;

  @hasMany('meal-planning-restaurant-constraint', { inverse: 'instance' })
  restaurantConstraints;

  @belongsTo('user')
  defaultOrderOwner;

  @belongsTo('order')
  orderTemplate;

  @hasMany('meal-planning-reservation')
  mealPlanningReservations;

  @hasMany('meal-planning-event')
  events;

  @belongsTo('meal-planning-preference-profile')
  preferenceProfile;

  @hasMany('meal-planning-log-record', { inverse: 'planable' })
  logRecords;

  @hasMany('order')
  orders;

  @hasMany('user')
  teamMembers;

  @attr('boolean')
  allowExtendedRadius;

  @attr('boolean')
  allowBeyondExtendedRadius;

  @fragment('fragments/meal-planning/objectives')
  objectives;

  @alias('mealPlanningReservations')
  reservations;

  @computed
  get label() {
    return `${this.get('name')}#${this.get('id')} date: ${moment(this.get('startOfWeek')).format(
      'MMMM Do YYYY'
    )}`;
  }

  get isEmpty() {
    return this.reservations.length === 0;
  }

  /**
   * Overridden to align with the old plan interface
   */
  @computed
  get plan() {
    return {
      days: new Array(7).fill('').map((_, id) =>
        DayAdapter.create({
          id,
          name: DAYS[id],
          events: this.get('events').filter(
            (event) => id === moment(event.get('deliverAt')).isoWeekday() - 1
          )
        })
      ),
      slots: []
    };
  }

  /** @type {Reservation[]} */
  @computed('mealPlanningReservations.@each.order')
  get unconvertedReservations() {
    return (this.get('mealPlanningReservations') || [])
      .filter((_) => _.get('state') !== 'cancelled')
      .filter((_) => !_.get('order.content'));
  }

  /** @type {Reservation[]} */
  @computed('mealPlanningReservations.@each.order')
  get convertedReservations() {
    return (this.get('mealPlanningReservations') || []).filter((_) => _.get('order.content'));
  }

  @mapBy('convertedReservations', 'order')
  ordersFromReservations;

  @computed('mealPlanningReservations.@each.state')
  get canBulkUpdate() {
    return this.get('mealPlanningReservations').mapBy('state').uniq().get('length') === 1;
  }

  @computed('mealPlanningReservations.@each.state')
  get canQuote() {
    return this.state.isConverted;
  }

  @computed('mealPlanningReservations.@each.state')
  get state() {
    const reservations = this.get('mealPlanningReservations').mapBy('state');
    if (reservations.some((_) => _.isDraft)) {
      return ReservationState.Draft;
    }

    if (reservations.some((_) => _.isQuoted)) {
      return ReservationState.Quoted;
    }

    if (reservations.some((_) => _.isRejected)) {
      return ReservationState.Rejected;
    }

    if (reservations.some((_) => _.isSubmitted)) {
      return ReservationState.Submitted;
    }

    if (reservations.some((_) => _.isConfirmed)) {
      return ReservationState.Confirmed;
    }

    if (reservations.some((_) => _.isConverted)) {
      return ReservationState.Converted;
    }

    if (reservations.some((_) => _.isCancelled)) {
      return ReservationState.Cancelled;
    }

    return '';
  }

  @computed
  get humanize() {
    return `Meal Plan for ${this.get('client.accountName')} for the week of ${moment(
      this.get('startOfWeek')
    ).format('MMMM Do YYYY')}`;
  }

  get allWarningsCount() {
    /*
     * Note: I'm not the biggest fan of these implementations. EmberArray/EmberData
     * have some quirkks that I was working against. Open to suggestions.
     */
    return this.get('reservations')
      .filter((r) => r?.hasSoftWarnings)
      .reduce((acc, _) => acc + _.softWarningCount, 0);
  }

  get allErrorsCount() {
    /*
     * Note: Same thing as above.
     */
    const reservationErrors = this.get('reservations').reduce((acc, _) => acc + _.errorsCount, 0);

    const eventErrors = this.get('events')
      .mapBy('restaurantConstraints')
      .filter((_) => _.length)
      .reduce((acc, _) => {
        return acc + _.filter((_) => !_.isSatisfied).length;
      }, 0);

    const constraintErrors = this.get('restaurantConstraints').filter((_) => !_.isSatisfied).length;

    return reservationErrors + constraintErrors + eventErrors;
  }

  @tracked actionVerb;

  get jobTitle() {
    return `${this.actionVerb} - ${this.name}`;
  }

  _planAsync = modelAction('plan-async', { method: 'POST' });
  _publish = modelAction('publish-async', { method: 'POST' });
  _submit = modelAction('submit-async', { method: 'POST' });
  _reject = modelAction('reject-async', { method: 'POST' });
  _confirm = modelAction('confirm-async', { method: 'POST' });
  _convert = modelAction('convert-async', { method: 'POST' });
  _cancel = modelAction('cancel-async', { method: 'POST' });

  planAsync = () => this._setActionVerbAndRun('Planning', this._planAsync);
  publish = () => this._setActionVerbAndRun('Publishing', this._publish);
  submit = () => this._setActionVerbAndRun('Submitting', this._submit);
  reject = () => this._setActionVerbAndRun('Rejecting', this._reject);
  confirm = () => this._setActionVerbAndRun('Confirming', this._confirm);
  cancel = () => this._setActionVerbAndRun('Cancelling', this._cancel);
  convert = () => this._setActionVerbAndRun('Converting', this._convert);

  _setActionVerbAndRun(verb, method) {
    this.actionVerb = verb;
    method.bind(this)();
  }

  reloadWith(include) {
    return this.store.findRecord('meal-planning-instance', this.get('id'), {
      reload: true,
      include: include
    });
  }

  addRestaurantConstraint(constraint, _templateOrInstance) {
    constraint.set('instance', this);
    return constraint.save();
  }

  async removeRestaurantConstraint(constraint, _templateOrInstance) {
    return constraint.destroyRecord();
  }

  async requestNewRestaurant() {
    const area = await this.get('area');
    return area.plan({
      instance_ids: [this.get('id')]
    });
  }

  @task
  async calculateWarnings() {
    const instanceConstraints = (await this.restaurantConstraints) ?? [];

    const eventConstraints = this.events?.reduce((acc, e) => {
      e.restaurantConstraints.forEach((c) => acc.push(c));
      return acc;
    }, []);

    const instanceConstraintSearchTasks = instanceConstraints.map((c) =>
      c.searchTask.perform(this.area)
    );
    const eventConstraintSearchTasks = (eventConstraints ?? []).map((c) =>
      c.searchTask.perform(this.area)
    );

    await Promise.all([...instanceConstraintSearchTasks, ...eventConstraintSearchTasks]);
  }

  sendReviewEmail = modelAction('send-review-email', { method: 'POST' });
  sendOrdersAreLiveEmail = modelAction('send-weeks-orders-ready-email', { method: 'POST' });
  updateOrderTemplate = modelAction('update-order-template', { method: 'PUT' });

  createPreferenceProfile = modelAction('create-preference-profile', {
    method: 'POST',
    pushToStore: true
  });

  search = modelAction('search', {
    method: 'GET',
    pushToStore: false
  });

  jobDone() {
    this.calculateWarnings.perform();
  }

  async reload(include = '') {
    const instance = await this.store.findRecord('meal-planning-instance', this.get('id'), {
      reload: true,
      include: include
    });

    this.calculateWarnings.perform();

    return instance;
  }
}
