import classic from 'ember-classic-decorator';
import Route, { NUMBER_OF_LOADED_ORDERS } from '../index/route';

@classic
export default class DeliveriesRoute extends Route {
  templateName = 'logged-in.logistics.index';

  /**
   * Formats the url params to JSONAPI Resources compatible query params for the order endpoint.
   * @params {object} params Parameters
   * @returns {object} A JSONAPI Resources compatible query params object
   */
  formatQueryParams(params) {
    const { areas, filterDate, pageLimit, pageOffset, id } = params;

    return {
      filter: {
        id,
        areas,
        deliverOn: filterDate,
        rejectState: 'draft,cancelled'
      },

      page: {
        limit: pageLimit,
        offset: pageOffset
      },

      sort: 'deliverAt,id',
      include: 'area,driver,courier'
    };
  }

  /*
   * Fetch first page of orders to help load the interface faster.
   */
  fetchFirstPageOfOrders(params) {
    params.pageOffset = 0;
    params.pageLimit = NUMBER_OF_LOADED_ORDERS;
    return this.store.query('logistics-delivery', this.formatQueryParams(params));
  }

  /*
   * If the remainingOrders promise beats the model promise, the controller may not have been
   * initiated (first page load), wait for the transition to complete to ensure controller's
   * instantiation and only then should you set remainingOrders on the controller.
   */
  fetchRemainingOrders(params) {
    const controller = this.controllerFor('logged-in.logistics.index');

    params.pageOffset = NUMBER_OF_LOADED_ORDERS;
    params.pageLimit = controller.get('pageLimit') - NUMBER_OF_LOADED_ORDERS;

    /*
     * Has to be cleared early, because fetch remaining is outside the normal
     * model -> setupController flow (main loader could finish before old remaining orders are
     * cleared)
     */
    controller.set('remainingOrders', []);

    this.store.query('logistics-delivery', this.formatQueryParams(params)).then((orders) => {
      controller.setProperties({
        remainingOrders: orders,
        isRemainingOrdersLoading: false
      });
    });
  }

  /**
   * @type {string} id
   * When an order is created through pusher, the order created action calls this method to fetch
   * the order with the current query filter params and append it onto the fetchedOrders array.
   */
  fetchOrderWithQueryParams(id) {
    const controller = this.get('controller');
    const params = this.getCurrentQueryParams();

    params.id = id;

    this.store.query('logistics-delivery', this.formatQueryParams(params)).then((orders) => {
      controller.get('fetchedOrders').pushObjects(orders.toArray());
    });
  }
}
