import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

import { action } from '@ember/object';

@classic
export default class EditRoute extends Route {
  model(params) {
    return this.store.findRecord('area', params.area_id, {
      include: 'invoicing-tax-rates,delivery-fee-tax-rate,meal-planning-requirements'
    });
  }

  // Subscribe to pusher events,
  // the channelName is defined in the controller.
  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setupPusher();
  }

  // Unsubscribe from the pusher channels we've been listening to.
  // Clean up when we leave. We probably don't want  to still be receiving events.
  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);

    controller.teardownPusher();
  }

  @action
  modelChanged() {
    this.refresh();
  }
}
