import classic from 'ember-classic-decorator';
import Helper from '@ember/component/helper';
import accounting from 'accounting';

/**
 * @param {number} value
 * @param {?Object} options
 * @returns {string}
 */
export function formatCurrency([value], options = {}) {
  const isCents = Object.keys(options).includes('isCents') ? options.isCents : true;
  const prefix = options.prefix || false;
  const amount = isCents ? value / 100 : value;
  let formattedAmount = accounting.formatMoney(amount);

  if (prefix) {
    formattedAmount = formattedAmount.replace('$', prefix);
  }

  return formattedAmount;
}

@classic
export default class FormatCurrency extends Helper {
  compute = formatCurrency;
}
