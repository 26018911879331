import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class MenuGroup extends Application {
  attrs = {
    menuItems: {
      serialize: true
    },
    foodTypes: {
      serialize: true
    },
    mealTypes: {
      serialize: true
    }
  };
}
