import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias, equal } from '@ember/object/computed';
import Model, { attr } from 'renard/models/foodee';
import { modelAction } from 'ember-custom-actions';

@classic
export default class InvoicingLedgerItem extends Model {
  @attr('date')
  issueDate;

  @attr('string')
  currency;

  @attr('string')
  totalAmount;

  @attr('string')
  description;

  @attr('date')
  dueDate;

  @attr('object')
  customFields;

  @attr('object')
  senderDetails;

  @attr('object')
  pdf;

  @attr('string')
  xeroId;

  @attr('string')
  identifier;

  @attr('string')
  ledgerType;

  @attr('string')
  status;

  readonly = true;
  noLineItems = true;

  @equal('ledgerType', 'ClientInvoice')
  canRefund;

  @alias('totalAmount')
  refundableAmount;

  log = null;

  @computed('ledgerType')
  get typeName() {
    return this.get('ledgerType');
  }

  @computed('ledgerType', 'identifier')
  get title() {
    return `${this.get('ledgerType')}: ${this.get('identifier')}`;
  }

  credit = modelAction('credit', { method: 'POST' });
  sync = modelAction('sync', { method: 'POST' });

  validations = {
    xeroId: {
      format: {
        with: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
        allowBlank: true,
        message: 'must be a valid uuid'
      }
    }
  };
}
