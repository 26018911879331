import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { run, next } from '@ember/runloop';
import $ from 'jquery';
import { isEmpty } from '@ember/utils';

@classic
@tagName('')
export default class InputControl extends Component {
  /** @type {string} */
  inputType = 'text';

  // labels

  /** @type {string} */
  leftLabel = '';

  /** @type {string} */
  leftLabelColor = '';

  /** @type {Function?} */
  leftLabelAction = null;

  /** @type {string} */
  rightLabel = '';

  /** @type {string} */
  rightLabelColor = '';

  /** @type {Function?} */
  rightLabelAction = null;

  /** @type {boolean} */
  disabled = false;

  /** @type {string} */
  @computed('leftLabel', 'rightLabel')
  get labelClass() {
    const ret = [];

    if (this.get('leftLabel')) {
      ret.push('left');
    }

    if (this.get('rightLabel')) {
      ret.push('right');
    }

    if (ret.length > 0) {
      ret.push('labeled');
    }

    return ret.join(' ');
  }

  // icons

  /** @type {string} */
  @computed('leftIcon', 'rightIcon')
  get iconClass() {
    let ret = [];

    if (this.get('leftIcon')) {
      ret.push('left');
    }

    if (this.get('rightIcon')) {
      ret.push('right');
    }

    if (ret.length > 0) {
      ret.push('icon');
    }

    return ret.join(' ');
  }

  /** @type {string} */
  leftIcon = '';

  /** @type {boolean} */
  leftIconIsButton = false;

  /** @type {string} */
  rightIcon = '';

  /** @type {string} */
  placeholder = '';

  /** @type {string} */
  min = null;

  /** @type {string} */
  max = null;

  /** @type {string} */
  step = 'any';

  /** @type {number} */
  size = null;

  /** @type {number} */
  maxLength = null;

  /** @type {Function?} */
  onLeftIconClick = null;

  /** @type {Function?} */
  onRightIconClick = null;

  /** @type {string} optional input mask to use on the control, uses jQuery.mask plugin */
  inputMask = null;

  /** @type {?string} optional input mask options, uses jQuery.mask plugin */
  inputMaskOptions = null;

  /** @type {boolean} if this input control is read only */
  readonly = false;

  /** @type {boolean} if this input control is disabled */
  isDisabled = false;

  /** @type {boolean} if this input control is disabled */
  isFluid = false;

  /** @type {boolean} if this input control is transparent */
  isTransparent = false;

  /** @type {boolean} if this input control is required */
  isRequired = false;

  /** @type {boolean} if this input control yields to the right or left */
  yieldRight = true;

  /** @type {boolean} if this input control is handling money */
  isMoney = false;

  /** @type {boolean} if this input control is loading */
  isLoading = false;

  /** @type {string} control id */
  controlId = null;

  /** @type {number} debounce */
  debounce = null;

  /** @type {boolean} if set to false, will prevent change events on keyboard inputs */
  changeOnInputEvent = true;

  onClick() {}

  onChange() {}

  onEnter() {}

  onEsc() {}

  doSubmit() {}

  doReset() {}

  onBlur() {}

  onKeyUp() {}

  onKeyDown() {}

  onInput() {}

  onFocus() {}

  focus() {
    next(() => {
      const $input = $(`#${this.get('controlId')}`);
      $input.focus();
    });
  }

  _sendChange() {
    this.onChange(this.get('_value'));
  }

  _handleChange(value) {
    const debounce = this.get('debounce');
    this.set('_value', value);

    if (debounce) {
      run.debounce(this, this._sendChange, debounce);
    } else {
      this._sendChange();
    }
  }

  /**
   * Masks the current value of the input box.
   */
  _maskMoney() {
    const $input = $(`#${this.get('controlId')}`);
    $input.val((parseInt(this.get('value')) / 100).toFixed(2));
    $input.maskMoney('mask');
  }

  /**
   * Gets the value of the money input and unmasks it
   * @returns {string}
   */
  _unmaskMoney() {
    const $input = $(`#${this.get('controlId')}`);
    return ($input.maskMoney('unmasked')[0] * 100).toFixed(0);
  }

  didRender() {
    super.didRender(...arguments);
    if (this.get('isMoney')) {
      this._maskMoney();
    }
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    const inputMask = this.get('inputMask');
    const $input = $(`#${this.get('controlId')}`);

    if (this.get('isMoney')) {
      //initalizes component
      $input.maskMoney();
    } else if (inputMask) {
      const inputMaskOptions = this.get('inputMaskOptions');
      inputMaskOptions ? $input.mask(inputMask, inputMaskOptions) : $input.mask(inputMask);
    }
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    if (this.get('inputMask')) {
      $(`#${this.get('controlId')}`).unmask();
    }

    // nuclear option
    $(`#${this.get('controlId')}`).off();
  }

  _coerceNumberIfNumeric(value) {
    if (!isEmpty(value) && this.get('inputType') === 'number') {
      value = value.includes('.') ? parseFloat(value) : parseInt(value, 10);
    }
    return value;
  }

  @action
  handleClick(e) {
    this.onClick(e);
  }

  @action
  handleLabelClick(action) {
    if (action) {
      action();
    }
  }

  @action
  handleBlur(e) {
    // TODO: for some reason with jQuery money mask, the `input` and `change` events don't fire. Get rid of jQueryMM
    // Not ideal, but a good work around for now
    if (this.isMoney) {
      this.handleChangeEvent(e);
    }

    let value = e.target.value;

    value = this._coerceNumberIfNumeric(value);

    this.onBlur(value, e);
  }

  @action
  handleFocus(e) {
    this.onFocus(e);
  }

  @action
  handleKeyUp(e) {
    // TODO: for some reason with jQuery money mask, the `input` and `change` events don't fire. Get rid of jQueryMM
    // Not ideal, but a good work around for now
    if (this.isMoney) {
      this.handleInput(e);
    }

    if (e.code === 'Enter') {
      this.onEnter();
      this.doSubmit();
    }

    if (e.code === 'Escape') {
      this.onEsc();
    }

    this.onKeyUp(e);
  }

  @action
  handleInput(e) {
    let value = e.target.value;

    if (this.get('isMoney')) {
      value = this._unmaskMoney();
    }
    value = this._coerceNumberIfNumeric(value);

    this.onInput(value);

    if (this.get('changeOnInputEvent')) {
      this._handleChange(value);
    }
  }

  @action
  handleKeyDown(e) {
    this.onKeyDown(e);
  }

  @action
  handleChangeEvent(e) {
    let value = e.target.value;

    if (this.get('isMoney')) {
      value = this._unmaskMoney();
    }

    value = this._coerceNumberIfNumeric(value);

    this._handleChange(value);
  }
}
