import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

/**
 * A component for tabbed navigation in star fox
 */
@classic
@tagName('')
export default class TabNav extends Component {
  /** @type {boolean} whether or not the tab is full screen */
  isFullScreen = false;
}
