import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class Event extends Model {
  @attr('string')
  event;

  @attr('date')
  eventTimestamp;

  // Relationships
  @belongsTo('email-message')
  emailMessage;

  // Computed Properties
  @computed('event')
  get eventName() {
    return this.get('event') || 'no event';
  }
}
