import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@tagName('')
export default class ListedOrder extends Component {
  /**
   * Single order to slot into the table row
   * @type {Object}
   */
  order = null;

  /** @type {boolean} */
  useRestaurantTotal = false;

  /**
   * ISO time zone of an order's area
   * @type {string}
   */
  @alias('order.area.isoTimeZone')
  isoTimeZone;

  /**
   * Whether or not to display the table row with narrow stylings
   * @type {Boolean}
   */
  narrowMode = false;
}
