import { get } from '@ember/object';

/**
 * Groups an Array of Ember Objects by a given attr
 * @param  {Array} arr - An array of Ember Objects
 * @param  {String} attr - An attribute to group the objects by
 * @return {Array} Returns an array of objects that have a label, and values for the grouped objects
 */
export function groupBy(arr, attr) {
  const groupedHash = arr.reduce((acc, obj) => {
    const label = get(obj, attr);

    if (!acc[label]) {
      acc[label] = [];
    }
    acc[label].push(obj);
    return acc;
  }, {});

  return Object.keys(groupedHash).map((label) => {
    return {
      label,
      values: groupedHash[label]
    };
  });
}
