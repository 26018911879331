import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { Time } from '../../../../transforms/time';

@classic
@classNames('fde-calendar-control ui calendar')
export default class CalendarControl extends Component {
  /** @type{string} **/
  inputClassNames = '';

  /** @type{string} **/
  type = 'time';

  icon = '';

  /** @type {Date} **/
  value = null;

  /** @type {boolea} **/
  disableMinute = false;

  /** @type {boolea} **/
  disableMonth = false;

  /** @type {boolea} **/
  disableYear = false;

  onChange(_value) {}

  /** @type{string} **/
  placeholder = '';

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.$().calendar({
      type: this.get('type'),
      format: this.get('format'),
      disableMinute: this.get('disableMinute'),
      disableMonth: this.get('disableMonth'),
      disableYear: this.get('disableYear'),

      onChange: (date, _text, _mode) => {
        if (this.get('type') === 'time') {
          // TODO extend to support timezones
          this.onChange(new Time(date));
        } else {
          // TODO extend to support timezones
          this.onChange(date);
        }
      }
    });

    // Not sure why it's initializing wrong but this hack does the trick
    this.$('.ui.popup').addClass('transition');
    this.$('.ui.popup').addClass('hidden');
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.$().off();
  }
}
