import Component from '@ember/component';
import { action, computed, observer } from '@ember/object';
import { on } from '@ember/object/evented';
import { run } from '@ember/runloop';
import moment from 'moment-timezone';

export default Component.extend({
  classNames: ['sf-time-select-tz'],
  row: null,
  column: null,
  stepAmount: '5',
  stepUnit: 'minutes',
  selectedTime: null,

  timeIntervals: [
    {
      startTime: '05:00',
      endTime: '23:00'
    }
  ],

  disabledTimeIntervals: null,
  timeZone: null,
  dateTimeValue: null,
  showFacade: true,
  reverseTimes: false,

  selectedTimeText: computed('selectedTime', function () {
    const selectedTime = this.get('selectedTime');

    if (selectedTime) {
      const timeObj = this.getTimeByTimeValue(selectedTime);
      return timeObj ? timeObj.text : '';
    }

    return '';
  }),

  isTimeStepDisabled(timeStep) {
    const disabledTimeIntervals = this.get('disabledTimeIntervals') || [];

    for (var i = 0; i < disabledTimeIntervals.length; i++) {
      var starTime = moment(disabledTimeIntervals[i].startTime, 'HH:mm');
      var endTime = moment(disabledTimeIntervals[i].endTime, 'HH:mm');

      if (timeStep.isSameOrAfter(starTime) && timeStep.isBefore(endTime)) {
        return true;
      }
    }
    return false;
  },

  timesHasTimeValue(timeValue) {
    let hasValue = false;
    this.get('times').forEach((time) => {
      if (!time.disabled && time.value === timeValue) {
        hasValue = true;
      }
    });
    return hasValue;
  },

  getTimeByTimeValue(timeValue) {
    return this.get('times').find((time) => !time.disabled && time.value === timeValue);
  },

  roundToNearestStep(minutes) {
    const stepAmount = this.get('stepAmount');
    return Math.round(minutes / stepAmount) * stepAmount;
  },

  isInvalid: computed('times', 'selectedTime', function () {
    if (this.get('selectedTime') == null) {
      return true;
    }
    return !this.timesHasTimeValue(this.get('selectedTime'));
  }),

  times: computed('timeIntervals', 'disabledTimeIntervals', function () {
    const times = [];

    const timeIntervals = this.get('timeIntervals');

    timeIntervals.forEach((timeInterval) => {
      const startTime = timeInterval.startTime;
      const endTime = timeInterval.endTime;

      const timeStep = moment(startTime, 'HH:mm');
      const lastTime = moment(endTime, 'HH:mm').add(this.get('stepAmount'), this.get('stepUnit'));

      while (timeStep.isBefore(lastTime)) {
        const timeDisabled = this.isTimeStepDisabled(timeStep);
        const timeValue = timeStep.format('HH:mm');
        const timeText = timeDisabled
          ? timeStep.format('hh:mm a') + ' (Unavailable)'
          : timeStep.format('hh:mm a');
        times.push({
          disabled: timeDisabled,
          value: timeValue,
          text: timeText
        });

        timeStep.add(this.get('stepAmount'), this.get('stepUnit'));
      }
    });

    if (this.get('reverseTimes')) {
      times.reverse();
    }

    return times;
  }),

  onSelectedTimeChange: observer('selectedTime', function () {
    if (this.get('selectedTime') == null) {
      return;
    }

    if (this.get('dateTimeValue')) {
      //Ensure that setting the time on the date object does not override
      //the date.
      const localizedDateTime = moment(
        moment.tz(this.get('dateTimeValue'), this.get('timeZone')).format('YYYY-MM-DDTHH:mm:00')
      );

      const time = moment(this.get('selectedTime'), 'HH:mm');
      localizedDateTime.set('hour', time.hour());
      localizedDateTime.set('minute', time.minute());

      this.set(
        'dateTimeValue',
        moment.tz(localizedDateTime.format('YYYY-MM-DDTHH:mm:00'), this.get('timeZone')).format()
      );
    } else {
      this.set('dateTimeValue', moment(this.get('selectedTime'), 'HH:mm').format());
    }
  }),

  onTimesChange: observer('times', function () {
    if (!this.timesHasTimeValue(this.get('selectedTime'))) {
      this.set('selectedTime', null);
    }
  }),

  onDateTimeValueChange: on(
    'init',
    observer('dateTimeValue', function () {
      const dateTimeValue = this.get('dateTimeValue');

      if (!dateTimeValue) {
        return;
      }

      const localizedDateTime = moment(
        moment.tz(this.get('dateTimeValue'), this.get('timeZone')).format('YYYY-MM-DDTHH:mm:00')
      );

      const nearestStepMinute = this.roundToNearestStep(localizedDateTime.minutes());
      const newSelectedTime = localizedDateTime.minutes(nearestStepMinute).format('HH:mm');
      this.set('selectedTime', newSelectedTime);
    })
  ),

  click(event) {
    event.stopPropagation();
  },

  onChange: action(function (component, timeValue) {
    this.set('selectedTime', timeValue);
    this.onChange(timeValue);
    run.next(() => {
      this.set('showFacade', true);
    });
  }),

  onFacadeClick: action(function () {
    if (this.get('showFacade')) {
      this.set('showFacade', false);
      run.next(() => {
        this.$('.sf-time-select-tz_dropdown').click();
      });
    }
  })
});
