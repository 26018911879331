import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';

import { action } from '@ember/object';

@classic
export default class ReleaseNotesController extends Controller {
  @action
  add() {
    this.set('newReleaseNote', this.store.createRecord('release-note'));
  }

  @action
  reset() {
    this.set('newReleaseNote', null);
    this.send('reloadReleaseNotes');
  }
}
