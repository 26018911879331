import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { computed } from '@ember/object';
import moment from 'moment-timezone';

@classic
@classNames('sf-logistics-pickup-delivery-time-table-cell')
export default class PickupDeliveryTimeTableCell extends Component {
  row = null;
  column = null;
  value = null;

  @computed('column.label')
  get pickupSelect() {
    let label = this.get('column.label') || '';
    return label.toLowerCase() == 'pickup time';
  }

  @computed('row.pickedUpAt', 'row.deliveredAt', 'pickupSelect')
  get actualDropTime() {
    if (this.get('pickupSelect')) {
      return this.formatTime(this.get('row.pickedUpAt'));
    } else {
      return this.formatTime(this.get('row.deliveredAt'));
    }
  }

  formatTime(date) {
    if (date == null) {
      return;
    }

    return moment(date).format('h:mmA');
  }

  pickupTimeUpdated() {}
}
