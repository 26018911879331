import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias, oneWay } from '@ember/object/computed';
import { action } from '@ember/object';
import Controller from '@ember/controller';
import { run } from '@ember/runloop';
import { Phones } from 'star-fox/transforms/phone';
import { VehicleTypes } from 'star-fox/transforms/vehicle';
import { task, timeout } from 'ember-concurrency';

@classic
export default class ShowController extends Controller {
  queryParams = ['pageLimit', 'pageOffset', 'searchText', 'sort'];

  @service
  notify;

  /** @type {Courier} */
  @alias('model.courier')
  courier;

  /** @type {number} */
  @oneWay('model.drivers.meta.recordCount')
  recordCount;

  /** @type {number} pageLimit - records per page */
  pageLimit = 20;

  /** @type {number} pageOffset - current page */
  pageOffset = 0;

  /** @type {string} */
  sort = '';

  /** @type {Phone[]} */
  phones = Phones;

  /** @type {VehicleType[]} */
  vehicles = VehicleTypes;

  /** @type {?User[]} */
  searchedDrivers = null;

  doSearch() {
    this.setProperties({
      searchText: this.get('_searchText'),
      pageOffset: 0
    });
  }

  /**
   * Queries users by an email address, and sets the searchedDrivers attribute
   * @param {string} email
   */
  @(task(function* (email) {
    yield timeout(250);

    if (email) {
      yield this.store
        .query('user', {
          filter: { email },
          page: {
            limit: 10,
            offset: 0
          }
        })
        .then((users) => this.set('searchedDrivers', users && users.toArray()));
    }
  }).restartable())
  _queryDriversByEmail;

  /**
   * Closes the new driver form, refreshes the model, and notifies the User
   * @param {User} driver
   * @private
   */
  _handleCreatedDriver(driver) {
    this.set('creatingNewDriver', false);
    this.send('refreshModel');
    this.get('notify').success(`Added ${driver.get('email')} as a driver`);
  }

  @action
  handleSearchChange(searchText) {
    this.set('_searchText', searchText);

    // prevent multiple search
    run.debounce(this, this.doSearch, 400);
  }

  @action
  createNewDriver(driver) {
    const notify = this.get('notify');
    const email = driver.get ? driver.get('email') : driver;

    this.get('courier')
      .addDriver(email)
      .then((driver) => this._handleCreatedDriver(driver))
      .catch((_) => notify.error(`There was an issue in adding ${email} as a driver`));
  }

  @action
  queryForDrivers(email) {
    this.get('_queryDriversByEmail').perform(email);
  }

  @action
  unlinkDriver(driver) {
    const notify = this.get('notify');
    const courier = this.get('courier');
    const actionReference = `${driver.get('email')} from ${courier.get('name')}`;

    courier
      .removeDriver(driver)
      .then((_) => notify.success(`Removed ${actionReference}`))
      .catch((_) => notify.error(`There was an issue in removing ${actionReference}`));
  }
}
