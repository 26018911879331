import { helper as buildHelper } from '@ember/component/helper';

/**
 *
 * The built in query params helper takes a hash, thus preventing us from
 * being able to vary the keys programatically.
 *
 * This query params helper builds them in order with positional params, ie:
 *
 * fde-query-params key value key2 value2
 *
 *
 * @param params
 * @returns {Object}
 */
export function fdeQueryParams(params) {
  let ret = params[0];

  if (typeof ret !== 'object') {
    const keysAndValues = params.reduce(
      (acc, arg, index) => {
        if (index % 2 === 0) {
          acc.keys.push(arg);
        } else {
          acc.values.push(arg);
        }

        return acc;
      },
      { keys: [], values: [] }
    );

    ret = keysAndValues.keys.reduce((acc, key, index) => {
      acc[key] = keysAndValues.values[index];

      return acc;
    }, {});
  }

  return ret;
}

export default buildHelper(fdeQueryParams);
