import classic from 'ember-classic-decorator';
import { alias, sort } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { action, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

@classic
export default class FeatureFlagsController extends Controller {
  @controller('application')
  applicationController;

  queryParams = ['sort', 'search'];

  /** @type {Object[]} */
  splitViewStops = [
    { name: 'initial', value: 0 },
    { name: 'initial', value: 400 }
  ];

  rightPaneSize = 0;
  sort = 'flagId';
  selectedFeatureFlagDescription = null;

  @computed('sort')
  get sortBy() {
    const sort = this.get('sort') || '';
    const sortKeys = isEmpty(sort) ? [] : sort.split(',');
    return sortKeys.map((_) => _.replace(/-([a-zA-Z-_^,]+)/i, '$1:desc'));
  }

  @alias('model.subscriptionPlans')
  subscriptionPlans;

  @sort('model.featureFlagDescriptions', 'sortBy')
  sortedFeatureFlagDescriptions;

  searchedSubscriptionPlans = [];

  @action
  open(selected) {
    const currentSelectedFeatureFlagDescription = this.get('selectedFeatureFlagDescription');

    if (currentSelectedFeatureFlagDescription) {
      currentSelectedFeatureFlagDescription.save();
    }

    if (isEmpty(selected)) {
      this.set('rightPaneSize', 0);
      this.set('selectedFeatureFlagDescription', null);
    } else {
      this.set('selectedFeatureFlagDescription', selected.get('firstObject'));
      this.set('rightPaneSize', 400);
    }
  }
}
