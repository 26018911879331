import classic from 'ember-classic-decorator';
import FeedItemBuilder from './base';
import moment from 'moment-timezone';

@classic
export default class RestaurantBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);

    const destroyed = version.get('event') === 'destroy';
    const verb = destroyed ? 'opened' : 'closed';

    feedItem.color = destroyed ? 'green' : 'red';
    feedItem.icon = destroyed ? 'utensils' : 'ban';

    feedItem.subject = `${version.get('whodunnit')} ${verb} the restaurant on '${moment(
      version.getValue('date')
    ).format('MMMM Do YYYY')}'`;

    return feedItem;
  }
}
