import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { Promise } from 'rsvp';
import Component from '@ember/component';
import { action, computed, get } from '@ember/object';
import { task } from 'ember-concurrency';

@classic
@classNames('fde-order-menu')
export default class OrderMenu extends Component {
  /** @type {Menu} the currently active menu for the restaurant */
  activeMenu = null;

  /** @type {Menu} the current menu for the order */
  orderMenu = null;

  /** @type {SetMenu} */
  setMenu = null;

  /** @type {Order} */
  order = null;

  /** @type {MealPlanningRequirement} */
  menuFilter = null;

  /** @type {boolean} */
  readonly = false;

  /** @type {boolean} */
  hideLinks = false;

  /** @type {Function} functishowSearchon that refreshes all the model keys needed for the page */
  refreshModel() {}

  /** @type {boolean} */
  setMenuHasChanged = false;

  /** @type {boolean} */
  setMenuIsEmpty = true;

  /** @type {boolean} Alias for external or internal menu view */
  isShowingActiveMenu = true;

  /** @type {boolean} Toggles external or internal menu view */
  isShowingInternalMenu = false;

  /** @type {boolean} Toggles external or internal menu view */
  isShowingSetMenu = false;

  /** @type {number} */
  setMenuItemMassQty = null;

  /** @type {Service} */
  @service
  store;

  /**
   * @type {Menu{internal: true} | Menu{external: true}}
   * Returns internal or external menus
   */
  @computed('isShowingInternalMenu', 'orderMenu')
  get menuGroups() {
    const menuGroups = get(this, 'orderMenu.menuGroups') ?? [];

    //If we're looking for internal menu stuff, we need all menu groups internal or not, because
    //there could be internal menu items in external menu groups.
    return this.get('isShowingInternalMenu')
      ? menuGroups.filter((_) => _.get('internal'))
      : menuGroups.filter((_) => !_.get('internal'));
  }

  /** @type {string[]} */
  get activeSetMenuItemIds() {
    return Object.keys(this.get('setMenu.setMenuItems'));
  }

  shouldShowWholeMenu = false;
  searchRequirement = null;
  quoteBuilderRequirement = null;

  @computed('searchRequirement', 'menuFilter', 'quoteBuilderRequirement', 'shouldShowWholeMenu')
  get requirement() {
    if (!this.get('shouldShowWholeMenu')) {
      return (
        this.get('searchRequirement') ||
        this.get('quoteBuilderRequirement') ||
        this.get('menuFilter')
      );
    }
    return null;
  }

  @task(function* () {
    yield this.get('order').addMenuFilter();
    this.set('menuFilter', this.get('order.menuFilter.content'));
  })
  addMenuFilterTask;

  @task(function* () {
    yield this.get('menuFilter').destroyRecord();
    this.set('menuFilter', null);
  })
  removeMenuFilterTask;

  /** @type {function} Handles menu item click */
  onMenuItemClick() {}

  /** @type {function} separate callback for initiating a save after a debounce period */
  handleOrderItemSave() {}

  /** @type {function} Handles when an item is customized */
  onCustomItemClick() {}

  /** @type {function} Handles when a set menu item is customized */
  onSetMenuItemClick() {}

  /** @type {function} Handles when a set menu item qty input box is blured */
  onSetMenuItemQtyBlur() {}

  /** @type {function} Handles when a set menu changes should be cancelled */
  onSetMenuCancelClick() {}

  /** @type {function} Handles when a set menu changes should be saved */
  onSetMenuSaveClick() {}

  onSetMenuItemsUpdated() {}

  /** @type {boolean} */
  search = false;

  @action
  showSearch() {
    this.set('searchIsShowing', true);

    const searchRequirement = this.get('store').createRecord('meal-planning-requirement', {});
    searchRequirement.reset();

    this.set('searchRequirement', searchRequirement);
  }

  @action
  resetSearch() {
    this.set('searchRequirement', this.get('store').createRecord('meal-planning-requirement', {}));
    return Promise.resolve(true);
  }

  @action
  closeSearch() {
    this.set('searchIsShowing', false);
    this.set('searchRequirement', null);
  }

  searchItems() {
    return Promise.resolve(true);
  }

  @action
  showWholeMenu() {
    this.set('shouldShowWholeMenu', true);
  }

  @action
  showMenuByRequirement() {
    this.set('shouldShowWholeMenu', false);
  }

  @action
  clearQuoteBuilderRequirement() {
    this.set('quoteBuilderRequirement', null);
  }

  @action
  selectQuoteBuilderRequirement(requirement) {
    this.set('quoteBuilderRequirement', requirement);
  }

  @action
  handleMenuItemClick(item) {
    // if it has at least one item that is choosable
    const hasChoosableOptions = item.get('menuOptionGroups').any((_) => _.get('isChoosable'));

    if (hasChoosableOptions) {
      console.debug('[orders/order-menu] clicked to add custom item', item);
      this.onCustomItemClick(item);
    } else {
      console.debug('[orders/order-menu] clicked to add menu item', item);
      this.onMenuItemClick(item);
    }
  }

  @action
  handleSetMenuItemClick(item) {
    console.debug('[orders/order-menu] clicked to add set menu item', item);
    this.onSetMenuItemClick(item);
  }

  @action
  handleSetMenuItemQtyClick(e) {
    e.stopImmediatePropagation();
  }

  @action
  handleSetMenuItemQtyBlur(item, qty) {
    console.debug('[orders/order-menu] blured set menu item qty', item, qty);
    this.onSetMenuItemQtyBlur(item, qty);
  }

  @action
  handleSetMenuSaveClick() {
    console.debug('[orders/order-menu] save set menu');
    this.onSetMenuSaveClick();
  }

  @action
  handleSetMenuCancelClick() {
    console.debug('[orders/order-menu] cancel set menu');
    this.onSetMenuCancelClick();
  }

  @action
  handleAddMenuFilterToOrderButtonClick() {
    this.get('addMenuFilterTask').perform();
  }

  @action
  handleRemoveMenuFilterButtonClick() {
    this.get('removeMenuFilterTask').perform();
  }

  @action
  showActiveMenu() {
    this.setProperties({
      isShowingActiveMenu: true,
      isShowingInternalMenu: false,
      isShowingSetMenu: false
    });
  }

  @action
  showInternalMenu() {
    this.setProperties({
      isShowingActiveMenu: false,
      isShowingInternalMenu: true,
      isShowingSetMenu: false
    });
  }

  @action
  showSetMenu() {
    this.setProperties({
      isShowingActiveMenu: false,
      isShowingInternalMenu: false,
      isShowingSetMenu: true
    });
  }

  @action
  applyMassQtyToSetMenu() {
    const qty = this.get('setMenuItemMassQty');
    const newSetMenuItems = this.get('activeSetMenuItemIds').reduce((acc, id) => {
      acc[id] = qty;
      return acc;
    }, {});

    this.set('setMenuItemMassQty', null);
    this.onSetMenuItemsUpdated(newSetMenuItems);
  }

  /** Handles select all set menu click */
  @action
  handleSetMenuSelectAllClick() {
    console.debug('[orders/order-menu] select all set menu item');
    this.onSetMenuSelectAllClick();
  }

  /** Handles clear set menu click */
  @action
  handleSetMenuClearClick() {
    console.debug('[orders/order-menu] clear all selected set menu items');
    this.onSetMenuClearClick();
  }
}
