import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { action } from '@ember/object';
import Controller from '@ember/controller';

@classic
export default class IndexController extends Controller {
  /** @type {Service} */
  @service
  notify;

  /** @type {Restaurant} */
  @alias('model.restaurant')
  restaurant;

  /** @type {Tag[]} */
  tags = [];

  /** @type {Area[]} */
  areas = [];

  newRestaurantBrand = null;

  restaurantBrands = null;

  @action
  didCancel() {
    this.transitionToRoute('logged-in.restaurants');
  }

  @action
  searchForTags(searchText) {
    this.store.query('tag', { filter: { name: searchText } }).then((tags) => {
      this.set('searchedTags', tags);
    });
  }

  @action
  duplicateMenu(menu) {
    menu.duplicate();
  }

  @action
  createMenu() {
    this.store
      .createRecord('menu', {
        name: 'Shiny New Menu!',
        active: false,
        restaurant: this.get('model').restaurant
      })
      .save();
  }

  @action
  resetNewLocation() {
    this.set(
      'newPickupLocation',
      this.store.createRecord('pickup-location', {
        restaurant: this.get('model.restaurant')
      })
    );
  }

  @action
  showValidationErrors() {
    const notify = this.get('notify');
    this.get('restaurant.errors').forEach((error) => notify.error(error.message.join(' ')));
  }

  @action
  handleDidSaveRestaurantBrand() {
    this.set('newRestaurantBrand', this.store.createRecord('restaurants-brand'));
  }
}
