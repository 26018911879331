import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';

@classic
export default class RuleRoute extends Route.extend(LoadingRouteMixin) {
  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    searchText: { refreshModel: true },
    sort: { refreshModel: true }
  };

  model(params) {
    const { pageLimit, pageOffset } = params;

    return this.store.query('rules-rule', {
      include: 'actions,predicates.facts,predicates.predicates.facts,run-logs',
      page: {
        limit: pageLimit,
        offset: pageOffset
      },
      reload: true
    });
  }

  @action
  refreshModel() {
    return this.refresh();
  }
}
