import { readOnly } from '@ember/object/computed';
import { action } from '@ember/object';
import Component from '@ember/component';
import { Time } from 'renard/transforms/time';
import { debug } from '../../fields/field-helpers';

export default Component.extend({
  classNames: 'fde-time-range-select ui fluid left icon input',

  /** @type {Time} initial start time (used only in range mode) */
  startTime: readOnly('value.startTime'),

  /** @type {Time} initial end time (used only in range mode) */
  endTime: readOnly('value.startTime'),

  /** @type {number} amount that the minute drop down increments by */
  timePickerIncrement: 15,

  /** @type {string} */
  format: 'h:mm a',

  /** @type {string} */
  timeZone: 'Etc/UTC',

  /** @type {date} Beginning of selectable range for first dropdown */
  beginningOfSelectableStartRange: Time.startOfDay,

  /** @type {date} End of selectable range for first dropdown */
  endOfSelectableStartRange: Time.endOfDay,

  /** @type {date} Beginning of selectable range for second dropdown */
  beginningOfSelectableEndRange: Time.startOfDay,

  /** @type {date} End of selectable range for second dropdown */
  endOfSelectableEndRange: Time.endOfDay,

  /** @property {string} Placeholder for first dropdown */
  startTimePlaceholder: 'Start...',

  /** @property {string} Placeholder for second dropdown */
  endTimePlaceholder: 'End...',

  /** @type {boolean} Whether or not to wrap selector in ui segment */
  hasBorder: true,

  /**
   * @callback changeHandler
   * @type {string} startDate as a string
   * @type {string} [endDate] as a string
   *
   * @type {changeHandler}
   */
  onChange() {},

  /** @type {changeHandler} */
  onStartTimeChange() {},

  /** @type {changeHandler} */
  onEndTimeChange() {},

  /**
   *
   *
   * @private
   * @param {Time} startTime
   * @param {Time} endTime
   */
  _onChange(startTime, endTime) {
    debug(`Time Picker Changed (${startTime}, ${endTime}) `);

    const earliestPossibleEndTime = startTime.add(this.get('timePickerIncrement'), 'minutes');
    const latestPossibleStartTime = endTime.subtract(this.get('timePickerIncrement'), 'minutes');

    if (startTime.isSameOrAfter(endTime)) {
      endTime = earliestPossibleEndTime;
    }

    // not sure this branch will ever get hit
    if (endTime.isSameOrBefore(startTime)) {
      startTime = latestPossibleStartTime;
      debug(`Time Picker Adjusted (${startTime}, ${endTime}) `);
    }

    this.onChange({ startTime, endTime });

    if (!this.get('beginningOfSelectableEndRange').equals(earliestPossibleEndTime)) {
      this.set('beginningOfSelectableEndRange', earliestPossibleEndTime);
    }
  },

  handleChangeStartTime: action(function (startTime) {
    this._onChange(startTime, this.get('endTime'));
  }),

  handleChangeEndTime: action(function (endTime) {
    this._onChange(this.get('startTime'), endTime);
  })
});
