import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@tagName('')
export default class RadarChart extends Component {
  /** @type {MealPlanningInstance|MealPlanningTemplate} */
  mealPlan = null;

  /** @type {boolean} */
  useCallout = false;

  @computed('mealPlan.mealPlanningReservations.@each.updatedAt')
  get reservations() {
    return this.mealPlan.mealPlanningReservations ?? [];
  }

  /** @type {number} */
  @computed('mealPlan.mealPlanningReservations.@each.updatedAt')
  get newCount() {
    return this.get('reservations').filterBy('first').get('length');
  }

  /** @type {number} */
  @computed('mealPlan.mealPlanningReservations.@each.updatedAt')
  get favouriteCount() {
    return this.get('reservations').filterBy('favourite').get('length');
  }

  /** @type {number} */
  @computed('mealPlan.mealPlanningReservations.@each.updatedAt')
  get favouriteCuisineCount() {
    return this.get('reservations').filterBy('favouriteCuisine').get('length');
  }

  /** @type {number} */
  @computed('mealPlan.mealPlanningReservations.@each.updatedAt')
  get lastWeekCount() {
    return this.get('reservations').filterBy('lastWeek').get('length');
  }

  /** @type {number} */
  @computed('mealPlan.mealPlanningReservations.@each.updatedAt')
  get percentileScore() {
    return (
      this.get('reservations')
        .mapBy('percentileRank')
        .reduce((acc, i) => acc + i, 0) / 100
    );
  }

  /** @type {number} */
  @computed('mealPlan.mealPlanningReservations.@each.updatedAt')
  get inDeliveryRadiusCount() {
    return this.get('mealPlan.mealPlanningReservations')
      .filterBy('distance.inDeliveryRadius')
      .get('length');
  }

  /** @type {number} */
  @computed('mealPlan.mealPlanningReservations.@each.updatedAt')
  get inExtendedRadiusCount() {
    return this.get('mealPlan.mealPlanningReservations')
      .filterBy('distance.inExtendedRadius')
      .get('length');
  }

  /** @type {number} */
  @computed('mealPlan.mealPlanningReservations.@each.updatedAt')
  get beyondExtendedRadiusCount() {
    return this.get('mealPlan.mealPlanningReservations')
      .filterBy('beyondExtendedRadiusCount')
      .get('length');
  }

  /** @type {ChartJsData} */
  @computed('mealPlan.mealPlanningReservations.@each.updatedAt')
  get data() {
    const total = this.get('reservations.length');

    return {
      labels: [
        'New Restaurants',
        'Favourite Restaurants',
        'Favourite Cuisines',
        'Fresh Restaurants',
        'Popularity score',
        'In Delivery Radius',
        'In Extended Radius',
        'Beyond Extended Radius'
      ],
      datasets: [
        {
          data: [
            this.get('newCount'),
            this.get('favouriteCount'),
            this.get('favouriteCuisineCount'),
            total - this.get('lastWeekCount'),
            this.get('percentileScore'),
            this.get('inDeliveryRadiusCount'),
            this.get('inExtendedRadiusCount'),
            this.get('beyondDeliveryRadiusCount')
          ],
          pointBackgroundColor: ['#FDAC2E', '#972424', '#972424', '#238646', '#4DA2D7']
        }
      ]
    };
  }

  /** @type {ChartJsOptions} */
  @computed('mealPlan.mealPlanningReservations.@each.updatedAt')
  get options() {
    return {
      responsive: true,
      scale: {
        ticks: {
          min: 0,
          max: this.get('mealPlan.mealPlanningReservations.length'),
          stepSize: 1,
          precision: 2
        }
      },
      legend: {
        display: false
      }
    };
  }
}
