import classic from 'ember-classic-decorator';
import OrderRoute from 'star-fox/features/logged-in/orders/edit/meal-feedback/route';

@classic
export default class FeedRoute extends OrderRoute {
  /** @override */
  templateName = 'logged-in.orders.edit.meal-feedback';

  orderEditRoute = 'logged-in.logistics.index.edit-order';
}
