import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class MealPlanningRestaurantConstraintSerializer extends Application {
  attrs = {
    requirement: {
      serialize: false
    }
  };
}
