import Helper from '@ember/component/helper';

/**
 * [orValue description]
 * @param {(string|null)[]} params
 * @return {string}
 */
export function orValue(params) {
  let [value = '', defaultValue = ''] = params;
  return value || defaultValue;
}

export default Helper.helper(orValue);
