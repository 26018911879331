import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import { action } from '@ember/object';
import Controller from '@ember/controller';

@classic
export default class ShowController extends Controller {
  @alias('model.logRecords.meta.recordCount')
  recordCount;

  pageLimit = 50;
  pageOffset = 0;
  searchText = null;
  _searchText = null;

  queryParams = ['status', 'pageLimit', 'pageOffset', 'searchText', 'created_at_range'];

  filterCollection = null;
  get uniqueStatuses() {
    return this.hashifyOptions(['info', 'success', 'failure']);
  }

  @action
  handleFilterApply() {
    this.send('setQueryParamsFromFilterCollection');
  }

  hashifyOptions(array) {
    // Remove null, undefined, and blank values
    const uniqueArray = Array.from(new Set(array)).filter(
      (value) =>
        value !== null && value !== undefined && typeof value === 'string' && value.trim() !== ''
    );
    return uniqueArray.map((value) => {
      return {
        id: value,
        value: value,
        label: value
      };
    });
  }
}
