import classic from 'ember-classic-decorator';
import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class MealPlanningPreferenceProfileRoute extends Route.extend(CapabilityRouteMixin) {
  /**
   * @param {Object} _params
   *
   * @returns {Promise} Client and MealPlanningTemplates
   */
  model(_params) {
    const client = this.modelFor('logged-in.clients.edit');

    return RSVP.hash({
      client: client
    });
  }
}
