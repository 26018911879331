import classic from 'ember-classic-decorator';
import {
  attributeBindings,
  classNameBindings,
  classNames,
  tagName
} from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { htmlSafe } from '@ember/template';

/**
 * Row component as the
 */
@classic
@tagName('tr')
@classNames('fde-tables-ordered-table-table-rows-row-tr')
@classNameBindings(
  'isSelected:fde-is-row-selected',
  'isExpanded:fde-is-row-expanded',
  'isEditable:fde-highlight',
  'isEditable:fde-can-click'
)
@attributeBindings(
  'componentStyle:style',
  'index:data-test-row-index',
  'sectionIndex:data-test-section-index'
)
export default class Tr extends Component {
  /** @type {String} */
  selectableRowCursor = 'pointer';

  /** @type {String} */
  @computed('selectableRowCursor')
  get componentStyle() {
    return htmlSafe(`cursor:${this.get('selectableRowCursor')};`);
  }

  /** @type {boolean} */
  isSelected = false;

  /** @type {boolean} */
  isEditable = false;
}
