import classic from 'ember-classic-decorator';
import SearchAction from './base';

@classic
export default class Tag extends SearchAction {
  /** @override */
  modelName = 'tag';

  /** @override */
  filterSearchName = 'name';
}
