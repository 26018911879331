import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed, action } from '@ember/object';
import Component from '@ember/component';
import { run } from '@ember/runloop';
import { guidFor } from '@ember/object/internals';
import Ember from 'ember';

const { ViewUtils } = Ember;

@classic
@tagName('')
export default class HoverCallout extends Component {
  /** @type {boolean} */
  isCalloutShowing = false;

  /** @type {boolean} */
  isToggle = false;

  /** @type {boolean} */
  showOnInit = false;

  /** @type {DOMElement} */
  wiredTarget = null;

  /** @type {string} */
  position = 'top center';

  /** @type {boolean} */
  showOnClick = false;

  /** @type {boolean} */
  preventClose = false;

  /** @type {string?} */
  icon = null;

  /** @type {boolean} */
  targetIsWired = false;

  /** @type {boolean} */
  bubbleTargetClick = true;

  /** @type {string} */
  @computed
  get hoverCalloutPaneId() {
    return `hover-callout-pane-id-${guidFor(this)}`;
  }

  init(...args) {
    super.init(...args);

    run.next((_) => {
      if (this.get('showOnInit')) {
        this.set('isCalloutShowing', true);
      }
    });
  }

  onShow() {}

  onHide() {}

  onFailedToDismiss() {}

  findHoverElement() {
    let targetToWire = this.get('wiredTarget');

    if (!targetToWire) {
      const firstNode = ViewUtils.getViewBounds(this).firstNode;
      targetToWire =
        firstNode.nodeType !== Node.ELEMENT_NODE ? firstNode.nextElementSibling : firstNode;
    }

    this.wireTarget(targetToWire);
    this.set('foundTarget', targetToWire);
  }

  @action
  onTargetMouseOver() {
    run((_) => {
      this.set('isCalloutShowing', true);

      this.onShow();
    });
  }

  @action
  onTargetMouseOut() {
    run((_) => {
      this.set('isCalloutShowing', false);
      this.onHide();
    });
  }

  @action
  onTargetClick(event) {
    if (!this.get('bubbleTargetClick')) {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }

    if (event.target.tagName === 'a') {
      event.preventDefault();
    }

    run((_) => {
      const { target } = event;

      if (target != this.get('wiredTarget') && !this.get('wiredTarget').contains(target)) {
        return;
      }

      if (this.get('isCalloutShowing')) {
        if (this.get('preventClose') && !this.get('isToggle')) {
          this.onFailedToDismiss();
          return;
        }

        this.set('isCalloutShowing', false);
        this.onHide();
      } else {
        this.set('isCalloutShowing', true);
        this.onShow();
      }
    });
  }

  @action
  onDocumentClick(event) {
    run((_) => {
      if (this.get('isToggle')) {
        return;
      }
      const target = event.target;

      const wiredTarget = this.get('wiredTarget');
      const calloutPane = document.getElementById(this.get('hoverCalloutPaneId'));

      const calloutPaneClicked =
        calloutPane && (calloutPane.contains(target) || target === calloutPane);
      const wiredTargetClicked = wiredTarget === target || wiredTarget.contains(target);

      if (this.get('preventClose') && !calloutPaneClicked) {
        this.onFailedToDismiss();
        return;
      }

      if (!calloutPaneClicked && !wiredTargetClicked) {
        this.onHide();
        this.set('isCalloutShowing', false);
      }
    });
  }

  wireTarget(target) {
    if (target) {
      const wiredTarget = this.get('wiredTarget');

      if (!this.get('targetIsWired') || wiredTarget !== target) {
        this.unWireTarget(wiredTarget);

        if (this.get('showOnClick')) {
          this.wireClick(target);
        } else {
          this.wireHover(target);
        }

        this.set('targetIsWired', true);
        this.set('wiredTarget', target);
      }
    }
  }

  wireClick(target) {
    if (target) {
      const _onTargetClick = this.onTargetClick;
      const _onDocumentClick = this.onDocumentClick;

      target.addEventListener('click', _onTargetClick);
      document.addEventListener('click', _onDocumentClick);

      this.setProperties({ _onTargetClick, _onDocumentClick });
    }
  }

  wireHover(target) {
    if (target) {
      const _onTargetMouseOver = this.onTargetMouseOver;
      const _onTargetMouseOut = this.onTargetMouseOut;

      target.addEventListener('mouseover', _onTargetMouseOver);
      target.addEventListener('mouseout', _onTargetMouseOut);

      this.setProperties({ _onTargetMouseOver, _onTargetMouseOut });
    }
  }

  unWireTarget(target) {
    if (target) {
      if (this.get('showOnClick')) {
        target.removeEventListener('click', this.get('_onTargetClick'));
        document.removeEventListener('click', this.get('_onDocumentClick'));
      } else {
        target.removeEventListener('mouseover', this.get('_onTargetMouseOver'));
        target.removeEventListener('mouseout', this.get('_onTargetMouseOut'));
      }
    }
  }

  didRender() {
    super.didRender(...arguments);
    this.findHoverElement();
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.unWireTarget(this.get('wiredTarget'));
  }
}
