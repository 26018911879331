import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

@classic
export default class ShowController extends Controller {
  @service('session')
  session;

  /** @type {Area[]} */
  areas = null;

  showNewLocationForm = false;
}
