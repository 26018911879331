import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class GiftbitRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'CLIENT_GIFTBIT_SHOW';

  /** @type {UserSession} */
  @service
  userSession;

  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true }
  };

  /** @override */
  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setupPusher();
  }

  /** @override */
  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);
    controller.teardownPusher();
  }

  model(params) {
    const { pageLimit, pageOffset } = params;
    const client = this.modelFor('logged-in.clients.edit');

    return RSVP.hash({
      client: client,
      currency: this.store
        .query('currencies-currency', { filter: { rewards: true } })
        .then((response) => response.get('firstObject')),
      transactions: this.store.query('currencies-transaction', {
        filter: { client: client.id },
        page: {
          limit: pageLimit,
          offset: pageOffset
        },
        sort: '-created-at'
      }),
      wallet: client.get('wallet')
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
