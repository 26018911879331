import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { isArray } from '@ember/array';
import { action, computed } from '@ember/object';
import { capitalize } from '@ember/string';
import { isPlainObject } from '../../../../utils/type';

/**
 * Allows selecting of a value from a provided list
 */
@classic
@classNames('field fde-field fde-value-select-field')
export default class ValueSelectControl extends Component {
  /** @property {Array} the models available to select from */
  values = [];

  /** @property {String|Array} the selected value(s) */
  value = null;

  /** @type {boolean} */
  allowAdditions = false;

  /** @property {string} text that will be displayed when no value is selected */
  placeholder = 'None';

  /** @property {boolean} whether or not the model select component is in fluid mode */
  isFluid = true;

  /** @type {boolean} whether or not the model select component is in readonly mode */
  readonly = true;

  /** @type {boolean} whether or not the model select component is in readonly mode */
  icon = null;

  /** @type {string} */
  noneText = 'None';

  allowNoneSelection = false;

  onChange() {}

  createValueObject(value) {
    return {
      id: value.toString(),
      value: value.toString(),
      label: value.label ?? capitalize(`${value.toString()}`.replace(/[-_]/g, ' ')),
      // these are put in specifically for enums that can be both the value and objects
      icon: value?.icon ?? null,
      iconColor: value?.iconColor ?? null
    };
  }

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    const newValues = (this.values || []).map((value) => {
      const existing = this._values.find((vObj) => vObj.value === value);
      if (existing) {
        return existing;
      } else if (isPlainObject(value) && value.id && value.label) {
        return Object.assign({ value: value.id }, value);
      } else {
        return this.createValueObject(value);
      }
    });
    this.set('_values', newValues);

    let newValue;
    if (this._isMultiple) {
      newValue = (this.value || []).map((value) => {
        const foundInValuesSet = this._values.find((vObj) => vObj.value === value);
        return foundInValuesSet ? foundInValuesSet : this.createValueObject(value);
      });
    } else if (this.value || this.value === 0) {
      const foundInValuesSet = (this._values || []).find((vObj) => vObj.value === this.value);
      newValue = foundInValuesSet ? foundInValuesSet : this.createValueObject(this.value);
    } else {
      newValue = this.value;
    }
    this.set('_value', newValue);
  }

  _value = null;
  _values = [];
  isMultiple = false;

  /** @type {boolean} whether you can select multiple values at once */
  @computed('value', 'isMultiple')
  get _isMultiple() {
    return isArray(this.get('value')) || this.isMultiple;
  }

  @action
  handleOnChange(vObj) {
    const v = this.get('_isMultiple')
      ? (vObj || []).map((_) => _.value)
      : (vObj && vObj.value) || '';
    this.onChange(v);
  }
}
