import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import moment from 'moment-timezone';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

@classic
export default class NewRoute extends Route.extend(AuthenticatedRouteMixin) {
  model() {
    return RSVP.hash({
      taxRate: this.store.createRecord('invoicing-tax-rate', {
        validFrom: moment().toDate(),
        validUntil: moment().add(5, 'years').toDate()
      }),
      areas: this.store.query('area', {
        page: {
          limit: 100,
          offset: 0
        }
      })
    });
  }
}
