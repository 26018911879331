import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action } from '@ember/object';

@classic
@classNames('fde-filters-filter-toggle')
export default class FilterToggle extends Component {
  /** @type {FilterCollection} */
  filters = null;

  /**
   * @param filter
   */
  @action
  toggleFilter(filter) {
    this.get('filters').toggleFilter(filter);
  }
}
