import classic from 'ember-classic-decorator';
import AdapterError from '@ember-data/adapter/error';
import EmberError from '@ember/error';
import RSVP from 'rsvp';
import Service, { inject as service } from '@ember/service';
import ModelUtil from 'star-fox/utils/model';

/**
 * Possible stripe Error Codes
 * @enum {string}
 */
export const StripeErrorCodes = {
  INVALID_CVC: 'invalid_cvc',
  INVALID_EXPIRY_MONTH: 'invalid_expiry_month',
  INVALID_EXPIRY_YEAR: 'invalid_expiry_year',
  CARD_DECLINED: 'card_declined',
  INCORRECT_NUMBER: 'incorrect_number'
};

/**
 * Possible card save error message
 * @enum {string}
 */
export const CardSaveErrorMessages = {
  INVALID_CVC: `Your card's security code is incorrect.`,
  CARD_EXPIRED: `Your card has expired.`,
  CARD_DECLINED: `Your card was declined.`,
  INCORRECT_NUMBER: `Your card number is incorrect.`,
  PROCESSING: `An error occurred while processing your card. Try again in a little bit.`
};

/** Possible Card Errors that yield from this service */

/** @constructor */
export function CardError() {
  EmberError.apply(this, arguments);
}
CardError.prototype = Object.create(EmberError.prototype);

/** @constructor */
export function ExpiryError() {
  EmberError.apply(this, arguments);
}
ExpiryError.prototype = Object.create(EmberError.prototype);

/** @constructor */
export function CVCError() {
  EmberError.apply(this, arguments);
}
CVCError.prototype = Object.create(EmberError.prototype);

/** @constructor */
export function DeclineError() {
  EmberError.apply(this, arguments);
}
DeclineError.prototype = Object.create(EmberError.prototype);

/**
 * Credit Card service is responsible for communicating with both stripe and V3 card resource
 * when creating a credit card for a user. The saveCard method will consider all possible errors
 * and throw corresponding app specified errors that can be handled downstream.
 */
@classic
export default class CardServiceService extends Service {
  @service
  stripe;

  /**
   * Creates a Stripe token with the user's credit card data and once a token has been received,
   * it removes the sensitive credit card data from the model and places only the token on the card
   * model for the backend to process and associate with the user.
   * Allows PATCHes to the card to bypass calls to Stripe, specifically so we can change the card nickname.
   *
   * @param {PaymentCard} card Card to be saved.
   * @param {Function} saveMethod the Model.save() method. useful for testing as an override in the base card model.
   * @param {Object} saveOptions Save options to be passed into the saveMethod (see: Model.save options)
   * @returns {Promise.<PaymentCard>}
   */
  saveCard(card, saveMethod, saveOptions) {
    if (saveOptions && saveOptions.update) {
      return saveMethod.call(card, saveOptions);
    }

    return RSVP.resolve(card)
      .then((card) => this._createToken(card))
      .catch((response) => this._handleStripeError(response))
      .then((response) => this._saveCardToken(response, card, saveMethod, saveOptions))
      .catch((response) => this._handleSaveError(response))
      .catch(CVCError, (_) => {
        ModelUtil.applyError(card, { cvc: [[_.message]] });
        throw _;
      })
      .catch(ExpiryError, (_) => {
        ModelUtil.applyError(card, { expiryMonth: [[_.message]], expiryYear: [['']] });
        throw _;
      })
      .catch(CardError, (_) => {
        ModelUtil.applyError(card, { number: [[_.message]] });
        throw _;
      });
  }

  /**
   * @param {PaymentCard} card
   */
  _createToken(card) {
    const stripe = this.get('stripe');
    return stripe.card.createToken({
      name: card.get('nameOnCard'),
      number: card.get('number'),
      cvc: card.get('cvc'),
      'exp-month': card.get('expiryMonth'),
      'exp-year': card.get('expiryYear'),
      address_line1: card.get('addressLine_1'),
      address_line2: card.get('addressLine_2'),
      address_city: card.get('city'),
      address_state: card.get('province'),
      address_zip: card.get('addressCode'),
      address_country: card.get('country')
    });
  }

  _handleStripeError(response) {
    if (response.error && response.error.code) {
      const message = response.error.message;
      switch (response.error.code) {
        case StripeErrorCodes.INVALID_CVC:
          throw new CVCError(message);
        case StripeErrorCodes.INVALID_EXPIRY_MONTH:
        case StripeErrorCodes.INVALID_EXPIRY_YEAR:
          throw new ExpiryError(message);
        case StripeErrorCodes.CARD_DECLINED:
        case StripeErrorCodes.INCORRECT_NUMBER:
          throw new CardError(message);
      }
    } else {
      //Didn't come from stripe token
      throw response;
    }
  }

  /**
   * @param {Object} response
   * @param {PaymentCard} card
   * @param {Function} saveMethod
   * @param {Object} saveOptions
   * @returns {Promise.<PaymentCard>}
   */
  _saveCardToken(response, card, saveMethod, saveOptions) {
    card.set('cardToken', response.id);
    return saveMethod.call(card, saveOptions);
  }

  _handleSaveError(response) {
    if (response instanceof AdapterError) {
      const message = response.errors ? response.errors[0].detail : 'unknown';
      switch (message) {
        case CardSaveErrorMessages.INVALID_CVC:
          throw new CVCError(message);
        case CardSaveErrorMessages.CARD_EXPIRED:
          throw new ExpiryError(message);
        case CardSaveErrorMessages.CARD_DECLINED:
        case CardSaveErrorMessages.INCORRECT_NUMBER:
        case CardSaveErrorMessages.PROCESSING:
          throw new CardError(message);
        default:
          //uknown error
          throw response;
      }
    } else {
      throw response;
    }
  }
}
