import Component from '@glimmer/component';
import { action } from '@ember/object';
import { arg } from 'ember-arg-types';
import $ from 'jquery';
import { next } from '@ember/runloop';

export default class FormControlsCronComponent extends Component {
  @arg value = '* * * * *';
  @arg onChange = (_value) => {};

  _cron;

  @action
  setupCron(el) {
    next(() => {
      this._cron = $(el).cron({
        initial: this.value,
        onChange: () => {
          this.onChange($(el).cron('value'));
        }
      });
    });
  }

  @action
  updateValue() {
    this._cron.cron('value', this.value);
  }
}
