import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@tagName('')
export default class ReservationFormFor extends Component {
  /** @type {NotificationService} */
  @service
  notify;

  /** @type {MealPlanningReservation} */
  reservation = null;

  /** @type {Restaurants[]} */
  restaurants = null;

  /** @type {boolean} */
  canEditTimes = true;

  /** @type {Restaurant[]} */
  highlightRestaurants = null;

  /** @type {Order} */
  @alias('reservation.order.content')
  order;

  @computed('highlightRestaurants', 'reservation.actualRestaurant')
  get isFulfilled() {
    const highlightRestaurants =
      this.get('highlightRestaurants.restaurants') ?? this.get('highlightRestaurants') ?? [];
    return (
      highlightRestaurants.mapBy('id').includes(this.get('reservation.actualRestaurant.id')) &&
      this.get('reservation.warnings.isEmpty')
    );
  }

  didDestroy() {}

  onRestaurantChange() {}

  @action
  setStateEvent(stateEvent) {
    const order = this.get('order');

    if (!this._validateStateEvent(stateEvent)) {
      this._displayError(order.get('errors'));
      return;
    }

    order.set('stateEvent', stateEvent);
    return order
      .save()
      .then((_) => this.get('notify').success(`Succesfully set the order to "${stateEvent}"`))
      .catch((response) => this._displayError(response.errors));
  }

  @action
  handleDidFail(errorObject) {
    this._displayError(errorObject.errors);
  }

  /**
   * Opportunity to validate order based on state event change.
   * @param {OrderStateEvent} stateEvent
   */
  _validateStateEvent(stateEvent) {
    if (stateEvent.isPublish) {
      return this.get('order').validate();
    }
    return true;
  }

  /**
   * Displays errors from the API in toasters, with some custom messages in specific cases.
   * @param  {Array} errors the errors returned by the API
   */
  _displayError(errors, closeAfter = 10000) {
    const order = this.get('order');
    this.get('notify').error(`Unable to save Order #${order.get('identifier')}`, { closeAfter });

    errors.forEach((error) => {
      let message = error.detail || (error.message && error.message.join());

      if (message.match(/restaurant-capacity/)) {
        message = order.get('isGroupOrder')
          ? `${message} — number of people`
          : `${message} — too many order items`;
      }

      this.get('notify').error(message, { closeAfter });
    });
  }
}
