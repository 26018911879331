import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';

@classic
export default class MasterfoxController extends Controller {
  @alias('model')
  restaurant;

  @computed('restaurant')
  get restaurantsEditPath() {
    return `../restaurants/${this.get('restaurant.id')}/edit`;
  }

  @computed('restaurant')
  get restaurantsShowPath() {
    return `../restaurants/${this.get('restaurant.id')}`;
  }

  @computed('restaurant')
  get restaurantStoryPath() {
    return `../restaurant_stories/${this.get('restaurant.restaurantStoryId')}`;
  }

  @computed('restaurant')
  get restaurantMenuPath() {
    return `../menus/${this.get('restaurant.id')}/edit`;
  }
}
