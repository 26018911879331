import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import PusherHandlersMixin from 'star-fox/mixins/pusher-handlers-mixin';

export default class AbstractControllerController extends Controller.extend(PusherHandlersMixin) {
  /** @type {Store} */
  @service store;

  /** @type {Router} */
  @service router;

  /** @type {ActionCableService} */
  @service actionCable;

  /** @type {PusherService} */
  @service pusher;

  /** @type {UserSessionService} */
  @service userSession;

  /** @type {Service.<Session>} */
  @service session;
}
