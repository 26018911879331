import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { run } from '@ember/runloop';
import $ from 'jquery';
import { pluralize } from 'ember-inflector';
import { dasherize } from '@ember/string';

const searchArea = localStorage.getItem('quicksearch:area');

@classic
export default class QuickSearch extends Component {
  // this will be a public api in the future
  @service
  router;

  store = null;

  /**  @type {*} */
  selection = null;

  /**  @type {booelan} */
  hasResults = false;

  /**  @type {booelan} */
  hasSearched = false;

  /**  @type {String} */
  searchValue = null;

  /**  @type {boolean} */
  isShowing = false;

  /** @type {Object[]} array of sectioned results */
  results = null;

  /**  @type {Area[]} */
  areas = null;

  /**  @type {String} */
  searchArea = searchArea === 'null' ? null : searchArea;

  /**  @type {String[]} */
  @computed('areas')
  get areaNames() {
    return (this.get('areas') || []).filterBy('active').mapBy('city');
  }

  /**
   * The pluralized version of the selection class name
   *
   * @type {string}
   */
  @computed('selection')
  get sectionForSelection() {
    return pluralize(this.get('selection._internalModel.modelName') || '');
  }

  /**
   * The route for the search string
   *
   * @type {string}
   */
  @computed('selection')
  get routeForSelection() {
    let ret = '';

    switch (this.get('sectionForSelection')) {
      case 'clients':
        ret = 'logged-in.clients.edit';
        break;
      case 'restaurants':
        ret = 'logged-in.restaurants.show';
        break;
      case 'orders':
        ret = 'logged-in.orders.edit';
        break;
      case 'users':
        ret = 'logged-in.users.show';
        break;
    }

    return ret;
  }

  /**
   * Navigates to the selection based on the route for selection property
   */
  @action
  navigateToSelection() {
    this.doWhileNavigating(() =>
      this.get('router').transitionTo(this.get('routeForSelection'), this.get('selection.id'))
    );
  }

  /**
   * Performs an action while setting the navigation flag - and then ultimately closing the panel
   *
   * The pass in function must return a promise
   *
   * @param {function} func
   */
  doWhileNavigating(func) {
    this.set('isNavigating', true);
    func()
      .then(() => {
        this.hideSearchPanel();
      })
      .finally(() => this.set('isNavigating', false));
  }

  /**
   * Callback after the panel is shown
   */
  didShow() {}

  /**
   * Callback after the panel is hidden
   */
  didHide() {}

  /**
   * Shows teh search panel
   */
  showSearchPanel() {
    this.set('isShowing', true);
    this.didShow();
    run.next(() => this.$('.fde-quick-search-input input:first').focus());
  }

  /**
   * Hides the search panel reseting all of the attributes
   */
  @action
  hideSearchPanel() {
    // reset initial state
    this.setProperties({
      selection: null,
      results: null,
      isShowing: false,
      _searchString: null,

      hasResults: false,
      hasSearched: false
    });

    // TODO: REMOVE this cause its a hack need to figure out why the
    // input isn't clearing properly but for now lets reach down to the dom
    // element and trash it
    this.$('input:first').val('');

    this.didHide();
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    // Doesn't technically need to be cleaned up as this is around for the life cycle of the app
    $(document).on('keydown', (e) => {
      // TODO make this not a magic int
      if (e.keyCode === 75 && (e.ctrlKey || e.metaKey)) {
        this.showSearchPanel();
      }
    });
  }

  keyDown(e) {
    switch (e.key) {
      case 'k':
        if (e.ctrlKey || e.metaKey) {
          this.hideSearchPanel();
          e.stopPropagation();
        }
        break;
      case 'Escape':
        this.hideSearchPanel();
        break;
      case 'ArrowDown':
        if (this.get('results.length')) {
          run.next(() => {
            this.$('.fde-lists-section-list').focus();
          });
        }
        break;
    }
  }

  /**
   * NOOP to catch clicks
   */
  noop() {}

  /**
   * Perform the actual search for orders, clients, restaurants
   */
  doSearch() {
    const searchString = this.get('_searchString');

    // handle the clearing case
    if (!searchString) {
      this.set('hasSearched', false);
      return;
    }

    this.set('isSearching', true);

    // We search all the endpoints with the same string
    const limit = 5;
    let searchOptions = {
      filter: {
        search: searchString
      },
      page: {
        limit: limit
      },
      search: {
        limit: limit
      }
    };

    let searchArea = this.get('searchArea');
    if (searchArea) {
      searchOptions.search.area = dasherize(searchArea.toLowerCase());
    }

    // Perform parallel search
    RSVP.hash({
      orders: this.store.query(
        'order',
        Object.assign({}, searchOptions, {
          include: 'area,client,restaurant',
          fields: {
            areas: 'city',
            clients: 'account-name',
            restaurants: 'name',
            orders: 'area,client,restaurant,identifier,number-of-people,total-amount'
          }
        })
      ),
      clients: this.store.query(
        'client',
        Object.assign({}, searchOptions, {
          include: 'areas',
          fields: {
            areas: 'city',
            clients: 'areas,account-name'
          }
        })
      ),
      restaurants: this.store.query(
        'restaurant',
        Object.assign({}, searchOptions, {
          include: 'areas',
          fields: {
            areas: 'city',
            restaurants: 'areas,name'
          }
        })
      ),
      users: this.store.query('user', Object.assign({}, searchOptions))
    })
      .then((results) => {
        // group the results into sections based on the hash
        results = Object.entries(results)
          .reduce((acc, [key, values]) => {
            if (values.length) {
              acc.push({
                title: key,
                data: values.toArray()
              });
            }
            return acc;
          }, [])
          // sort by title so they don't move around based on resolution time... oddly
          .sortBy('title');

        this.setProperties({
          results: results,
          // Assign the first result
          selection: results.reduce((acc, result) => acc.concat(result.data), [])[0],
          hasResults: results.any((_) => _.data.length !== 0),
          hasSearched: true
        });
      })
      .finally(() => this.set('isSearching', false));
  }

  /**
   * Handles changes to the search input control
   *
   * @param {string} searchString
   */
  @action
  handleChange(searchString) {
    if (this.get('_searchString') !== searchString) {
      this.set('_searchString', searchString);

      // prevent multiple search
      run.debounce(this, this.doSearch, 400);
    }
  }

  /**
   * Selects the area we want to search in
   * @param area
   */
  @action
  selectArea(area) {
    area = area === 'none' ? null : area;
    this.set('searchArea', area);
    localStorage.setItem('quicksearch:area', area);
    this.doSearch();
  }
}
