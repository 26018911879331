import { helper as buildHelper } from '@ember/component/helper';

/**
 * Helper that stops propagation of events of onclick attributes
 */
export default buildHelper(([fn = () => {}], { immediate = false }) => (event) => {
  if (event) {
    event.stopPropagation();
    immediate && event.stopImmediatePropagation();
  }
  return fn(event);
});
