import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class ServiceTimesRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'RESTAURANT_SERVICE_TIMES_INDEX';

  model(_params) {
    return this.modelFor('logged-in.restaurants.show');
  }
}
