import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { alias, or } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import isSameAs from 'star-fox/utils/is-same-as';

@classic
@classNames('fde-order-teams-team')
export default class Team extends Component {
  /** @type {Team} */
  orderTeam = null;

  /** @type {Order} */
  order = null;

  /** @type {Team} */
  selectedTeam = null;

  /** @type {boolean} */
  expandAllTeams = false;

  /** @type {boolean} */
  isLoadingMembers = false;

  /** @type {boolean} */
  @or('isSelected', 'expandAllTeams')
  isExpanded;

  /** @type {boolean} */
  @isSameAs('orderTeam.id', 'selectedTeam.id')
  isSelected;

  /** @type {boolean} */
  canAddGroupOrderMember = false;

  /** @type {User[]} */
  members = null;

  /** @type {Object} */
  teamCounts = null;

  /** @type {Number} */
  membersPageLimit = 10;

  /** @type {Number} */
  membersPageOffset = 0;

  /** @type {Number} */
  @alias('members.meta.recordCount')
  membersRecordCount;

  /** @type {string} */
  @computed('teamCounts')
  get teamCountsTooltipMessage() {
    return `[members who have ordered] / [total team members]`;
  }

  /** @type {boolean} Whether or not records will be listed over more than one page. */
  @computed('membersRecordCount', 'membersPageLimit')
  get membersHavePages() {
    const recordCount = this.get('membersRecordCount');
    const pageLimit = this.get('membersPageLimit');
    return recordCount > pageLimit;
  }

  removeTeam() {}
  onClick() {}

  /** @type {string} Unique id to prevent multiple modals opening */
  @computed
  get teamModalId() {
    return `fde-notify-team-modal ${this.get('elementId')}`;
  }

  @action
  loadMembers() {
    this.set('isLoadingMembers', true);

    const memberQueryOptions = {
      page: {
        limit: this.get('membersPageLimit'),
        offset: this.get('membersPageOffset')
      }
    };

    return this.get('orderTeam')
      .query('members', memberQueryOptions)
      .then((_) => this.set('members', _))
      .then((_) => this.set('isLoadingMembers', false));
  }

  @action
  handleMembersPaginationChange(newPageOffset) {
    this.set('membersPageOffset', newPageOffset);
    this.send('loadMembers');
  }

  @action
  closeModal(modal) {
    modal.get('modal').closeModal(modal, modal.get('id'));
  }
}
