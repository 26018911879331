import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-invoicing-ledger-item-form')
export default class InvoicingLedgerItemFormFor extends Component {
  type = '';
  id = '';
}
