import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('fde-actual-segment')
@classNameBindings('compact:fde-is-compact')
export default class ActualSegment extends Component {
  /** @type {boolean} */
  compact = false;

  /** @type {?string} */
  actualSegment = null;

  /** @type {string} */
  @computed('actualSegment')
  get segmentColor() {
    switch (this.get('actualSegment')) {
      case 'A':
        return 'green';
      case 'B':
        return 'blue';
      case 'C':
        return 'yellow';
      case 'D':
        return 'brown';
      case 'SuperA':
        return 'pink';
      default:
        return 'grey';
    }
  }

  /** @type {string} */
  @computed('actualSegment')
  get segmentCharacter() {
    const seg = this.get('actualSegment');
    switch (seg) {
      case 'A':
      case 'B':
      case 'C':
      case 'D':
      case 'SuperA':
        return seg;
      default:
        return '?';
    }
  }
}
