import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { computed } from '@ember/object';

@classic
@tagName('')
export default class JoinedUsers extends Component {
  /** @type {User[]} */
  joinedUsers = null;

  /** @type {boolean} */
  canAddGroupOrderMember = false;

  /** @type {boolean} */
  enableReminder = false;

  /** @type {Order} */
  order = null;

  /** @type {Object} */
  joinedUsersMeta = null;

  /** @type {string} */
  @computed('joinedUsersMeta')
  get joinedUserCountsTooltipMessage() {
    return `[users who have ordered] / [total joined users]`;
  }

  removeUser() {}
  handleAddGroupOrderMemberClick() {}
  triggerShareNotification() {}
}
