import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class AppConfigurationsRoute extends Route {
  model(_params) {
    return this.store.query('app-configuration', {
      reload: true,
      page: {
        limit: 200,
        offset: 0
      }
    });
  }
}
