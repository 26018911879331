import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';

/**
 * A table footer
 */
@classic
@classNames('fde-tables-ordered-table-footer')
@tagName('tfoot')
export default class TableFooter extends Component {}
