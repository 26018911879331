import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';

@classic
export default class UsersController extends Controller {
  /** @type {Service} */
  @service
  session;

  /** @type {Restaurant} */
  @alias('model.restaurant')
  restaurant;
}
