import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class LoggedInAdminsTwilioShowController extends Controller {
  searchedResources;
  testRecord;

  @action
  testMessage() {
    return this.model.testMessage({ record_global_id: this.testRecord.globalId });
  }

  get recordTypes() {
    return [this.model.recordType, 'recipient:User', 'message:string'];
  }
}
