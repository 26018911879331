import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class RestaurantClosure extends Model {
  // Validations
  validations = {
    date: {
      presence: true
    }
  };

  @attr('date-only')
  date;

  @belongsTo('restaurant')
  restaurant;
}
