import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, hasMany } from 'renard/models/foodee';

@classic
export default class InvoicingTaxRate extends Model {
  /*
   * Attributes
   */
  @attr('string')
  description;

  @attr('boolean')
  isDefault;

  @attr('string')
  name;

  @attr('number')
  rate;

  @attr('string')
  taxCode;

  @attr('date')
  validFrom;

  @attr('date')
  validUntil;

  /*
   * Relationships
   */
  @hasMany('area', { inverse: 'invoicingTaxRates' })
  areas;

  /*
   * Computed Properties
   */
  /** @type {string} */
  @computed('rate')
  get label() {
    const rate = this.get('rate') * 100;
    return `${this.get('taxCode')} - ${this.get('name')} (${rate}%)`;
  }

  /*
   * Validations
   */
  validations = {
    name: {
      presence: {}
    },
    taxCode: {
      presence: {}
    },
    rate: {
      numericality: true
    },
    description: {
      presence: {}
    }
  };
}
