import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@tagName('')
export default class RestaurantIndex extends Component {
  /** @type {Service} */
  @service
  router;

  /** @type {Restaurant} */
  restaurant = null;

  /** @type {boolean} */
  isLoading = false;

  /** @type {Tag[]} */
  tags = null;

  /** @type {RestaurantBrand[]} */
  restaurantBrands = null;

  /** @type {Owner} */
  @alias('restaurant.owner')
  owner;

  /** @type {string} */
  @computed('owner')
  get urlSafeExtension() {
    const extension = this.get('owner.extension');
    return extension ? `;ext=${extension}` : '';
  }

  @action
  makeNewOrder() {
    const restaurant = this.get('restaurant');
    const restaurantId = restaurant.get('id');
    const areaId = restaurant.get('areas.firstObject.id');
    const router = this.get('router');
    this.set('isLoading', true);

    router.transitionTo('logged-in.new-order', {
      queryParams: { restaurantId, areaId }
    });
  }
}
