import classic from 'ember-classic-decorator';
import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class MealPlanRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'CLIENT_MEALPLAN_SHOW';

  model() {
    const client = this.modelFor('logged-in.clients.edit');

    // either we find the client's configured meal plan
    // or we create a new record
    return client.get('mealPlan').then((mealPlan) =>
      RSVP.hash({
        client: client,
        mealPlan:
          mealPlan ||
          this.store.createRecord('meal-plan', {
            client: client,
            ssoType: 'none'
          })
      })
    );
  }
}
