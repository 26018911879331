import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class DeliveryCase extends Model {
  // Attributes
  /**
   * enums: [
   *  'driver_late_to_restaurant',
   *  'driver_late_to_client',
   *  'restaurant_late',
   *  'noa'
   * ]
   * @type {string}
   */
  @attr('string')
  caseType;

  /**
   * enums: [5, 10, 15]
   * @type {number}
   */
  @attr('number')
  value;

  @attr('date')
  createdAt;

  // Relationships
  @belongsTo('order')
  order;

  @belongsTo('user')
  user;

  // Display helpers

  /** @type {[type]} return String */
  @computed('value')
  get formattedValue() {
    const value = this.get('value');
    const formattedValue = value == null ? 'No Order on Arrival' : `${value} min`;

    return formattedValue;
  }
}
