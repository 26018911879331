import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { object, func } from 'prop-types';
import { alias } from 'macro-decorators';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { empty } from 'macro-decorators';

export default class SfFormsMealPlanningFormForChangeExplanationModalComponent extends Component {
  @service appConfiguration;
  @arg(object.isRequired) data;
  @arg(func.isRequired) close;

  @alias('data.changeCount') changeCount;
  @alias('data.log') log;

  @tracked reasons = [];
  @tracked otherText;

  get changeReasons() {
    return this.appConfiguration.mealPlanning.preferences.changeReasons || [];
  }

  get text() {
    return this.reasons.concat(this.otherText).filter(Boolean).join(', ');
  }

  @empty('text')
  isEmpty;

  @action
  async saveLog() {
    this.log.text = this.text;
    await this.log.save();
    this.close();
  }
}
