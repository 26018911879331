import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
export default class ManualForm extends Component {
  @service
  appConfiguration;
  couriers = [];
  showButtons = false;
  drivers = [];
  order = null;
  searchedDrivers = [];
  couriersForOrder = [];
  allocateCourier() {}
  deallocateCourier() {}
  clearCourier() {}
  saveManualForm() {}
}
