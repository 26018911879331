import { attr } from 'renard/models/foodee';
import { computed } from '@ember/object';
import MF from 'ember-data-model-fragments';

export default MF.Fragment.extend({
  firstName: attr('string'),
  lastName: attr('string'),
  email: attr('string'),

  type: attr('string'),

  phoneNumber: attr('phone-number'),
  extension: attr('string'),
  smsNumber: attr('phone-number'),

  phoneType: attr('string'),

  name: computed(function () {
    return `${this.get('firstName')} ${this.get('lastName')}`;
  })
});
