import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import moment from 'moment-timezone';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';

@classic
export default class BulkOrdersRoute extends Route.extend(LoadingRouteMixin) {
  queryParams = {
    managedByMe: { refreshModel: true },
    showExisting: { refreshModel: true },
    startDeliverOn: { refreshModel: true },
    endDeliverOn: { refreshModel: true },
    clientId: { refreshModel: true },
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    sort: { refreshModel: true }
  };

  /** @override */
  model(params) {
    const {
      pageLimit,
      pageOffset,
      managedByMe,
      showExisting,
      startDeliverOn,
      endDeliverOn,
      clientId,
      sort
    } = params;

    return this.store.findRecord('area', params.area_id).then((area) =>
      RSVP.hash({
        area: area,
        areaClosures: area.get('areaClosures'),
        orders: this._queryForOrders(
          showExisting,
          area,
          managedByMe,
          startDeliverOn,
          endDeliverOn,
          clientId,
          pageLimit,
          pageOffset,
          sort
        ),
        clients: this._queryForClients(area, managedByMe),
        restaurants: this._queryForRestaurants(area),
        client: clientId ? this.store.findRecord('client', clientId) : null
      })
    );
  }

  /**
   * Query's for 200 restaurants in a given area (should always get all restaurants)
   * @param {Area} area
   * @private
   */
  _queryForRestaurants(area) {
    return area.query('restaurants', {
      page: {
        offset: 0,
        limit: 200
      },
      filter: {
        active: true,
        comingSoon: false
      },
      fields: {
        restaurants: 'name'
      },
      sort: 'name'
    });
  }

  /**
   * Query's for 10 clients in a given area
   * @param {Area} area
   * @private
   */
  _queryForClients(area, managedByMe) {
    return area.query('clients', {
      filter: {
        managedByMe: managedByMe
      },
      page: {
        limit: 10
      },
      fields: {
        clients: 'account-name'
      }
    });
  }

  /**
   * Query's for the orders based on filtering and sort criteria set from the table.
   * @param {boolean} showExisting
   * @param {Area} area
   * @param {boolean} managedByMe
   * @param {Date} startDeliverOn
   * @param {Date} endDeliverOn
   * @param {String} clientId
   * @param {Number} pageLimit
   * @param {Number} pageOffset
   * @param {String} sort
   * @returns {Array}
   * @private
   */
  _queryForOrders(
    showExisting,
    area,
    managedByMe,
    startDeliverOn,
    endDeliverOn,
    clientId,
    pageLimit,
    pageOffset,
    sort
  ) {
    return showExisting
      ? area.query('orders', {
          reload: true,
          filter: {
            isBeforeSubmitted: true,
            managedByMe: managedByMe,
            deliverOnRange: [
              moment(startDeliverOn).format('YYYY-MM-DD'),
              moment(endDeliverOn).format('YYYY-MM-DD')
            ],
            clientId: clientId || undefined
          },
          page: {
            limit: pageLimit,
            offset: pageOffset
          },
          sort: sort,
          include: [
            'area',
            'driver',
            'courier',
            'contact',
            'restaurant',
            'client',
            'restaurant-location',
            'client-location',
            'payment-card',
            'owner'
          ].join(','),
          fields: {
            clients: 'account-name,terms,plan',
            restaurants: 'name'
          }
        })
      : [];
  }
}
