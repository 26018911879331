import { helper as buildHelper } from '@ember/component/helper';

export function teamMemberIsGroupOrderMember([teamMember = null, gomMeta = null]) {
  if (teamMember && gomMeta) {
    const teamMemberId = teamMember.get('id');

    return gomMeta['membersWithGoms'].indexOf(parseInt(teamMemberId)) > -1;
  }
}

export default buildHelper(teamMemberIsGroupOrderMember);
