import classic from 'ember-classic-decorator';
import { htmlSafe } from '@ember/template';
import EmberObject from '@ember/object';
import { getOwner } from '@ember/application';
import { dasherize } from '@ember/string';

// Base has to be an ember object until 2.16 when we can use regular classes
// all subclasses can be POJOs
@classic
export default class Base extends EmberObject {
  /**
   * Builds a FeedItem from a HistorianVersion
   *
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = new FeedItem();
    const event = version.get('event');
    const relationshipChanges = version.get('misc.relationshipChanges') || {};

    feedItem.timestamp = version.get('createdAt');
    feedItem.id = version.id;
    feedItem.requestId = version.requestId;
    feedItem.meta = [`${version.get('itemType')}:${version.get('itemId')}`];
    feedItem.subject = `${version.get('whodunnit')} ${version.get('verb')} ${version.get(
      'itemType'
    )}:${version.get('itemId')}`;
    feedItem.icon = 'bullhorn';

    if (Object.keys(relationshipChanges).length) {
      feedItem.relationshipChanges = this.convertJsonToString(relationshipChanges);
    } else {
      feedItem.relationshipChanges = null;
    }

    let details;

    if (event === 'destroy') {
      details = null;
    } else {
      details = this.convertJsonToString(version.get('objectChanges'));
    }

    feedItem.details = details;

    if (event === 'destroy') {
      feedItem.color = 'red';
      feedItem.details = null;
    }

    return feedItem;
  }

  /**
   * Takes a JSON object and wraps it in an HTML safe PRE element
   * @param {Object} value
   * @returns {SafeString}
   */
  convertJsonToString(value) {
    return htmlSafe(`<pre>${JSON.stringify(value, null, 2)}</pre>`);
  }

  /**
   * Given an arbitrary historian version returns a correctly formatted feed item
   *
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  fromVersion(version) {
    const owner = getOwner(version);
    const builderName = dasherize(version.get('itemType')).replace(/::/g, '-');
    const builder = owner.lookup(`builder:feed-item/${builderName}`) || this;

    return builder.build(version);
  }
}

export class FeedItem {
  constructor() {
    this._isNew = false;
    this._id = '';
    this._timestamp = '';
    this._icon = '';
    this._color = '';
    this._subject = '';
    this._details = '';
    this._meta = '';
    this._requestId = '';
  }

  get urlSafeRequestId() {
    return this.requestId.split(':')[1];
  }

  /** @returns {boolean} */
  get requestId() {
    return this._requestId;
  }

  /** @param {boolean} value */
  set requestId(value) {
    this._requestId = value;
  }

  /** @returns {boolean} */
  get isNew() {
    return this._isNew;
  }

  /** @param {boolean} value */
  set isNew(value) {
    this._isNew = value;
  }

  /**
   * @returns {DateTime} value
   */
  get timestamp() {
    return this._timestamp;
  }

  /**
   * @param {DateTime} value
   */
  set timestamp(value) {
    this._timestamp = value;
  }

  /**
   * @returns {string}
   */
  get color() {
    return this._color;
  }

  /**
   * @param {string} value
   */
  set color(value) {
    this._color = value;
  }

  /**
   * @returns {string}
   */
  get id() {
    return this._id;
  }

  /**
   * @param {string} value
   */
  set id(value) {
    this._id = value;
  }

  /**
   * @returns {string}
   */
  get icon() {
    return this._icon;
  }

  /**
   * @param {string} value
   */
  set icon(icon) {
    this._icon = icon;
  }

  /**
   * @returns {string}
   */
  get subject() {
    return this._subject;
  }

  /**
   * @param {string} value
   */
  set subject(value) {
    this._subject = value;
  }

  /**
   * @returns {string}
   */
  get details() {
    return this._details;
  }

  /**
   * @param {string} value
   */
  set details(value) {
    this._details = value;
  }

  /**
   * @returns {string}
   */
  get meta() {
    return this._meta;
  }

  /**
   * @param {string} value
   */
  set meta(value) {
    this._meta = value;
  }
}
