import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import { action } from '@ember/object';

@classic
export default class LedgerController extends Controller {
  hideXero = true;

  @action
  handleDidCredit() {
    this.send('reloadLedgers');
  }
}
