import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('ui item')
export default class CheckboxListItem extends Component {
  /** @property {Object} source of checks */
  object = null;

  @computed
  get isChecked() {
    return this.get(`object.${this.get('key')}`);
  }

  /** @property {string} title for this item */
  title = null;

  /** @property {string} subTitle for this item */
  subTitle = null;
}
