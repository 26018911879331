import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('four wide column')
export default class GiftCard extends Component {
  /** @type {GiftbitGift} **/
  gift = null;

  /** @type {boolean} **/
  isSelected = false;

  /** @type {function} **/
  onClick() {}
}
