import classic from 'ember-classic-decorator';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias, or, bool } from '@ember/object/computed';
import Model, { attr, belongsTo } from 'renard/models/foodee';
import { htmlSafe } from '@ember/template';
import { modelActionWithErrors } from 'renard/utils/model-actions';
import { Enum } from 'star-fox/utils/enum';
import { ReservationState } from 'renard/transforms/reservation-state';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import { OrderState } from 'renard/transforms/order-state';
import config from 'star-fox/config/environment';

export class Commands extends Enum {
  static Publish = new Enum('publish');
  static Submit = new Enum('submit');
  static Reject = new Enum('reject');
  static Confirm = new Enum('confirm');
  static Convert = new Enum('convert');
  static Cancel = new Enum('cancel');
}

@classic
export default class MealPlanningReservation extends Model {
  @service appConfiguration;

  /*
   * Attributes
   */
  @attr('date')
  createdAt;

  @attr('date')
  updatedAt;

  @attr('reservation-state', { defaultValue: ReservationState.Draft.valueOf() })
  state;

  @attr('date')
  pickupAt;

  @attr('date')
  deliverAt;

  @attr('boolean')
  ignoresCapacityLimit;

  @attr('number')
  numberOfPeople;

  @attr('number')
  requestedCapacity;

  @attr('boolean')
  lastWeek;

  @attr('boolean')
  favourite;

  @attr('boolean')
  favouriteCuisine;

  @attr('boolean')
  first;

  @attr('number')
  percentRank;

  @attr('boolean')
  deadlineIsSameDay;

  @attr('distance')
  distance;

  /** @type {number} **/
  @computed('percentRank')
  get percentileRank() {
    return 100 - this.get('percentRank');
  }

  @computed
  get planningUrl() {
    return `${config.rootURL}planning?area=${this.area.get(
      'id'
    )}&expanded="${this.mealPlanningInstance.get('id')}"&monday=${moment(
      this.mealPlanningInstance.get('startOfWeek')
    ).format('YYYY-MM-DD')}`;
  }

  /*
   * Relationships
   */
  @belongsTo('area')
  area;

  @belongsTo('order')
  order;

  @belongsTo('client')
  client;

  @belongsTo('restaurant')
  restaurant;

  @belongsTo('meal-planning-event')
  event;

  @belongsTo('meal-planning-instance')
  mealPlanningInstance;

  @computed('warnings.notEmpty')
  get hasWarnings() {
    return this.warnings.notEmpty;
  }

  async convert(orderDetails = {}) {
    return this._convert({ order_details: orderDetails });
  }

  // There are currently many places using this function (checkRestaurantAvailability)
  // and the only reason why this exists still is because of all those places
  // we need to plan a proper removal of all these manual checks being done in the front-end
  _applyModelActionErrors(e) {
    // Clears previous validations
    this.validate({ only: [] });

    const hasErrorKeys = Object.keys(e.serializedErrors || {}).length;
    this.send('becomeDirty');

    const serializedError = hasErrorKeys ? e.serializedErrors : { general: [e.toString()] };

    this.store.recordWasInvalid(this._internalModel, serializedError);

    // Definitely not the best, but I'm only concerned
    // with capacity issues right now.
    if (e.serializedErrors && e.serializedErrors['restaurant-capacity']) {
      throw e.serializedErrors['restaurant-capacity'];
    } else {
      throw e;
    }
  }

  // Always check if the restaurant has availability
  // otherwise the plan can be published, quoted, converted, confirmed without
  // the restaurant having how to accommodate the meal
  async checkRestaurantAvailability() {
    this.validate({ only: [] });
    const restaurant = await this.restaurant;

    if (!restaurant) {
      return null;
    }

    // If the restaurant is available, then does nothing
    // otherwise it will throw an error and make the object dirty
    (await restaurant.checkAvailability(this.deliverAt)) ||
      this._applyModelActionErrors(new Error('Restaurant is unavailable or has reached capacity.'));

    // otherwise just return nothing
    return null;
  }

  @computed('state')
  get isNotCancelled() {
    return !this.state.isCancelled;
  }

  publish = modelActionWithErrors('publish', { method: 'POST', pushToStore: true });
  submit = modelActionWithErrors('submit', { method: 'POST', pushToStore: true });
  reject = modelActionWithErrors('reject', { method: 'POST', pushToStore: true });
  confirm = modelActionWithErrors('confirm', { method: 'POST', pushToStore: true });
  cancel = modelActionWithErrors('cancel', { method: 'POST', pushToStore: true });
  _convert = modelActionWithErrors('convert', { method: 'POST', pushToStore: true });
  requestCapacityOverride = modelActionWithErrors('request-capacity-override', { method: 'POST' });
  approveCapacityOverride = modelActionWithErrors('approve-capacity-override', {
    method: 'POST',
    pushToStore: true
  });
  denyCapacityOverride = modelActionWithErrors('deny-capacity-override', {
    method: 'POST',
    pushToStore: true
  });

  /*
   * Computed Properties
   */

  @or('order.pickupAt', 'pickupAt')
  actualPickupAt;

  @or('order.deliverAt', 'deliverAt')
  actualDeliverAt;

  @or('order.state', 'state')
  actualState;

  @or('order.restaurant', 'restaurant')
  actualRestaurant;

  @or('first', 'favourite', 'lastWeek', 'favouriteCuisine')
  hasSpecialState;

  @or('first', 'lastWeek', 'distance.isBeyondExtendedRadius', 'distance.inExtendedRadius')
  hasSoftWarnings;

  @computed('warnings.isEmpty', 'hasSoftWarnings')
  get softWarningCount() {
    return [
      this.first,
      this.lastWeek,
      this.distance.isBeyondExtendedRadius,
      this.distance.inExtendedRadius
    ].filter((_) => _).length;
  }

  @computed('warnings.length', 'ignoresCapacityLimit')
  get hasCapacityErrors() {
    return this.warnings?.warnings?.get('restaurantCapacity')?.length;
  }

  @computed('warnings.length', 'ignoresCapacityLimit', 'hasNonCapacityErrors')
  get errorsCount() {
    if (
      this.hasNonCapacityErrors &&
      this.ignoresCapacityLimit &&
      !this.hasCapacityIgnoredWithCapacityErrorsAndNoOtherErrors
    ) {
      const num = this.warnings.length;
      return num - 1;
    }

    if (this.hasCapacityIgnoredWithCapacityErrorsAndNoOtherErrors) {
      return 0;
    }

    return this.warnings.length;
  }

  @computed('warnings.isEmpty', 'hasSoftWarnings')
  get showSoftWarnings() {
    return (
      (this.warnings.isEmpty && this.hasSoftWarnings) ||
      (this.hasCapacityIgnoredWithCapacityErrorsAndNoOtherErrors && this.hasSoftWarnings)
    );
  }

  @computed('warnings.isEmpty', 'ignoresCapacityLimit', 'hasNonCapacityErrors')
  get hasCapacityIgnoredWithCapacityErrorsAndNoOtherErrors() {
    return (
      this.ignoresCapacityLimit &&
      this.warnings?.warnings?.get('restaurantCapacity')?.length &&
      !this.hasNonCapacityErrors
    );
  }

  @computed(
    'warnings',
    'ignoresCapacityLimit',
    'hasCapacityIgnoredWithCapacityErrorsAndNoOtherErrors'
  )
  get hasNonCapacityErrors() {
    const allWarnings = this.warnings?.warnings || new Map();
    let hasErrors = false;

    // eslint-disable-next-line no-unused-vars
    for (let entry of allWarnings.keys()) {
      if (entry && entry !== 'restaurantCapacity') {
        hasErrors = true;
      }
    }

    return hasErrors;
  }

  @alias('restaurant.activeMenu')
  menu;

  @computed('actualState')
  get style() {
    const state = this.actualState ?? OrderState.None;
    let style = [`border-color: ${state.color}`];

    if (this.state.isConverted) {
      style.push(`background-color: ${state.color}`);
    }

    return htmlSafe(style.join(';'));
  }

  @computed('deliverAt', 'pickupAt', 'restaurant')
  get humanize() {
    return `Reservation for ${this.get('numberOfPeople')}`;
  }

  reloadWith(include) {
    return this.store.findRecord('meal-planning-reservation', this.get('id'), {
      reload: true,
      include: include
    });
  }

  get m() {
    return moment;
  }

  @computed('day.reservations.[]')
  get meal() {
    const day = this.get('day');
    if (day) {
      return day.get('meals').find((_) => _.get('reservations').includes(this));
    }
    return undefined;
  }

  @computed('mealPlanningInstance.content.plan.days.[]')
  get day() {
    const days = this.get('mealPlanningInstance.content.plan.days');
    if (days) {
      return days.find((_) => _.get('reservations').includes(this));
    }

    return undefined;
  }

  @computed
  get identifier() {
    return `Reservation ${this.get('id')}`;
  }

  @alias('appConfiguration.mealPlanning.preferences.sameDayDeadlineRanges')
  sameDayDeadlineRanges = [];

  @computed('deliverAt', 'deadlineIsSameDay', 'area.isoTimeZone')
  get sameDayDeadlineTime() {
    if (!this.deliverAt) {
      return null;
    }
    const deadlineTime = this.sameDayDeadlineRanges.reduce((time, range) => {
      if (time) {
        return time;
      }
      const { startTime, endTime, deadlineTime } = range;
      const start = moment(startTime, 'HH:mm');
      const end = moment(endTime, 'HH:mm');
      const deadline = moment(deadlineTime, 'HH:mm');
      const deliveryTimeOnly = moment
        .tz(this.deliverAt, this.get('area.isoTimeZone'))
        .format('HH:mm');
      const deliveryTimeAsToday = moment(deliveryTimeOnly, 'HH:mm');
      // set all timestamps to today to compare time only
      if (deliveryTimeAsToday.isSameOrAfter(start) && deliveryTimeAsToday.isBefore(end)) {
        return deadline.format('h:mma');
      }
    }, null);
    return deadlineTime;
  }

  @bool('sameDayDeadlineTime') isDeliveryTimeInSameDayDeadlineRanges;

  requestNewRestaurant = modelActionWithErrors('plan', { pushToStore: true, method: 'POST' });
}
