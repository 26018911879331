import { helper as buildHelper } from '@ember/component/helper';
import { capitalize, dasherize } from '@ember/string';

export function titleize(params) {
  return dasherize(params[0] || '')
    .replace(/-/g, ' ')
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
}

export default buildHelper(titleize);
