import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';

const tagTypeToCollectionMap = {
  MEAL: 'mealTypes',
  FOOD: 'foodTypes',
  DIETARY: 'dietaryTags',
  ALLERGY: 'allergyTags',
  PACKAGING: 'packingTags'
};

const tagName = {
  MEAL: 'meal type',
  FOOD: 'food type',
  DIETARY: 'dietary tag',
  PACKAGING: 'packaging tag'
};

@classic
export default class MealPlanningRequirementConstraint extends Model {
  /*
   * Attributes
   */

  @attr('string')
  constraintType;

  @attr('string')
  tagType;

  @attr('boolean')
  isReadonly;

  @belongsTo('meal-planning-requirement')
  requirement;

  @hasMany('meal-type')
  mealTypes;

  @hasMany('food-type')
  foodTypes;

  @hasMany('dietary-tags')
  dietaryTags;

  @hasMany('dietary-tags')
  allergyTags;

  @hasMany('dietary-tags')
  packagingTags;

  @computed(
    'mealTypes.[]',
    'foodTypes.[]',
    'dietaryTags.[]',
    'allergyTags.[]',
    'packagingTags.[]',
    'tagType'
  )
  get tagCollection() {
    if (this.get('tagType')) {
      return this.get(tagTypeToCollectionMap[this.get('tagType')]) || [];
    } else {
      return [];
    }
  }

  @computed('tagCollection.[]')
  get includedTags() {
    return this.get('tagCollection')
      .map((tag) => tag.name)
      .filter((tagName) => !!tagName)
      .join(', ');
  }

  @computed('tagType')
  get tagCollectionName() {
    return tagTypeToCollectionMap[this.get('tagType')];
  }

  // isEmpty: computed.empty('tagCollection'),

  @computed('tagCollection', 'constraintType')
  get description() {
    const isDietary = this.get('tagType') === 'DIETARY' || this.get('tagType') === 'ALLERGY';
    const key = isDietary ? 'abbreviation' : 'name';

    let names = (this.get('tagCollection') || []).mapBy(key);

    if (names.length === 0 && !isDietary) {
      return `any ${tagName[this.get('tagType')]}`;
    }
    if (names.length === 0 && isDietary) {
      return '';
    }

    const constraintType = this.get('constraintType');

    if (constraintType === 'ANY' || constraintType === 'NOT') {
      names = names.join(' or ');
    } else {
      names = names.join(' & ');
    }

    if (constraintType === 'NOT') {
      return `<strong>Not</strong> ${names}`;
    } else {
      return names;
    }
  }

  /**
   * @return {Object}
   */
  toQuery() {
    return this.get('tagCollection').mapBy('name');
  }

  /**
   * See's if the constraint applies to a given menuItem
   * @param menuItem
   * @returns {boolean}
   */
  apply(menuItem) {
    const tagCollection = this.get('tagCollection');
    return (
      this[this.get('constraintType').toLowerCase()](tagCollection, menuItem) ||
      tagCollection.length === 0
    );
  }

  /**
   * @return {boolean}
   */
  all(tagCollection, menuItem) {
    return (tagCollection || []).every((_) => _.matches(menuItem));
  }

  /**
   * @return {boolean}
   */
  any(tagCollection, menuItem) {
    return (tagCollection || []).any((_) => _.matches(menuItem));
  }

  /**
   * @return {boolean}
   */
  not(tagCollection, menuItem) {
    return (tagCollection || []).every((_) => !_.matches(menuItem));
  }

  /**
   * TODO: Work this in once searchkick implements this
   * @return {boolean}
   */
  exact(hayStack, needles) {
    return this.all(hayStack, needles) && hayStack.length === needles.length;
  }
}
