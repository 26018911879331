import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class ShowRoute extends Route {
  model(params) {
    return this.store.findRecord('restaurants-brand', params.restaurant_brand_id, {
      reload: true,
      include: 'restaurants'
    });
  }
}
