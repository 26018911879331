import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@tagName('')
export default class UsersItem extends Component {
  /** @type {User} */
  item = null;

  /** @type {User} */
  @alias('item')
  user;
}
