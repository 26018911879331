import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class AreaClosure extends Model {
  // Validations
  validations = {
    startBlock: {
      presence: true
    },
    endBlock: {
      presence: true
    }
  };

  @attr('date')
  startBlock;

  @attr('date')
  endBlock;

  @belongsTo('area')
  area;
}
