import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';

@classic
export default class UsersRoute extends Route.extend(CapabilityRouteMixin, LoadingRouteMixin) {
  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    teamName: { refreshModel: true },
    searchText: { refreshModel: true }
  };

  capability = 'CLIENT_USERS_SHOW';

  model(params) {
    const { pageLimit, pageOffset, teamName, searchText } = params;

    const client = this.modelFor('logged-in.clients.edit');

    const teamQueryOptions = {
      filter: {}
    };

    const userQueryOptions = {
      filter: {},
      page: {
        limit: pageLimit,
        offset: pageOffset
      },
      include: 'payment-cards',
      reload: true
    };

    if (searchText !== null && searchText !== '') {
      userQueryOptions.filter.search = searchText;
    }

    if (teamName !== null && teamName !== '') {
      teamQueryOptions.filter.name = teamName;
    }

    if (client.get('manageTeams')) {
      /**
       * A second query will modify the mutable array of values in the first query.
       * Storing queries as arrays allows separation and preservation of multiple queries
       * to the team and user models.
       * Resulting model has the following properties:
       * model.client
       * model.allTeams
       * model.selectedTeam
       * model.users
       */
      return RSVP.hash({
        client: client,
        dietaryTags: this.store.findAll('dietary-tag', { page: { limit: 200 } }),
        allTeams: this.get('cachedAllTeams') || client.query('teams', { reload: true, limit: 200 })
      }).then((result) => {
        result.allTeams = result.allTeams.toArray();

        //allTeams has been set. CachedAllTeams will prevent it from being reset when
        //changes to query params refresh the model.
        this.set('cachedAllTeams', result.allTeams.toArray());

        return client
          .query('teams', teamQueryOptions)
          .then((teams) => {
            result.selectedTeam = teams.get('firstObject');
            return result;
          })
          .then((result) => {
            return result.selectedTeam.query('members', userQueryOptions).then((members) => {
              result.usersMeta = members.meta;
              result.users = members.toArray();
              return result;
            });
          });
      });
    } else {
      return RSVP.hash({
        client: client
      });
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    if (model.client.get('manageTeams')) {
      controller.send('resetInviteNewUsersForm');

      const usersRecordCount = model.usersMeta.recordCount || 0;

      controller.set('recordCount', usersRecordCount);
    } else {
      controller.set('isLoading', true);

      this.store
        .findRecord('client', model.client.get('id'), {
          include: ['admins.payment-cards', 'owner.preference-profile'].join(',')
        })
        .then((_) => controller.set('isLoading', false));
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }

  @action
  clearCachedAllTeams() {
    this.set('cachedAllTeams', null);
  }
}

/**
 * A note on this model's states:
 *
 * First page load:
 * model.client - client's edit model
 * model.allTeams - query is sent to get allTeams
 * model.selectedTeam - client's default team
 * model.users - users in the client's default team
 *
 *
 * When a different team is selected:
 *
 * model.client - is client's edit model
 * model.allTeams - the cached query from the first page load is re-used
 * model.selectedTeam - the team selected by the user
 * model.users - the users in the selected team
 *
 *
 * When a new team is added:
 *
 * model.client - is client's edit model
 * model.allTeams - the cached query from the first page load is wiped away and the query is refreshed.
 * model.selectedTeam - the team last selected by the user
 * model.users - the users in the selected team
 *
 *
 * When a user is searched for:
 *
 * model.client - is client's edit model
 * model.allTeams - the cached query
 * model.selectedTeam - the team last selected by the user
 * model.users - users users in the selected team that match the user searchText
 *
 *
 * When a new user is added:
 *
 * model.client - is client's edit model
 * model.allTeams - the cached query
 * model.selectedTeam - the team selected by the user
 * model.users - the updated users in the selected team
 */
