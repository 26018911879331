import classic from 'ember-classic-decorator';
import FeedItemBuilder from './base';

@classic
export default class UserBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);
    const [oldRoles, newRoles] = version.get('misc.relationshipChanges.roles') || [];
    const event = version.get('event');
    const hasChangedRoles = oldRoles && newRoles && oldRoles.length !== newRoles.length;

    feedItem.icon = 'user';

    if (event === 'update' && hasChangedRoles) {
      const hasAdded = oldRoles.length < newRoles.length;
      const userAction = hasAdded ? 'added' : 'removed';

      const changedRoles = hasAdded
        ? newRoles.reject((role) => oldRoles.includes(role))
        : oldRoles.reject((role) => newRoles.includes(role));

      const pluralizedRoles = changedRoles.length > 1 ? 'roles' : 'role';

      feedItem.subject = `${version.get(
        'whodunnit'
      )} ${userAction} the ${pluralizedRoles} ${changedRoles.join(', ')}`;
    }

    return feedItem;
  }
}
