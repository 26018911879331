import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

/**
 * Simple component for committing fields
 */
@classic
@tagName('')
export default class ProfilePicture extends Component {
  /** @type {User} */
  user = null;

  /** @type {string} */
  size = 'mini';
}
