import { helper as buildHelper } from '@ember/component/helper';

/**
 * Returns the property of the object based on key.
 * Avoids any fancy dot splitting that the normal get does, and works reliably on POJOs
 *
 * @param {(string|number|null)[]}
 * @returns {*}
 */
export function getProp([object, prop]) {
  if (!object || !prop) {
    return undefined;
  }

  return object[prop];
}

export default buildHelper(getProp);
