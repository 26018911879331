import classic from 'ember-classic-decorator';
import Component from '@ember/component';

@classic
export default class IconHeader extends Component {
  /** @type {string} */
  heading = null;

  /** @type {string} */
  subHeading = null;

  /** @type {string} */
  icon = null;
}
