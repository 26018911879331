import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@ember/component';
import RSVP from 'rsvp';
import moment from 'moment-timezone';
import $ from 'jquery';

@classic
export default class QuickSimulation extends Component {
  @service
  store;

  @service
  ajax;

  order = null;
  orders = null;
  speed = { id: 'fast', label: 'fast' };
  initialLocation = null;
  speeds = ['slow', 'medium', 'fast'];
  area = null;
  client = null;
  clients = null;
  restaurant = null;
  restaurants = null;
  user = null;
  users = null;
  instance = null;
  deliverAt = null;

  @action
  async areaDidChange(area) {
    const clients = area.query('clients', { page: { limit: 200 } });
    const restaurants = area.query('restaurants', { page: { limit: 200 } });
    const orders = area.query('orders', {
      fields: { orders: 'id,identifier' },
      page: { limit: 200 },
      filter: { state: ['scheduled', 'driver_confirmed'] }
    });

    this.setProperties(
      await RSVP.hash({
        clients,
        restaurants,
        orders
      })
    );
  }

  @action
  async clientDidChange(client) {
    const teams = await client.get('teams');
    const defaultTeam = teams.get('firstObject');
    const users = defaultTeam.query('members', { page: { limit: 200 } });

    const mealPlanningInstances = client.query('mealPlanningInstances', {
      fields: {
        'meal-planning-instances': 'id,name'
      },
      page: { limit: 200 }
    });

    this.setProperties(await RSVP.hash({ users, mealPlanningInstances }));
  }

  async show() {
    if (!this.get('areas')) {
      this.set(
        'areas',
        await this.get('store').query('area', { filter: { active: true }, page: { limit: 200 } })
      );
    }
    this.set('isShowing', true);
    this.didShow();
  }

  hide() {
    this.set('isShowing', false);
    this.didHide();
  }

  didShow() {}
  didHide() {}

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.reset();
    // Doesn't technically need to be cleaned up as this is around for the life cycle of the app
    $(document).on('keydown', (e) => {
      // TODO make this not a magic int
      if (e.key === 's' && (e.ctrlKey || e.metaKey)) {
        this.show();
      }
    });
  }

  keyDown(e) {
    switch (e.key) {
      case 's':
        if (e.ctrlKey || e.metaKey) {
          this.hide();
          e.stopPropagation();
        }
        break;
      case 'Escape':
        this.hide();
        break;
    }
  }

  postOrderingSimulation(uri) {
    return this.get('ajax')
      .postJSON(uri, {
        area_id: this.get('area.id'),
        order_attributes: {
          client_id: this.get('client.id'),
          restaurant_id: this.get('restaurant.id'),
          deliver_at: this.get('deliverAt'),
          pickup_at: moment(this.get('deliverAt')).subtract(30, 'minutes').toDate()
        }
      })
      .then((_) => this.set('orderId', _.order_id));
  }

  @action
  reset() {
    this.setProperties({
      area: null,
      user: null,
      users: null,
      client: null,
      clients: null,
      instance: null,
      order_id: null,
      order_ids: null,
      // default to next monday
      deliverAt: moment().add(1, 'week').startOf('week').add(36, 'hours').toDate()
    });

    return Promise.resolve(true);
  }

  @action
  simulateOrdering() {
    return this.postOrderingSimulation('/api/simulation/ordering');
  }

  @action
  simulateGroupOrdering() {
    return this.postOrderingSimulation('/api/simulation/group-ordering');
  }

  @action
  simulateTeamOrdering() {
    return this.postOrderingSimulation('/api/simulation/team-ordering');
  }

  @action
  simulateDelivery() {
    return this.get('ajax').postJSON('/api/simulation/delivery', {
      order_id: this.get('order.id'),
      speed: this.get('speed.id'),
      initial_location: this.get('initialLocation')
    });
  }

  @action
  simulateInstanceOrdering() {
    return this.get('ajax')
      .postJSON('/api/simulation/instance-ordering', {
        instance_id: this.get('instance.id'),
        user_id: this.get('user.id')
      })
      .then((_) => this.set('orderIds', _.order_ids));
  }
}
