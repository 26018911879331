import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import Component from '@ember/component';

/**
 * A table footer wrapper for the pagination control
 */
@classic
@classNames('fde-tables-ordered-table-pagination-footer')
@tagName('tfoot')
class PaginationFooter extends Component {
  /** @type {number} number of records per page */
  pageLimit = 10;

  /** @type {number} number of records we are currently offset to */
  pageOffset = 0;

  /** @type {string} */
  pageLimitKey = 'pageLimit';

  /** @type {string} */
  pageOffsetKey = 'pageOffset';

  /** @type {number} total number of records in this paginated set */
  recordCount = 0;

  /** @type {string} base route that the pagination will be conducted against by way of query params */
  route = '';

  /** @type {string} */
  sortByString = '';

  /** @type {Number} */
  @computed('pageOffset')
  get firstResult() {
    return this.get('pageOffset') + 1;
  }

  /** @type {Number} */
  @computed('pageOffset', 'pageLimit')
  get lastResult() {
    return this.get('pageOffset') + this.get('pageLimit');
  }

  /** @type {string} */
  @computed('sortByString')
  get humanFriendlySortString() {
    return this.get('sortByString')
      .split(',')
      .map((key) => {
        if (key.startsWith('-')) {
          return `${key.substring(1)} DESC`;
        } else {
          return `${key} ASC`;
        }
      })
      .join(', ');
  }

  /**
   * Callback when the sort changes
   */
  onSortChange() {}

  /**
   * Callback to clear the sort
   */
  @action
  clearSort() {
    this.onSortChange('');
  }
}

export default PaginationFooter;
