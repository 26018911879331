import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class OrderItem extends Application {
  attrs = {
    createdAt: {
      serialize: false
    },

    updatedAt: {
      serialize: false
    },

    groupOrderMember: {
      serialize: true
    },

    maximumServing: {
      serialize: false
    },

    minimumServing: {
      serialize: false
    }
  };
}
