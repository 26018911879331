import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@tagName('')
export default class DrawerHeader extends Component {
  /** @type {boolean} */
  icon = false;

  /** @type {boolean} */
  title = false;

  /** @type {boolean} */
  subTitle = false;

  close() {}
}
