import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';

@classic
export default class PayrollController extends Controller {
  /** @type {User} user model */
  @alias('model')
  user;
}
