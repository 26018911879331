import { attr } from '@ember-data/model';
import { union } from '@ember/object/computed';
import { computed } from '@ember/object';
import MF from 'ember-data-model-fragments';

export default MF.Fragment.extend({
  key: attr('object'),
  count: attr('number'),

  dietaryTags: computed('key.dietaryTags.length', function () {
    return this.get('key.dietaryTags');
  }),

  allergyTags: computed('key.allergyTags.length', function () {
    return this.get('key.allergyTags');
  }),

  allTags: union('dietaryTags', 'allergyTags'),

  name: computed('allTags.[]', function () {
    const allTags = this.get('allTags');

    if (allTags.get('length') === 0) {
      return 'Omni';
    } else {
      return allTags.join(',');
    }
  }),

  tags: computed('allTags.[]', function () {
    const allTags = this.get('allTags');
    return this.store
      .peekAll('dietary-tag')
      .filter((tag) => allTags.includes(tag.get('abbreviation')));
  })
});
