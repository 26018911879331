import classic from 'ember-classic-decorator';
import Component from '@ember/component';

@classic
export default class SortableList extends Component {
  /** @type {Array} items to be selected */
  items = null;

  /** @type {String} name to distinguish which list */
  groupName = null;

  /** @type {boolean} whether or not the current list item is selectable */
  isSelectable = false;

  /** @type {*} The currently selected item */
  selected = null;

  /** @type {boolean} */
  readonly = false;

  /**
   * Called when ever the an item in the list is selected
   *
   * @param _item
   */
  onItemSelect(_item) {}

  /**
   * Called whenever the list is reordered
   *
   * @param _listItems, _model
   */
  onReorder(_listItems, _model) {}

  /**
   * Called whenever we finish dragging the item
   *
   * @param _listItem
   */
  onDraggingStopped(_listItem) {}
}
