import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import moment from 'moment';

@classic
export default class MealPlanningInstanceController extends Controller {
  /** @type {?MealPlanningInstance} */
  selectedInstance = null;

  /** @type {String} Defines the format to display a meal planning instance's startOfWeek */
  instanceDateDisplayFormat = 'MMMM Do YYYY';

  /** @type {?mealPlanningInstance[]} */
  @alias('model.mealPlanningInstances')
  mealPlanningInstances;

  /**
   * @type {Array.{title: String, data: []}} An array of objects with a title key and a data key
   *
   * Example
   * [
   *  {title: 'Foo', data: [MealPlanningInstance]},
   *  {title: 'Bar', data: [MealPlanningInstance]}
   * ]
   */
  @computed('mealPlanningInstances.[]')
  get sortedMealPlanningInstances() {
    return this._sortPastAndFutureInstances();
  }

  /**
   * Sorts mealPlanningInstances into upcoming/current instances
   * and past instances. Current and upcoming instances are any
   * instance with their startOfWeek in the future or the beginning
   * of the current week.
   *
   * @returns {*[]}
   * @private
   */
  _sortPastAndFutureInstances() {
    const mealPlanningInstances = this.get('mealPlanningInstances');
    const futureInstances = { title: 'Future', data: [] };
    const pastInstances = { title: 'Past', data: [] };

    const beginningOfWeek = moment().isoWeekday(1);

    mealPlanningInstances.forEach((mpi) => {
      moment(mpi.get('startOfWeek')).isSameOrAfter(beginningOfWeek)
        ? futureInstances.data.push(mpi)
        : pastInstances.data.push(mpi);
    });

    return [futureInstances, pastInstances];
  }

  @action
  selectInstance(instance) {
    this.set('selectedInstance', instance);
  }
}
