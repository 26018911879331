import Component from '@glimmer/component';
import { action } from '@ember/object';
import hljs from 'highlight.js';

export default class SfHighlightCodeComponent extends Component {
  @action
  initHighlight(codeEl) {
    const language = this.args.language;
    const options = Object.assign({}, { language });

    hljs.highlightElement(codeEl, options);
  }
}
