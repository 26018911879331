import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';

@classic
export default class EditRoute extends Route {
  model(params) {
    console.debug('Grabbing order:', params.order_id);

    return this.store
      .findRecord('order', params.order_id, {
        reload: true,
        include: [
          'restaurant',
          'order-items.menu-item',
          'menu.menu-groups.menu-items.dietary-tags',
          'menu.menu-groups.menu-items.menu-option-groups.menu-option-items'
        ].join(',')
      })
      .then((order) => {
        const restaurant = order.get('restaurant');

        const menu = restaurant && order.get('menu');

        return RSVP.hash({
          order,
          restaurant,
          menu,
          activeMenu: restaurant
            .query('menus', {
              filter: {
                active: true
              },
              include: [
                'menu-groups.menu-items.dietary-tags',
                'menu-groups.menu-items.menu-option-groups.menu-option-items'
              ].join(',')
            })
            .then((menus) => menus.get('firstObject')),
          invoice: this.store.findRecord('invoice', params.order_id, {
            reload: true
          })
        });
      });
  }

  // Subscribe to pusher events,
  // the channelName is defined in the controller.
  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setupPusher();
  }

  // Unsubscribe from the pusher channels we've been listening to.
  // Clean up when we leave. We probably don't want  to still be receiving events.
  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);

    controller.teardownPusher();
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
