import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import $ from 'jquery';
import { action } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('sf-logistics-pickup-time-select')
export default class PickupTimeSelect extends Component {
  didInsertElement() {
    super.didInsertElement(...arguments);
    const timePickerEl = $('.sf-logistics-pickup-time-select');

    timePickerEl.parents('.lt-cell').on('click', function (event) {
      // Need to stop the cell from expanding when clicking cell
      event.stopPropagation();
    });
  }

  @action
  pickupTimeUpdated() {
    this.pickupTimeUpdated();
  }
}
