import classic from 'ember-classic-decorator';
import { get } from '@ember/object';
import FeedItemBuilder from './base';
import { capitalize } from '@ember/string';

@classic
export default class EmailLogsEventsOrderBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);
    const customFields = version.getValue('customFields');
    const emailType = capitalize(
      get(customFields, 'eventData.category.firstObject').replace(/_/g, ' ')
    );
    const recipient = get(customFields, 'eventData.email');
    const subject = get(customFields, 'eventData.subject');
    const event = get(customFields, 'eventData.event');

    feedItem.icon = 'paper plane';
    feedItem.subject = `SendGrid sent '${event}' for '${emailType}' to ${recipient}`;
    feedItem.meta = [`Subject: ${subject}`, `To: ${recipient}`];
    feedItem.details = '';

    return feedItem;
  }
}
