import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import EmberObject, { action, computed } from '@ember/object';

const order = EmberObject.create();

const customOrderItemFor = () => {
  return EmberObject.create({
    order,
    name: this.get('name'),
    customDescription: this.get('description'),
    customClientPrice: this.get('clientPriceCents'),
    customRestaurantPrice: this.get('restaurantPriceCents')
  });
};

@classic
class MenuGroup extends EmberObject {
  matches(requirement) {
    return this.get('menuItems')
      .toArray()
      .some((_) => _.matches(requirement));
  }
}

@classic
class MenuItem extends EmberObject {
  matches(requirement) {
    return !requirement || requirement.apply(this);
  }
}

const menu = EmberObject.create({
  name: "Joe's Donuts",
  menuGroups: [
    {
      name: 'Breakfast Burritos',
      description: 'Crispy potatoes, scrambled egg, vegetarian green chili and shredded lettuce.',
      internal: false,
      external: true,
      menuItems: [
        {
          name: 'Applewood smoked bacon — large',
          description: 'Grain-fed ethically raised buttermilk donuts tossed in cane sugar.',
          clientPriceCents: '290',
          restaurantPriceCents: '200',
          customOrderItemFor: customOrderItemFor
        },
        {
          name: 'Hatch Chili Chicken Sausage',
          description: 'Old fashioned hens slow cook roosters in a delicious Hatch chili sauce',
          clientPriceCents: '790',
          restaurantPriceCents: '700',
          customOrderItemFor: customOrderItemFor
        },
        {
          name: 'Salad',
          description: 'You degenerate.',
          clientPriceCents: '9999',
          restaurantPriceCents: '100',
          customOrderItemFor: customOrderItemFor
        },
        {
          name: 'Vanilla dog-nuts',
          description: 'Half dozen new age donuts tossed in vanilla sugar.',
          clientPriceCents: '820',
          restaurantPriceCents: '550',
          customOrderItemFor: customOrderItemFor
        }
      ].map((_) => MenuItem.create(_))
    },
    {
      name: 'Rodentia',
      description: 'A whole variety of arboreal, fossorial, or semiaquatic',
      internal: false,
      external: true,
      menuItems: [
        {
          name: 'Trio of mice',
          description:
            'Red, grey and white mice served cold with salsa fresco, raw as carpaccio and warm in a decadent 3-day mole sauce',
          clientPriceCents: '1490',
          restaurantPriceCents: '1000',
          customOrderItemFor: customOrderItemFor
        }
      ].map((_) => MenuItem.create(_))
    },
    {
      name: 'Bones and miscellany',
      description: 'Tasty decadent marrow, lets enjoy the nectar',
      internal: false,
      external: true,
      menuItems: [
        {
          name: 'Beef marrow',
          description: 'Chiffonade of herbs, oiled crostini and smoked vanilla salt',
          clientPriceCents: '890',
          restaurantPriceCents: '650',
          customOrderItemFor: customOrderItemFor
        },
        {
          name: 'Cracked wing tips',
          description: 'Smashed and broken, seasoned and fried until crispy',
          clientPriceCents: '1290',
          restaurantPriceCents: '1000',
          customOrderItemFor: customOrderItemFor
        },
        {
          name: 'Meaty pork hock',
          description:
            'Baked, covered in a smear of lemon grass and garlic — this is for gnawing at cartilage and meaty treasures',
          clientPriceCents: '1890',
          restaurantPriceCents: '1550',
          customOrderItemFor: customOrderItemFor
        }
      ].map((_) => MenuItem.create(_))
    },
    {
      name: 'Barbiturates',
      description: 'From mild sedation to total anesthesia',
      internal: true,
      external: false,
      menuItems: [
        {
          name: 'Codeine',
          description: 'Hush, there, there, hey now...',
          clientPriceCents: '1000',
          restaurantPriceCents: '200',
          customOrderItemFor: customOrderItemFor
        },
        {
          name: 'Morphine',
          description: 'All natural typically found in a number of plants and animals',
          clientPriceCents: '1290',
          restaurantPriceCents: '600',
          customOrderItemFor: customOrderItemFor
        },
        {
          name: 'Pholcodine',
          description: 'For those with a little after dinner cough',
          clientPriceCents: '990',
          restaurantPriceCents: '500',
          customOrderItemFor: customOrderItemFor
        }
      ].map((_) => MenuItem.create(_))
    }
  ].map((_) => MenuGroup.create(_))
});

const dietaryTags = [
  {
    name: 'vegan',
    slug: 'vegan',
    abbreviation: 'vg',
    color: '#104C5C'
  },
  {
    name: 'dairy free',
    slug: 'dairy-free',
    abbreviation: 'df',
    color: '#353585'
  },
  {
    name: 'vegetarian',
    slug: 'vegetarian',
    abbreviation: 'v',
    color: '#3BB76C'
  },
  {
    name: 'gluten free',
    slug: 'gluten-free',
    abbreviation: 'gf',
    color: '#F68B29'
  }
].map((_) => EmberObject.create(_));

const menuGroup = (version) => {
  return {
    name: `Breakfast Burritos ${version}`,
    description: 'Crispy potatoes, scrambled eggs',
    internalDescription: '...and your Mom.',

    menuItems: [menuItem('A'), menuItem('B'), menuItem('C'), menuItem('D')].map((_) =>
      EmberObject.create(_)
    )
  };
};

const menuItem = (version) => {
  return EmberObject.create({
    name: `Dragon's Egg nibblets ${version}`,
    description: "Savoury poached dragon's eggs",
    internalDescription: "...lovingly dressed in shaved wolf's nipple chips.",
    clientPriceCents: 290,
    retailPrice: 0,
    clientPrice: 0,
    restaurantPrice: 0,
    menuOptionGroups: [menuOptionGroup(1), menuOptionGroup(2), menuOptionGroup(3)],
    dietaryTags: [dietaryTags[1], dietaryTags[2]],
    isNew: false,
    errors: EmberObject.create()
  });
};

const menuOptionGroup = (version) => {
  const verb = ['add', 'substitute', 'pick', 'remove'][Math.floor(Math.random() * 4)];

  const mog = EmberObject.create({
    name: `Cheeses ${version}`,
    labelName: `Generated label for ${version}`,
    verb,
    menuOptionItems: [menuOptionItem(1), menuOptionItem(2), menuOptionItem(3)],
    errors: EmberObject.create(),
    isNew: false
  });

  mog.reopen({
    isSingleOpt: computed('verb', function () {
      const verb = this.get('verb');
      return verb == 'substitute' || verb == 'pick';
    })
  });

  return mog;
};

const menuOptionItem = (version) => {
  return EmberObject.create({
    name: `Stilton ${version}`,
    retailPrice: 1000,
    restaurantPrice: 900,
    clientPrice: 1200,
    taxRate: 0.05,
    labelName: `Genrated label for ${version}`,
    isNew: false,
    errors: EmberObject.create()
  });
};

export default Controller.extend({
  order,
  menuItemWithMenuOptionGroups: menuItem('with existing menu option groups'),

  firstMenuOptionGroup: computed('menuItemWithMenuOptionGroups', function () {
    return this.get('menuItemWithMenuOptionGroups.menuOptionGroups.firstObject');
  }),

  menuItemWithNewMenuOptionGroup: computed('', function () {
    const mi = menuItem('new menu option groups');
    const mog = menuOptionGroup('new menu option group');
    mog.set('isNew', true);

    const moi = menuOptionItem('new menu option item');
    moi.set('isNew', true);

    mog.set('menuOptionItems', [moi]);
    mi.set('menuOptionGroups', [mog]);

    return mi;
  }),

  newMenuOptionGroup: computed('menuItemWithNewMenuOptionGroup', function () {
    return this.get('menuItemWithNewMenuOptionGroup.menuOptionGroups.firstObject');
  }),

  canSaveMenuOptionGroup: computed(
    'newMenuOptionGroup',
    'hasVerbCahanged',
    'hasNameChanged',
    'hasNewMenuOptionItem',
    function () {
      const mog = this.get('newMenuOptionGroup');
      const isNew = mog.get('isNew');
      const hasVerbChanged = this.get('hasVerbChanged');
      const hasNameChanged = this.get('hasNameChanged');
      const hasNewMenuOptionItem = this.get('hasNewMenuOptionItem');

      return isNew || hasVerbChanged || hasNameChanged || hasNewMenuOptionItem;
    }
  ),

  /** @type {object} I like big butts...and I cannot lie */
  menuItem: menuItem('X'),

  menuOptionGroupToEdit: computed('menuItemToEdit', function () {
    return this.get('menuItemToEdit.menuOptionGroups.firstObject');
  }),

  hasNewMenuOptionItem: computed('menuItemWithNewMenuOptionGroup', function () {
    const menuOptionGroups = this.get('menuItemWithNewMenuOptionGroup.menuOptionGroups');

    return menuOptionGroups.some((_) => _.get('menuOptionItems').some((_) => _.get('isNew')));
  }),

  pickedMenuItemMessage: 'Nothing, yet',
  availableDietaryTags: dietaryTags,
  orderMenu: menu,

  menu: EmberObject.create({
    menuGroups: [menuGroup('1'), menuGroup('2'), menuGroup('3'), menuGroup('4')].map((_) =>
      EmberObject.create(_)
    )
  }),

  areaTaxRates: [
    {
      id: 1,
      name: 'OPP'
    },
    {
      id: 2,
      name: 'WOOF'
    },
    {
      id: 3,
      name: 'YOLO'
    }
  ].map((_) => EmberObject.create(_)),

  finishEditing: action(function (thing) {
    console.log('Finished editing menu option group', thing);
  }),

  createMenuOptionGroup: action(function (menuItem) {
    menuItem.get('menuOptionGroups').pushObject(menuOptionGroup(Math.floor(Math.random() * 100)));
  }),

  createMenuOptionItem: action(function (mog) {
    mog.get('menuOptionItems').pushObject(menuOptionItem(Math.floor(Math.random() * 100)));
  }),

  customOrderItemFor: action(function (order) {
    return this.store.createRecord('order-item', {
      order,
      customDescription: this.get('description'),
      customClientPrice: this.get('clientPriceCents'),
      customRestaurantPrice: this.get('restaurantPriceCents')
    });
  }),

  handleClick: action(function (menuItem) {
    this.set('pickedMenuItemMessage', menuItem.get('name'));
  }),

  updateMenuItem: action(function (key, value) {
    this.get('menuItem').setProperties(value);
  }),

  clearModelErrors: action(function (model, field) {
    this.set(`${model}.errors.${field}`, []);
  }),

  setModelErrors: action(function (model, field) {
    this.set(`${model}.errors.${field}`, [
      {
        message: `${field} is in error now.`
      }
    ]);
  })
});
