import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import url from 'renard/utils/url';

@classic
@classNames('fde-orders-email-messages-email-message')
export default class EmailMessage extends Component {
  /** @type {EmailMessage} */
  emailMessage = null;

  /** @type {string} Returns a string for the email message link consistent with the current environment/host */
  @computed
  get emailLink() {
    const emailId = this.get('emailMessage.id');
    const hostPath = url.getHostPath();

    return `${hostPath}/sent_mails/${emailId}`;
  }
}
