import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class Area extends Application {
  attrs = {
    invoicingTaxRates: {
      serialize: true
    },
    deliveryFeeTaxRate: {
      serialize: true
    },
    clients: {
      serialize: false
    },
    restaurants: {
      serialize: false
    },
    features: {
      serialize: false
    }
  };
}
