import { inject as controller } from '@ember/controller';
import { action, computed } from '@ember/object';
import { assign } from '@ember/polyfills';
import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';
import PusherBindingsMixin from 'star-fox/mixins/pusher-bindings-mixin';
import config from 'star-fox/config/environment';
import uniqueDebounce from 'star-fox/utils/unique-debounce';
import moment from 'moment-timezone';

export const PUSHER_DEBOUNCE_TIME = 2000 + Math.random() * 4000;

/**
 * Extends functionality of EmberPusher Bindings by adding default action handlers
 * for known model events that emmit from MasterFox. This also add 2 methods to easily setup ( setupPusher() )
 * and teardown ( teardownPusher() ) for a known channel name. Expects the controller to set it's own channelName
 * name before calling setupPusher().
 */
export default Mixin.create(PusherBindingsMixin, {
  userSession: service('user-session'),

  secondaryStore: service(),

  /**
   * Controller name of where global channel subscription happen used to apply queryParams on fetch
   * Used to assign puserhModelQueryParams on models at the global level from a child controller
   * @type {Controller}
   */
  globalChannelController: controller('logged-in'),

  /** @type {strings[]} */
  pusherChannels: [],

  /** @type {strings[]} */
  pusherEvents: ['pusher:subscription_succeeded', 'updated', 'created', 'destroyed'],

  /**
   * Model name to query params map. Use this to define query params to use in the
   * fetch on a model when pusher model events are handled.
   * @example
   * pusherModelQueryParams: { 'Area': { include: 'area-closures' }}
   * @type {Object.<string, object>}
   */
  pusherModelQueryParams: {},

  pusherIgnoreActionTypes: {
    created: [],
    updated: []
  },

  pusherIgnoreModels: ['lead'],

  /** @type {boolean} */
  useNewPubSub: computed('userSession.session.user', function () {
    return this.userSession.session.user?.hasFeatureEnabled('newPubSub');
  }),

  /** @type {string} */
  pubSub: computed('useNewPubSub', function () {
    return this.useNewPubSub
      ? { service: this.actionCable, name: 'cable' }
      : { service: this.pusher, name: 'pusher' };
  }),

  /** @type {object?} Global channel's previous puserhModelQueryParms for re-application at teardown */
  _prevGlobalQueryParams: null,

  /** Takes the pusherModelQueryParams from the local level and ensures they're used when fetching models at the global level */
  _setupGlobalPusherModelQueryParams() {
    const globalChannelController = this.get('globalChannelController');

    if (globalChannelController && this !== globalChannelController) {
      const globalQueryParams = globalChannelController.get('pusherModelQueryParams');
      const localQueryParams = this.get('pusherModelQueryParams');

      const newGlobalQueryParams = assign({}, globalQueryParams, localQueryParams);

      this.set('_prevGlobalQueryParams', globalQueryParams);

      globalChannelController.set('pusherModelQueryParams', newGlobalQueryParams);
    }
  },

  /** Reverts the pusherModelQueryParams at the global level back it's former */
  _teardownGlobalPusherModelQueryParams() {
    const globalChannelController = this.get('globalChannelController');

    if (globalChannelController && this !== globalChannelController) {
      const prevGlobalQueryParams = this.get('_prevGlobalQueryParams');
      globalChannelController.set('pusherModelQueryParams', prevGlobalQueryParams);
    }
  },

  /** Takes channels array names and sets up connections to pusher */
  setupPusher() {
    // No pusher in test cause memory
    if (config.environment === 'test') {
      return;
    }

    console.info(
      `${moment().format('hh:mm:ss ')} [${
        this.pubSub.name
      }] Subscribing to the following channels: [${this.pusherChannels.join(
        ', '
      )}] and events: [${this.pusherEvents.join(', ')}]`
    );

    this.pusherChannels.forEach((channelName) =>
      this.pubSub.service.wire(this, channelName, this.pusherEvents)
    );

    this._setupGlobalPusherModelQueryParams();
  },

  /** Takes channels array names and tears down connections to pusher */
  teardownPusher() {
    // No pusher in test cause memory
    if (config.environment === 'test') {
      return;
    }

    this.pusherChannels.forEach((channelName) =>
      this.pubSub.service.unwire(this, channelName, this.pusherEvents)
    );

    this._teardownGlobalPusherModelQueryParams();

    console.info(
      `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Unsubscribed to ${this.pusherChannels}`
    );
  },

  _fetchRecord(type, id, modelQueryParams, updatedAt = null) {
    if (!this.userSession.user) {
      return;
    }
    try {
      const record = this.store.peekRecord(type, id);
      if (record) {
        const existingUpdatedAt = record.get('updatedAt');

        // If we already have this record or a fresher record ignore this
        if (existingUpdatedAt >= updatedAt) {
          console.info(
            `${moment().format('hh:mm:ss ')} [${
              this.pubSub.name
            }] Ignoring fetch on update model ${type} with id ${id} because we had a newer one locally.`
          );
          return;
        }

        if (record.isDeleted) {
          console.info(
            `${moment().format('hh:mm:ss ')} [${
              this.pubSub.name
            }] Ignoring fetch on update model ${type} with id ${id} because we are deleting it.`
          );
          return;
        }
      }
      this.secondaryStore
        .findRecord(type, id, modelQueryParams)
        .catch((_) =>
          console.warn(
            `${moment().format('hh:mm:ss ')} [${
              this.pubSub.name
            }] Attempting to fetch a created model ${type} with id ${id} failed: `,
            _
          )
        );
    } catch (e) {
      console.warn('Problem updating record: ', e);
    }
  },

  pusher__subscriptionSucceeded: action(function () {
    console.info(
      `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Connected to ${this.get(
        'pusherChannels'
      )}`
    );
  }),

  updated: action(function (data) {
    if (this.pusherIgnoreModels.includes(data.type)) {
      return;
    }

    try {
      console.info(
        `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Updated event received. `,
        data
      );
      const { type, id, updated_at, session_id } = data;

      if (session_id === this.userSession.sessionId) {
        console.info(
          `[${this.pubSub.name}] Ignoring fetch on updated model ${type} because we initiated it.`
        );
        return;
      }

      const record = this.store.peekRecord(type, id);
      // if no updated at is present, just use the current one date which
      // should force an update
      const updatedAt = new Date(updated_at);
      const modelQueryParams = Object.assign({}, this.get(`pusherModelQueryParams.${type}`), {
        reload: true
      });
      const ignore = (this.get('pusherIgnoreActionTypes.updated') || []).includes(type) || !record;

      if (!ignore) {
        // wait between 2-6s to update to prevent all requests coming in at the same time
        uniqueDebounce(
          this,
          this._fetchRecord,
          type,
          id,
          modelQueryParams,
          updatedAt,
          PUSHER_DEBOUNCE_TIME
        );
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(
        `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Problem handling updated record: `,
        e
      );
    }
  }),

  created: action(function (data) {
    if (this.pusherIgnoreModels.includes(data.type)) {
      return;
    }
    try {
      console.info(
        `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Created event received. `,
        data
      );

      const { type, id, session_id } = data;

      if (session_id === this.userSession.sessionId) {
        console.info(
          `[${this.pubSub.name}] Ignoring fetch on create model ${type} because we initated  it.`
        );
        return;
      }

      const record = this.store.peekRecord(data.type, data.id);

      const modelQueryParams = Object.assign({}, this.get(`pusherModelQueryParams.${type}`), {
        reload: true
      });

      const ignore = (this.get('pusherIgnoreActionTypes.created') || []).includes(type);

      if (!record && !ignore) {
        // wait between 2-6s to update to prevent all requests coming in at the same time
        uniqueDebounce(this, this._fetchRecord, type, id, modelQueryParams, PUSHER_DEBOUNCE_TIME);
      } else {
        console.info(
          `${moment().format('hh:mm:ss ')} [${
            this.pubSub.name
          }] Ignoring fetch on create model ${type} with id ${id} because we had a newer one locally.`
        );
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(
        `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Problem handling created record: `,
        e
      );
    }
  }),

  destroyed: action(function (data) {
    console.info(
      `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Destroyed event received. `,
      arguments
    );

    const { type, id, session_id } = data;

    if (session_id === this.userSession.sessionId) {
      console.info(
        `[${this.pubSub.name}] Ignoring fetch on destroy model ${type} because we initiated it.`
      );
      return;
    }

    if (this.pusherIgnoreModels.includes(type)) {
      return;
    }

    try {
      const record = this.store.peekRecord(type, id);

      if (record) {
        this.store.unloadRecord(record);
      }
    } catch (e) {
      console.warn(
        `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Problem finding record: `,
        e
      );
    }
  })
});
