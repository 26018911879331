import classic from 'ember-classic-decorator';
import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import moment from 'moment-timezone';

import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class NewRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'AREA_CLOSURE_CREATE';

  model() {
    const area = this.modelFor('logged-in.areas.edit');
    return hash({
      area: area,
      areaClosure: this.store.createRecord('area-closure', {
        startBlock: moment().startOf('hour').toDate(),
        endBlock: moment().startOf('hour').add(15, 'minute').toDate(),
        area: area
      })
    });
  }
}
