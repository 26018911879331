import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';

@classic
export default class IndexController extends Controller {
  /** @type {IntegrationLogRecordByIntegrationName[]} **/
  @alias('model')
  integrationLogRecordByIntegrationNames;
}
