export function initialize(appInstance) {
  const formFor = appInstance.lookup('service:form-for');
  const notify = appInstance.lookup('service:notify');

  formFor.notifySuccess = function (message) {
    notify.success(message);
  };

  formFor.notifyError = function (message) {
    notify.error(message);
  };
}

export default {
  name: 'form-for',
  initialize: initialize
};
