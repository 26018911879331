import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import Helper from '@ember/component/helper';
import '@ember/object';

@classic
export default class MatchesRequirement extends Helper {
  /** @type {MealPlanningRequirement} */
  requirement = null;

  /**
   * @param {MenuItem|MenuGroup} menuElement Either the menu group or menu item
   * @param {MealPlanningRequirement} messageType the type of message we're displaying
   */
  compute([menuElement, requirement]) {
    this.set('requirement', requirement);

    return menuElement.matches(requirement);
  }

  @observes(
    'requirement.useNormalizedPrice',
    'requirement.maxPrice',
    'requirement.requirementConstraints.@each.tagCollection.length',
    'requirement.requirementConstraints.@each.constraintType',
    'requirement.requirementConstraints.@each.isSaving'
  )
  requirementDidChange() {
    this.recompute();
  }
}
