import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { gt, lte, oneWay } from '@ember/object/computed';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';
import { isPresent } from '@ember/utils';
import { modelAction } from 'ember-custom-actions';
import { fragment } from 'ember-data-model-fragments/attributes';
import EmailRegexp from 'renard/utils/email-regexp';

@classic
export default class GroupOrderMember extends Model {
  /*
   * Attributes
   */
  @attr('string')
  globalId;

  @attr('string')
  name;

  @attr('string')
  department;

  @attr('string')
  email;

  @attr('phone-number')
  phoneNumber;

  @attr('string')
  stripeChargeToken;

  @attr('number')
  numberOfPeople;

  @attr('boolean')
  notifyByEmailOnDelivery; // deprecated

  @attr('boolean')
  notifyBySmsOnDelivery; // deprecated

  @fragment('fragments/notification-preferences', { defaultValue: {} })
  notificationPreferences;

  @attr('group-order-member-state', { defaultValue: 'draft' })
  state;

  /*
   * Relationships
   */
  @hasMany('order-item', { inverse: 'groupOrderMember' })
  orderItems;

  @belongsTo('order', { inverse: 'groupOrderMembers' })
  order;

  @belongsTo('order', { inverse: 'orderedGroupOrderMembers' })
  orderedOrder;

  @belongsTo('user', { async: false })
  user;

  @belongsTo('accounting-ledger-item')
  invoice;

  /*
   * Pseudo Properties
   */
  /** @type {number} */
  taxTotal = 0;

  /*
   * Computed Properties
   */

  /** @type {boolean} */
  @lte('memberPaysTotal', 0)
  hasBudgetRemaining;

  @gt('numberOfPeople', 1)
  isGroup;

  /** @type {number} */
  @computed('itemsTotal', 'numberOfPeople')
  get pricePerPerson() {
    return this.get('itemsTotal') / (this.get('numberOfPeople') || 1);
  }

  /** @type {boolean} */
  @computed('order.isPoop', 'stripeChargeToken')
  get hasStripePayment() {
    const stripeChargeToken = this.get('stripeChargeToken');

    return this.get('order.isPoop') && !!stripeChargeToken && stripeChargeToken !== '';
  }

  /** @type {boolean} */
  @computed('order.client', 'user')
  get isAdmin() {
    const client = this.get('order.client');
    const user = this.get('user');

    return client && user && client.isUserAdmin(user);
  }

  /** @type {boolean} */
  @oneWay('hasStripePayment')
  isEditDisabled;

  @computed('orderItems.@each.{clientTotalPriceCents,isDeleted}')
  get itemsTotal() {
    return this.get('orderItems').reduce(
      (sum, item) => (!item.get('isDeleted') ? sum + item.get('clientTotalPriceCents') : sum),
      0
    );
  }

  /** @type {number} */
  @computed('itemsTotal')
  get overBudgetSubtotal() {
    return this.get('itemsTotal') - this.get('order.perPersonBudget');
  }

  /** @type {number} */
  @computed('itemsTotal', 'taxTotal', 'order.payOutOfPocketFee')
  get grandTotal() {
    const isNotAdmin = !this.get('isAdmin');
    const itemsTotal = this.get('itemsTotal');

    let total = itemsTotal + this.get('taxTotal');

    if (isNotAdmin && itemsTotal > this.get('order.perPersonBudget')) {
      // Taxes on the fee is already accounted for by the fetchTax service
      total += this.get('order.payOutOfPocketFee');
    }

    return total;
  }

  /** @type {number} */
  @computed('grandTotal', 'order.perPersonBudget')
  get memberPaysTotal() {
    return this.get('grandTotal') - this.get('order.perPersonBudget');
  }

  /** @type {number} */
  @computed('memberPaysTotal')
  get isOverBudget() {
    return this.get('memberPaysTotal') > 0;
  }

  /** @type {OrderItem[]} */
  @computed('orderItems.@each.{isDeleted,isSaving}')
  get visibleOrderItems() {
    return this.get('orderItems').reject(
      (orderItem) =>
        (orderItem.get('isDeleted') && !orderItem.get('isSaving')) || orderItem.get('quantity') < 1
    );
  }

  /** @type {boolean} Says when the cart is limited by POOP, only admins can go over budget */
  @computed('order.isPoop', 'isAdmin')
  get isCartLimitedByPoop() {
    return this.get('order.isPoop') && !this.get('isAdmin');
  }

  /** @type {number} */
  @computed('order.perPersonBudgetCents', 'subtotal')
  get remainingBudget() {
    return this.order.perPersonBudgetCents - this.subtotal;
  }

  @computed('code')
  get humanize() {
    return this.get('name');
  }

  @computed('orderItems.[]', 'orderItems.@each.quantity', 'orderItems.@each.isCutlery')
  get hasItems() {
    const orderItemsWithoutEmptyCutlery = this.orderItems.filter(
      (_) => !(_.get('isCutlery') && _.get('quantity') === 0)
    );
    return !!orderItemsWithoutEmptyCutlery.length;
  }

  @computed('orderItems.[]', 'orderItems.@each.quantity', 'orderItems.@each.isCutlery')
  get hasActiveItems() {
    if (this.orderItems.length === 0) {
      return false;
    }

    return !!this.orderItems.every((item) => {
      const isNotEmptyCutlery = !(item.get('isCutlery') && item.get('quantity') === 0);
      const isNotDeleted = !item.get('isDeleted');
      const hasQuantity = item.get('quantity');
      const hasId = item.get('id');

      return isNotEmptyCutlery && isNotDeleted && hasQuantity && hasId;
    });
  }

  /**
   * Send the GOM summary
   */
  sendSummary = modelAction('send-summary', { method: 'POST' });
  processInvoice = modelAction('process-invoice', { method: 'POST' });
  checkout = modelAction('checkout', { method: 'POST', pushToStore: true });

  // TODO add charge aciton

  unload() {
    this.get('orderItems').then((ois) =>
      ois.forEach((oi) => {
        if (oi) {
          this.store.unloadRecord(oi);
        }
      })
    );
    this.store.unloadRecord(this);
  }

  /*
   * Validations
   */
  validations = {
    email: {
      custom: [
        {
          validation(key, value) {
            return !value || EmailRegexp.test(value);
          },
          message: 'You must provide a valid email or we will generate a fake one'
        }
      ]
    },
    name: {
      custom: [
        {
          validation(key, value, model) {
            return !(model.email && !value);
          },
          message: `If you provide an email you must provide a name`
        },
        {
          validation(key, value) {
            if (!value) {
              return true;
            }
            const names = value && value.split(' ');
            if (!names) {
              return false;
            }
            return isPresent(names[0] && names[1]);
          },
          message: `If you must provide an email first and last name or alternatively you can clear the email and we will assign Extra Meal to the name`
        }
      ]
    },
    department: {
      custom: {
        validation(_key, value, model) {
          const isDepartmentRequired =
            model.get('order.client.mealPlan.isDepartmentRequired') && model.name && model.email;
          return isDepartmentRequired ? isPresent(value) : true;
        },

        message: `You must provide a Department Name`
      }
    }
  };
}
