import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { mapBy, unique } from 'macro-decorators';
import { camelize } from '@ember/string';

/**
 * Component for rendering a collection of TaxRates
 */
export default class IntegrationsHealthMonitoring extends Component {
  @service
  appConfiguration;
  /** @type {IntegrationLogRecordByIntegrationName[]} **/
  @arg logRecords = [];

  @mapBy('logRecords', 'formattedDate') formattedDates;
  @mapBy('logRecords', 'integrationName') integrationNames;
  @unique('formattedDates') uniqueDates;
  @unique('integrationNames') uniqueIntegrationNames;

  getReport(date, name) {
    try {
      return this.logRecords.find((record) => {
        return record.formattedDate === date && record.integrationName === name;
      });
    } catch {
      return null;
    }
  }

  @action
  getTotal(date, name) {
    try {
      let recordFound = this.getReport(date, name);
      return recordFound.total;
    } catch {
      return 0;
    }
  }

  @action
  getSuccessCount(date, name) {
    try {
      let recordFound = this.getReport(date, name);
      return recordFound.success;
    } catch {
      return 0;
    }
  }

  @action
  getFailureCount(date, name) {
    try {
      let recordFound = this.getReport(date, name);
      return recordFound.failure;
    } catch {
      return 0;
    }
  }

  @action
  getSuccessPercentage(date, name) {
    try {
      let recordFound = this.getReport(date, name);
      return recordFound.successPercentage;
    } catch {
      return 0;
    }
  }

  @action
  getFailurePercentage(date, name) {
    try {
      let recordFound = this.getReport(date, name);
      return recordFound.failurePercentage;
    } catch {
      return 0;
    }
  }

  @action
  getStandardDeviation(date, name) {
    try {
      let recordFound = this.getReport(date, name);
      return recordFound.standardDeviation;
    } catch {
      return 0;
    }
  }

  @action
  getConfiguredThreshold(date, name) {
    try {
      let recordFound = this.getReport(date, name);
      return recordFound.configuredThreshold;
    } catch {
      return 0;
    }
  }

  @action
  getExpectedMinimumThreshold(date, name) {
    try {
      let recordFound = this.getReport(date, name);
      return recordFound.expectedMinimumThreshold;
    } catch {
      return 0;
    }
  }

  @action
  getColor(date, name) {
    let recordFound = this.getReport(date, name);
    const thresholds =
      this.appConfiguration.integrations.preferences.errorThreshold[camelize(name)];
    // Do not continue if the report can't be found
    // Also, if the total is 0, means this integration was not yet used today
    if (recordFound == null || recordFound.total === 0 || thresholds == null) {
      return 'no-data';
    }

    // If success is between great and good or more than great, should be green
    if (
      recordFound.successPercentage > thresholds['great'] ||
      (recordFound.successPercentage <= thresholds['great'] &&
        recordFound.successPercentage > thresholds['good'])
    ) {
      return 'healthy';
    }

    // if the success is between good and bad, should be yellow
    if (
      recordFound.successPercentage <= thresholds['good'] &&
      recordFound.successPercentage > thresholds['bad']
    ) {
      return 'warning';
    }

    // If the success is exact or below bad, should be red
    if (recordFound.successPercentage <= thresholds['bad']) {
      return 'critical';
    }
  }
}
