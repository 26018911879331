import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import { not } from '@ember/object/computed';
import { htmlSafe } from '@ember/template';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import $ from 'jquery';
import { setReset } from 'star-fox/utils/ember-object-setters';

const CARET_OFFSET = 4.914;

@classic
@classNames('fde-callout-pane')
@classNameBindings('isNotShowing:fde-callout-pane_hide')
export default class CalloutPane extends Component {
  @computed
  get calloutPaneId() {
    return `callout-pane-${guidFor(this)}`;
  }

  /** @type {?number} */
  minWidth = null;

  /** @type {?number} */
  maxWidth = 400;

  /** @type {?number} */
  maxHeight = null;

  /** @type {?number} */
  minHeight = null;

  /** @type {?String} */
  target = null;

  /** @type {String} */
  position = 'top left';

  /** @type {?String} */
  animationClass = null;

  /** @type {boolean} */
  softHide = false;

  /** @type {boolean} */
  isShowing = true;

  /** @type {boolean} */
  @not('isShowing')
  isNotShowing;

  /** @type {boolean} */
  autoClose = true;

  /** @type {boolean} */
  showCloseButton = false;

  /** @type {boolean} Hides the callout arrow */
  hideArrow = false;

  /** @type {boolean} Removes all padding from the callout pane */
  noPadding = false;

  /** @type {boolean} Will prevent the height of the callout to extend beyond the window bottom */
  shouldRestrictCalloutHeightToWindow = false;

  /** @type {boolean} Will align the callout to a small icon from semantic ui */
  iconArrow = false;

  lastAttachment = null;

  constraints = [
    {
      to: 'window',
      attachment: 'together',
      pin: true
    }
  ];

  @action
  handleUpdate(event) {
    // TODO left

    this.set('leftOffset', event.xDelta - CARET_OFFSET);
    const top =
      event.attachment.top === false || event.attachment.top === 'bottom' ? 'top' : 'bottom';

    this.set('computedPosition', `${top} center`);
  }

  /** @type {string} Useful, since ember tether moves the elements outside of their normal dom hierarchy */
  @computed
  get uniquePopupSelector() {
    return `${guidFor(this)}_popup`;
  }

  /** @override */
  willInsertElement() {
    this.set('computedPosition', this.get('position'));
    this._runAnimation();
    super.willInsertElement(...arguments);
  }

  /** @override */
  didRender() {
    super.didRender(...arguments);
    if (this.get('shouldRestrictCalloutHeightToWindow')) {
      this.restrictCalloutHeightToWindow();
    }
  }

  /** Runs the animation when the isShowing property is set */
  @observes('isShowing')
  onIsShowingObservation() {
    if (this.get('isShowing')) {
      this._runAnimation();
    }
  }

  /** Restricts the callout height to the height bottom of the window */
  restrictCalloutHeightToWindow() {
    if (this.get('isDestroyed')) {
      return;
    }

    const $calloutEl = $(`.${this.get('uniquePopupSelector')}`);
    const top = $calloutEl.offset().top;
    const height = $calloutEl.outerHeight();
    const windowHeight = $(window).height();

    const calloutBottomX = top + height;

    const diff = calloutBottomX - windowHeight;

    if (calloutBottomX > windowHeight) {
      $calloutEl.outerHeight(height - diff);
    }
  }

  /**
   * Runs the appropriate animation based on position of the callout
   * @private
   */
  _runAnimation() {
    switch (this.get('position')) {
      default:
      case 'top left':
        setReset(this, 'animationClass', 'fde-scale-in-bl', 500, null);
        break;
      case 'top center':
        setReset(this, 'animationClass', 'fde-scale-in-bottom', 500, null);
        break;
      case 'top right':
        setReset(this, 'animationClass', 'fde-scale-in-br', 500, null);
        break;
      case 'right center':
        setReset(this, 'animationClass', 'fde-scale-in-left', 500, null);
        break;
      case 'bottom right':
        setReset(this, 'animationClass', 'fde-scale-in-tr', 500, null);
        break;
      case 'bottom center':
        setReset(this, 'animationClass', 'fde-scale-in-top', 500, null);
        break;
      case 'bottom left':
        setReset(this, 'animationClass', 'fde-scale-in-tl', 500, null);
        break;
      case 'left center':
        setReset(this, 'animationClass', 'fde-scale-in-right', 500, null);
        break;
    }
  }

  /** @type {String} Attachment point in Ember Tether driven from Semantic UIs position string */
  @computed('position')
  get attachment() {
    switch (this.get('position')) {
      default:
      case 'top left':
        return 'bottom left';
      case 'top center':
        return 'bottom center';
      case 'top right':
        return 'bottom right';
      case 'right center':
        return 'middle left';
      case 'bottom right':
        return 'bottom left';
      case 'bottom center':
        return 'top center';
      case 'bottom left':
        return 'top left';
      case 'left center':
        return 'middle right';
    }
  }

  /** @type {String} Target attachment point in Ember Tether driven from Semantic UIs position string */
  @computed('position')
  get targetAttachment() {
    switch (this.get('position')) {
      default:
      case 'top left':
        return 'top left';
      case 'top center':
        return 'top middle';
      case 'top right':
        return 'top right';
      case 'right center':
        return 'middle right';
      case 'bottom right':
        return 'bottom right';
      case 'bottom center':
        return 'bottom middle';
      case 'bottom left':
        return 'bottom left';
      case 'left center':
        return 'middle left';
    }
  }

  /** @type {String} min and max width are set through this computed style attribute */
  @computed('maxWidth', 'minWidth', 'maxHeight', 'minHeight')
  get style() {
    let dimensionCss = '';

    dimensionCss += this.get('maxWidth') ? `max-width:${this.get('maxWidth')}px !important;` : '';
    dimensionCss += this.get('minWidth') ? `min-width:${this.get('minWidth')}px !important;` : '';
    dimensionCss += this.get('maxHeight')
      ? `max-height:${this.get('maxHeight')}px !important;`
      : '';
    dimensionCss += this.get('minHeight')
      ? `min-height:${this.get('minHeight')}px !important;`
      : '';

    return htmlSafe(dimensionCss);
  }

  /** @type {Function} */
  onCloseClick() {}

  @action
  handleCloseClick() {
    if (this.get('autoClose')) {
      this.set('isShowing', false);
    }

    this.onCloseClick();
  }
}
