import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

/**
 * @class Wallet
 */
@classic
export default class CurrenciesWallet extends Model {
  @attr('object', {
    defaultValue: {
      balances: {}
    }
  })
  balances;

  @belongsTo('owner', { polymorphic: true })
  owner;
}
