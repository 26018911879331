import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';

@classic
export default class InternalController extends Controller {
  @service
  userSession;

  /** @type {Client} */
  @alias('model')
  client;

  @alias('client.features')
  features;

  searchedCompanies = [];

  @computed('features')
  get sortedFeatures() {
    const features = this.get('features');

    return Object.keys(features)
      .sort()
      .reduce((acc, key) => {
        acc[key] = features[key];
        return acc;
      }, {});
  }

  /** @type {Company} */
  company = null;

  @action
  resetApiToken() {
    this.get('model').resetApiToken();
  }

  @action
  assignCompanyAndSave() {
    const company = this.get('company');
    const client = this.get('model');

    client.set('company', company);

    client.save().then(() => this.set('company', this.store.createRecord('company')));
  }

  @action
  reInitStripe() {
    return this.get('model').reInitStripe();
  }

  @action
  forceOnboarding() {
    this.get('model').forceOnboarding();
  }

  @action
  makeActivePlanner() {
    this.get('model').makeActivePlanner();
  }

  @action
  overrideState() {
    this.get('model').overrideState({ client_state: this.get('model.state') });
  }
}
