import { attr, belongsTo } from '@ember-data/model';
import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';

export default Mixin.create({
  /*
   * Attributes
   */
  locationId: attr('string'),
  latitude: attr('number'),
  longitude: attr('number'),
  building: attr('string'),
  street: attr('string'),
  city: attr('string'),
  province: attr('string'),
  country: attr('string'),
  addressCode: attr('string'),
  unitNumber: attr('string'),
  floor: attr('string'),
  buzzer: attr('string'),

  /*
   * Relationships
   */
  location: belongsTo('location'),

  /*
   * Computed Properties
   */
  humanize: computed(
    'building',
    'street',
    'city',
    'province',
    'country',
    'addressCode',
    function () {
      const unitNumber = this.get('unitNumber');
      const floor = this.get('floor');
      const buzzer = this.get('buzzer');

      let ret = [
        `${this.get('building')} ${this.get('street')}, ${this.get('city')} ${this.get(
          'province'
        )} ${this.get('country')},  ${this.get('addressCode')}`
      ];

      if (unitNumber) {
        ret.unshift(unitNumber);
      }

      if (floor) {
        ret.push(`Floor: ${floor}`);
      }

      if (buzzer) {
        ret.push(`Buzzer # ${buzzer}`);
      }

      return ret.join(' - ');
    }
  ),

  humanizeSimple: computed(function () {
    const unitNumber = this.get('unitNumber');

    const ret = [`${this.get('building')} ${this.get('street')}, ${this.get('city')}`];

    if (unitNumber) {
      ret.unshift(`${unitNumber} - `);
    }

    return ret.join(' ');
  })
});
