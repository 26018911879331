import classic from 'ember-classic-decorator';
import Model, { attr } from 'renard/models/foodee';
import { computed } from '@ember/object';
import moment from 'moment-timezone';

@classic
export default class IntegrationLogRecord extends Model {
  /*
   * Attributes
   */
  @attr('string')
  text;

  @attr('string')
  status;

  @attr('string')
  source;

  @attr('string')
  message;

  @attr('number')
  syncableId;

  @attr('string')
  syncableType;

  @attr('string')
  requestId;

  @attr('string')
  externalId;

  @attr('string')
  workflowDescription;

  @attr('date')
  createdAt;

  @computed('createdAt')
  get formattedCreatedAt() {
    return moment.tz(this.createdAt, 'UTC').format('MMM D YYYY, HH:mm:ss');
  }

  @computed('text')
  get parsedText() {
    try {
      return JSON.parse(this.text);
    } catch {
      return this.text;
    }
  }
}
