import classic from 'ember-classic-decorator';
import { classNameBindings, classNames, attributeBindings } from '@ember-decorators/component';
import { not } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@classNames('')
@classNameBindings('isHidden:fde-tab-pane-hide')
@attributeBindings('data-test-sf-tab-pane')
export default class TabPane extends Component {
  get 'data-test-sf-tab-pane'() {
    return this.tabName || '';
  }

  /** @type {string|number} Active tab index of the group */
  activeTab = null;

  /** @type {string|number} Index to represent the identity of pane */
  tabName = null;

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
  }

  /** @type {boolean} is true when pane is active */
  @computed('activeTab', 'tabName')
  get isActive() {
    return this.get('activeTab') === this.get('tabName');
  }

  onShow() {}
  onHide() {}

  /** @type {boolean} set to true provided isActive has been true at least once, this is to support deferred rendering */
  shouldRender = false;

  /**
   * Once active, set did
   */
  @action
  onActiveChange() {
    if (this.isActive) {
      this.set('shouldRender', true);
      this.onShow();
    } else {
      this.onHide();
    }
  }

  /** @type {boolean} is true when pane is not active */
  @not('isActive')
  isHidden;
}
