import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Model, { attr } from 'renard/models/foodee';
import Copyable from '../mixins/copyable';
@classic
export default class Location extends Model.extend(Copyable) {
  /*
   * Attributes
   */
  @attr('number')
  latitude;

  @attr('number')
  longitude;

  @attr('string')
  street;

  @attr('string')
  building;

  @attr('string')
  city;

  @attr('string')
  province;

  @attr('country')
  country;

  @attr('string')
  addressCode;

  @attr('string')
  unitNumber;

  @attr('string')
  floor;

  @attr('string')
  buzzer;

  @attr('string')
  addressLine_1;

  @attr('string')
  addressLine_2;

  @attr('string')
  rawAddress;

  @attr('number')
  localDeliveryRadius;

  @attr('number')
  deliveryRadius;

  @attr('number')
  extendedDeliveryRadius;

  /*
   * Relationships
   */

  /*
   * Computed Properties
   */
  /** @type {string} */
  @alias('id')
  locationId;

  @computed
  get center() {
    return {
      lat: this.get('latitude'),
      lng: this.get('longitude')
    };
  }

  /** @type {string} */
  @computed
  get humanize() {
    const unitNumber = this.get('unitNumber');
    const floor = this.get('floor');
    const buzzer = this.get('buzzer');

    const ret = [
      `${this.get('building')} ${this.get('street')}, ${this.get('city')} ${this.get(
        'province'
      )} ${this.get('country')}, ${this.get('addressCode')}`
    ];

    if (unitNumber) {
      ret.unshift(`${unitNumber} - `);
    }

    if (floor) {
      ret.push(`, Floor: ${floor}`);
    }

    if (buzzer) {
      ret.push(`, Buzzer: ${buzzer}`);
    }

    return ret.join(' ');
  }

  @computed('building', 'street', 'city')
  get humanizeSimple() {
    const unitNumber = this.get('unitNumber');

    const ret = [`${this.get('building')} ${this.get('street')}, ${this.get('city')}`];

    if (unitNumber) {
      ret.unshift(`${unitNumber} - `);
    }

    return ret.join(' ');
  }

  /** @type {string} */
  @computed
  get gmapsLocation() {
    return [
      `${this.get('building')} ${this.get('street')}, ${this.get('city')} ${this.get(
        'province'
      )} ${this.get('country')}, ${this.get('addressCode')}`
    ].join(' ');
  }

  /** @type {string} */
  @computed('name', 'humanize')
  get label() {
    const name = this.get('name');
    const humanize = this.get('humanize');
    return name ? `${name}: ${humanize}` : humanize;
  }

  /*
   * Validations
   */
  validations = {
    city: {
      presence: {}
    },
    building: {
      presence: {}
    },
    street: {
      presence: {}
    },
    province: {
      presence: {}
    },
    country: {
      presence: {}
    },
    addressCode: {
      presence: {}
    }
  };
}
