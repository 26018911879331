import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class RestaurantVote extends Model {
  /*
   * Attributes
   */
  @attr('string')
  email;

  /*
   * Relationships
   */
  @belongsTo('order')
  order;

  @belongsTo('restaurant')
  restaurant;
}
