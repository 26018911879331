import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { action } from '@ember/object';
import Controller from '@ember/controller';
import { run } from '@ember/runloop';

@classic
export default class RulesController extends Controller {
  queryParams = ['rulesPageOffset', 'rulesSearchText'];

  @service
  store;

  @service
  notify;

  @alias('model')
  rules;

  @alias('rules.meta.recordCount')
  rulesRecordCount;

  rulesPageLimit = 10;
  rulesPageOffset = 0;
  rulesSearchText = '';
  _rulesSearchText = '';
  isSearching = false;
  rule = null;
  isLoading = false;
  owner = null;
  rulesRoute = 'logged-in.admins.rules';

  doSearch() {
    this.setProperties({
      rulesSearchText: this.get('_rulesSearchText'),
      rulesPageOffset: 0
    });
  }

  @action
  addRule() {
    this.set('isLoading', true);

    const owner = this.get('owner');
    const rule = this.get('store').createRecord('rules-rule', { owner: owner });

    return rule
      .save()
      .then((rule) => {
        this.send('refreshRules');
        return rule;
      })
      .then((rule) => this.transitionToRoute(`${this.get('rulesRoute')}.show`, rule.get('id')))
      .catch((e) => this.get('notify').error('An error occurred:', e))
      .finally((_) => this.set('isLoading', false));
  }

  @action
  handlePaginationChange(pageOffset) {
    this.set('rulesPageOffset', pageOffset);
  }

  @action
  onSearchChange(searchText) {
    this.set('_rulesSearchText', searchText);

    // prevent multiple search
    run.debounce(this, this.doSearch, 500);
  }
}
