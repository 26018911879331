import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, hasMany } from 'renard/models/foodee';

@classic
export default class FeatureFlagDescription extends Model {
  @attr('string')
  flagId;

  @attr('string')
  description;

  @attr('boolean')
  isPlanFeature;

  @hasMany('subscription-plan')
  subscriptionPlans;

  @computed('subscriptionPlans.@each.plan')
  get plan() {
    return this.get('subscriptionPlans')
      .map((_) => _.get('plan'))
      .join(',');
  }
}
