import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action } from '@ember/object';

@classic
@classNames('fde-new-team-user-form-for')
@tagName('')
export default class NewTeamUserFormFor extends Component {
  /** @type {Service} */
  @service
  store;

  /** @type {Service} */
  @service
  notify;

  /** @type {boolean} */
  readonly = null;

  /** @type {UserInvite} */
  newUser = null;

  /** @type {Team} */
  team = null;

  /** @type {boolean} */
  showNewUserForm = false;

  addNewUser() {}

  /** Creates a new User for the Client */
  @action
  resetUser() {
    this.setProperties({
      showNewUserForm: false,
      newUser: this.get('store').createRecord('user-invite', {
        firstName: 'invitationPending',
        lastName: 'invitationPending',
        phoneNumber: '9999999999',
        team: this.get('team')
      })
    });
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.resetUser();
  }
}
