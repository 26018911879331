import EmberObject from '@ember/object';
import RSVP from 'rsvp';

/**
 * Extracts menuOptionGroups and menuOptionItems from menuItems
 * and provides an intermediary struct to manipulate before applying
 * and saving the orderItem.
 *
 * Also applies the intermediary struct to the orderItem before saving/updating.
 *
 * 2017/05/24 — Currently using foxy form-for.
 */
class OrderItemOptions {
  // Reference
  // mog = menuOptionGroup
  // moi = menuOptionItem

  constructor(attrs) {
    // Assuming mog1 is single option with 3 choices
    //
    // {
    //   'mog1': {Object.<menuOptionItem>},
    //   'moi4': false,
    //   'moi5': false,
    // }
    this.attrs = attrs;
  }

  /**
   * FORM the intermediary struct TO to orderItem.menuOptionItems
   * @param  {OrderItem} The OrderItem to save/update with optional MenuOptionItems
   * @param  {Object} orderItemOptions - Itermediary struct to apply to orderItem
   * @return {OrderItem} Returns with MenuOptionItems set according to the intermediary struct
   */
  static applyToOrderItem(orderItem, orderItemOptions) {
    // Reference
    // mog = menuOptionGroup
    // moi = menuOptionItem

    const mois = this._extractMenuOptionItems(orderItem);

    const moisToSet = Object.keys(orderItemOptions).reduce((acc, key) => {
      if (key != 'needsSave') {
        const isMog = key.includes('mog');
        const optionsKey = orderItemOptions[key];

        // This is a weird one. you have two possible options in
        // grabbing the id of a menu option item. Example object:
        // { moi3: true, moi5: true, moi4: false, mog1: MenuOptionItemObj }
        //
        // If this is a menu option group then we have to grab the id from the
        // menu option item, else we get the id from how we constructed the
        // menu option item's key by chopping off 'moi'
        const id = isMog ? optionsKey && optionsKey.get('id') : key.slice(3);

        // We find the actual menu option item object
        // and add it to the order item
        const moi = mois.find((_) => _.get('id') == id);

        // If the menu option item is part of a isSingleOpt: true menu option group
        // then we know it's a required option else we check that the menu option
        // item key exists before checking if the moi was found.
        if ((isMog || orderItemOptions[key]) && moi) {
          acc.push(moi);
        }
      }

      return acc;
    }, []);

    return RSVP.Promise.resolve(orderItem.set('menuOptionItems', moisToSet));
  }

  /**
   * FROM orderItem TO intermediary struct
   * @param  {OrderItem} The orderItem to extract menuOptionGroups and menuOptionItems
   * @return {Object} Returns an object representing available MenuOptionItems for OrderItem
   */
  static createFromOrderItem(orderItem) {
    const isNew = orderItem.get('isNew');
    const mois = this._extractMenuOptionItems(orderItem);
    const intermediaryStruct = this._createIntermediaryStruct(mois);

    return isNew
      ? this._setNewMois(intermediaryStruct)
      : this._setMois(intermediaryStruct, orderItem);
  }

  /**
   * Finds the orderItem's menuItem and extracts menuOptionGroups
   * to then extract all menuOptionItems
   * @param  {OrderItem} The orderItem to extract MenuOptionGroups and MenuOptionItems
   * @return {MenuOptionItem[]} Returns an a flat array of all menuOptionItems records
   */
  static _extractMenuOptionItems(orderItem) {
    const mois = orderItem
      .get('menuItem.menuOptionGroups')
      .map((_) => _.get('menuOptionItems').toArray());

    // Gotta flatten the multi-dimensional array
    return [].concat(...mois);
  }

  /**
   * Creates the intermediary struct of MenuOptionItems
   * @param  {OrderItem} The orderItem to extract MenuOptionGroups and MenuOptionItems
   * @return {Object} Returns an object representing available MenuOptionItems for an OrderItem
   * ie. {'moi1': false, 'moi2': false, 'mog1': MenuOptionItem}
   */
  static _createIntermediaryStruct(menuOptionItems) {
    return menuOptionItems.reduce((acc, moi) => {
      // Reference
      // mog = menuOptionGroup
      // moi = menuOptionItem

      const moiId = moi.get('id');

      const mog = moi.get('menuOptionGroup');
      const mogKey = `mog${mog.get('id')}`;
      const isSingleOpt = mog.get('isSingleOpt');
      const isFirstMog = isSingleOpt && !acc[mogKey];

      if (isFirstMog) {
        acc[mogKey] = [];
      }

      const isMogGroup = isSingleOpt && acc[mogKey];

      const key = isMogGroup || isFirstMog ? mogKey : `moi${moiId}`;
      const value = isMogGroup ? acc[mogKey].concat(moi) : false;

      acc.set(key, value);

      return acc;
    }, EmberObject.create());
  }

  /**
   * For a new OrderItem need to set MenuOptionItem defaults. If a MenuOptionItem
   * belongs to a MenuOptionGroup with isSingleOpt: true then set the default
   * value to the first MenuOptionItem otherwise set everything to false.
   *
   * @param  {Object} intermediaryStruct - An object to represent the available MenuOptionItems for a OrderItem
   * @return {Object} Returns an object representing available MenuOptionItems for an OrderItem with defaults
   * ie. {'moi1': false, 'moi2': false, 'mog1': MenuOptionItem[0]}
   */
  static _setNewMois(intermediaryStruct) {
    Object.keys(intermediaryStruct).forEach((key) => {
      const isMog = key.includes('mog');
      const value = isMog ? intermediaryStruct[key][0] : false;
      const isSubstitute = value && value.get('menuOptionGroup.verb') === 'substitute';

      // substitute menu option groups can have a none option
      intermediaryStruct.set(key, isSubstitute ? undefined : value);
    });

    return intermediaryStruct;
  }

  /**
   * When updating an OrderItem need to set the current MenuOptionItems.
   *
   * @param  {Object} intermediaryStruct - An object to represent the available MenuOptionItems for a OrderItem
   * @param  {OrderItem} A saved OrderItem with already set MenuOptionItems
   * @return {Object} Returns an object representing an OrderItem's MenuOptionItems
   * ie. {'moi1': true, 'moi2': false, 'mog1': MenuOptionItem[2]}
   */
  static _setMois(intermediaryStruct, orderItem) {
    const presentMois = orderItem.get('menuOptionItems').mapBy('id');
    Object.keys(intermediaryStruct).forEach((key) => {
      const isMog = key.includes('mog');

      // If isMog this id is an array of choices, ie. [1, 2, 3]
      const id = isMog ? intermediaryStruct[key] : key.slice(3);
      const value = isMog ? this._setMogChoice(id, presentMois) : presentMois.includes(id);

      intermediaryStruct.set(key, value);
    });

    return intermediaryStruct;
  }

  /**
   * The intermediary struct handles MenuOptionItems that belong to MenuOptionGroups
   * with isSingleOpt: true differently. If a MenuOptionItem belongs to a MenuOptionGroup
   * with isSingleOpt: true the struct has a key-value where the value is the MenuOptionItem.
   *
   * If isSingleOpt: false all MenuOptionItems are available to the OrderItem and instead
   * are represented with key-values that are booleans.
   *
   * Since a saved OrderItem might not have the default MenuOptionItem of a
   * MenuOptionGroup with isSingleOpt: true we need to check and set the proper
   * MenuOptionItem.
   *
   * @param  {Array} mogChoices - Ids representing MenuOptionItem ids
   * @param  {Array} presentMois - Ids of saved MenuOptionItems on current OrderItem
   * @return {Object} Returns an intermediary struct representing the OrderItem's MenuOptionItems
   * ie. {'moi1': true, 'moi2': false, 'mog1': MenuOptionItem[2]}
   */
  static _setMogChoice(mogChoices, presentMois) {
    // What?
    // This takes mogChoices and presentMois arrays, flattens them into one,
    // sorts the values and returns one of the duplicate values
    //
    // ie.
    // [1, 2, 3].concat([4, 2])                         => [1, 2, 3, 4, 2]
    // arr.sort                                         => [1, 2, 2, 3, 4]
    // arr.filter((_ = 2, i = 2, arr) => _ == arr[i-1]) => [2]

    return mogChoices
      .concat(presentMois)
      .sort()
      .filter((_, i, arr) => typeof _ === 'object' && arr.includes(_.get('id')))[0];
  }
}

export default OrderItemOptions;
