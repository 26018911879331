import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class MenuOptionItem extends Application {
  attrs = {
    orderItem: {
      serialize: false
    },
    menuOptionGroup: {
      serialize: true
    },
    dietaryTags: {
      serialize: true
    }
  };
}
