import classic from 'ember-classic-decorator';
import $ from 'jquery';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

export const CardTypeClassMap = {
  visa: 'visa',
  mastercard: 'mastercard',
  amex: 'american express',
  dinersclub: 'diners club',
  discover: 'discover',
  jcb: 'japan credit bureau'
};

@classic
export default class CreditCardControl extends Component {
  _cardType = null;

  /** @type {string} Type of card for semantic ui to show a cool icon */
  @computed('_cardType')
  get cardClass() {
    const cardClass = CardTypeClassMap[this.get('_cardType')];
    return cardClass ? cardClass : 'credit card alternative';
  }

  /** @type {string} Card number value */
  number = null;

  /**
   * @callback handleCommit
   * @param {string} number
   *
   * @property {handleCommit}
   */
  onCommit() {}

  /**
   * @callback handleChange
   * @param {string} number
   *
   * @property {handleChange}
   */
  onChange() {}

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.$('input').payment('formatCardNumber');
  }

  @action
  handleChange(e) {
    console.debug('form-controls/credit-card-number#handleChange', e.target.value);
    this.onChange(e.target.value);
    this.set('_cardType', $.payment.cardType(e.target.value));
  }

  @action
  handleBlur(e) {
    console.debug('form-controls/credit-card-number#handleBlur', e.target.value);
    this.onCommit(e.target.value);
    this.set('_cardType', $.payment.cardType(e.target.value));
  }
}
