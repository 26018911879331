import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-order-teams')
export default class OrderTeams extends Component {
  /** @type {Order} */
  order = null;

  /** @type {boolean} */
  isReadonly = false;

  /** @type {Team[]} */
  orderTeams = null;

  /** @type {Object} */
  gomMeta = null;

  /** @type {Object} */
  teamCounts = null;

  /** @type {Team} */
  selectedTeam = null;

  /** @type {boolean} */
  expandAllTeams = false;

  /** @type {boolean} */
  canEditOrderUsers = false;

  /** @type {boolean} */
  canAddGroupOrderMember = false;

  /** @type {boolean} */
  enableReminder = false;

  removeTeam() {}
  addOrderGuest() {}
  removeOrderGuest() {}
  handleAddGroupOrderMemberClick() {}
}
