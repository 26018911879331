import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-orders-email-messages-email-message')
export default class MessagesWrapper extends Component {
  /** @type {EmailMessage} */
  emailMessage = null;

  onClientPaginationChange() {}
  onClientSearchTextChange() {}
}
