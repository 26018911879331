import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';

@classic
export default class NewController extends Controller {
  /** @type {Service} */
  @service
  notify;

  /** @type {InvoicingTaxRate[]} */
  invoicingTaxRates = null;

  /** @type {Area} */
  @alias('model')
  area;

  /** @type {string} */
  @computed('model.country')
  get regionLabel() {
    return {
      US: 'State',
      CA: 'Province',
      UK: 'County'
    }[this.get('model.country')];
  }

  /** @type {string} */
  @computed('model.country')
  get moneySymbol() {
    return {
      US: 'dollar',
      CA: 'dollar',
      UK: 'pound'
    }[this.get('model.country')];
  }

  @action
  onCountryChange(value) {
    let oldValue = this.get('model.country');

    if (oldValue !== value) {
      this.get('model').setProperties({
        province: '',
        country: value
      });
    }
  }

  @action
  handleDidSave() {
    this.transitionToRoute('logged-in.areas.edit.index', this.get('area.id'));
  }
}
