import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import { not } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import VelocityMixin from 'ember-velocity-mixin/main';
import $ from 'jquery';

import { compact, expanded, spring } from './sizes';

@classic
@classNames('fde-navigation-view')
@classNameBindings(
  'isCompact:fde-navigation_compact',
  'isBlurred:fde-blur',
  'isScrolling:is-scrolling'
)
export default class NavigationView extends Component.extend(VelocityMixin) {
  // todo: move this into an adapter -
  isCompact = !!window.localStorage.getItem('isCompact');

  @not('isCompact')
  isExpanded;

  /** @property {User} whether or not tooltips should be shown */
  currentUser = null;

  /** @property {boolean}*/
  isScrolling = null;

  /**
   * Leaking the ember abstraction some, and forcibly updating the component's
   * width.
   */
  didInsertElement() {
    super.didInsertElement(...arguments);
    const width = this.get('isCompact') ? compact : expanded;
    $('#' + this.elementId).css('paddingLeft', `${width}px`);

    let isScrolling;
    this.$('fde-content-container').on('scroll', () => {
      this.set('isScrolling', true);

      clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(() => {
        this.set('isScrolling', false);
      }, 66);
    });
  }

  @observes('isCompact')
  updateLeft() {
    if (this.get('isCompact')) {
      this.animate(
        { paddingLeft: compact },
        {
          easing: spring,
          delay: 200
        }
      );
    } else {
      this.animate({ paddingLeft: expanded }, spring);
    }
  }

  /**
   * @type {string} the tooltip for the toggle toolbar view
   */
  @computed('isCompact')
  get toggleTooltip() {
    return this.get('isCompact') ? 'Expand Toolbar' : 'Collapse Toolbar';
  }

  @action
  toggleExpansion() {
    this.toggleProperty('isCompact');

    window.localStorage.setItem('isCompact', this.get('isCompact'));
  }
}
