import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@tagName('')
export default class ServiceTimesDay extends Component {
  /** @type {boolean} */
  readonly = null;

  /** @type {ServiceTime[]} Array of ServiceTimes passed to component. Grouped by weekday. */
  serviceTimes = null;

  /** @type {string} Name of given weekday */
  weekdayName = null;

  /** @type {number} Number of weekday. Sunday is 0, Saturday is 6 */
  weekday = null;

  didDestroy() {}
  newServiceTimeForWeekday(_weekday) {}
}
