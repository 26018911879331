import { inject as service } from '@ember/service';
import { alias, or } from '@ember/object/computed';
import Component from '@ember/component';
import RSVP from 'rsvp';
import { action, computed } from '@ember/object';
import { task, taskGroup, timeout } from 'ember-concurrency';
import { matchSorter } from 'match-sorter';

export default Component.extend({
  /** @type {Restaurant} */
  value: null,

  /** @type {Function} */
  onChange() {},

  /** @type {Restaurants[]} */
  values: null,

  /** @type {boolean} */
  deadlineIsSameDay: false,

  /** @type {Service} */
  restaurantSearch: service(),

  /** @type {Service} */
  store: service(),

  /** @type {Area} */
  area: null,

  /** @type {DrawerPane} */
  drawer: null,

  /** @type {Location} */
  deliveryLocation: null,

  /** @type {Date} */
  deliverAt: null,

  /** @type {Client} */
  client: null,

  /** @type {boolean} */
  showEnterpriseDetails: false,

  /** @type {function} */
  onSearchChange(_requirement) {},

  /** @type {Requirement} */
  menuItemSearch: null,

  /** @type {boolean} */
  preventSearch: false,

  /** @type {boolean} */
  readonly: false,

  /** @type {String} */
  activeTab: 'Search',

  preferenceProfile: null,
  preferenceProfileActiveTab: 'Team',
  preferee: null,

  /** @type {boolean} */
  hideForm: false,

  /** @type {boolean} */
  hideDeliveryRadius: false,

  init(...args) {
    this._super(...args);

    this._values = this.deadlineIsSameDay
      ? this.values.sortBy('canSameDayFoodeePlusTeamOrder:desc', 'name')
      : this.values.sortBy('name');
  },

  _fetchValues: action(async function () {
    const locationId = this.get('deliveryLocation.id');

    if (locationId) {
      let sortIndexes = ['distance.distance', 'name'];
      if (this.deadlineIsSameDay) {
        sortIndexes.unshift('deadlineIsSameDay');
      }
      this._values = (
        await this.store.query('restaurant', {
          fields: {
            restaurants: 'name,distance,can-same-day-foodee-plus-team-order'
          },
          filter: {
            id: this.values.mapBy('id'),
            location_id: locationId
          },
          page: {
            limit: 200
          }
        })
      ).sortBy(...sortIndexes);
    } else {
      this._values = this.values;
    }
  }),

  canClearFilter: computed('filterRequirement', 'requirement', function () {
    const filterRequirement = this.get('filterRequirement');
    return filterRequirement !== null && filterRequirement !== this.get('requirement');
  }),

  _initSearch() {
    const requirement = this.get('store').createRecord('meal-planning-requirement');
    requirement.reset();

    this.set('requirement', requirement);

    if (this.get('activeTab') === 'Search') {
      this.set('filterRequirement', requirement);
    }
  },

  onOpen: action(function () {
    this.get('loadingTask').perform();
    this.guardSearch();
    this._initSearch();
    this.unGuardSearch();
  }),

  onClose: action(function () {
    this.set('filterString', '');
    this.unGuardSearch();
    this._initSearch();
  }),

  doSearch: action(function () {
    if (this.get('preventSearch')) {
      return RSVP.Promise.resolve(true);
    }

    const requirement = this.get('requirement');

    const searchString = JSON.stringify(requirement.toQuery());

    if (this.get('_storedSearch') !== searchString) {
      this.get('searchTask').perform();
      this.set('_storedSearch', searchString);
      this.onSearchChange(requirement);
    }

    return RSVP.Promise.resolve(true);
  }),

  /**
   * @param {Restaurant} restaurant
   */
  selectRestaurant: action(async function (restaurant) {
    let selectedRestaurant = this.get('store').peekRecord('restaurant', restaurant.key);

    if (selectedRestaurant === null) {
      selectedRestaurant = await this.get('store').find('restaurant', restaurant.key);
    }

    console.info(`[restaurant-select-control] Selecting restaurant#${selectedRestaurant.id}`);

    this.onChange(selectedRestaurant);

    if (this.drawer) {
      this.drawer.close();
    }
  }),

  /**
   * @param {Restaurant} restaurant
   */
  _performSearch(restaurant = null) {
    const requirement = this.get('requirement');

    if (!requirement) {
      return RSVP.Promise.resolve(true);
    }

    const restaurantId = restaurant ? restaurant.key : null;

    const distanceAreasEnabled =
      this.get('client.features.deliveryRadius') || this.get('area.features.deliveryRadius');

    const query = requirement.toQuery({
      area: distanceAreasEnabled ? null : this.get('area'),
      locationId: distanceAreasEnabled ? this.get('deliveryLocation.id') : null,
      client: this.get('client'),
      restaurant: restaurantId,
      deliverAt: this.get('deliverAt').toISOString()
    });

    return this.restaurantSearch.call(query);
  },

  /**
   * @type {Task}
   */
  loadingTask: task(function* () {
    const preferee = yield this.get('preferee') ?? this.get('client');

    if (preferee) {
      const preferenceProfile = yield preferee.get('preferenceProfile');

      // clear out menu items which may have been assigned
      preferenceProfile.set('menuItems', null);

      // set the profile
      this.set('preferenceProfile', preferenceProfile);
    } else {
      console.error('Somehow the restaurant select control was initialized without a preferee');
    }
  }),

  /**
   * @type {{aggregates: []}}
   */
  searches: taskGroup().drop(),

  results: alias('searches.last.value'),
  performed: or('searches.last', 'searches.isRunning'),

  searchSelectedTask: action(function () {
    const task =
      this.get('activeTab') === 'Search' ? this.get('searchTask') : this.get('profileSearchTask');
    task.perform();
  }),

  /**
   * @type {Task}
   */
  searchTask: task(function* () {
    yield timeout(300);

    const ret = yield this._performSearch();

    return ret;
  }).group('searches'),

  /**
   * @type {Task}
   */
  profileSearchTask: task(function* () {
    yield timeout(300);

    const preferee = yield this.get('preferee') ?? this.get('client');
    if (preferee) {
      const profile = yield preferee.get('preferenceProfile');

      if (profile) {
        return this.restaurantSearch.searchRecordRestaurants(profile, {
          deliverAt: this.get('deliverAt').toISOString(),
          locationId: this.get('deliveryLocation.id'),
          forTeamOrder: this.get('preferenceProfileActiveTab') === 'Team'
        });
      }
    } else {
      console.error('Somehow the restaurant select control was initialized without a preferee');
    }
  }).group('searches'),

  guardSearch: action(function () {
    this.set('preventSearch', true);

    if (this.get('searches.last')) {
      this.set('searches.last.value', null);
    }

    return true;
  }),

  unGuardSearch: action(function () {
    this.set('preventSearch', false);
    this.set('_storedSearch', null);
  }),

  handleTabChange: action(function (name) {
    this.set('activeTab', name);
    const filterRequirement = name === 'Search' ? this.get('requirement') : null;
    this.set('filterRequirement', filterRequirement);
  }),

  handlePreferenceProfileTabChange: action(function (name) {
    this.set('preferenceProfileActiveTab', name);
    this.set('filterRequirement', null);
  }),

  filterString: '',

  filteredResults: computed('results.restaurants', 'filterString', function () {
    return matchSorter(this.results?.restaurants ?? [], this.filterString, { keys: ['name'] });
  }),

  filterResults: action(function (value) {
    this.set('filterString', value);
  }),

  registerSearchBox: action(function (searchBox) {
    this.searchBox = searchBox;
    this.searchBox.focus();
  }),

  selectFirstRestaurant: action(function () {
    this.selectRestaurant(this.filteredResults.firstObject);
    this.set('filterString', '');
  })
});
