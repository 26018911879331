import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import Evented from '@ember/object/evented';
import { isEmpty } from '@ember/utils';

/**
 * @class Filter
 * Container for the definition of a filter
 */
@classic
export default class Filter extends EmberObject.extend(Evented) {
  /** @type {String} A unique identifier for the filter. Will default to key if none is provided. */
  id = '';

  /** @type {String} */
  key = '';

  /** @type {Array} */
  value = null;

  /** @type {String[]} */
  valueText = null;

  /** @type {String} */
  icon = '';

  /** @type {String} */
  label = null;

  /** @type {boolean} */
  single = null;

  /** @type {?String} */
  resourceName = null;

  /** @type {?String} */
  resourceValueKey = 'id';

  /** @type {?String} */
  resourceValueLabel = 'label';

  /** @type {?String} Limits the searched resource fields to a comma separated string of field names */
  resourceRequiredFields = null;

  /** @type {?String} */
  valueTextDelimiter = null;

  /** @type {?String} */
  valueTextKey = null;

  /** @override */
  init() {
    const valueTextDelimiter = this.get('valueTextDelimiter');
    const value = this.get('value');
    const valueText = this.get('valueText');
    const id = this.get('id');

    if (valueTextDelimiter) {
      const valueArray = (value || []).map((_) => _.split(valueTextDelimiter)[0]);
      const valueTextArray = (value || []).map((_) => {
        const splitValueText = _.split(valueTextDelimiter);
        splitValueText.length > 1 && splitValueText.splice(0, 1);
        return splitValueText.join(valueTextDelimiter);
      });

      this.setProperties({
        value: valueArray,
        valueText: isEmpty(valueText) ? valueTextArray : valueText
      });
    }

    this.set('id', id || this.get('key'));

    super.init(...arguments);
  }

  /**
   * @param {*} object
   * @return boolean
   */
  filter(object) {
    const value = this.get('value');

    return value ? value.includes(object.get(this.get('key'))) : true;
  }

  /**
   * Concatenates this filter to JR filter
   *
   * In JR individual keys are treated as ANDs and multiple entries (arrays) on each key are treated as ORs
   * So filters are able to represent multiple values.
   * @param {?Object} object
   */
  toJR(object = {}) {
    const key = this.get('key');
    const value = this.get('value');

    if (key && value) {
      object[key] = (object[key] || []).concat(value);
    }

    return object;
  }

  /**
   * Concatenates this filter to queryParams key value pairs.
   * @param object
   */
  toQueryParam(object = {}) {
    const key = this.get('key');
    const value = this.get('value');
    const valueText = this.get('valueText');
    const valueTextDelimiter = this.get('valueTextDelimiter');

    const valueWithValueText = valueTextDelimiter
      ? value.map((item, i) => `${item}${valueTextDelimiter}${valueText[i]}`)
      : value;

    if (key && value) {
      object[key] = object[key]
        ? `${object[key]},${valueWithValueText.join(';')}`
        : valueWithValueText.join(';');
    }

    return object;
  }

  /**
   * Returns a Plain Old Javascript Object version of this filter. Useful for creating a new instance of the current
   * object.
   */
  toPOJO() {
    return JSON.parse(JSON.stringify(this));
  }

  /**
   * Sets the value and valueText of the filter
   * @param value {String[]}
   * @param valueText {String[]}
   */
  setValue(value, valueText = null) {
    const prevValue = this.get('value');
    const prevValueText = this.get('valueText');

    this.setProperties({
      value,
      valueText
    });

    value !== prevValue && this.trigger('valueChange', value, prevValue, this);
    valueText !== prevValueText && this.trigger('valueTextChange', valueText, prevValueText, this);
  }

  /**
   * Adds a single value to the value and valueText array
   * @param singleValue {String}
   * @param singleValueText {String}
   */
  addSingleValue(singleValue, singleValueText) {
    const value = this.get('value');
    const valueText = this.get('valueText');

    const newValue = value.toArray();
    const newValueText = valueText ? valueText.toArray() : null;

    if (newValue.includes(singleValue)) {
      return;
    }

    newValue.push(singleValue);
    newValueText && newValueText.push(singleValueText || singleValue);

    this.setValue(newValue, newValueText);
  }
}
