import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, hasMany } from 'renard/models/foodee';
import ConstraintTagMixin from 'star-fox/mixins/constraint-tag-mixin';

/**
 * @class FoodType
 * @implements ConstraintTag
 */
@classic
export default class FoodType extends Model.extend(ConstraintTagMixin) {
  /*
   * Attributes
   */
  @attr('string')
  emoji;

  @attr('string')
  name;

  /*
   * Relationships
   */
  @hasMany('menu-group', { inverse: 'foodTypes' })
  menuGroups;

  @hasMany('menu-items', { inverse: 'foodTypes' })
  menuItems;

  /*
   * Computed Properties
   */
  @computed('name')
  get humanize() {
    return `Meal Type '${this.get('name')}'`;
  }

  @computed('name', 'emoji')
  get label() {
    return `${this.get('name')} ${this.get('emoji') || ''}`;
  }

  @computed('name', 'emoji')
  get reverseLabel() {
    return `${this.get('emoji')} ${this.get('name') || ''}`;
  }

  /** @override Implementing ConstraintTag matches method */
  matches(menuItem) {
    return menuItem.get('allFoodTypes').includes(this);
  }

  /*
   * Validations
   */
  validations = {
    name: {
      presence: {}
    }
  };
}
