import Helper from '@ember/component/helper';
import { isPresent } from '@ember/utils';

export function toFixed([value, length]) {
  if (isPresent(value) && isPresent(length)) {
    return value.toFixed(length);
  }
  value = value | '';
  return value.toString();
}

export default Helper.helper(toFixed);
