import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class ImportOrdersRoute extends Route {
  model(_params) {
    return {
      clientId: this.modelFor('logged-in.clients.edit').get('id')
    };
  }
}
