import attr from 'ember-data/attr';
import MF from 'ember-data-model-fragments';

export default class MealPlanningSlot extends MF.Fragment {
  @attr('number') reservationId;

  get reservation() {
    const reservationId = this.reservationId;
    return reservationId ? this.store.peekRecord('meal-planning-reservation', reservationId) : null;
  }
}
