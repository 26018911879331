import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

import { action } from '@ember/object';

@classic
@classNames('fde-dietary-tags-control')
export default class DietaryTagsControl extends Component {
  /** @type {boolean} */
  readonly = false;

  /** @type {function} handles the toggling of dietary tags */
  onChange() {}

  /** @type {DietaryTag[]} array of all dietary tags */
  values = null;

  /** @type {DietaryTag[]} array of selected dietary tags */
  value = null;

  @action
  toggleTag(dietaryTag) {
    if (!this.get('readonly')) {
      const value = this.get('value').toArray();

      if (value.includes(dietaryTag)) {
        value.removeObject(dietaryTag);
      } else {
        value.pushObject(dietaryTag);
      }

      this.onChange(value);
    }
  }
}
