import { helper as buildHelper } from '@ember/component/helper';

export function joinedUserIsGroupOrderMember([joinedUser = null, joinedUsersMeta = null]) {
  if (joinedUser && joinedUsersMeta) {
    const joinedUserId = joinedUser.get('id');

    return joinedUsersMeta['usersWithGoms'].indexOf(parseInt(joinedUserId)) > -1;
  }
}

export default buildHelper(joinedUserIsGroupOrderMember);
