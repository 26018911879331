import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { intersect } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@classNames('fde-tables-ordered-section-row')
@tagName('tr')
export default class SectionRow extends Component {
  /** @type {boolean} */
  isSelectable = false;

  /** @type {boolean} */
  allowActionMenu = false;

  /** @type {{title: String, values: Object[]}} */
  section = null;

  /** @type {?Model[]} */
  selectedValues = null;

  /** @type {?Model[]} */
  @intersect('section.values', 'selectedValues')
  selectedAndValuesIntersect;

  /** @type {boolean} */
  @computed('selectedAndValuesIntersect.length', 'section.values.length')
  get isSelected() {
    return (
      this.get('selectedAndValuesIntersect.length') === this.get('section.values.length') &&
      this.get('section.values.length') !== 0
    );
  }

  onSelectSection() {}
  onDeselectSection() {}

  @action
  handleSelectChange(value) {
    value ? this.onSelectSection(this.get('section')) : this.onDeselectSection(this.get('section'));
  }
}
