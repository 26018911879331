import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import Component from '@ember/component';
import '@ember/object';
import VelocityMixin from 'ember-velocity-mixin/main';
import { compact, expanded, spring } from '../sizes';
import config from 'star-fox/config/environment';
import $ from 'jquery';

@classic
@classNames('ui vertical inverted menu fde-nav-bar')
@classNameBindings('isCompact:fde-nav-bar_compact')
export default class NavigationBar extends Component.extend(VelocityMixin) {
  /** @property {User} whether or not tooltips should be shown */
  currentUser = null;

  /** @property {string} releaseVersion current Heroku release version */
  releaseVersion = config.releaseVersion || '';

  /**
   *
   * Leaking the ember abstraction some, and forcibly updating the component's
   * width.
   */
  didInsertElement() {
    super.didInsertElement(...arguments);
    const width = this.get('isCompact') ? compact : expanded;
    $(`#${this.elementId}`).css('width', `${width}px`);
  }

  /**
   * Observer for animating the component width on compact state change
   * @private
   */
  @observes('isCompact')
  _updateWidth() {
    if (this.get('isCompact')) {
      this.animate(
        { width: compact },
        {
          easing: spring,
          delay: 200
        }
      );
    } else {
      this.animate({ width: expanded }, spring);
    }
  }
}
