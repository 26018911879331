import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class DriverPing extends Model {
  @attr('number')
  latitude;

  @attr('number')
  longitude;

  @attr('number')
  userId;

  // Relationships
  @belongsTo('user')
  user;
}
