import classic from 'ember-classic-decorator';
import { guidFor } from '@ember/object/internals';
import EmberObject, { computed } from '@ember/object';
import FilterCollection from './filter-collection';

@classic
export default class FilterCollectionManager extends EmberObject {
  storageKey = 'ee.food.concierge.filter-collection-manager';

  /** @type {FilterCollection[]} */
  filterCollections = null;

  /** @type {} */
  @computed('filterCollections.[]')
  get filterCollectionsKeys() {
    return this.get('filterCollections').map((_) => _.get('storageKey'));
  }

  /** @override */
  init() {
    super.init(...arguments);
    !this.get('filterCollections') && this.set('filterCollections', []);
  }

  /** Restores the filter collection from the */
  restoreFilterCollections() {
    const keys = localStorage.getItem(`${this.get('storageKey')}.collection-keys`);

    if (keys) {
      const collectionKeys = JSON.parse(keys);
      const filterCollections = collectionKeys.map((storageKey) =>
        FilterCollection.create({ storageKey })
      );
      this.set('filterCollections', filterCollections);
    }
  }

  /** Stores the filter collection keys in localStorage */
  storeFilterCollections() {
    const storageKeys = this.get('filterCollectionsKeys');
    localStorage.setItem(`${this.get('storageKey')}.collection-keys`, JSON.stringify(storageKeys));
  }

  /**
   * Creates a new filter collection for the manager.
   * @returns {FilterCollection}
   */
  createFilterCollection() {
    const filterCollection = FilterCollection.create({
      storageKey: `ee.food.concierge.filter-collection.${guidFor({})}`
    });

    const filterCollections = this.get('filterCollections').toArray();
    filterCollections.push(filterCollection);
    this.set('filterCollections', filterCollections);
    this.storeFilterCollection();
    return filterCollection;
  }

  /**
   * Removes a filter collection from the manager.
   * @returns {FilterCollection}
   */
  removeFilterCollection(filterCollection) {
    const filterCollections = this.get('filterCollections').toArray();
    filterCollections.removeObject(filterCollection);
    this.set('filterCollections', filterCollection);

    filterCollections.clearStore();
    this.storeFilterCollections();
  }
}
