import classic from 'ember-classic-decorator';
import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

import moment from 'moment-timezone';

@classic
export default class MealPlanningTemplateRoute extends Route.extend(CapabilityRouteMixin) {
  /** @type {String} */
  capability = 'CLIENT_MEALPLAN_SHOW';

  /**
   * @param {Object} params
   *
   * @returns {Promise} Client and MealPlanningTemplates
   */
  model(_params) {
    const client = this.modelFor('logged-in.clients.edit');

    return RSVP.hash({
      client: client,
      mealPlanningTemplates: client.query('mealPlanningTemplates', {
        include: ['restaurant-constraints.requirement', 'restaurant-constraints.restaurants'].join(
          ','
        ),
        page: { limit: 100 }
      }),
      instanceToPublish: this.store.createRecord('mealPlanningInstance', {
        startOfWeek: moment().isoWeekday(1).add(7, 'days').toDate()
      }),
      areas: this.store.query('area', { filter: { active: true }, page: { limit: 100 } }),
      admins: client.hasMany('admins').reload()
    });
  }

  afterModel(model) {
    const mealPlanningTemplateToSet = this.getSelectedTemplate(model);

    if (mealPlanningTemplateToSet) {
      return mealPlanningTemplateToSet.reload();
    }
  }

  /** @override */
  setupController(controller, model) {
    super.setupController(controller, model);
    const mealPlanningTemplateToSet = this.getSelectedTemplate(model);
    controller.set('selectedTemplate', mealPlanningTemplateToSet);
  }

  getSelectedTemplate(model) {
    let selectedTemplate = model.mealPlanningTemplates.firstObject;
    const { selectedTemplateId } = this.paramsFor(this.routeName);
    if (selectedTemplateId) {
      selectedTemplate = model.mealPlanningTemplates.find(
        (template) => template.id === selectedTemplateId
      );
    }
    return selectedTemplate;
  }
}
