import Fixture, {
  element,
  // elementWithValue,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';
import { Form, field } from 'renard/fixtures/form';
import { sfTabViewTabGroupTab } from 'star-fox/features/components/sf/tab-view/tab-group/tab/fixture-chai';

class OrderForm extends Form {
  constructor() {
    super('order');
  }
  @field({ using: 'radio', for: 'serviceFee.type' }) feeType;
}
const orderForm = getElementDecoratorFor(OrderForm);

export default class SfFormsOrderFormForFixture extends Fixture {
  constructor() {
    super('data-test-sf-forms-order-form-for');
  }

  @sfTabViewTabGroupTab({ value: 'discountTab' }) discountTab;
  @sfTabViewTabGroupTab({ value: 'notesTab' }) notesTab;
  @sfTabViewTabGroupTab({ value: 'teamOrderTab' }) teamOrderTab;
  @orderForm form;
  @element serviceFeeType;
  @element serviceFeeTypeLabel;
  @element serviceFeeAmount;
  @element customizeServiceFeeAmount;
  @element({ selector: 'data-test-form-controls-checkbox-named', value: 'deliveryFeeActive' })
  deliveryFeeCheckbox;

  async enterTab(tabName) {
    return this[tabName].click();
  }

  async selectFeeType(feeType) {
    return this.form.feeType.control.selectOption(feeType);
  }

  deliveryShouldBeTurnedOn(checked) {
    if (checked) {
      this.deliveryFeeCheckbox.should.have.class('checked');
    } else {
      this.deliveryFeeCheckbox.should.not.have.class('checked');
    }
  }
}

export const sfFormsOrderFormFor = getElementDecoratorFor(SfFormsOrderFormForFixture);
export const sfFormsOrderFormForWithValue = getElementWithValueDecoratorFor(
  SfFormsOrderFormForFixture
);
