import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
export default class DriverPaymentsModal extends Component {
  /** @type {string} color of the approve button */
  approvedButtonColor = null;

  /** @type {string} icon of the approve button */
  approvedButtonIcon = null;

  /** @type {string} read state of the approve button */
  approvedButtonReadOnly = null;

  /** @type {string} text of the approve button */
  approvedButtonText = null;

  /** @type {Function} action that creates a new driver payment record in the store */
  createNewDriverPaymentAction = null;

  /** @type {Ember$DriverPayment} the driver payment being edited in the form */
  currentDriverPayment = null;

  /** @type {Ember$Driver} driver of reference */
  driver = null;

  /** @type {Ember$Array} all driver payroll runs */
  driverPayrollRuns = null;

  /** @type {Ember$Array} all driver payments for this modal */
  driverPayments = null;

  /** @type {number} count of driver payments in this modal */
  @computed('driverPayments')
  get driverPaymentsCount() {
    const driverPayments = this.get('driverPayments');
    return driverPayments.meta ? driverPayments.meta.recordCount : 0;
  }

  /** @type {String[]} list of predefined event types */
  @computed
  get eventTypes() {
    return ['adminTask', 'bowtie'];
  }

  /** @type {boolean} boolean controlling the transition between liquid views */
  onInitialView = true;

  /** @type {Function} sets up the Semantic UI popup */
  setupPopup = null;

  /** @type {Date} delivery date used for the modal */
  selectedDeliveryDate = null;

  /** @type {DriverPayrollRun} driver payroll run used for the modal */
  @computed('selectedDriverPayrollRunId')
  get selectedDriverPayrollRun() {
    return this.get('driverPayrollRuns').findBy('id', this.get('selectedDriverPayrollRunId'));
  }

  /** @type {number} id of the driver payroll run used for the modal */
  selectedDriverPayrollRunId = null;

  /** @type {number} total payment amount for this day for this driver */
  @computed('driverPayments')
  get totalAmount() {
    const driverPayments = this.get('driverPayments');
    return driverPayments.reduce((total, driverPayment) => total + driverPayment.get('amount'), 0);
  }

  /*
   * Events
   */
  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    if (this.get('selectedDriverPayrollRun')) {
      if (this.get('driverPaymentsCount') > 0) {
        this.hideFormView();
      } else {
        this.showFormView();
        this.createNewRecord();
      }
    }
  }

  /**
   * Internal
   */

  /**
   * createNewRecord - creates a new driver payment record from the store and sets it as current
   */
  createNewRecord() {
    const driverPayment = this.createNewDriverPaymentAction(
      this.get('selectedDriverPayrollRun'),
      this.get('selectedDeliveryDate')
    );

    this.set('currentDriverPayment', driverPayment);
  }

  /**
   * hideFormView - hides the form and gets back to the initial view
   */
  hideFormView() {
    this.set('onInitialView', true);
  }

  /**
   * _updateUI - gets current parameters and prepares the modal UI
   */
  _updateUI() {
    const selectedDriverPayrollRunId = this.get('selectedDriverPayrollRunId');
    const selectedDeliveryDate = this.get('selectedDeliveryDate');

    this.setupPopup(selectedDriverPayrollRunId, selectedDeliveryDate).then(() =>
      this.hideFormView()
    );
  }

  /**
   * showFormView - switches from the initial view to the form view
   */
  showFormView() {
    this.set('onInitialView', false);
  }

  @action
  approveDay() {
    const driverPayrollRun = this.get('selectedDriverPayrollRun');
    const approvals = driverPayrollRun.get('approvals');

    if (!approvals[this.get('selectedDeliveryDate')]) {
      approvals[this.get('selectedDeliveryDate')] = true;
      driverPayrollRun.set('approvals', approvals);
      driverPayrollRun.save().then(() => this._updateUI());
    }
  }

  @action
  back() {
    this.hideFormView();
  }

  @action
  destroyRecord() {
    const driverPayment = this.get('currentDriverPayment');

    if (driverPayment.get('isNew')) {
      this.hideFormView();
    } else {
      driverPayment.destroyRecord().then(() => this._updateUI());
    }
  }

  @action
  editDriverPayment(driverPayment) {
    this.set('currentDriverPayment', driverPayment);
    this.showFormView();
  }

  @action
  formDidSubmit() {
    this._updateUI();
  }

  @action
  showNewRecordFormView(needsNewRecord) {
    if (needsNewRecord) {
      this.createNewRecord();
    }

    this.showFormView();
  }
}
