import classic from 'ember-classic-decorator';
import FeedItemBuilder from './base';

@classic
export default class GroupOrderMemberItemBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);

    feedItem.subject = `${version.get('whodunnit')} ${version.get(
      'verb'
    )} group order member ${version.getValue('name')} (${version.getValue('email')})`;
    feedItem.icon = 'user';

    return feedItem;
  }
}
