import classic from 'ember-classic-decorator';
import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class NewRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'TAG_EDIT';

  model() {
    return hash({
      tag: this.store.createRecord('dietary-tag')
    });
  }
}
