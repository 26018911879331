import classic from 'ember-classic-decorator';
import Component from '@ember/component';

@classic
export default class CssControl extends Component {
  lines = 10;
  mode = '';
  value = null;
  onChange() {}
}
