/* global LE */
import config from 'star-fox/config/environment';
import ace from 'ember-ace';

export function initialize(_ApplicationInstance) {
  LE.destroy();
  LE.init(config.logentries.key);
  ace.config.set('basePath', `${config.assetsPrepend}assets/ace`);
}

export default {
  name: 'third-party-inits',
  initialize: initialize
};
