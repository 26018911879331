import Component from '@ember/component';
import { on } from '@ember/object/evented';
import { action, observer } from '@ember/object';

/**
 * Row content - no tag just to make contents re-usable
 */
export default Component.extend({
  /** @override */
  tagName: '',

  /** @type {boolean} */
  isSelectable: false,

  /** @type {boolean} */
  isEditable: false,

  /** @type {boolean} */
  isSelected: false,

  /** @type {boolean} */
  isEditing: false,

  /** @type {Object} */
  form: null,

  /** @type {Model} */
  value: null,

  /**
   * Observes isModelDirty on the form and hasDirtyAttributes and coalesces the ideas into one isModelDirty value and
   * emits an onModelDirtyChange event
   */
  observeModelDirty: on(
    'init',
    observer('form.self.isModelDirty', 'value.hasDirtyAttributes', function () {
      this.onModelDirtyChange(
        this.get('form.self.isModelDirty') || this.get('value.hasDirtyAttributes')
      );
    })
  ),

  /** @override */
  didInsertElement() {
    this._super(...arguments);
    const tableManager = this.get('tableManager');
    tableManager && tableManager.registerRow(this);
  },

  /** @override */
  didDestroyElement() {
    this._super(...arguments);
    const tableManager = this.get('tableManager');
    tableManager && tableManager.unregisterRow(this);
  },

  onExpand(_value) {},

  onCollapse(_value) {},

  onSelectChange() {},

  onModelDirtyChange() {},

  /** Method to submit the form - can be used by the TableManager to submit a row */
  submitForm() {
    const form = this.get('form');
    form && form.send('submit');
  },

  /** Method to select a single row - can be used by the TableManager to select a row */
  selectRow() {
    this.onSelectChange(true);
  },

  /** Method to deselect a single row - can be used by the TableManager to deselect a row */
  deselectRow() {
    this.onSelectChange(false);
  },

  @action
  toggleExpansion() {
    if (this.get('isExpanded')) {
      this.onCollapse(this.get('value'));
    } else {
      this.onExpand(this.get('value'));
    }
  }
});
