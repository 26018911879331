import classic from 'ember-classic-decorator';
import AdapterError from '@ember-data/adapter/error';
import RSVP from 'rsvp';

import CardService, { StripeErrorCodes } from 'star-fox/services/card-service';

/**  @enum {string} */
export const FailureSource = {
  ON_CARD_SAVE: 'onCardSave',
  ON_TOKEN_SAVE: 'onTokenSave'
};

/**  @enum {string} */
export const FailureType = {
  CVC: 'cvc',
  EXPIRY: 'expiry',
  NUMBER: 'number',
  DECLINED: 'declined'
};

/**
 * Mock Credit Card Service, can be set to fail.
 * @inherits CardService
 */
@classic
export default class MockCardServiceService extends CardService {
  /** @type {FailureType} */
  _failureType = null;

  /** @type {FailureSource} */
  _failureSource = null;

  _getCreateTokenMethod() {
    const failureType = this.get('_failureType');
    const failureSource = this.get('_failureSource');
    return function () {
      return RSVP.resolve().then((_) => {
        if (failureSource === FailureSource.ON_TOKEN_SAVE) {
          switch (failureType) {
            case FailureType.CVC:
              throw {
                error: {
                  code: StripeErrorCodes.INVALID_CVC,
                  message: `Your card's security code is incorrect. (Debug: Stripe)`
                }
              };
            case FailureType.EXPIRY:
              throw {
                error: {
                  code: StripeErrorCodes.INVALID_EXPIRY_MONTH,
                  message: `Your card is expired. (Debug: Stripe)`
                }
              };
            case FailureType.NUMBER:
              throw {
                error: {
                  code: StripeErrorCodes.INCORRECT_NUMBER,
                  message: `Your card is number is incorrect. (Debug: Stripe)`
                }
              };
            case FailureType.DECLINED:
              throw {
                error: {
                  code: StripeErrorCodes.CARD_DECLINED,
                  message: `Your card is declined. (Debug: Stripe)`
                }
              };
          }
        } else {
          return { id: 'RANDOM_' + Math.floor(Math.random() * 1000) };
        }
      });
    };
  }

  _getSaveCardMethod() {
    const failureSource = this.get('_failureSource');
    const failureType = this.get('_failureType');

    return function () {
      //record/this at the time this method is called, will be the card model, because of the use
      //of apply.
      const record = this;
      return RSVP.resolve().then((_) => {
        if (failureSource === FailureSource.ON_CARD_SAVE) {
          switch (failureType) {
            case FailureType.CVC:
              throw new AdapterError(null, `Your card's security code is incorrect.`);
            case FailureType.EXPIRY:
              throw new AdapterError(null, `Your card has expired.`);
            case FailureType.NUMBER:
              throw new AdapterError(null, `Your card number is incorrect.`);
            case FailureType.DECLINED:
              throw new AdapterError(null, `Your card was declined.`);
          }
        } else {
          record.setProperties({
            id: Math.ceil(Math.random() * 10000),
            number: null,
            cvc: null,
            expiryMonth: '12',
            expiryYear: '2020',
            type: 'Visa',
            lastFourDigits: '4242'
          });

          //Sets the dirty record to having been 'saved'
          record.send('pushedData');
          return record;
        }
      });
    };
  }

  /**
   * Sets the service to fail, by way of type and by source (stripe or card resource)
   * @param {FailureType} type Type of failure.
   * @param {FailureSource} source Source of the failure.
   */
  setToFail(type = FailureType.NUMBER, source = FailureSource.ON_CARD_SAVE) {
    this.setProperties({
      _failureType: type,
      _failureSource: source
    });
  }

  /** Sets the service to succeed by clearing failure type and source */
  setToSucceed() {
    this.setProperties({
      _failureType: null,
      _failureSource: null
    });
  }

  /** @override */
  saveCard(card, saveMethod, saveOptions) {
    const createTokenMethod = this._getCreateTokenMethod();
    const saveCardMethod = this._getSaveCardMethod();

    this.set('stripe', { card: { createToken: createTokenMethod } });
    return super.saveCard(card, saveCardMethod, saveOptions);
  }
}
