import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class IndexRoute extends Route.extend(CapabilityRouteMixin) {
  mealPlanIsEditing = false;

  queryParams = {
    sort: { refreshModel: true },
    expanded: { refreshModel: false },
    showSingles: { refreshModel: true }
  };

  beforeModel(transition) {
    if (this.get('_abortInBeforeModel')) {
      transition.abort();
    }
  }

  model(params) {
    const planningParams = this.paramsFor('logged-in.planning');

    const { area, monday } = planningParams;
    const { sort, showSingles } = params;

    if (!area) {
      return null;
    }

    return RSVP.hash({
      area: area,

      restaurants: this.store.query('restaurant', {
        page: { limit: 300 },
        fields: {
          restaurants: 'name,active-menu,can-foodee-plus-team-order,team-order-lead-time,slug'
        },
        filter: { active: true, coming_soon: false, area_ids: area }
      }),

      instances: area
        ? this.store
            .query('meal-planning-instance', {
              include: [
                'meal-planning-reservations',
                'events.reservations',
                'events.reservations.client',
                'events.reservations.restaurant',
                'events.reservations.area',
                'events.reservations.order.restaurant',
                'events.restaurant-constraints.restaurants',
                'events.restaurant-constraints.requirement',

                // this doesn't feel great
                'restaurant-constraints.restaurants',
                'restaurant-constraints.requirement',
                'order-template',
                'order-template.client-location',
                'client.admins'
              ].join(','),
              fields: {
                area: 'city,delivery-lead-time',
                clients: 'account-name,admins,preference-profile',
                restaurants: 'name,active-menu,can-foodee-plus-team-order,team-order-lead-time',
                orders: 'identifier,state,restaurant,client-location',
                users: 'first-name,last-name,email,salesforce-email,'
              },
              sort: sort,
              filter: { area_ids: area, monday: monday, isRecurring: !showSingles },
              page: { limit: 300 }
            })
            .then((_) => _.toArray())
        : null,

      clientsToPlan:
        area && monday
          ? this.store.query('client', {
              page: { limit: 300 },
              fields: { clients: 'account-name,meal-planning-templates' },
              filter: { area_ids: area, has_templates: true }
            })
          : null,

      expanded: params.expanded || []
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setProperties({
      templateToPublish: null,
      instancesToPlan: [],
      expanded: model ? model.expanded : []
    });

    controller.setupPusher();
  }

  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);
    controller.teardownPusher();

    controller.setProperties({
      instancesToPlan: [],
      expanded: []
    });
  }

  @action
  reload() {
    this.refresh();
  }

  @action
  willTransition(_transition) {
    if (this.get('mealPlanIsEditing')) {
      if (
        !confirm(
          'Your changes on this meal plan have not been saved. Are you sure you want to leave?'
        )
      ) {
        this.set('_abortInBeforeModel', true);
      }
    }

    return true;
  }

  @action
  didTransition() {
    this.set('_abortInBeforeModel', false);

    return true;
  }
}
