import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class IndexRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'CLIENT_SHOW';

  model(_params) {
    return this.modelFor('logged-in.clients.edit');
  }

  /**
   * Areas should not block entering the route
   *
   * @param controller
   * @param model
   */
  setupController(controller, model) {
    super.setupController(controller, model);

    this.store.findRecord('client', model.get('id'), {
      reload: true,
      include: 'tax-rate'
    });

    this.store
      .query('area', {
        page: {
          limit: 100,
          offset: 0
        }
      }) // My solution for now is to query all of the tags so we get simple search
      .then((areas) => controller.set('areas', areas));

    this.store
      .findAll('invoicing-tax-rate')
      .then((taxRates) => controller.set('taxRates', taxRates));

    this.store
      .findAll('discount-code')
      .then((discountCodes) => controller.set('discountCodes', discountCodes));

    // setup a new delivery location which can be created from here.
    const newPaymentCard = this.store.createRecord('payment-card', {
      client: model,
      country: 'CA'
    });

    const newDeliveryLocation = this.store.createRecord('delivery-location', {
      client: model
    });

    controller.setProperties({
      newPaymentCard,
      newDeliveryLocation,
      originalTermDays: model.get('termDays')
    });
  }
}
