import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class DriverPayrollRun extends Application {
  serialize(snapshot, options) {
    const json = super.serialize(snapshot, options);
    json.data.custom = {
      'driver-id': snapshot.record.get('masterFoxDriverId')
    };

    return json;
  }
}
