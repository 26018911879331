import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from 'renard/models/foodee';
import { fragment } from 'ember-data-model-fragments/attributes';
import { modelAction } from 'ember-custom-actions';

@classic
export default class LogisticsDelivery extends Model {
  /*
   * Attributes
   */
  @attr('string')
  orderType;

  @attr('string')
  identifier;

  @attr('boolean')
  isFoodhall;

  @attr('boolean')
  isAsap;

  @attr('boolean')
  lastMinute;

  @attr('boolean')
  allowsPayOutOfPocket;

  @fragment('fragments/order-notes', { defaultValue: {} })
  notes;

  @attr('string')
  monitoringNotes;

  @attr('string')
  thirdPartyLogisticsException;

  @attr('date')
  deliverAt;

  @attr('object')
  deliveryEstimate;

  @attr('date')
  pickupAt;

  @attr('date')
  deliveredAt;

  @attr('date-only')
  deliverOn;

  @attr('date')
  pickedUpAt;

  @attr('string', { defaultValue: 'draft' })
  state;

  @attr('string')
  numberOfPeople;

  @attr('number')
  totalAmount;

  @attr('boolean')
  flag;

  @attr('string')
  alertStatus;

  @attr('boolean')
  isMealPlanOrder;

  // MIGHT NEED THESE
  // createdAt: attr('date'),
  // updatedAt: attr('date'),
  // largeOrder: attr('boolean'),
  // outOfZone: attr('boolean'),
  // outsideHours: attr('boolean'),

  /* 3rd party */
  @attr('string')
  thirdPartyLogisticsDropOffId;

  @attr('string')
  thirdPartyLogisticsPickUpId;

  @attr('string')
  thirdPartyLogisticsTrackingUrl;

  @attr('string')
  logisticsType;

  @attr('date')
  syncedWithThirdPartyLogisticsAt;

  /*
   * Relationships
   */
  @belongsTo('area', { async: false })
  area;

  @belongsTo('user', { async: false })
  driver;

  @belongsTo('order')
  order;

  @belongsTo('courier')
  courier;

  @attr('object')
  client;

  @attr('object')
  restaurant;

  @attr('object')
  clientLocation;

  @attr('object')
  restaurantLocation;

  @attr('array')
  orderItems;

  @computed
  get orderId() {
    return this.belongsTo('order').id();
  }

  loadOrderItems() {
    return Promise.resolve(this.orderItems);
  }

  loadSalesforceCases() {
    return this.get('store').query('salesforceCase', {
      filter: { orderId: this.get('orderId'), origin: 'Starfox' }
    });
  }

  allocateDelivery = modelAction('allocate-delivery', { method: 'PATCH' });
  deallocateDelivery = modelAction('deallocate-delivery', { method: 'PATCH' });
}
