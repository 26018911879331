import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
@classNames('fde-client-status')
@classNameBindings('compact:fde-is-compact')
export default class ClientStatus extends Component {
  /** @type boolean */
  compact = false;

  /** @type {Client} */
  client = null;

  /** @type {Service} */
  @service
  session;
}
