import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@tagName('')
export default class UsersPane extends Component {
  /** @type {User} */
  selection = null;

  /** @type {User} */
  @alias('selection')
  user;
}
