import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class Restaurant extends Application {
  attrs = {
    tags: {
      serialize: true
    },

    completionProgress: {
      serialize: false
    },

    menus: {
      serialize: false
    },

    defaultPickupLocation: {
      serialize: false
    },

    restaurantStoryId: {
      serialize: false
    },

    pickupLocations: {
      serialize: true
    },

    billingLocation: {
      serialize: false
    },

    features: {
      serialize: false
    },
    salesforceId: {
      serialize: false
    }
  };
}
