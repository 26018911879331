import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';

import { task } from 'ember-concurrency';

@classic
export default class SubscriptionController extends Controller {
  subscribingTo = null;

  @alias('model.client.subscriptionPlan.content')
  clientSubscriptionPlan;

  @computed('model.subscriptionPlans.[]', 'model.client.subscriptionPlan.content')
  get subscriptionPlans() {
    const clientSubscriptionPlan = this.get('clientSubscriptionPlan');
    const defaultSubscriptionPlans = this.get('model.subscriptionPlans').toArray();

    if (clientSubscriptionPlan && !defaultSubscriptionPlans.includes(clientSubscriptionPlan)) {
      return [...defaultSubscriptionPlans, clientSubscriptionPlan];
    } else {
      return defaultSubscriptionPlans;
    }
  }

  showSubscriptionForm = false;

  @(task(function* (subscriptionPlan) {
    const client = this.get('model.client');
    this.set('subscribingTo', subscriptionPlan);
    yield client.subscribe({ subscription_plan_id: subscriptionPlan.get('id') });

    this.set('showCustomPlanSelector', false);
  }).restartable())
  subscribeTask;

  activityFeedFilters = [
    {
      id: 'subscription_plans',
      label: 'Subscription Plans',
      icon: 'money',
      single: true,
      key: 'subscriptionPlan',
      value: [true],

      // override the standard behavior
      filter(version) {
        return !!version.get('objectChanges.subscriptionPlanId');
      }
    }
  ];

  jrActivityFeedFilter = { propertyChanged: 'subscription_plan_id' };
  selectedFilters = ['subscription_plans'];

  @computed('model.client')
  get versionChannels() {
    return [`ee.food.restaurants.${this.get('model.client.id')}`];
  }

  @action
  customize(subscriptionPlan) {
    const client = this.get('model.client');
    client.set(
      'subscriptionPlan',
      this.store.createRecord('subscription-plan', {
        plan: `Custom ${subscriptionPlan.get('plan')} - For: ${client.get(
          'name'
        )} - ${new Date().toLocaleDateString()}`,
        planType: subscriptionPlan.get('planType'),
        description: subscriptionPlan.get('description'),
        price: subscriptionPlan.get('price'),
        deliveryPrice: subscriptionPlan.get('deliveryPrice'),
        featureFlagDescriptions: subscriptionPlan.get('featureFlagDescriptions').toArray(),
        isCustom: true
      })
    );

    this.set('showSubscriptionForm', true);
  }
}
