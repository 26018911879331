import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import { action } from '@ember/object';

@classic
export default class NewController extends Controller {
  @action
  handleDidSubmit() {
    this.send('refreshModel');
    this.transitionToRoute('logged-in.admins.tax-rates');
  }

  @action
  handleDidReset() {
    this.get('model.taxRate').destroyRecord();
    this.transitionToRoute('logged-in.admins.tax-rates');
  }
}
