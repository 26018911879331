import classic from 'ember-classic-decorator';
import RSVP from 'rsvp';
import Route from '../ledger/route';

@classic
export default class LegacyLedgerRoute extends Route {
  orderEditRoute = 'logged-in.orders.edit';
  templateName = 'logged-in.orders.edit.accounting.ledger';

  model({ ledger_id }) {
    const { order } = this.modelFor(this.get('orderEditRoute'));
    return RSVP.hash({
      order: order,
      client: order.get('client'),
      ledger: this.store.findRecord('invoicing-ledger-item', ledger_id),
      discountCodes: this.store.findAll('discount-code')
    });
  }
}
