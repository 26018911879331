import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { isPresent } from '@ember/utils';

@classic
@tagName('')
export default class FilterSelect extends Component {
  /** @type {string} */
  icon = null;

  /** @type {string} */
  placeholder = 'Filter';

  /** @type {string} */
  filterParam = null;

  /** @type {boolean} */
  orderByLabel = true;

  /** @type {string} */
  classNames = '';

  /** @type {string[]} */
  @computed('filterParam')
  get filters() {
    return this.get('filterParam')
      .split(',')
      .filter((filter) => isPresent(filter));
  }

  /** @type {Object[]} */
  possibleFilters = null;

  /** @type {string} */
  filterKey = null;

  /** @type {string[]} */
  @computed('filters.[]', 'possibleFilters')
  get availableFilters() {
    const filters = this.get('filters');
    const possibleFilters = this.get('possibleFilters');

    return possibleFilters.reject((filter) => filters.includes(filter.get(this.get('filterKey'))));
  }

  onFilterChange() {}

  @action
  handleFilterChange(newFilter) {
    console.debug('sf.filters.filter-select#handleFilterChange', newFilter);

    const filters = this.get('filters');
    const filter = newFilter.get(this.get('filterKey'));
    filters.push(filter);

    this.onFilterChange(filters.join(','));
  }
}
