import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';

import { action } from '@ember/object';

@classic
export default class MealPlanningReservationController extends Controller {
  @action
  addReservation() {
    return this.store
      .createRecord('meal-planning-reservation', {
        client: this.get('model.client'),
        area: this.get('model.client.defaultArea'),
        restaurant: this.get('model.restaurants.first'),
        deliverAt: new Date(),
        pickupAt: new Date()
      })
      .save({ include: 'area,client,restaurant' });
  }
}
