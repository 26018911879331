import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@ember/component';

import { task } from 'ember-concurrency';

let fetchedDiscountCodes = null;

@classic
@classNames('fde-invoicing-discount-list')
export default class DiscountList extends Component {
  /** @override */
  @service
  store;

  /** @type {DiscountCodes[]} */
  discountCodes = null;

  /** @type {RestaurantDiscount|ClientDiscount} */
  discountToEdit = null;

  /** @type {Model} */
  model = null;

  /** @type {boolean} */
  isReadonly = false;

  /** @type {String} */
  discountType = 'client';

  /** @type {String} */
  discountCollectionName = 'clientDiscounts';

  /** @type {String} */
  discountListTitle = '';

  /** @type {boolean} */
  isForOwner = false;

  /** Event fired when the discount was saved */
  didSaveDiscount() {}

  /** @override */
  didInsertElement() {
    super.didInsertElement(...arguments);
    if (!fetchedDiscountCodes && !this.get('discountCodes')) {
      this.get('loadDiscountCodesTask').perform();
    }

    this.set('discountCodes', this.get('discountCodes') || fetchedDiscountCodes);
  }

  /** Fetches the DiscountCodes and caches them in the component  */
  @task(function* () {
    fetchedDiscountCodes = yield this.get('store').findAll('discount-code');
    this.set('discountCodes', fetchedDiscountCodes.toArray());
  })
  loadDiscountCodesTask;

  /** Creates a new client discount against the model */
  createDiscount() {
    const discount = this.get('store').createRecord(`${this.get('discountType')}-discount`, {
      amountType: 'value'
    });

    discount.set(
      this.get('isForOwner') ? 'owner' : this.get('model').constructor.modelName,
      this.get('model')
    );
  }

  selectLatestDiscount() {
    this.set('discountToEdit', this.get(`model.${this.get('discountCollectionName')}.last`));
  }

  @action
  onAddDiscount() {
    this.createDiscount();
    this.selectLatestDiscount();
  }

  @action
  onRemoveDiscount(discount) {
    if (!discount.get('isSaving')) {
      return discount.destroyRecord();
    }
  }
}
