import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { KEYS } from 'renard/models/app-configuration';

@classic
export default class GiftbitRoute extends Route {
  model(_params) {
    return RSVP.hash({
      americanGifts: this.store.query('giftbit-gift', { filter: { currencyisocode: 'USD' } }),

      canadianGifts: this.store.query('giftbit-gift', { filter: { currencyisocode: 'CAD' } }),

      giftbitConfiguration: this.store
        .query('app-configuration', { filter: { key: KEYS.giftbit } })
        .then((appConfiguration) => {
          return (
            appConfiguration.get('firstObject') ||
            this.store.createRecord('app-configuration', {
              key: KEYS.giftbit,
              preferences: {
                emailSubject: '',
                emailMessage: '',
                americanGiftIds: [],
                canadianGiftIds: []
              }
            })
          );
        })
    });
  }
}
