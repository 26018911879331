import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import moment from 'moment-timezone';
import { action } from '@ember/object';

@classic
@tagName('')
export default class RestaurantsPane extends Component {
  init() {
    super.init(...arguments);
    this.date = moment().add(1, 'day').toDate();
  }

  /** @type {Restaurant} */
  selection = null;

  /** @type {Restaurant} */
  @alias('selection')
  restaurant;

  /** @type {Date} */
  date = null;

  @action
  makeOrder() {
    this._makeOrder(false);
  }

  @action
  makeGroupOrder() {
    this._makeOrder(true);
  }

  @action
  showRestaurantHistory() {
    const restaurant = this.get('restaurant');
    this.get('quickSearch').hideSearchPanel();

    const router = this.get('router');

    router.transitionTo('logged-in.orders', {
      queryParams: {
        deliver_on_range: undefined,
        restaurant_id: restaurant.get('id') + '-' + restaurant.get('name'),
        sort: '-deliverAt'
      }
    });
  }

  /**
   * Make a order for this restaurant
   *
   * @param {boolean} isGroupOrder
   */
  _makeOrder(isGroupOrder) {
    const restaurantId = this.get('restaurant.id');
    const clientId = null;
    const areaId = this.get('restaurant.areas.firstObject.id');
    const deliverAt = this.get('date');

    this.get('quickSearch').doWhileNavigating(() =>
      this.get('router').transitionTo('logged-in.new-order', {
        queryParams: {
          clientId,
          restaurantId,
          areaId,
          deliverAt,
          isGroupOrder,
          hasMainDetails: true
        }
      })
    );
  }
}
