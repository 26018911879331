import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-form-controls-cover-image-control')
export default class CoverImageControl extends Component {
  /** @type {string} url of cover image */
  value = null;

  /** Change handler */
  onChange() {}
}
