import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { TYPES } from '../component';

@classic
@tagName('')
export default class ValueField extends Component {
  /** @type {Value} */
  values = null;

  /** @type {string} */
  control = null;

  /** @type {Array} */
  selectableValues = null;

  /** @type {string} */
  groupByKey = null;

  /** @type {string} */
  noneText = '';

  /** @type {FormFor} */
  form = null;

  /**
   * @param {string} value
   */
  nextValue(value) {
    const valueMap = {
      all: 'any',
      any: 'not',
      not: 'all'
    };

    value.set('type', valueMap[value.get('type')]);

    this.doSubmit();
  }

  add() {
    this.get('values').add(TYPES.NOT);
  }

  remove(value) {
    this.get('values').remove(value);
  }

  doSubmit() {
    this.get('form').doSubmit();
  }
}
