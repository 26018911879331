import Fixture, {
  // element,
  // elementWithValue,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class SfTabViewTabPaneFixture extends Fixture {
  constructor() {
    super('data-test-sf-tab-pane');
  }
}

export const sfTabViewTabPane = getElementDecoratorFor(SfTabViewTabPaneFixture);
export const sfTabViewTabPaneWithValue = getElementWithValueDecoratorFor(SfTabViewTabPaneFixture);
