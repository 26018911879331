import Fixture, {
  element,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class SfUiProgressFixture extends Fixture {
  constructor() {
    super('data-test-sf-ui-progress');
  }

  @element bar;
  @element barProgress;
  @element label;
}

export const sfUiProgress = getElementDecoratorFor(SfUiProgressFixture);
export const sfUiProgressWithValue = getElementWithValueDecoratorFor(SfUiProgressFixture);
