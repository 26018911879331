import Component from '@glimmer/component';
import { alias } from 'macro-decorators';
import { arg } from 'ember-arg-types';
import { object, func } from 'prop-types';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class SfOrdersModalsCreateGroupOrderMemberComponent extends Component {
  @arg(object.required) data;
  @arg(func.required) close;

  @alias('data.order')
  order;

  @service
  store;

  groupOrderMember;

  @action
  _close() {
    this.close(this.groupOrderMember);
  }

  constructor(...args) {
    super(...args);

    this.groupOrderMember = this.store.createRecord('group-order-member', { order: this.order });
  }
}
