import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';
import { computed } from '@ember/object';
import MenuItemValidator from 'star-fox/models/validators/menu-item';
import { fragment } from 'ember-data-model-fragments/attributes';

//Could move this to our own type of computed property
//Move to utils/(computed/)formatted-price, and return an Ember.computed object
const formattedPrice = function (key) {
  return {
    get() {
      return (this.get(key) / 100).toFixed(2);
    },

    set(_key, value) {
      // TODO Change number input fields to new form-for money or number
      // Quick fix b/c this was deployed as a hotfix
      const dollarsAndCents =
        typeof value == 'string' ? value.split('.') : value.toFixed(2).toString().split('.');

      const doNotRound = !this.overridePrices;

      if (doNotRound) {
        const priceToNearestNickel = (Math.round(value * 20) / 20).toFixed(2);

        // handles situations like 13.12
        if (dollarsAndCents[1] && dollarsAndCents[1].length > 1) {
          this.set(key, (priceToNearestNickel * 100).toFixed(0));
          return priceToNearestNickel;
        }

        const dollars = dollarsAndCents[0];

        // handles situations like 13.1
        if (dollarsAndCents[1] && dollarsAndCents[1].length == 1) {
          this.set(key, priceToNearestNickel);
          return `${dollars}.${dollarsAndCents[1]}`;
        }
      }

      // Set the real price in cents
      this.set(key, value * 100);

      // Don't force user input to arbitrarily add decimals
      // ie. if user enters 12.3 we don't want to return 12.30
      // b/c they haven't finished entering the price.
      return value;
    }
  };
};

function formatNames(items) {
  const ret = items.mapBy('name').join(', ');
  return ret === '' ? 'None' : ret;
}

export default Model.extend(MenuItemValidator, {
  /*
   * Attributes
   */
  clientPriceCents: attr('number'),
  notes: attr('string'),
  overridePrices: attr('boolean'),
  quantity: attr('number', { defaultValue: 1 }),
  restaurantPriceCents: attr('number'),
  capacityUnits: attr('number'),
  maximumServing: attr('number'),
  minimumServing: attr('number'),
  createdAt: attr('date'),
  updatedAt: attr('date'),

  restaurantDiscount: fragment('fragments/accounting/charge', {
    defaultValue: { amount: 0, amountType: 'percentage' }
  }),
  clientDiscount: fragment('fragments/accounting/charge', {
    defaultValue: { amount: 0, amountType: 'percentage' }
  }),

  /*
   * Relationships
   */
  order: belongsTo('order', { inverse: 'orderItems' }),
  menuOptionItems: hasMany('menu-option-item'),
  menuItem: belongsTo('menu-item'),
  groupOrderMember: belongsTo('group-order-member', { inverse: 'orderItems' }),

  /*
   * Computed Properties
   */
  clientPrice: computed('clientPriceCents', formattedPrice('clientPriceCents')),
  restaurantPrice: computed('restaurantPriceCents', formattedPrice('restaurantPriceCents')),

  servesRange: computed('maximumServing', 'minimumServing', function () {
    return this.get('maximumServing') !== this.get('minimumServing');
  }),

  /**
   * Whether or not any of the discounts are active
   *
   * @type {boolean}
   */
  discountsActive: computed.or('clientDiscount.active', 'restaurantDiscount.active'),

  /**
   * Calculates the order item's total cost including pickles options
   * @type {number}
   */
  singleItemTotalCents: computed('clientPriceCents', 'menuOptionItems.[]', function () {
    const price = this.get('clientPriceCents');
    const menuOptionItemsPrice = this.get('menuOptionItems').reduce((sum, val) => {
      const price = val.get('clientPriceCents');
      return price > 0 ? price + sum : sum;
    }, 0);

    return price + menuOptionItemsPrice;
  }),

  /**
   * Multiplies the order item's total cost by its quantity
   * @type {number}
   */
  clientTotalPriceCents: computed(
    'clientPriceCents',
    'quantity',
    'menuOptionItems.[]',
    function () {
      const qty = this.get('quantity');

      return qty * this.get('singleItemTotalCents');
    }
  ),

  clientTotalPrice: computed('clientTotalPriceCents', function () {
    return (this.clientTotalPriceCents / 100).toFixed(2);
  }),

  /**
   * Calculates the order item's total cost including pickles options
   * @type {number}
   */
  singleItemRestaurantTotalCents: computed(
    'restaurantPriceCents',
    'menuOptionItems.[]',
    function () {
      const price = this.get('restaurantPriceCents');
      const menuOptionItemsPrice = this.get('menuOptionItems').reduce((sum, val) => {
        const price = val.get('restaurantPriceCents');
        return price > 0 ? price + sum : sum;
      }, 0);

      return price + menuOptionItemsPrice;
    }
  ),

  /**
   * Multiplies the order item's total cost by its quantity
   * @type {number}
   */
  restaurantTotalPriceCents: computed(
    'singleItemRestaurantTotalCents',
    'quantity',
    'menuOptionItems.[]',
    function () {
      const qty = this.get('quantity');

      return qty * this.get('singleItemRestaurantTotalCents');
    }
  ),

  restaurantTotalPrice: computed('restaurantTotalPriceCents', function () {
    return (this.restaurantTotalPriceCents / 100).toFixed(2);
  }),

  /** @type {String} */
  menuGroupName: computed('menuItem', function () {
    return (this.get('menuItem.menuGroup.name') || '').replace(/\./g, '&#46;');
  }),

  /** @type {String} */
  menuGroupIndex: computed('menuItem', function () {
    return this.get('menuItem.menuGroup.position');
  }),

  /** @type {String} */
  mealType: computed('menuItem', function () {
    return formatNames(this.get('menuItem.allMealTypes'));
  }),

  /** @type {String} */
  foodType: computed('menuItem', function () {
    return formatNames(this.get('menuItem.allFoodTypes'));
  }),

  /** @type {String} */
  dietaryTags: computed('allDietaryTags', function () {
    return formatNames(this.get('allDietaryTags'));
  }),

  /** @type {DietaryTag[]} */
  allDietaryTags: computed('menuItem', 'menuOptionItems.[]', function () {
    const allMoiTags = this.get('menuOptionItems')
      .mapBy('dietaryTags')
      .reduce((acc, item) => item.toArray().concat(acc), []);

    return this.get('menuItem.dietaryTags').toArray().concat(allMoiTags).uniq();
  }),

  /*
   * Validations
   */
  validations: {
    clientPriceCents: {
      presence: {},
      format: {
        allowBlank: false,
        message: 'Custom items require a client price'
      }
    },
    restaurantPriceCents: {
      presence: {},
      format: {
        allowBlank: false,
        message: 'Custom items require a client price'
      }
    },
    quantity: {
      presence: {},
      numericality: { greaterThan: 0 }
    },
    order: {
      presence: {
        message: 'Order item requires an associated order'
      }
    }
  }
});
