import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import config from 'star-fox/config/environment';

@classic
export default class ApplicationController extends Controller {
  releaseVersion = config.releaseVersion;

  @computed
  get closeNotificationsAfter() {
    if (config.environment === 'test') {
      return null;
    }
    return 4000;
  }

  /** @type {Service} */
  @service
  store;
}
