import classic from 'ember-classic-decorator';
import Model, { attr, hasMany } from 'renard/models/foodee';

export const SubscriptionPlanPlanTypes = {
  BASIC: 'basic',
  BUSINESS: 'business',
  PRO: 'pro',
  ENTERPRISE: 'enterprise'
};

@classic
export default class SubscriptionPlan extends Model {
  @attr('string')
  plan;

  @attr('string')
  description;

  @attr('number')
  price;

  @attr('number')
  deliveryPrice;

  @attr('string')
  planType;

  @attr('boolean')
  isCustom;

  @attr('object')
  jsonClientOverride;

  @attr('string')
  ctaLink;

  @hasMany('feature-flag-description')
  featureFlagDescriptions;
}
