import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-controls-action-bar')
export default class ActionBar extends Component {
  /** @type {boolean} */
  hasSearch = false;

  /** @type {string} capability required to show the 'new' button */
  requiredNewCapability = null;

  /** @type {boolean} */
  hasNew = false;

  /** @type {boolean} */
  hasFilter = false;

  /** @type {string} */
  newLinkTo = null;

  /** @type {string} */
  filterLeftIcon = 'filter';

  /** @type {*} */
  filterValue = null;

  /** @type {*} */
  filterValues = null;

  /** @type {string} placeholder for the filter input */
  filterPlaceholder = 'Filter...';

  /** @type {boolean} */
  isSearching = false;

  /** @type {string} icon for the search text input */
  searchLeftIcon = 'search';

  /** @type {string} placeholder for the search text input */
  searchPlaceholder = 'Search...';

  /** @type {number} time in milliseconds to debounce the field */
  debounce = null;

  /** @type {string} */
  description = null;

  onFilterChange() {}
  onSearchChange() {}
  onNew() {}
}
