import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { isPresent } from '@ember/utils';
import RSVP from 'rsvp';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';
import { ORDER_EDIT_INCLUDE } from 'star-fox/features/logged-in/orders/edit/route';
import { next } from '@ember/runloop';
import { GroupOrderMemberState } from 'renard/transforms/group-order-member-state';

@classic
export default class CartRoute extends Route.extend(CapabilityRouteMixin, LoadingRouteMixin) {
  capability = 'ORDER_EDIT_EDIT_CART';

  /** @override */
  bubblesLoadingEvent = true;

  /** @override */
  queryParams = {
    memberPageLimit: { refreshModel: true },
    draftMemberPageLimit: { refreshModel: true },
    memberPageOffset: { refreshModel: true },
    draftMemberPageOffset: { refreshModel: true },
    groupOrderMemberSearchQuery: { refreshModel: true },
    isSearchActive: { refreshModel: true },
    displayDraftGroupOrderMembers: { refreshModel: false }
  };

  /** @type {Modal} */
  @service
  modal;

  /** @type {FetchTax} */
  @service
  fetchTax;

  isRefreshing = false;

  orderEditRoute = 'logged-in.orders.edit';

  /** @override */
  async model(params) {
    let order = this.modelFor(this.get('orderEditRoute')).order;

    const prevModel = this.getPrevModel();

    if (prevModel && this.get('isIndexTypeRouteRefreshing')) {
      return prevModel;
    }

    order = await (this.isRefreshing ? order.reloadWith(ORDER_EDIT_INCLUDE) : order);
    const menuId = order.belongsTo('menu').id();

    let draftGroupOrderMembers = await order.query('groupOrderMembers', {
      page: {
        limit: params.draftMemberPageLimit,
        offset: params.draftMemberPageOffset
      },
      filter: {
        search: params.groupOrderMemberSearchQuery,
        state: GroupOrderMemberState.Draft.value
      },
      include: 'order-items.menu-item,user',
      sort: 'name'
    });
    // convert ember data array to JS array before running next group order member query otherwise the subsequent query's result leak into this array
    const totalDraftGroupOrderMembersCount = draftGroupOrderMembers.meta.recordCount;
    draftGroupOrderMembers = draftGroupOrderMembers.toArray();

    let groupOrderMembers = await order.query('groupOrderMembers', {
      page: {
        limit: params.memberPageLimit,
        offset: params.memberPageOffset
      },
      filter: {
        search: params.groupOrderMemberSearchQuery,
        state: [GroupOrderMemberState.CheckedOut.value, GroupOrderMemberState.Cancelled.value]
      },
      include: 'order-items.menu-item,user',
      sort: 'name'
    });
    const totalGroupOrderMembersCount = groupOrderMembers.meta.recordCount;
    groupOrderMembers = groupOrderMembers.toArray();

    const modelHash = await RSVP.hash({
      order,
      groupOrderMembers,
      draftGroupOrderMembers,
      totalGroupOrderMembersCount,
      totalDraftGroupOrderMembersCount,
      orderItems: order.query('orderItems', {
        include: [
          'menu-item',
          'group-order-member',
          'menu-option-items.dietary-tags',
          'menu-option-items.menu-option-group'
        ].join(','),
        page: {
          limit: 200
        }
      }),
      activeMenu: order.get('restaurant').belongsTo('activeMenu').reload(),
      orderMenu: menuId ? this.store.findRecord('menu', menuId) : null,
      menuFilter: order.belongsTo('menuFilter').reload(),
      isSearchActive: params.isSearchActive
    });

    if (order.get('isPoop')) {
      await this.get('fetchTax').apply(order);
    }

    next(() => this.set('isRefreshing', false));

    return modelHash;
  }

  /** @override */
  setupController(controller, model) {
    super.setupController(...arguments);

    controller.setProperties({
      isSearchActive: model.isSearchActive === 'true',
      params: this.paramsFor('orders.edit.cart'),
      selectedGroupOrderMember: model.groupOrderMembers.get('firstObject'),
      totalGroupOrderMembersCount: model.totalGroupOrderMembersCount,
      totalDraftGroupOrderMembersCount: model.totalDraftGroupOrderMembersCount
    });

    controller.setupPusher();
    controller.fetchInvoice();
  }

  // Unsubscribe from the pusher channels we've been listening to.
  // Clean up when we leave. We probably don't want  to still be receiving events.
  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);
    controller.teardownPusher();
  }

  /** @override */
  deactivate() {
    this.get('modal')._nukeAllModals();
  }

  @action
  willTransition(transition) {
    const { order } = this.modelFor(this.get('orderEditRoute'));
    const unsavedOrderItems = order.get('orderItems').filterBy('isNew');

    if (
      isPresent(unsavedOrderItems) &&
      !confirm(
        'Some of your order items are still saving, and will be deleted. Are you sure you want to leave?'
      )
    ) {
      transition.abort();
    } else {
      unsavedOrderItems.forEach((orderItem) => orderItem.destroyRecord());
      return true;
    }
  }

  @action
  reload() {
    this.set('isRefreshing', true);
    this.refresh();
  }
}
