import DS from 'ember-data';
import MF from 'ember-data-model-fragments';

const { attr } = DS;

export default MF.Fragment.extend({
  active: attr('boolean'),
  name: attr('string'),
  cron: attr('string'),
  jobClass: attr('string'),
});
