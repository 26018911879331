import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import { guidFor } from '@ember/object/internals';
import Component from '@ember/component';
import EmberObject, { action, computed } from '@ember/object';
import RSVP from 'rsvp';

@classic
@classNames('fde-orders-email-messages')
export default class EmailMessages extends Component {
  init() {
    super.init(...arguments);
    this._resetMessage('restaurant');
    this._resetMessage('client');
  }

  /** @type {boolean} */
  readonly = false;

  /** @type {Order} */
  order = null;

  /** @type {Service} */
  @service
  ajax;

  /** @type {Service} */
  @service
  notify;

  /** @type {object} Form maps to custom object instead of a model.*/
  clientMail = null;

  /** @type {object} Form maps to custom object instead of a model.*/
  restaurantMail = null;

  /** @type {boolean} */
  mailIsSending = false;

  clientEmailMessages = null;
  restaurantEmailMessages = null;

  /** @type {Function} Updates client pagination */
  onClientPaginationChange() {}

  /** @type {Function} Updates client search term */
  onClientSearchTextChange() {}

  /** @type {Function} Updates restaurant pagination */
  onRestaurantPaginationChange() {}

  /** @type {Function} Updates restaurant search term */
  onRestaurantSearchTextChange() {}

  /** @type {string} */
  clientSearchText = '';

  /** @type {boolean} */
  isClientSearchLoading = false;

  /** @type {number} */
  clientEmailMessagePageOffset = 0;

  /** @type {number} */
  restaurantEmailMessagePageOffset = 0;

  /** @type {number} */
  emailMessagePageLimit = 0;

  /** @type {number} */
  clientEmailMessagesRecordCount = 0;

  /** @type {string} */
  restaurantSearchText = '';

  /** @type {boolean} */
  isRestaurantSearchLoading = false;

  /** @type {number} */
  restaurantEmailMessagesRecordCount = 0;

  /** @type {Function} function to handle navigation to next page of group order member paginator */
  handleNextPageClick() {}

  /** @type {Function} function to handle navigation to th previous page of group order member paginator*/
  handlePrevPageClick() {}

  onCommit() {}

  /** @type {string} Finds the unique Id of the ember modal. Ex Ember-177-client-mail-modal */
  @computed('model')
  get _clientMailModalId() {
    return `${guidFor(this)}-client-mail-modal`;
  }

  /** @type {string} Finds the unique Id of the ember modal. Ex Ember-177-restaurant-mail-modal */
  @computed('model')
  get _restaurantMailModalId() {
    return `${guidFor(this)}-restaurant-mail-modal`;
  }

  /**
   * @param {string} type client or restaurant
   */
  _resetMessage(type) {
    const key = `${type}Mail`;
    this.set(
      key,
      EmberObject.create({
        message: '',
        messageType: type
      })
    );
  }

  /** @returns {Promise} */
  _hideRestaurantModal() {
    this._resetMessage('restaurant');
    return this._hideModal('_restaurantMailModalId', 'restaurant');
  }

  /** @returns {Promise} */
  _hideClientModal() {
    this._resetMessage('client');
    return this._hideModal('_clientMailModalId', 'client');
  }

  /**
   * @param {string} idKey the id key of the modal to hide
   * @param {string} messageType client or restaurant
   * @returns {Promise}
   */
  _hideModal(idKey, messageType) {
    return new RSVP.Promise((resolve, _reject) => {
      $(`#${this.get(idKey)}`)
        .modal({
          onHidden() {
            resolve();
          }
        })
        .modal('hide');
    }, `Hiding ${messageType} Email Modal`).then(this._resetMessage(messageType));
  }

  /**
   * @param {string} idKey the id key of the modal to open
   * @param {string} messageType client or restaurant
   * @returns {Promise}
   */
  _openModal(idKey, messageType) {
    return new RSVP.Promise((_resolve, _reject) => {
      $(`#${this.get(idKey)}`)
        .modal({
          closable: true,
          context: '.ember-application'
        })
        .modal('show');
    }, `Showing ${messageType} Email Modal`);
  }

  /**
   * enqueues the order summary to be sent to the client
   * @param {string} idKey
   * @param {string} messageType
   * @returns {Promise}
   */
  _createMail(idKey, messageType) {
    const message = this.get(`${messageType}Mail.message`);
    const uri = `api/v3/orders/${this.get('order.id')}/send-summary`;
    const notify = this.get('notify');

    this.set('mailIsSending', true);
    return this.get('ajax')
      .postJSONAPI(uri, {
        type: 'email-messages',
        attributes: {
          message: message,
          'message-type': messageType
        }
      })
      .then((_) => {
        console.debug('[email-messages] Successfully sent mail');
        notify.success('Your message has been sent');
        this._resetMessage('restaurant');
        this._resetMessage('client');
        this._hideModal(idKey, messageType);
      })
      .catch((reason) => {
        console.debug('[email-messages] Error sending Mail', reason.error || reason);
        notify.success('We were unable to send your message');
        this.set(`${messageType}Mail.error`, 'Something went wrong');
      })
      .finally((_) => {
        this.set('mailIsSending', false);
      });
  }

  @action
  cancelClientMail() {
    this._hideClientModal();
  }

  @action
  cancelRestaurantMail() {
    this._hideRestaurantModal();
  }

  @action
  openRestaurantMailModal() {
    return this._openModal('_restaurantMailModalId', 'restaurant');
  }

  @action
  openClientMailModal() {
    return this._openModal('_clientMailModalId', 'client');
  }

  @action
  createClientMail() {
    return this._createMail('_clientMailModalId', 'client');
  }

  @action
  createRestaurantMail() {
    return this._createMail('_restaurantMailModalId', 'restaurant');
  }
}
