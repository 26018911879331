import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { RuleNameConversionMap, RuleTypeConversionMap } from '../../../../../../models/rules-rule';
import { ActionType } from '../../../../../../transforms/action-type';

@classic
@tagName('')
@classNames('')
export default class RuleConfigurator extends Component {
  @service
  store;

  @service
  notify;

  get ruleTypes() {
    return Object.keys(RuleTypeConversionMap).map((id) => ({
      id,
      value: id,
      label: RuleNameConversionMap[id]
    }));
  }

  @alias('rule.events')
  events;

  @alias('rule.allRoles')
  roles;

  rule = null;
  owner = null;
  searchedResources;
  testRecord;

  @action
  testRule() {
    return this.rule.execute({ record_global_id: this.testRecord.globalId });
  }

  @computed('rule.ruleType')
  get isNamedRule() {
    const type = this.get('rule.ruleType');
    const isSpecificRule = type !== 'rules-rule' && type !== 'rules-cron';

    return isSpecificRule;
  }

  @action
  addNewRule() {
    const owner = this.get('owner');

    const rule = this.get('store').createRecord('rules-rule', { owner: owner });

    return rule
      .save()
      .then((rule) => this.set('rule', rule))
      .catch((e) => this.get('notify').error('An error occurred:', e));
  }

  @action
  addPredicate() {
    return this.get('store').createRecord('rules-predicate', { rule: this.get('rule') });
  }

  @action
  addAction() {
    return this.get('store').createRecord('rules-action', {
      rule: this.get('rule'),
      actionType: ActionType.SetAlertStatus
    });
  }

  @action
  addNegativeAction() {
    return this.get('store').createRecord('rules-action', {
      rule: this.get('rule'),
      outcome: 'negative'
    });
  }

  @action
  addErrorAction() {
    return this.get('store').createRecord('rules-action', {
      rule: this.get('rule'),
      outcome: 'error'
    });
  }
}
