import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import RSVP from 'rsvp';

@classic
export default class RulesController extends Controller {
  @service
  notify;

  @computed
  get mockFact() {
    const notify = this.get('notify');

    return this.get('store').createRecord('rules-fact', {
      save() {
        notify.info(`Created a fact!`);
        return RSVP.Promise.resolve(true);
      }
    });
  }

  @computed
  get mockAction() {
    const notify = this.get('notify');

    return this.get('store').createRecord('rules-action', {
      save() {
        notify.info(`Created an action!`);
        return RSVP.Promise.resolve(true);
      }
    });
  }

  @computed
  get mockPredicate() {
    const notify = this.get('notify');

    return this.get('store').createRecord('rules-predicate', {
      save() {
        notify.info(`Created a predicate!`);
        return RSVP.Promise.resolve(true);
      }
    });
  }

  @computed
  get mockRule() {
    const notify = this.get('notify');

    return this.get('store').createRecord('rules-rule', {
      save() {
        notify.info(`Created a tule!`);
        return RSVP.Promise.resolve(true);
      }
    });
  }

  @computed
  get mockOwner() {
    return this.get('store').createRecord('area');
  }
}
