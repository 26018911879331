import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { empty } from '@ember/object/computed';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';
import { modelAction } from 'ember-custom-actions';

@classic
export default class AccountingConsolidationConfiguration extends Model {
  @attr('date-only')
  firstRunOn;

  @attr('date-only')
  lastRanOn;

  @attr('date-only')
  nextRunOn;

  @attr('string')
  period;

  @attr('string')
  queryType;

  @attr('string')
  queryData;

  @attr('string')
  currency;

  @attr('number')
  termDays;

  @belongsTo('client')
  client;

  @belongsTo()
  paymentCard;

  @hasMany('accounting-consolidation-runs')
  runs;

  // TODO improve
  @computed('period', 'queryType', 'queryData')
  get description() {
    return `C${this.get('id')} Every ${this.get('period')} for ${this.get('queryType')} ${this.get(
      'queryData'
    )}`;
  }

  @empty('runs')
  canDestroy;

  run = modelAction('run', { method: 'POST' });
}
