import classic from 'ember-classic-decorator';
import JSONSerializer from '@ember-data/serializer/json';

@classic
export default class GiftbitPointsAction extends JSONSerializer {
  serialize(_snapshot, _options) {
    let json = super.serialize(...arguments);

    delete json.type;
    delete json.giftbitCard;

    return json;
  }
}
