import Controller from 'star-fox/features/application/abstract-controller';
import EmberObject, { action, computed, set } from '@ember/object';
import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import $ from 'jquery';
import { copy, guidFor } from '@ember/object/internals';
import { isEmpty, isPresent } from '@ember/utils';
import RSVP from 'rsvp';
import { run } from '@ember/runloop';
import OrderItemOptions from 'star-fox/utils/order-item-options';
import { task, timeout } from 'ember-concurrency';
import moment from 'moment-timezone';
import PusherHandlersMixin from 'star-fox/mixins/pusher-handlers-mixin';
import { tracked } from '@glimmer/tracking';

@classic
export default class CartController extends Controller.extend(PusherHandlersMixin) {
  queryParams = [
    'memberPageLimit',
    'draftMemberPageLimit',
    'memberPageOffset',
    'draftMemberPageOffset',
    'groupOrderMemberSearchQuery',
    'displayDraftGroupOrderMembers'
  ];

  /** @override */
  pusherEvents = [
    'pusher:subscription_succeeded',
    'set-menu-counts-updated',
    'ledger-processing',
    'ledger-processed',
    'menu-changed',
    'order-quoted',
    'cart-cleared',
    'group-order-member-cancelled'
  ];

  /**
   * @type {string} the name of the channel that we use for presence,
   * defaults to ee.food.orders.{id}.model-events
   */
  @computed('order')
  get pusherChannels() {
    const orderId = this.get('order.id');
    const invoiceId = this.get('order.invoice.id');

    return [
      `ee.food.orders.${orderId}.order-events`,
      `ee.food.orders.${orderId}.model-events`,
      `ee.food.accounting.ledgers.${invoiceId}.events`
    ];
  }

  /** @type {Menu} the currently active menu for the restaurant */
  @alias('model.activeMenu')
  activeMenu;

  /** @type {Menu} the current menu for the order */
  @alias('model.orderMenu')
  orderMenu;

  /** @type {Modal} */
  @service
  modal;

  /** @type {Modal} */
  @service
  modals;

  /** @type {Notify} */
  @service
  notify;

  /** @type {SetMenuService} */
  @service
  setMenuService;

  /** @type {Object} Stored query params */
  params = null;

  /** @type {boolean} */
  expandAllMembers = false;

  /** @type {boolean} */
  isCalculatingInvoice = false;

  /** @type {Object[]} */
  splitViewStops = [
    { name: 'closed', value: 30 },
    { name: 'open', value: 440 }
  ];

  /** @type {number} */
  rightPaneSize = 440;

  /** @type {boolean} */
  displayDraftGroupOrderMembers = false;

  /** @type {string} Unique id for the group order member modal, in case multiple modals exist at the same time */
  @computed
  get _groupOrderMemberModalId() {
    return `${guidFor(this)}-group-order-member-modal`;
  }

  /** @type {string} Unique id for the custom item modal, in case multiple modals exist at the same time */
  @computed
  get _customItemModalId() {
    return `${guidFor(this)}-custom-item-modal`;
  }

  /** @type {string} Unique selector using unique id */
  @computed('_customItemModalId')
  get customItemSelector() {
    return `ui modal fde-custom-item-modal-content ${this.get('_customItemModalId')}`;
  }

  /** @type {Order} */
  @alias('model.order')
  order;

  /** @type {MealPlanningRequirement} */
  @alias('model.menuFilter')
  menuFilter;

  /** @type {Array.<MenuOptionGroup>} */
  menuOptionGroups = null;

  /** @type {Object} */
  orderItemOptions = null;

  /** @type {Object} Requires a blank object to properly set the bindings on the form-for in the modals **/
  orderItemToEdit = null;

  /** @type {string} message for custom price warning */
  @computed('orderItemToEdit.overridePrices')
  get customPriceMessage() {
    const overridePrice = this.orderItemToEdit.overridePrices;
    const defaultPricesMessage = 'Default prices will be used';

    return overridePrice ? null : defaultPricesMessage;
  }

  /** @type {GroupOrderMember} */
  groupOrderMemberToCreate = null;

  /** @type {string} Item name to display in custom item modal */
  customItemName = '';

  /** @type {string} Search query of group order member search bar */
  groupOrderMemberSearchQuery = '';

  /** @type {boolean} */
  isSearchActive = null;

  /** @type {number} memberPageOffset - current page of group order members */
  memberPageOffset = 0;

  /** @type {number} draftMemberPageOffset - current page of draft group order members */
  @tracked draftMemberPageOffset = 0;

  /** @property {number} memberPageLimit - members per page */
  memberPageLimit = 20;

  /** @property {number} draftMemberPageLimit - draft members per page */
  draftMemberPageLimit = 5;

  /** @type {boolean} */
  isSearchLoading = false;

  /** @type {GroupOrderMember} */
  @tracked
  selectedGroupOrderMember = null;

  /** @type {number} totalGroupOrderMembersCount */
  totalGroupOrderMembersCount;

  /** @type {number} totalDraftGroupOrderMembersCount */
  @tracked totalDraftGroupOrderMembersCount;

  /** @type {number} currentGroupOrderMemberLimitCount */
  @computed('totalGroupOrderMembersCount', 'memberPageOffset')
  get currentGroupOrderMemberLimitCount() {
    const totalMembers = this.get('totalGroupOrderMembersCount');
    const max = this.get('memberPageOffset') + 20;

    return max > totalMembers ? totalMembers : max;
  }

  /** @type {number} currentDraftGroupOrderMemberLimitCount */
  @computed('totalDraftGroupOrderMembersCount', 'draftMemberPageOffset')
  get currentDraftGroupOrderMemberLimitCount() {
    const totalMembers = this.get('totalDraftGroupOrderMembersCount');
    const max = this.get('draftMemberPageOffset') + 5;

    return max > totalMembers ? totalMembers : max;
  }

  /** @type {number} currentMemberPage - Current page number displayed in UI */
  @computed('memberPageOffset')
  get currentMemberPage() {
    return this.get('memberPageOffset') + 1;
  }

  /** @type {number} currentDraftMemberPage - Current draft page number displayed in UI */
  @computed('draftMemberPageOffset')
  get currentDraftMemberPage() {
    return this.get('draftMemberPageOffset') + 1;
  }

  /** @type {boolean} */
  @computed('order.setMenu')
  get setMenuHasChanged() {
    return this.get('order').changedAttributes().setMenu;
  }

  /** @type {boolean} */
  @computed('order.setMenu')
  get setMenuIsEmpty() {
    return this.get('setMenuService').isEmpty(this.get('order.setMenu'));
  }

  /** @type {SetMenu?} The set menu that was recorded before it started to change, so we can roll back just this attribute */
  _oldSetMenu = null;

  /** @type {string} */
  _memberSearchQuery = '';

  /** @type {boolean} */
  orderItemOptionsPresent = false;

  init() {
    super.init(...arguments);
    set(this, 'orderItemToEdit', EmberObject.create());
  }

  /**
   * Sends the event and params to the modal service
   * after setting the custom order item for the form.
   * Also provides sets orderItemOptions
   * @type {OrderItem} orderItemToEdit
   * @type {Event} event a click event
   * @type {Object} extraParams
   */
  _openCustomOrderModal(orderItemToEdit, event, extraParams = {}) {
    const orderItemOptions = OrderItemOptions.createFromOrderItem(orderItemToEdit);

    if (this.get('order.isGroupOrder')) {
      const isCartLimitedByPoop = this.get('selectedGroupOrderMember.isCartLimitedByPoop');
      const isOverBudget = this.get('selectedGroupOrderMember.isOverBudget');

      if (isCartLimitedByPoop && isOverBudget) {
        orderItemToEdit.deleteRecord();
        return;
      }
    }

    const params = {
      selector: this.get('customItemSelector'),
      orderItemToEdit,
      closable: false
    };

    const orderItemOptionsPresent = !!Object.keys(orderItemOptions).length;

    $.extend(params, extraParams);

    this.setProperties({
      customItemName: orderItemToEdit.get('menuItem.name'),
      orderItemToEdit,
      orderItemOptions,
      orderItemOptionsPresent
    });

    this.get('modal')
      .openModal(event, params)
      .then((_) => {
        $('.fde-custom-item-modal-content').addClass('scrolling');
      });
  }

  /**
   * @param {string} modalId the ID of the modal to open
   * @returns {Promise}
   */
  _openModal(modalId) {
    return new RSVP.Promise((resolve, _reject) => {
      // this is couply as eff.
      $(`#${modalId}`)
        .modal({
          context: '.ember-application',
          keyboardShortcuts: true,
          onVisible() {
            resolve();
          }
        })
        .modal('show');
    }, `Showing ${modalId} modal`);
  }

  /**
   * @param {string} modalId the ID of the modal to destroy
   * @returns {Promise}
   */
  _hideModal(modalId) {
    const $modal = $(`#${modalId}`);
    return new RSVP.Promise((resolve, _reject) => {
      $modal
        .modal({
          onHidden() {
            resolve();
            $modal.remove();
          }
        })
        .modal('hide');
    }, `Hiding ${modalId} Modal`);
  }

  /**
   * Creates a unique hash map of the current orderItems for quicker lookup
   * @type {Object}
   */
  @computed('order.orderItems.[]')
  get orderItemIndex() {
    return this.get('order.orderItems').reduce((acc, orderItem) => {
      if (!(orderItem.get('notes') || orderItem.get('isDeleted'))) {
        const menuItemId = orderItem.get('menuItem.id');
        const groupOrderMemberId = orderItem.get('groupOrderMember.id');
        const key = this._orderItemIndexKey(menuItemId, groupOrderMemberId);
        acc[key] = orderItem;
      }

      return acc;
    }, {});
  }

  /**
   * @param menuItemId
   * @returns {?OrderItem}
   * @private
   */
  _locateOrderItem(menuItemId) {
    const groupOrderMemberId = this.get('selectedGroupOrderMember.id');

    return this.get('orderItemIndex')[this._orderItemIndexKey(menuItemId, groupOrderMemberId)];
  }

  /**
   * Provides the key for the orderItemIndex hash
   * @param {string} menuItemId
   * @param {string} groupOrderMemberId
   * @returns {string}
   */
  _orderItemIndexKey(menuItemId, groupOrderMemberId = null) {
    const prefix = isPresent(groupOrderMemberId) ? `${groupOrderMemberId}-` : '';
    return `${prefix}${menuItemId}`;
  }

  /**
   * @param {OrderItem} orderItem
   */
  increaseOrderItem(orderItem) {
    console.info('[orders/edit/cart/controller]#increaseOrderItem', orderItem);
    if (orderItem.get('isSaving')) {
      return;
    }

    orderItem.incrementProperty('quantity');
  }

  async fetchInvoice() {
    const invoice = this.get('invoice');
    const orderInvoice = this.get('order.invoice');

    if (invoice === orderInvoice) {
      invoice.reload();
    } else {
      orderInvoice.then((invoice) => this.set('invoice', invoice));
    }
  }

  /**
   * @param {OrderItem} orderItem
   * @returns {Promise.<OrderItem>}
   */
  _saveOrderItem(orderItem) {
    if (orderItem && !orderItem.get('isSaving') && orderItem.get('hasDirtyAttributes')) {
      return orderItem.save().catch((response) => {
        if (orderItem.get('isNew')) {
          orderItem.destroyRecord();
        } else {
          orderItem.rollbackAttributes();
        }

        this._dealWithOrderItemBudgetError(
          response,
          'LoggedInOrdersEditCartController#_saveOrderItem'
        );
      });
    }
  }

  /**
   * @param {MenuItem} menuItem
   * @param {string|null} notes
   * @return {Promise.<OrderItem>}
   */
  createOrderItem(menuItem, notes = null) {
    const groupOrderMember = this.get('selectedGroupOrderMember');
    const order = this.get('order');

    if (!order) {
      return this.get('notify').error(
        "The order isn't associated with the order item you're trying to save.",
        {
          closeAfter: 3000
        }
      );
    }

    const orderItem = this.get('store').createRecord('order-item', {
      quantity: 1,
      order,
      menuItem,
      notes,
      groupOrderMember
    });

    return this._saveOrderItem(orderItem).catch((response) => {
      if (response.isAdapterError) {
        this._dealWithOrderItemBudgetError(response);
        orderItem.destroyRecord();
      }
    });
  }

  /**
   * Checks an error for whether the reason is budget related
   * If it is, it notifies the user. If not, it logs the message.
   * @param {Error|Object} response
   * @param {string} method the method that called the function. for display in logs
   */
  _dealWithOrderItemBudgetError(
    response,
    message = 'LoggedInOrdersEditCartController#_dealWithOrderItemBudgetError'
  ) {
    const notify = this.get('notify');

    if (response.errors[0].title.match(/budget/)) {
      notify.alert(`This User has exceeded their budget.`);
      console.info(message, 'OrderItem is over budget');
    } else if (response.errors[0].title.match(/limit/)) {
      notify.alert(`There are no more of this item left in the set menu.`);
      console.info(message, 'OrderItem is over Set Menu limit');
    } else if (response.errors[0].title.match(/capacity/)) {
      notify.alert(
        `You have reached the maximum number of allowed members, you can change this value on the order details page.`
      );
      console.info(message, 'OrderItem is over max team order member capacity');
    } else {
      console.error(message, response);
    }
  }

  /**
   * Rollsback changes, or deletes OrderItem
   * @param {OrderItem} orderItem
   * @private
   */
  _revertOrderItem(orderItem) {
    if (orderItem.get('isNew')) {
      orderItem.destroyRecord();
    } else {
      orderItem.rollbackAttributes();
    }
  }

  /**
   * Returns a new SetMenu that can be used for modification and safely applied to the Order model
   * so that Ember will recognize modifications to the SetMenu as a property change on the Order model.
   *
   * If no SetMenu is initialized on the order it will create a new SetMenu.
   *
   * This method will also cache the initially loaded SetMenu for use in rolling back if the user
   * decides to discard their changes.
   * @return {SetMenu}
   */
  _getSetMenu() {
    const setMenuService = this.get('setMenuService');
    const orderSetMenu = this.get('order.setMenu');

    //Cache the setMenu before changes are made, so that we can roll back to this specific one.
    if (!this.get('setMenuHasChanged')) {
      this.set('_oldSetMenu', orderSetMenu);
    }

    return setMenuService.isEmpty(orderSetMenu)
      ? setMenuService.create(
          `Set menu item for order ${this.get('order.id')}`,
          this.get('orderMenu.id')
        )
      : copy(orderSetMenu, true);
  }

  /**
   * Checks if this is a group order, and if it is
   * notifies the User if there isn't a selectedGroupOrderMember set
   * @returns {boolean}
   */
  _isMissingSelectedGroupOrderMember() {
    if (this.get('order.isGroupOrder') && !this.get('selectedGroupOrderMember')) {
      this.get('notify').alert(`Sorry, you need to select a group order member`);
      return true;
    }
    return false;
  }

  /**
   * Filters groupOrderMembers model by _memberSearchQuery
   */
  _doGroupMemberSearch() {
    this.setProperties({
      isSearchLoading: false,
      memberPageOffset: 0,
      draftMemberPageOffset: 0,
      memberPageLimit: 20,
      draftMemberPageLimit: 5,
      groupOrderMemberSearchQuery: this.get('_memberSearchQuery')
    });
  }

  /**
   * Reset menuOptionItems on orderItem
   * @returns {Promise.<OrderItem>}
   */
  _orderItemOptionsUpdate() {
    const orderItemToEdit = this.get('orderItemToEdit');
    const orderItemOptions = this.get('orderItemOptions');
    const notes = orderItemToEdit.get('notes');

    // People have the ability to submit blank notes so we should
    // explicitly set the notes attribute to null if none are present.
    if (notes && !notes.replace(/\s/g, '')) {
      orderItemToEdit.set('notes', null);
    }

    return OrderItemOptions.applyToOrderItem(orderItemToEdit, orderItemOptions);
  }

  /**
   * @type {Task}
   */
  @(task(function* () {
    yield timeout(300);

    this.send('reload');
  }).restartable())
  reloadRouteTask;

  @action
  async refreshModel() {
    return this.send('reload');
  }

  @action
  toggleNotes(checkboxValue) {
    this.set('orderItemToEdit.notes', checkboxValue ? 'Add allergy information!' : null);
  }

  @action
  handleOrderItemOptionsSave(form) {
    console.debug('[edit/cart/controller]#handleOrderItemOptionSave form:', form);

    if (this.get('order.isGroupOrder')) {
      const orderItem = form.model;
      const groupOrderMember = this.get('selectedGroupOrderMember');

      const isCartLimitedByPoop = groupOrderMember.get('isCartLimitedByPoop');
      const isOverBudget = groupOrderMember.get('isOverBudget');

      if (isCartLimitedByPoop && isOverBudget) {
        orderItem.deleteRecord();
        return RSVP.resolve(form.didSubmit());
      }
    }

    return RSVP.hash({
      parentSubmit: form.doSubmit(),
      orderItemOptionsSave: this._orderItemOptionsUpdate()
    });
  }

  @action
  handleAfterResetCustomItem(form) {
    return RSVP.hash({
      parentReset: form.doReset(),
      orderItemOptionsSave: this._orderItemOptionsUpdate()
    });
  }

  @action
  selectDefaultGroupOrderMember() {
    if (this.get('selectedGroupOrderMember.isDeleted')) {
      this.set(
        'selectedGroupOrderMember',
        this.get('order.groupOrderMembers').rejectBy('isDeleted').get('firstObject')
      );
    }
  }

  @action
  handleOnSearchQueryChange(_memberSearchQuery) {
    this.setProperties({ _memberSearchQuery, isSearchLoading: true });

    run.debounce(this, this._doGroupMemberSearch, 400);
  }

  @action
  handleClearSearchClick() {
    this.setProperties({
      isSearchActive: false,
      _memberSearchQuery: '',
      groupOrderMemberSearchQuery: '',
      memberPageOffset: 0,
      draftMemberPageOffset: 0,
      memberPageLimit: 20,
      draftMemberPageLimit: 5
    });
  }

  @action
  handleNextMemberPageClick() {
    const currentLimitCount = this.get('currentGroupOrderMemberLimitCount');
    const totalMembersCount = this.get('totalGroupOrderMembersCount');

    if (currentLimitCount !== totalMembersCount) {
      const currentOffset = this.get('memberPageOffset');
      const currentLimit = this.get('memberPageLimit');

      this.setProperties({
        memberPageOffset: currentOffset + currentLimit
      });
    }
  }

  @action
  handleNextDraftMemberPageClick() {
    const currentLimitCount = this.get('currentDraftGroupOrderMemberLimitCount');
    const totalDraftMembersCount = this.get('totalDraftGroupOrderMembersCount');

    if (currentLimitCount !== totalDraftMembersCount) {
      const currentOffset = this.get('draftMemberPageOffset');
      const currentLimit = this.get('draftMemberPageLimit');

      this.setProperties({
        draftMemberPageOffset: currentOffset + currentLimit
      });
    }
  }

  @action
  handlePrevMemberPageClick() {
    if (this.get('currentMemberPage') !== 1) {
      const currentOffset = this.get('memberPageOffset');

      this.setProperties({
        memberPageOffset: currentOffset - 20
      });
    }
  }

  @action
  handlePrevDraftMemberPageClick() {
    if (this.get('currentDraftMemberPage') !== 1) {
      const currentOffset = this.get('draftMemberPageOffset');

      this.setProperties({
        draftMemberPageOffset: currentOffset - 5
      });
    }
  }

  @action
  openCustomCreateOrderModal(menuItem) {
    if (this._isMissingSelectedGroupOrderMember()) {
      return;
    }

    const orderItem = menuItem.customOrderItemFor(
      this.get('order'),
      this.get('selectedGroupOrderMember')
    );

    const params = {
      onHide: (_) => this.get('orderItemToEdit').destroyRecord()
    };

    this._openCustomOrderModal(orderItem, event, params);
  }

  @action
  openCustomEditOrderModal(orderItem) {
    const params = {
      onHide: (_) => this.get('orderItemToEdit').rollbackAttributes()
    };
    this._openCustomOrderModal(orderItem, event, params);
  }

  @action
  onOrderItemFail(orderItem, response, message) {
    if (response.errors.find((_) => _.detail.includes('budget'))) {
      this._dealWithOrderItemBudgetError(response, message);
    } else if (response.errors.find((_) => _.detail.includes('capacity'))) {
      this.get('notify').error('Restaurant at capacity.');
    } else {
      this.get('notify').error(response.errors[0].title);
    }

    this._revertOrderItem(orderItem);
  }

  @action
  onCustomOrderItemFail(orderItem, response, message) {
    if (response.errors.find((_) => _.detail.includes('budget'))) {
      this._dealWithOrderItemBudgetError(response, message);
    } else {
      this.get('notify').error(response.errors[0].title);
    }

    const orderItemOptions = OrderItemOptions.createFromOrderItem(orderItem);

    const params = {
      selector: this.get('customItemSelector'),
      orderItem
    };

    const orderItemOptionsPresent = !!Object.keys(orderItemOptions).length;

    $.extend(params);

    this.setProperties({
      customItemName: orderItem.get('menuItem.name'),
      orderItem,
      orderItemOptions,
      orderItemOptionsPresent
    });
  }

  @action
  handleMenuItemClick(menuItem) {
    if (
      this._isMissingSelectedGroupOrderMember() ||
      this.get('selectedGroupOrderMember.isEditDisabled')
    ) {
      return;
    } else {
      const preExistingOrderItem = this._locateOrderItem(menuItem.get('id'));

      if (this.get('order.isGroupOrder')) {
        const groupOrderMember = this.get('selectedGroupOrderMember');

        const isCartLimitedByPoop = groupOrderMember.get('isCartLimitedByPoop');
        const amountToIncreaseBy = preExistingOrderItem
          ? preExistingOrderItem.get('clientTotalPriceCents')
          : menuItem.get('clientPriceCents');
        const isOverBudget = amountToIncreaseBy + groupOrderMember.get('memberPaysTotal') > 0;

        if (isCartLimitedByPoop && isOverBudget) {
          return;
        }
      }

      if (preExistingOrderItem) {
        this.increaseOrderItem(preExistingOrderItem);
      } else {
        this.createOrderItem(menuItem);
      }
    }
  }

  @action
  handleOrderItemSave(menuItem) {
    if (
      this._isMissingSelectedGroupOrderMember() ||
      this.get('selectedGroupOrderMember.isEditDisabled')
    ) {
      return;
    } else {
      const preExistingOrderItem = this._locateOrderItem(menuItem.get('id'));

      if (preExistingOrderItem) {
        this._saveOrderItem(preExistingOrderItem);
      }
    }
  }

  @action
  handleSetMenuItemClick(menuItem) {
    let setMenu = this._getSetMenu();

    const menuItemId = menuItem.get('id');

    if (setMenu.setMenuItems.hasOwnProperty(menuItemId)) {
      delete setMenu.setMenuItems[menuItemId];
    } else {
      setMenu.setMenuItems[menuItemId] = null;
    }

    if (this.get('setMenuService').isEmpty(setMenu.setMenuItems)) {
      setMenu = {};
    }

    this.set('order.setMenu', setMenu);

    console.debug(`[orders/edit/cart] Toggled set menu item.`, menuItem);
  }

  @action
  handleSetMenuItemQtyBlur(menuItem, qty) {
    const setMenu = this._getSetMenu();

    setMenu.setMenuItems[menuItem.get('id')] = isEmpty(qty) ? null : parseInt(qty);

    //In order to force the updattau
    this.set('order.setMenu', setMenu);

    console.debug(`[orders/edit/cart] Set set menu item qty.`, menuItem, qty);
  }

  @action
  handleSetMenuCancelClick() {
    const order = this.get('order');
    order.set('setMenu', this.get('_oldSetMenu'));

    this.set('_oldSetMenu', null);
  }

  @action
  handleSetMenuSaveClick() {
    this.get('order').save();
  }

  @action
  handleSetMenuSelectAllClick() {
    const setMenu = this._getSetMenu();

    this.get('orderMenu.activeMenuItems').forEach(
      (_) => (setMenu.setMenuItems[_.get('id')] = setMenu.setMenuItems[_.get('id')] || null)
    );

    this.set('order.setMenu', setMenu);
  }

  @action
  handleSetMenuClearClick() {
    this.set('order.setMenu', {});
  }

  @action
  handleSelectGroupOrderMember(groupOrderMember) {
    this.set('selectedGroupOrderMember', groupOrderMember);
  }

  @action
  afterCustomItemSave() {
    this.get('modal').closeModal(event, this.get('customItemSelector'));
  }

  @action
  async openGroupOrderMemberModal() {
    const groupOrderMember = await this.modals.open(
      'sf/orders/modals/create-group-order-member',
      { order: this.order },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false
        }
      }
    );

    if (groupOrderMember.id) {
      this.model.draftGroupOrderMembers.unshift(groupOrderMember);
      this.model.draftGroupOrderMembers.arrayContentDidChange();
      this.set('displayDraftGroupOrderMembers', true);
      this.handleSelectGroupOrderMember(groupOrderMember);
    }
  }

  @action
  handleClearCart() {
    const order = this.get('order');
    const notify = this.get('notify');

    order.clearCart().catch((_) => notify.error(`Unable to clear cart`));
  }

  @action
  setMenuCountsUpdated(counts) {
    const setMenu = this.get('order.setMenu');
    const newSetMenu = Object.assign({}, setMenu, { setMenuCounts: counts });

    this.set('order.setMenu', newSetMenu);
  }

  @action
  handleSetMenuItemsUpdated(setMenuItems) {
    const setMenu = this._getSetMenu();
    setMenu.setMenuItems = setMenuItems;

    this.set('order.setMenu', setMenu);
  }

  @action
  menuChanged(data) {
    console.info(
      `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Menu change received: ${data.menu_id}`
    );
    return this.send('reload');
  }

  @action
  ledgerProcessing() {
    this.set('isCalculatingInvoice', true);
  }

  @action
  ledgerProcessed() {
    this.get('invoice')
      .reload()
      .finally((_) => {
        this.set('isCalculatingInvoice', false);
      });
  }

  @action
  cartCleared(_data) {
    console.info(`${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Clear Cart Received`);
    this.get('reloadRouteTask').perform();
  }

  @action
  orderQuoted(_data) {
    console.info(`${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Order Quoted Received`);
    this.get('reloadRouteTask').perform();
  }

  @action
  handleToggleDisplayDraftGroupOrderMembers() {
    this.toggleProperty('displayDraftGroupOrderMembers');
  }

  @action
  handleMoveMemberToCheckedOutList(groupOrderMember) {
    this.model.draftGroupOrderMembers.removeObject(groupOrderMember);
    this.model.groupOrderMembers.pushObject(groupOrderMember);
    this.model.groupOrderMembers.arrayContentDidChange();
  }

  @action
  groupOrderMemberCancelled({ group_order_member_id }) {
    console.info(
      `${moment().format('hh:mm:ss ')} [${
        this.pubSub.name
      }] Group Order Member ${group_order_member_id} Cancelled`
    );
    const gom = this.store.peekRecord('group-order-member', group_order_member_id);

    if (gom && !this.order.state.isCancelled) {
      gom.unload();
    }
  }
}
