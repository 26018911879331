import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { equal } from '@ember/object/computed';
import $ from 'jquery';
import { guidFor } from '@ember/object/internals';
import { htmlSafe } from '@ember/template';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import RSVP from 'rsvp';
import { task } from 'ember-concurrency';

@classic
@classNames('fde-order-cart')
export default class OrderCart extends Component {
  /** @type {boolean} */
  readonly = false;

  /** @type {Order} Order object */
  order = null;

  /** @type {Invoice} Invoice object */
  invoice = null;

  /** @type {Form} Order Form object */
  form = null;

  /** @type {GroupOrderMember} The group order member who has been selected to have items added to their cart*/
  selectedGroupOrderMember = null;

  /** @type {number?} */
  totalGroupOrderMembersCount = null;

  /** @type {number?} */
  totalDraftGroupOrderMembersCount = null;

  /** @type {number?} */
  currentGroupOrderMemberLimitCount = null;

  /** @type {number?} */
  currentDraftGroupOrderMemberLimitCount = null;

  /** @type {GroupOrderMembers[]?} */
  groupOrderMembers = null;

  /** @type {GroupOrderMembers[]?} */
  draftGroupOrderMembers = null;

  /** @type {number?} */
  currentPage = null;

  /** @type {number?} */
  currentDraftPage = null;

  /** @type {boolean?} */
  canAddMember = null;

  /** @type {boolean} */
  expandAllMembers = false;

  /** @type {boolean} */
  isSearchActive = false;

  /** @type {boolean} */
  isSearchLoading = false;

  /** @type {string} */
  initialSearchValue = '';

  /** @type {boolean} */
  isCalculatingInvoice = false;

  /** @type {Notification} */
  @service
  notify;

  /** @type {Function} */
  toggleExpandAllMembers() {}

  /** @type {Function} action to call when a group order member has been selected */
  selectGroupOrderMember() {}

  /** @type {Function} function to clear the cart */
  onClearCart() {}

  /** @type {Function} function to clear the search query */
  onClearSearchClick() {}

  /** @type {Function} action that is called when searchQuery changes */
  handleOnSearchQueryChange() {}

  /** @type {Function} function to edit a custom order item */
  handleCustomItemEdit() {}

  /** @type {Function} */
  handleOnSaveItem() {}

  /** @type {Function} */
  handleOnDeleteItem() {}

  /** @type {Function} */
  handleOnFailItem() {}

  /** @type {Function} */
  handleDidDestroyGroupOrderMember() {}

  /** @type {Function} function to handle navigation to next page of group order member paginator */
  handleNextPageClick() {}

  /** @type {Function} function to handle navigation to next page of group order member paginator */
  handleNextDraftPageClick() {}

  /** @type {Function} function to handle navigation to th previous page of group order member paginator*/
  handlePrevPageClick() {}

  /** @type {Function} function to handle navigation to th previous page of group order member paginator*/
  handlePrevDraftPageClick() {}

  handleGroupOrderMemberClick() {}

  groupByKey = 'menuGroupName';

  @computed('groupByKey')
  get groupBySort() {
    const map = {
      menuGroupName: 'menuGroupIndex',
      mealType: 'mealType',
      foodType: 'foodType',
      dietaryTags: 'dietaryTags'
    };

    const ret = map[this.get('groupByKey')];

    return ret === 'None' ? 'zzzzzz' : ret;
  }

  /** @type {String} */
  @computed('groupByKey')
  get groupByLabel() {
    const map = {
      menuGroupName: 'Menu Group',
      mealType: 'Meal Type',
      foodType: 'Food Type',
      dietaryTags: 'Dietary Tags'
    };

    return htmlSafe(`Grouped By: <strong>${map[this.get('groupByKey')]}</strong>`);
  }

  /** @type {boolean} */
  @computed('order.perPersonBudget')
  get isBudgetActive() {
    return this.get('order.perPersonBudget') > 0;
  }

  /** @type {number} */
  @computed('invoice.clientInvoice.totalAmountCents')
  get totalAmountCents() {
    return this.get('invoice.clientInvoice.totalAmountCents') || 0;
  }

  /** @type {GroupOrderMember[]} */
  @computed(
    'order.groupOrderMembers.[]',
    'order.groupOrderMembers.@each.{isNew,isDeleted,state}',
    'groupOrderMembers.@each.{isNew,isDeleted,state}'
  )
  get filteredGroupOrderMembers() {
    if (this.order.state.isCancelled) {
      return this.get('groupOrderMembers').filter((member) => member.state.isCancelled);
    }

    if (this.get('groupOrderMembers')) {
      return this.get('groupOrderMembers').reject(
        (member) => member.get('isNew') || member.get('isDeleted') || member.state.isNotCheckedOut
      );
    }
    return [];
  }

  /** @type {DraftGroupOrderMember[]} */
  @computed('draftGroupOrderMembers.@each.{isNew,isDeleted}')
  get filteredDraftGroupOrderMembers() {
    if (this.get('draftGroupOrderMembers')) {
      return this.get('draftGroupOrderMembers').reject(
        (member) => member.get('isNew') || member.get('isDeleted') || member.state.isNotDraft
      );
    }
    return [];
  }

  onToggleDisplayDraftGroupOrderMembers() {}

  /** @type {boolean} */
  displayDraftGroupOrderMembers = false;

  /** @type {string} */
  @computed('displayDraftGroupOrderMembers')
  get displayDraftOrdersLabel() {
    return `${this.displayDraftGroupOrderMembers ? 'Hide' : 'Show'} Draft Orders`;
  }

  /** @type {OrderItem[]} */
  @computed('order.orderItems.@each.{isDeleted,isSaving}')
  get orderItems() {
    return this.get('order.orderItems')
      .sortBy('id')
      .reject(
        (orderItem) =>
          (!orderItem.get('isSaving') && orderItem.get('isDeleted')) || orderItem.get('isNew')
      );
  }

  /** @type {boolean} */
  @equal('currentPage', 1)
  isPrevPageDisabled;

  /** @type {boolean} */
  @equal('currentDraftPage', 1)
  isPrevDraftPageDisabled;

  /** @type {boolean} */
  @computed('currentGroupOrderMemberLimitCount', 'totalGroupOrderMembersCount')
  get isNextPageDisabled() {
    return (
      this.get('currentGroupOrderMemberLimitCount') === this.get('totalGroupOrderMembersCount')
    );
  }

  /** @type {boolean} */
  @computed('currentDraftGroupOrderMemberLimitCount', 'totalDraftGroupOrderMembersCount')
  get isNextDraftPageDisabled() {
    return (
      this.get('currentDraftGroupOrderMemberLimitCount') ===
      this.get('totalDraftGroupOrderMembersCount')
    );
  }

  /**
   * @type {string} id of the confirm destroying all the order items if present
   * @private
   */
  @computed('model')
  get _confirmClearModalId() {
    return `${guidFor(this)}-confirm-clear-modal`;
  }

  /**
   * Hides the modal for this form, wrapped in a promise so that acceptance tests execute in lock step.
   *
   * @private
   */
  _hideModal() {
    return new RSVP.Promise((resolve) => {
      $(`#${this.get('_confirmClearModalId')}`)
        .modal({
          onHidden() {
            resolve();
          }
        })
        .modal('hide');
    }, 'Hiding Confirmation Modal');
  }

  _clearCart() {
    return new RSVP.Promise((resolve) => {
      // this is couply as eff.
      $(`#${this.get('_confirmClearModalId')}`)
        .modal({
          closable: false,
          context: '.ember-application',
          keyboardShortcuts: true,
          onVisible() {
            resolve();
          }
        })
        .modal('show');
    }, 'Showing Confimation Modal');
  }

  @task(function* () {
    const emails = yield this.get('order').emailsOnOrder();
    const emailsJoined = emails.join(',');
    const emailsJoinedWithSpace = emails.join(', ');

    if (navigator.clipboard) {
      navigator.clipboard.writeText(emailsJoined);
      this.get('notify').success('Copied emails to clipboard!');
    }

    return emailsJoinedWithSpace;
  })
  emailsOnOrder;

  @action
  sum(a, b) {
    return a + b;
  }

  @action
  clearCart() {
    return this._hideModal().then((_) => this.onClearCart());
  }

  @action
  handleToggleExpandAllMembers(value) {
    this.toggleExpandAllMembers(value);
  }

  @action
  openClearCartModal() {
    return this._clearCart();
  }

  @action
  abortClearCart() {
    return this._hideModal();
  }

  @action
  openEmailsOnOrderModal() {
    this.set('showEmailsOnOrder', true);
    this.get('emailsOnOrder').perform();
  }

  @action
  handleMemberDidCheckout() {
    this.moveMemberToCheckedOutList();
  }
}
