import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class VersionsRoute extends Route {
  queryParams = {
    pageOffset: { refreshModel: true }
  };

  model(params) {
    const { pageLimit, pageOffset } = params;

    const restaurant = this.modelFor('logged-in.restaurants.show').restaurant;

    return restaurant.query('versions', {
      page: {
        limit: pageLimit,
        offset: pageOffset
      }
    });
  }
}
