import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

const US_STATES = [
  { id: 'AL', label: 'Alabama' },
  { id: 'AK', label: 'Alaska' },
  { id: 'AZ', label: 'Arizona' },
  { id: 'AR', label: 'Arkansas' },
  { id: 'CA', label: 'California' },
  { id: 'CO', label: 'Colorado' },
  { id: 'CT', label: 'Connecticut' },
  { id: 'DE', label: 'Delaware' },
  { id: 'FL', label: 'Florida' },
  { id: 'GA', label: 'Georgia' },
  { id: 'HI', label: 'Hawaii' },
  { id: 'ID', label: 'Idaho' },
  { id: 'IL', label: 'Illinois' },
  { id: 'IN', label: 'Indiana' },
  { id: 'IA', label: 'Iowa' },
  { id: 'KS', label: 'Kansas' },
  { id: 'KY', label: 'Kentucky' },
  { id: 'LA', label: 'Louisiana' },
  { id: 'ME', label: 'Maine' },
  { id: 'MD', label: 'Maryland' },
  { id: 'MA', label: 'Massachusetts' },
  { id: 'MI', label: 'Michigan' },
  { id: 'MN', label: 'Minnesota' },
  { id: 'MS', label: 'Mississippi' },
  { id: 'MO', label: 'Missouri' },
  { id: 'MT', label: 'Montana' },
  { id: 'NE', label: 'Nebraska' },
  { id: 'NV', label: 'Nevada' },
  { id: 'NH', label: 'New Hampshire' },
  { id: 'NJ', label: 'New Jersey' },
  { id: 'NM', label: 'New Mexico' },
  { id: 'NY', label: 'New York' },
  { id: 'NC', label: 'New Carolina' },
  { id: 'ND', label: 'North Dakota' },
  { id: 'OH', label: 'Ohio' },
  { id: 'OK', label: 'Oklahoma' },
  { id: 'OR', label: 'Oregon' },
  { id: 'PA', label: 'Pennsylvania' },
  { id: 'RI', label: 'Rhode Island' },
  { id: 'SC', label: 'South Carolina' },
  { id: 'SD', label: 'South Dakota' },
  { id: 'TN', label: 'Tennessee' },
  { id: 'TX', label: 'Texas' },
  { id: 'UT', label: 'Utah' },
  { id: 'VT', label: 'Vermont' },
  { id: 'VA', label: 'Virginia' },
  { id: 'WA', label: 'Washington' },
  { id: 'WV', label: 'West Virginia' },
  { id: 'WI', label: 'Wisconsin' },
  { id: 'WY', label: 'Wyoming' }
];

const CA_PROVINCES = [
  { id: 'BC', label: 'British Columbia' },
  { id: 'AB', label: 'Alberta' },
  { id: 'SK', label: 'Saskatchewan' },
  { id: 'MB', label: 'Manitoba' },
  { id: 'ON', label: 'Ontario' },
  { id: 'QC', label: 'Quebec' },
  { id: 'NL', label: 'Newfoundland' },
  { id: 'NS', label: 'Nova Scotia' },
  { id: 'NB', label: 'New Brunswick' },
  { id: 'PE', label: 'Prince Edward Island' },
  { id: 'YK', label: 'Yukon' },
  { id: 'NT', label: 'Northwest Territories' },
  { id: 'NU', label: 'Nunavut' }
];

const UK_COUNTY = [
  { id: 'Bedfordshire', label: 'Bedfordshire' },
  { id: 'Berkshire', label: 'Berkshire' },
  { id: 'Bristol', label: 'Bristol' },
  { id: 'Buckinghamshire', label: 'Buckinghamshire' },
  { id: 'Cambridgeshire', label: 'Cambridgeshire' },
  { id: 'Cheshire', label: 'Cheshire' },
  { id: 'City', label: 'City' },
  { id: 'Cornwall', label: 'Cornwall' },
  { id: 'Cumbria', label: 'Cumbria' },
  { id: 'Derbyshire', label: 'Derbyshire' },
  { id: 'Devon', label: 'Devon' },
  { id: 'Dorset', label: 'Dorset' },
  { id: 'Durham', label: 'Durham' },
  { id: 'East Riding of Yorkshire', label: 'East Riding of Yorkshire' },
  { id: 'East Sussex', label: 'East Sussex' },
  { id: 'Essex', label: 'Essex' },
  { id: 'Gloucestershire', label: 'Gloucestershire' },
  { id: 'Greater', label: 'Greater' },
  { id: 'Greater', label: 'Greater' },
  { id: 'Hampshire', label: 'Hampshire' },
  { id: 'Herefordshire', label: 'Herefordshire' },
  { id: 'Hertfordshire', label: 'Hertfordshire' },
  { id: 'Isle of Wight', label: 'Isle of Wight' },
  { id: 'Kent', label: 'Kent' },
  { id: 'Lancashire', label: 'Lancashire' },
  { id: 'Leicestershire', label: 'Leicestershire' },
  { id: 'Lincolnshire', label: 'Lincolnshire' },
  { id: 'Merseyside', label: 'Merseyside' },
  { id: 'Norfolk', label: 'Norfolk' },
  { id: 'North', label: 'North' },
  { id: 'Northamptonshire', label: 'Northamptonshire' },
  { id: 'Northumberland', label: 'Northumberland' },
  { id: 'Nottinghamshire', label: 'Nottinghamshire' },
  { id: 'Oxfordshire', label: 'Oxfordshire' },
  { id: 'Rutland', label: 'Rutland' },
  { id: 'Shropshire', label: 'Shropshire' },
  { id: 'Somerset', label: 'Somerset' },
  { id: 'South Yorkshire', label: 'South Yorkshire' },
  { id: 'Staffordshire', label: 'Staffordshire' },
  { id: 'Suffolk', label: 'Suffolk' },
  { id: 'Surrey', label: 'Surrey' },
  { id: 'Tyne', label: 'Tyne' },
  { id: 'Warwickshire', label: 'Warwickshire' },
  { id: 'West Midlands', label: 'West Midlands' },
  { id: 'West Sussex', label: 'West Sussex' },
  { id: 'West Yorkshire', label: 'West Yorkshire' },
  { id: 'Wiltshire', label: 'Wiltshire' },
  { id: 'Worcestershire', label: 'Worcestershire' }
];

const STATE_PROVINCE_COUNTY_COUNTRY_MAP = {
  US: US_STATES,
  CA: CA_PROVINCES,
  UK: UK_COUNTY
};

@classic
@tagName('')
export default class ProvinceSelectControl extends Component {
  /** @type {string} code of the country we want to select provinces / states in */
  country = 'CA';

  /** @type {boolean} */
  readonly = false;

  value = '';

  @computed('country')
  get statesProvincesCounties() {
    return STATE_PROVINCE_COUNTY_COUNTRY_MAP[this.country] || [];
  }

  @computed('value', 'statesProvincesCounties')
  get stateProvinceCounty() {
    return this.statesProvincesCounties.findBy('id', this.value);
  }

  /** @type {function} callback function when the selected value is changed */
  onChange() {}

  @action
  handleChange(stateProvinceCounty) {
    this.onChange(stateProvinceCounty.id);
  }

  /** @type {string} placeholder for no selection */
  @computed('country')
  get placeholder() {
    return {
      US: 'State',
      CA: 'Province',
      UK: 'County'
    }[this.get('country')];
  }
}
