import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { action } from '@ember/object';

@classic
@tagName('')
export default class OrdersPane extends Component {
  /** type {Order} */
  selection = null;

  /** type {Order} */
  @alias('selection')
  order;

  /** @type {QuickSearch} */
  quickSearch = null;

  /**
   * @param {Event} e
   */
  @action
  hideSearchPanel(e) {
    // if we are opening in a new window don't dismiss the panel
    if (!(e.metaKey || e.cntlKey)) {
      this.get('quickSearch').hideSearchPanel();
    }
  }
}
