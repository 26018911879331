import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';

@classic
export default class IndexRoute extends Route {
  queryParams = {
    weekOf: { refreshModel: true }
  };

  model(params) {
    const area = this.modelFor('logged-in.areas.edit');
    const controller = this.controllerFor('logged-in.areas.edit.index');

    controller.set('isLoading', true);

    return RSVP.hash({
      area: area,
      areaClosures: area.query('areaClosures', {
        page: {
          limit: 100
        },
        sort: 'startBlock',
        filter: {
          overlapsWeek: params.weekOf
        }
      })
    }).finally(() => controller.set('isLoading', false));
  }
}
