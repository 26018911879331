import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { action } from '@ember/object';
import $ from 'jquery';

let openPanel = null;

@classic
@tagName('')
export default class DrawerPane extends Component {
  /** @type {boolean} */
  isOpen = false;

  /** @type {boolean} */
  isModal = false;

  pickSide = false;
  onOpen() {}
  onClose() {}

  @action
  open() {
    if (openPanel === this) {
      return;
    }

    if (openPanel) {
      if (openPanel.get('isDestroyed')) {
        openPanel = null;
      } else {
        openPanel.close();
      }
    }

    this.set('isOpen', true);
    this.onOpen();

    openPanel = this;
  }

  @action
  close() {
    this.set('isOpen', false);
    this.onClose();

    openPanel = null;
  }

  handleEscape = null;

  didInsertElement() {
    super.didInsertElement(...arguments);
    if (openPanel === null && this.get('isOpen')) {
      openPanel = this;
    }

    this.handleEsacpe = (e) => {
      if (e.key === 'Escape') {
        this.close();
      }
    };

    $(document).on('keydown', this.handleEsacpe);
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    $(document).off('keydown', this.handleEsacpe);
  }
}
