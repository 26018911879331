import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Component from '@ember/component';

/**
 * Repeats n times a (block) operation.
 */
@classic
@tagName('')
export default class ViewPane extends Component {
  /** @type {?boolean} */
  isLeft = false;

  /** @type {?number} */
  value = null;

  /** @type {?boolean} */
  isTransitioning = null;

  /** @type {number} */
  transitionTime = null;

  /** @type {boolean} */
  useValue = false;

  /**
   * The style of the 'left' | 'top' is variant in either height / width or pixels / percentage
   */
  @computed('value', 'splitView.percentageMode', 'splitView.isVertical')
  get style() {
    if (!this.get('useValue')) {
      return htmlSafe('');
    }

    const value = this.get('value');
    const type = this.get('splitView.percentageMode') ? '%' : 'px';
    const attr = this.get('splitView.isVertical') ? 'height' : 'width';

    return htmlSafe(`min-${attr}: ${value}${type}; max-${attr}: ${value}${type};`);
  }
}
