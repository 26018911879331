import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@tagName('')
export default class TextareaControl extends Component {
  /** @type {boolean} Whether or not the textarea is editable. */
  readonly = false;

  /** @type {string} */
  leftLabel = '';

  /** @type {string} */
  rightLabel = '';

  /** @type {number} */
  rows = 3;

  /** @type {boolean} if set to false, will prevent change events on keyboard inputs */
  changeOnInputEvent = true;

  /** @type {string} */
  @computed('leftLabel', 'rightLabel')
  get labelClass() {
    let ret = [];

    if (this.get('leftLabel')) {
      ret.push('left');
    }

    if (this.get('rightLabel')) {
      ret.push('right');
    }

    if (ret.length > 0) {
      ret.push('labeled');
    }

    return ret.join(' ');
  }

  // icons

  /** @type {string} */
  @computed('leftIcon', 'rightIcon')
  get iconClass() {
    let ret = [];

    if (this.get('leftIcon')) {
      ret.push('left');
    }

    if (this.get('rightIcon')) {
      ret.push('right');
    }

    if (ret.length > 0) {
      ret.push('icon');
    }

    return ret.join(' ');
  }

  /** @type {string} */
  leftIcon = '';

  /** @type {string} */
  rightIcon = '';

  /** @type {string} */
  placeholder = '';

  /** @type {string} */
  min = null;

  /** @type {string} */
  max = null;

  /** @type {string} */
  step = null;

  onChange() {}
  onCommandEnter() {}
  onEsc() {}

  /** @type {function} Function for clicking outside of the textarea */
  onFocusOut() {}

  @action
  handleKeyUp(e) {
    if (this.changeOnInputEvent) {
      this.onChange(e.target.value);
    }

    if (e.code === 'Enter') {
      this.get('onCommandEnter')();
    }

    if (e.code === 'Escape') {
      this.get('onEsc')();
    }
  }

  @action
  handleChangeEvent(e) {
    this.onChange(e.target.value);
  }
}
