import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

export const FactTypeConversionMap = Object.freeze({
  equal: 'Rules::Facts::Equal',
  'equal-any': 'Rules::Facts::EqualAny',
  'less-than': 'Rules::Facts::LessThan',
  'not-equal': 'Rules::Facts::NotEqual',
  'less-than-or-equal': 'Rules::Facts::LessThanOrEqual',
  'greater-than': 'Rules::Facts::GreaterThan',
  'greater-than-or-equal': 'Rules::Facts::GreaterThanOrEqual',
  'within-range': 'Rules::Facts::WithinRange',
  'outside-range': 'Rules::Facts::OutsideRange'
});

@classic
export default class RulesFact extends Model {
  /*
   * Attributes
   */
  @attr('array')
  leftLiterals; // array

  @attr('string')
  leftPath;

  @attr('array')
  rightLiterals; // array

  @attr('string')
  rightPath;

  @attr('fact-type', { defaultValue: 'equal' })
  factType;

  /*
   * Relationships
   */
  @belongsTo('rules-predicate', { inverse: 'facts' })
  predicate;

  get rightLiteral() {
    return this.rightLiterals?.firstObject;
  }

  set rightLiteral(rightLiteral) {
    this.rightLiterals = [rightLiteral];
  }
}
