import classic from 'ember-classic-decorator';
import { attributeBindings, classNameBindings, classNames } from '@ember-decorators/component';
import { guidFor } from '@ember/object/internals';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { run } from '@ember/runloop';

/**
 * @typedef {Object} Section
 * @property {String} title
 * @property {Array.<*>}
 */

@classic
@classNames('fde-lists-section-list')
@classNameBindings('showAddRemoveControls:fde-lists-section-list_show-add-remove-controls')
@attributeBindings('tabIndex')
export default class SectionList extends Component {
  /**
   * @type {int} used to focus this object
   */
  tabIndex = 0;

  /**
   * @type {Section[]} sections
   */
  sections = null;

  /**
   * @type {Object} the current selection
   */
  selection = null;

  /** @type {boolean} Will fire onItemAction when you click a selection */
  itemActionOnClick = false;

  /** @type {string} */
  errorKey = 'isError';

  /** @type {string} */
  warningKey = 'isWarning';

  /** @type {boolean} */
  isDisabled = false;

  @computed
  get guid() {
    return guidFor(this);
  }

  /**
   * @type {Object[]} all of the data arrays from each section
   */
  @computed('sections')
  get allData() {
    return this.get('sections')
      .mapBy('data')
      .reduce((acc, d) => acc.concat(d), []);
  }

  /** Shows the add and remove buttons at the bottom of the section list */
  showAddRemoveControls = false;

  // ---- events handled by this component

  keyDown(e) {
    this._dispatchKey(e.key);

    if (e.key === 'Tab') {
      e.stopPropagation();
    }
    return false;
  }

  /**
   * Handler for key events
   *
   * @param {String} key
   * @private
   */
  _dispatchKey(key) {
    switch (key) {
      case 'ArrowDown':
        this._selectNext();
        break;
      case 'ArrowUp':
        this._selectPrev();
        break;
      case 'Enter':
        this.onItemAction(this.get('selection'));
        break;
      case 'Escape':
        this.onCancel();
        break;
    }
  }

  // ---- callback

  /**
   * Called when a new selection is made in the list
   *
   * @param {*} _item
   */
  onSelect(_item) {}

  /**
   * Called when the user hits enter / clicks on a item
   *
   * @param {*} _item
   */
  onItemAction(_item) {}

  /**
   * Called when the user has tried to select up, but has reached the top
   */
  onTopReached() {}

  /**
   * Called when the user has tried to select down, but has reached the bottom
   */
  onBottomReached() {}

  /** Called when add button is pressed */
  onAdd() {}

  /** Called when remove button is pressed */
  onRemove() {}

  /**
   * Selects the passed item and first the on select event
   *
   * @param item
   * @private
   */
  _select(item) {
    if (this.get('isDisabled')) {
      return;
    }

    this.set('selection', item);
    this.onSelect(item);

    if (item) {
      run.next(() => {
        //Possible that an outside user of the selection list might destroy it after a selection.
        if (this.get('isDestroyed')) {
          return;
        }

        const $selected = this.$('.selected');
        const $scrollbox = this.$('.fde-lists-section-list_scrollbox');
        const top = $selected.position().top;
        const height = this.$().height();
        const selectedHeight = $selected.innerHeight();
        const sectionHeight = this.$('.fde-lists-section-list_section').innerHeight();
        const atTopOfScroll = top - sectionHeight < 0;
        const atBottomOfSCroll = top + selectedHeight > height;

        // if we are out of the top of the div scroll to the top - sectionHeight
        if (atTopOfScroll) {
          $scrollbox.scrollTop($scrollbox.scrollTop() + top - 2 * sectionHeight);
        }
        // if we are at the bottom scroll just into view
        else if (atBottomOfSCroll) {
          $scrollbox.scrollTop($scrollbox.scrollTop() + (top + selectedHeight - height));
        }
      });
    }
  }

  /**
   * Selects the next item in the list
   *
   * @private
   */
  _selectNext() {
    this._updateSelection(1);
  }

  /**
   * Selects the previous item in the list
   *
   * @private
   */
  _selectPrev() {
    this._updateSelection(-1);
  }

  /**
   * Updates the selection up or down
   *
   * @param {int} delta
   * @private
   */
  _updateSelection(delta) {
    const allData = this.get('allData');
    const index = allData.indexOf(this.get('selection'));
    const nextSelection = allData.objectAt(index + delta);

    if (nextSelection) {
      this._select(nextSelection);
    } else if (delta < 0) {
      this.onTopReached();
    } else if (delta > 0) {
      this.onBottomReached();
    }
  }

  @action
  handleAdd() {
    const result = this.onAdd();

    if (result && result.then) {
      result.then((_) => this._select(_));
    } else {
      this._select(result);
    }
  }

  @action
  handleRemove(selection) {
    const result = this.onRemove(selection);

    if (result?.then) {
      result.then((_) => this._updateSelection(-1));
    } else {
      this._updateSelection(-1);
    }
  }

  @action
  handleItemAction(item) {
    const itemActionOnClick = this.get('itemActionOnClick');
    const isSelection = this.get('selection') === item;
    this._select(item);

    if (isSelection || itemActionOnClick) {
      this.onItemAction(item);
    }
  }
}
