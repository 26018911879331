import { helper as buildHelper } from '@ember/component/helper';
import moment from 'moment-timezone';
import { formattedTime } from './formatted-time';

//Takes a start time, end time, format and timezone, and returns a formatted time range.

export function formattedTimeRange(params) {
  let [start = moment(), end = moment(), format = 'lll', _timezone = null] = params;

  return `From '${formattedTime([start, format])}' to '${formattedTime([end, format])}'`;
}

export default buildHelper(formattedTimeRange);
