import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import EmberObject, { action } from '@ember/object';
import Component from '@ember/component';
import RSVP from 'rsvp';

@classic
class Quote extends EmberObject {
  perPersonBudget = 20;
  carts = null;
  menuItems = null;
  store = null;
  order = null;

  toSolverJSON() {
    return {
      quote: {
        // per person budget needs to be in pennies
        per_person_budget: this.get('perPersonBudget') * 100,
        requirement_groups: this.get('preferenceProfile.requirementGroups').map((_) =>
          _.toSolverJSON()
        )
      }
    };
  }
}

@classic
@tagName('')
export default class QuoteBuilder extends Component {
  restaurant = null;
  order = null;
  quote = null;

  /** Event that emits the requirement selected */
  onRequirementSelect /* requirement */() {}

  /** Event that emits the requirement group selected */
  onRequirementGroupSelect /* requirementGroup */() {}

  /**
   * If the quote builder is in single order mode then it will be configured to only show requirements
   * once we build out multi order mode in which we will select multiple restaurants and generate satisfactory
   * orders for all of them flag will be used up stream, however we will need to configure multiple orders on
   * the output side.
   *
   * /ramble
   *
   * @type {boolean}
   * */
  singleOrderMode = true;

  /** @type {Service} */
  @service
  store;

  /** @type {Service} */
  @service
  ajax;

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.get('order.client.preferenceProfile').then((preferenceProfile) =>
      this.initQuote(preferenceProfile)
    );
  }

  @action
  initQuote(preferenceProfile) {
    if (preferenceProfile) {
      preferenceProfile.set('menuItems', this.get('order.menu.menuItems'));

      this.set(
        'quote',
        Quote.create({
          store: this.get('store'),
          order: this.get('order'),
          preferenceProfile: preferenceProfile
        })
      );
    }
  }

  @action
  doQuote() {
    const valid = this.get('quote.preferenceProfile.requirementGroups').reduce(
      (acc, requirementGroup) =>
        acc && requirementGroup.get('requirements').every((_) => _.validate()),
      true
    );

    if (!valid) {
      return RSVP.Promise.resolve(false);
    }

    this.set('results', null);

    const order = this.get('quote.order');
    return order
      .quote()
      .then((_) =>
        order.reloadWith(
          'group-order-members.order-items.menu-item,group-order-members.order-items.menu-option-items'
        )
      )
      .catch(({ errors }) => this.set('errors', errors));
  }
}
