import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import moment from 'moment-timezone';

@classic
export default class AvailabilityRoute extends Route {
  queryParams = {
    monday: { refreshModel: true }
  };

  model(params) {
    const user = this.modelFor('logged-in');

    // either use the passed in monday or monday of this week
    const monday = params.monday ? moment(params.monday) : moment().startOf('week').add(1, 'days');

    return RSVP.hash({
      driver: user,
      driverWeek: user
        .query('driverWeeks', {
          filter: {
            monday: monday.format('YYYY-MM-DD')
          }
        })
        .then((driverWeeks) => {
          // either we find the first saved availability or we are here to create a new record
          const driverWeek = driverWeeks.find((dw) => {
            return moment(dw.get('monday')).diff(monday) === 0;
          });

          return (
            driverWeek ||
            this.store.createRecord('driver-week', {
              driver: user,
              monday: monday.toDate(),
              notes: ''
            })
          );
        })
    });
  }

  /**
   * Overload this setup to force the fetching values back to false
   * after we reload the route.
   *
   * @param {Controller} controller
   * @param {Object} model
   */
  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setProperties({
      fetchingPrev: false,
      fetchingNow: false,
      fetchingNext: false
    });
  }
}
