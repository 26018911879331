import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

@classic
export default class PasswordResetRoute extends Route {
  @service('user-session')
  session;

  model(params) {
    return this.store
      .query('user', { filter: { resetPasswordToken: params.token } })
      .then((users) => {
        const user = users.get('firstObject');

        if (user) {
          user.set('resetPasswordToken', params.token);
          return user;
        } else {
          return null;
        }
      });
  }
}
