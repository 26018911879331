import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

import { Time } from 'renard/transforms/time';

@classic
@classNames('fde-time-select has-border')
@classNameBindings('hasBorder:ui', ':segment')
export default class TimeSelectControl extends Component {
  /** @type {boolean} Whether or not to wrap the component in ui segment class */
  hasBorder = true;

  /** @type {Time|Date} initial date  (user in single selection mode) */
  value = null;

  /** @type {number} amount that the minute drop down increments by */
  timePickerIncrement = 15;

  /** @type {string} Placeholder for the dropdown when no option is selected */
  @computed('selectableTimes')
  get placeholder() {
    return this.get('selectableTimes.firstObject');
  }

  /** @type {string} Format used to display the times */
  format = 'h:mm a';

  /** @type {string} Timezone for the times */
  timeZone = '';

  /**
   * @callback changeHandler
   * @type {string} startDate as a string
   * @type {changeHandler}
   */
  onChange() {}

  /** @type {Time} Beginning of selectable range */
  beginningOfSelectableRange = Time.startOfDay;

  /** @type {Time} End of selectable range */
  endOfSelectableRange = Time.endOfDay;

  /** @type {date} Selected time */
  @computed('value')
  get selected() {
    const value = this.get('value');
    return value ? value.id : '';
  }

  /** @type {Time[]} Array of selectrable times. Times will be between
   * the given begin and end time. Throws an error if this range if time
   * is not divisible by timePickerIncrement
   */
  @computed('timePickerIncrement', 'beginningOfSelectableRange', 'endOfSelectableRange')
  get selectableValues() {
    return this.get('beginningOfSelectableRange').rangeBetween(
      this.get('endOfSelectableRange'),
      this.get('timePickerIncrement')
    );
  }

  didRender() {
    super.didRender(...arguments);
    this.$(`.item[data-value='${this.get('selected')}']`).addClass('active selected');
  }

  @action
  handleOnChange(newValue) {
    let newTime = newValue;

    newTime = new Time(newTime);

    const value = this.get('value');

    if (value instanceof Date) {
      newTime = newValue.applyToDate(value);
    }

    this.onChange(newTime);
  }
}
