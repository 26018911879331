import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@tagName('div')
export default class BackToMasterfox extends Component {
  restaurant = null;
  showImage = true;
  alignCenter = false;

  @computed('restaurant')
  get restaurantsEditPath() {
    return `../restaurants/${this.get('restaurant.id')}/edit`;
  }

  @computed('restaurant')
  get restaurantsShowPath() {
    return `../restaurants/${this.get('restaurant.id')}`;
  }

  @computed('restaurant')
  get restaurantStoryPath() {
    return `../restaurant_stories/${this.get('restaurant.restaurantStoryId')}`;
  }

  @computed('restaurant')
  get restaurantMenuPath() {
    return `../restaurants/${this.get('restaurant.id')}`;
  }

  @computed('restaurant')
  get restaurantServiceTimesPath() {
    return `../restaurants/${this.get('restaurant.slug')}/service_times`;
  }
}
