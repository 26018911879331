import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

import { action } from '@ember/object';

@classic
export default class IndexRoute extends Route {
  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    orderId: { refreshModel: true }
  };

  model(params) {
    const { pageLimit, pageOffset, orderId } = params;

    return this.store.query('giftbit-error', {
      include: 'order.client',
      page: {
        limit: pageLimit,
        offset: pageOffset
      },
      filter: {
        orderId: orderId
      }
    });
  }

  @action
  refreshModel() {
    return this.refresh();
  }
}
