import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Model, { attr, belongsTo } from 'renard/models/foodee';
import CompositeLocation from './decorators/composite-location';

@classic
export default class PickupLocation extends Model.extend(CompositeLocation) {
  /*
   * Attributes
   */
  @attr('string')
  name;

  @attr('string')
  notes;

  @attr('phone-number')
  phoneNumber;

  @attr('number')
  parkAndLoadTime;

  /*
   * Relationships
   */
  @belongsTo('restaurant', { inverse: 'pickupLocations' })
  restaurant;

  /*
   * Computed Properties
   */
  /** @type {string} */
  @computed('name', 'humanize')
  get label() {
    const name = this.get('name');
    const humanize = this.get('humanize');
    return name ? `${name}: ${humanize}` : humanize;
  }

  @alias('location.center')
  center;

  /*
   * Validations
   */
  validations = {
    name: {
      presence: {}
    },
    city: {
      presence: {}
    },
    building: {
      presence: {}
    },
    street: {
      presence: {}
    },
    addressCode: {
      presence: {}
    }
  };
}
