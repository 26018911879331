import classic from 'ember-classic-decorator';
import FeedItemBuilder from './base';

@classic
export default class ClientBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);

    if (version.get('objectChanges.subscriptionPlanId')) {
      const currentSubscriptionPlanPlanId = version.get(
        'objectChanges.subscriptionPlanId.lastObject'
      );
      feedItem.subject = `${version.get('whodunnit')} subscribed client to ${
        version.get('misc.subscriptionPlanName') || 'Unknown'
      } (${currentSubscriptionPlanPlanId})`;
      feedItem.icon = 'dollar sign';
    } else {
      feedItem.icon = 'building';
    }

    return feedItem;
  }
}
