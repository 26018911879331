import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { alias, and } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';

/**
 * A mixin to manage a property currentlyLoading on the controller for this route
 */
export default Mixin.create({
  /** @type {boolean} Whether or not this route bubbles the loading event up to its parent */
  bubblesLoadingEvent: false,

  /** @type {boolean} Whether or not this is an index type route, where no children have dependencies on the parent */
  isIndexTypeRoute: false,

  /** @type {boolean} Set to true once activated */
  hasActivated: false,

  /** @type {boolean} Set to true if this specific route's loading hook was hit */
  isRouteLoading: false,

  /** @type {Router} */
  router: service(),

  /** @type {boolean} True if the route has already activated and is loading */
  isRefreshing: and('isRouteLoading', 'hasActivated'),

  /** @type {Route} Alias to route that triggered the transition */
  transitioningRoute: alias('router.transitioningRoute'),

  /** @type {boolean} True if the transitioning route is refreshing */
  transitioningRouteRefreshing: alias('transitioningRoute.isRefreshing'),

  /** @type {boolean} True if the transitioning route is an index type route that is refreshing */
  isIndexTypeRouteRefreshing: and(
    'transitioningRouteRefreshing',
    'transitioningRoute.isIndexTypeRoute'
  ),

  /**
   * Returns the previously resolved model of this route
   * @returns {*}
   */
  getPrevModel() {
    return this.modelFor(this.get('routeName'));
  },

  /** @overide */
  activate() {
    this._super(...arguments);
    this.set('hasActivated', true);
  },

  /** @overide */
  deactivate() {
    this._super(...arguments);
    this.set('hasActivated', false);
  },

  loading: action(function (transition, route) {
    console.debug('Loading route transition: ', transition);

    this.setProperties({
      'router.transitioningRoute': route,
      isRouteLoading: true
    });

    this.controller && this.controller.set('currentlyLoading', true);

    transition.promise.finally(() => {
      this.controller && this.controller.set('currentlyLoading', false);

      this.setProperties({
        'router.transitioningRoute': null,
        isRouteLoading: false
      });
    });

    return this.get('bubblesLoadingEvent');
  })
});
