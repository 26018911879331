import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-filters-filter-bar-control-wrapper')
class ControlWrapper extends Component {
  /** @type {String} */

  for = null;

  /** @type {Filter} */
  filter = null;

  /** @type {?String} */
  using = null;

  /**
   * @type {Array.<*>} Useful for setting some default selectable values when dealing with selecting filter values
   * among a collection
   */
  values = null;

  /** @type {boolean} */
  useDefaultValue = false;
}

export default ControlWrapper;
