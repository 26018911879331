import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

@classic
export default class TopCuisinesController extends Controller {
  @alias('model.availableCuisines')
  availableCuisines;

  @alias('model.area')
  area;

  @service
  router;

  @action
  addCuisine(cuisine) {
    this.area.mealPlanningRequirements?.pushObject(cuisine);
    this.area.save();
    this.availableCuisines.removeObject(cuisine);
  }

  @action
  removeCuisine(cuisine) {
    if (confirm('Are you sure you want to remove this cuisine?')) {
      this.area.mealPlanningRequirements?.removeObject(cuisine);
      this.area.save();
      this.availableCuisines.pushObject(cuisine);
    }
  }
}
