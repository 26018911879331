import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import $ from 'jquery';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { Time } from 'renard/transforms/time';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment-timezone';

export default Controller.extend({
  /** @type {Restaurant} */
  restaurant: alias('model.restaurant'),

  /** @type {ServiceTime} */
  serviceTimes: alias('restaurant.serviceTimes'),

  /** @type {Time} */
  beginningOfWeek: moment().startOf('week'),

  /** @type {Service} */
  notify: service(),

  /** @type {Store} */
  store: service(),

  /** @type {Boolean} */
  isLoading: false,

  /** @type {Hash} */
  fullCalendarPlugins: [resourceTimeGridPlugin, interactionPlugin],

  /** @type {String} **/
  initialViewName: 'resourceTimeGridWeek',

  /** @type {String} **/
  slotMinTime: '06:00:00',

  /** @type {String} **/
  slotMaxTime: '23:00:00',

  /** @type {String} **/
  slotDuration: '00:15:00',

  /** @type {String} **/
  slotLabelInterval: '01:00',

  /** @type {Integer} **/
  /** 0 means sunday **/
  firstDay: 0,

  /** @type {String} **/
  weekDayFormatName: 'long',

  /** Other options **/
  deliveryServiceTimeColor: '#4DA2D7',
  pickupServiceTimeColor: '#008080',

  /**
   * Options for the calendar header
   *
   * @type {Object}
   */
  headerOptions: {
    left: '',
    right: ''
  },

  /** @type {String} */
  columnHeaderFormat: 'dddd',

  /** @type {String} */
  timeFormat: 'HH:mm',

  /**
   * Generates a set of events for the calendar component
   * that correspond with a restaurant's hours of operation and
   * pick-up times.
   *
   * @type {Object[]}
   */
  events: computed(
    'serviceTimes.@each.{pickupStartTime,pickupEndTime,deliveryStartTime,deliveryEndTime}',
    function () {
      return [
        ...this._formatDeliveryServiceTimes(),
        ...this._formatPickupServiceTimes(),
        ...this._formatRestaurantCapacityTranceTimes() // TODO - Show capacity usage, too? HOW? WHY? Useful?
      ];
    }
  ),

  /** @type {Array[Object]} Defines the pickup and deliver at columns per each day. */
  resources: [
    { id: 'pickup', title: 'Pickup' },
    { id: 'delivery', title: 'Delivery' }
  ],

  /**
   * Logs errors and posts errors as toasters
   *
   * @param message
   * @param error
   * @private
   */
  _notifyError(message, error) {
    const notifyService = this.get('notify');

    console.error(message);
    notifyService.error(message);

    error.errors.forEach((error) => {
      console.error(error.detail);
      notifyService.error(error.detail);
    });
  },

  /**
   * Notifies user if an error occurred and rolls back the service time's dirty attributes.
   *
   * @param error
   * @param serviceTime
   * @private
   */
  _serviceTimeError(error, serviceTime) {
    this._notifyError('Error setting service time', error);

    return serviceTime.rollbackAttributes();
  },

  /**
   * Formats delivery times for the full-calendar plugin. Defines the resource, the color, start and end times.
   *
   * @private
   */
  _formatDeliveryServiceTimes() {
    return this.get('serviceTimes').map((serviceTime) => {
      const serviceTimeId = `${serviceTime.get('id')}-deliver`;
      const resourceId = 'delivery';

      const [start, end] = this._formatStartAndEnd(
        serviceTime.get('deliveryStartTime'),
        serviceTime.get('deliveryEndTime'),
        serviceTime.get('weekday')
      );

      return this._formatServiceTime(
        serviceTimeId,
        this.get('deliveryServiceTimeColor'),
        start,
        end,
        resourceId
      );
    });
  },

  /**
   * Formats pickup times for the full-calendar plugin. Defines the resource, the color, start and end times.
   *
   * @private
   */
  _formatPickupServiceTimes() {
    return this.get('serviceTimes').map((serviceTime) => {
      const serviceTimeId = `${serviceTime.get('id')}-pickup`;
      const resourceId = 'pickup';

      const [start, end] = this._formatStartAndEnd(
        serviceTime.get('pickupStartTime'),
        serviceTime.get('pickupEndTime'),
        serviceTime.get('weekday')
      );

      return this._formatServiceTime(
        serviceTimeId,
        this.get('pickupServiceTimeColor'),
        start,
        end,
        resourceId
      );
    });
  },

  /**
   * If we ever want to have background events that show capacity tranches...
   *
   * @returns {Array}
   * @private
   */
  _formatRestaurantCapacityTranceTimes() {
    return [];
  },

  /**
   * @param date
   * @param time
   * @returns {*}
   * @private
   */
  _formatDateTime(date, time) {
    const hours = time.hours();
    const minutes = time.minutes();

    return date.hours(hours).minutes(minutes).format();
  },

  /**
   * @param serviceTimeId
   * @param colour
   * @param start
   * @param end
   * @param resourceId
   * @returns {{resourceId: *, borderColor: *, color: *, eventResizeableFromStart: boolean, editable: boolean, start: *, eventDurationEditable: boolean, end: *, serviceTimeId: *, eventStartEditable: boolean}}
   * @private
   */
  _formatServiceTime(serviceTimeId, colour, start, end, resourceId) {
    return {
      id: serviceTimeId,
      resourceId,
      color: colour,
      borderColor: colour,
      start,
      end,
      editable: true,
      eventStartEditable: true,
      eventResizeableFromStart: true,
      eventDurationEditable: true
    };
  },

  /**
   * @param startServiceTime
   * @param endServiceTime
   * @param serviceTimeWeekday
   * @returns {*[]}
   * @private
   */
  _formatStartAndEnd(startServiceTime, endServiceTime, serviceTimeWeekday) {
    const beginningOfWeek = this.get('beginningOfWeek');

    const startTime = startServiceTime.moment;
    const endTime = endServiceTime.moment;
    const weekday = moment(beginningOfWeek).add(serviceTimeWeekday, 'days');

    const start = this._formatDateTime(moment(weekday), startTime);
    const end = this._formatDateTime(moment(weekday), endTime);

    return [start, end];
  },

  /**
   * Setting the start and end of a service time in a specific Time format.
   *
   * @param startOf
   * @param endOf
   * @returns {{start: Time, end: Time}}
   * @private
   */
  _getServiceTimeRange(startOf, endOf) {
    const timeFormat = this.get('timeFormat');
    const startTime = moment(startOf);
    const endTime = moment(endOf);

    return {
      start: new Time(startTime.format(timeFormat)),
      end: new Time(endTime.format(timeFormat))
    };
  },

  /**
   * When creating a service time we need to offset the pickup and delivery times. In this case we're
   * offsetting the initial delivery time by 30 minutes, start and end, from the pickup time.
   *
   * @param startEvent
   * @param endEvent
   * @returns {{pickupStartTime: , pickupEndTime: , deliveryEndTime: , restaurant: *, weekday: *, deliveryStartTime: }}
   * @private
   */
  _initialServiceTimesAttributes(startEvent, endEvent) {
    // We add buffer time if the start and end times are only 15 minutes apart because:
    // ===================================
    // pickupStart - 1:00pm
    // deliveryStart - 1:15pm
    // pickupEnd - 1:15pm
    // deliveryEnd - 1:30pm
    // ===================================
    // The whole range needs to be minimum 30 minutes, 1:00-1:30pm.
    const addBufferTime = startEvent
      .startOf()
      .isSame(moment(endEvent.startOf()).subtract(15, 'minutes'));

    const pickupStart = startEvent.startOf();
    const deliverStart = moment(startEvent.startOf()).add(30, 'minutes');

    const pickupEnd = addBufferTime
      ? moment(endEvent.startOf()).add(30, 'minutes')
      : endEvent.startOf();
    const deliverEnd = moment(pickupEnd).add(30, 'minutes');

    const pickupTimes = this._getServiceTimeRange(pickupStart, pickupEnd);
    const deliverTimes = this._getServiceTimeRange(deliverStart, deliverEnd);
    const weekday = startEvent.startOf().weekday();

    return {
      pickupStartTime: pickupTimes.start,
      pickupEndTime: pickupTimes.end,
      deliveryStartTime: deliverTimes.start,
      deliveryEndTime: deliverTimes.end,
      weekday,
      restaurant: this.get('restaurant')
    };
  },

  /**
   * Creates a new service time.
   *
   * @param startEvent
   * @param endEvent
   * @returns {*}
   * @private
   */
  _createServiceTimes(startEvent, endEvent) {
    const serviceTimeAttributes = this._initialServiceTimesAttributes(startEvent, endEvent);

    this.set('isLoading', true);

    return this.get('store')
      .createRecord('service-time', serviceTimeAttributes)
      .save()
      .finally(() => {
        this.set('isLoading', false);
        $('.full-calendar').fullCalendar('unselect');
      });
  },

  /**
   * Edits a pickup time.
   *
   * @param id
   * @param event
   * @returns {*}
   * @private
   */
  _editPickupTimes(id, event) {
    const pickupTimes = this._getServiceTimeRange(event.start, event.end);

    // in case the server returns a 422 for validation reasons we
    // need to rollback the serviceTime's new attributes.
    let foundServiceTime;

    this.set('isLoading', true);

    return this.store
      .findRecord('serviceTime', id)
      .then((serviceTime) => {
        foundServiceTime = serviceTime;

        serviceTime.setProperties({
          pickupStartTime: pickupTimes.start,
          pickupEndTime: pickupTimes.end
        });

        return serviceTime.save();
      })
      .catch((error) => this._serviceTimeError(error, foundServiceTime))
      .finally(() => this.set('isLoading', false));
  },

  /**
   * Edits a delivery time.
   *
   * @param id
   * @param event
   * @returns {*}
   * @private
   */
  _editDeliveryTimes(id, event) {
    const deliveryTimes = this._getServiceTimeRange(event.start, event.end);

    // in case the server returns a 422 for validation reasons we
    // need to rollback the serviceTime's new attributes.
    let foundServiceTime;

    this.set('isLoading', true);

    return this.store
      .findRecord('serviceTime', id)
      .then((serviceTime) => {
        foundServiceTime = serviceTime;

        serviceTime.setProperties({
          deliveryStartTime: deliveryTimes.start,
          deliveryEndTime: deliveryTimes.end
        });

        return serviceTime.save();
      })
      .catch((error) => this._serviceTimeError(error, foundServiceTime))
      .finally(() => this.set('isLoading', false));
  },

  /**
   * Finds the ServiceTime id and confirms the deletion action.
   *
   * @param event
   * @private
   */
  _promptForDeletion(info) {
    const [id] = info.event.id.split('-');
    confirm('Are you sure you want to delete this service time?') && this._deleteServiceTime(id);
  },

  /**
   * Deletes a service time.
   *
   * @param id
   * @returns {*}
   * @private
   */
  _deleteServiceTime(id) {
    this.set('isLoading', true);

    return this.store
      .findRecord('serviceTime', id, { reload: true })
      .then((serviceTime) => serviceTime.destroyRecord())
      .finally(() => this.set('isLoading', false));
  },

  addPickupTime: action(function ({ start, end }) {
    this._createServiceTimes(moment(start), moment(end));
  }),

  editServiceTime: action(function ({ event }) {
    const [id, serviceTimeType] = event.id.split('-');
    const isPickupType = serviceTimeType == 'pickup';

    if (isPickupType) {
      this._editPickupTimes(id, event);
    } else {
      this._editDeliveryTimes(id, event);
    }
  }),

  promptForDeletion: action(function (info) {
    // Because the resto guys wanted to specifically
    // click on the "x" icon and I needed to wrestle
    // with the full-calendar plugin a bit and this
    // is where it ended up.
    if (info.jsEvent.target.className.includes('event-delete')) {
      this._promptForDeletion(info);
    }
  }),

  canSelect: action(function (selectInfo) {
    return selectInfo.resource.id == 'pickup';
  }),

  eventDidMount: action(function ({ el, event }) {
    const title = `${moment(event.start).format('h:mm')} - ${moment(event.end).format('h:mm')}`;

    $(el).addClass('event');
    $(el).append(`<span class='event-delete ${event.id}'>x</span>`);

    $(el).popup({
      title,
      content: 'Drag to edit the start and stop times.',
      delay: {
        show: 350,
        hide: 25
      }
    });
  }),

  eventMouseout: action(function (event, target) {
    $(target.currentTarget).removeClass('hover-over-event');
  }),

  eventMouseover: action(function (event, target) {
    $(target.currentTarget).addClass('hover-over-event');
  })
});
