import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';

@classic
@classNames('fde-filters-controls-date-selector')
export default class DateSelector extends Component {
  /** @type {Area[]} */
  areas = null;

  /** @type {Area} */
  forArea = null;

  /** @type {string} */
  value = null;

  /** @type {string} */
  format = null;

  /** @type {Filter} */
  filter = null;

  /** @type {boolean} */
  useDefaultValue = false;

  /** @type {String} */
  customFormat = null;

  /** @type {boolean} */
  hasTimePicker = false;

  /** @type {number} amount that the minute drop down increments by */
  timePickerIncrement = 15;

  /** @type {string} */
  @computed('hasTimePicker', 'format')
  get _format() {
    const valueFormat = this.get('valueFormat');
    const hasTimePicker = this.get('hasTimePicker');
    const defaultValueFormat = !hasTimePicker ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss.sssZ';

    return valueFormat || defaultValueFormat;
  }

  init() {
    super.init(...arguments);
    this.set('forArea', this.get('areas.firstObject'));

    if (this.get('useDefaultValue')) {
      this.setProperties({
        'filter.value': [this.dateToValueFormat(this.get('defaultDateValue'))],
        'filter.valueText': [this.dateToValueTextFormat(this.get('defaultDateValue'))]
      });
    }

    if (this.get('areas.length')) {
      const cityMatch = (this.get('filter.valueText.firstObject') || '').match(/.*\[(.*)\]/i) || [];
      const foundCity = cityMatch[1] || this.get('areas.firstObject.city');

      const areaForCity = this.get('areas').findBy('city', foundCity);
      areaForCity && this.set('forArea', areaForCity);
    }
  }

  /**
   * Returns value format from a date object
   * @param {Date} date
   * @returns {String}
   */
  dateToValueFormat(date) {
    const timeZone = this.get('timeZone') || moment.tz.guess();
    return moment.tz(date, timeZone).format(this.get('_format'));
  }

  /**
   * Returns value text format from a date object
   * @param {Date} date
   * @returns {String}
   */
  dateToValueTextFormat(date) {
    const timeZone = this.get('timeZone');
    const forArea = this.get('forArea');

    if (timeZone && forArea) {
      return `${moment
        .tz(date, timeZone)
        .format(this.get('customFormat') || this.get('dateControlFormat'))} [${this.get(
        'forArea.city'
      )}]`;
    } else {
      return `${moment
        .tz(date, moment.tz.guess())
        .format(this.get('customFormat') || this.get('dateControlFormat'))}`;
    }
  }

  /**
   * Returns date format from a string date representation
   * @param {String} value
   * @returns {Date}
   */
  valueFormatToDate(value) {
    if (this.get('hasTimePicker')) {
      return moment(value, this.get('_format')).toDate();
    } else {
      let timeZone = this.get('timeZone');
      const forArea = this.get('forArea');

      timeZone = (forArea && timeZone) || moment.tz.guess();
      return moment.tz(value, this.get('_format'), timeZone).toDate();
    }
  }

  /** @type {Date} */
  @computed('hasTimePicker')
  get defaultDateValue() {
    return this.get('hasTimePicker')
      ? moment().hour(0).minute(0).second(0).millisecond(0).toDate()
      : moment().minute(0).second(0).millisecond(0).toDate();
  }

  /** @type {date} */
  @computed('filter.value.[]')
  get toDateValue() {
    const value = this.get('filter.value.firstObject');
    return value ? this.valueFormatToDate(value) : this.get('defaultDateValue');
  }

  /** @type {string} */
  @computed('hasTimePicker')
  get dateControlFormat() {
    return this.get('hasTimePicker') ? 'ddd, MMMM Do YYYY hh:mm a' : 'dddd, MMMM Do YYYY';
  }

  /** @type {string} */
  @computed('forArea')
  get timeZone() {
    const area = this.get('forArea');
    return area ? area.get('isoTimeZone') : moment.tz.guess();
  }

  @action
  handleDateChange(date) {
    console.debug(date);
    this.get('filter').setValue([this.dateToValueFormat(date)], [this.dateToValueTextFormat(date)]);
  }

  @action
  handleAreaChange(area) {
    this.set('forArea', area);
    this.get('filter').setValue(
      [this.dateToValueFormat(this.get('toDateValue'))],
      [this.dateToValueTextFormat(this.get('toDateValue'))]
    );
  }
}
