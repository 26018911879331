import classic from 'ember-classic-decorator';
import { classNameBindings, classNames, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

/**
 * Simple component for committing fields
 */
@classic
@tagName('button')
@classNames('fde-toggle-button ui button')
@classNameBindings(
  'value:fde-is-selected',
  'color',
  'isIcon:icon',
  'buttonClasses',
  'isReadonly:readonly'
)
export default class ToggleButtonControl extends Component {
  buttonClasses = '';

  /** @property {boolean} attribute to be toggled **/
  value = false;

  /** @property {string} a string of classes to use to color the button when active **/
  activeColor = null;

  /** @property {string} a string of classes to use to color the button when inactive **/
  inactiveColor = null;

  /** @property {string} a string of classes to use to color the icon  button when active **/
  activeIconColor = null;

  /** @property {string} a string of classes to use to color the icon button when inactive **/
  inactiveIconColor = null;

  /** @property {string} a string of classes to use for an inactive icon **/
  inactiveIcon = null;

  /** @property {string} a string of classes to use for an active icon **/
  activeIcon = null;

  /** @property {string} Text to display during an active state **/
  activeText = null;

  /** @property {string} Text to display during an inactive state **/
  inactiveText = null;

  /** @property {function} handler for when the toggle is pressed **/
  onChange() {}

  /** @property {Boolean} whether the button is only an Icon **/
  @computed('activeIcon', 'inactiveIcon', 'activeText', 'inactiveText', 'hasBlock')
  get isIcon() {
    const { activeIcon, inactiveIcon, activeText, inactiveText, hasBlock } = this.getProperties(
      'activeIcon',
      'inactiveIcon',
      'activeText',
      'inactiveText',
      'hasBlock'
    );

    return (activeIcon || inactiveIcon) && !(activeText || inactiveText || hasBlock);
  }

  @computed('value', 'activeColor', 'inactiveColor')
  get color() {
    return this.get('value') ? this.get('activeColor') : this.get('inactiveColor');
  }

  @computed('value', 'activeIconColor', 'inactiveIconColor')
  get iconColor() {
    return this.get('value') ? this.get('activeIconColor') : this.get('inactiveIconColor');
  }

  click() {
    this.onChange(!this.get('value'));
    return false;
  }
}
