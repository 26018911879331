import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class ChecklistRoute extends Route {
  /**
   * @param {Object} params
   * @returns {{restaurant: Restaurant}}
   */
  model(_params) {
    return this.modelFor('logged-in.restaurants.show');
  }
}
