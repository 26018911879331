import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { Phones } from 'star-fox/transforms/phone';

@classic
export default class ProfileController extends Controller {
  /** @type {Phone[]} */
  phones = Phones;

  /** @type {boolean} whether or not to show the profile update message */
  showSuccess = false;

  /** @type {boolean} whether or not to show the change password section */
  showPasswordFields = false;

  @action
  handleDidSave() {
    this.setProperties({
      showPasswordFields: false,
      showSuccess: true
    });
  }

  @action
  handleDidCancel() {
    this.transitionToRoute('logged-in');
  }
}
