import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias, oneWay } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action } from '@ember/object';

@classic
export default class IndexController extends Controller {
  queryParams = ['pageLimit', 'pageOffset', 'search'];

  /** @type {Service} */
  @service
  notify;

  /** @type {Courier} */
  newCourier = null;

  /** @type {number} records per page */
  pageLimit = 20;

  /** @type {number} current page */
  pageOffset = 0;

  /** @type {?string} */
  search = '';

  /**
   * Computed parameters
   */

  /** @type {Area[]} */
  @alias('model.areas')
  areas;

  @alias('model.couriers')
  couriers;

  /** @type {number} */
  @oneWay('model.couriers.meta.recordCount')
  recordCount;

  @action
  addNewCourier() {
    const newCourier = this.store.createRecord('courier');
    this.set('newCourier', newCourier);
  }

  @action
  courierSaved() {
    this.get('notify').success('Created Courier');
    this.send('refreshModel');
  }

  @action
  removeNewCourier() {
    this.get('newCourier').destroyRecord();
    this.set('newCourier', null);
  }
}
