import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';

import accounting from 'accounting';
import moment from 'moment-timezone';

@classic
export default class PromoCode extends Model {
  /*
   * Attributes
   */
  @attr('string')
  description;

  @attr('string')
  code;

  @attr('date')
  startsAt;

  @attr('date')
  expiresAt;

  @attr('boolean')
  allowMultipleClientUses;

  @attr('boolean')
  internal;

  /** If accounting wants to track a promo code,
   * they can assign a client accounting code.
   * and it will be printed in the xero exports for client.
   */
  @attr('number')
  clientAccountingCode;

  /** If accounting wants to track a promo code,
   * they can assign a restaurant accounting code.
   * and it will be printed in the xero exports for restaurant.
   */
  @attr('number')
  restaurantAccountingCode;

  /** If amountType is value, it uses dollars; if it's a percentage, percent. */
  @attr('number', { defaultValue: 0 })
  amount;

  /** 'value' for dollars, and 'percentage' for percentages */
  @attr('string', { defaultValue: 'value' })
  amountType;

  /*
   * Relationships
   */
  @belongsTo('area', { async: true })
  area;

  @hasMany('order')
  orders;

  @belongsTo('restaurant', { async: true })
  restaurant;

  /*
   * Computed Properties
   */
  /** @type {string} */
  @computed('code', 'formattedAmount')
  get humanize() {
    return `Promo Code '${this.get('code')}' for ${this.get('formattedAmount')}`;
  }

  /** @type {string} */
  @computed('expiresAt', 'startsAt')
  get duration() {
    return moment
      .duration(moment(this.get('expiresAt')).diff(moment(this.get('startsAt'))))
      .humanize();
  }

  /** @type {boolean} */
  @computed('expiresAt')
  get isExpired() {
    return moment(this.get('expiresAt')).isBefore(moment());
  }

  /** @type {string} formats promo amounts for messaging, cart, and invoice */
  @computed('amount', 'amountType', 'percentAmount')
  get formattedAmount() {
    if (this.get('amountType') === 'percentage') {
      return `${this.get('amount')}%`;
    }

    if (this.get('amountType') === 'value') {
      return accounting.formatMoney(this.get('amount'));
    }

    return '';
  }

  /*
   * Validations
   */
  validations = {
    code: {
      presence: {}
    },
    description: {
      presence: {}
    },
    clientAccountingCode: {
      presence: true
    },
    amount: {
      presence: {},
      numericality: {
        greaterThan: 0
      }
    }
  };
}
