import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

/**
 * Placeholder for consistent table API, but you could imagine behavior maybe getting added here
 */
@classic
@tagName('tbody')
export default class TableBody extends Component {
  click() {}
}
