import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';
import { isPresent } from '@ember/utils';

@classic
export default class Courier extends Model {
  /*
   * Attributes
   */
  @attr('string')
  legalName;

  @attr('string')
  name;

  @attr('number')
  driverCount;

  @attr('string')
  ownerEmail;

  @attr('string')
  apiType;

  @attr('object')
  thirdPartyLogisticsConfiguration;

  @attr('boolean')
  active;

  /*
   * Relationships
   */
  @hasMany('area')
  areas;

  @hasMany('user', { inverse: 'couriers' })
  drivers;

  @belongsTo('user')
  owner;

  /*
   * Computed Properties & static properties
   */
  @sort('drivers', 'sortDriversBy')
  sortedDrivers;

  sortDriversBy = ['firstName:asc'];

  @service
  ajax;

  /*
   * Functions & Non Crud
   */
  /**
   * @param {Driver} driver
   * @returns {Promise}
   */
  removeDriver(driver) {
    return this.get('ajax')
      .del(`/api/v3/couriers/${this.get('id')}/drivers/${driver.get('id')}`)
      .then((_) => this.get('drivers').removeObject(driver));
  }

  /**
   * @param {string} email
   * @returns {Promise}
   */
  addDriver(email) {
    return this.store
      .createRecord('user-invite', {
        firstName: 'pending',
        lastName: 'invite',
        phoneNumber: '9999999999',
        email: email,
        courier: this
      })
      .save()
      .then((invite) => invite.get('user'));
  }

  /*
   * Validations
   */
  validations = {
    name: { presence: true },
    legalName: { presence: true },
    areas: { presence: true },

    ownerEmail: {
      custom: [
        {
          validation(key, value, courier) {
            const isNew = courier.get('isNew');
            return !isNew || (isNew && isPresent(courier.get(key)));
          },

          message: 'You need to provide an email when creating a new courier'
        }
      ]
    }
  };
}
