import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

/**
 * Sortable Table configured for teams and users
 */
@classic
@classNames('fde-tables-client-teams')
export default class ClientTeams extends Component {
  /** @type {number} */
  recordCount = null;

  /** @type {number} */
  pageOffset = null;

  /** @type {number} */
  pageLimit = null;

  /** @type {Service} */
  @service
  notify;

  /** @type {boolean} */
  readonly = false;

  /** @type {Client} */
  client = null;

  /** @type {Team} */
  team = null;

  /** @type {User.[]} */
  users = null;

  /** @type {User.[]} */
  searchedUsers = null;

  /** @type {boolean} */
  isCreatingTeamUser = false;

  /** @type {boolean} */
  isPromotingUser = false;

  /** @type {boolean} */
  isDemotingUser = false;

  /** @type {boolean} */
  showTeamUserForm = false;

  /** @type {boolean} */
  highlightTeamUserForm = false;

  createNewTeamUser() {}
  queryForTeamUsers() {}
  handleAddNewTeamUser() {}
  unlinkTeamUser() {}
  promoteTeamUser() {}
  demoteTeamUser() {}
  setNewOwner() {}
}
