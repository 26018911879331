import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { empty } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import $ from 'jquery';

@classic
@tagName('')
export default class DriverFormFor extends Component {
  /** @type {Service} */
  @service
  ajax;

  /** @type {Service} */
  @service
  notify;

  /** @type {User} */
  driver = null;

  /** @type {?User[]} */
  searchedDrivers = null;

  /** @type {boolean} */
  @empty('driver')
  isNew;

  /** @type {boolean} */
  isCreatingDriver = false;

  /** @type {Object[]} */
  phones = null;

  /** @type {Object[]} */
  vehicles = null;

  /** @type {string} */
  @computed('driver.id')
  get confirmModalId() {
    return `unlink-driver-${this.get('driver.id')}`;
  }

  unlinkDriver() {}
  addNewDriver() {}
  queryForDrivers() {}
  closeNewDriverForm() {}

  _closeConfirmModal() {
    $(`.${this.get('confirmModalId')}`).modal('hide');
  }

  @action
  closeConfirmModal() {
    this._closeConfirmModal();
  }

  @action
  handleAddNewDriver(driver) {
    this.set('isCreatingDriver', true);
    this.addNewDriver(driver);
  }

  @action
  handleUnlinkDriver() {
    this.unlinkDriver(this.get('driver'));
    this._closeConfirmModal();
  }

  @action
  confirmUnlinkDriver() {
    const modalPath = `.${this.get('confirmModalId')}`;
    $(modalPath)
      .modal({
        context: '.ember-application',
        closable: false
      })
      .modal('show');
  }

  @action
  sendFleetFoxBuild() {
    this.set('isLoading', true);
    const driverId = this.get('driver.id');
    const notify = this.get('notify');
    const uri = 'api/bitrise/send-latest-build';
    const data = { user_id: driverId };
    this.get('ajax')
      .postJSONAPI(uri, data)
      .then((res) => notify.success(`Sent to ${res.email}`))
      .catch((errorResponse) => {
        if (errorResponse.errors) {
          errorResponse.errors.forEach((err) => notify.error(err.detail));
        } else {
          notify.error('An unexpected error occurred');
        }
      })
      .finally(() => this.set('isLoading', false));
  }
}
