import classic from 'ember-classic-decorator';
import FeedItemBuilder from './base';

@classic
export default class OrderingFeedbackBuilder extends FeedItemBuilder {
  build(feedback) {
    const feedItem = super.build(feedback);
    feedItem.record = feedback;
    feedItem.icon = feedback.score.isGood ? 'thumbs up outline' : 'thumbs down outline';
    feedItem.subject = feedback.get('groupOrderMember.name');
    feedItem.tagline = feedback.score.isGood ? 'Liked their meal' : 'Did not like their meal';
    feedItem.secondTagline = feedback.score.isGood
      ? null
      : `Stated reason: ${feedback.reasons.join(', ')}`;
    feedItem.color = feedback.score.isGood ? 'green' : 'red';
    feedItem.meta = ['Member supplied feedback:', feedback.description];
    feedItem.detailsTitle = 'Order details';
    return feedItem;
  }
}
