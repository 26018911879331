import classic from 'ember-classic-decorator';
import {
  classNameBindings,
  classNames,
  tagName,
  attributeBindings
} from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('')
@tagName('a')
@classNameBindings('getClassNames')
@attributeBindings('data-test-sf-tab-group-tab')
export default class Tab extends Component {
  get 'data-test-sf-tab-group-tab'() {
    return this.tabName || '';
  }

  /** @type {string} If the tab doesn't have any block, this label will display */
  label = null;

  /** @type {string|number} Index to represent the identity of tab */
  tabName = null;

  /** @type {string|number} Active tab index of the group */
  activeTab = null;

  /** @type {string} Class name for active tab. */
  activeTabClass = '';

  icon = null;

  @computed('isActive', 'activeTabClass')
  get getClassNames() {
    return this.get('isActive') ? this.get('activeTabClass') : '';
  }

  /** @type {boolean} is true when tab is active */
  @computed('activeTab', 'tabName')
  get isActive() {
    return this.get('activeTab') === this.get('tabName');
  }

  /** Dom click handler */
  click() {
    this.onClick(this.get('tabName'));
  }
}
