import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';

@classic
export default class IndexRoute extends Route.extend(AuthenticatedRouteMixin, LoadingRouteMixin) {
  @service
  orderService;

  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    sort: { refreshModel: true },
    areas: { refreshModel: true },
    search: { refreshModel: true }
  };

  model(params) {
    params.isBookmark = true;

    return RSVP.hash({
      areas: this.store.query('area', {
        page: {
          limit: 100,
          offset: 0
        }
      }),
      orders: this.get('orderService').fetchOrders(params)
    });
  }
}
