import Helper from '@ember/component/helper';

/**
 * Checks to see if all of the passed in arguments are true
 * @param {boolean[]} values
 * @returns {boolean}
 */
export function and(values) {
  return values.reduce((acc, arg) => acc && arg, true);
}

export default Helper.helper(and);
