import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class Client extends Application {
  attrs = {
    globalId: {
      serialize: false
    },

    areas: {
      serialize: true
    },

    teams: {
      serialize: true
    },

    defaultArea: {
      serialize: true
    },

    company: {
      serialize: true
    },

    accountName: {
      serialize: false
    },

    salesSupportClient: {
      serialize: false
    },

    billingLocation: {
      serialize: false
    },

    defaultDeliveryLocation: {
      serialize: false
    },

    deliveryLocations: {
      serialize: true
    },

    features: {
      serialize: false
    },

    paymentCards: {
      serialize: true
    },

    apiToken: {
      serialize: false
    },
    state: {
      serialize: false
    },
    recentSignup: {
      serialize: false
    },
    salesforceId: {
      serialize: false
    }
  };
}
