import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from 'renard/models/foodee';
import { getOwner } from '@ember/application';
import HasManyQuery from 'ember-data-has-many-query';

const { ModelMixin } = HasManyQuery;

/**
 * @typedef {Version}
 * @type {Model}
 */
@classic
export default class HistorianVersion extends Model.extend(ModelMixin) {
  @attr('date')
  createdAt;

  @attr('string')
  itemType;

  @attr('number')
  itemId;

  @attr('string')
  event;

  @attr('string')
  verb;

  @attr('object')
  object;

  @attr('object')
  objectChanges;

  @attr('object')
  misc;

  @attr('string')
  whodunnit;

  @attr('string')
  email;

  @belongsTo('order')
  order;

  @attr('string')
  requestId;

  /**
   * @type {boolean}
   */
  @computed('whodunnit')
  get createdBySystem() {
    return this.get('whodunnit') === 'Foxee';
  }

  /**
   * Retrieves a value either from the object, or the object changes
   *
   * @param {string} key
   * @returns {*}
   */
  getValue(key) {
    return this.get(`object.${key}`) || this.get(`objectChanges.${key}.lastObject`);
  }

  /** @type {Array<{changeKey:, oldValue:, newValue:}>} */
  @computed
  get changes() {
    return this.get('changeKeys').map((changeKey) => {
      const [oldValue, newValue] = this.get(`objectChanges.${changeKey}`);
      return {
        changeKey,
        oldValue: JSON.stringify(oldValue, null, 2),
        newValue: JSON.stringify(newValue, null, 2)
      };
    });
  }

  /** @type {Array<string>} */
  @computed
  get changeKeys() {
    return Object.keys(this.get('objectChanges'));
  }

  /**
   * Returns a feed item build from the feed item factory registered
   * for the item type of this version
   *
   * @type {FeedItem}
   */
  @computed
  get feedItem() {
    const owner = getOwner(this);
    return owner.lookup('builder:feed-item/base').fromVersion(this);
  }
}
