import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class CommunicationsRoute extends Route {
  // refreshModel is being used to re-fetch data from the backend
  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    searchText: { refreshModel: true },
    sort: { refreshModel: true }
  };

  async model({ pageLimit, pageOffset, searchText, sort }) {
    const restaurant = this.modelFor('logged-in.restaurants.show').restaurant;

    return restaurant.query('notificationLogs', {
      sort,
      page: {
        limit: pageLimit,
        offset: pageOffset
      },
      filter: {
        receiver: `${restaurant.id},Restaurant`,
        search: searchText
      },
      include: 'order'
    });
  }
}
