import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias, oneWay } from '@ember/object/computed';
import Controller from '@ember/controller';
import { run } from '@ember/runloop';
import EmberObject, { action, computed } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { UserInviteDefaults } from 'star-fox/models/user';

@classic
export default class UsersController extends Controller {
  @service
  notify;

  @service('user-session')
  userSession;

  /** @type {boolean} */
  isLoading = false;

  /** @type {string} searchText - text for searching the MasterFox api with */
  searchText = '';

  /** @type {string} building searchText, updated with each key press.
   * searchText is set from this after being debounced in
   * onSearchChange
   */
  _searchText = '';

  /** @type {Team} */
  @alias('model.selectedTeam')
  team;

  /** @type {number} */
  @oneWay('model.usersMeta.recordCount')
  recordCount;

  /** @type {number} pageLimit - records per page */
  pageLimit = 20;

  /** @type {number} pageOffset - current page */
  pageOffset = 0;

  queryParams = ['pageLimit', 'pageOffset', 'searchText', 'teamName'];

  /** @type {Client} */
  @alias('model.client')
  client;

  /** @type {String} */
  teamName = '';

  /** @type {Team[]} */
  @alias('model.allTeams')
  allTeams;

  /** @type {boolean} */
  showNewTeamForm = false;

  /** @type {boolean} */
  showNewAdminForm = false;

  /** @type {boolean} */
  queriedUsers = null;

  /** @type {Client} */
  isCreatingTeamUser = false;

  /** @type {boolean} */
  showTeamUserForm = false;

  /** @type {boolean} */
  showInviteTeamUsersForm = false;

  /** @type {boolean} */
  highlightTeamUserForm = false;

  /** @type {User[]} */
  searchedUsers = null;

  /** @type {Team} */
  @alias('model.selectedTeam')
  selectedTeam;

  /** @type {boolean} */
  isPromotingUser = false;

  /** @type {boolean} */
  isDemotingUser = false;

  /** @type {boolean} */
  isSubmittingTeamUser = false;

  /** @type {boolean} */
  isSubmittingUserInvites = false;

  /** @type {User} Used when new-team-user-form-for needs to be reset from the controller */
  newUser = null;

  /** @type {Object} */
  newUsers = null;

  /** @type {?User} */
  existingUser = null;

  /** @type {?Array} */
  failedUserInvites = null;

  /** @type {?Object} */
  csvDownload = {
    startDate: new Date(),
    endDate: new Date(),
    detailed: true
  };

  @computed('model.users')
  get users() {
    return this.get('model.users').sortBy('lastName');
  }

  /**
   * Downloads fetches a the report from the backend
   * @return {Promise<boolean>}
   */
  @action
  async downloadCSV() {
    try {
      await this.client.fetchUsageReportCSV(
        this.csvDownload.startDate,
        this.csvDownload.endDate,
        this.csvDownload.detailed
      );
      this.notify.success(
        `Summary will be sent to "${this.userSession.user.email}", it might take a minute or two`
      );
    } catch (e) {
      this.notify.error(`Something went wrong generating the summary: ${e}`);
    }
  }

  doSearch() {
    this.setProperties({
      searchText: this.get('_searchText'),
      pageOffset: 0
    });
  }

  /**
   * Closes the new team user form, refreshes the model, and notifies the User
   * @private
   */
  _handleCreatedTeamUser() {
    this.setProperties({
      isCreatingTeamUser: false,
      showTeamUserForm: false
    });

    this.send('refreshModel');
    this.get('notify').success('Successfully added team member');
  }

  /**
   * Closes the new user form
   * @private
   */
  _handleCreatedUser() {
    this.setProperties({
      showNewUserForm: false,
      isSubmittingTeamUser: false
    });

    this.send('refreshModel');
    this.get('notify').success('Successfully added user');
  }

  _resetNewTeamUserForm() {
    //reset new-team-user-form-for with a new user invite object
    //turns off the loader on new-team-user-form-for submit button
    this.set('isSubmittingTeamUser', false);
    const newUser = this.get('store').createRecord('user-invite', {
      firstName: UserInviteDefaults.FIRST_NAME,
      lastName: UserInviteDefaults.LAST_NAME,
      phoneNumber: UserInviteDefaults.PHONE_NUMBER,
      team: this.get('model.selectedTeam')
    });

    this.setProperties({
      newUser: newUser,
      isSubmittingTeamUser: false
    });
  }

  _handleExistingUserInvite() {
    const notify = this.get('notify');
    notify.error('User already exists. Please add user by searching in the form below');

    this.setProperties({
      isSubmittingTeamUser: false,
      showNewUserForm: false,
      showTeamUserForm: true,
      highlightTeamUserForm: true
    });

    this._resetNewTeamUserForm();
  }

  _handleNewUserInvite(newUser) {
    const notify = this.get('notify');

    return this.store
      .createRecord('user-invite', {
        firstName: newUser.get('firstName') || UserInviteDefaults.FIRST_NAME,
        lastName: newUser.get('lastName') || UserInviteDefaults.LAST_NAME,
        phoneNumber: newUser.get('phoneNumber') || UserInviteDefaults.PHONE_NUMBER,
        email: newUser.get('email'),
        team: this.get('model.selectedTeam')
      })
      .save()
      .then((_) => this._handleCreatedUser())
      .catch((_) => {
        this.set('isSubmittingTeamUser', false);
        notify.error('There was a problem adding user');
      });
  }

  /**
   * Queries users by an email address, and sets the searchedUsers attribute
   * @param {string} email
   */
  @(task(function* (email) {
    yield timeout(250);

    if (email) {
      yield this.store
        .query('user', {
          filter: { email },
          page: {
            limit: 10,
            offset: 0
          }
        })
        .then((users) => this.set('searchedUsers', users && users.toArray()));
    }
  }).restartable())
  _queryTeamUsersByEmail;

  @action
  resetInviteNewUsersForm() {
    this.setProperties({
      isSubmittingUserInvites: false,
      newUsers: EmberObject.create({
        emails: [],
        team: this.get('model.selectedTeam'),
        errors: []
      })
    });
  }

  @action
  addNewTeam(newTeam) {
    const notify = this.get('notify');

    return this.store
      .createRecord('team', {
        name: newTeam.get('name'),
        client: this.get('client')
      })
      .save()
      .then((_) => notify.success(`Successfully created ${newTeam.get('name')}`))
      .then((_) => this.send('clearCachedAllTeams'))
      .then((_) => this.set('teamName', newTeam.get('name')))
      .catch((_) => notify.error('There was a problem creating team'));
  }

  @action
  inviteNewUsers(newUsers) {
    this.set('isSubmittingUserInvites', true);
    const notify = this.get('notify');

    const team = newUsers.team;

    const emails = newUsers.emails;

    if (emails) {
      return team
        .bulkAddTeamUsers(emails, newUsers.inviteAsAdmin)
        .then((result) => {
          const skippedInvites = result.skipped_invites || [];
          const invalidEmails = result.invalid_emails || [];

          const failedUserInvites = skippedInvites.concat(invalidEmails);

          this.setProperties({
            successfulInvites: result.successful_invites,
            migratedUsers: result.migrated_users,
            showMigratedWarning: !!result.migrated_users.length,
            failedUserInvites: failedUserInvites
          });

          // This should not happen.
          if (failedUserInvites.length) {
            notify.info('Some invites were unsuccessful.');
          }

          notify.success('Successfully invited users');
          this.send('resetInviteNewUsersForm');
          this.send('refreshModel');
        })
        .catch((e) => notify.error(e.message))
        .finally((_) => this.set('isSubmittingUserInvites', false));
    } else {
      notify.error('There was a problem inviting users');
    }
  }

  @action
  addNewUser(newUser) {
    this.set('isSubmittingTeamUser', true);
    const notify = this.get('notify');
    const userIsAlreadyAdded = this.get('team.members').findBy('email', newUser.get('email'));

    const userQueryOptions = {
      filter: {
        search: newUser.get('email')
      }
    };

    this.get('store')
      .query('user', userQueryOptions)
      .then((_) => {
        const existingUser = _.get('firstObject');

        if (existingUser && !userIsAlreadyAdded) {
          this._handleExistingUserInvite();
        } else if (userIsAlreadyAdded) {
          this._resetNewTeamUserForm();
          notify.error('User already belongs to this team');
        } else {
          this._handleNewUserInvite(newUser);
        }
      });
  }

  @action
  promoteTeamUser(teamUser) {
    this.set('isPromotingUser', teamUser.get('id'));

    const clientId = this.get('client.id');
    const notify = this.get('notify');

    teamUser
      .promoteToAdmin(teamUser, clientId)
      .then((_) => this.send('refreshModel'))
      .then((_) => notify.success(`Promoted ${teamUser.get('email')} to admin`))
      .catch((_) => notify.error(`There was an issue promoting ${teamUser.get('email')}`))
      .finally((_) => this.set('isPromotingUser', null));
  }

  @action
  demoteTeamUser(teamUser) {
    this.set('isDemotingUser', teamUser.get('id'));

    const clientId = this.get('client.id');
    const notify = this.get('notify');

    teamUser
      .demoteAdminToTeamMember(teamUser, clientId)
      .then((_) => this.send('refreshModel'))
      .then((_) => notify.success(`Demoted ${teamUser.get('email')} to team member`))
      .catch((_) => notify.error(`There was an issue demoting ${teamUser.get('email')}`))
      .finally((_) => this.set('isDemotingUser', null));
  }

  @action
  setNewOwner(teamUser) {
    this.set('isPromotingUser', teamUser.get('id'));

    const notify = this.get('notify');

    return this.get('client')
      .promoteAdminToOwner({ user_id: teamUser.get('id') })
      .then(() => notify.success(`Successfully set ${teamUser.get('fullName')} as the new owner`))
      .catch((error) => error.errors.forEach((err) => notify.error(err.detail)))
      .finally(() => this.set('isPromotingUser', null));
  }

  @action
  onFilterChange(newTeam) {
    const teamName = newTeam.get('name');

    this.setProperties({
      teamName,
      pageOffset: 0
    });
  }

  @action
  onSearchChange(searchText) {
    this.set('_searchText', searchText);

    // prevent multiple search
    run.debounce(this, this.doSearch, 400);
  }

  @action
  createNewTeamUser(teamUser) {
    const notify = this.get('notify');
    const team = this.get('model.selectedTeam');

    team
      .addTeamUser(teamUser)
      .then((_) => this._handleCreatedTeamUser())
      .catch((_) => notify.error(`User ${teamUser.get('email')} belongs to another team`));
  }

  @action
  handleAddNewTeamUser(teamUser) {
    this.set('isCreatingTeamUser', true);
    this.send('createNewTeamUser', teamUser);
  }

  @action
  queryForTeamUsers(email) {
    this.set('highlightTeamUserForm', false);
    this.get('_queryTeamUsersByEmail').perform(email);
  }

  @action
  closeNewTeamUserForm() {
    this.toggleProperty('showTeamUserForm');
  }

  @action
  unlinkTeamUser(teamUser) {
    const notify = this.get('notify');
    const team = this.get('model.selectedTeam');
    const actionReference = `${teamUser.get('email')} from ${team.get('name')}`;

    team
      .removeTeamUser(teamUser)
      .then((_) => notify.success(`Removed ${actionReference}`))
      .then((_) => this.send('refreshModel'))
      .catch((_) => notify.error(`There was an issue in removing ${actionReference}`));
  }
}
