import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('fde-meal-plan-custom-fields')
export default class CustomFields extends Component {
  @service
  notify;

  @service
  store;

  customFields = null;
  mealPlan = null;
  isLoading = false;

  _handleCustomFieldCleanup() {
    this.set('isLoading', false);
  }

  /**
   * Called after an unsuccessful response from addCustomField or deleteCustomField.
   * Notifies the user of the encountered error.
   *
   * @private
   */
  _handleCustomFieldError(error) {
    console.debug('Custom field error:', error.error || error);
    this.get('notify').error('Custom field action was not completed.');
  }

  @action
  addCustomField() {
    this.set('isLoading', true);

    return this.get('mealPlan')
      .addCustomField()
      .catch((error) => this._handleCustomFieldError(error))
      .finally((_) => this._handleCustomFieldCleanup());
  }

  @action
  deleteCustomField(customField) {
    if (
      confirm(
        `Are you sure you want to delete ${customField.get(
          'fieldName'
        )}? You will still have to save the meal plan.`
      )
    ) {
      this.set('isLoading', true);

      return this.get('mealPlan')
        .deleteCustomField(customField)
        .catch((error) => this._handleCustomFieldError(error))
        .finally((_) => this._handleCustomFieldCleanup());
    }
  }
}
