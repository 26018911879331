import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { run } from '@ember/runloop';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import { action } from '@ember/object';

import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';

export const ORDER_EDIT_INCLUDE = [
  'owner',
  'area',
  'area.invoicing-tax-rates',
  'arrival-estimate',
  'client',
  'client-discounts',
  'client-location',
  'client.account-manager',
  'client.admins',
  'client.meal-plan',
  'client.payment-cards',
  'contact',
  'courier',
  'creator',
  'driver',
  'owner.communication-preference',
  'owner.payment-cards',
  'payment-card',
  'promo-code',
  'restaurant',
  'restaurant-discounts',
  'restaurant-location',
  'restaurant.areas',
  'restaurant.owner',
  'restaurant.pickup-locations.location',
  'restaurant.service-times',
  'restaurant.closures',
  'sales-support',
  'event.orders',
  'event.order-template',
  'feedbacks.group-order-member'
].join(',');

@classic
export default class EditRoute extends Route.extend(AuthenticatedRouteMixin, LoadingRouteMixin) {
  queryParams = {
    editMealEvent: { refreshModel: true }
  };

  orderEditRoute = 'logged-in.orders.edit';

  /** @type {OrderService} */
  @service
  orderService;

  /** Opens the order edit panel */
  openRightPane() {
    const ordersController = this.controllerFor('logged-in.orders');
    if (ordersController.get('rightPaneSize') < 200) {
      ordersController.set('rightPaneSize', 1000);
    }
  }

  /** Minimizes the order edit panel */
  minimizeRightPane() {
    const ordersController = this.controllerFor('logged-in.orders');
    ordersController.set('rightPaneSize', 0);
  }

  /** @override */
  activate() {
    super.activate(...arguments);
    this.openRightPane();
  }

  /** @override */
  deactivate() {
    super.deactivate(...arguments);
    this.minimizeRightPane();
  }

  /** @override */
  beforeModel() {
    if (!this.get('hasActivated')) {
      const ordersController = this.controllerFor('logged-in.orders');
      ordersController.set('editRouteLoadingFirstTime', true);
    }
  }

  /** @override */
  model(params) {
    console.debug('Grabbing order:', params.order_id);

    if (this.get('isIndexTypeRouteRefreshing')) {
      return this.getPrevModel();
    }

    return RSVP.hash({
      order: this.store.findRecord('order', params.order_id, {
        reload: true,
        include: ORDER_EDIT_INCLUDE
      })
    });
  }

  /** @override */
  afterModel(model) {
    /**
     * This is so that orphaned payment cards don't become unassociated from the order just
     * because they don't show up in the client collection (assumed deleted for some reason)
     * I believe we can remove this once we upgrade to the latest ember-data
     */
    model.order.belongsTo('paymentCard').reload();

    const ordersController = this.controllerFor('logged-in.orders');
    ordersController.set('editRouteLoadingFirstTime', false);
    return model;
  }

  // Subscribe to pusher events,
  // the channelName is defined in the controller.
  setupController(controller, model) {
    super.setupController(controller, model);

    const originalRestaurant = model.order.get('restaurant');
    controller.removeDeadPaymentCard();
    controller.setProperties({ originalRestaurant, orderEditRoute: this.orderEditRoute });
    controller.setupPusher();

    this.setupSelectedOrder(model.order);
  }

  // Unsubscribe from the pusher channels we've been listening to.
  // Clean up when we leave. We probably don't want  to still be receiving events.
  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);
    controller.teardownPusher();
  }

  /**
   * Sets up the selected order for the page.
   * @param {Order} order
   */
  setupSelectedOrder(order) {
    const ordersController = this.controllerFor('logged-in.orders');
    const selectedOrders = ordersController.get('selectedOrders');

    // Since the parent route is already rendered, we need to put this in the next runloop.
    !selectedOrders.includes(order) &&
      run.next((_) => ordersController.set('selectedOrders', [order]));
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
