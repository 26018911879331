import { get } from '@ember/object';
import { camelize } from '@ember/string';

/**
 * Helper util to perform the user can / can't operations
 * @param {SessionService} session
 * @param {string} capability
 * @returns {boolean}
 */
export default function (session, capability) {
  const userRoles = (get(session, 'user.roles') ?? []).mapBy('name');

  // actions get camelized by the object transform so we need to make sure we do the same
  const actionRoles =
    get(session, `capabilities.${camelize(capability.toLowerCase())}.roles`) ?? [];

  return actionRoles.any((role) => userRoles.includes(role));
}
