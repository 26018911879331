import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { action } from '@ember/object';
import Controller from '@ember/controller';

@classic
export default class ShowController extends Controller {
  /** @type {Service} */
  @service
  session;

  /** @type {Restaurant} */
  @alias('model.restaurant')
  restaurant;

  /** @type {Area[]} */
  areas = [];

  @action
  didCancel() {
    this.transitionToRoute('logged-in.restaurants');
  }

  @action
  toggleEditBillingLocation() {
    if (!this.get('editableBillingLocation')) {
      this.get('model.billingLocation')
        // should be a clone
        .then((_) => _.copy().then((copy) => this.set('editableBillingLocation', copy)));
    } else {
      this.set('editableBillingLocation', null);
    }
  }

  @action
  makeOrderForRestaurant() {
    const restaurantId = this.get('restaurant.id');
    const areaId = this.get('restaurant.areas.firstObject.id');

    this.transitionToRoute('logged-in.new-order', {
      queryParams: { restaurantId, areaId }
    });
  }
}
