import Mixin from '@ember/object/mixin';

/**
 * Interface for any Logger service that will be used inside a LoggerManager
 * @interface ConstraintTag
 */
export default Mixin.create({
  /**
   * Takes a menu item and sees if the menu item is constraint by this tag. (Usually by comparing against a collection
   * of tags of a similar type)
   * @param {MenuItem} menuItem
   */
  matches(_menuItem) {
    console.assert(false, 'Must implement the matches method');
  }
});
