import { helper as buildHelper } from '@ember/component/helper';
import moment from 'moment-timezone';

export function addToDate(params) {
  let [date = moment(), amount = 1, type = 'days'] = params;

  return moment(date).add(amount, type);
}

export default buildHelper(addToDate);
