import Helper from '@ember/component/helper';
import { isPresent } from '@ember/utils';

export function truncate([value, limit]) {
  let out = '';

  if (isPresent(value)) {
    out = value.substr(0, limit);

    if (value.length > limit) {
      out += '...';
    }
  }

  return out;
}

export default Helper.helper(truncate);
