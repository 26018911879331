import Controller from '@ember/controller';
import EmberObject, { action } from '@ember/object';

const radioGroupExample = EmberObject.create({
  rg1: [
    { id: 1, name: 'Bodoni' },
    { id: 2, name: 'Akzidenz-Grotesk' },
    { id: 3, name: 'Avenir' }
  ].map((_) => EmberObject.create(_))
});

export default Controller.extend({
  radioGroupExample: radioGroupExample,

  radioGroupValue: radioGroupExample.get('rg1.firstObject'),

  handleRadioButtonChange: action(function (value) {
    this.set('radioGroupValue', value);
  })
});
