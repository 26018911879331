import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@tagName('td')
@classNames('fde-tables-ordered-table-table-rows-select-cell')
export default class SelectCell extends Component {
  /** @type {FormFor} */
  form = null;

  /** @type {boolean} */
  isSelected = false;

  onSelectChange() {}
}
