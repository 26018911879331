import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import EmberObject, { action, computed } from '@ember/object';
import moment from 'moment-timezone';

@classic
export default class ClosuresController extends Controller {
  /** @type {Service} */
  @service
  notify;

  @alias('model.restaurant')
  restaurant;

  /**
   * @type {string} the name of the channel that we use for presence,
   * defaults to ee.food.restaurants.{id}.model-events
   */
  @computed('restaurant')
  get pusherChannels() {
    const restaurantId = this.get('restaurant.id');

    return [`ee.food.restaurants.${restaurantId}.model-events`];
  }

  @computed('month')
  get date() {
    const month = this.get('month');
    return month !== '' ? moment(month) : moment();
  }

  closures = [];
  queryParams = ['month'];

  /** @type {string} the current month */
  month = '';

  // at some point we will need a calendar component, but it is not this day
  /** @type {Object[} weeks in this object */
  @computed('model', 'closures.[]')
  get weeks() {
    const startOfMonth = moment(this.get('date')).startOf('month');
    const thisMonth = startOfMonth.format('M');
    const firstSunday = startOfMonth.startOf('week');
    const closures = this.get('closures');

    // 6 is the maximum number of weeks that a month can span
    return (
      new Array(6)
        .fill('')
        // 7 days to a week
        .map((_, week) =>
          new Array(7)
            .fill('')

            .map((_, dayOfWeek) => {
              const day = moment(firstSunday).add(week * 7 + dayOfWeek, 'days');
              const closure = closures.find(
                (_) => moment(_.get('date')).format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
              );

              return EmberObject.create({
                // SUN = 0, SAT = 6
                closure: closure,
                isWeekend: dayOfWeek === 0 || dayOfWeek == 6,
                isThisMonth: day.format('M') === thisMonth,
                isToday: day.isSame(new Date(), 'day'),
                dayOfMonth: day.format('D'),
                date: day
              });
            })
        )
        // remove the week if there isn't a single day in this month
        .filter((week) => week.find((_) => _.get('isThisMonth')))
    );
  }

  @action
  updated(data) {
    console.debug(`${moment().format('hh:mm:ss ')} [${this.pubSub}] Updated event received.`, data);
    this.send('refreshModel');
  }

  @action
  created(data) {
    console.debug(`${moment().format('hh:mm:ss ')} [${this.pubSub}] Created event received.`, data);
    this.send('refreshModel');
  }

  @action
  destroyed(data) {
    console.debug(
      `${moment().format('hh:mm:ss ')} [${this.pubSub}] Destroyed event received.`,
      data
    );
    this.send('refreshModel');
  }

  @action
  toggleDay(day) {
    // only work on the current month
    if (!day.get('isThisMonth')) {
      return;
    }

    if (day.isActing) {
      return;
    }

    let promise = null;
    const closures = this.get('closures');
    const notify = this.get('notify');

    day.set('isActing', true);

    if (day.closure) {
      promise = day.closure.destroyRecord().then((_) => {
        closures.removeObject(day.closure);
      });
    } else {
      promise = this.store
        .createRecord('restaurant-closure', {
          restaurant: this.get('model.restaurant'),
          date: day.date.toDate()
        })
        .save()
        .then((_) => this.get('closures').pushObject(_));
    }

    promise
      .catch((_) => notify.error(`Could not set restaurant closure`))
      .finally((_) => day.set('isActing', false));
  }

  @action
  today() {
    const thisMonth = moment().format('YYYY-MM-DD');

    if (thisMonth !== this.get('month')) {
      this.setProperties({
        fetchingNow: true,
        month: thisMonth
      });
    }
  }

  @action
  nextMonth() {
    const nextMonth = moment(this.get('month') || new Date()).add(1, 'month');

    this.setProperties({
      fetchingNext: true,
      month: nextMonth.format('YYYY-MM-DD')
    });
  }

  @action
  prevMonth() {
    const previousMonth = moment(this.get('month') || new Date()).subtract(1, 'month');

    this.setProperties({
      fetchingPrev: true,
      month: previousMonth.format('YYYY-MM-DD')
    });
  }
}
