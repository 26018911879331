import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { filter } from '@ember/object/computed';
import { action } from '@ember/object';
import Component from '@ember/component';
import { isPresent } from '@ember/utils';

/**
 * Sortable Table configured for locations
 */
@classic
@classNames('fde-tables-client-invites')
export default class ClientInvites extends Component {
  /** @type {Service} */
  @service
  notify;

  /** @type {Service} */
  @service
  store;

  /** @type {Client} */
  client = null;

  /** @type {?string} */
  readonly = false;

  /** @type {?string} */
  isSending = false;

  /** @type {User[]} */
  @filter('client.admins.@each.{isPending,id}', function (admin) {
    return admin.get('isPending') && isPresent(admin.get('id'));
  })
  invitedUsers;

  @action
  resendInvite(user) {
    this.set('isSending', user.get('id'));

    user.resendInvite(this.get('client')).finally((_) => this.set('isSending', null));
  }
}
