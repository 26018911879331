import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class MenuOptionGroup extends Application {
  attrs = {
    menuOptionItems: {
      serialize: true
    }
  };
}
