import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-order-teams-topbar')
export default class Topbar extends Component {
  /** @type {boolean} */
  enableReminder = false;

  /** @type {boolean} */
  remindAllUsersIsLoading = false;

  remindAllUsers() {}
}
