import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('ui grid fde-invoices-line-item-row')
export default class LineItemRow extends Component {
  /** @type {Object} Line item */
  lineItem = null;

  /** @type {boolean} */
  isOrderItem = false;
}
