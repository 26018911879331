import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';

import { action } from '@ember/object';

@classic
export default class NewController extends Controller {
  @action
  handleDidSave() {
    this.send('refreshModel');
    this.transitionToRoute('logged-in.tags.dietary');
  }

  @action
  canceled() {
    this.transitionToRoute('logged-in.tags.dietary');
  }
}
