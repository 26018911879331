/**
 * Created by joe on 2016-08-04.
 */

export default function () {
  const spring = [250, 20];

  this.transition(
    this.fromRoute('login'),
    this.toRoute('logged-in'),

    this.use('toLeft', { easing: spring }),
    this.reverse('toRight', { easing: spring })
  );

  this.transition(
    this.fromRoute('logged-in'),
    this.toRoute('logout'),

    this.use('toRight', { easing: spring }),
    this.reverse('toLeft', { easing: spring })
  );

  this.transition(this.hasClass('fde-liquid-dropdown'), this.use('fade', { duration: 500 }));

  /** Transition for the order index to order edit page */
  this.transition(
    this.hasClass('fde-orders_if-edit'),

    // this makes our rule apply when the liquid-if transitions to the
    // true state.
    this.toValue(true),
    this.use('toLeft', { duration: 350 }),

    // which means we can also apply a reverse rule for transitions to
    // the false state.
    this.reverse('toRight', { duration: 350 })
  );

  this.transition(
    this.hasClass('accounting-modal'),
    this.toValue(false),
    this.use('toLeft', { duration: 500, easing: spring }),
    this.reverse('toRight', { duration: 500, easing: spring })
  );
}
