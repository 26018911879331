import Controller from '@ember/controller';
import EmberObject, { action, computed } from '@ember/object';
import RSVP from 'rsvp';
import { ServerError } from 'star-fox/errors/application';
import moment from 'moment-timezone';

export default Controller.extend({
  models: [
    {
      id: 1,
      name: 'Foo'
    },
    {
      id: 2,
      name: 'Bar'
    },
    {
      id: 3,
      name: 'Baz'
    }
  ].map((_) => EmberObject.create(_)),

  location: EmberObject.create({
    isNew: true
  }),

  contact: EmberObject.create({
    isNew: true
  }),

  basicField: {
    value: 'Click me to Edit',
    errors: []
  },

  amountField: {
    value: EmberObject.create({
      amount: 100,
      amountType: 'percentage'
    }),
    errors: []
  },

  modelSelectField: {
    value: {
      id: 0,
      name: 'None'
    },
    errors: []
  },

  dateField: {
    value: new Date(),
    errors: []
  },

  dateRangeField: {
    value: {
      startDate: new Date(),
      endDate: new Date()
    },
    errors: []
  },

  timeField: EmberObject.create({
    value: moment().startOf('day'),
    errors: []
  }),

  timeRangeField: EmberObject.create({
    value: {
      startTime: moment().startOf('day'),
      endTime: moment().startOf('day').add('3', 'hours')
    },
    errors: []
  }),

  formModel: EmberObject.create({
    foo: 'foo',
    bar: 'bar',
    baz: 10,

    testOption: 'One',

    errors: {
      foo: [
        {
          message: 'Foo is in error'
        }
      ]
    },

    save() {
      // NOOP
    },

    validate() {
      // NOOP
    }
  }),

  messageModel: EmberObject.create({
    fails: false,

    successMessage: 'Hooray',
    errorMessage: 'Dagnabbit',

    save() {
      return this.get('fails')
        ? RSVP.reject(ServerError('400 Bad Request'))
        : RSVP.resolve('Because');
    },

    validate() {
      return true;
    }
  }),

  card: computed(function () {
    const card = this.store.createRecord('payment-card');

    card.save = () => {
      card.send('pushedData');
      return RSVP.resolve(card);
    };

    return card;
  }),

  setErrors: action(function (key) {
    this.set(`${key}.errors`, [
      {
        message: `${key} is in error now.`
      }
    ]);
  }),

  clearErrors: action(function (key) {
    this.set(`${key}.errors`, []);
  }),

  commitValue: action(function (key, value) {
    this.set(key, value);
  }),

  handleCardDidSave: action(function (value) {
    console.info(
      'dev-team::components::forms #handleDidSave for {{sf/forms/credit-card-form-for}}',
      value
    );
  }),

  handleDidSave: action(function (value) {
    console.info(
      'dev-team::components::forms #handleDidSave for {{sf/forms/discount-form-for}}',
      value
    );
  })
});
