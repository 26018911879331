import classic from 'ember-classic-decorator';
import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class ShowRoute extends Route.extend(CapabilityRouteMixin) {
  /** @type {String} */
  capability = 'CLIENT_MEALPLAN_SHOW';

  /**
   * @param {Object} params
   *
   * @returns {Promise} Client and mealPlanningInstances
   */
  model(params) {
    const id = params.meal_planning_instance_id;

    return RSVP.hash({
      mealPlanningInstance: this.store.findRecord('mealPlanningInstance', id)
    });
  }
}
