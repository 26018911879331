import classic from 'ember-classic-decorator';
import { equal } from '@ember/object/computed';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class AccountingConsolidationRun extends Model {
  @attr('date')
  ranOn;

  @attr('string')
  period;

  @attr('string')
  identifier;

  @attr('string')
  queryType;

  @attr('string')
  queryData;

  @belongsTo('accounting-consolidation-configuration')
  configuration;

  @belongsTo('accounting-ledger-item')
  invoice;

  @equal('invoice.status', 'open')
  canDestroy;
}
