import Fixture, {
  // element,
  // elementWithValue,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class SfTabViewTabGroupTabFixture extends Fixture {
  constructor(_, tabName) {
    super('data-test-sf-tab-group-tab', tabName);
  }
}

export const sfTabViewTabGroupTab = getElementDecoratorFor(SfTabViewTabGroupTabFixture);
export const sfTabViewTabGroupTabWithValue = getElementWithValueDecoratorFor(
  SfTabViewTabGroupTabFixture
);
