import EmberObject from '@ember/object';
import Controller from '@ember/controller';

export default Controller.extend({
  models: new Array(20).fill(1).map((_, index) => {
    return EmberObject.create({
      fooLabel: `fooValue_${index}`,
      barLabel: `barValue_${index}`,
      bazLabel: `bazValue_${index}`
    });
  }),

  handleNewSelection() {},

  handleOrderEdit() {}
});
