import attr from 'ember-data/attr';
import { alias } from 'macro-decorators';
import MF from 'ember-data-model-fragments';

export default class FragmentsLogisticsPing extends MF.Fragment {
  @attr('luxon-date') createdAt;
  @attr('number') latitude;
  @attr('number') longitude;

  @alias('latitude') lat;
  @alias('longitude') lng;
}
