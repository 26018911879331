import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import config from 'star-fox/config/environment';

@classic
export default class FeatureFlagsRoute extends Route {
  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    search: { refreshModel: true },
    sort: { refreshModel: true }
  };

  @service
  ajax;

  async model() {
    const featuresObj = await this.get('ajax').request(
      `/${config.APP.apiV3Namespace}/users/features`
    );

    const features = Object.keys(featuresObj);
    const foundFeatureFlagDescriptions = await this.store.findAll('feature-flag-description');

    const featureFlagDescriptions = features.map((flagId) => {
      const foundFeatureFlagDescription = foundFeatureFlagDescriptions.findBy('flagId', flagId);
      return (
        foundFeatureFlagDescription ||
        this.store.createRecord('feature-flag-description', { flagId })
      );
    });

    return RSVP.hash({
      featureFlagDescriptions,
      subscriptionPlans: this.store.findAll('subscription-plan')
    });
  }
}
