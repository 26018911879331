import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';

@classic
@classNames('fde-filters-controls-date-range-selector')
export default class DateRangeSelector extends Component {
  /** @type {Area[]} */
  areas = null;

  /** @type {Area} */
  forArea = null;

  /** @type {string} */
  value = null;

  /** @type {string} */
  format = null;

  /** @type {boolean} */
  useDefaultValue = false;

  /** @type {String} */
  customFormat = null;

  /** @override */
  init() {
    super.init(...arguments);
    if (this.get('useDefaultValue')) {
      const defaultDateValue = this.get('defaultDateValue');

      this.set('filter.value', [
        this.dateToValueFormat(defaultDateValue.startDate),
        this.dateToValueFormat(defaultDateValue.endDate)
      ]);

      this.set('filter.valueText', [
        this.dateToValueTextFormat(defaultDateValue.startDate),
        this.dateToValueTextFormat(defaultDateValue.endDate)
      ]);
    }

    if (this.get('areas.length')) {
      const cityMatch = (this.get('filter.valueText.firstObject') || '').match(/.*\[(.*)\]/i) || [];
      const foundCity = cityMatch[1] || this.get('areas.firstObject.city');

      const areaForCity = this.get('areas').findBy('city', foundCity);
      areaForCity && this.set('forArea', areaForCity);
    }
  }

  /** @type {String} */
  @computed('hasTimePicker', 'format')
  get _format() {
    const valueFormat = this.get('valueFormat');
    const hasTimePicker = this.get('hasTimePicker');
    const defaultValueFormat = !hasTimePicker ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss.sssZ';

    return valueFormat || defaultValueFormat;
  }

  /** @type {String} */
  dateToValueFormat(date) {
    return moment(date).format(this.get('_format'));
  }

  /**
   * Returns value text format from a date object
   * @param {Date} date
   * @returns {String}
   */
  dateToValueTextFormat(date) {
    const timeZone = this.get('timeZone');
    const forArea = this.get('forArea');

    if (timeZone && forArea) {
      return `${moment
        .tz(date, timeZone)
        .format(this.get('customFormat') || this.get('dateControlFormat'))} [${this.get(
        'forArea.city'
      )}]`;
    } else {
      return `${moment
        .tz(date, moment.tz.guess())
        .format(this.get('customFormat') || this.get('dateControlFormat'))}`;
    }
  }

  /** @type {Date} */
  valueFormatToDate(value) {
    return moment(value, this.get('_format')).toDate();
  }

  /** @type {Date} */
  @computed('hasTimePicker')
  get defaultDateValue() {
    const hasTimePicker = this.get('hasTimePicker');

    const today = hasTimePicker
      ? moment().hour(0).minute(0).second(0).millisecond(0).toDate()
      : moment().minute(0).second(0).millisecond(0).toDate();

    const tomorrow = hasTimePicker
      ? moment().add(1, 'day').hour(0).minute(0).second(0).millisecond(0).toDate()
      : moment().add(1, 'day').minute(0).second(0).millisecond(0).toDate();

    return {
      startDate: today,
      endDate: tomorrow
    };
  }

  /** @type {{ startDate: Date, endDate: Date}} */
  @computed('filter.value.[]')
  get toDateRangeValue() {
    const firstFilterValue = this.get('filter.value.firstObject');
    const lastFilterValue = this.get('filter.value.lastObject');

    const value =
      firstFilterValue && lastFilterValue
        ? {
            startDate: this.valueFormatToDate(firstFilterValue),
            endDate: this.valueFormatToDate(lastFilterValue)
          }
        : null;

    return value ? value : this.get('defaultDateValue');
  }

  /** @type {boolean} */
  hasTimePicker = false;

  /** @type {number} amount that the minute drop down increments by */
  timePickerIncrement = 15;

  /** @type {string} */
  @computed('hasTimePicker')
  get dateControlFormat() {
    return this.get('hasTimePicker') ? 'MMMM Do YYYY hh:mm a' : 'MMMM Do YYYY';
  }

  /** @type {string} */
  @computed('forArea')
  get timeZone() {
    const area = this.get('forArea');
    return area ? area.get('isoTimeZone') : moment.tz.guess();
  }

  @action
  handleDateRangeChange(dateRange) {
    console.debug(dateRange);
    this.get('filter').setValue(
      [this.dateToValueFormat(dateRange.startDate), this.dateToValueFormat(dateRange.endDate)],
      [
        this.dateToValueTextFormat(dateRange.startDate),
        this.dateToValueTextFormat(dateRange.endDate)
      ]
    );
  }

  @action
  handleAreaChange(area) {
    this.set('forArea', area);
    const dateRange = this.get('toDateRangeValue');

    this.get('filter').setValue(
      [this.dateToValueFormat(dateRange.startDate), this.dateToValueFormat(dateRange.endDate)],
      [
        this.dateToValueTextFormat(dateRange.startDate),
        this.dateToValueTextFormat(dateRange.endDate)
      ]
    );
  }
}
