import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class MealPlanningLogRecord extends Model {
  /*
   * Attributes
   */
  @attr('string')
  text;

  @attr('boolean')
  foodeeEmployee;

  @attr('string')
  solverLog;

  @attr('string')
  logType;

  @attr('date')
  createdAt;

  /*
   * Relationships
   */
  @belongsTo('meal-planning-planable', { polymorphic: true })
  planable;

  validations = {
    text: {
      presence: {}
    }
  };
}
