import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class GiftbitError extends Model {
  @computed
  get humanize() {
    return `SalesforceSynchronizationError: ${this.get('recordType')}#${this.get('recordId')}`;
  }

  @attr('date')
  createdAt;

  @attr('date')
  updatedAt;

  @attr('string')
  recordType;

  @attr('number')
  recordId;

  @attr('boolean')
  isResolved;

  @attr('string')
  log;

  @belongsTo('order')
  order;

  /** @type {boolean} Whether or not this error is being retried */
  isRetrying = false;

  /**
   *
   */
  retry() {
    const modelName = this.constructor.modelName;
    const adapter = this.store.adapterFor(modelName);

    adapter.retry(this);
  }
}
