import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';

import { action } from '@ember/object';

@classic
export default class NewController extends Controller {
  @action
  handleDidSave() {
    this.transitionToRoute('logged-in.areas.edit.index');
  }

  @action
  handleDidCancel() {
    this.transitionToRoute('logged-in.areas.edit.index');
  }
}
