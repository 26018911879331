import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { oneWay } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';

@classic
@classNames('ui segment sf-orders-restaurant-details')
export default class RestaurantDetails extends Component {
  @service
  store;

  @service
  notify;

  /** @type {object} The form object passed to the component. */
  form = null;

  /** @type {Order} */
  order = null;

  /** @type {Restaurant[]} */
  restaurants = null;

  /** @type {Restaurant} */
  @oneWay('order.restaurant')
  selectedRestaurant;

  /** @type {?string} */
  pickupAtError = null;

  /** @type {boolean} */
  isLoading = false;

  /** @type {boolean} */
  @computed('order.pickupAt', 'order.restaurant.closures.content')
  get restaurantIsClosed() {
    const restaurant = this.get('order.restaurant');
    const pickupAt = this.get('order.pickupAt');
    return restaurant && !restaurant.isOutsideOfClosures(pickupAt);
  }

  /** @type {boolean} */
  @computed('order.pickupAt', 'order.restaurant.serviceTimes')
  get pickupIsOutsideServiceTime() {
    const restaurant = this.get('order.restaurant');
    const pickupAt = this.get('order.pickupAt');
    return restaurant && !restaurant.isWithinPickupTime(pickupAt);
  }

  /** @type {Function} */
  onRestaurantChange() {}

  onTimeChange() {}
}
