import classic from 'ember-classic-decorator';
import FeedItemBuilder from './base';

@classic
export default class OrderUserBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);

    const event = version.get('event');
    let verb = '';
    let preposition = '';

    if (event === 'create') {
      verb = 'added';
      preposition = 'to';
    } else {
      verb = 'removed';
      preposition = 'from';
    }

    feedItem.icon = 'users';
    feedItem.subject = `${version.get('whodunnit')} ${verb} user '${version.get(
      'misc.name'
    )}' ${preposition} the order`;
    feedItem.details = null;

    return feedItem;
  }
}
