import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';

@classic
export default class EditController extends Controller {
  @alias('model')
  area;

  /**
   * @type Array.[{string}] the name of the channels we subscribe to,
   * currently only ee.food.areas.{id}.model-events
   */
  @computed('area')
  get pusherChannels() {
    const areaId = this.get('area.id');

    return [`ee.food.areas.${areaId}.model-events`];
  }

  @action
  updated(data) {
    console.debug(`${moment().format('hh:mm:ss ')} [${this.pubSub}] Updated event received.`, data);
    this.send('modelChanged');
  }

  @action
  created(data) {
    console.debug(`${moment().format('hh:mm:ss ')} [${this.pubSub}] Created event received.`, data);
    this.send('modelChanged');
  }
}
