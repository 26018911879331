export default {
  mobile: '(max-width: 960px)',
  phone: '(max-width: 768px)',
  abovePhone: '(min-width: 769px)',
  tablet: '(min-width: 769px) and (max-width: 960px)',
  aboveTablet: '(min-width: 961px)',
  desktop: '(min-width: 961px) and (max-width: 1440px)',
  aboveDesktop: '(min-width: 961px)',
  largeDesktop: '(min-width: 1441px)',
};
