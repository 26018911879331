import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('fde-contact-view')
export default class ContactView extends Component {
  /**  @type {User|Contact} */
  contact = null;

  @computed('contact')
  get urlSafeExtension() {
    const extension = this.get('contact.extension');
    return extension ? `;ext=${extension}` : '';
  }

  /** @type {string} */
  @computed('contact.smsNumber')
  get smsDisplayNumber() {
    return this.get('contact.smsNumber') || '0000000000';
  }
}
