import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action } from '@ember/object';
import ENV from 'star-fox/config/environment';

export const modalConfig = {
  confirm: {
    options: {
      confirmHeader: 'A user with this email already exists',
      confirmBody:
        'Would you like to add the user to this account using their existing contact details?',
      confirmBody2:
        'If you want to add a new user you will need to go back and select a different email address.',
      acceptText: 'Yes, add this user',
      cancelText: 'Go back'
    },
    template: 'sf/restaurants/multi-restaurant/modals/confirm'
  },
  search: {
    options: {
      headerText: 'Search an existing user to add to this restaurant',
      acceptText: 'Add this user',
      cancelText: 'Go back'
    },
    template: 'sf/restaurants/multi-restaurant/modals/search-user'
  },
  focusTrapOptions: {
    clickOutsideDeactivates: false
  }
};

@classic
@classNames('fde-new-restaurant-user-form-for')
export default class NewRestaurantUserFormFor extends Component {
  /** @type {Service} */
  @service
  store;

  /** @type {Service} */
  @service
  modals;

  /** @type {Service} */
  @service
  notify;

  /** @type {boolean} */
  readonly = null;

  /** @type {UserInvite} */
  newUser = null;

  /** @type {Restaurant} */
  restaurant = null;

  /** @type {boolean} */
  showNewUserForm = false;

  /** Creates a new User for the Restaurant */
  @action
  resetUser() {
    this.setProperties({
      showNewUserForm: false,
      newUser: this.get('store').createRecord('user-invite', {
        restaurant: this.get('restaurant')
      })
    });
  }

  @action
  async willFailIfUserExists(user) {
    const users = await this.store.query('user', { filter: { email: user.email } });

    if (users.length && users.firstObject.email === user.email) {
      const isConfirmed = await this.modals.open(
        modalConfig.confirm.template,
        modalConfig.confirm.options,
        modalConfig.focusTrapOptions
      );

      if (isConfirmed) {
        this.addExistingUser(users.firstObject);

        return false;
      }
    }

    return true;
  }

  @action
  async selectExistingUser() {
    const user = await this.modals.open(
      modalConfig.search.template,
      modalConfig.search.options,
      modalConfig.focusTrapOptions
    );

    if (user) {
      this.addExistingUser(user);
    }
  }

  @action
  async addExistingUser(user) {
    try {
      this.restaurant.admins.pushObject(user);

      await fetch(`${ENV.API_HOST}/api/v3/users/${user.id}/multi-restaurant-invitation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      await this.restaurant.save();
      await user.save();

      this.notify.success('Admin successfully added to restaurant.');
    } catch (_err) {
      this.notify.error(
        'An error has occurred while trying to remove this admin from the restaurant.'
      );
    }
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.resetUser();
  }
}
