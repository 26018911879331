import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import url from 'renard/utils/url';
import { formatDate } from 'renard/helpers/format-date';

@classic
@classNames('ui grid segment fde-orders-team-order-details')
export default class TeamOrderDetails extends Component {
  /** @type {object} The form object passed to the component. */
  form = null;

  /** @type {object} The model object from the order edit route. */
  model = null;

  /** @type {Order} */
  @alias('model.order')
  order;

  /** @type {String[]} */
  budgetTypeValues = ['money', 'hide_prices'];

  /** @type {string} */
  @computed
  get swiftFoxUrl() {
    return url.getSwiftFoxUrl();
  }

  /** @type {string} */
  @computed('swiftFoxUrl', 'order.client.mealPlan.vanityUrl', 'order.urlSafeId')
  get mealPlanOrderUrl() {
    const vanityUrl = this.get('order.client.mealPlan.vanityUrl');

    return `${this.get('swiftFoxUrl')}/teams/${vanityUrl}/order/${this.get('order.urlSafeId')}`;
  }

  /** @type {string} */
  @computed('swiftFoxUrl', 'order.urlSafeId')
  get teamOrderUrl() {
    return `${this.get('swiftFoxUrl')}/team-order/${this.get('order.urlSafeId')}`;
  }

  @computed('swiftFoxUrl', 'order.meal.uuid')
  get mealEventUrl() {
    return `${this.get('swiftFoxUrl')}/guest-meal/${this.get('order.event.uuid')}`;
  }

  /** @type {string} */
  @computed('order.deadlineAt', 'order.area.isoTimeZone')
  get sameDayDeadlineTooltipMsg() {
    return `Same-day deadline: ${formatDate([
      this.order.deadlineAt,
      'ddd h:mma',
      this.order.area.isoTimeZone
    ])}`;
  }

  onTimeChange() {}
}
