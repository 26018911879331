import { attr } from '@ember-data/model';
import MF from 'ember-data-model-fragments';

export default MF.Fragment.extend({
  /*
   * Attributes
   */
  fieldName: attr('string', { defaultValue: 'Custom field' }),
  fieldValue: attr('string'),

  /*
   * These act as validation settings
   */
  minLength: attr('number', { defaultValue: 1 }),
  maxLength: attr('number', { defaultValue: 20 }),
  onlyDigits: attr('boolean', { defaultValue: false }),
  onlyCharacters: attr('boolean', { defaultValue: false }),
  isEmail: attr('boolean', { defaultValue: false })
});
