import classic from 'ember-classic-decorator';
import Helper from '@ember/component/helper';
import { htmlSafe as hs } from '@ember/template';

export function htmlSafe(options) {
  return hs(options.join(''));
}

@classic
export default class HtmlSafe extends Helper {
  compute = htmlSafe;
}
