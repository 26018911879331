import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import Helper from '@ember/component/helper';
import { set } from '@ember/object';

@classic
export default class FeatureEnabledFor extends Helper {
  /**
   * @param {string} feature the name of the feature
   * @param {object} sources objects should have flipper features hash under the feature key
   * @returns {boolean}
   */
  compute([feature, ...sources]) {
    set(this, 'sources', sources);

    return (
      !!sources &&
      sources
        .filter(Boolean)
        .mapBy(`features.${feature}`)
        .reduce((feature, acc) => acc || !!feature, false)
    );
  }

  @observes('source.{features,client,user,mealPlanClient}')
  stateDidChange() {
    this.recompute();
  }
}
