import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action } from '@ember/object';
import { ActionType } from 'star-fox/transforms/action-type';

@classic
@tagName('')
@classNames('')
export default class ActionConfigurator extends Component {
  @service
  store;

  actionTypes = ActionType.values.sortBy('category');

  action = null;
  rule = null;

  @action
  deleteAction() {
    this.get('action')
      .destroyRecord()
      .then(() => this.set('action', null));
  }
}
