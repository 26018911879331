import Controller from 'star-fox/features/application/abstract-controller';
import { inject as service } from '@ember/service';
import { alias, oneWay } from '@ember/object/computed';
import { inject as controller } from '@ember/controller';
import EmberObject, { action, computed } from '@ember/object';
import moment from 'moment-timezone';
import { ORDER_INDEX_FIELDS, ORDER_INDEX_INCLUDE } from 'star-fox/services/order-service';
import { OrderState } from 'renard/transforms/order-state';

export default Controller.extend({
  /**
   * Query params for controller.
   */
  queryParams: [
    'areas',
    'filterDate',
    'pageLimit',
    'pageOffset',
    'reverseSort',
    'sortBy',
    'sort',
    'search',
    'restaurant_ids',
    'order_ids',
    'deliver_on',
    'deliver_on_range'
  ],

  applicationController: controller('application'),

  /** @type {Tock} */
  tock: service(),

  /** @type {number} */
  recordCount: oneWay('model.orders.meta.recordCount'),

  /** @type {String} */
  userSession: service(),

  /** @type {number} records per page */
  pageLimit: 20,

  /** @type {number} current page */
  pageOffset: 0,

  /**
   * Sort settings
   * @type {string}
   */
  sortBy: 'deliverAt',

  /** @type {string} sorting string */
  sort: 'deliverAt',

  /** @type {string} order search string */
  search: null,

  /** @type {Number} */
  rightPaneSize: 0,

  /** @type {Area[]} */
  allAreas: alias('model.areas'),

  /** @type {boolean} */
  editRouteLoadingFirstTime: false,

  /** @type {String} */
  filterBarSearchQuery: null,

  /** @type {Object[]} */
  splitViewStops: [
    { name: 'initial', value: 0 },
    { name: 'initial', value: 1100 },
    { name: 'initial', value: 1300 }
  ],

  // Rewritting the Mixin attribute
  useNewPubSub: computed('session.user', function () {
    return this.session.user.hasFeatureEnabled('newPubSub');
  }),

  /** @override */
  pusherModelQueryParams: {
    Order: {
      include: ORDER_INDEX_INCLUDE,
      fields: ORDER_INDEX_FIELDS
    }
  },

  /** @type {{id: String, value: String: label: String}[]} */
  allOrderStates: OrderState.values.map((_) => {
    return {
      id: _.valueOf(),
      value: _.valueOf(),
      label: _.replace(/_/g, ' ')
    };
  }),

  /** @type {String} */
  editRoute: computed('applicationController.currentRouteName', function () {
    const currentRouteName = this.get('applicationController.currentRouteName');
    return currentRouteName.includes('edit') ? currentRouteName : 'logged-in.orders.edit';
  }),

  /**
   * @type {string[]}
   */
  pusherChannels: computed('model', function () {
    const areas = (this.get('areas') || '').split(',').filter((_) => _ !== '');
    const allAreas = this.get('allAreas');

    if (areas.length > 0) {
      return allAreas
        .filter((a) => areas.includes(a.get('city')))
        .map((a) => `ee.food.areas.${a.get('id')}.orders.model-events`);
    } else {
      return ['ee.food.areas.all.orders.model-events'];
    }
  }),

  /** @type {boolean} denotes is the route is in a loading state */
  isLoading: false,

  /** @type {string} displaying areas when in split pane view */
  areaNames: computed('areas', function () {
    const areas = (this.get('areas') || '').replace(',', ', ') || 'All areas';
    return areas;
  }),

  /** @type {string} displaying date when in split pane view */
  displayDate: computed('filterDate', function () {
    return moment
      .tz(this.get('filterDate'), this.get('order.area.isoTimeZone'))
      .format('dddd, MMMM Do');
  }),

  /** @type {Order} order object */
  order: null,

  /** @type {Order[]} */
  selectedOrders: [],

  /** @type {boolean} True when the current route is edit route */
  narrowMode: computed('applicationController.currentRouteName', function () {
    return this.get('applicationController.currentRouteName').includes('logged-in.orders.edit');
  }),

  /** @type {String} Filter orders by date */
  filterDate: moment().format('YYYY-MM-DD'),

  /** @type {date} Start of the week of the selected date */
  startOfWeek: computed('filterDate', function () {
    // isoweek instead of startOfWeek starts the week on a monday :)
    return moment(this.get('filterDate')).startOf('isoweek');
  }),

  // TODO: Not complete, require more out-of-scope backend work
  // in MasterFox. Soon to be completed.
  /** @type {Object[]} */
  daysOfWeek: computed('filterDate', function () {
    const startOfWeek = this.get('startOfWeek');
    const orderWeekInfo = this.get('model.orders.meta.orderWeekInfo');

    return new Array(7).fill('').map((_, i) => {
      const day = moment(startOfWeek).add(i, 'day').format();
      const dayMeta = orderWeekInfo[i.toString()];

      return EmberObject.create({
        date: moment(day),
        info: `${dayMeta.confirmedCount}/${dayMeta.allCount}`
      });
    });
  }),

  /**
   * Replaces the current set of date filter
   * @param {Filter} newDateFilter
   */
  replaceDateFilterWith(newDateFilter) {
    const filterCollection = this.get('filterCollection');
    const selectedFilters = filterCollection.get('selectedFilters');

    const dateFilters = selectedFilters.filter((_) =>
      ['deliver_on', 'deliver_on_range', 'deliver_at_after', 'deliver_at_before'].includes(
        _.get('key')
      )
    );
    dateFilters.forEach((_) => filterCollection.removeFilter(_));

    newDateFilter && filterCollection.addFilter(newDateFilter);
  },

  /** @type {boolean} */
  isDateFilterTodayOnward: computed(
    'tock.time',
    'filterCollection.selectedFilters.@each.value',
    function () {
      const dateFilter = this.get('filterCollection.selectedFilters').findBy(
        'key',
        'deliver_at_after'
      );
      return dateFilter && dateFilter.get('value.firstObject') === moment().format('YYYY-MM-DD');
    }
  ),

  /** @type {boolean} */
  isDateFilterMissing: computed(
    'tock.time',
    'filterCollection.selectedFilters.@each.value',
    function () {
      const dateFilters = this.get('filterCollection.selectedFilters').filter((_) => {
        return (
          _.get('key') === 'deliver_on' ||
          _.get('key') === 'deliver_on_range' ||
          _.get('key') === 'deliver_at_after' ||
          _.get('key') === 'deliver_at_before'
        );
      });

      return !dateFilters.length;
    }
  ),

  /** @type {boolean} */
  isDateFilterYesterday: computed(
    'tock.time',
    'filterCollection.selectedFilters.@each.value',
    function () {
      const dateFilter = this.get('filterCollection.selectedFilters').findBy('key', 'deliver_on');
      return (
        dateFilter &&
        dateFilter.get('value.firstObject') === moment().subtract(1, 'days').format('YYYY-MM-DD')
      );
    }
  ),

  /** @type {boolean} */
  isDateFilterToday: computed(
    'tock.time',
    'filterCollection.selectedFilters.@each.value',
    function () {
      const dateFilter = this.get('filterCollection.selectedFilters').findBy('key', 'deliver_on');
      return dateFilter && dateFilter.get('value.firstObject') === moment().format('YYYY-MM-DD');
    }
  ),

  /** @type {boolean} */
  isDateFilterTomorrow: computed(
    'tock.time',
    'filterCollection.selectedFilters.@each.value',
    function () {
      const dateFilter = this.get('filterCollection.selectedFilters').findBy('key', 'deliver_on');
      return (
        dateFilter &&
        dateFilter.get('value.firstObject') === moment().add(1, 'days').format('YYYY-MM-DD')
      );
    }
  ),

  /** @type {boolean} */
  isDateFilterThreeDaysOut: computed(
    'tock.time',
    'filterCollection.selectedFilters.@each.value',
    function () {
      const dateFilter = this.get('filterCollection.selectedFilters').findBy(
        'key',
        'deliver_on_range'
      );

      return (
        dateFilter &&
        dateFilter.get('value.firstObject') === moment().format('YYYY-MM-DD') &&
        dateFilter.get('value.lastObject') === moment().add(3, 'days').format('YYYY-MM-DD')
      );
    }
  ),

  /** @type {boolean} */
  isDateFilterSevenDaysOut: computed(
    'tock.time',
    'filterCollection.selectedFilters.@each.value',
    function () {
      const dateFilter = this.get('filterCollection.selectedFilters').findBy(
        'key',
        'deliver_on_range'
      );

      return (
        dateFilter &&
        dateFilter.get('value.firstObject') === moment().format('YYYY-MM-DD') &&
        dateFilter.get('value.lastObject') === moment().add(7, 'days').format('YYYY-MM-DD')
      );
    }
  ),

  /** @type {string} */
  searchableFilterIds: computed('filterBarSearchQuery', function () {
    if (/^\+?\d+$/.test(this.get('filterBarSearchQuery'))) {
      return 'id';
    } else {
      return 'restaurant_id client_id areas managed_by_id driver_id';
    }
  }),

  /** @type {Object[]} */
  clientSegments: [
    {
      id: 'SuperA',
      value: 'SuperA',
      label: 'SuperA'
    },
    {
      id: 'A',
      value: 'A',
      label: 'A'
    },
    {
      id: 'B',
      value: 'B',
      label: 'B'
    },
    {
      id: 'C',
      value: 'C',
      label: 'C'
    },
    {
      id: 'D',
      value: 'D',
      label: 'D'
    }
  ],

  /** @type {Object[]} */
  clientMealPlans: [
    {
      id: 'enterprise',
      value: 'enterprise',
      label: 'Enterprise'
    },
    {
      id: 'business',
      value: 'business',
      label: 'Business'
    },
    {
      id: 'pro',
      value: 'pro',
      label: 'Pro'
    },
    {
      id: 'basic',
      value: 'basic',
      label: 'Basic'
    }
  ],

  selectDate: action(function (value) {
    this.setProperties({
      filterDate: moment(value).format('YYYY-MM-DD'),
      pageOffset: 0
    });
  }),

  nextWeek: action(function () {
    const filterDate = this.get('filterDate');
    const value = moment(filterDate).add(7, 'days').toDate();
    const nextWeek = moment(value).format('YYYY-MM-DD');

    this.set('filterDate', nextWeek);
  }),

  lastWeek: action(function () {
    const filterDate = this.get('filterDate');
    const value = moment(filterDate).subtract(7, 'days').toDate();
    const lastWeek = moment(value).format('YYYY-MM-DD');

    this.set('filterDate', lastWeek);
  }),

  commitDate: action(function () {
    console.debug('Commited the date, huh?');
  }),

  changeWeek: action(function (newDate) {
    console.debug('Changing the week, huh?', newDate);
  }),

  handleBulkCreateCityClick: action(function (areaId) {
    this.transitionToRoute('logged-in.bulk-orders', areaId);
  }),

  handleSearchChange: action(function (searchText) {
    this.setProperties({
      search: searchText,
      pageOffset: 0
    });
  }),

  handleFilterApply: action(function () {
    this.send('setQueryParamsFromFilterCollection');
  }),

  handleSplitViewChange: action(function (value) {
    this.set('rightPaneSize', value);
  }),

  handleNewOrderSelection: action(function (newSelection) {
    const firstSelection = newSelection[0];
    this.set('selectedOrders', newSelection);

    firstSelection && this.transitionToRoute(this.get('editRoute'), firstSelection.get('id'));
  }),

  openInNewTabs: action(function () {
    const selectedOrders = this.get('selectedOrders');
    this.send('openOrdersInNewTabs', selectedOrders);
  }),

  workOnSpecificOrders: action(function () {
    const selectedOrders = this.get('selectedOrders');
    this.send('workOnSpecificOrdersInNewTab', selectedOrders);
  }),

  handleTodayOnwardButtonClick: action(function () {
    const filterCollection = this.get('filterCollection');

    const newFilter = filterCollection.createFilter({
      key: 'deliver_at_after',
      value: [moment().format('YYYY-MM-DD')],
      valueText: [moment().format('MMMM Do YYYY')]
    });

    this.replaceDateFilterWith(newFilter);
  }),

  handleAllTimeButtonClick: action(function () {
    this.replaceDateFilterWith();
  }),

  handleYesterdayButtonClick: action(function () {
    const filterCollection = this.get('filterCollection');
    const date = moment().subtract(1, 'days');

    const newFilter = filterCollection.createFilter({
      key: 'deliver_on',
      value: [date.format('YYYY-MM-DD')],
      valueText: [date.format('MMMM Do YYYY')]
    });

    this.replaceDateFilterWith(newFilter);
  }),

  handleTodayButtonClick: action(function () {
    const filterCollection = this.get('filterCollection');

    const newFilter = filterCollection.createFilter({
      key: 'deliver_on',
      value: [moment().format('YYYY-MM-DD')],
      valueText: [moment().format('MMMM Do YYYY')]
    });

    this.replaceDateFilterWith(newFilter);
  }),

  handleTomorrowButtonClick: action(function () {
    const filterCollection = this.get('filterCollection');

    const newFilter = filterCollection.createFilter({
      key: 'deliver_on',
      value: [moment().add(1, 'day').format('YYYY-MM-DD')],
      valueText: [moment().add(1, 'day').format('MMMM Do YYYY')]
    });

    this.replaceDateFilterWith(newFilter);
  }),

  handleThreeDayButtonClick: action(function () {
    const filterCollection = this.get('filterCollection');

    const newFilter = filterCollection.createFilter({
      key: 'deliver_on_range',
      value: [moment().format('YYYY-MM-DD'), moment().add(3, 'days').format('YYYY-MM-DD')]
    });

    this.replaceDateFilterWith(newFilter);
  }),

  handleSevenDayButtonClick: action(function () {
    const filterCollection = this.get('filterCollection');

    const newFilter = filterCollection.createFilter({
      key: 'deliver_on_range',
      value: [moment().format('YYYY-MM-DD'), moment().add(7, 'days').format('YYYY-MM-DD')]
    });

    this.replaceDateFilterWith(newFilter);
  })
});
