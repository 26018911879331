import { inject as service } from '@ember/service';

import Mixin from '@ember/object/mixin';
import RSVP from 'rsvp';

export const ServiceAreaStatus = {
  INSIDE: 'inside',
  NEAR: 'near',
  OUTSIDE: 'outside'
};

export const ServiceAreaStatusError = {
  INVALID_POSTALCODE: 'invalid_postalcode',
  INVALID_ADDRESS: 'invalid_address'
};

export default Mixin.create({
  /** @type {Service} */
  store: service(),

  /**
   * Returns a list of areas containing the address
   * @param {string} address
   * @return {Promise<Area[]>}
   */
  getServiceAreaContaining(address) {
    return this.get('store').query('area', {
      filter: {
        containingAddress: address
      }
    });
  },

  /**
   * @param {string} addr
   * @return {Promise.<boolean>}
   */
  addressOutOfZone(addr) {
    return new RSVP.Promise((resolve) => {
      this.getServiceAreaContaining(addr).then((areas) => {
        const inZone = areas.get('length');
        resolve(!inZone);
      });
    });
  },

  /**
   * wrapper around getServiceAreaContaining
   * meant to handle new locations that have lat/lng but not addresses
   *
   * @param {Location} location
   * @return {Promise.<bool>}
   */
  locationOutOfZone(location) {
    const { latitude, longitude, gmapsLocation } = location.getProperties(
      'latitude',
      'longitude',
      'gmapsLocation'
    );

    // case it comes from our Location model
    if (gmapsLocation) {
      return this.addressOutOfZone(gmapsLocation);
    } else if (latitude && longitude) {
      // when location comes straight from a map, it onlyhas lat/lng
      const geocoder = new google.maps.Geocoder();
      const latLng = {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
      };

      return geocoder
        .geocode({
          location: latLng
        })
        .then((results) => this.getServiceAreaContaining(results[0].formatted_address))
        .then((areas) => areas.get('length') === 0);
    } else {
      // we don't have enough information, so let's play it safe
      return RSVP.Promise.resolve(false);
    }
  }
});
