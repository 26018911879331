import classic from 'ember-classic-decorator';
import Transform from '@ember-data/serializer/transform';

@classic
export default class RuleOwner extends Transform {
  deserialize(value) {
    if (!value) {
      throw new Error(`${value} is not a valid rule owner when deserializing`);
    }

    return value;
  }

  serialize(value) {
    if (!value) {
      throw new Error(`${value} is not a valid rule owner when serializing`);
    }

    return value;
  }
}
