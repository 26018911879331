import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@ember/component';
import { capitalize } from '@ember/string';

@classic
@classNames('fde-order-item')
export default class OrderItem extends Component {
  /** @type {boolean} */
  readonly = false;

  /** @type {Service} */
  @service
  notify;

  /** @type {OrderItem} OrderItem object */
  orderItem = null;

  /** @type {?boolean} */
  isEditDisabled = null;

  /** @type {Function} function to edit a custom order item */
  handleCustomItemEdit() {}

  /** @type {Function} function to handle an item being over budget */
  handleOverBudgetItem() {}

  /** @type {Function} */
  handleOnSave() {}

  /** @type {Function} */
  handleOnFail() {}

  /** @type {Function} */
  handleOnDelete() {}

  /**
   * Saves the orderItem, and then calls callbacks if they exist
   */
  _save() {
    const orderItem = this.get('orderItem');

    if (orderItem.get('order.isGroupOrder')) {
      const groupOrderMember = orderItem.get('groupOrderMember');
      const isOverBudget = groupOrderMember.get('isOverBudget');
      const isCartLimitedByPoop = groupOrderMember.get('isCartLimitedByPoop');

      if (isCartLimitedByPoop && isOverBudget) {
        orderItem.rollbackAttributes();
        return;
      }
    }

    if (orderItem.validate({ only: ['quantity'] }) && orderItem.get('hasDirtyAttributes')) {
      return orderItem
        .save()
        .then((_) => {
          this.handleOnSave();
        })
        .catch((error) => {
          this.handleOnFail(orderItem, error);
        });
    } else {
      const notify = this.get('notify');
      orderItem
        .get('errors')
        .forEach((error) =>
          notify.error(`${capitalize(error.attribute)}: ${error.message.join(', ')}`)
        );
    }
  }

  @action
  incrementQuantity() {
    const orderItem = this.get('orderItem');
    orderItem.incrementProperty('quantity');
    this._save();
  }

  @action
  decrementQuantity() {
    const orderItem = this.get('orderItem');

    if (orderItem.get('quantity') > 1) {
      orderItem.decrementProperty('quantity');
      this._save();
    }
  }

  @action
  changeQuantity(quantity) {
    const orderItem = this.get('orderItem');
    orderItem.set('quantity', parseInt(quantity, 10));
    this._save();
  }

  @action
  destroyItem() {
    this.get('orderItem')
      .destroyRecord()
      .then((_) => this.handleOnDelete());
  }
}
