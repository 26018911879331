import classic from 'ember-classic-decorator';
import Service, { inject as service } from '@ember/service';

/**
 * API V3 Invoices#calcluate_taxes_for_items
 * Fetches & updates taxes for a group order
 */
@classic
export default class FetchTaxService extends Service {
  /** @type {Ember.Service} */
  @service
  ajax;

  /** @type {Order} groupOrder */
  groupOrder = null;

  /**
   * Fetches and sets the taxes for GOMs & group order
   * @param {Order} groupOrder
   * @returns {Ember.RSVP.Promise}
   */
  apply(groupOrder) {
    this.set('groupOrder', groupOrder);

    const perPersonBudget = groupOrder.get('perPersonBudget');

    // Snake case vars are getting sent to MasterFox
    // only send up orderItems that have a GOM that is present
    const order_items = groupOrder
      .get('orderItems')
      .filter((oi) =>
        groupOrder.get('groupOrderMembers').includes(oi.get('groupOrderMember.content'))
      )
      .map((orderItem) => {
        const groupOrderMember = orderItem.get('groupOrderMember');

        return {
          group_order_member_id: parseInt(groupOrderMember.get('id')),
          menu_item_id: parseInt(orderItem.get('menuItem.id')),
          quantity: orderItem.get('quantity'),
          is_agnes_item: groupOrderMember.get('isAdmin')
        };
      });

    const payload = {
      order_items,
      per_person_budget: perPersonBudget || 0,
      is_agnes: true
    };

    return this.get('ajax')
      .post('/api/v3/invoices/menu-items-calculate-taxes', {
        data: JSON.stringify(payload),
        contentType: 'application/json'
      })
      .then((res) => {
        this._updateMembersTotals(res.group_order_members);
        this._updateOrderTotals(res);
      });
  }

  /**
   * Calculates and sets taxTotal for GOMs of the groupOrder
   * @param {Object} membersTaxes - APIResponse.group_order_members
   */
  _updateMembersTotals(membersTaxes) {
    const groupOrder = this.get('groupOrder');
    const areaTaxRate = groupOrder.get('area.taxRate.rate');

    const membersTaxTotal = Object.keys(membersTaxes).reduce((sum, id) => {
      let memberTaxTotal = membersTaxes[id].member_owes;

      //The set of GOMS on the order could have changed since the original fetch, consider all GOMs in the store.
      const allGroupOrderMembers = groupOrder.store.peekAll('group-order-member');

      const groupOrderMember = allGroupOrderMembers.findBy('id', id);
      const overBudgetSubtotal = groupOrderMember.get('overBudgetSubtotal');

      if (!groupOrderMember.get('isAdmin') && overBudgetSubtotal > 0) {
        const poopFee = groupOrder.get('payOutOfPocketFee');
        memberTaxTotal += poopFee * areaTaxRate;
      }

      groupOrderMember.set('taxTotal', memberTaxTotal);

      return sum + memberTaxTotal;
    }, 0);

    groupOrder.set('membersTaxTotal', membersTaxTotal);
  }

  /**
   * Calculates and sets companyTaxTotal of groupOrder
   * @param {Object} taxes - APIResponse
   */
  _updateOrderTotals(taxes) {
    const groupOrder = this.get('groupOrder');

    const deliveryFee = groupOrder.get('area.deliveryFee');
    const areaTaxRate = groupOrder.get('area.taxRate.rate');

    const companyTaxTotal = taxes.tax_total + deliveryFee * areaTaxRate;

    groupOrder.set('companyTaxTotal', companyTaxTotal);
  }
}
