import classic from 'ember-classic-decorator';
import Model, { attr } from 'renard/models/foodee';

@classic
export default class Invoice extends Model {
  @attr('object')
  clientInvoice;

  @attr('object')
  restaurantInvoice;
}
