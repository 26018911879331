import classic from 'ember-classic-decorator';
import Service, { inject as service } from '@ember/service';

@classic
export default class NotificationLogsService extends Service {
  @service
  store;

  fetch(uniquenessContext, receiver) {
    return this.get('store').query('notification-log', {
      filter: {
        uniquenessContext: uniquenessContext,
        receiver: receiver
      },
      sort: '-createdAt'
    });
  }
}
