import classic from 'ember-classic-decorator';
import { alias, mapBy, union, uniq, equal } from '@ember/object/computed';
import { attr, belongsTo, hasMany } from 'renard/models/foodee';
import { computed, get } from '@ember/object';
import { modelAction } from 'ember-custom-actions';
import moment from 'moment-timezone';
import Owner from './owner';

import { fragment } from 'ember-data-model-fragments/attributes';
import { inject as service } from '@ember/service';
import { BudgetType } from 'renard/transforms/budget-type';
import ENV from 'star-fox/config/environment';
import { action } from '@ember/object';

@classic
export default class Client extends Owner {
  /*
   * Attributes
   */
  @attr('string')
  globalId;

  @attr('string')
  apiToken;

  @attr('string')
  accountName;

  @attr('boolean')
  isPayingServiceFee;

  @attr('string')
  legalName;

  @attr('string')
  name;

  @attr('string')
  notes;

  @attr('string')
  deliveryNotes;

  @attr('string')
  plan;

  @attr('object')
  features;

  @attr('boolean')
  salesSupportClient;

  @attr('number')
  termDays;

  @attr('boolean')
  terms;

  @attr('object')
  veryLateScore;

  @attr('string')
  cutleryPreference;

  @attr('boolean')
  manageTeams;

  @attr('boolean')
  limitShareNotifications;

  @attr('string')
  uuid;

  @attr('client-state')
  state;

  @attr('boolean')
  recentSignup;

  /** enums: ['A', 'B', 'C', 'D', 'SuperA'] */
  @attr('string')
  actualSegment;

  // accounting
  @attr('date')
  updatedAt;

  @attr('string')
  xeroContactId;

  @attr('date')
  xeroSynchronizedAt;

  @attr('number')
  closedOrderCount;

  @attr('string')
  salesforceId;

  @attr('minimum-order-cost-setting')
  minimumOrderCostSetting;

  @attr('boolean')
  alwaysRetainInDd;

  // waived fees
  @fragment('fragments/accounting/charge', {
    defaultValue: {}
  })
  waivedDeliveryFee;

  @fragment('fragments/accounting/charge', {
    defaultValue: {}
  })
  waivedServiceFee;

  @fragment('fragments/accounting/charge', {
    defaultValue: {}
  })
  globalDriverTip;

  @fragment('fragments/accounting/charge', {
    defaultValue: {}
  })
  globalGhostTip;

  @fragment('fragments/accounting/charge', {
    defaultValue: {}
  })
  waivedDriverTip;

  @fragment('fragments/accounting/charge', {
    defaultValue: {}
  })
  groupOrderMaximumDiscount;

  @fragment('fragments/accounting/service-fee', { defaultValue: {} })
  serviceFee;

  @fragment('fragments/accounting/delivery-fee', { defaultValue: {} })
  deliveryFee;

  /*
   * Relationships
   */
  @hasMany('area', { inverse: 'clients' })
  areas;

  @belongsTo('area')
  defaultArea;

  @belongsTo('user')
  accountManager;

  @hasMany('user')
  admins;

  @hasMany('user', { inverse: 'client' })
  users;

  @belongsTo('user')
  owner;

  @belongsTo('user')
  salesSupport;

  @hasMany('user-invite')
  userInvite;

  @hasMany('team')
  teams;

  @belongsTo('company')
  company;

  @belongsTo('invoicing-tax-rate')
  taxRate;

  @hasMany('payment-card', { inverse: 'client' })
  paymentCards;

  @hasMany('contact')
  adminContacts;

  @hasMany('contact')
  orderContacts;

  @hasMany('contact')
  contacts;

  @hasMany('delivery-location')
  deliveryLocations;

  @belongsTo('delivery-location')
  defaultDeliveryLocation;

  @belongsTo('location')
  billingLocation;

  @belongsTo('contact')
  billingContact;

  @hasMany('giftbit-gift')
  giftbitGifts;

  @belongsTo('meal-plan')
  mealPlan;

  @hasMany('meal-planning-template')
  mealPlanningTemplates;

  @hasMany('meal-planning-instance')
  mealPlanningInstances;

  @hasMany('meal-planning-reservation')
  mealPlanningReservations;

  @belongsTo('meal-planning-preference-profile')
  preferenceProfile;

  @belongsTo('giftbit-card')
  giftbitCard;

  @hasMany('historian-version')
  versions;

  @hasMany('historian-version')
  allClientVersions;

  @hasMany('order')
  orders;

  @hasMany('clientDiscount')
  clientDiscounts;

  @belongsTo('subscription-plan')
  subscriptionPlan;

  @hasMany('notification-log', { inverse: 'receiver' })
  notificationLogs;

  @fragment('fragments/client-storage', {
    defaultValue: {}
  })
  storage;

  @service
  ajax;

  /*
   * Computed Properties
   */

  @alias('accountName')
  label;

  @mapBy('deliveryLocations', 'asFragment')
  fragmentDeliveryLocations;

  @mapBy('possibleOrderContacts', 'asFragment')
  fragmentDeliveryContacts;

  /** @type {Location[]} Maps the location models and assigns names to each from the wrapping DeliveryLocation object */
  @computed('deliveryLocations.@each.location')
  get deliveryLocationsLocations() {
    const locations = this.get('deliveryLocations')
      .mapBy('location.content')
      .filter((_) => _);
    locations.forEach((_, index) =>
      _.set('name', this.get('deliveryLocations').objectAt(index).get('name'))
    );
    return locations;
  }

  /** @type {Contact[]} */
  @union('adminContacts', 'orderContacts')
  possibleOrderContacts;

  /** @type {Contact[]} */
  @uniq('possibleOrderContacts')
  uniquePossibleOrderContacts;

  /** @type {Contact} */
  @computed('owner.contact.isFulfilled', 'adminContacts.[]', 'orderContacts.[]')
  get defaultContact() {
    //most recent should be first object
    return this.get('orderContacts.firstObject') || this.get('adminContacts.firstObject');
  }

  cantDestroy = true;

  @computed('serviceFee.type', 'isEnterprise')
  get serviceFeeTypeLabel() {
    if (this.serviceFee.isConditional) {
      return 'Conditional Service Fee';
    }
    const subscriptionType = this.isEnterprise ? 'Plus' : 'Basic';
    return `Service Fee (${subscriptionType})`;
  }

  @action
  setDefaultFeeSettings() {
    this.serviceFee.amount =
      this.serviceFee.isConditional || !this.isEnterprise
        ? this.serviceFee.defaultValues.BaseRate
        : this.serviceFee.defaultValues.PlusRate;
    this.deliveryFee.active = this.serviceFee.isConditional || !this.isEnterprise;
  }

  /**
   * Resets the api token
   */
  resetApiToken() {
    const modelName = this.constructor.modelName;
    const adapter = this.store.adapterFor(modelName);
    return adapter.resetApiToken(this);
  }

  get firstDeliveryLocation() {
    return this.get('defaultDeliveryLocation').then((defaultDeliveryLocation) =>
      defaultDeliveryLocation
        ? defaultDeliveryLocation.get('location')
        : this.get('deliveryLocations').then((_) => _.get('firstObject.location'))
    );
  }

  @equal('subscriptionPlan.planType', 'enterprise') isSubscriptionPlanEnterprise;
  @equal('plan', 'enterprise') isEnterprise;

  /**
   * Returns true if the provided feature is enabled for the client
   * @param {string} feature
   * @return {boolean}
   */
  hasFeatureEnabled(feature) {
    return (get(this, 'features') ?? {})[feature];
  }

  /**
   * Applies default client settings to the provided order
   *
   * @param {Order} order
   */
  applyDefaultsTo(order) {
    return this.reloadWith(
      'delivery-locations.location,owner.payment-cards,admin-contacts,order-contacts,payment-cards,teams'
    ).then((_) =>
      order.setProperties({
        budgetType: BudgetType.Money,
        cutleryPreference: this.get('cutleryPreference'),
        clientLocation: this.get('deliveryLocations.firstObject.location'),
        owner: this.get('owner'),
        contact: this.get('adminContacts.firstObject'),
        paymentCard: this.get('paymentCards.firstObject'),
        teams:
          this.get('manageTeams') && order.get('isGroupOrder')
            ? [this.get('teams.content.firstObject')]
            : []
      })
    );
  }

  /**
   * TODO generalize this to all records
   *
   * @param {string} include
   */
  reloadWith(include) {
    return this.store.findRecord('client', this.get('id'), {
      reload: true,
      include: include
    });
  }

  /**
   * Checks if a given user is an admin of the client
   * @param {User} user
   */
  isUserAdmin(user) {
    if (!user) {
      return false;
    }

    const userClient = user.get('client.content');
    const isClientAdmin = user.get('isClientAdmin');
    const clientOwner = this.get('owner.content');

    const isUserClientThisClient = userClient === this;
    const isUserOwnerOfThisClient = clientOwner === user;

    return (isUserClientThisClient && isClientAdmin) || isUserOwnerOfThisClient;
  }

  promoteAdminToOwner = modelAction('promote-admin-to-owner', {
    method: 'POST',
    pushToStore: true
  });
  redeemPoints = modelAction('redeem-points', { method: 'POST' });
  rewardPoints = modelAction('reward-points', { method: 'POST' });
  subscribe = modelAction('subscribe', { method: 'POST', pushToStore: true });
  forceOnboarding = modelAction('fire-state-event/force_onboarding', {
    method: 'POST',
    pushToStore: true
  });
  makeActivePlanner = modelAction('fire-state-event/make_active_planner', {
    method: 'POST',
    pushToStore: true
  });
  overrideState = modelAction('override-state', { method: 'POST', pushToStore: true });

  /** @Creates the preference profile */
  createPreferenceProfile = modelAction('create-preference-profile', {
    method: 'POST',
    pushToStore: true
  });

  reInitStripe = modelAction('re-init-stripe', {
    method: 'POST',
    pushToStore: true
  });

  applyGlobalTip = modelAction('apply-global-tip', {
    method: 'POST',
    pushToStore: false
  });

  /**
   * Gets a CSV of the
   * @param {date} startDate
   * @param {date} endDate
   */
  fetchUsageReportCSV(startDate, endDate, detailed = true) {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');

    return this.ajax.post(
      `/api/v3/clients/${this.id}/usage-summary/${start}/${end}?detailed=${detailed}`
    );
  }

  /**
   * Returns the link for a redirect URL to the salesforce record
   * @return {String}
   */
  get salesforceLink() {
    return `${ENV.salesforceHost}/${this.salesforceId}`;
  }

  /*
   * Validations
   */
  validations = {
    areas: {
      presence: true
    },
    xeroContactId: {
      format: {
        with: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
        allowBlank: true,
        message: 'must be a valid uuid'
      }
    }
  };
}
