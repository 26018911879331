import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { empty } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import $ from 'jquery';

@classic
@classNames('fde-team-user-form-for')
@tagName('')
export default class TeamUserFormFor extends Component {
  /** @type {User} */
  teamUser = null;

  /** @type {Team} */
  team = null;

  /** @type {?User[]} */
  searchedUsers = null;

  /** @type {boolean} */
  @empty('teamUser')
  isNew;

  @computed('team', 'teamUser', 'highlightTeamUserForm')
  get highlightedClassName() {
    if (this.get('highlightTeamUserForm')) {
      return 'fde-team-user-form-for_highlighted';
    }
    return '';
  }

  /** @type {boolean} */
  isCreatingTeamUser = false;

  /** @type {Client} */
  client = null;

  /** @type {boolean} */
  readonly = false;

  /** @type {Service} */
  @service
  notify;

  /** @type {string} */
  @computed('teamUser.id')
  get confirmModalId() {
    return `unlink-team-user-${this.get('teamUser.id')}`;
  }

  /** @type {boolean} */
  @computed('team')
  get hideUnlinkUser() {
    return this.get('team.isDefault');
  }

  /** @type {Array} */
  @computed('teamUser')
  get belongsToTeams() {
    return this.get('teamUser.teams').filterBy('isDefault', false).mapBy('name');
  }

  /** @type {?boolean||Number} */
  isSendingInvite = false;

  /** @type {boolean} */
  highlightTeamUserForm = false;

  unlinkTeamUser() {}
  addNewTeamUser() {}
  queryForTeamUsers() {}
  handleAddNewTeamUser() {}
  closeNewTeamUserForm() {}
  promoteTeamUser() {}
  demoteTeamUser() {}
  setNewOwner() {}

  /** @type {boolean} */
  @computed('isNew', 'teamUser', 'client')
  get disableActiveToggle() {
    const isOwner = this.get('client.owner.id') === this.get('teamUser.id');
    return this.get('isNew') || isOwner;
  }

  _closeConfirmModal() {
    $(`.${this.get('confirmModalId')}`).modal('hide');
  }

  @action
  async sendInvite(teamUser, isFoodeePlus) {
    this.set('isSendingInvite', teamUser.get('id'));

    if (isFoodeePlus) {
      await teamUser.sendFoodeePlusInvite(this.get('client'));
      await teamUser.reload();
    } else {
      await teamUser.resendTeamInvite(this.get('team'));
    }

    this.set('isSendingInvite', null);
  }

  @action
  closeConfirmModal() {
    this._closeConfirmModal();
  }

  @action
  handleUnlinkTeamUser() {
    this.unlinkTeamUser(this.get('teamUser'));
    this._closeConfirmModal();
  }

  @action
  confirmUnlinkTeamUser() {
    const modalPath = `.${this.get('confirmModalId')}`;
    $(modalPath)
      .modal({
        context: '.ember-application',
        closable: false
      })
      .modal('show');
  }
}
