import classic from 'ember-classic-decorator';
import Controller, { inject as controller } from '@ember/controller';
import { isEmpty } from '@ember/utils';
import { action, computed } from '@ember/object';
import { task, timeout } from 'ember-concurrency';

@classic
export default class SubscriptionPlansController extends Controller {
  @controller('application')
  applicationController;

  queryParams = ['pageLimit', 'pageOffset', 'sort', 'search'];

  /** @type {Object[]} */
  splitViewStops = [
    { name: 'initial', value: 0 },
    { name: 'initial', value: 400 }
  ];

  rightPaneSize = 0;
  sort = '';
  selectedValues = [];
  pageOffset = 0;
  pageLimit = 10;

  @(task(function* (search) {
    yield timeout(250);

    return yield this.store.query('subscription-plan', {
      filter: {
        search
      },
      page: {
        limit: this.get('pageLimit'),
        offset: this.get('pageOffset')
      },
      sort: isEmpty(this.get('sort')) ? undefined : this.get('sort')
    });
  }).restartable())
  searchTask;

  @computed('model.subscriptionPlansAppConfig.preferences.foodeePlusTrialSubscriptionPlan')
  get trialSubscriptionPlan() {
    const trialPlanId = this.get(
      'model.subscriptionPlansAppConfig.preferences.foodeePlusTrialSubscriptionPlan'
    );
    if (trialPlanId) {
      return this.store.findRecord('subscription-plan', trialPlanId);
    }
    return null;
  }

  @action
  open(selected) {
    if (isEmpty(selected)) {
      this.transitionToRoute('logged-in.admins.subscription-plans');
    } else {
      this.transitionToRoute(
        'logged-in.admins.subscription-plans.edit',
        selected.get('firstObject.id')
      );
    }
  }
}
