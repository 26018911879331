import classic from 'ember-classic-decorator';
import FeedItemBuilder from './base';
import { capitalize } from '@ember/string';

@classic
export default class ClientAdminBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);
    const icon = version.getValue('medium') === 'email' ? 'envelope outline' : 'comment outline';
    const emailType = capitalize(version.getValue('reason') || '');
    const recipients = version.getValue('sentTos');

    feedItem.icon = icon;
    feedItem.subject = `Foxee sent a '${emailType}' email`;
    feedItem.meta = [`To: ${recipients ? recipients.join(', ') : '(No recipients found...?)'}`];

    return feedItem;
  }
}
