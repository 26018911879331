import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class Order extends Application {
  attrs = {
    globalId: {
      serialize: false
    },

    createdAt: {
      serialize: false
    },

    updatedAt: {
      serialize: false
    },

    isGroupOrder: {
      serialize: false
    },

    identifier: {
      serialize: false
    },

    totalAmount: {
      serialize: false
    },

    deliveryEstimate: {
      serialize: false
    },

    deliverOn: {
      serialize: false
    },

    customLocation: {
      serialize: false
    },

    clientLocation: {
      serialize: true
    },

    restaurant: {
      serialize: true
    },

    menu: {
      serialize: false
    },

    restaurantLocation: {
      serialize: true
    },

    clientDiscounts: {
      serialize: false
    },

    restaurantDiscounts: {
      serialize: false
    },
    clientInvoicePdf: {
      serialize: false
    },

    restaurantEmailVersion: {
      serialize: false
    },

    salesSupport: {
      serialize: false
    },

    state: {
      serialize: false
    },

    meta: {
      serialize: false
    },

    restaurantInvoice: {
      serialize: false
    },

    clientInvoice: {
      serialize: false
    },

    requestedCapacity: {
      serialize: false
    },

    syncedWithThirdPartyLogisticsAt: {
      serialize: false
    },

    courier: {
      serialize: false
    },

    event: {
      serialize: false
    },
    eventAsTemplate: {
      serialize: false
    }
  };
}
