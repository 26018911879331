import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias, oneWay } from '@ember/object/computed';
import { action, computed } from '@ember/object';
import Controller from '@ember/controller';

@classic
export default class IndexController extends Controller {
  /** @type {Service} */
  @service
  userSession;

  /** @type {Tag[]} tags */
  tags = [];

  /*
   * Computed properties
   */

  /** @type {String} computes the label text for the create button */
  @computed('isFirstDraftPresent')
  get createButtonText() {
    return this.get('isFirstDraftPresent') ? 'Edit draft' : 'Create one';
  }

  /** @type {Array} */
  @alias('model.dietaryTags')
  dietaryTags;

  /** @type {?Menu} */
  @alias('model.drafts.firstObject')
  isFirstDraftPresent;

  /** @type {Menu} */
  @oneWay('model.menus.firstObject')
  menu;

  /** @type {Restaurant} */
  @oneWay('model.restaurant')
  restaurant;

  /*
   * Private functions
   */

  /**
   * @private
   * _createMenuFromScratch - creates the first menu for this restaurant
   * and returns it
   *
   * @return {Promise<Menu>} the new menu in draft
   */
  _createMenuFromScratch() {
    const { restaurant } = this.get('model');
    const menu = this.store.createRecord('menu', {
      name: 'First menu',
      active: false,
      draft: true,
      restaurant,
      area: restaurant.get('areas.firstObject')
    });

    return menu.save().then(() => menu);
  }

  @action
  duplicateMenu(menu) {
    menu.duplicate();
  }

  @action
  createMenu() {
    const { restaurant } = this.get('model');

    this.store
      .createRecord('menu', {
        name: 'Shiny New Menu!',
        active: false,
        restaurant,
        area: restaurant.get('areas.firstObject')
      })
      .save();
  }

  @action
  editNewMenu() {
    const restaurant = this.get('restaurant');

    if (this.get('isFirstDraftPresent')) {
      const menu = this.get('model.drafts.firstObject');
      this.transitionToRoute('logged-in.restaurants.show.menus.edit', restaurant.id, menu.id);
    } else {
      this.set('isCreatingDraft', true);

      this._createMenuFromScratch().then((menu) => {
        this.transitionToRoute('logged-in.restaurants.show.menus.edit', restaurant.id, menu.id);
        this.set('isCreatingDraft', false);
      });
    }
  }

  @action
  updateActiveMenu() {
    return this.model.restaurant.updateActiveMenu();
  }
}
