import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@classNames('fde-logistics-desk-case')
export default class DeskCase extends Component {
  @service('session')
  session;

  /** @type {Order} Order obj that's passed in from the expanded-row */
  order = null;

  /** @type {DeskCase} new DeskCase obj */
  deskCase = null;

  /**
   * @type {string} Unique ids to prevent multiple modals opening
   * when viewing many expanded rows
   */
  @computed
  get modalId() {
    return `fde-notify-concierge-modal-button ${this.get('elementId')}`;
  }

  /** @type {string} Monitoring notes on the order */
  @alias('order.monitoringNotes')
  monitoringNotes;

  /** @type {number[]} Number of minutes late for a DeskCase */
  caseValues = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

  /** @type {string[]} Requested actions for a DeskCase */
  caseRequests = ['Call Restaurant', 'Call Client'];

  /** @type {string} Changes the label to indicate if it's a delivery or pickup late*/
  @computed('deskCase.caseRequest')
  get delayPlaceholder() {
    const isClientRequest = this.get('deskCase.caseRequest') == 'Call Client';

    return `How long will ${isClientRequest ? 'delivery' : 'the pickup'} be delayed?`;
  }

  @action
  closeModal(modal) {
    modal.get('modal').closeModal(modal, modal.get('id'));
  }

  @action
  resetDeskCase() {
    this.resetDeskCase();
  }

  @action
  setDeskCase() {
    const deskCase = this.get('deskCase');
    const monitoringNotes = this.get('monitoringNotes');
    const order = this.get('order');
    const user = this.get('session.user');

    deskCase.setProperties({
      caseMessage: monitoringNotes,
      order,
      user,
      caseStatus: 'open'
    });
  }
}
