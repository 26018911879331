import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import moment from 'moment-timezone';
import { action } from '@ember/object';

export default Component.extend({
  tagName: '',

  /** @type {*} */
  selection: null,

  /** @type {Service} */
  router: service(),

  /** @type {Client} */
  client: alias('selection'),

  /** @type {QuickSearch} */
  quickSearch: null,

  /** @type {Date} */
  date: moment().add(1, 'day').toDate(),

  makeOrder: action(function () {
    this._makeOrder(false);
  }),

  makeGroupOrder: action(function () {
    this._makeOrder(true);
  }),

  showClientOrders: action(function () {
    const client = this.get('client');
    this.get('quickSearch').hideSearchPanel();

    const router = this.get('router');

    router.transitionTo('logged-in.orders', {
      queryParams: {
        deliver_on_range: undefined,
        client_id: client.get('id') + '-' + client.get('accountName'),
        sort: '-deliverAt'
      }
    });
  }),

  /**
   * Takes us to the new order page, and sets it to the selected Client
   *
   * @param {boolean} isGroupOrder
   */
  _makeOrder(isGroupOrder) {
    // this is async so...
    const clientId = this.get('client.id');
    const deliverAt = this.get('date');
    const restaurantId = null;
    const areaId = this.get('client.areas.firstObject.id');

    this.get('quickSearch').doWhileNavigating(() =>
      this.get('router').transitionTo('logged-in.new-order', {
        queryParams: {
          clientId,
          restaurantId,
          areaId,
          deliverAt,
          isGroupOrder,
          hasMainDetails: true
        }
      })
    );
  },

  createBulkOrders: action(function () {
    const clientId = this.get('client.id');
    const areaId = this.get('client.areas.firstObject.id');

    this.get('quickSearch').doWhileNavigating(() =>
      this.get('router').transitionTo('logged-in.bulk-orders', areaId, {
        queryParams: { clientId }
      })
    );
  })
});
