import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';

import { task, timeout } from 'ember-concurrency';

@classic
@classNames('fde-order-menu-group-item row')
@classNameBindings(
  'active:active',
  'hasPointer:fde-can-click',
  'readonly:readonly',
  'hasMenuOptionGroups:--fde-has-pickles'
)
export default class OrderMenuGroupItem extends Component {
  /** @type {Order} */
  order = null;

  /** @type {boolean} */
  readonly = false;

  /** @type {Object} */
  @alias('order.setMenu')
  setMenu;

  /** @type {boolean} */
  active = false;

  /** @type {MenuItem} */
  menuItem = null;

  /** @type {boolean} whether to apply the .fde-can-click */
  hasPointer = false;

  /** @type {boolean} */
  isSetMenuItem = false;

  /** @type {boolean} */
  setMenuIsEmpty = false;

  /** @type {Requirement} */
  requirement = null;

  /** @type {boolean} */
  search = false;

  /** @type {boolean} */
  @computed('menuItem')
  get menuItemHasNoSingleOpt() {
    return !this.get('menuItem.menuOptionGroups').any((_) => _.get('isSingleOpt'));
  }

  /** @type {boolean} */
  @computed('menuItem.menuOptionGroups.[]')
  get hasMenuOptionGroups() {
    const menuOptionGroups = this.get('menuItem.menuOptionGroups');
    return menuOptionGroups.length && menuOptionGroups.any((_) => _.get('isChoosable'));
  }

  handleSetMenuItemQtyBlur() {}
  handleSetMenuItemQtyClick() {}
  handleSetMenuItemClick() {}
  handleMenuItemClick() {}
  handleOrderItemSave() {}
  handleOrderItemIncrease() {}

  /**
   * Runs as a restartable task to debounce saves to updating order items
   * See: https://ember-concurrency.com/#/docs/task-concurrency for how this works
   */
  @(task(function* () {
    if (!this.get('hasMenuOptionGroups')) {
      yield timeout(600);
      this.handleOrderItemSave(this.get('menuItem'));
    }
  }).restartable())
  deferSave;

  click() {
    const menuItem = this.get('menuItem');
    if (this.get('isSetMenuItem')) {
      this.handleSetMenuItemClick(menuItem);
    } else {
      const isOnSetMenu =
        this.get('setMenuIsEmpty') ||
        this.get('setMenu.setMenuItems').hasOwnProperty(menuItem.get('id'));

      if (isOnSetMenu) {
        this.handleMenuItemClick(menuItem);
        this.get('deferSave').perform();
      }
    }
  }
}
