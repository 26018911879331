import classic from 'ember-classic-decorator';
import RSVP from 'rsvp';
import { run } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';

import { KEYS } from 'renard/models/app-configuration';

// Days of the week
const SATURDAY = 6;
const SUNDAY = 0;

export const ORDER_INDEX_INCLUDE = [
  'area',
  'contact',
  'restaurant',
  'client',
  'restaurant-location',
  'client-location'
].join(',');
export const ORDER_INDEX_FIELDS = {
  clients: 'account-name',
  restaurants: 'name',
  areas: 'city',
  orders: [
    ORDER_INDEX_INCLUDE,
    'alert-status',
    'bookmark-expires-at',
    'bookmark-name',
    'deliver-at',
    'deliver-on',
    'deadline-at',
    'deadline-is-same-day',
    'identifier',
    'is-group-order',
    'is-asap',
    'number-of-people',
    'order-type',
    'pickup-at',
    'total-amount',
    'flag',
    'state',
    'storage',
    'order-images',
    'client-total',
    'restaurant-total'
  ].join(',')
};

@classic
export default class OrderServiceService extends Service {
  /** @type {Service} */
  @service
  store;

  /** @type {string} */
  lastFetchedOrderQuery = null;

  /**
   * Fetches the orders
   * @param {object} params
   * @return {Promise.<Order[]>}
   */
  fetchOrders(params) {
    const store = this.get('store');

    const {
      pageLimit,
      pageOffset,
      sort,
      areas,
      deliverAt,
      filterDate,
      search,
      isBookmark,
      isTemplate,
      sameDayAs
    } = params;

    this.set('lastFetchedOrderQuery', deliverAt || filterDate);

    return store.query('order', {
      page: {
        limit: pageLimit || 300,
        offset: pageOffset
      },

      filter: {
        areas,
        search,
        sameDayAs,
        deliverOn: sameDayAs ? undefined : filterDate || deliverAt,
        isBookmark: isBookmark || false,
        isTemplate: isTemplate || false,
        rejectOnlineDraft: true
      },
      sort: sort,
      include: ORDER_INDEX_INCLUDE,
      fields: ORDER_INDEX_FIELDS
    });
  }

  /**
   * Fetches the orders
   * @param {object} params
   * @return {Promise.<Order[]>}
   */
  filterOrders(params, filterParams) {
    const store = this.get('store');

    const { pageLimit, pageOffset, sort, search } = params;

    return store.query('order', {
      page: {
        limit: pageLimit || 300,
        offset: pageOffset
      },
      filter: search ? { search } : filterParams,
      sort: sort,
      include: ORDER_INDEX_INCLUDE,
      fields: ORDER_INDEX_FIELDS
    });
  }

  /**
   * Updates order details with client defaults
   * @param {Order} order
   * @param {Client} client
   * @return {Promise.<Order>}
   */
  updateOrderDetailsWithClientDefaults(order, client) {
    if (!client) {
      order.setProperties({
        owner: null,
        clientLocation: null,
        paymentCard: null,
        contact: null
      });
      return RSVP.resolve(order);
    }

    return RSVP.hash({
      owner: client.get('owner').then((_) => order.set('owner', _)),

      defaultDeliveryLocation: client
        .get('firstDeliveryLocation')
        .then((location) => run.next((_) => order.set('clientLocation', location))),

      defaultPaymentCard: client.get('paymentCards').then((paymentCards) => {
        const defaultPaymentCard =
          paymentCards.get('length') > 1 || client.get('terms')
            ? null
            : paymentCards.get('firstObject');
        order.set('paymentCard', defaultPaymentCard);
      })
    })
      .then((_) => run.next((_) => order.set('contact', client.get('defaultContact'))))
      .then((_) => order);
  }

  /**
   * Updates order details with restaurant defaults: menu and location.
   * @param {Order} order the current order
   * @param {Restaurant} restaurant the newly assigned restaurant
   * @return {Promise.<Order>} a promise that resolves to the updated order
   */
  updateOrderDetailsWithRestaurantDefaults(order, restaurant) {
    if (!restaurant) {
      order.setProperties({
        restaurantLocation: null
      });

      return RSVP.resolve(order);
    }

    return RSVP.hash({
      restaurantLocation: restaurant.get('firstPickupLocation')
    })
      .then((properties) => order.setProperties(properties))
      .then(() => order);
  }

  /**
   * Updates order details with restaurant defaults
   * @param {Order} order
   * @return {Promise.<boolean>}
   */
  isOutsideHours(deliverAt) {
    const deliverAtHour = deliverAt.hours();

    return this.get('store')
      .query('app-configuration', { filter: { key: KEYS.serviceFeeParams } })
      .then((appConfiguration) => {
        const dayStart = appConfiguration.get('firstObject.preferences.dayStart');
        const dayEnd = appConfiguration.get('firstObject.preferences.dayEnd');

        const deliveryOusideWorkingHours = !(dayStart < deliverAtHour && deliverAtHour < dayEnd);
        const deliveryOnWeekend = deliverAt.day() === SATURDAY || deliverAt.day() === SUNDAY;

        return deliveryOnWeekend || deliveryOusideWorkingHours;
      });
  }
}
