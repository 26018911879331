import Service, { inject as service } from '@ember/service';
import { get } from '@ember/object';
import Evented from '@ember/object/evented';
import generateGuid from 'star-fox/utils/generate-guid';

import ENV from 'star-fox/config/environment';

/**
 * @class UserSessionService
 */
export default class UserSessionService extends Service.extend(Evented) {
  /** @type {Ajax} */
  @service ajax;

  /** @type {AppState} */
  @service session;

  @service datadog;

  sessionId = generateGuid();

  get user() {
    return this.session.user;
  }

  constructor() {
    super(...arguments);

    if (ENV.environment !== 'test') {
      this.datadog.setup(ENV.datadog, this);
    }
  }

  forgotPassword(email) {
    const ajax = get(this, 'ajax');

    return ajax.request(`/api/v3/users/password`, {
      data: { user: { email: email } },
      type: 'POST'
    });
  }
}
