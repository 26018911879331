import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

import FilterCollection from 'star-fox/supports/filters/filter-collection';
import Filter from 'star-fox/supports/filters/filter';

import PusherBindingsMixin from 'star-fox/mixins/pusher-bindings-mixin';

import { task, timeout } from 'ember-concurrency';

export const availableFilters = [
  {
    id: 'order_states',
    label: 'Order States',
    icon: 'cart',
    single: true,
    key: 'stateChanges',
    value: [true],

    // override the standard behavior
    filter(version) {
      return !!version.getValue('state');
    }
  },
  {
    id: 'no_foxee',
    label: 'No Foxee',
    icon: 'hand scissors',

    key: 'noFoxee',
    value: [true],

    // override the standard behavior
    filter(version) {
      return version.email != 'foxee@food.ee';
    }
  },
  {
    id: 'item_type_area',
    key: 'itemType',
    value: ['Area', 'AreaClosure'],
    label: 'Area',
    icon: 'map'
  },
  {
    id: 'item_type_client',
    key: 'itemType',
    value: ['Client'],
    label: 'Clients',
    icon: 'building'
  },
  {
    id: 'item_type_order',
    key: 'itemType',
    value: ['Order'],
    label: 'Order',
    icon: 'cart'
  },
  {
    id: 'item_type_order_items',
    key: 'itemType',
    value: ['Menu', 'MenuItem', 'MenuOptionItem'],
    label: 'Menu',
    icon: 'food'
  },
  {
    key: 'itemType',
    value: ['OrderItem', 'OrderItemOption'],
    label: 'Order Items',
    icon: 'cart'
  },
  {
    id: 'item_type_email_messages',
    key: 'itemType',
    value: ['EmailLogs::Messages::Order'],
    label: 'Email Messages',
    icon: 'paper plane'
  },
  {
    id: 'item_type_email_events',
    key: 'itemType',
    value: ['EmailLogs::Events::Order'],
    label: 'Email Events',
    icon: 'paper plane'
  },
  {
    key: 'item_type_restaurants',
    value: ['Restaurant', 'RestaurantClosure'],
    label: 'Restaurants',
    icon: 'utensils'
  },
  {
    id: 'item_type_teams_and_users',
    key: 'itemType',
    value: ['GroupOrderMember', 'User', 'TeamOrder', 'UserOrder'],
    label: 'Teams & Users',
    icon: 'users'
  }
];

@classic
@classNames('fde-versions-feed ui feed')
export default class ActivityFeed extends Component.extend(PusherBindingsMixin) {
  /** @type {PusherService} **/
  @service
  pusher;

  /** @type {ActionCableService} **/
  @service
  actionCable;

  /** @type {UserSessionService} **/
  @service
  userSession;

  /** @type {DS.Store} **/
  @service
  store;

  /** @type {string} **/
  sourceKey = 'versions';

  /** @type {DS.Store | DS.Model} **/
  source = null;

  /** @type {String} */
  route = '';

  /** @type {number} */
  currentPage = 0;

  /** @type {number} */
  perPage = 50;

  /** @type {number} */
  scrollBottomTolerance = 20;

  /** @type {number} */
  recordCount = 0;

  /** @type {FeedItem[]} */
  feedItems = null;

  /** @type {string} selector to monitor for scrolling */
  scrollTarget = null;

  /** @type {boolean} */
  useFilters = true;

  /** @type {boolean} */
  useSearch = true;

  /** @type {string} */
  include = undefined;

  /** @type {string} */
  sort = undefined;

  /** @type {Element} element to monitor for scrolling */
  @computed('scrollTarget')
  get _scrollDiv() {
    const scrollTarget = this.get('scrollTarget');
    return scrollTarget ? $(scrollTarget) : this.$();
  }

  /** @type {boolean} */
  @computed('feedItems.length', 'recordCount')
  get canLoadMore() {
    const length = this.get('feedItems.length');
    return length > 0 && length < this.get('recordCount');
  }

  /** @type {boolean} */
  @computed('userSession.session.user')
  get useNewPubSub() {
    return this.userSession.session.user?.hasFeatureEnabled('newPubSub') ?? false;
  }

  /** @type {string} */
  @computed('useNewPubSub')
  get pubSub() {
    return this.useNewPubSub
      ? { service: this.actionCable, name: 'cable' }
      : { service: this.pusher, name: 'pusher' };
  }

  /** @type {string[]} */
  versionChannels = null;

  /** @type {string[]} */
  versionEvents = ['pusher:subscription_succeeded', 'created'];

  /** @type {boolean} */
  isLoadingMore = true;

  /** @type {string} where to store configuration about this feed */
  storageKey = null;

  /** @type {string} filters that are selected initially */
  selectedFilters = [];

  /** @type {string} A way to explicitly set the JR filter */
  jrFilter = null;

  /** @type {User} Filter the search results by a user who might have `dunnit` */
  whodunnit = null;

  /** @override */
  didReceiveAttrs() {
    super.didReceiveAttrs();
    this._tearDownPubSub();
    this._setupPubSub();
    this.reloadFirstPage();
  }

  /** @type {Object[]} filter definitions */
  availableFilters = availableFilters;

  didInsertElement() {
    super.didInsertElement(...arguments);

    this._setupFilters();
    this._setupScrollWatcher();
    this._setupPubSub();
    this._fetchData(this.get('currentPage'));
  }

  @(task(function* (searchText) {
    yield timeout(300);
    this.set('searchText', isEmpty(searchText) ? null : searchText);
    yield this.reloadFirstPage();
  }).restartable())
  searchVersion;

  @(task(function* (user) {
    this.set('whodunnit', user);
    yield this.reloadFirstPage();
  }).restartable())
  searchVersionForWhodunnit;

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    // remove event listeners
    this.get('_scrollDiv').off();
    this._tearDownPubSub();
  }

  /**
   * Reloads the first page of versions
   */
  reloadFirstPage() {
    this.setProperties({
      currentPage: 0,
      feedItems: []
    });

    this._fetchData(this.get('currentPage'));
  }

  /**
   * Loads the first page of versions and resets the current page counter based
   * on the total record count
   *
   * @param {FeedItem} feedItem to push to the head
   */
  _pushFeedItem(feedItem) {
    feedItem.isNew = true;

    // have to use ember's array unshift so that things re draw
    this.get('feedItems').unshiftObject(feedItem);
    // reset the current page so we can scroll properly
    this.set('page', this.get('feedItems.length') % this.get('perPage'));
  }

  /**
   * Loads the next page of versions
   * @private
   */
  _loadNextPage() {
    if (this.get('canLoadMore') && !this.get('isLoadingMore')) {
      this.incrementProperty('currentPage');
      this._fetchData(this.get('currentPage'));
    }
  }

  /**
   * Mounts the scroll watcher
   * @private
   */
  _setupScrollWatcher() {
    const scrollBottomTolerance = this.get('scrollBottomTolerance');
    this.get('_scrollDiv').scroll((e) => {
      const scrollBottom = e.target.scrollHeight - e.target.clientHeight;

      if (e.target.scrollTop + scrollBottomTolerance >= scrollBottom) {
        console.debug('[activity-feed] Reached Scroll bottom');

        this._loadNextPage();
      }
    });
  }

  /**
   * Sets up filters
   * @private
   */
  _setupFilters() {
    if (this.useFilters) {
      const filterCollection = FilterCollection.create({
        storageKey: `activity-feed:filters:${this.get('storageKey')}`,
        filters: this.get('availableFilters').map((_) => Filter.create(_)),
        isStaticFilterList: true,
        includeAllFilter: true,
        onChange: () => this.reloadFirstPage()
      });

      this.set('filters', filterCollection);

      this.get('selectedFilters').forEach((_) =>
        filterCollection.toggleFilter(filterCollection.get('filters').findBy('id', _))
      );
    }
  }

  /**
   * Sets up the private pubSub channel for the feed
   * @private
   */
  _setupPubSub() {
    const versionChannels = this.get('versionChannels');
    this.set('prevVersionChannels', versionChannels);

    if (versionChannels) {
      versionChannels.forEach((channel) => {
        this.pubSub.service.wire(this, `private-${channel}.versions`, this.versionEvents);
      });
    }
  }

  /**
   * Tears down the private pubSub channel for the feed
   * @private
   */
  _tearDownPubSub() {
    const versionChannels = this.get('prevVersionChannels');

    if (versionChannels) {
      versionChannels.forEach((channel) => {
        this.pubSub.service.unwire(this, `private-${channel}.versions`, this.versionEvents);
      });
    }
  }

  get filterForQuery() {
    let filter;

    if (!this.useFilters) {
      return filter;
    }

    filter = this.get('jrFilter') || this.get('filters.asJR');
    filter = isEmpty(this.get('searchText'))
      ? filter
      : Object.assign({ search: this.get('searchText') }, filter);
    filter = isEmpty(this.get('whodunnit'))
      ? filter
      : Object.assign({ whodunnit: this.get('whodunnit.id') }, filter);

    return filter;
  }

  /**
   * Fetches data based on the page, maps them to feed items, and then adds them to the
   * current list
   * @param {number} page page number we want to get
   * @returns {Promise}
   * @private
   */
  _fetchData(page) {
    console.debug(`[activity-feed] Fetching new data page: ${page}`);
    const perPage = this.get('perPage');

    this.set('isLoadingMore', true);

    return this.get('source')
      .query(this.get('sourceKey'), {
        page: {
          limit: perPage,
          offset: page * perPage
        },
        filter: this.filterForQuery,
        include: this.include,
        sort: this.sort
      })
      .then((data) => {
        const newFeedItems = data.mapBy('feedItem').map((feedItem) => {
          feedItem.isNew = false;

          return feedItem;
        });
        const currentFeedItems = this.get('feedItems') || [];
        const feedItems = currentFeedItems.concat(newFeedItems);

        // any time we fetch items we need to update the total record count
        this.set('recordCount', data.get('meta.recordCount'));

        // only uniq ones to keep things sane
        this.set('feedItems', feedItems.uniqBy('id'));
      })
      .finally(() => this.set('isLoadingMore', false));
  }

  @action
  pusher__subscriptionSucceeded() {
    console.debug(
      `[activity-feed ${this.pubSub}] Connected to versions channels ${this.get(
        'versionChannels'
      ).join(',')}`
    );
  }

  @action
  created(payload) {
    console.debug(`[activity-feed ${this.pubSub}] New Feed Event`, payload);
    const store = this.get('store');
    store.pushPayload(payload);

    const newVersion = store.peekRecord('historian-version', payload.data.id);

    // if the filters allow for this object to be pushed, lets doit
    if (this.get('filters').filterObject(newVersion)) {
      this._pushFeedItem(newVersion.get('feedItem'));
      this.incrementProperty('recordCount');
    }
  }
}
