import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';

@classic
export default class PromoCodesRoute extends Route.extend(
  AuthenticatedRouteMixin,
  LoadingRouteMixin
) {
  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    showExpired: { refreshModel: true },
    sort: { refreshModel: true },
    searchText: { refreshModel: true }
  };

  model(params) {
    const { pageLimit, pageOffset, showExpired, searchText, sort } = params;

    return this.store.query('promo-code', {
      include: 'area,restaurant',
      sort: sort,
      page: {
        limit: pageLimit,
        offset: pageOffset
      },
      filter: {
        expired: showExpired,
        search: searchText
      }
    });
  }

  /**
   * Areas and Restaurants are lazily loaded after the initial PC fetch.
   *
   * @param controller
   * @param model
   */
  setupController(controller, model) {
    super.setupController(controller, model);

    this.store
      .query('area', {
        page: {
          limit: 100,
          offset: 0
        },
        reload: true
      })
      .then((areas) => controller.set('areas', areas));

    this.store
      .findAll('restaurant', { reload: true })
      .then((restaurants) => controller.set('restaurants', restaurants));

    this.store
      .findAll('discount-code')
      .then((discountCodes) => controller.set('discountCodes', discountCodes));
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
