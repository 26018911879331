import classic from 'ember-classic-decorator';
import Component from '@ember/component';

import { action } from '@ember/object';

@classic
export default class InputControl extends Component {
  /** @type {Filter} */
  filter = null;

  @action
  handleOnChange(value) {
    console.debug(value);

    const filter = this.get('filter');
    filter.setValue([value], [value]);
  }
}
