import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';

@classic
@classNames('fde-invite-team-users-form-for')
@tagName('')
export default class InviteTeamUsersFormFor extends Component {
  /** @type {Service} */
  @service
  store;

  /** @type {Service} */
  @service
  notify;

  /** @type {boolean} */
  readonly = null;

  /** @type {Object} */
  newUsers = null;

  /** @type {boolean} */
  showInviteTeamUsersForm = false;

  /** @type {boolean} */
  isSubmittingUserInvites = false;

  /** @type {boolean} */
  showMigratedWarning = false;

  /** @type {string} */
  buttonText = 'Invite users';

  /** @type {boolean} */
  invalidEmailsPresent = false;

  /** @type {Array} */
  failedUserInvites = null;

  /** @type {Array} */
  emails = [];

  /** @type {Function} */
  onSubmit() {}

  /** @type {Function} */
  didSubmit() {}

  /** @type {Function} */
  onCloseMigratedWarning() {}

  /** @type {boolean} */
  emailsIsEmpty = true;

  /** Sets an appropriate error message to explain the invalid elements.
   * In practice, badSyntaxEmails will be removed before submit and failedUserInvites
   * will be removed after submit.
   */
  _buildErrorMessage() {
    const badSyntaxEmails = this.get('badSyntaxEmails.firstObject');
    const failedUserInvites = this._currentFailedUserInvites();
    const failedUserInvitesPresent = failedUserInvites && failedUserInvites.length > 0;

    let message = '';

    //need the length checks when property has been initialized but is now empty
    //(so the error disappears when the invalid elements are removed)

    if (badSyntaxEmails) {
      message += ' Some emails have illegal syntax.';
    }

    if (failedUserInvitesPresent) {
      message += ' Some users already belong to this or another client.';
    }

    if (message) {
      return message + ' Please remove invalid emails before sending the invites. ';
    }

    return message;
  }

  /**
   * creates an errors object for the newUsers model
   */
  _setFormErrors(invalidEmails) {
    const model = this.get('newUsers');

    model.set('errors.emails', invalidEmails);
    this.set('errorMessage', this._buildErrorMessage());
  }

  /** @type {?string[]}
   * Checks if any emails currently in the form have failed the previous submit.
   * Invites fail on submit if the user already belongs to another client.
   */
  _currentFailedUserInvites() {
    const failedUserInvites = this.get('failedUserInvites');
    const userEmails = this.get('newUsers.emails');

    if (userEmails && failedUserInvites) {
      return userEmails.filter((email) => failedUserInvites.indexOf(email) >= 0);
    }
  }

  /** @type {?string[]} emails that fail a regex check before submit */
  @computed('newUsers', 'newUsers.emails.[]', 'newUsers.emails')
  get badSyntaxEmails() {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const userEmails = this.get('newUsers.emails');

    if (userEmails) {
      return userEmails.filter((email) => !emailRegex.test(email) && email != '');
    }

    return [];
  }

  /** @type {?string[]}
   * Composite of badSyntaxEmails and failedUserInvites
   */
  @computed('badSyntaxEmails', 'badSyntaxEmails.[]', 'failedUserInvites', 'failedUserInvites.[]')
  get invalidEmails() {
    let errorsArray = [];

    const badSyntaxEmails = this.get('badSyntaxEmails');
    const currentFailedUserInvites = this._currentFailedUserInvites();

    return errorsArray
      .concat(badSyntaxEmails)
      .concat(currentFailedUserInvites)
      .filter((e) => e != undefined);
  }

  /**
   * observes the email values and changes the form state when invalid
   * values are present
   */
  @observes('newUsers.emails.[]', 'newUsers.emails', 'failedUserInvites', 'failedUserInvites.[]')
  validateEmails() {
    const emailsIsEmpty = this.get('newUsers.emails.length') < 1;

    const invalidEmails = this.get('invalidEmails');

    const invalidEmailsPresent = this.get('invalidEmails.length') > 0;

    const buttonText = invalidEmailsPresent ? 'Some emails are not valid' : 'Invite users';

    this.setProperties({
      invalidEmailsPresent,
      buttonText,
      emailsIsEmpty
    });

    this._setFormErrors(invalidEmails);
  }
}
