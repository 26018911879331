import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class DiversityRoute extends Route.extend(LoadingRouteMixin, CapabilityRouteMixin) {
  capability = 'TAGS_INDEX';

  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    searchText: { refreshModel: true },
    sort: { refreshModel: true }
  };

  model(params) {
    const { pageLimit, pageOffset, searchText, sort } = params;

    return RSVP.hash({
      tags: this.store.query('tag', {
        page: {
          limit: pageLimit,
          offset: pageOffset
        },
        filter: {
          search: searchText,
          tagType: 'diversity'
        },
        sort: sort
      })
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
