import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@classNames('fde-phone-number-control')
export default class PhoneNumberControl extends Component {
  /** @type {AjaxService} */
  @service('ajax')
  ajaxService;

  @service
  session;

  /** @type {boolean} if this input control is read only */
  readonly = false;

  /** @type {boolean} if this input control sends change events on input */
  live = true;

  /** @type {string} Raw phone number value */
  value = null;

  /** @type {string} Formatted phone number value */
  /** @deprecated */
  @computed('value')
  get phoneNumberValue() {
    const $input = this.get('$input');
    return ($input && $input.masked(this.get('value'))) || this.get('value');
  }

  onCommit() {}
  onChange() {}

  /** @type {jQuery} */
  $input = null;

  internationalTelInput = null;

  // Uses intl-tel-input as the main control
  didInsertElement() {
    super.didInsertElement(...arguments);

    const input = this.element.querySelector('input');

    const internationalTelInput = intlTelInput(input, {
      autoHideDialCode: true,
      nationalMode: true,
      autoPlaceholder: 'aggressive',
      onlyCountries: ['us', 'ca'],
      initialCountry: 'auto',
      geoIpLookup: (success) => this._findClientCountry(success)
    });

    this.set('internationalTelInput', internationalTelInput);
    this.get('internationalTelInput').setNumber(this.get('value') || '');
  }

  /**
   * Refreshes formatting, if the number is valid.
   */
  didRender() {
    super.didRender(...arguments);

    if (this.get('value')) {
      this.get('internationalTelInput').setNumber(this.get('value'));
    }
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.get('internationalTelInput').destroy();
  }

  /**
   * Internal
   */

  /**
   * Finds client country from IP, to decide which flag to use.
   *
   * @param  {Function} success
   */
  async _findClientCountry(success) {
    try {
      const resp = await this.get('ajaxService').request('https://ipinfo.io', {
        method: 'GET',
        dataType: 'jsonp'
      });

      return success(resp.countryCode || 'us');
    } catch (error) {
      console.debug(error);
      return success('us');
    }
  }

  @action
  handleChange(e) {
    // Stripping special characters out of phone number.
    const phoneNumber = e.target.value.replace(/\D/g, '');
    console.debug('sf/controls/phone-number#handleChange', phoneNumber);
    this.onChange(phoneNumber);
  }

  @action
  handleInput(e) {
    this.get('internationalTelInput').setNumber(e.target.value);
    if (this.live) {
      this.onChange(this.get('internationalTelInput').getNumber());
    }
  }

  @action
  handleBlur(e) {
    this.get('internationalTelInput').setNumber(e.target.value);
    this.onCommit(this.get('internationalTelInput').getNumber());
  }
}
