import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { run } from '@ember/runloop';

import ServiceAreaCheckerMixin from 'star-fox/mixins/service-area-checker-mixin';

@classic
@classNames('fde-location-form')
export default class LocationFormFor extends Component.extend(ServiceAreaCheckerMixin) {
  /** @type {LocationService} */
  @service('location')
  locationService;

  /** @type {Location} */
  model = null;

  /** @type {boolean} whether or not we show the map on the location create or edit */
  showMap = true;

  /** @type {boolean} whether or not we show the labels on the forms */
  hideLabels = false;

  /** @type {boolean} whether or not we should show the the delivery fields (unit, buzzer, floor) */
  showDeliveryFields = true;

  /** @type {boolean} whether or not this location is Out-of-Zone */
  outOfZone = false;

  /** @type {boolean} whether or not this location has a name (Delivery/Pickup locations) */
  locationHasName = false;

  /** @type {?string} Providing a city name will bias the search results to a specific city */
  cityBias = null;

  selectedPlace = false;

  /** @type {boolean} whether or not we show the location form override for CC forms */
  @computed('model.isNew', 'selectedPlace')
  get showLocationForm() {
    return !this.get('model.isNew') || this.get('selectedPlace');
  }

  /** @type {string} What we label the address code */
  @computed('model.country')
  get addressCodeLabel() {
    return this.get('model.country') === 'US' ? 'Zip Code*' : 'Postal Code*';
  }

  /** @type {string} Search string for the addresses */
  searchText = '';

  /**
   * @callback didSaveHandler
   * @params {object} model Model that did save
   *
   * @type {didSaveHandler} Handler for the the didSave action
   */
  didSubmit() {}

  /**
   * @callback didReset
   *
   * @type {didReset} Handler for the the didReset action
   */
  didReset() {}

  /** @type {number} Here for overriding for testing purposes */
  debounceTime = 400;

  doSearchForLocation() {
    const searchText = this.get('searchText');

    if (searchText) {
      this.toggleProperty('isSearching');
      this.get('locationService')
        .suggestPlaces(searchText, this.get('cityBias'))
        .then((_) => {
          this.set('suggestions', _);
          this.toggleProperty('isSearching');
        });
    }
  }

  @action
  searchForLocation(searchText) {
    this.set('searchText', searchText);

    // prevent multiple search
    if (this.get('debounceTime') > 0) {
      run.debounce(this, this.doSearchForLocation, this.get('debounceTime'));
    } else {
      //Handy for avoiding quirky async behavior on integration tests
      this.doSearchForLocation();
    }
  }

  @action
  selectSuggestion(suggestion) {
    this.set('searchText', '');
    this.get('locationService')
      .lookupAddressByPlaceId(suggestion.placeId)
      .then((address) => {
        this.get('model').setProperties(address);
        this.set('selectedPlace', true);

        // note that the model doesn't have gmapsLocation
        const formattedAddr = `${address.building} ${address.street}, ${address.city}, ${address.country}`;

        this.addressOutOfZone(formattedAddr).then((ooz) => {
          this.set('outOfZone', ooz);
        });
      });
  }

  @action
  resetLocation() {
    this.setProperties({
      suggestions: [],
      selectedPlace: false
    });

    this.get('model').setProperties({
      unitNumber: '',
      foor: '',
      buzzer: ''
    });
  }
}
