import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import Component from '@ember/component';
import RSVP from 'rsvp';
import moment from 'moment-timezone';
import { task } from 'ember-concurrency';
import { flashSet } from 'star-fox/utils/ember-object-setters';
import { CutleryPreference } from 'renard/transforms/cutlery-preference';
import { union, filterBy } from 'macro-decorators';
import { FeeType } from 'renard/transforms/fee-type';

export const OrderFormForTabs = {
  TEAM_ORDER_TAB: 'teamOrderTab',
  DISCOUNT_TAB: 'discountTab',
  NOTES_TAB: 'notesTab'
};

@classic
@classNames('fde-order-form-for')
export default class OrderFormFor extends Component {
  /** @type {Store} */
  @service
  store;

  /** @type {Notify} */
  @service
  notify;

  /** @type {UserSession} */
  @service
  userSession;

  /** @type {Area} */
  area = null;

  /** @type {AreaClosures[]} */
  areaClosures = null;

  /** @type {boolean} Whether or not we copy the order attributes once it's been successfully saved */
  copyOnSave = true;

  /** @type {Client[]} clients to choose from */
  clients = null;

  /** @type {Restaurant[]} restaurants to choose from */
  restaurants = null;

  /** @type {Order} the order we are currently creating */
  order = null;

  /** @type {Restaurant[]} */
  searchedRestaurants = null;

  /** @type {Client[]} */
  searchedClients = null;

  /** @type {boolean} */
  adjustedDeadlineAt = false;

  /** @type {Contact[]} */
  clientAdminContacts = null;

  /** @type {boolean} */
  disableRestaurant = false;

  /** @type {boolean} */
  disableNumberOfPeople = false;

  /** @type {boolean} */
  disableEventName = false;

  /** @type {boolean} */
  disableDeliverAt = false;

  /** @type {boolean} */
  disableDeadlineAt = false;

  /** @type {boolean} */
  hideClient = false;

  /** @type {boolean} */
  hideDeliverAt = false;

  /** @type {boolean} */
  hideEventName = false;

  /** @type {boolean} */
  hideArea = false;

  /** @type {boolean} */
  hideRestaurants = false;

  /** @type {FormFor} */
  parentForm = null;

  /** @type {boolean} */
  addOrderSubmit = false;

  /** @type {boolean} */
  showMenuFilterControls = false;

  /** @type {String} */
  activeTab = OrderFormForTabs.TEAM_ORDER_TAB;

  /** @type {DiscountCode[]} */
  discountCodes = null;

  /** @type {String} */
  TEAM_ORDER_TAB = OrderFormForTabs.TEAM_ORDER_TAB;

  /** @type {String} */
  NOTES_TAB = OrderFormForTabs.NOTES_TAB;

  /** @type {String} */
  DISCOUNT_TAB = OrderFormForTabs.DISCOUNT_TAB;

  /** @type {String[]} */
  cutleryPreferenceTypes = CutleryPreference.values;

  @union('order.client.paymentCards', 'order.owner.paymentCards') usablePaymentCards;
  @filterBy('usablePaymentCards', 'isNew', false) paymentCardOptions;

  serviceFeeMapping = {
    'serviceFee.amount': 'amount',
    'serviceFee.amountType': 'amountType'
  };

  feeTypeValues = [FeeType.Conditional, FeeType.Flat];

  /** @override */
  didInsertElement() {
    super.didInsertElement(...arguments);
    if (!this.get('order')) {
      this.setupNewOrder();
    }

    const client = this.get('client');
    if (client) {
      // Set the client if there is one present
      this.set('order.client', client);

      // Probably hasn't been setup yet
      if (!this.get('order.clientLocation')) {
        this.setupFormForClient();
      }
    }
  }

  /**
   * Setups a new order to be edited using the area
   */
  setupNewOrder() {
    this.set(
      'order',
      this.get('store').createRecord('order', {
        area: this.get('area'),
        deliverAt: moment()
          .add(1, 'day')
          .set('minute', 0)
          .set('second', 0)
          .set('millisecond', 0)
          .toDate(),
        deadlineAt: moment()
          .add(1, 'day')
          .set('minute', 0)
          .set('second', 0)
          .set('millisecond', 0)
          .toDate(),
        notes: {
          clientNotes: '',
          accountingNotes: '',
          restaurantNotes: '',
          courierNotes: '',
          internalNotes: '',
          deliveryNotes: ''
        }
      })
    );

    this.setProperties({
      paymentCards: null,
      deliveryLocations: null,
      teams: null,
      restaurantLocations: null,
      restaurantClosures: null,
      restaurantServiceTimes: null
    });
  }

  /**
   * Resets the form with a copy of the existing order
   */
  resetWithCopyOfOrder() {
    this.set('order', this.get('order').copy());
  }

  @computed('order.isTemplate', 'order.serviceFee.type', 'client.isEnterprise')
  get canChangeServiceFee() {
    return (
      !this.get('order.isTemplate') ||
      (this.get('order.serviceFee.isFlat') && this.get('order.client.isEnterprise'))
    );
  }

  /**
   * Sets up the form for use with the selected client this is run after a form change to
   * the client
   */
  @action
  setupFormForClient() {
    const client = this.get('order.client');

    if (client) {
      this.set('isLoading', true);
      client
        .applyDefaultsTo(this.get('order'))
        .then((_) => this.set('clientAdminContacts', client.get('adminContacts').toArray()))
        .finally((_) => this.set('isLoading', false));
    }
  }

  /**
   * Sets up the form for use with the selected client, run after a form change to the client
   */
  @action
  setupFormForRestaurant() {
    const restaurant = this.get('order.restaurant');

    if (restaurant) {
      this.set('isLoading', true);

      restaurant
        .reloadWith('closures,service-times,pickup-locations.location')
        .then((restaurant) => {
          this.get('order').setProperties({
            restaurantLocation: restaurant.get('pickupLocations.firstObject.location')
          });
        })
        .finally((_) => this.set('isLoading', false));
    }
  }

  @action
  doAddOrderSubmit() {
    const order = this.get('order');

    const teams = order.get('teams.content');
    teams &&
      RSVP.Promise.all(teams.map((_) => order.addTeam(_))).catch((_) =>
        this.get('notify').error(
          `Problem adding team(s) for order ${order.get('identifier')}. ${_}`
        )
      );

    this.get('notify').success(`Created Order ${order.get('identifier')}`);

    if (this.get('copyOnSave')) {
      this.resetWithCopyOfOrder();
    } else {
      this.setupNewOrder();
    }
  }

  @task(function* () {
    const discountCodes = yield this.get('store').findAll('discount-code');
    this.set('discountCodes', discountCodes);
    return discountCodes;
  })
  loadDiscountCodesTask;

  didSaveDiscount() {}
  didSubmit() {}

  @action
  handleDidSubmit() {
    this.didSubmit(this.get('order'));

    if (this.get('addOrderSubmit')) {
      this.doAddOrderSubmit();
    }
  }

  @action
  handleWillSubmit(order) {
    if (!order.get('isGroupOrder') && order.get('perPersonBudget') === 0) {
      order.set('perPersonBudget', null);
    }
    return order;
  }

  @action
  handleIsGroupOrderDidCommit(value) {
    const order = this.get('order');
    value && order.resetDeadlineAtRelativeToDeliverAtAndRestaurant();

    const client = order.get('client');
    const defaultTeam = client.get('teams.content.firstObject');
    const isGroupOrder = order.get('isGroupOrder');

    const perPersonBudget = value ? 2000 : 0;
    const teams = client.get('manageTeams') && isGroupOrder && value ? [defaultTeam] : [];

    order.setProperties({
      perPersonBudget,
      teams,
      allowsPayOutOfPocket: false,
      isMealPlanOrder: false
    });
  }

  @action
  handleDeliverAtDidCommit(deliverAt, prevDeliverAt) {
    const diff = prevDeliverAt ? moment(deliverAt).diff(moment(prevDeliverAt), 'minutes') : null;

    this.get('order').adjustTimesRelativeToDeliverAt(diff);
    flashSet(this, 'adjustedDeadlineAt', true, 2000, false);
  }

  @action
  handleAddClientDiscountButtonClick() {
    this.get('store').createRecord('client-discount', {
      order: this.get('order'),
      amountType: 'value',
      description: ''
    });
  }

  @action
  changeActiveTab(tabName) {
    if (tabName === OrderFormForTabs.DISCOUNT_TAB) {
      this.get('loadDiscountCodesTask').perform();
    }

    this.set('activeTab', tabName);
  }

  @action
  handleDidSaveDiscount() {
    this.get('order')
      .save()
      .catch((_) => {
        if (_.errors) {
          _.errors.forEach((error) => {
            this.get('notify').error(error.detail);
          });
        } else {
          this.get('notify').error(`Something went wrong: ${_.toString()}`);
        }
      })
      .then((_) => {
        this.didSubmit(this.get('order'));
      });
  }

  @action
  handleConfirmSubmit() {
    return this.confirmMessage ? window.confirm(this.confirmMessage) : true;
  }
}
