import classic from 'ember-classic-decorator';
import FeedItemBuilder from './base';

@classic
export default class TeamMemberBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);

    feedItem.icon = 'users';

    return feedItem;
  }
}
