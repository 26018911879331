import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { isPresent } from '@ember/utils';

@classic
@classNames('fde-layouts-split-view')
@classNameBindings(
  'isVertical:vertical',
  'overHalf',
  'isDragging:fde-is-dragging',
  'transitionStops:fde-transition-stops'
)
export default class SplitView extends Component {
  /** @type {string} name of this split panel for the purposes of storing its last position */
  name = '';

  /** @type {boolean} whether or not the panel is split or not */
  isVertical = false;

  /** @type {boolean} whether or not the values are in percentage points or pixels */
  percentageMode = true;

  /** @type {number} current position of the split */
  value = 30;

  /**
   * Since we might never hit the stop exactly while moving the split view
   * the tolerance allows us to have some wiggle room. Reflecting on this it probably
   * makes sense to do something a little different, but this works for now.
   * @type {number}
   **/
  stopTolerance = 10;

  /** @type {string} name of the first stop */
  firstStop = 'initial';

  /** @type {boolean} will animate between stop transitions */
  transitionStops = false;

  /** @type {boolean} Will be true when the stops are transitioning */
  isTransitioning = false;

  /** @type {Number} */
  transitionTime = 1;

  /** @type {String} */
  stopsFor = 'left';

  /** @type {boolean} */
  loadValueFromStorage = false;

  /** @type {boolean} */
  isDragging = false;

  /**
   * An array of objects with a name and value, can be overridden for custom behavior
   */
  stops = null;

  init(...args) {
    super.init(...args);

    if (this.get('stops') === null) {
      const stops = this.get('percentageMode')
        ? [
            {
              name: 'closed',
              value: 0
            },
            {
              name: 'initial',
              value: 30
            },
            {
              name: 'open',
              value: 100
            }
          ]
        : [
            {
              name: 'closed',
              value: 0
            },
            {
              name: 'initial',
              value: 300
            },
            {
              name: 'really-big',
              value: 500
            }
          ];
      this.set('stops', stops);
    }
  }

  didInsertElement() {
    super.didInsertElement(...arguments);

    // either we use the stored value or the initial value
    let value = this.get('loadValueFromStorage')
      ? parseInt(localStorage.getItem(this.get('storageKey')) || this.get('value'), 10)
      : this.get('value');

    // if no value can be sorted out, lets find the first specified stop
    if (!isPresent(value) || isNaN(value) || value !== this.get('value')) {
      value = this.get('stops').find((_) => _.name === this.get('firstStop')).value || 0;
      this.set('value', value);
      this.emitEvents(value);
      this.onDragComplete(value);
    }

    this.onSplitViewInsert(this);
  }

  /**
   * Interface for the divider element
   * @param {number} amount the amount to modify the pane's width / height by
   */
  dragDividerBy(amount) {
    let value = this.get('value') + amount;
    const firstStop = this.get('stops.firstObject');
    const lastStop = this.get('stops.lastObject');

    // saturate or minimize values
    if (value > lastStop.value) {
      value = lastStop.value;
    }

    if (value < firstStop.value) {
      value = firstStop.value;
    }

    this.emitEvents(value);
    localStorage.setItem(this.get('storageKey'), value);
    this.set('value', value);
  }

  /**
   * Emits events for the split pane where needed
   * @param {value} value
   */
  emitEvents(value) {
    const stopTolerance = this.get('stopTolerance');
    const stop = this.get('stops').find(
      (stop) => stop.value < value + stopTolerance && stop.value > value - stopTolerance
    );

    if (stop) {
      this.onStopReached(stop.name);
    }

    this.onChange(value);
  }

  /**
   * Called when ever a stop is reached while resizing, useful for updating contained UI
   *
   * @param {string} stop
   */
  onStopReached() {}

  /**
   * Called when a value change has occured
   * @param value
   */
  onChange() {}

  /**
   * Called when the drag has ever reached completion
   * @param value
   */
  onDragComplete() {}

  /**
   * Called when the split view component is inserted
   * @param {Component} splitView
   */
  onSplitViewInsert() {}

  /**
   * Selects the next stop if one is present
   */
  @action
  nextStop() {
    const stop = this._findNextStop();

    if (stop) {
      this.set('value', stop.value);
      this.onStopReached(stop.name);
    }
  }

  /**
   * Selects the previous stop if one is present
   */
  @action
  prevStop() {
    const stop = this._findPrevStop(true);

    if (stop) {
      this.set('value', stop.value);
      this.onStopReached(stop.name);
    }
  }

  /**
   * @type {boolean} whether or not we are over halfway through the scrubbable range
   * dictates where we show the stop buttons
   */
  @computed('value')
  get overHalf() {
    const value = this.get('value');

    if (this.get('percentageMode')) {
      return this.get('stopsFor') === 'left' ? value > 50 : value < 50;
    } else {
      const size = this.get('isVertical') ? this.$().outerHeight() : this.$().outerWidth();

      if (this.get('stopsFor') === 'left') {
        return value > size / 2;
      } else {
        return size - value > size / 2;
      }
    }
  }

  /** @type {boolean} */
  @computed('value')
  get hasNextStop() {
    return this._findNextStop();
  }

  /** @type {boolean} */
  @computed('value')
  get hasPrevStop() {
    return this._findPrevStop();
  }

  /** @type {string} */
  @computed('name')
  get storageKey() {
    return `views:split-view:${this.get('name')}:value`;
  }

  /**
   * Finds the next stop based on the current value
   * @private
   */
  _findNextStop() {
    const value = this.get('value');

    const stops = this.get('stops');

    if (this.get('stopsFor') === 'left') {
      return stops.find((stop) => stop.value > value && stop.navigable !== false);
    } else {
      return stops
        .toArray()
        .reverse()
        .find((stop) => stop.value < value && stop.navigable !== false);
    }
  }

  /**
   * Find the previous stop based on the current value
   * @private
   */
  _findPrevStop() {
    const value = this.get('value');

    const stops = this.get('stops');

    if (this.get('stopsFor') === 'left') {
      return stops
        .toArray()
        .reverse()
        .find((stop) => stop.value < value && stop.navigable !== false);
    } else {
      return stops.find((stop) => stop.value > value && stop.navigable !== false);
    }
  }
}
