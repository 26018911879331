import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import moment from 'moment-timezone';

@classic
export default class CapacitiesRoute extends Route {
  model(params) {
    const dateRange = this._extractDateRangeOrProvideDefault(params);
    const { restaurant } = this.modelFor('logged-in.restaurants.show');

    return RSVP.hash({
      referenceDate: moment(dateRange.split(',')[0]),
      restaurant,
      restaurantCapacityTranches: restaurant.query('restaurantCapacityTranches', {
        filter: {
          dateRangeWithRestaurant: `${dateRange},${restaurant.get('id')}`
        }
      })
    });
  }

  queryParams = {
    dateRange: { refreshModel: true }
  };

  dateRangeFormat = 'Y-M-DD';

  @computed('dateRangeFormat')
  get startOfWeek() {
    return moment().startOf('week').format(this.get('dateRangeFormat'));
  }

  @computed('dateRangeFormat')
  get endOfWeek() {
    return moment().endOf('week').format(this.get('dateRangeFormat'));
  }

  @computed('startOfWeek', 'endOfWeek')
  get defaultDateRange() {
    return `${this.get('startOfWeek')},${this.get('endOfWeek')}`;
  }

  _extractDateRangeOrProvideDefault(params) {
    const { dateRange } = params;

    return dateRange || this.get('defaultDateRange');
  }

  @action
  refreshModel() {
    this.refresh();
  }

  @action
  loading(transition, _originRoute) {
    const controller = this.controllerFor('logged-in.restaurants.show.capacities');

    controller.set('isLoading', true);

    transition.finally(function () {
      controller.set('isLoading', false);
    });
  }
}
