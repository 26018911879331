import Fixture, {
  // element,
  // elementWithValue,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class SfTabViewTabGroupFixture extends Fixture {
  constructor() {
    super('data-test-sf-tab-group');
  }
}

export const sfTabViewTabGroup = getElementDecoratorFor(SfTabViewTabGroupFixture);
export const sfTabViewTabGroupWithValue = getElementWithValueDecoratorFor(SfTabViewTabGroupFixture);
