import classic from 'ember-classic-decorator';
import {
  attributeBindings,
  classNameBindings,
  classNames,
  tagName
} from '@ember-decorators/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

@classic
@tagName('button')
@attributeBindings('type')
@classNames('ui button')
@classNameBindings(
  'isEnabled:enabled:disabled',
  'isPrimary:primary',
  'isSecondary:secondary',
  'isSubmit:submit',
  'isFluid:fluid',
  'isBasic:basic',
  'icon:icon',
  'color',
  'isInverted:inverted',
  'isMini:mini',
  'isLarge:large',
  'isBig:big',
  'isHuge:huge',
  'isMassive:massive',
  'isNegative:negative',
  'isPositive:positive',
  'isRight:right',
  'isLeft:left',
  'isFloated:floated',
  'isSaving:loading'
)
export default class SimpleButton extends Component {
  /** @type {Service} */
  @service
  router;

  /** @type {string} */
  icon = '';

  /** @type {string} */
  color = '';

  /** @type {boolean} */
  isEnabled = true;

  /** @type {boolean} */
  isFluid = false;

  /** @type {boolean} */
  isPrimary = false;

  /** @type {boolean} */
  isSecondary = false;

  /** @type {boolean} */
  isSubmit = false;

  /** @type {boolean} */
  isBasic = false;

  /** @type {boolean} */
  isLarge = false;

  /** @type {boolean} */
  isInverted = false;

  /** @type {boolean} */
  isSaving = false;

  /** @type {boolean} */
  isRightFloated = false;

  /** @type {boolean} */
  isLeftFloated = false;

  /** @type {boolean} */
  bubbles = true;

  /** @type {boolean} */
  preventDefault = false;

  /** @type {?string} A routable path: can be a string or an array for routes that need params */
  linkTo = null;

  /** @type {?function} A callback on clicking the new button */
  newAction = null;

  /** @type {string} submit | button */
  @computed('isSubmit')
  get type() {
    return this.get('isSubmit') ? 'submit' : 'button';
  }

  click(e) {
    const linkTo = this.get('linkTo');
    const newAction = this.get('newAction');

    if (!this.get('isSaving')) {
      if (linkTo) {
        this.set('isSaving', true);

        if (typeof linkTo === 'string') {
          this.get('router')
            .transitionTo(linkTo)
            .finally(() => this.set('isSaving', false));
        } else {
          this.get('router')
            .transitionTo(...linkTo)
            .finally(() => this.set('isSaving', false));
        }
      } else if (newAction) {
        newAction();
      }

      if (this.get('preventDefault')) {
        e.preventDefault();
      }

      if (!this.get('bubbles')) {
        e.stopPropagation();
        return false;
      }
    }
  }
}
