import MF from 'ember-data-model-fragments';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';

export default class MealPlanningPlan extends MF.Fragment {
  @fragmentArray('fragments/meal-planning/day') days;

  //Likely don't need these at the root level anymore... all handled by OrderTemplate
  @fragment('fragments/contact') contact;
  @fragment('fragments/location') location;

  get meals() {
    return this.days.map((_) => _.meals.toArray()).flat();
  }

  get restaurantConstraintIds() {
    return this.days.mapBy('restaurantConstraintIds').flat();
  }

  get slots() {
    return this.days.mapBy('slots').flat();
  }
}
