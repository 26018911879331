import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

/**
 * Enum that represents all the different constraint types
 * @enum {string}
 */
export const TYPES = Object.freeze({
  ALL: 'ALL', // All of the values are in the menu item property
  ANY: 'ANY', // A least one of the values is in the menu item property
  NOT: 'NOT', // None of the items are in the menu item property
  EXT: 'EXT' // The items are exactly the same
});

@classic
@tagName('')
export default class ConstraintsFormFor extends Component {
  /** @type {FormFor} */
  parentForm = null;

  /** @type {boolean} */
  autoSubmit = true;

  /** @type {boolean} */
  isTopCuisine = false;

  /** @type {MealPlanningRequirementConstraint[]} */
  constraints = null;

  @computed('constraints.[]')
  get editableConstraints() {
    return this.get('constraints').filter((_) => !_.get('isReadonly'));
  }

  /** @type {?string} */
  constraintTagType = null;

  /** @type {string} */
  control = null;

  /** @type {string} */
  label = null;

  /** @type {Array} */
  selectableValues = null;

  /** @type {string} */
  groupByKey = null;

  /** @type {string} */
  noneText = '';

  /** @type {boolean} */
  isSearchForm = true;

  /** @type {store} */
  @service
  store;

  /**
   * Selects the next constraint, and requests a submission of the form. Manual submission is used to relay
   * submission request to parentForm in-case this has been setup as a searchForm
   * @param {string} constraint
   * @param {FormFor} ownForm
   */
  @action
  nextConstraint(constraint, ownForm) {
    const nextConstraintTypeMap = {
      ALL: 'ANY',
      ANY: 'NOT',
      NOT: 'ALL'
    };

    constraint.set('constraintType', nextConstraintTypeMap[constraint.get('constraintType')]);

    this.doSubmit(ownForm);
  }

  /** onSubmit event for the form */
  onSubmit() {}

  /** onSubmit event for the form */
  didSubmit() {}

  /** onAddConstraint event for the form */
  @action
  onAddConstraint(/* constraint */) {}

  /** onRemoveConstraint event for the form */
  @action
  onRemoveConstraint(/* constraint */) {}

  /** doSubmit method for form, written to support search callback through parent submit */
  @action
  doSubmit(ownForm) {
    if (this.get('isSearchForm') && this.get('autoSubmit')) {
      this.get('parentForm').doSubmit();
    } else if (this.get('autoSubmit')) {
      ownForm.doSubmit();
    }
  }

  @action
  handleAddClick() {
    const store = this.get('store');

    const constraints = this.get('constraints');
    const existingDeletedConstraint = constraints.find((_) => {
      return _.get('tagType') === this.get('constraintTagType') && _.get('isDeleted');
    });

    if (existingDeletedConstraint) {
      existingDeletedConstraint.rollbackAttributes();
      this.onAddConstraint(existingDeletedConstraint);
    } else {
      const constraint = store.createRecord('mealPlanningRequirementConstraint', {
        constraintType: TYPES.NOT,
        tagType: this.get('constraintTagType')
      });
      this.get('constraints').pushObject(constraint);

      if (!this.get('isSearchForm') && this.get('autoSubmit')) {
        constraint.save();
      }

      this.onAddConstraint(constraint);
    }
  }

  @action
  handleRemoveClick(constraint) {
    constraint.destroyRecord();
    this.onRemoveConstraint(constraint);
  }
}
