import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';

@classic
export default class InternalRoute extends Route {
  userShowRoute = 'logged-in.users.show';

  model(_params) {
    const user = this.modelFor(this.userShowRoute);

    return RSVP.hash({
      user: user,
      roles: this.store.query('role', {
        page: {
          limit: 100
        }
      })
    });
  }
}
