import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { run } from '@ember/runloop';
import $ from 'jquery';

const UP_ARROW = 38;
const DOWN_ARROW = 40;
const ESCAPE = 27;

@classic
@classNames('fde-searchable-input-control', 'ui input')
export default class SearchableInputControl extends Component {
  /** @type {string} Search value of the input box */
  searchValue = null;

  /** @type {Object} Object that is currently selected on the section list */
  selection = null;

  /** @type {Object[]} An array of result items that will be used for the list*/
  results = null;

  /** @type {Object[]} An array of sections that contain `data` arrays of result items that will be used for the list */
  sectionedResults = null;

  /** @type {string} Label key of the item used to display text as default display mechanism for a result list item */
  itemLabelKey = 'label';

  /** @type {string} Denotes a loading state to be used by the input control to show a loader */
  isLoading = false;

  /** @type {?number} */
  //Sane default
  maxCalloutWidth = 325;

  /** @type {?number} */
  //Sane default
  minCalloutWidth = 325;

  /** @type {?number} */
  maxCalloutHeight = null;

  /** @type {?number} */
  minCalloutHeight = null;

  /**
   * Normalizes `results` array into a sectioned array for the section-list component if passed in to this component.
   * If not, it is assumed the `sectionResults` property has been passed in and will be used for the section-list
   * component.
   * @type {Section[]}
   */
  @computed('results.[]', 'sectionedResults.[]')
  get _sectionedResults() {
    const results = this.get('results');
    const sectionResults = this.get('sectionResults');

    if (results && !sectionResults) {
      return {
        title: null, // Will prevent a title from showing up. Works as a regular list.
        data: results
      };
    }

    return this.get('sectionedResults');
  }

  /** @type {Object[]} A concatenation of each sections data arrays */
  @computed('sectionedResults.[]')
  get flattenedSectionedResults() {
    return this.get('sectionedResults').reduce((acc, section) => acc.concat(section.data), []);
  }

  /** @type {string} Left icon for the input control search box */
  leftIcon = 'search';

  /** @type {string} Placeholder text for the input control search box */
  placeholder = 'Search';

  /** @type {string} ID unique to this component to target search input box */
  @computed
  get searchBarId() {
    return `${guidFor(this)}_search-bar`;
  }

  /**
   * Search Change Event
   * @param {?string} query
   */
  onSearchChange(_query) {}

  /**
   * Selection Event
   * @param {*} selection
   */
  onSelection(selection) {
    this.set('selection', selection);
  }

  /** Item Action Event, called when an item is selected from the list */
  onItemAction() {}

  /** Cancel Event, called when the section list selecting is cancelled */
  onCancel() {}

  /** Focuses on the section list */
  focusOnSearchBox() {
    this.$(`#${this.get('searchBarId')}`).focus();
  }

  /** Focuses on the section list */
  focusOnSectionList() {
    $(`.${this.get('searchBarId')}_section-list`).focus();
  }

  /** @override */
  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
    this.setSectionListScrollbarHeightToMatchCallout();
  }

  /** @override */
  didInsertElement() {
    super.didInsertElement(...arguments);
    this.wireDismissOnDocument();
  }

  /** @override */
  willDestroyElement() {
    super.willDestroyElement(...arguments);
  }

  /** Handles click event on document, for dismissing the dropdown */
  onDocumentClick() {
    run((_) => {
      this.resetResults();
    });
  }

  /** Wires up the dismiss on document click */
  wireDismissOnDocument() {
    const onDocumentClick = this.get('onDocumentClick').bind(this);
    document.addEventListener('click', onDocumentClick);
    this.set('_onDocumentClick', onDocumentClick);
  }

  /** Tearsdown up the dismiss on document click */
  unwireDismissOnDocument() {
    const onDocumentClick = this.get('_onDocumentClick');
    document.removeEventListener('click', onDocumentClick);
  }

  /** Resets the results to an empty set */
  resetResults() {
    if (this.get('isDestroyed')) {
      return;
    }

    const results = this.get('results');
    const sectionedResults = this.get('sectionedResults');

    if (results) {
      this.set('results', []);
    } else if (sectionedResults) {
      this.set('sectionedResults', []);
    }
  }

  /** Resets the search value to an empty string */
  resetSearchValue() {
    this.set('searchValue', null);
  }

  /** Selects the first result */
  selectFirstResult() {
    this.set('selection', this.get('flattenedSectionedResults.firstObject'));
  }

  /** Selects the last result */
  selectLastResult() {
    this.set('selection', this.get('flattenedSectionedResults.lastObject'));
  }

  /** Resets the selection */
  resetSelection() {
    this.set('selection', null);
  }

  /** Sets the section-list's component scrollbar height to match any callout restrictions */
  setSectionListScrollbarHeightToMatchCallout() {
    if (this.get('isDestroyed')) {
      return;
    }

    const $sectionListScrollbox = $(
      `.${this.get('searchBarId')}_section-list .fde-lists-section-list_scrollbox`
    );

    // Reset the scrollbox height to auto so that we don't accidentally force the height of the wrapping callout
    if ($sectionListScrollbox.length) {
      $sectionListScrollbox.css('height', '100%');
    }

    run.next((_) => {
      const $sectionListScrollbox = $(
        `.${this.get('searchBarId')}_section-list .fde-lists-section-list_scrollbox`
      );

      if ($sectionListScrollbox.length) {
        $sectionListScrollbox.outerHeight(
          $sectionListScrollbox.closest('.fde-callout-pane_popup').innerHeight()
        );
      }
    });
  }

  @action
  handleInputChange(query) {
    console.debug(query);
    this.set('searchValue', query);
    this.onSearchChange(query);
  }

  @action
  handleSearchInputKeyDown(e) {
    console.debug(e);

    const hasResults = !!this.get('flattenedSectionedResults.length');

    if (e.which === DOWN_ARROW && hasResults) {
      this.focusOnSectionList();
      this.selectFirstResult();
    } else if (e.which === UP_ARROW && hasResults) {
      this.focusOnSectionList();
      this.selectLastResult();
    } else if (e.which === ESCAPE) {
      this.resetResults();
      this.resetSearchValue();
      this.focusOnSearchBox();
    }
  }

  @action
  handleSectionListSelection(selection) {
    console.debug(selection);
    this.onSelection(selection);
  }

  @action
  handleSectionListItemAction(item) {
    console.debug(item);
    this.onItemAction(item);
    this.resetSearchValue();
  }

  @action
  handleSectionListCancel() {
    console.debug();
    this.onCancel();
    this.focusOnSearchBox();
    this.resetSearchValue();
    this.resetResults();
    this.resetSelection();
  }

  @action
  handleTopReached() {
    console.debug();
    this.focusOnSearchBox();
  }

  @action
  handleBottomReached() {
    console.debug();
    this.focusOnSearchBox();
  }
}
