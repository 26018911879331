import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@tagName('')
export default class FormContainer extends Component {
  /** @type {boolean} */
  readonly = false;

  /** @type {boolean} whether or not the form is showing */
  showForm = false;

  /** @type {string} Title to render above the table */
  title = '';
}
