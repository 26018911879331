import Controller from 'star-fox/features/application/abstract-controller';
import { alias, oneWay } from '@ember/object/computed';
import EmberObject, { action, computed } from '@ember/object';
import { Promise } from 'rsvp';
import moment from 'moment-timezone';

export default Controller.extend({
  /** @override */
  pusherEvents: ['balance-updated'],

  /** @override */
  pusherChannels: computed('wallet', function () {
    return [`ee.food.wallets.${this.get('wallet.id')}.events`];
  }),

  defaultEmail: 'rewards@food.ee',

  /** @type {EmberObject} **/
  reward: EmberObject.create({ amount: 0 }),

  /** @type {EmberObject} **/
  redemption: EmberObject.create({ amount: 0 }),

  balance: computed('wallet.balances.rewards', function () {
    return this.get('wallet.balances.rewards');
  }),

  /** @type {Client} **/
  client: alias('model.client'),

  currency: oneWay('model.currency'),

  /** @type {number} pageLimit - records per page */
  pageLimit: 20,

  /** @type {number} pageOffset - current page */
  pageOffset: 0,

  transactions: oneWay('model.transactions'),
  transactionCount: oneWay('transactions.meta.recordCount'),
  wallet: oneWay('model.wallet'),

  _didRedeemOrReward(type) {
    let promise;
    const giftbitCard = this.get('model.giftbitCard');
    promise = giftbitCard.reload();

    this.set(type, this.get(`model.${type}`));

    this.set(
      `model.${type}`,
      this.store.createRecord('giftbit-points-action', {
        type: type,
        amount: 0,
        giftbitCard: giftbitCard
      })
    );

    return promise
      .then(() => this.store.findRecord('giftbit-card', this.get('model.giftbitCard.id')))
      .then((giftCard) => giftCard.get('transactions.firstObject.transactionId'));
  },

  balanceUpdated: action(function (event) {
    console.info(
      `${moment().format('hh:mm:ss ')} [${this.pubSub}] Balance updated received: ${
        event.wallet_id
      }`
    );
    this.get('client').belongsTo('wallet').reload();
  }),

  handleDidRedeem: action(function () {
    const client = this.get('client');

    return Promise.resolve().then((lightrailTransactionId) => {
      const amount = this.get('redemption.amount');
      const reason = this.get('redemption.reason');

      return client
        .redeemPoints({
          value_in_point_cents: amount * 100,
          reason: `${lightrailTransactionId}|||${reason}`,
          user_id: this.get('userSession.user.id'),
          client_id: this.get('client.id')
        })
        .then(() => this.set('redemption', EmberObject.create({ amount: 0 })))
        .then(() => this.send('refreshModel'));
    });
  }),

  handleDidReward: action(function () {
    const client = this.get('client');

    return Promise.resolve().then((lightrailTransactionId) => {
      const amount = this.get('reward.amount');
      const reason = this.get('reward.reason');

      return client
        .rewardPoints({
          value_in_point_cents: amount * 100,
          reason: `${lightrailTransactionId}|||${reason}`,
          user_id: this.get('userSession.user.id'),
          client_id: this.get('client.id')
        })
        .then(() => this.set('reward', EmberObject.create({ amount: 0 })))
        .then(() => this.send('refreshModel'));
    });
  }),

  useAllPoints: action(function () {
    this.set('redemption.amount', this.get('balance.amount'));
  })
});
