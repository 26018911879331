import classic from 'ember-classic-decorator';
import {
  attributeBindings,
  classNameBindings,
  classNames,
  tagName
} from '@ember-decorators/component';
import Component from '@ember/component';
import { run } from '@ember/runloop';
import { htmlSafe } from '@ember/template';

/**
 * Renders a single cell
 */
@classic
@tagName('td')
@classNames('fde-tables-ordered-table-table-cell')
@classNameBindings('allowsOverflow:fde-tables-ordered-table-table-cell_allows-overflow')
@attributeBindings('componentStyle:style', 'title')
export default class TableCell extends Component {
  init() {
    super.init(...arguments);

    const tableManager = this.get('tableManager');
    tableManager && tableManager.registerCell(this, this.get('rowIndex'));
    this.set('previousRowIndex', this.get('rowIndex'));
  }

  /** @type {TableManager} */
  tableManager = null;

  /** @type {number} */
  rowIndex = null;

  /** @type {number} */
  prevRowIndex = null;

  /** @type {number} */
  columnWidth = null;

  /** @type {boolean} */
  allowsOverflow = false;

  /** @type {boolean} Ensures that the last column is always fluid all proceeding ones are hidden */
  lastColumnAlwaysFluid = true;

  /** @override */
  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    //Observer won't work here, because in ember 3.x they're triggered after the render.
    if (this.get('rowIndex') !== this.get('prevRowIndex')) {
      this.rowIndexChanged();
    }
    this.set('prevRowIndex', this.get('rowIndex'));
  }

  /** Required, because the rowIndex can change during a render phase, must re-register with correct row index */
  rowIndexChanged() {
    const tableManager = this.get('tableManager');

    if (tableManager) {
      tableManager.unregisterCell(this, this.get('previousRowIndex'));
      tableManager.registerCell(this, this.get('rowIndex'));
    }

    this.set('previousRowIndex', this.get('rowIndex'));
  }

  /** @type {string} */
  calculatedComponentStyle = '';

  /** @type {string} */
  componentStyle = '';

  /** Triggers a recalculation of the table-cell on the next render phase of the run-loop */
  recalculateCellSize() {
    if (!this.get('isRegistered')) {
      return '';
    }

    const cellRowMap = this.get('tableManager.cellRowMap');
    const rowIndex = this.get('rowIndex');
    const cellsInRow = cellRowMap[rowIndex] || [];

    const columnCell = this.get('tableManager').getColumnForCell(this);

    const width = columnCell.width;

    const minWidth = columnCell.minWidth || width;
    const maxWidth = columnCell.maxWidth || width;

    const percentage = 1 / cellsInRow.length;

    const lastVisibleColumn = this.get('tableManager').getLastVisibleColumn();

    const isLastVisibleColumn = columnCell === lastVisibleColumn;
    const lastColumnAlwaysFluid = this.get('lastColumnAlwaysFluid');

    const forceFluid = lastColumnAlwaysFluid && isLastVisibleColumn;

    let style;

    if (forceFluid) {
      style = `flex-basis: ${percentage}%`;
    } else if (width) {
      style = `max-width: ${Math.min(width, maxWidth)}px; min-width: ${Math.max(
        width,
        minWidth
      )}px;`;
    } else {
      style = `flex-basis: ${percentage}%; min-width: ${
        minWidth ? minWidth + 'px' : 'auto'
      }; max-width: ${maxWidth ? maxWidth + 'px' : 'auto'};`;
    }

    if (!columnCell.visible) {
      style = `${style} display: none !important;`;
    }

    const prevCalculatedComponentStyle = this.get('calculatedComponentStyle');
    if (prevCalculatedComponentStyle !== style) {
      this.set('componentStyle', htmlSafe(style));
      this.set('calculatedComponentStyle', style);
    }
  }

  /** Triggers a recalculation of the table-cell on the next render phase of the run-loop */
  notifyVisibilityChange() {
    if (this.get('lastColumnAlwaysFluid')) {
      this.get('tableManager').recalculateCellSizes();
    } else {
      this.recalculateCellSize();
    }
  }

  /** @override */
  didInsertElement() {
    super.didInsertElement(...arguments);
    run.scheduleOnce('afterRender', this, (_) => this.recalculateCellSize());
  }

  /** @override */
  willDestroyElement() {
    super.willDestroyElement(...arguments);
    const tableManager = this.get('tableManager');
    tableManager && tableManager.unregisterCell(this, this.get('rowIndex'));
  }
}
