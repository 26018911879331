import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class User extends Application {
  attrs = {
    globalId: {
      serialize: false
    },
    // disabled for now as couriers are added through roles and not through a normal
    // association
    couriers: {
      serialize: false
    },

    features: {
      serialize: false
    },

    roles: {
      serialize: true
    },

    paymentCards: {
      serialize: true
    },

    client: {
      serialize: false
    },

    restaurant: {
      serialize: false
    },

    state: {
      serialize: false
    }
  };

  serialize() {
    let json = super.serialize(...arguments);

    // If the invitation token is present in update calls, the invitation is automatically accepted.
    // This should only happen when a user is accepting their invite (password and confirmation are present).
    if (!json.data.attributes.password || !json.data.attributes['password-confirmation']) {
      delete json.data.attributes['invitation-token'];
    }

    return json;
  }
}
