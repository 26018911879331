import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-orders-type-icons')
export default class TypeIcons extends Component {
  /** @type {Order} */
  order = null;

  onEnterpriseClick() {}
}
