import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@tagName('')
export default class CalloutContainer extends Component {
  minWidth = 400;
  position = 'top left';
  isShowing = false;
  target = null;
  isCalloutShowing = false;
  iconArrow = null;
  hoverCalloutPaneId = null;
}
