import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias, union } from '@ember/object/computed';
import { inject as controller } from '@ember/controller';
import RSVP from 'rsvp';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';
import { camelize } from '@ember/string';

@classic
export default class IndexController extends Controller {
  @service
  session;

  @service
  eventBus;

  pusherEvents = ['created', 'changed', 'estimate-updated'];

  /**
   * @type {string} the name of the channel that we use for presence,
   * defaults to ee.food.orders.{id}.model-events
   */
  @computed('order')
  get pusherChannels() {
    const orderId = this.get('order.id');

    return [`ee.food.orders.${orderId}.model-events`, `ee.food.orders.${orderId}.order-events`];
  }

  @computed('session.user')
  get useNewPubSub() {
    return this.session.user.hasFeatureEnabled('newPubSub');
  }

  /** @type {OrderService} */
  @service
  orderService;

  /** @type {NotificationService} */
  @service
  notify;

  /** @type {Controller} */
  @controller('logged-in.orders.edit')
  ordersEditController;

  /** @type {Order} */
  @alias('model.order')
  order;

  notificationLogs = null;

  @alias('order.area.isoTimeZone')
  isoTimeZone;

  /** @type {?Client[]} */
  searchedClients = null;

  /** @type {?Client[]} */
  areaClients = null;

  /** @type {Restaurant[]} */
  areaRestaurants = null;

  /** @type {DiscountCode[]} */
  discountCodes = null;

  /** @type {User[]} */
  clientAdmins = null;

  /** @type {boolean} */
  isRestaurantDetailsLoading = false;

  /** @type {boolean} */
  isClientDetailsLoading = false;

  /** @type {number} */
  emailMessagePageLimit = 5;

  /** @type {number} */
  clientEmailMessagePageOffset = 0;

  /** @type {number} */
  restaurantEmailMessagePageOffset = 0;

  /** @type {number} */
  teamMemberEmailMessagePageOffset = 0;

  /** @type {number} */
  clientEmailMessagesRecordCount = 0;

  /** @type {number} */
  restaurantEmailMessagesRecordCount = 0;

  /** @type {number} */
  teamMemberEmailMessagesRecordCount = 0;

  /** @type {string} The search term used to filter email messages */
  _clientRecipientSearchTerm = '';

  /** @type {string} The search term used to filter email messages */
  _restaurantRecipientSearchTerm = '';

  /** @type {string} The search term used to filter email messages */
  _teamMemberRecipientSearchTerm = '';

  /** @type {string} Stores the users search term input until they stop typing and search term is set */
  clientSearchText = '';

  /** @type {string} Stores the users search term input until they stop typing and search term is set */
  restaurantSearchText = '';

  /** @type {string} Stores the users search term input until they stop typing and search term is set */
  teamMemberSearchText = '';

  /** @type {boolean} Whether or not we are filtering client email messages by recipient. */
  filterClientMessagesByRecipient = false;

  /** @type {boolean} Whether or not we are filtering client email messages by recipient. */
  filterRestaurantMessagesByRecipient = false;

  /** @type {boolean} Whether or not we are filtering client email messages by recipient. */
  filterTeamMemberMessagesByRecipient = false;

  /** @type {EmailMessage[]} */
  filteredClientEmailMessages = null;

  /** @type {EmailMessage[]} */
  filteredRestaurantEmailMessages = null;

  /** @type {EmailMessage[]} */
  filteredTeamMemberEmailMessages = null;

  /** @type {boolean} */
  isClientSearchLoading = false;

  /** @type {boolean} */
  isRestaurantSearchLoading = false;

  /** @type {boolean} */
  isTeamMemberSearchLoading = false;

  /** @type {?DeliveryLocation[]} */
  searchedDeliveryLocations = null;

  /** @type {Contact[]} */
  adminContacts = null;

  /** @type {Contact[]} */
  orderContacts = null;

  /** @type {Contact[]} */
  @union('adminContacts', 'orderContacts')
  clientContacts;

  /** @type {?Contact[]} */
  searchedAdminContacts = null;

  /** @type {?Contact[]} */
  searchedOrderContacts = null;

  /** @type {?Contact[]} */
  @union('searchedAdminContacts', 'searchedOrderContacts')
  searchedContacts;

  /**
   * Calls the query function with messageType.
   * Called in setupController before page loads
   * Is also used by pagination-search-control to update pagination.
   * @param {string} modelName
   * @param {string} pageOffset
   */
  @action
  handleMailPaginationChange(modelName, pageOffset) {
    this.set(`${camelize(modelName)}EmailMessagePageOffset`, pageOffset);
    this._buildEmailMessagesQuery(modelName);
  }

  /**
   * @description fetches all the area's clients for the dropdown..
   */
  fetchAreaClients() {
    const areaCity = this.get('order.area.city');

    const queryObject = {
      filter: {
        area: areaCity
      }
    };

    return this.store.query('client', queryObject).then((clients) => {
      clients && this.set('areaClients', clients);
    });
  }

  /**
   * fetches the discount codes available
   */
  fetchDiscountCodes() {
    return this.store
      .findAll('discount-code')
      .then((discountCodes) => this.set('discountCodes', discountCodes.toArray()));
  }

  /**
   * fetches the admins for the dropdown.
   */
  async fetchClientAdmins() {
    const clientAdmins = await this.get('order.client.admins');
    this.set('clientAdmins', clientAdmins.toArray());
  }

  /**
   * fetches, and sets the related client contacts
   */
  fetchClientContacts() {
    const client = this.get('order.client');

    if (!client) {
      return null;
    }

    const queryOptions = {
      page: {
        offset: 0,
        limit: 8
      },
      sort: '-updatedAt'
    };

    return RSVP.Promise.all([
      client
        .query('orderContacts', queryOptions)
        .then((orderContacts) => this.set('orderContacts', orderContacts)),
      client
        .query('adminContacts', queryOptions)
        .then((adminContacts) => this.set('adminContacts', adminContacts))
    ]);
  }

  /**
   * fetches all the area's restaurants for the dropdown.
   */
  async fetchAreaRestaurants() {
    const filter = { active: true, comingSoon: false };
    let sort;

    if (this.order.client?.features?.deliveryRadius || this.order.area?.features?.deliveryRadius) {
      filter.location_id = this.order.clientLocation?.id;
      sort = 'distance';
    } else {
      sort = 'name';
      filter.area = this.get('order.area.city');
    }

    const queryObject = {
      filter,
      page: { limit: 200 },
      fields: { restaurants: 'name,distance' }
    };

    const restaurants = await this.store.query('restaurant', queryObject);

    this.set('areaRestaurants', restaurants.toArray().sortBy(sort));
  }

  /**
   * Fetches, and sets the initially loaded client delivery locations
   */
  fetchDeliveryLocations() {
    const client = this.get('order.client.id');

    if (client) {
      this.store
        .query('delivery-location', {
          page: {
            offset: 0,
            limit: 10
          },
          filter: {
            client
          },
          include: 'client,location'
        })
        .then((deliveryLocations) => this.set('searchedDeliveryLocations', deliveryLocations));
    }
  }

  fetchNotificationLogs() {
    const order = this.get('order');

    const query = {
      page: {
        limit: 300
      },
      filter: {
        orderLogsFor: `${order.id},Client,Restaurant,GroupOrderMember`
      }
    };

    return order
      .query('notificationLogs', query)
      .then((logs) => this.set('notificationLogs', logs))
      .catch((err) => this.get('notify').warning(err));
  }

  fetchNotificationLog(id) {
    return this.store
      .findRecord('notificationLog', id)
      .then((log) => this.get('notificationLogs').pushObject(log))
      .catch((err) => this.get('notify').warning(err));
  }

  @action
  created(data) {
    if (data.type === 'EmailMessage') {
      this.callAllEmailMessagesQuery();
    } else if (data.type === 'RestaurantVote') {
      this.fetchRestaurantChoices();
    } else if (data.type === 'NotificationLog') {
      this.fetchNotificationLog(data.id);
    }
  }

  @action
  changed(data) {
    if (data.type === 'RestaurantVote') {
      this.fetchRestaurantChoices();
    } else if (data.type === 'NotificationLog') {
      this.fetchNotificationLog(data.id);
    }
  }

  @action
  estimateUpdated() {
    this.get('order').belongsTo('arrivalEstimate').reload();
  }

  @action
  handleOrderDidFail(errorObject) {
    this.get('ordersEditController').send('handleDidFail', errorObject);
  }

  @action
  handleOrderDidSave() {
    this.get('ordersEditController').send('handleDidSave');
  }

  @action
  searchForClients(search) {
    const area = this.get('order.area.city');

    return this.store
      .query('client', {
        filter: {
          search,
          area
        }
      })
      .then((clients) => this.set('searchedClients', clients));
  }

  @action
  searchForDeliveryLocations(search) {
    const client = this.get('order.client.id');

    return this.store
      .query('delivery-location', {
        filter: {
          search,
          client
        },
        page: {
          offset: 0,
          limit: 10
        }
      })
      .then((deliveryLocations) => this.set('searchedDeliveryLocations', deliveryLocations));
  }

  @action
  searchForContacts(name) {
    const client = this.get('order.client');

    const queryOptions = {
      filter: {
        name
      },
      page: {
        offset: 0,
        limit: 8
      }
    };

    client
      .query('orderContacts', queryOptions)
      .then((orderContacts) => this.set('searchedOrderContacts', orderContacts));

    client
      .query('adminContacts', queryOptions)
      .then((adminContacts) => this.set('searchedAdminContacts', adminContacts));
  }

  @action
  handleDidChangeClient() {
    const order = this.get('order');
    const client = order.get('client');
    const orderService = this.get('orderService');

    if (!client) {
      orderService.updateOrderDetailsWithClientDefaults(order, client);
      return;
    }

    this.set('isClientDetailsLoading', true);

    this.get('store')
      .findRecord('client', client.get('id'), {
        include: [
          'account-manager',
          'admin-contacts',
          'admins',
          'delivery-locations.location',
          'meal-plan',
          'owner',
          'payment-cards'
        ].join(','),
        reload: true
      })
      .then((client) => orderService.updateOrderDetailsWithClientDefaults(order, client))
      .then((_) => this.fetchClientContacts())
      .finally((_) => this.set('isClientDetailsLoading', false));
  }

  @action
  handleRestaurantDidChange(restaurant) {
    if (!restaurant) {
      return;
    }

    this.set('isRestaurantDetailsLoading', true);

    restaurant
      .applyDefaultsTo(this.get('order'))
      .finally((_) => this.set('isRestaurantDetailsLoading', false));
  }

  @action
  handleTimeChanges(type) {
    const order = this.get('order');
    const deliverAt = moment(order.get('deliverAt'));
    const deadlineAt = moment(order.get('deadlineAt'));

    if (type == 'deliverAt') {
      const leadTimeInMinutes = order.get('area.deliveryLeadTime') || 30;
      const pickupAt = deliverAt.subtract(leadTimeInMinutes, 'minutes').toDate();
      const properties = { pickupAt };

      // must be done async
      this.get('orderService')
        .isOutsideHours(deliverAt)
        .then((isOutsideHours) => order.set('outsideHours', isOutsideHours));

      if (order.get('isGroupOrder')) {
        properties.expiresAt = deliverAt.subtract(1, 'seconds').toDate();
        this.get('notify').warning('You may need to modify the team deadline');
      }

      if (deadlineAt.isAfter(deliverAt)) {
        properties.deadlineAt = deliverAt.subtract(1, 'hours').toDate();
      }

      order.setProperties(properties);
    }

    order.validate({ only: ['pickupAt', 'deliverAt', 'expiresAt', 'deadlineAt'] });
  }

  @action
  handleOrderBecameDirty() {
    this.set('order._dirty', true);
  }

  @action
  handleOrderBecameClean() {
    this.set('order._dirty', false);
  }
}
