import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
import { camelizeObject } from 'renard/transforms/object';
import config from 'star-fox/config/environment';
import FoodeeAdapter from 'renard/adapters/foodee';

@classic
export default class Application extends FoodeeAdapter.extend(DataAdapterMixin) {
  namespace = config.APP.apiV3Namespace;
  authorizer = 'authorizer:devise';

  @service
  userSession;

  @service
  notify;

  @service
  secondaryStore;

  get host() {
    return this.useSecondaryHost ? config.SECONDARY_API_HOST ?? config.API_HOST : config.API_HOST;
  }

  buildURL(modelName, id, snapshot, requestType, query) {
    this.useSecondaryHost = Boolean(snapshot?.adapterOptions?.useSecondaryHost);
    const ret = super.buildURL(modelName, id, snapshot, requestType, query);
    this.useSecondaryHost = false;
    return ret;
  }

  /**
   * Additional headers to be included with every request.
   * @type {Object}
   */
  get headers() {
    const headers = {
      'Content-Type': 'application/vnd.api+json',
      SESSION_ID: this.get('userSession.sessionId'),
      USER_ID: this.get('userSession.userId')
    };

    if (this.userSession.session.isAuthenticated) {
      let { email, token } = this.userSession.session.data.authenticated;
      headers['Authorization'] = `Token token="${token}", email="${email}"`;
    }

    return headers;
  }

  /**
   * @override
   * @method handleResponse
   * @param  {Number} status
   * @param  {Object} headers
   * @param  {Object} payload
   * @param  {Object} requestData - the original request information
   * @return {Object | DS.AdapterError} response
   */
  handleResponse(status, headers, payload, requestData) {
    if (payload && payload.meta !== undefined) {
      payload.meta = camelizeObject(payload.meta);
    }

    if (status === 500) {
      this.get('notify').error(
        `Something has gone wrong on the server. It is unlikely you can do anything to resolve this. Please post in #help-requests to help us figure this out please include the following id '${headers['X-Request-Id']}'`,
        {
          closeAfter: 90000
        }
      );
    }

    return super.handleResponse(status, headers, payload, requestData);
  }

  duplicate(model, includedValues = '', queryParams = null) {
    const modelName = model.get('constructor.modelName');
    let url = `${this.buildURL(modelName, model.get('id'))}/duplicate?include=${includedValues}`;

    if (queryParams) {
      url += `&${queryParams}`;
    }

    return this.ajax(url, 'POST', { data: model.serialize() }).then((payload) => {
      model.store.pushPayload(modelName, payload);

      return model.store.peekRecord(modelName, payload.data.id);
    });
  }
}
