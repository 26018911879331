import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class RulesRule extends Application {
  attrs = {
    actions: {
      serialize: false
    },
    negativeActions: {
      serialize: false
    },
    errorActions: {
      serialize: false
    }
  };
}
