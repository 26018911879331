import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';

@classic
export default class ShowController extends Controller {
  /** @type {mealPlanningInstance} */
  @alias('model.mealPlanningInstance')
  mealPlanningInstance;
}
