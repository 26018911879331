import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@tagName('')
export default class CardFor extends Component {
  record = null;

  readonlyActionStateButton = true;

  @computed('record')
  get cardName() {
    return 'cards/' + this.get('record.constructor.modelName') + '-card';
  }

  onClose() {}
}
