import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@tagName('')
export default class CheckboxControl extends Component {
  /** @type {string} */
  toggleLabel = '';

  /** @type {boolean} */
  isToggle = false;

  /** @type {boolean} if the toggle should be disabled */
  isDisabled = false;

  /** @type {boolean} if the toggle should be readonly */
  readonly = false;

  /** @type {string} */
  @computed('toggleLabel', 'isDisabled', 'readonly')
  get toggleClasses() {
    const ret = [];

    if (this.get('readonly')) {
      ret.push('readonly');
    }

    if (this.get('isToggle')) {
      ret.push('toggle');
    }

    if (this.get('isDisabled')) {
      ret.push('disabled');
    }

    if (!this.get('toggleLabel')) {
      ret.push('fitted');
    }

    return ret.join(' ');
  }

  onChange() {}
}
