import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { arg } from 'ember-arg-types';
import { dasherize, decamelize } from '@ember/string';
import { string } from 'prop-types';

class Property {
  name;
  type;

  constructor(name, type) {
    this.name = decamelize(name);
    this.type = type;
  }

  get values() {
    return [];
  }
}

class Attribute extends Property {
  icon = 'circle outline';
  isRelationship = false;
  isAttribute = true;

  constructor(name, type) {
    super(name, type);
  }
}

class Relationship extends Property {
  store;
  isRelationship = true;
  isAttribute = false;

  constructor(store, name, type) {
    super(name, type);
    this.store = store;
    this.record = this.store.createRecord(dasherize(type), {});
  }

  get attributes() {
    const record = this.record;
    const ret = [];

    if (record) {
      record.eachAttribute((_, { name, type, isFragment }) => {
        if (!isFragment) {
          ret.push(new Attribute(name, type));
        }
      });
    }

    return ret.sortBy('name');
  }

  get relationships() {
    const record = this.record;
    const ret = [];

    if (record) {
      record.eachRelationship((_, { name, type, kind }) => {
        const klass = kind === 'belongsTo' ? BelongsTo : HasMany;
        ret.push(new klass(this.store, name, type));
      });

      record.eachAttribute((_, data) => {
        const { name, type, isFragment } = data;
        if (isFragment) {
          const fragmentType = type.split('$').at(-1);
          ret.push(new BelongsTo(this.store, name, fragmentType));
        }
      });
    }

    return ret.sortBy('isHasMany', 'name');
  }

  get values() {
    return this.attributes.concat(this.relationships);
  }

  // todo add unloading hook for these when we clear out the store
}

class BelongsTo extends Relationship {
  isBelongsTo = true;
  isHasMany = false;
  icon = 'angle right';
}

class HasMany extends Relationship {
  isBelongsTo = false;
  isHasMany = true;
  icon = 'angle double right';

  get values() {
    return [
      new BelongsTo(this.store, 'first', this.type),
      new BelongsTo(this.store, 'last', this.type),
      new Attribute('size', 'number')
    ];
  }
}

export class RootRecord extends BelongsTo {
  icon = 'angle right';

  constructor(store, recordType) {
    super(store, 'root', recordType);
  }

  /**
   * This property is a conversion of a property path into a collection
   * of the classes defined above that make rendering the selections possible
   * @param {string} path
   * @param {string} recordType
   * @param {Store} store
   * @return {Array<{relationship:, selected:}>}
   */
  static pathProperties(path, recordType, store) {
    const rootRecord = new RootRecord(store, recordType ?? 'Order');

    return (
      path
        // Tokenize the path foo.bar.baz -> ['foo', 'bar', 'baz']
        .split('.')
        // Starting with the root property create an array of properties based on the path
        // ['foo', 'bar', 'baz'] => [Root, BelongsTo(foo), BelongsTo(bar), Attribute(baz)]
        .reduce((acc, name) => [...acc, acc.at(-1).values.findBy('name', name)], [rootRecord])
        // Collect the properties into pairs of relationship and selected
        // [Root, BelongsTo(foo), BelongsTo(bar), Attribute(baz)] =>
        // [
        //   {relationship: Root, selected: BelongsTo(foo)},
        //   {relationship: BelongsTo(foo), selected: BelongsTo(bar)},
        //   {relationship: BelongsTo(bar), selected: Attribute(baz)},
        //   {relationship: Attribute(baz), selected: null},
        //   {relationship: null, selected: null},
        // ]
        .map((relationship, index, arr) => ({ relationship, selected: arr[index + 1] }))
        // Filter the last pair will be empty, and the second last pair will be only an attribute (sometimes)
        .filter((_) => _.relationship?.isRelationship)
    );
  }
}

export default class FormControlsPathSelect extends Component {
  // value is expected to be a path delimited by
  @arg
  value = '';

  @arg(string.required)
  recordType = 'Order';

  @service
  store;

  @arg
  onChange = () => {};

  @arg
  onTypeChange = () => {};

  get pathProperties() {
    return RootRecord.pathProperties(this.value, this.recordType, this.store);
  }

  @action
  handleChange(value, index) {
    const tokens = this.value.split('.').slice(0, index);
    const newValue = [...tokens, value.name].map(decamelize).join('.');
    this.onChange(newValue);
    this.onTypeChange(value.type);
  }

  @action
  notifyCurrentType() {
    this.onTypeChange(this.pathProperties.lastObject.selected?.type);
  }
}
