import classic from 'ember-classic-decorator';
import SearchAction from './base';

@classic
export default class Company extends SearchAction {
  /** @override */
  modelName = 'company';

  /** @override */
  filterSearchName = 'name';
}
