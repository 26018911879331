import classic from 'ember-classic-decorator';
import Tether from 'ember-tether/components/ember-tether';

@classic
export default class FdeTether extends Tether {
  /**
   * Override me for custom behavior
   * @param _event
   */
  update(_event) {}

  /**
   * Handle the update event and add some information to the event that we might care about
   *
   * @param event
   */
  _update(event) {
    const elementBounding = this._tether.element.getBoundingClientRect();
    const targetBounding = this._tether.target.getBoundingClientRect();
    const elementCenterX = elementBounding.x + elementBounding.width / 2;
    const targetCenterX = targetBounding.x + targetBounding.width / 2;
    const xDelta = targetCenterX - elementCenterX;

    const elementCenterY = elementBounding.y + elementBounding.height / 2;
    const targetCenterY = targetBounding.y + targetBounding.height / 2;
    const yDelta = targetCenterY - elementCenterY;

    event.element = this._tether.element;
    event.target = this._tether.target;
    event.xDelta = xDelta;
    event.yDelta = yDelta;

    this.update(event);
  }

  addTether() {
    super.addTether();
    this._update = this._update.bind(this);
    this._tether && this._tether.on('update', this._update);
  }

  willDestroyElement() {
    super.willDestroyElement();
    this._tether && this._tether.off('update', this._update);
  }
}
