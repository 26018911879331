import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { mapBy } from '@ember/object/computed';
import { action } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('fde-discount-form-for ui form')
export default class DiscountFormFor extends Component {
  /** @type {Service} */
  @service
  session;

  /** @type {boolean} */
  isReadonly = false;

  /** @type {object} Discount object. */
  discount = null;

  /** @type {DiscountCode[]} */
  discountCodes = null;

  /** @type {string[]} */
  @mapBy('discountCodes', 'description')
  discountCodeDescriptions;

  /** @type {string} */
  labelSize = '200px';

  /** @type {function} callback fired when a new discount is saved  */
  didSave() {}

  /** @type {function} callback fired when a new discount is destroyed  */
  didDestroy() {}

  @action
  updateDescription(discountCode) {
    this.set(
      'discount.description',
      this.get('discountCodes').findBy('codeInteger', discountCode).get('description')
    );
  }
}
