import Mixin from '@ember/object/mixin';

export default Mixin.create({
  validations: {
    name: { presence: { message: `Name can't be blank'` } },
    pickupLocations: {
      custom: [
        {
          validation(key, value, restaurant) {
            return restaurant.get('pickupLocations.firstObject.id') || !restaurant.get('active');
          },

          message() {
            return `An active restaurant requires at least one pickup location.`;
          }
        }
      ]
    },

    leadTime: {
      numericality: {
        greaterThanOrEqualTo: 0,
        message: "lead time can't be less than zero. Most restaurants require 16."
      }
    }
  }
});
