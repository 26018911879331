import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';

@classic
export default class ShowMenusIndexRoute extends Route {
  /**
   * @returns {{restaurant: Restaurant}}
   */
  model() {
    const { restaurant } = this.modelFor('logged-in.restaurants.show');

    return RSVP.hash({
      restaurant,
      dietaryTags: this.store.findAll('dietary-tag'),
      drafts: this.store.query('menu', {
        filter: {
          draft: true,
          restaurantId: restaurant.id
        }
      }),
      menus: this.store.query('menu', {
        include: [
          'area',
          'menu-groups.food-types',
          'menu-groups.meal-types',
          'menu-groups.menu-items.food-types',
          'menu-groups.menu-items.meal-types',
          'menu-groups.menu-items.dietary-tags',
          'menu-groups.menu-items.tax-rate',
          'menu-groups.menu-items.menu-option-groups.menu-option-items.dietary-tags'
        ].join(','),
        filter: {
          active: true,
          restaurantId: restaurant.id
        }
      })
    });
  }
}
