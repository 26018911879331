import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@classNames('fde-logistics-desk-case')
export default class LateNotification extends Component {
  /** @type {Service} */
  @service
  session;

  /** @type {Service} */
  @service
  store;

  /** @type {Service} */
  @service
  notify;

  /** @type {Service} */
  @service
  ajax;

  /** @type {Order} */
  order = null;

  /**
   * @type {DeskCase}
   * Because a late notification also needs to trigger a Desk case
   */
  deskCase = null;

  /** @type {?number} */
  @alias('deskCase.caseValue')
  minutesLate;

  /** @type {string} Unique id to prevent multiple modals opening */
  @computed
  get modalId() {
    return `fde-notify-concierge-modal-button ${this.get('elementId')}`;
  }

  /** @type {boolean} */
  overrideMessage = false;

  /** @type {User} */
  @alias('order.owner')
  recipient;

  /** @type {boolean} */
  isLoading = false;

  /** @type {number[]} Number of minutes late for a DeskCase */
  caseValues = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

  /**
   * Recomputes default message and set Desk case
   * Gets dynamically added/removed as an observer
   */
  onMinutesLateChanged() {
    if (this.get('overrideMessage')) {
      return;
    }

    this.set('deskCase.caseMessage', this.get('defaultMessage'));
  }

  /**
   * @type {string}
   * The default concierge message, this can be overwritten when overrideMessage is true
   */
  @computed('minutesLate', 'recipient', 'order')
  get defaultMessage() {
    const orderIdentifier = this.get('order.identifier');
    const restaurantName = this.get('order.restaurant.name');
    const recipientName = this.get('recipient.firstName');
    const minutesLate = this.get('minutesLate');
    const latePhrase = minutesLate ? `about ${minutesLate} minutes behind` : `late`;

    return [
      `Hi ${recipientName}, this is Foodee.`,
      `Your order ${orderIdentifier} from ${restaurantName} is running ${latePhrase}.`,
      `Sorry about that & we'll see you soon.`
    ].join('\n\n');
  }

  closeModal(modal) {
    modal.get('modal').closeModal(modal, modal.get('id'));
  }

  resetDeskCase() {
    this.resetDeskCase();
  }

  /**
   * POST the message to the server to trigger an SMS notification
   */
  sendSmsLateNotification() {
    this.set('isLoading', true);
    const notify = this.get('notify');

    const payload = {
      message: this.get('deskCase.caseMessage')
    };

    return this.get('order')
      .sendSmsLateNotification({ data: payload })
      .then(() => notify.success('Message sent successfully'))
      .catch((err) => notify.error(`Whoops! We couldn't send that message: ${err}`))
      .finally(() => this.set('isLoading', false));
  }

  @action
  handleDidSave(modal) {
    this.sendSmsLateNotification();
    this.cleanUpModal();
    this.resetDeskCase();
    this.closeModal(modal);
  }

  @action
  cleanUpModal() {
    this.removeObserver('minutesLate', this, 'onMinutesLateChanged');
  }

  @action
  setDeskCaseForSms() {
    const deskCase = this.get('deskCase');
    const defaultMessage = this.get('defaultMessage');
    const order = this.get('order');
    const user = this.get('session.user');

    this.addObserver('minutesLate', this, 'onMinutesLateChanged');

    deskCase.setProperties({
      caseMessage: defaultMessage,
      order,
      user,
      caseStatus: 'closed',
      caseRequest: 'SMS Client'
    });
  }
}
