import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class InternalRoute extends Route {
  model(_params) {
    return this.modelFor('logged-in.clients.edit');
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('company', this.store.createRecord('company'));
  }
}
