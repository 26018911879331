import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@tagName('div')
@classNames('fde-tables-reorderable-table')
export default class TableComponent extends Component {
  /** @type {DS.Model} the model which is the source of the collection */
  model = null;

  /** @type {string} the key which points to the collection on the model */
  collectionKey = '';

  /** @type {string} the key that points at a value that would represent if the collectino is loading */
  @computed('collectionKey')
  get loadingKey() {
    return `${this.get('collectionKey')}.isFulfilled`;
  }
}
