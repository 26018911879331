import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import $ from 'jquery';
import { guidFor } from '@ember/object/internals';
import { run } from '@ember/runloop';

@classic
@tagName('')
@classNames('fde-context-menu')
export default class ContextMenu extends Component {
  /** @type {?String} */
  targetElementSelector = null;

  /** @type {?jQuery} */
  $contextMenuAnchor = null;

  /** @type {boolean} */
  isShowing = false;

  /** @type {boolean} */
  isContextMenuAdded = false;

  /** @type {String} */
  @computed
  get contextMenuAnchorId() {
    return `${guidFor(this)}_context-menu-anchor`;
  }

  /** Adds the context menu anchor, an invisible div that the context menu anchors off of */
  addContextMenuAnchor() {
    if (!this.get('isContextMenuAdded')) {
      this.set('isContextMenuAdded', true);

      const $contextMenuAnchor = $(
        `<div class='fde-context-menu_anchor' id='${this.get('contextMenuAnchorId')}'></div>`
      );
      $('body').append($contextMenuAnchor);

      this.set('$contextMenuAnchor', $contextMenuAnchor);
    }
  }

  /** Removes the context menu anchor */
  removeContextMenuAnchor() {
    if (this.get('isContextMenuAdded')) {
      this.set('isContextMenuAdded', false);
      this.get('$contextMenuAnchor').remove();
      this.set('$contextMenuAnchor', null);
    }
  }

  /**
   * Positions the context menu anchor to the specified X,Y coordinates
   * @param {number} clientX
   * @param {number} clientY
   */
  positionContextMenuAnchor(clientX, clientY) {
    const $contextMenuAnchor = this.get('$contextMenuAnchor');
    $contextMenuAnchor.css({
      left: `${clientX}px`,
      top: `${clientY}px`
    });
  }

  /** Shows the context menu */
  showContextMenu() {
    this.set('isShowing', true);
  }

  /** Hides the context menu */
  hideContextMenu() {
    this.set('isShowing', false);
  }

  /** Checks if the target is a match of the selector, or if the selector is an ancestor of the target */
  isTargetMatch(target) {
    const $target = $(target);
    const foundElements = $(this.get('targetElementSelector'));

    const targetMatchesSelector = foundElements.toArray().includes(target);
    const targetHasAncestorWithSelector = $target.closest(this.get('targetElementSelector')).length;

    return targetMatchesSelector || targetHasAncestorWithSelector;
  }

  /**
   * Handler for the contextMenu event, handles opening and closing the context menu
   * @param {Event} e
   */
  onContextMenu(e) {
    this.hideContextMenu();
    this.removeContextMenuAnchor();
    this.unwireDismissOnDocument();

    if (this.isTargetMatch(e.target)) {
      e.preventDefault();

      run.next((_) => {
        this.addContextMenuAnchor();
        this.positionContextMenuAnchor(e.clientX, e.clientY);
        this.showContextMenu();
        this.wireDismissOnDocument();
      });
    }
  }

  /** Handles click event on document, for dimissing the context menu */
  onDocumentClick() {
    this.hideContextMenu();
    this.removeContextMenuAnchor();
    this.unwireDismissOnDocument();
  }

  /** Wires up the dismiss on document click */
  wireDismissOnDocument() {
    const onDocumentClick = this.get('onDocumentClick').bind(this);
    document.addEventListener('click', onDocumentClick);
    this.set('_onDocumentClick', onDocumentClick);
  }

  /** Tearsdown up the dismiss on document click */
  unwireDismissOnDocument() {
    const onDocumentClick = this.get('_onDocumentClick');
    document.removeEventListener('click', onDocumentClick);
  }

  /** @override */
  didInsertElement() {
    super.didInsertElement(...arguments);
    const onContextMenu = this.onContextMenu.bind(this);
    this.set('_onContextMenu', onContextMenu);
    document.addEventListener('contextmenu', onContextMenu);
  }

  /** @override */
  willDestroyElement() {
    const onContextMenu = this.get('_onContextMenu');
    document.removeEventListener('contextmenu', onContextMenu);
    this.unwireDismissOnDocument();
    super.willDestroyElement(...arguments);
  }
}
