import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import { alias, equal } from '@ember/object/computed';
import $ from 'jquery';
import EmberObject, { action, computed } from '@ember/object';
import moment from 'moment-timezone';

@classic
export default class CapacitiesController extends Controller {
  /** The day range used to draw the table (1 week long) as a string */
  dateRange = null;

  /**
   * We're adding 1 day because the week starts on a Sunday but the
   * meal planning instance date range starts on a Monday
   */
  @computed('dateRange')
  get monday() {
    const dateRange = this.get('dateRange');
    const monday = dateRange ? dateRange.split(',')[0] : moment().startOf('week');

    return moment(monday).startOf('week').add(1, 'day').format('YYYY-MM-DD');
  }

  /**
   * We're adding 1 day  and subtracting 1 week because planning weeks start on Mondays
   * but the capacity page date range starts on a Sunday
   */
  @computed('monday')
  get prevMonday() {
    return moment(this.get('monday')).subtract(1, 'week').format('YYYY-MM-DD');
  }

  /** Dom ID for the modal of the form to edit the capacity tranches */
  editCapacityTranchesModalDOMId = 'edit-restaurant-capacity-tranches';

  /** Time format for tranche times */
  format = 'hh:mmA';

  /** Params used to fetch the data */
  queryParams = ['dateRange'];

  /** Reference date for the current table */
  @alias('model.referenceDate')
  referenceDate;

  @computed('referenceDate')
  get startOfWeek() {
    return this.get('referenceDate').startOf('week').format('YYYY-MM-DD');
  }

  @computed('referenceDate')
  get endOfWeek() {
    return this.get('referenceDate').endOf('week').format('YYYY-MM-DD');
  }

  isLoading = false;

  /** Compares the current route for the purposes of sliding out a drawer-pane to view an order's details */
  @equal('router.currentRouteName', 'logged-in.restaurants.show.capacities.index')
  isCurrentRoute;

  /*
   * Computed attributes
   */

  /** The current restaurant */
  @alias('model.restaurant')
  restaurant;

  @alias('restaurant.areas.firstObject.id')
  area_id;

  /**
   * RestaurantCapacityTranches sorted by start time
   * @return {RestaurantCapacityTranche[]}
   */
  @computed('model.restaurantCapacityTranches')
  get tranches() {
    return this.get('model.restaurantCapacityTranches').sortBy('startTime');
  }

  /**
   * Clone of `tranches` used for the form inputs/side effects
   * @return {RestaurantCapacityTranche[]}
   */
  @computed('tranches')
  get tranchesForForm() {
    return this.get('tranches').reduce((acc, tranche, index) => {
      acc[`tranche-${index}`] = {
        capacity: tranche.get('capacity'),
        start_time: tranche.get('startTimeUTC'),
        end_time: tranche.get('endTimeUTC')
      };
      return acc;
    }, {});
  }

  /**
   * Construct a set of daily utilization sums indexed by RestaurantCapacityTranche id and day
   * @returns {Object} {1: {23: {sum: 55, orderIds: [1, 2]}}
   *   1: RestaurantCapacityTranche.id
   *   23: day
   *   55: utilization sum
   *   [1, 2]: order ids
   */
  @computed('tranches', 'weekDays')
  get utilizations() {
    const utilizations = this.get('model.restaurantCapacityTranches.meta.utilizationByDay');

    return utilizations.reduce((acc, util) => {
      const day = moment(util.day).format('D');

      if (!acc[util.id]) {
        acc[util.id] = {};
      }

      acc[util.id][day] = {
        sum: util.utilizationSum,
        orders: util.orders,
        reservations: util.reservations
      };

      return acc;
    }, {});
  }

  /**
   * Creates array of dates for the current week
   * @returns {Array}
   */
  @computed('model')
  get weekDays() {
    const startOfWeek = this.get('startOfWeek');
    const days = [...Array(7)];

    return days.map((_, i) =>
      EmberObject.create({
        day: moment(startOfWeek).add(i, 'days').format('ddd'),
        date: moment(startOfWeek).add(i, 'days').format('D'),
        month: moment(startOfWeek).add(i, 'days').format('MMM')
      })
    );
  }

  /*
   * Private methods
   */

  /**
   * setDateRange
   * Sets the day range query param again, based on the current reference moment
   */
  setDateRange() {
    const date = this.get('referenceDate');
    const dateFormat = 'Y-M-DD';

    const startOfWeek = date.startOf('week').format(dateFormat);
    const endOfWeek = date.endOf('week').format(dateFormat);

    this.set('dateRange', `${startOfWeek},${endOfWeek}`);
  }

  @action
  today() {
    this.set('referenceDate', moment());
    this.setDateRange();
  }

  @action
  nextWeek() {
    this.get('referenceDate').add(1, 'week');
    this.setDateRange();
  }

  @action
  prevWeek() {
    this.get('referenceDate').subtract(1, 'week');
    this.setDateRange();
  }

  @action
  editTranches() {
    $(`#${this.get('editCapacityTranchesModalDOMId')}`)
      .modal({
        closable: true,
        context: '.ember-application'
      })
      .modal('show');
  }

  @action
  updateTranches() {
    const tranchesForForm = this.get('tranchesForForm');
    const serializedTranches = Object.keys(tranchesForForm).reduce((acc, key) => {
      acc.push(tranchesForForm[key]);
      return acc;
    }, []);

    this.set('isLoading', true);

    return this.get('restaurant')
      .bulkUpdateTranches({ tranches: serializedTranches })
      .then(() => this.send('refreshModel'))
      .then(() => $(`#${this.get('editCapacityTranchesModalDOMId')}`).modal('hide'))
      .finally(() => this.set('isLoading', false));
  }
}
