import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';

import HasManyQuery from 'ember-data-has-many-query';
import { isBlank } from '@ember/utils';
import { union } from '@ember/object/computed';
import { modelAction } from 'ember-custom-actions';

const { ModelMixin } = HasManyQuery;

export const RuleTypeConversionMap = Object.freeze({
  'rules-cron': 'Rules::Cron',
  'rules-rule': 'Rules::Rule'
});

export const RuleNameConversionMap = Object.freeze({
  'rules-cron': 'Scheduled',
  'rules-rule': 'Evented'
});

export default Model.extend(ModelMixin, {
  /*
   * Attributes
   */
  ruleType: attr('rule-type', { defaultValue: 'rules-rule' }),
  recordType: attr('string', { defaultValue: 'Order' }),
  name: attr('string', { defaultValue: 'Default rule name' }),
  highlightedName: attr('string'),
  handlesEvents: attr('array'),
  allEvents: attr('array'),
  allRoles: attr('array'),
  active: attr('boolean'),
  runOnlyOncePerRecord: attr('boolean', { default: true }),
  cron: attr('string', { defaultValue: '* * * * *' }),
  nullaryScopes: attr('array'),
  recordScopes: attr('array'),
  role: attr('string'),

  /*
   * Relationships
   */
  owner: belongsTo('owner'),
  actions: hasMany('rules-action'),
  negativeActions: hasMany('rules-action'),
  errorActions: hasMany('rules-action'),
  runLogs: hasMany('rules-run-log', { inverse: 'rule' }),
  predicates: hasMany('rules-predicate', { inverse: 'rule' }),

  allActions: union('negativeActions', 'failedActions', 'actions'),

  execute: modelAction('execute'),

  get configured() {
    return Boolean(this.allActions.length || this.predicates.length);
  },

  get roleName() {
    return isBlank(this.role) ? 'No Role' : this.role;
  }
});
