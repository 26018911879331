import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias, not } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import url from 'renard/utils/url';

@classic
export default class IndexController extends Controller {
  /** @type {Service} */
  @service
  ajax;

  /** @type {Service} */
  @service
  notify;

  /** @type {User} */
  @alias('model.user')
  user;

  /** @type {CommunicationPreference} */
  @alias('user.communicationPreference')
  communicationPreference;

  /** @type {boolean} */
  @alias('communicationPreference.sms')
  smsEnabled;

  /** @type {boolean} */
  @not('user.lastSignInAt') userNeverLoggedIn;

  /** @type {boolean} */
  @not('user.invitationSentAt') userNeverInvited;

  get isRestaurantUser() {
    return this.user.roles.find((e) => e.name === 'restaurant');
  }

  get isRestaurantOwner() {
    return this.user?.restaurants?.firstObject?.owner?.get('email') === this.user.email;
  }

  get invitationPending() {
    return !this.user.invitationAcceptedAt && this.user.invitationSentAt;
  }

  get showResetPassword() {
    return (
      this.userNeverLoggedIn ||
      this.invitationPending ||
      (!this.userNeverLoggedIn && this.userNeverInvited)
    );
  }

  /** @type {string} */
  @computed('user.roles.[]')
  get hostUrl() {
    if (this.isRestaurantUser) {
      return url.getArcticFoxUrl();
    } else if (this.get('user.isExclusivelySwiftFoxUser')) {
      return url.getSwiftFoxUrl();
    } else {
      return url.getStarFoxUrl();
    }
  }

  /** @type {string} */
  @computed('user.resetPasswordToken')
  get resetPasswordUrl() {
    if (this.isRestaurantUser) {
      return `${this.get('hostUrl')}/reset-password?token=${
        this.get('user.resetPasswordToken') || 'XXXXX'
      }`;
    }

    return `${this.get('hostUrl')}/password-reset/${
      this.get('user.resetPasswordToken') || 'XXXXX'
    }`;
  }

  /** @type {string} */
  @computed('user.invitationToken')
  get userInvitationUrl() {
    if (this.isRestaurantUser) {
      return `${this.get('hostUrl')}/activate-account?token=${
        this.get('user.invitationToken') || 'XXXXX'
      }&owner=${this.isRestaurantOwner ? 'true' : 'false'}`;
    }

    return `${this.get('hostUrl')}/user-invite/${this.get('user.invitationToken') || 'XXXXX'}`;
  }

  @action
  handleDidFail(response) {
    const notify = this.get('notify');

    response.errors.forEach((err) => notify.error(err.detail));
  }

  @action
  sendPasswordReset() {
    const user = this.get('user');

    user.resetPassword().then((_) => user.reload());
  }

  @action
  sendUserInvite() {
    this.user.createInvitation().then((_) => this.user.reload());
  }

  @action
  smsOptIn() {
    if (confirm(`This will send an opt-in text message to ${this.get('user.smsNumber')}`)) {
      const notify = this.get('notify');

      this.set('isLoading', true);

      return this.get('user')
        .sendSmsOptIn()
        .then(() => notify.success('Message sent successfully'))
        .catch((err) => notify.error(err))
        .finally(() => this.set('isLoading', false));
    }
  }
}
