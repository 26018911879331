import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class SalesforceCase extends Model {
  // Attributes
  // CaseNumber: attr('string'),
  // Case_Reason__c: attr('string'),
  // CreatedDate: attr('date'),
  // IsDeleted: attr('boolean'),
  // Priority: attr('string'),
  @attr('string')
  caseReason;

  @attr('string')
  status;

  @attr('string')
  origin;

  @attr('string')
  description;

  @attr('string')
  subject;

  @attr('string')
  caseType;

  // Relationships
  @belongsTo('order')
  order;

  // client: belongsTo('client'),
  // restaurant: belongsTo('restaurant'),

  /*
   * Validations
   */
  validations = {
    caseType: { presence: { message: `Please select values for the case reason and estimate.` } },
    caseReason: { presence: { message: `Please select values for the case reason and estimate.` } }
  };
}
