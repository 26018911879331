import classic from 'ember-classic-decorator';
import SearchAction from './base';

@classic
export default class User extends SearchAction {
  /** @override */
  modelName = 'user';

  /** @override */
  filterSearchName = 'search';
}
