import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@tagName('')
export default class CountrySelectControl extends Component {
  /** @type {string} code of the country selected */
  value = '';

  /** @type {boolean} */
  isReadonly = false;

  /** @type {boolean} */
  readonly = false;

  countries = [
    { id: 'CA', label: 'Canada', icon: 'ca flag' },
    { id: 'US', label: 'United States', icon: 'us flag' },
    { id: 'UK', label: 'United Kingdom', icon: 'uk flag' }
  ];

  @computed('value')
  get country() {
    return this.countries.findBy('id', this.value);
  }

  /** @type {function} callback function when the selected value changed */
  onChange(_value) {}

  @action
  handleChange(country) {
    this.onChange(country.id);
  }
}
