import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@tagName('')
export default class InfoCallout extends Component {
  /** @type {string} **/
  icon = 'blue question circle outline';

  /** @type {string} **/
  title = '';

  /** @type {string} **/
  content = '';

  /** @type {string} **/
  position = 'top right';
}
