import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias, not } from '@ember/object/computed';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';
import { modelAction } from 'ember-custom-actions';

@classic
export default class MealPlanningRequirementGroup extends Model {
  /*
   * Attributes
   */
  @attr('string')
  name;

  @attr('dollars')
  budget;

  @attr('number')
  numberOfPeople;

  @attr('string')
  portionSize;

  @attr('boolean')
  isSharingStyleGroup;

  @belongsTo('meal-planning-preference-profile')
  preferenceProfile;

  @hasMany('meal-planning-requirement', { inverse: 'requirementGroup' })
  requirements;

  /*
   * Computed Properties
   */

  @alias('preferenceProfile.menuItems')
  menuItems;

  @alias('name')
  description;

  @not('isValid')
  isNotValid;

  @computed('portionSize')
  get priceKey() {
    const portionSize = this.get('portionSize') || 'balanced';
    return this.get('isSharingStyleGroup')
      ? `${portionSize.toLowerCase()}Price`
      : 'clientPriceCents';
  }

  @computed('requirements.@each.isNotValid')
  get isNotValidOrChildrenAreNotValid() {
    return this.get('isNotValid') || this.get('requirements').any((_) => _.get('isNotValid'));
  }

  @computed('requirements.@each.averagePrice', 'portionSize')
  get expectedPrice() {
    const key = `${this.get('portionSize') || 'balanced'}Price`;
    return this.get('requirements').reduce((acc, r) => acc + r.get(key), 0);
  }

  @computed('requirements.[]', 'budget')
  get inBudgetWarningState() {
    return this.get('budget') < this.get('expectedPrice');
  }

  /** @Creates the requirement group */
  createRequirement = modelAction('create-requirement', {
    method: 'POST',
    pushToStore: true,
    responseType: 'object'
  });

  toSolverJSON() {
    return {
      id: this.get('id'),
      number_of_people: this.get('numberOfPeople'),
      per_person_budget: this.get('budget'),
      requirements: this.get('requirements').map((r) => r.toSolverJSON())
    };
  }

  validations = {
    budget: {
      custom: {
        validation(key, value, model) {
          return model
            .get('requirements')
            .mapBy('maxPrice')
            .every((_) => _ <= value);
        },
        message(_key, _value, _model) {
          return `The max price of all of your requirements must be below the per person budget`;
        }
      }
    }
  };
}
