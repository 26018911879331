import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('event')
@classNameBindings('feedItem.isNew:is-new')
export default class FeedEvent extends Component {
  forceOpen = false;
  feedItem = null;
}
