import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias, and } from '@ember/object/computed';
import Component from '@ember/component';
import { task } from 'ember-concurrency';

@classic
@tagName('')
export default class RestaurantItem extends Component {
  /** @type {Component} **/
  parent = null;

  /** @type {Restaurant} **/
  restaurant = null;

  /** @type {Area} **/
  area = null;

  /** @type {MealPlanning::Requirement} **/
  filterRequirement = null;

  /** @type {boolean} **/
  isExpanded = false;

  /** @type {boolean} **/
  canExpand = true;

  /** @type {boolean} **/
  showUsage = true;

  /** @type {boolean} **/
  canSelect = true;

  /** @type {boolean} **/
  canHighlight = true;

  /** @type {Store} */
  @service
  store;

  /** @type {boolean} **/
  @and('isExpanded', 'activeMenu')
  shouldShowDetails;

  @computed
  get distanceColor() {
    const restaurant = this.get('restaurant');
    let ret = 'green';

    if (restaurant.beyond_extended_radius) {
      ret = 'red';
    } else if (restaurant.in_extended_radius) {
      ret = 'yellow';
    }

    return ret;
  }

  onMouseEnter() {}

  onMouseLeave() {}

  selectRestaurant(_restaurant) {}

  @action
  toggleExpansion() {
    this.toggleProperty('isExpanded');

    const loadingTask = this.get('loadingTask');
    const searchTask = this.get('searchTask');

    if (!this.get('activeMenu')) {
      loadingTask.perform();
    }

    if (searchTask && !this.get('results')) {
      searchTask.perform(this.get('restaurant'));
    }
  }

  /** @type {Menu}} **/
  @alias('loadingTask.lastSuccessful.value')
  activeMenu;

  /** @type {{ aggregates: [], menu_items: []}} **/
  @alias('searchTask.lastSuccessful.value')
  results;

  /** @type {Task} **/
  @task({ restartable: true })
  async loadingTask() {
    const foundRestaurant = await this.store.findRecord('restaurant', this.get('restaurant.key'), {
      include: 'active-menu.menu-groups.menu-items.dietary-tags'
    });
    return foundRestaurant.activeMenu;
  }

  /** @type {Task} **/
  @task({ restartable: true })
  async searchTask() {
    return await this.parent._performSearch(this.restaurant);
  }
}
