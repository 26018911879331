import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import ENV from 'star-fox/config/environment';
import filepicker from 'filepicker-js';

// TODO: extract this to a service so we can test this component properly
filepicker.setKey(ENV.filepicker.key);

@classic
@classNames('fde-filepicker-control fde-can-click')
export default class FilepickerControl extends Component {
  /** @type {*} */
  value = null;

  /** @type {String[]} list of mime types supported in the file picker */
  mimetypes = null;

  /** @type {String[]} list of extensions */
  extensions = null;

  /** @type {number} maximum size in MB */
  maxSize = 2;

  /** @type {string|number|null} ratio that the image can be cropped to */
  cropRatio = null;

  /** @type {function} callback function called when the file has been uploaded */
  onChange() {}

  /** @type {function} callback function called when the file errored uploading */
  onError() {}

  click() {
    const options = {
      maxSize: this.get('maxSize') * 1024 * 1024 // mb
    };

    const conditionallyAdd = (key) => {
      const value = this.get(key);
      if (value) {
        options[key] = value;
      }
    };

    conditionallyAdd('mimetypes');
    conditionallyAdd('cropRatio');
    conditionallyAdd('extensions');

    filepicker.pick(
      options,
      (result) => {
        this.onChange(result.url);
      },
      (FPError) => {
        this.onError(FPError);
      }
    );
  }
}
