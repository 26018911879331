import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';
import $ from 'jquery';
import { AlertStatus } from 'renard/transforms/alert-status';

export const StateToName = {
  draft: 'Draft',
  group_locked: 'Group Locked',
  group_building: 'Group Building',

  pre_quote: 'Pre Quote',
  quoted: 'Quoted',
  client_rejected: 'Client Rejected',
  restaurant_rejected: 'Restaurant Rejected',
  submitted: 'Submitted',
  finalized: 'Finalized',
  confirmed: 'Confirmed',
  scheduled: 'Scheduled',
  driver_confirmed: 'Driver Confirmed',
  driver_at_restaurant: 'Driver at Restaurant',
  picked_up: 'Picked Up',
  driver_at_client: 'Driver at Client',
  delivered: 'Delivered',
  ready_to_eat: 'Ready to Eat',
  reported: 'Reported',
  closed: 'Closed',
  cancelled: 'Cancelled',
  payment_processing: 'Payment Processing',
  payment_failed: 'Payment Failed'
};

export const AlertStatusColors = {
  none: '',
  warning: 'yellow',
  error: 'red',
  good: 'green'
};

const open = function (uri, event) {
  // trigger this event so that the drop down doesn't stay open
  $(window.document.body).click();
  event.stopPropagation();
  window.open(uri, '_blank');
};

@classic
@tagName('')
export default class OrderRow extends Component {
  @service
  notify;

  @service
  appConfiguration;

  @service
  router;

  @computed('router.currentRouteName')
  get editRoute() {
    const isDeliveries = this.get('router.currentRouteName').includes('deliveries');
    return isDeliveries
      ? 'logged-in.logistics.deliveries.index.edit-order'
      : 'logged-in.logistics.index.edit-order';
  }

  /** @type {Order} */
  order = null;

  /** @type {DeskCase} new DeskCase obj */
  deskCase = null;

  couriers = null;
  hasBeenOnScreen = false;
  showPopover = false;

  alertStatus = AlertStatus;

  isFullScreen = false;

  onExpand() {}

  onCollapse() {}

  @computed('order.state')
  get state() {
    const state = this.get('order.state');
    return StateToName[state];
  }

  /** @type {string} Returns order alert status */
  @computed('order.alertStatus')
  get alertStatusColor() {
    const alertStatus = this.get('order.alertStatus');
    return AlertStatusColors[alertStatus];
  }

  @computed('order.clientLocation', 'order.customLocation')
  get clientLocation() {
    let location = this.get('order.clientLocation') || this.get('order.customLocation');

    return location ? location.get('humanize') : 'Location Error';
  }

  @computed('couriers')
  get couriersWithAPINames() {
    return this.get('couriers')
      .filter((courier) => courier.get('apiType'))
      .map((c) => ({
        id: c.get('id'),
        name: c.get('name'),
        areas: c.get('areas'),
        active: c.get('active')
      }));
  }

  @computed('couriersWithApiNames.[]', 'order.area')
  get couriersForOrder() {
    return this.couriersWithAPINames.filter(
      (courier) => courier.active && courier.areas.includes(this.order.area)
    );
  }

  @computed('order.pickupAt')
  get pickupTimeIntervals() {
    const deliverAt = this.get('order.deliverAt');
    const nearestFive = Math.round(moment(deliverAt).minutes() / 5) * 5;

    const orderTimeZone = this.get('order.area.isoTimeZone');
    const endTime = moment.tz(deliverAt, orderTimeZone).minutes(nearestFive);
    const startTime = moment.tz(endTime, orderTimeZone).subtract(60, 'minutes');

    return [
      {
        startTime: startTime.format('HH:mm'),
        endTime: endTime.format('HH:mm')
      }
    ];
  }

  @computed('order.deliverAt', 'order.area.isoTimeZone')
  get manifestDate() {
    const deliverAt = this.get('order.deliverAt');
    const isoTimeZone = this.get('order.area.isoTimeZone');

    return moment.tz(deliverAt, isoTimeZone).format('YYYY-MM-DD');
  }

  fetchOrder() {}

  resetDeskCase() {}

  allocate(payload, message) {
    this.set('allocating', true);

    return this.get('order')
      .allocateDelivery(payload)
      .then(() => {
        this.get('notify').success(message);
      })
      .catch((response) => {
        response.errors.forEach((_) => this.get('notify').error(_.detail, { closeAfter: null }));
      })
      .finally(() => {
        this.set('allocating', false);
        this.set('showPopover', false);
        this.fetchOrder();
      });
  }

  deallocate(payload, message) {
    this.set('allocating', true);

    return this.get('order')
      .deallocateDelivery(payload)
      .then(() => {
        this.get('notify').success(message);
      })
      .catch((response) => {
        response.errors.forEach((_) => this.get('notify').error(_.detail, { closeAfter: null }));
      })
      .finally(() => {
        this.set('allocating', false);
        this.set('showPopover', false);
        this.fetchOrder();
      });
  }

  @action
  allocateCourier(courierId) {
    this.allocate(
      { logistics_type: 'auto', courier_id: courierId },
      'Delivery automatically allocated'
    );
  }

  @action
  deallocateCourier() {
    if (confirm('Are you sure you want to cancel this delivery? This action cannot be undone')) {
      this.deallocate({ force_clear: false }, 'The Delivery was cancelled for the Courier');
    }
  }

  @action
  clearCourier() {
    if (
      confirm(
        'This is a destructive action, all information for Courier will be cleared and the Courier will not receive a cancellation request. Are you sure?'
      )
    ) {
      this.deallocate({ force_clear: true }, 'Courier was completely cleared for this Order');
    }
  }

  @action
  expandRow(e) {
    if (e.target.href) {
      return;
    }

    this.toggleProperty('order.expandRow');

    if (this.get('order.expandRow')) {
      this.onExpand(this.get('order'));
    } else {
      this.onCollapse(this.get('order'));
    }
  }

  @action
  onSelectedDriverChange(driverId) {
    const order = this.get('order');
    this.updateSelectedDriverOnOrder(driverId, order);
  }

  @action
  onPickupTimeChange() {
    this.get('order').save();
  }

  @action
  goToOrderPage(orderId, event) {
    event.preventDefault();
    this.transitionToRoute('logged-in.orders.edit', this.get('order.id'));
  }

  @action
  goToOrderEditPage() {
    this.transitionToRoute('logged-in.orders.edit', this.get('order.id'));
  }

  @action
  goToOrderDriverManifest(event) {
    const areaSlug = this.get('order.area.slug');
    const manifestDate = this.get('manifestDate');
    const driverId = this.get('order.driver.id');

    open(
      `/areas/${areaSlug}/logistics/driver_manifest/?date=${manifestDate}&driver_id=${driverId}`,
      event
    );
  }

  @action
  saveManualForm(driverId) {
    return this.allocate(
      { logistics_type: 'manual', driver_id: driverId },
      'Delivery manually allocated'
    );
  }

  @action
  setOrderAlertStatus(alertStatus) {
    const order = this.get('order');
    order.reload().then((_) => {
      order.set('alertStatus', alertStatus);
      order.save();
    });
  }

  @action
  toggleIsSuperSized() {
    this.set('isSuperSized', !this.isSuperSized);
  }

  @action
  reloadOrder() {
    return this.order.reload();
  }
}
