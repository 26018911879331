import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class AcccountingConsolidationConfiguration extends Application {
  attrs = {
    client: {
      serialize: true
    }
  };
}
