import classic from 'ember-classic-decorator';
import { attributeBindings, classNameBindings, classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';

@classic
@classNames(
  'fde-forms-meal-planning-form-for-day-form-for',
  'ui',
  'attached',
  'segment',
  'vertical',
  'segments'
)
@classNameBindings('isEmpty:secondary')
@attributeBindings('index:data-test-meal-planning-day-form-for')
export default class DayFormFor extends Component {
  @service
  store;

  /** @type {Area} */
  area = null;

  /** @type {MealPlanDay} */
  day = null;

  /** @type {MealPlanInstance|MealPlanTemplate} */
  mealPlan = null;

  /** @type {boolean} */
  canReserve = false;

  /** @type {boolean} */
  readonly = false;

  /** @type {Restaurant[]} */
  restaurants = null;

  // isEmpty: computed.empty('day.meals'),

  /** @type {RestaurantConstraint} */
  selectedRestaurantConstraint = null;

  /** @type {?Function} */
  onSelectRestaurantConstraint = null;

  removeMeal() {}
  addSlot() {}
  removeSlot() {}

  /** @type {Date} */
  @computed('mealPlan.startOfWeek', 'day')
  get dayDate() {
    const startOfWeek = this.get('mealPlan.startOfWeek');
    return startOfWeek ? this.get('day').getDate(startOfWeek) : null;
  }

  /** @type {?Function} */
  onRestaurantChange() {}

  @action
  async addEvent() {
    const date = moment(this.get('dayDate')).add(12, 'hours');
    return this.get('store')
      .createRecord('meal-planning-event', {
        name: 'New Meal',
        instance: this.get('mealPlan'),
        deliverAt: date.toDate(),
        pickupAt: moment(date).subtract(30, 'minutes').toDate(),
        leadTime: 2,
        numberOfPeople: 30
      })
      .save()
      .then((_) => this.get('day.events').pushObject(_));
  }
}
