import Helper from '@ember/component/helper';

export function and([ranking]) {
  if (ranking <= 10) {
    return '🏆';
  } else if (ranking <= 40) {
    return '🥇';
  } else if (ranking <= 70) {
    return '🥈';
  } else {
    return '🥉';
  }
}

export default Helper.helper(and);
