import Helper from '@ember/component/helper';

export function and([ranking]) {
  if (ranking <= 10) {
    return 'Top 90th Percent';
  } else if (ranking <= 40) {
    return 'Top 60th Percent';
  } else if (ranking <= 70) {
    return 'Top 30th Percent';
  } else {
    return 'Bottom 30th';
  }
}

export default Helper.helper(and);
