import { run } from '@ember/runloop';

/**
 * Will first set the property to undefined, then to the value specified on next run loop. Useful for hiding and
 * showing CSS animations in one set call, or forcing computed property dependency chains to recompute.
 * @param {EmberObject} context
 * @param {string} propName
 * @param {*} value
 * @param {?number} resetTimeout Optional timeout to reset the property back to the resetValue passed in, or undefined
 * @param {?*} resetValue Optional resetValue if using resetTimeout.
 */
export function flashSet(context, propName, value, resetTimeout, resetValue) {
  if (context.isDestroyed) {
    return;
  }

  context.set(propName, undefined);
  run.next((_) => !context.get('isDestroyed') && context.set(propName, value));

  if (resetTimeout) {
    run.later((_) => {
      !context.get('isDestroyed') && context.set(propName, resetValue);
    }, resetTimeout);
  }
}

/**
 * Sets the value on the next run loop. Useful for when you need one property to have had side effects (like rendering)
 * before a property can have an effect.
 * @param {EmberObject} context
 * @param {string} propName
 * @param {*} value
 */
export function setNext(context, propName, value) {
  if (context.isDestroyed) {
    return;
  }

  run.next((_) => !context.get('isDestroyed') && context.set(propName, value));
}

/**
 * Will set a property to a value immediately, then reset it to an optionally specified resetValue after a certain
 * period of time. Will reset to undefined if none is specified.
 * @param {EmberObject} context
 * @param {string} propName
 * @param {*} value
 * @param {?number} resetTimeout Optional timeout to reset the property back to the resetValue passed in, or undefined
 * @param {?*} resetValue Optional resetValue if using resetTimeout.
 */
export function setReset(context, propName, value, resetTimeout, resetValue) {
  if (context.isDestroyed) {
    return;
  }

  context.set(propName, value);

  if (resetTimeout) {
    run.later((_) => {
      !context.get('isDestroyed') && context.set(propName, resetValue);
    }, resetTimeout);
  }
}

/**
 * Will set a property to a value at a later time.
 * @param {EmberObject} context
 * @param {string} propName
 * @param {*} value
 * @param {?number} laterTimeout Timeout to set the property
 */
export function setLater(context, propName, value, laterTimeout) {
  if (context.isDestroyed) {
    return;
  }

  run.later((_) => {
    !context.get('isDestroyed') && context.set(propName, value);
  }, laterTimeout);
}
