import Fixture, {
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class SfObservabilityTooltipFixture extends Fixture {
  constructor(_, value, scope) {
    super('data-test-sf-observability-tooltip', value, scope);
  }
}

export const sfObservabilityTooltip = getElementDecoratorFor(SfObservabilityTooltipFixture);
export const sfObservabilityTooltipWithValue = getElementWithValueDecoratorFor(
  SfObservabilityTooltipFixture
);
