import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { run } from '@ember/runloop';
import $ from 'jquery';

@classic
@tagName('')
export default class ActionButton extends Component {
  /** @type {string} the label text in the button **/
  label = null;

  /** @type {string} icon - an optional icon to show beside the button **/
  icon = null;

  /** @type {string} what color the button should be **/
  color = '';

  /** @type {string} any extra semantic styling for the outer element **/
  classes = '';

  /** @type {string} float option set vis css style **/
  floatPosition = '';

  /** @type {boolean} whether we should show the dropdown icon **/
  showCaret = true;

  /** @type {function} a single action to enact when there is only one option **/
  onClick = null;

  /** @type {boolean} whether or not this button is in readonly mode **/
  readonly = false;

  /** @type {boolean} */
  disabled = false;

  /** @type {*} */
  value = null;

  /** @type {*} */
  bubbles = true;

  renderPlaceholder = true;

  @action
  handlePlaceholderClick() {
    this.set('renderPlaceholder', false);
    run.next((_) => $(`#${this.get('dropdownId')}`).click());
  }

  @computed
  get dropdownId() {
    return `${guidFor(this)}-dropdown`;
  }

  /** @type {boolean} whether this is an icon button **/
  @computed('icon', 'label')
  get isIconButton() {
    return !this.get('label') && this.get('icon');
  }

  /** @type {string} classes used to style the display, and colour of this button **/
  @computed('color', 'classes', 'readonly', 'disabled')
  get _uiClasses() {
    const color = this.get('color');
    const classes = this.get('classes');
    const colorClasses = color ? `${color} fde-has-color` : '';
    const iconButton = this.get('isIconButton') ? 'icon' : '';
    const readonly = this.get('readonly') ? 'readonly' : '';
    const disabled = this.get('disabled') ? 'disabled' : '';

    return `fde-controls-action-button button floating ${color} ${classes} ${colorClasses} ${iconButton} ${readonly} ${disabled}`;
  }

  @action
  showOptions(e) {
    if (!this.get('showOptions')) {
      this.set('showOptions', true);
      run.next(() => {
        // bleck
        $(e.target).click();
      });
    }
    e.stopPropagation();
  }

  @action
  handleClick(e) {
    if (this.bubbles === false) {
      e.stopPropagation();
    }
    this.onClick(e);
  }
}
