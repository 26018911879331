import Fixture, {
  // element,
  // elementWithValue,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';
// import { sfUiProgress } from '../ui/progress/fixture-chai';

export default class SfJobStatusFixture extends Fixture {
  constructor() {
    super('data-test-sf-job-status');
  }
}

export const sfJobStatus = getElementDecoratorFor(SfJobStatusFixture);
export const sfJobStatusWithValue = getElementWithValueDecoratorFor(SfJobStatusFixture);
