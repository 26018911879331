import { helper as buildHelper } from '@ember/component/helper';

export function formatJoinedUsersCounts([joinedUsersCounts = null]) {
  if (joinedUsersCounts) {
    const usersWithOrdersCount = joinedUsersCounts['usersWithOrdersCount'];
    const totalJoinedUsersCount = joinedUsersCounts['totalJoinedUsersCount'];

    return `${usersWithOrdersCount}/${totalJoinedUsersCount}`;
  }
}

export default buildHelper(formatJoinedUsersCounts);
