import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';

import { action } from '@ember/object';

@classic
export default class NewController extends Controller {
  /** @type {Restaurants[]} results of searching for restos */
  searchedRestaurants = null;

  @action
  handleDidSubmit() {
    this.send('refreshModel');
    this.transitionToRoute('logged-in.promo-codes');
  }

  @action
  handleDidReset() {
    this.transitionToRoute('logged-in.promo-codes');
  }

  @action
  searchForRestaurants(searchText) {
    return this.store
      .query('restaurant', { filter: { search: searchText } })
      .then((restaurants) => this.set('searchedRestaurants', restaurants));
  }
}
