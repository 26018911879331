import { modifier } from 'ember-modifier';
import { run } from '@ember/runloop';

export default modifier(function autofocus(
  element,
  positional,
  {
    selector = '[tabindex="0"]:not([disabled]), button, input:not([disabled])',
    next = false,
    delay = 0
  } = {}
) {
  const focus = () => {
    const childElement = element.querySelector(selector);

    if (childElement) {
      childElement.focus();
    } else {
      element.focus();
    }
  };

  if (delay !== 0) {
    run.later(null, focus, delay);
  } else if (next) {
    run.next(focus);
  } else {
    focus();
  }
});
