import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import timeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import moment from 'moment-timezone';

export default Controller.extend({
  classNames: 'fde-area-closures',
  queryParams: ['weekOf'],
  weekOf: null,
  fullCalendarPlugins: [timeGridPlugin, interactionPlugin, momentTimezonePlugin],

  /** @type {Service} */
  notify: service(),

  /** @type {Area} */
  area: alias('model.area'),

  timeZone: alias('area.isoTimeZone'),

  /** @type {AreaClosure[]} */
  areaClosures: alias('model.areaClosures'),

  slotDuration: '00:15:00',
  slotLabelInterval: '01:00',
  slotMinTime: '06:00',
  slotMaxTime: '23:00',

  /**
   * Options for the calendar header. It's kind of confusing
   * but here's the documentation: https://fullcalendar.io/docs/header
   *
   * @type {Object}
   */
  headerOptions: {
    left: 'title',
    right: 'today prev,next'
  },

  /** @type {Boolean} */
  isAreaClosureSelected: computed('selectedAreaClosure', function () {
    return !!this.get('selectedAreaClosure');
  }),

  /** @type {Boolean} */
  isLoading: false,

  /** @type {AreaClosure} We set this after selecting an area closure */
  selectedAreaClosure: null,

  /**
   * Generates a set of events that correspond with
   * area closures that is fed into the calendar component.
   *
   * @type {Object[]}
   */
  events: computed(
    'areaClosures.@each.startBlock',
    'areaClosures.@each.endBlock',
    'areaClosures.@each.id',
    'selectedAreaClosure',
    'isLoading',
    function () {
      const timezone = this.timeZone;
      const format = 'ddd D, h:mmA zz';

      return this.get('areaClosures').map((areaClosure) => {
        const areaClosureId = areaClosure.get('id');
        const start = moment.tz(areaClosure.get('startBlock'), timezone).toISOString();
        const end = moment.tz(areaClosure.get('endBlock'), timezone).toISOString();

        const title = `Closure for ${moment.tz(start, timezone).format(format)} to ${moment
          .tz(end, timezone)
          .format(format)}`;

        const isEventSelected =
          this.get('isAreaClosureSelected') && this.get('selectedAreaClosure.id') == areaClosureId;

        const selectedColor = '#b36563';
        const notSelectedColor = '#972424';
        const color = isEventSelected ? selectedColor : notSelectedColor;

        return {
          id: areaClosureId,
          areaClosureId,
          title,
          backgroundColor: color,
          borderColor: color,
          start,
          end
        };
      });
    }
  ),

  /** @type {Object} Specific calendar settings for the display of core business hours and days of the week. */
  businessHours: {
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],

    startTime: '06:00:00',
    endTime: '19:00:00'
  },

  /**
   * Sets the selectedAreaClosure property to the areaClosure param
   *
   * @param {AreaClosure} areaClosure
   * @private
   */
  _setSelectedAreaClosure(areaClosure) {
    this.set('selectedAreaClosure', areaClosure);
  },

  /**
   * Logs errors and posts errors as toasters
   *
   * @param message
   * @param error
   * @private
   */
  _notifyError(message, error) {
    const notifyService = this.get('notify');

    console.error(message);
    notifyService.error(message);

    error.errors.forEach((error) => {
      console.error(error.detail);
      notifyService.error(error.detail);
    });
  },

  /**
   * If an update fails we roll back the changes
   *
   * @param {String} id The identifier of the area closure of which we need to roll back attributes
   * @returns {Promise}
   * @private
   */
  _rollbackAreaClosure(id) {
    return this.store.findRecord('areaClosure', id).then((record) => record.rollbackAttributes());
  },

  /**
   * Formats date-times for the current area's time zone
   *
   * @param {DateTime} start Start time of the area closure block
   * @param {DateTime} end End time of the area closure block
   * @returns {{startBlock: Date, endBlock: Date}}
   * @private
   */
  _formatInTimeZone(start, end) {
    const area = this.get('area');
    const timezone = area.get('isoTimeZone');

    const startDateTime = moment.tz(start, timezone);
    const endDateTime = moment.tz(end, timezone);

    return {
      startBlock: new Date(startDateTime.format()),
      endBlock: new Date(endDateTime.format())
    };
  },

  addAreaClosure: action(function ({ date }) {
    this.setProperties({
      isLoading: true,
      selectedAreaClosure: null
    });

    const area = this.get('area');

    const { startBlock, endBlock } = this._formatInTimeZone(
      date,
      moment(date).add(15, 'minutes').toDate()
    );

    const areaClosure = this.store.createRecord('areaClosure', {
      area,
      startBlock,
      endBlock
    });

    areaClosure
      .save()
      .catch((error) => {
        this._notifyError('Error creating area closure', error);
        areaClosure.unloadRecord();
      })
      .finally(() => this.set('isLoading', false));
  }),

  dismissSelectedAreaClosureCard: action(function () {
    this.set('selectedAreaClosure', null);
  }),

  editAreaClosure: action(function ({ event }) {
    this.setProperties({
      selectedAreaClosure: null,
      isLoading: true
    });

    const areaClosureId = event.id;

    const { startBlock, endBlock } = this._formatInTimeZone(
      moment(event.start).toDate(),
      moment(event.end).format()
    );

    return this.store
      .findRecord('areaClosure', areaClosureId)
      .then((areaClosure) => {
        areaClosure.setProperties({
          startBlock,
          endBlock
        });

        return areaClosure.save();
      })
      .catch((error) => {
        this._notifyError('Error editing area closure', error);
        this._rollbackAreaClosure(areaClosureId);
      })
      .finally(() => this.set('isLoading', false));
  }),

  selectAreaClosure: action(function ({ event }) {
    this.set('isLoading', true);

    const areaClosureId = event.id;

    return this.store
      .findRecord('areaClosure', areaClosureId, { backgroundReload: false })
      .then((areaClosure) => this._setSelectedAreaClosure(areaClosure))
      .catch((error) => this._notifyError('Error selecting area closure', error))
      .finally(() => this.set('isLoading', false));
  }),

  deleteAreaClosure: action(function () {
    this.set('isLoading', true);

    return this.get('selectedAreaClosure')
      .destroyRecord()
      .then(() => this.set('selectedAreaClosure', null))
      .catch((error) => this._notifyError('Error deleting area closure', error))
      .finally(() => this.set('isLoading', false));
  }),

  fetchWeekClosures: action(function (event) {
    const startOfWeek = moment
      .tz(event.view.activeStart, this.timeZone)
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    this.set('weekOf', startOfWeek);
  })
});
