import { helper as buildHelper } from '@ember/component/helper';

const colors = [
  'blue',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black'
];

const semanticColorFor = function ([index]) {
  // circularly pick from the color index
  return colors[index % colors.length];
};
export default buildHelper(semanticColorFor);
