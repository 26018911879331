import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class MealPlanningInstance extends Application {
  attrs = {
    client: {
      serialize: true
    },
    area: {
      serialize: true
    },
    smallestLeadTime: {
      serialize: false
    },
    preferenceProfile: {
      serialize: false
    }
  };
}
