import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { attr, belongsTo, hasMany, fragment } from 'renard/models/foodee';

import Planable from './meal-planning-planable';

@classic
export default class MealPlanningTemplate extends Planable {
  /*
   * Attributes
   */
  @attr('date')
  createdAt;

  @attr('date')
  updatedAt;

  @attr('string')
  name;

  @fragment('fragments/meal-planning/plan', {
    defaultValue: {
      days: [
        { dayOfWeek: 0, name: 'Monday' },
        { dayOfWeek: 1, name: 'Tuesday' },
        { dayOfWeek: 2, name: 'Wednesday' },
        { dayOfWeek: 3, name: 'Thursday' },
        { dayOfWeek: 4, name: 'Friday' },
        { dayOfWeek: 5, name: 'Saturday' },
        { dayOfWeek: 6, name: 'Sunday' }
      ],
      defaultNumberOfPeople: 25
    }
  })
  plan;

  @fragment('fragments/meal-planning/objectives')
  objectives;

  @attr('boolean', { defaultValue: true })
  buildGroupOrders;

  @attr('number')
  defaultNumberOfPeople;

  @attr('number', { defaultValue: 1 })
  newWeight;

  @attr('number', { defaultValue: 1 })
  popularityWeight;

  @attr('number', { defaultValue: 1 })
  favWeight;

  @attr('number', { defaultValue: 1 })
  lastWeekWeight;

  // Should be the same as the default values from the Backend model
  @attr('number', { defaultValue: 30 })
  lastWeekOffset;

  @attr('number', { defaultValue: 1 })
  maxUsesPerWeek;

  @attr('number', { defaultValue: 1 })
  maxUsesPerDay;

  @attr('number', { defaultValue: 1 })
  maxUsesPerMeal;

  // Should be the same as the default values from the Backend model
  @attr('boolean', { defaultValue: true })
  allowRepeats;

  @attr('boolean')
  useTeamProfiles;

  @attr('boolean')
  ignoreEmptyConstraints;

  @attr('boolean')
  isRecurring;

  @attr('boolean')
  syncToSfdc;

  @attr('boolean')
  allowExtendedRadius;

  @attr('boolean')
  allowBeyondExtendedRadius;

  @attr('boolean')
  allowNewRecommendations;

  @attr('boolean')
  allowInternal;

  @attr('boolean', { defaultValue: false })
  autoScheduleActive;

  @attr('number', { defaultValue: 1 })
  scheduledWeeksInAdvance;

  /*
   * Relationships
   */
  @belongsTo('client')
  client;

  @belongsTo('area')
  area;

  @hasMany('meal-planning-instance')
  mealPlanningInstance;

  @belongsTo('meal-planning-preference-profile')
  preferenceProfile;

  @hasMany('meal-planning-restaurant-constraint', { inverse: 'template' })
  restaurantConstraints;

  @belongsTo('user')
  defaultOrderOwner;

  @belongsTo('order')
  orderTemplate;

  @alias('client.areas')
  areas;

  isTemplate = true;

  get humanize() {
    return `'${this.get('name')}'`;
  }

  @computed('restaurantConstraints.[]', 'plan.restaurantConstraintIds.[]')
  get weeklyRestaurantConstraints() {
    const restaurantConstraintIds = this.get('plan.restaurantConstraintIds');
    return this.get('restaurantConstraints').filter(
      (_) => !restaurantConstraintIds.includes(_.get('id'))
    );
  }

  // Validations
  validations = {
    area: { presence: true }
  };

  addRestaurantConstraint(constraint, _templateOrInstance) {
    constraint.set('template', this);
    return constraint.save();
  }

  async removeRestaurantConstraint(constraint, _templateOrInstance) {
    return constraint.destroyRecord();
  }
}
