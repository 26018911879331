import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';
import FilterRouteMixin from 'star-fox/mixins/filter-route-mixin';

@classic
export default class IndexRoute extends Route.extend(LoadingRouteMixin, FilterRouteMixin) {
  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    searchText: { refreshModel: true },
    sort: { refreshModel: true },
    areas: { refreshModel: true },
    cuisines: { refreshModel: true },
    dietary_tag_ordering: { refreshModel: true },
    can_team_order: { refreshModel: true },
    active: { refreshModel: true },
    coming_soon: { refreshModel: true },
    is_new: { refreshModel: true },
    can_asap_order: { refreshModel: true },
    available_at: { refreshModel: true },
    can_foodee_plus_team_order: { refreshModel: true },
    can_same_day_foodee_plus_team_order: { refreshModel: true }
  };

  filters = [
    {
      key: 'can_team_order',
      icon: 'users',
      label: 'Can Team Order',
      valueTextDelimiter: '-'
    },
    {
      key: 'areas',
      icon: 'map',
      label: 'Area',
      resourceName: 'area',
      resourceValueLabel: 'city',
      valueTextDelimiter: '-'
    },
    {
      key: 'active',
      icon: 'check',
      label: 'Active',
      valueTextDelimiter: '-'
    },
    {
      key: 'is_new_restaurant',
      icon: 'star outline',
      label: 'Is New',
      valueTextDelimiter: '-'
    },
    {
      key: 'cuisines',
      icon: 'tags',
      label: 'Cuisine Tags',
      resourceValueKey: 'name',
      resourceValueLabel: 'reverseLabel',
      valueTextDelimiter: '-'
    },
    {
      key: 'available_at',
      icon: 'clock outline',
      label: 'Available At',
      valueTextDelimiter: '::'
    },
    {
      key: 'can_asap_order',
      icon: 'calendar check',
      label: 'Can ASAP Order'
    },
    {
      key: 'dietary_tag_ordering',
      icon: 'heartbeat',
      label: 'Dietary Tags Ordering',
      resourceValueKey: 'abbreviation',
      resourceValueLabel: 'name',
      valueTextDelimiter: '-'
    },
    {
      key: 'coming_soon',
      icon: 'calendar check outline',
      label: 'Coming Soon',
      valueTextDelimiter: '-'
    },
    {
      key: 'can_foodee_plus_team_order',
      icon: 'user plus',
      label: 'Can Foodee+ Team Order'
    },
    {
      key: 'can_same_day_foodee_plus_team_order',
      icon: 'hourglass half',
      label: 'Can Same Day Foodee+ Team Order'
    }
  ];

  /**
   * @param {Object} params
   * @returns {{areas: Area[], restaurants: Restaurant[]}}
   */
  model(params) {
    super.model(...arguments);

    const { pageLimit, pageOffset, searchText, sort } = params;

    return RSVP.hash({
      areas: this.store.query('area', {
        page: {
          limit: 100,
          offset: 0
        }
      }),
      restaurants: this.store.query('restaurant', {
        page: {
          limit: pageLimit,
          offset: pageOffset
        },
        filter: searchText ? { name: searchText } : this.get('filterCollection').get('asJR'),
        sort: sort,
        include: 'areas,tags,owner,rankings'
      }),

      tags: this.store.query('tag', { page: { limit: 100 } }),
      restaurantBrands: this.store.query('restaurants-brand', { page: { limit: 200 } })
    });
  }

  /**
   * Tags should not block entering the route
   *
   * @param controller
   * @param model
   */
  setupController(controller, model) {
    super.setupController(controller, model);

    this.store
      .query('tag', { page: { limit: 100 } }) // My solution for now is to query all of the tags so we get simple search
      .then((tags) => controller.set('tags', tags));

    this.store
      .query('dietary-tag', { page: { limit: 100 } }) // My solution for now is to query all of the tags so we get simple search
      .then((dietaryTags) => controller.set('dietaryTags', dietaryTags));
  }
}
