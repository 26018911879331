import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@tagName('')
export default class NavigationItem extends Component {
  /** @type {boolean} */
  showTooltip = true;

  /** @type {string} the capability the user is required to have authority for to see this link  */
  requiredCapability = '';

  /** @type {string} the route this item should link to */
  route = null;

  /** @type {string} name of the route*/
  name = null;

  /**
   * @type {string}
   * @private
   */
  @computed('showTooltip', 'isExpanded', 'tooltip')
  get _tooltip() {
    return !this.get('showTooltip') || this.get('isExpanded') ? null : this.get('name');
  }
}
