import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class RulesRunLog extends Model {
  /*
   * Attributes
   */

  @attr('date')
  createdAt;

  @attr('string')
  result;

  @attr('string')
  highlightedResult;

  @attr('string')
  outcome;

  @attr('integer')
  recordId;

  @attr('string')
  recordType;

  /*
   * Relationships
   */
  @belongsTo('rules-rule', { inverse: 'runLogs' })
  rule;
}
