/** Initializing the promise watch for the settled promise queue */
export function initialize(appInstance) {
  const settledPromiseQueue = appInstance.lookup('service:settled-promise-queue');
  settledPromiseQueue.startPromiseWatch();
}

export default {
  name: 'settled-promise-queue',
  initialize: initialize
};
