import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { alias, gt } from '@ember/object/computed';
import Component from '@ember/component';

/**
 * This control generates pagination links based on the recordCount / pageOffset / pageLimt
 */
@classic
@classNames('')
export default class PaginationControl extends Component {
  /** @type {number} number of records per page */
  pageLimit = 10;

  /** @type {number} number of records we are currently offset to */
  pageOffset = 0;

  /** @type {string} */
  pageLimitKey = 'pageLimit';

  /** @type {string} */
  pageOffsetKey = 'pageOffset';

  /** @type {number} total number of records in this paginated set */
  recordCount = 0;

  /** @type {string} base route that the pagination will be conducted against by way of query params */
  route = '';

  /**
   * @type {boolean} whether or not this pagination control actually has pages
   * (recordCount > pageLimit)
   */
  @gt('pages.length', 1)
  hasPages;

  /** @type {number} The number of pages */
  @computed('recordCount', 'pageLimit')
  get pageCount() {
    const pageLimit = this.get('pageLimit');
    return Math.ceil(this.get('recordCount') / pageLimit) || 1;
  }

  /**
   * Shows links to the left of the page range
   * that nav to the first page. Doesn't make sense to show it
   * unless we are more than 7 records deep and the first page
   * is not currently shown as part of the nav.
   * @type {boolean}
   */
  @computed('pageCountSet')
  get showLeftBound() {
    return this.get('pageCountSet.lastObject') > 8;
  }

  /**
   * Shows links to the right of the page range
   * that nav to the last page.
   * doesn't make sense to show it if the last page is already being shown
   * @type {boolean}
   */
  @computed('pageCountSet', 'pageCount')
  get showRightBound() {
    const hasFewerThanSevenPages = this.get('pageCountSet.firstObject') < this.get('pageCount') - 8;
    const viewingLastPage = this.get('currentPage') >= this.get('pageCount') - 1;

    return hasFewerThanSevenPages && !viewingLastPage;
  }

  /** @type {number} Returns current page number, minimum is one. */
  @computed('pageOffset', 'pageLimit')
  get currentPage() {
    const pageOffset = this.get('pageOffset');
    const pageLimit = this.get('pageLimit');
    const newPageNumberIsValid = pageOffset / pageLimit >= 1;

    return newPageNumberIsValid ? Math.ceil(pageOffset / pageLimit) : 0;
  }

  /** @type {Page[]} Builds objects to represent all available pages. */
  @computed('recordCount')
  get pages() {
    const pageLimit = this.get('pageLimit');
    const pageCount = this.get('pageCount');

    return new Array(pageCount).fill('').map((_, i) => ({
      id: i,
      offset: i * pageLimit,
      label: i + 1
    }));
  }

  /**
   * Because we adjusted all the pages by one
   * to avoid a page '0', we end up with an empty page at the end.
   * This solves the problem.
   * @type {Number}
   */
  @computed('pages')
  get lastPageLabel() {
    return this.get('pages.lastObject.label');
  }

  /**
   * Uses set of page numbers to build page objects shown in nav.
   * Maximum is 7 pages shown at a time
   * @type {Page[]}
   */
  @computed('pageCountSet')
  get pagesShownInNav() {
    const pageLimit = this.get('pageLimit');
    const pageCountSet = this.get('pageCountSet');

    return pageCountSet.map((pageNumber) => ({
      id: pageNumber,
      offset: (pageNumber - 1) * pageLimit,
      label: pageNumber
    }));
  }

  /**
   * Uses low value to build the range of page numbers
   * that will be shown in the nav.
   * Array.from creates an array given an iterable object.
   * Here, the given iterable is new array(arrayLength), element0, element1.
   * Therefore, given 1, _buildPageCountSet returns [1,2,3,4,5,6,7]
   * @type {Number[]}
   */
  _buildPageCountSet(lowEnd, numberOfPagesToDisplay) {
    return Array.from(new Array(numberOfPagesToDisplay), (x, i) => i + lowEnd);
  }

  /**
   * Sets low value for the range of 7 page numbers
   * that will be shown in the nav,
   * and calls _buildPageCountSet
   * @type {Array}
   */
  @computed('currentPage', 'pageCount', 'recordCount', 'pages')
  get pageCountSet() {
    const pageCount = this.get('pageCount');
    const currentPage = this.get('currentPage');

    let lowEnd;
    let numberOfPagesToDisplay = 7;

    if (pageCount < 7) {
      lowEnd = 1;
      numberOfPagesToDisplay = pageCount;
    } else {
      if (currentPage < 7) {
        lowEnd = 1;
      } else if (currentPage === pageCount) {
        lowEnd = currentPage - 6;
      } else if (currentPage > pageCount - 6) {
        lowEnd = pageCount - 6;
      } else {
        lowEnd = currentPage - 3;
      }
    }
    return this._buildPageCountSet(lowEnd, numberOfPagesToDisplay);
  }

  /** @type {number} returns the offset of the last page */
  @alias('pages.firstObject.offset')
  firstPageOffset;

  /** @type {number} returns the offset of the first page */
  @alias('pages.lastObject.offset')
  lastPageOffset;

  /** @type {number} the previous page offset based on the current offset */
  @computed('currentPage')
  get previousPageOffset() {
    const pageLimit = this.get('pageLimit');
    const currentPage = this.get('currentPage');

    const newOffset = currentPage * pageLimit - pageLimit;

    return Math.max(0, newOffset);
  }

  /** @type {number} the next page offset based on the current offset */
  @computed('currentPage')
  get nextPageOffset() {
    const pageLimit = this.get('pageLimit');
    const currentPage = this.get('currentPage');
    const lastPageNumber = this.get('pageCount') - 1;

    const currentOffset = currentPage * pageLimit;
    const newOffset = currentOffset + pageLimit;

    return currentPage >= lastPageNumber ? currentOffset : newOffset;
  }
}
