import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class RestaurantRanking extends Model {
  @attr('string')
  month;

  @attr('number')
  areaRestaurantCount;

  @attr('number')
  platformRestaurantCount;

  @attr('number')
  orders;

  @attr('number')
  orderPlatformRank;

  @attr('number')
  orderAreaRank;

  @attr('number')
  orderAreaPercentRank;

  @attr('number')
  orderTotal;

  @attr('number')
  orderPercentage;

  @attr('number')
  onlineOrders;

  @attr('number')
  onlineTotal;

  @attr('number')
  onlinePlatformRank;

  @attr('number')
  onlineAreaRank;

  @attr('number')
  onlineAreaPercentRank;

  @attr('number')
  onlinePercentage;

  @attr('number')
  foodeeOrders;

  @attr('number')
  foodeeTotal;

  @attr('number')
  foodeePlatformRank;

  @attr('number')
  foodeeAreaRank;

  @attr('number')
  foodeeAreaPercentRank;

  @attr('number')
  foodeePercentage;

  @attr('number')
  onlineTeamOrders;

  @attr('number')
  onlineTeamOrderTotal;

  @attr('number')
  onlineTeamOrderPlatformRank;

  @attr('number')
  onlineTeamOrderAreaRank;

  @attr('number')
  onlineTeamOrderAreaPercentRank;

  @attr('number')
  onlineTeamOrderPercentage;

  @attr('number')
  foodeeTeamOrders;

  @attr('number')
  foodeeTeamOrderTotal;

  @attr('number')
  foodeeTeamOrderPlatformRank;

  @attr('number')
  foodeeTeamOrderAreaRank;

  @attr('number')
  foodeeTeamOrderAreaPercentRank;

  @attr('number')
  foodeeTeamOrderPercentage;

  @attr('number')
  onlineEnterpriseOrders;

  @attr('number')
  onlineEnterpriseOrderTotal;

  @attr('number')
  onlineEnterpriseOrderPlatformRank;

  @attr('number')
  onlineEnterpriseOrderAreaRank;

  @attr('number')
  onlineEnterpriseOrderAreaPercentRank;

  @attr('number')
  onlineEnterpriseOrderPercentage;

  @attr('number')
  foodeeEnterpriseOrders;

  @attr('number')
  foodeeEnterpriseOrderTotal;

  @attr('number')
  foodeeEnterpriseOrderPlatformRank;

  @attr('number')
  foodeeEnterpriseOrderAreaRank;

  @attr('number')
  foodeeEnterpriseOrderAreaPercentRank;

  @attr('number')
  foodeeEnterpriseOrderPercentage;

  @belongsTo('restaurant', { inverse: 'rankings' })
  restaurant;
}
