import ShowUserRoute from 'star-fox/features/logged-in/users/show/route';

export default class LoggedInRestaurantsShowUsersShow extends ShowUserRoute {
  /** @override */
  templateName = 'logged-in.users.show';

  userShowRoute = 'logged-in.restaurants.show.users.show';
  backRoute = 'logged-in.restaurants.show.users';
  hasPreferenceProfile = false;

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setProperties({ userShowRoute: this.userShowRoute, backRoute: this.backRoute });
  }
}
