import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@tagName('')
export default class ReleaseNoteFormFor extends Component {
  /** @type {ReleaseNote} */
  releaseNote = null;

  /** @type {boolean} */
  isEditing = false;

  /** @type {boolean} */
  canDestroy = true;

  didSubmit() {}
  didReset() {}
}
