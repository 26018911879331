import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { computed, get } from '@ember/object';

@classic
@classNames('fde-late-score')
@classNameBindings('compact:fde-is-compact')
export default class LateScore extends Component {
  /**  @type {object} */
  lateScore = null;

  /**  @type {object} */
  lateScoreTrend = null;

  popupDelay = {
    show: 0,
    hide: 500
  };

  /**  @type {boolean}  */
  compact = false;

  mouseEnter() {
    this.set('renderPopup', true);
  }

  /**  @type {string} */
  @computed('lateScore')
  get lateScorePopup() {
    const recentGlobalRating = get(this, 'lateScore.recentGlobalRating') ?? 'UNKNOWN';
    const recentLateRate = get(this, 'lateScore.recentLateRate') ?? 0;
    const globalAvg = get(this, 'lateScore.globalAvg') ?? 0;
    const lastOrderDeliverOn = get(this, 'lateScore.lastOrderDeliverOn') ?? 'UNKNOWN';
    const lastLateOrderDeliverOn = get(this, 'lateScore.lastLateOrderDeliverOn') ?? 'UNKNOWN';

    return `<table class="ui small compact table fde-late-score_hover-table">
              <tbody>
                <tr>
                  <td><i class="heartbeat icon"></i> Delivery Rating</td>
                  <td class="right aligned"><a class="ui ${this.get(
                    'ratingColor'
                  )} label">${recentGlobalRating.toUpperCase()}</a></td>
                </tr>
                <tr>
                  <td><i class="percent icon"></i> Late Rate</td>
                  <td class="right aligned"><a class="ui ${this.get('ratingColor')} label">${(
      recentLateRate * 100
    ).toFixed(2)}% (Avg: ${(globalAvg * 100).toFixed(2)}%)</a></td>
                </tr>
                <tr>
                  <td><i class="line chart icon"></i> Trending</td>
                  <td class="right aligned"><a class="ui ${this.get(
                    'trendingColor'
                  )} label">${this.get(
      'trending'
    )} <i class="angle double this.get('trending')"></i></a></td>
                </tr>
                <tr>
                  <td><i class="checked calendar icon"></i> Last Order Was</td>
                  <td class="right aligned"><a class="ui ${this.get(
                    'lastOrderWasColor'
                  )} label">${this.get('lastOrderWas')}</a></td>
                </tr>
                <tr>
                  <td><i class="calendar icon"></i> Last Order</td>
                  <td class="right aligned"><a class="ui grey label">${lastOrderDeliverOn}</a></td>
                </tr>
                <tr>
                  <td><i class="calendar icon"></i> Last Late Order</td>
                  <td class="right aligned"><a class="ui grey label">${lastLateOrderDeliverOn}</a></td>
                </tr>
              </tbody>
            </table>`;
  }

  /**
   * Get the slope of a linear regression (trend line) so we know which way the late score is trending. Use only the
   * last 5 orders. https://en.wikipedia.org/wiki/Simple_linear_regression
   * @type {number}
   */
  @computed('lateScore')
  get trendLineSlope() {
    const lateScoreTrend = this.get('lateScoreTrend') || [];
    const pastTrends = lateScoreTrend.pastTrends || [];

    const n = pastTrends.length;

    let sumOfXY = 0;

    let sumOfX = 0;
    let sumOfY = 0;

    let sumOfXSquared = 0;

    const lastFiveLateRates =
      n > 0 ? pastTrends.slice(Math.max(n - 5, 0), n - 1).map((_) => _.recentLateRate) : [0];

    lastFiveLateRates.forEach((y, x) => {
      sumOfXY += x * y;

      sumOfX += x;
      sumOfY += y;

      sumOfXSquared += x * x;
    });

    return (n * sumOfXY - sumOfX * sumOfY) / (n * sumOfXSquared - sumOfX * sumOfX);
  }

  /** @type {number} */
  @computed('trendLineSlope')
  get trending() {
    if (this.get('trendLineSlope') > 0) {
      return 'UP';
    } else if (this.get('trendLineSlope') < 0) {
      return 'DOWN';
    } else {
      return 'NEUTRAL';
    }
  }

  /** @type {string} */
  @computed('lateScore')
  get lastOrderWas() {
    const lastOrderDeliverOn = this.get('lateScore.lastOrderDeliverOn');
    const lastLateOrderDeliverOn = this.get('lateScore.lastLateOrderDeliverOn');

    const wasOnTime = lastOrderDeliverOn && lastOrderDeliverOn !== lastLateOrderDeliverOn;

    if (!lastOrderDeliverOn) {
      return 'UNKNOWN';
    } else if (wasOnTime) {
      return 'ON TIME';
    } else {
      return 'LATE';
    }
  }

  /** @type {string} */
  @computed('lastOrderWas')
  get lastOrderWasColor() {
    switch (this.get('lastOrderWas')) {
      case 'ON TIME':
        return 'green';
      case 'LATE':
        return 'red';
      default:
        return 'grey';
    }
  }

  /** @type {string} */
  @computed('trending')
  get trendingColor() {
    switch (this.get('trending')) {
      case 'UP':
        return 'red';
      case 'DOWN':
        return 'green';
      default:
        return 'grey';
    }
  }

  /** @type {string} */
  @computed('lateScore')
  get ratingColor() {
    switch (this.get('lateScore.recentGlobalRating')) {
      case 'very good':
        return 'green';
      case 'good':
        return 'olive';
      case 'very bad':
        return 'red';
      case 'bad':
        return 'orange';
      case 'average':
        return 'yellow';
      default:
        return 'grey';
    }
  }

  /** @type {string} */
  @computed('lateScore')
  get ratingCharacter() {
    switch (this.get('lateScore.recentGlobalRating')) {
      case 'very good':
        return 'VG';
      case 'good':
        return 'G';
      case 'very bad':
        return 'VB';
      case 'bad':
        return 'B';
      case 'average':
        return 'A';
      default:
        return 'U';
    }
  }
}
