import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class DetailsRoute extends Route {
  model(_params) {
    return this.modelFor('logged-in.areas.edit');
  }

  /**
   * Loading tax rates should not block entering the route
   *
   * @param controller
   * @param model
   */
  setupController(controller, model) {
    super.setupController(controller, model);

    this.store
      .query('invoicing-tax-rate', { page: { limit: 200 } })
      .then((invoicingTaxRates) => controller.set('invoicingTaxRates', invoicingTaxRates));
  }
}
