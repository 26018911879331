import classic from 'ember-classic-decorator';
import { classNames, attributeBindings } from '@ember-decorators/component';
import Component from '@ember/component';
import { action } from '@ember/object';

@classic
@classNames('')
@attributeBindings('data-test-sf-tab-group')
export default class TabGroup extends Component {
  'data-test-sf-tab-group' = '';
  /** @type {string|null} Active tab index of the group */
  activeTab = null;

  /** @type {boolean} Class names to pass to the individual tabs */
  tabClassNames = '';

  /**
   * Default handler for tabSelect action, will likely be overriden by parent.
   * @param {String|Number} tab
   */
  onTabSelect(nextTab) {
    const activeTab = this.get('activeTab');

    this.set('activeTab', nextTab);
    this.onTabChange(nextTab, activeTab);
  }

  /**
   * Handler for a tab click
   * @param {String|Number} tab
   */
  @action
  handleTabClick(tab) {
    this.onTabSelect(tab);
  }
}
