import Component from '@glimmer/component';
import { alias } from 'macro-decorators';
import { arg } from 'ember-arg-types';
import { object, func } from 'prop-types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class SfOrdersModalsPerLineItemDiscountComponent extends Component {
  @arg(object.required) data;
  @arg(func.required) close;

  @alias('data.order')
  order;

  @tracked
  clientDiscount;

  @tracked
  restaurantDiscount;

  @tracked
  canBulkEdit = false;

  itemsToDiscount = [];

  reset;

  @service
  store;

  @service
  notify;

  constructor(...args) {
    super(...args);
    this.resetDiscounts();
  }

  resetDiscounts() {
    this.clientDiscount = this.store.createFragment('fragments/accounting/charge');
    this.restaurantDiscount = this.store.createFragment('fragments/accounting/charge');
  }

  get isGroupOrder() {
    return this.order.groupOrderMembers.length > 0;
  }

  get groupBy() {
    return this.isGroupOrder ? 'groupOrderMember.name' : 'menuGroupName';
  }

  get sortBy() {
    return this.isGroupOrder ? 'groupOrderMember.name' : 'menuGroupIndex';
  }

  @action
  async loadOrderTask() {
    return this.order.loadOrderItems();
  }

  @action
  async applyToSelected() {
    let orderItem;
    for (orderItem of this.itemsToDiscount) {
      orderItem.clientDiscount = this.clientDiscount.copy();
      orderItem.clientDiscount.amount = orderItem.clientDiscount.amount ?? 100;
      orderItem.restaurantDiscount = this.restaurantDiscount.copy();
      orderItem.restaurantDiscount.amount = orderItem.restaurantDiscount.amount ?? 100;
      await orderItem.save();
    }

    this.notify.success('Discounts Applied');
    this.reset = new Date();
    this.canBulkEdit = false;
    this.resetDiscounts();

    return true;
  }

  @action
  async enableBulkEdit() {
    this.canBulkEdit = true;
  }
}
