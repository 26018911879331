import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, get } from '@ember/object';

/**
 * Displays a collection of sections in a table, this component is composed of
 * table-rows component and the section-row component
 */
@classic
@tagName('tbody')
export default class TableSections extends Component {
  /** @type {{title: String, values: Object[]}[]} */
  sections = null;

  /** @type {boolean} */
  isSelectable = false;

  /** @type {boolean} */
  isRowSelectable = false;

  /** @type {String} */
  selectableRowCursor = 'pointer';

  /** @type {boolean} */
  isEditable = false;

  /** @type {boolean} */
  isExpandable = false;

  allowActionMenu = false;

  /** @type {TableManager} */
  tableManager = null;

  /** @type {Model[]} */
  selectedValues = [];

  /** @type {boolean} */
  managesOwnSelected = false;

  /** @type {boolean} */
  showLoaderInActionCell = false;

  /** @type {boolean} */
  showLoaderInSaveCell = true;

  /** @type {boolean} Ensures that the last column is always fluid all proceeding ones are hidden */
  lastColumnAlwaysFluid = true;

  onSelect() {}
  onDeselect() {}
  onNewSelection() {}
  onValueEdit() {}
  onExpand() {}
  onCollapse() {}

  @action
  handleOnCollapse(item) {
    this.onCollapse(item);
  }

  @action
  handleOnExpand(item) {
    this.onExpand(item);
  }

  handleTableRowsNewSelection() {}

  @action
  handleTableRowsSelect(value) {
    const selectedValues = this.get('selectedValues').toArray();
    const managesOwnSelected = this.get('managesOwnSelected');

    selectedValues.push(value);
    this.onSelect(value);

    managesOwnSelected && this.set('selectedValues', selectedValues);

    this.onNewSelection(selectedValues.toArray());
  }

  @action
  handleTableRowsDeselect(value) {
    const selectedValues = this.get('selectedValues').toArray();
    const managesOwnSelected = this.get('managesOwnSelected');

    selectedValues.removeObject(value);
    this.onDeselect(value);

    managesOwnSelected && this.set('selectedValues', selectedValues);

    this.onNewSelection(selectedValues.toArray());
  }

  @action
  handleSelectSection(section) {
    const sectionValues = get(section, 'values');
    const selectedValues = this.get('selectedValues').toArray();

    sectionValues.forEach((_) => !selectedValues.includes(_) && selectedValues.push(_));

    this.onNewSelection(selectedValues);
  }

  @action
  handleDeselectSection(section) {
    const sectionValues = get(section, 'values');
    const selectedValues = this.get('selectedValues').toArray();

    this.onNewSelection(selectedValues.filter((_) => !sectionValues.includes(_)));
  }
}
