import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { run } from '@ember/runloop';
import { isEmpty } from '@ember/utils';
import RSVP from 'rsvp';

@classic
export default class SubscriptionPlansRoute extends Route {
  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    search: { refreshModel: false },
    sort: { refreshModel: true }
  };

  @service
  ajax;

  @service
  router;

  async model(params) {
    const { pageLimit, pageOffset, sort, search } = params;

    return RSVP.hash({
      subscriptionPlansAppConfig: this.store
        .query('app-configuration', { filter: { key: 'subscription' } })
        .then((_) => _.get('firstObject')),
      defaultSubscriptionPlans: this.store
        .query('subscription-plan', { filter: { isCustom: false } })
        .then((_) => _.toArray()),
      subscriptionPlans: this.store
        .query('subscription-plan', {
          filters: {
            search
          },
          page: {
            limit: pageLimit,
            offset: pageOffset
          },
          sort: isEmpty(sort) ? undefined : sort
        })
        .then((_) => {
          const results = _.toArray();
          results.meta = _.meta;
          return results;
        })
    });
  }

  @action
  didTransition() {
    const routesToOpenPaneFor = [
      'logged-in.admins.subscription-plans.edit',
      'logged-in.admins.subscription-plans.new'
    ];

    run.next((_) => {
      const openPaneForRoute = routesToOpenPaneFor.includes(this.get('router.currentRouteName'));
      const controller = this.controllerFor('logged-in.admins.subscription-plans');
      controller.set('rightPaneSize', openPaneForRoute ? 400 : 0);
    });
  }
}
