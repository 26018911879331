import Service, { inject as service } from '@ember/service';
import { camelizeObject, underscoreObject } from 'renard/transforms/object';
import { Distance } from 'renard/transforms/distance';

export default class RestaurantSearchService extends Service {
  @service
  store;

  async searchRecordRestaurants(searchableRecord, params, searchMethod = 'search') {
    let result = await searchableRecord[searchMethod](
      {},
      { queryParams: underscoreObject(params) }
    );
    const { restaurantIds, restaurants } = camelizeObject(result);

    return {
      restaurantIds,
      restaurants: restaurants.map((restaurant) => {
        if (restaurant.distance) {
          restaurant.distance = Distance.build(restaurant.distance);
        }

        return restaurant;
      })
    };
  }

  call(params) {
    return this.searchRecordRestaurants(
      this.store.createRecord('restaurant'),
      params,
      'searchRestaurants'
    );
  }
}
