import Model from 'renard/models/foodee';
import RSVP from 'rsvp';
import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';

import config from 'star-fox/config/environment';
import './models/custom-inflector-rules';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);

/**** App Class Setup and Customizations ****/

/**
 * Relay promise errors to error console
 */
RSVP.on('error', function (error) {
  if (error !== 'TransitionAborted') {
    console.assert(false, error);
  }
});

//Ember.run.backburner.DEBUG = true;

Model.reopen({
  /**
   * Reloads the model with a list of includes
   * @param {string} include include list of things you'd like reloaded
   * @returns {Promise.<DS.Model>}
   */
  reloadWith(include) {
    return this.store.findRecord(this.constructor.modelName, this.get('id'), {
      reload: true,
      include: include
    });
  }
});
