import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';

/**
 * Component for rendering a collection of TaxRates
 */
@classic
@tagName('table')
@classNames('ui selectable celled compact table fde-tax-rate-table')
export default class TaxRates extends Component {
  /** @type {TaxRate[]} **/
  taxRates = [];

  /** @type {Area[]} **/
  areas = [];
}
