import classic from 'ember-classic-decorator';
// Poll Manager Service Requirements:
// - Generic
// - Manage many polls
// - Methods:
//   - createPoll - takes an internval time, a function, and a function scope, returns a Poll
//   - deletePoll - takes a poll object, and stop the poll
//   - getAllPolls - return an array of poll objects
//
// Poll ->
//   start;
//   stop;
import { cancel, later } from '@ember/runloop';

import Service from '@ember/service';

@classic
export default class PollManagerService extends Service {
  eventInterval = 10000;

  schedulePollEvent(event, interval) {
    var eventInterval = interval || this.get('eventInterval');
    return later(() => {
      event.apply(this);
      this.set('timer', this.schedulePollEvent(event));
    }, eventInterval);
  }

  startPolling(interval) {
    this.set('timer', this.schedulePollEvent(this.get('onPollEvent'), interval));
  }

  stopPolling() {
    cancel(this.get('timer'));
  }

  onPollEvent() {
    //make request
  }
}
