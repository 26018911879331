import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';

@classic
export default class PaymentCardsRoute extends Route {
  userShowRoute = 'logged-in.users.show';

  model(_params) {
    const user = this.modelFor(this.userShowRoute);

    return RSVP.hash({
      user: user,
      roles: this.store.findAll('role')
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.set(
      'newPaymentCard',
      this.store.createRecord('payment-card', {
        user: model.user,
        country: 'CA'
      })
    );
  }
}
