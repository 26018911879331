import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class Courier extends Application {
  serialize(snapshot, options) {
    const json = super.serialize(snapshot, options);

    if (!snapshot.record.get('isNew')) {
      //Need to remove ownerEmail on update, but not create
      delete json.data.attributes['owner-email'];
    }

    return json;
  }

  attrs = {
    driverCount: {
      serialize: false
    },

    drivers: {
      serialize: false
    }
  };
}
