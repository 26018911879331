import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { begin, end } from '@ember/runloop';
import Component from '@ember/component';
import PusherBindingsMixin from 'star-fox/mixins/pusher-bindings-mixin';
import config from 'star-fox/config/environment';
import moment from 'moment-timezone';

function processMember(member, key) {
  member.id = key;
  member.initials = member.name
    .split(' ')
    .reduce((acc, word) => acc + word.charAt(0), '')
    .toUpperCase();

  return member;
}

@classic
@classNames('fde-presence-indicator')
@classNameBindings('isInverted:inverted')
export default class PresenceIndicator extends Component.extend(PusherBindingsMixin) {
  /**
   * @type {DS.Model}
   */
  model = null;

  /**
   * @type {string} a name to use for the presence channel  ex: ee.food.orders which
   * is uses as to presence-ee.food.orders.{id}
   */
  path = '';

  /**
   * @type {string} the name of the channel that we use for presence, defaults to presence-ee.food.orders.{id}
   * note if you want to override this one, you need to use presence- as a prefix.
   */
  @computed('path', 'model')
  get channelName() {
    return `presence-${this.get('path')}.${this.get('model.id')}`;
  }

  /**
   * The pusher service is required, but we need to reach into
   * its internals since it's not really designed to work the way we need it to here
   *  @type {PusherService}
   */
  @service
  pusher;

  /** @type {PusherChannel} channel that has been subscribed to */
  channel = null;

  @observes('model')
  modelDidChange() {
    console.info(`${moment().format('hh:mm:ss ')} [pusher] model did change`);

    this.unsubscribe();

    this.subscribe();
  }

  members = [];

  init(...params) {
    super.init(...params);

    // We need to bind these to this so they can be attached as listeners to
    // the pusher channel events
    this._subscriptionSucceeded = this.subscriptionSucceeded.bind(this);
    this._memberAdded = this.memberAdded.bind(this);
    this._memberRemoved = this.memberRemoved.bind(this);

    this.subscribe();
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    // clean up after our selves
    this.unsubscribe();
    delete this.get('channel');
  }

  /**
   * Subscribes to the presence channel for this component
   */
  subscribe() {
    // No pusher in test cause memory
    if (config.environment === 'test') {
      return;
    }

    let pusher = this.get('pusher.pusher');

    if (this.get('model')) {
      const channelName = this.get('channelName');
      const channel = pusher.subscribe(channelName);
      console.info(`${moment().format('hh:mm:ss ')} [pusher] joining -> ${channelName}`);

      channel
        .bind('pusher:subscription_succeeded', this._subscriptionSucceeded)
        .bind('pusher:member_added', this._memberAdded)
        .bind('pusher:member_removed', this._memberRemoved);

      this.set('channel', channel);
      this.set('channelName', channelName);
    }
  }

  /**
   * Unsubscribes to the presence channel for this component
   */
  unsubscribe() {
    // No pusher in test cause memory
    if (config.environment === 'test') {
      return;
    }
    const channel = this.get('channel');
    const channelName = this.get('channelName');

    if (channel) {
      console.info(`${moment().format('hh:mm:ss ')} [pusher] unsubscribing ${channelName}`);

      channel
        .unbind('pusher:subscription_succeeded', this._subscriptionSucceeded)
        .unbind('pusher:member_added', this._memberAdded)
        .unbind('pusher:member_removed', this._memberRemoved);

      channel.unsubscribe();
    }
  }

  /**
   * Callback when the channel has been joined, returns a set of members.
   * This is a goofy MemberObject from the Pusher library, it's a bit combersome
   *
   * @param {Object} members
   */
  subscriptionSucceeded(members) {
    console.info('pusher__subscriptionSucceeded');
    const actualMembers = members.members || {};
    const viewMembers = Object.keys(actualMembers).map((key) =>
      processMember(actualMembers[key], key)
    );
    begin(); // Adding ember run to prevent the ember run loop error in tests.
    this.set('members', viewMembers);
    end();
  }

  /**
   * Callback when a member is added to the channel
   *
   * @param {Object} member
   */
  memberAdded(member) {
    console.info('pusher:memberAdded');

    const viewMember = processMember(member.info, member.id);

    const members = this.get('members').toArray();
    members.push(viewMember);
    this.set('members', members);
  }

  /**
   * Callback when a member is removed from the channel
   *
   * @param {Object} member
   */
  memberRemoved(member) {
    console.info('pusher:memberRemoved');

    const members = this.get('members').filter((_) => _.id.toString() !== member.id.toString());

    this.set('members', members);
  }
}
