import classic from 'ember-classic-decorator';
import { equal } from '@ember/object/computed';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class NotificationLog extends Model {
  /*
   * Attributes
   */
  @attr('string')
  uniquenessContext;

  @attr('string')
  medium; // enum medium: [:email, :sms], _suffix: true

  @attr('string')
  reason;

  @attr('object')
  metaInfo;

  @attr('date')
  createdAt;

  @attr('date')
  updatedAt;

  @attr('string')
  url;

  @attr('array')
  events;

  @attr('string')
  uuid;

  @attr('string')
  receiverType;

  @attr('string')
  receiverId;

  @attr('string')
  message;

  @attr('string')
  status;

  @attr('string')
  provenanceId;

  @attr('string')
  sgMessageId;

  @attr('array')
  sentTos;

  @attr('string')
  orderId;

  /*
   * Relationships
   */
  @belongsTo('receiver', { polymorphic: true, async: true })
  receiver;

  @belongsTo('order')
  order;

  /** @type{boolean}*/
  @equal('status', 'pending')
  isPending;

  get icon() {
    return {
      email: 'envelope outline',
      sms: 'mobile alternate',
      robo_call: 'phone'
    }[this.medium];
  }

  get isBounced() {
    return this.status === 'bounce' && this.medium === 'email';
  }

  get isSingleEmail() {
    return this.sentTos.length === 1 && this.medium === 'email';
  }

  get color() {
    return (
      {
        pending: 'grey',
        undelivered: 'red',
        bounce: 'red',
        dropped: 'red',
        failed: 'red'
      }[this.status] || 'green'
    );
  }
}
