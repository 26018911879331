import classic from 'ember-classic-decorator';
import Model, { attr } from 'renard/models/foodee';

/**
 * @class Transaction
 */
@classic
export default class CurrenciesTransaction extends Model {
  @attr('number')
  value;

  @attr('string')
  agentName;

  @attr('string')
  agentEmail;

  @attr('string')
  subjectName;

  @attr('string')
  reason;

  @attr('string')
  event;

  @attr('date')
  createdAt;
}
