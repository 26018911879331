import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias, and, empty, equal } from '@ember/object/computed';
import { inject as controller } from '@ember/controller';
import RSVP from 'rsvp';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';

import { task } from 'ember-concurrency';

import { storageFor } from 'ember-local-storage';
import { section } from '../../../../utils/array';

@classic
export default class IndexController extends Controller {
  @controller('logged-in.planning')
  parent;

  @equal('router.currentRouteName', 'logged-in.planning.index.index')
  isCurrentRoute;

  @alias('model.area')
  area;

  /** @override */
  queryParams = ['expanded', 'sort', 'showSingles'];

  sort = 'client.name';
  expanded = [];

  @computed('expanded.[]', 'model.instances.[]')
  get expandedValues() {
    const expanded = this.get('expanded');

    return this.get('model.instances').filter((_) => expanded.includes(_.get('id')));
  }

  /** @type {Client} **/
  clientToPlan = null;

  /** @type {MealPlanningTemplate[]} **/
  mealPlanningTemplates = null;

  /** @type {MealPlanningTemplate[]} **/
  templatesToPublish = [];

  /** @type {boolean} **/
  @empty('model.instances')
  instancesEmpty;

  /** @type {boolean} **/
  @empty('model.clientsToPlan')
  clientsEmpty;

  /** @type {Client[]} **/
  searchedClients = null;

  /** @type {boolean} **/
  @and('instancesEmpty', 'clientsEmpty')
  empty;

  /** @type {MealPlanningInstance[]} **/
  instancesToPlan = null;

  /** @type {NotificationService} **/
  @service
  notify;

  /** @type {MealPlanningInstance[]} **/
  @computed('model.instances.[]')
  get groupedInstances() {
    return section(this.get('model.instances'), 'client.accountName');
  }

  /**
   * Logs errors and posts errors as toasters
   *
   * @param message
   * @param error
   * @private
   */
  _notifyError(message, error) {
    const notifyService = this.get('notify');

    console.error(message);
    notifyService.error(message);

    error.errors.forEach((error) => {
      console.error(error.detail);
      notifyService.error(error.detail);
    });
  }

  // have to prefix this cause we use ember pods, which wants to put everything in the features directory.
  // any thoughts @arthur ?
  @storageFor('storages/meal-planning/planner-settings')
  settings;

  /** @override **/
  pusherModelQueryParams = {
    MealPlanningReservation: {
      include: 'restaurant,order.restaurant'
    }
  };

  /** @override **/
  pusherIgnoreActionTypes = {
    created: ['MealPlanningReservation']
  };

  /**
   * @type {string[]}
   */
  @computed('area')
  get pusherChannels() {
    const area = this.get('area');

    let ret = [];

    if (area) {
      ret = [`ee.food.areas.${area}.model-events`];
    }

    return ret;
  }

  async save() {
    const templates = this.get('templatesToPublish');
    const instances = await RSVP.Promise.all(
      templates.map((template) =>
        this.store
          .createRecord('meal-planning-instance', {
            startOfWeek: moment(this.get('parent.monday')).toDate(),
            name: template.get('name'),
            client: this.get('clientToPlan'),
            area: this.get('parent.areaModel'),
            mealPlanningTemplate: template,
            defaultOrderOwner: template.get('defaultOrderOwner')
          })
          .save()
      )
    );
    this.model.instances.pushObjects(instances);
    this.expanded.pushObjects(instances.mapBy('id'));
    this.setProperties({
      templatesToPublish: [],
      clientToPlan: null,
      mealPlanningTemplates: null
    });
    this.planInstances(instances);
  }

  @task(function* (client) {
    const mealPlanningTemplates = yield client.query('mealPlanningTemplates');
    this.set('mealPlanningTemplates', mealPlanningTemplates);

    this.setProperties({
      mealPlanningTemplates,
      templateToPublish: mealPlanningTemplates.get('firstObject')
    });
  })
  fetchMealPlanTemplatesTask;

  @task(function* () {
    yield this.get('instanceToSaveBackToTemplate').updateOrderTemplate();
    this.set('shouldSaveBackToTemplateModal', false);
  })
  updateOrderTemplateTask;

  planInstances(instancesToPlan) {
    // TODO: This should be a queue some how
    return Promise.all(
      instancesToPlan.filter((i) => !i.isEmpty).map((instance) => instance.planAsync())
    );
  }

  @action
  removeInstance(instance) {
    this.get('expanded').removeObject(instance.get('id'));
    this.get('model.instances').removeObject(instance);
  }

  @action
  reset() {
    this.set('instancesToPlan', []);

    return RSVP.Promise.resolve(true);
  }

  @action
  plan() {
    return this.planInstances(this.instancesToPlan);
  }

  @action
  fetchMealPlanTemplates(client) {
    this.get('fetchMealPlanTemplatesTask').perform(client);
  }

  @action
  handleRowExpand(instance) {
    this.get('expanded').pushObject(instance.get('id'));
  }

  @action
  handleRowCollapse(instance) {
    this.get('expanded').removeObject(instance.get('id'));
  }
}
