import classic from 'ember-classic-decorator';
import Model, { belongsTo, hasMany } from 'renard/models/foodee';

@classic
export default class Owner extends Model {
  @belongsTo('currencies-wallet', { inverse: 'owner' })
  wallet;

  @hasMany('rules-rule', { inverse: 'owner' })
  rules;
}
