import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class MealType extends Application {
  attrs = {
    menuGroups: {
      serialize: false
    },
    menuItems: {
      serialize: false
    }
  };
}
