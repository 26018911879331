import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-controls-action-bar-action-bar-nav')
export default class ActionBarNav extends Component {
  /** @type {function} the callback to change to the previous value **/
  goToPrev = null;

  /** @type {function} the callback to change to the next value **/
  goToNext = null;
}
