import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';

@classic
export default class IndexRoute extends Route.extend(AuthenticatedRouteMixin, LoadingRouteMixin) {
  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    searchText: { refreshModel: true }
  };

  model(params) {
    const { pageLimit, pageOffset, searchText } = params;

    return RSVP.hash({
      areas: this.store.query('area', {
        page: {
          limit: pageLimit,
          offset: pageOffset
        },
        filter: {
          search: searchText
        }
      })
    });
  }
}
