import { InvalidError } from '@ember-data/adapter/error';
import EmberError from '@ember/error';

export function NotFoundError() {
  EmberError.apply(this, arguments);
}
NotFoundError.prototype = Object.create(InvalidError.prototype);

export function ServerError() {
  EmberError.apply(this, arguments);
}
ServerError.prototype = Object.create(InvalidError.prototype);

export default {
  NotFoundError: NotFoundError,
  ServerError: ServerError
};
