import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr } from 'renard/models/foodee';

@classic
export default class Driver extends Model {
  /*
   * Attributes
   */

  @attr('string')
  driverId;

  @attr('string')
  firstName;

  @attr('string')
  lastName;

  /*
   * Computed Properties, Instance Variables
   */

  /** @type {string} */
  @computed('firstName', 'lastName')
  get fullName() {
    return `${this.get('firstName')} ${this.get('lastName')}`;
  }
}
