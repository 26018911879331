import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';

@classic
export default class EditController extends Controller {
  /** @type {Area[]} */
  areas = [];

  /**
   * @type {string[]}
   */
  @computed('model')
  get pusherChannels() {
    return [`ee.food.client.${this.get('model.id')}.model-events`];
  }

  showNewLocationForm = false;

  @action
  didCancel() {
    this.transitionToRoute('logged-in.clients');
  }

  @action
  resetNewLocation() {
    this.set(
      'newDeliveryLocation',
      this.store.createRecord('delivery-location', {
        client: this.get('model')
      })
    );

    this.set('showNewLocationForm', false);
  }

  @action
  savedNewLocation() {
    this.set(
      'newDeliveryLocation',
      this.store.createRecord('delivery-location', {
        client: this.get('model')
      })
    );

    this.set('showNewLocationForm', false);
  }

  @action
  toggleShowNewLocationForm() {
    this.toggleProperty('showNewLocationForm');
  }

  @action
  toggleEditBillingLocation() {
    if (!this.get('editableBillingLocation')) {
      this.get('model.billingLocation')
        // should be a clone
        .then((_) => _.copy().then((copy) => this.set('editableBillingLocation', copy)));
    } else {
      this.set('editableBillingLocation', null);
    }
  }

  @action
  makeNewOrder() {
    const client = this.get('model');
    const clientId = client.get('id');
    const areaId = client.get('defaultArea.id');

    this.transitionToRoute('logged-in.new-order', {
      queryParams: { clientId, areaId }
    });
  }
}
