/**
 * Formats a phone number to look good
 * @param  {string} number
 * @return {string}
 */
export default function formatPhoneNumber(number) {
  // i18n.phonenumbers.PhoneNumberFormat.NATIONAL: 2
  const format = 2;
  return intlTelInputUtils.formatNumber(number, 'us', format);
}
