import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { object } from 'prop-types';

const baseUrl = 'https://us3.datadoghq.com/';

const requestIdPatterns = [
  {
    regex: /event_id=(.*)/,
    trace: (res) => ({ type: 'sidekiqEventId', value: res[1].replace(':', '') })
  },
  { regex: /job_id=(.*)/, trace: (res) => ({ type: 'sidekiqJobId', value: res[1] }) },
  { regex: /request_id=(.*)/, trace: (res) => ({ type: 'httpRequestId', value: res[1] }) },
  {
    regex: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
    trace: (res) => ({ type: 'integrationLogEventId', value: res[0] })
  },
  { regex: /^[\w+]{24}/, trace: (res) => ({ type: 'integrationLogJobId', value: res[0] }) }
];

const urlTagPatterns = [
  { sidekiqEventId: { tag: '@named_tags.sidekiq.event_id', dest: 'logs' } },
  { sidekiqJobId: { tag: '@named_tags.sidekiq.jid', dest: 'logs' } },
  { httpRequestId: { tag: null, dest: 'apm/traces' } },
  { integrationLogEventId: { tag: null, dest: 'apm/traces' } },
  { integrationLogJobId: { tag: null, dest: 'apm/traces' } }
];

export default class ObservabilityTooltip extends Component {
  @arg(object.isRequired) feedItem;

  _fetchTrace(requestId) {
    const tracePattern = requestIdPatterns.find(({ regex }) => regex.test(requestId));
    return tracePattern ? tracePattern.trace(requestId.match(tracePattern.regex)) : null;
  }

  _fetchUrlComponents(requestId) {
    const trace = this._fetchTrace(requestId);
    const match = trace ? urlTagPatterns.find((traceType) => traceType[trace.type]) : null;
    return match ? [trace, Object.values(match)[0]] : [];
  }

  get tooltipTitle() {
    return this.feedItem?.requestId || this.feedItem;
  }

  get tooltipHref() {
    const requestId = this.feedItem?.requestId;

    if (!requestId) {
      return baseUrl;
    }

    const [trace, urlComponents] = this._fetchUrlComponents(requestId);

    if (!trace) {
      return baseUrl;
    }

    const { dest, tag } = urlComponents;
    const urlTag = tag ? `${encodeURIComponent(`${tag}:`)}` : '';
    const traceUrl = `${baseUrl}${dest}?query=${urlTag}${trace.value}&from_ts=${this.fromTs}&to_ts=${this.toTs}&live=false`; // eslint-disable-line

    return trace ? traceUrl : baseUrl;
  }

  get fromTs() {
    const timestamp = this.feedItem.timestamp || this.feedItem.createdAt;
    return timestamp.setUTCSeconds(0o0, 0o00); // prevent octal literals error
  }

  get toTs() {
    const timeOffSet = 60 * 1000; // offset by one minute
    return this.fromTs + timeOffSet;
  }
}
