import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import RSVP, { Promise } from 'rsvp';
import { action, computed } from '@ember/object';
import { task } from 'ember-concurrency';
import { TYPES } from './constraints-form-for/component';

/**
 * @enum
 */
export const CONSTRAINT_TAG_TYPES = Object.freeze({
  MEAL: 'MEAL',
  FOOD: 'FOOD',
  DIETARY: 'DIETARY',
  ALLERGY: 'ALLERGY'
});

@classic
@tagName('')
export default class RequirementFormFor extends Component {
  /** @type {boolean} */
  autoSubmit = true;

  /** @type {FormFor} */
  parentForm = null;

  /** @type {Requirement} */
  requirement = null;

  /** @type {boolean} */
  hideRestaurantFeatures = false;

  /**  @type {boolean} */
  showNameField = false;

  /**  @type {boolean} */
  showQueryField = true;

  /**  @type {boolean} */
  showDietaryTags = true;

  showAllergies = true;

  /**  @type {boolean} */
  isSearchForm = false;

  /**  @type {boolean} */
  showTags = false;

  /**  @type {boolean} */
  showNumberOfOptions = true;

  /** @type {Service} */
  @service
  store;

  hideHelp = false;

  /** @override */
  didInsertElement() {
    super.didInsertElement(...arguments);
    this.get('loadingTask').perform();
  }

  /** Handler for internal form's willSubmit event to support search form callback */
  @action
  _willSubmit() {
    if (this.get('isSearchForm')) {
      this.set('isDirty', true);
      this.doSubmit();
      return false;
    }

    return this.get('requirement').validate();
  }

  /** Handler for internal form's willReset event to support search form resetting */
  @action
  _willReset() {
    this.willReset();
    return true;
  }

  /** Override's form's doReset method to support search form resetting */
  @action
  _doReset() {
    this.get('requirement').reset();
    return Promise.resolve(true);
  }

  /** Handler for internal form's didReset event to support search form resetting */
  @action
  _didReset() {
    this.set('isDirty', false);
    return this.didReset();
  }

  /** didReset event to support search form resetting */
  didReset() {}

  /** wilLReset event to support search form resetting */
  willReset() {}

  didSubmit() {}

  didDestroyModel() {}

  /** doSubmit event to support search form callback */
  doSubmit() {}

  /** @type {MealType[]} */
  @computed('model.mealTypes.[]', 'requirement.mealTypes.[]')
  get selectableMealTypes() {
    const mealTypes = this.get('requirement.mealTypes') || [];
    const anyExclusive = mealTypes.any((_) => _.get('isExclusive'));
    const availableMealTypes = this.get('model.mealTypes') || [];

    return anyExclusive
      ? availableMealTypes.rejectBy('isExclusive').concat(mealTypes).uniq()
      : availableMealTypes;
  }

  @computed('requirement.topCuisine')
  get isTopCuisine() {
    return Boolean(this.get('requirement.topCuisine'));
  }

  @computed('requirement.preferenceProfile.searchBudgetEnabled')
  get hidePrice() {
    return Boolean(this.get('requirement.preferenceProfile.searchBudgetEnabled'));
  }

  /** @type {task} */
  @task(function* () {
    const store = this.get('store');

    const searchOptions = { page: { limit: 200 } };

    if (!RequirementFormFor.models) {
      // run this only once.
      RequirementFormFor.models = yield RSVP.hash({
        tags: store.query('tag', searchOptions),
        foodTypes: store.query('food-type', searchOptions),
        mealTypes: store.query('meal-type', searchOptions),
        dietaryTags: store
          .query('dietary-tag', { filter: { tagType: 'DIETARY' } })
          .then((_) => _.toArray()),
        allergyTags: store
          .query('dietary-tag', { filter: { tagType: 'ALLERGY' } })
          .then((_) => _.toArray())
      });
    }

    return RequirementFormFor.models;
  })
  loadingTask;

  /** @type {{foodTypes: FoodType[], mealTypes: MealType[], dietaryTags: DietaryTag[]} */
  @alias('loadingTask.lastSuccessful.value')
  model;

  @computed('requirement.requirementConstraints.@each.tagType')
  get isMissingAllergy() {
    return !this.get('requirement.requirementConstraints').any(
      (_) => _.get('tagType') === CONSTRAINT_TAG_TYPES.ALLERGY
    );
  }

  @action
  addAllergyConstraint() {
    const constraint = this.get('store').createRecord('mealPlanningRequirementConstraint', {
      constraintType: TYPES.NOT,
      tagType: CONSTRAINT_TAG_TYPES.ALLERGY,
      requirement: this.get('requirement')
    });
    constraint.save();
  }
}

RequirementFormFor.models = null;
