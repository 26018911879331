import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';

@classic
export default class ShowRoute extends Route {
  /**
   * @param {Object} params
   * @returns {{restaurant: Restaurant}}
   */
  model(params) {
    const eventuallyRestaurant = this.store.findRecord('restaurant', params.restaurant_id, {
      reload: true,
      include: [
        'areas',
        'billing-location',
        'menus',
        'pickup-locations',
        'service-times',
        'tags'
      ].join(',')
    });

    return RSVP.hash({
      restaurant: eventuallyRestaurant,
      rankings: eventuallyRestaurant.then((_) => _.get('rankings')),
      restaurantBrands: this.store
        .query('restaurants-brand', { page: { limit: 200 } })
        .then((_) => _.toArray())
    });
  }

  /**
   * Tags should not block entering the route
   *
   * @param controller
   * @param model
   */
  setupController(controller, model) {
    super.setupController(controller, model);

    this.store
      .query('tag', { page: { limit: 100 } }) // My solution for now is to query all of the tags so we get simple search
      .then((tags) => controller.set('tags', tags));
  }
}
