import { helper as buildHelper } from '@ember/component/helper';
import moment from 'moment-timezone';
import { Time } from 'renard/transforms/time';

//takes a time and a format. Returns time formatted in the given format.

export function formattedTime(params) {
  let [time = new Time(moment()), format = 'hh:mm a'] = params;

  return time.constructor.name === 'Date' ? moment(time).format(format) : time.format(format);
}

export default buildHelper(formattedTime);
