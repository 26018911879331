import Fixture, {
  element,
  elementWithValue,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';
import { click } from '@ember/test-helpers';

export default class SfFiltersFilterToggleFixture extends Fixture {
  constructor() {
    super('data-test-sf-filters-filter-toggle');
  }

  @elementWithValue item;
  @element({ selector: 'data-test-sf-collapsible-segment' }) collapsibleSegment;

  click() {
    return click(this.collapsibleSegment.selector);
  }
}

export const sfFilterToggle = getElementDecoratorFor(SfFiltersFilterToggleFixture);
export const sfFilterToggleWithValue = getElementWithValueDecoratorFor(
  SfFiltersFilterToggleFixture
);
