import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import RSVP from 'rsvp';
import { task } from 'ember-concurrency';
import moment from 'moment';

@classic
export default class InfoCalloutFormFor extends Component {
  /** @type {Resrvation} */
  reservation = null;

  @task(function* () {
    return yield RSVP.hash({
      restaurant: this.get('reservation.restaurant.content').reloadWith('tags'),
      activeMenu: this.get('reservation.restaurant.activeMenu')
    });
  })
  load;

  @alias('load.last.value')
  model;

  get deliveryDayOfWeek() {
    return new moment(this.reservation.deliverAt).format('ddd');
  }

  get sameDayDeadlineCaption() {
    const sameDayDeadlineTime = this.reservation?.sameDayDeadlineTime;
    return sameDayDeadlineTime
      ? `Same-day deadline: ${this.deliveryDayOfWeek} ${sameDayDeadlineTime}`
      : '';
  }
}
