import Component from '@glimmer/component';
import { action } from '@ember/object';
import { arg } from 'ember-arg-types';
import { tracked } from '@glimmer/tracking';

export default class FormControlsPathSelectSelectComponent extends Component {
  @arg
  relationship;

  @tracked
  _selected;

  @arg
  index;

  constructor(...args) {
    super(...args);

    this._selected = this.args.selected;
  }

  @arg
  onChange = () => {};

  @action
  handleChange(value) {
    this._selected = value;
    this.onChange(value, this.index);
  }
}
