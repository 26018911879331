import Fixture, {
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export class NotifyFixture extends Fixture {
  constructor() {
    super('data-test-ember-notify');
  }

  shouldContainMessage(message, className) {
    this.should.be.rendered;
    this.shouldHaveParentWithClass(className);
    this.should.contain.textContent(message);
  }
}

export const notify = getElementDecoratorFor(NotifyFixture);

export const notifyWithValue = getElementWithValueDecoratorFor(NotifyFixture);
