import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { FactTypeConversionMap } from '../../../../../../models/rules-fact';

@classic
@tagName('')
@classNames('')
export default class FactConfigurator extends Component {
  @service
  store;

  @service
  notify;

  @computed('fact')
  get factTypes() {
    return Object.keys(FactTypeConversionMap);
  }

  fact = null;
  rule = null;

  @computed('fact')
  get canSave() {
    const fact = this.get('fact');
    return fact && fact.get('hasDirtyAttributes');
  }
}
