import classic from 'ember-classic-decorator';
import {
  attributeBindings,
  classNameBindings,
  classNames,
  tagName
} from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { run } from '@ember/runloop';
import { capitalize } from '@ember/string';
import { htmlSafe } from '@ember/template';
import $ from 'jquery';

@classic
@tagName('th')
@classNames('ui --fde-table-sort fde-header-cell_th')
@classNameBindings('canSort:fde-can-click', 'isActive:fde-is-active', 'hideable:fde-is-hideable')
@attributeBindings('colspan', 'rowspan', 'componentStyle:style')
export default class TableHeaderCell extends Component {
  init() {
    super.init(...arguments);

    const width = this.get('width');
    const visible = this.get('visible');
    const order = this.get('order');
    const minWidth = this.get('minWidth');
    const maxWidth = this.get('maxWidth');

    const defaultColumnValues = {
      width,
      visible,
      order,
      minWidth,
      maxWidth
    };

    this.set('defaultColumnValues', defaultColumnValues);

    const tableManager = this.get('tableManager');
    tableManager && tableManager.registerHeaderCell(this, defaultColumnValues);
  }

  /** @type {boolean} Ensures that the last column is always fluid all proceeding ones are hidden */
  lastColumnAlwaysFluid = true;

  /** @type {string} */
  componentStyle = '';

  /** @type {string} */
  calculatedComponentStyle = '';

  /** @type {number} */
  colspan = 1;

  /** @type {number} */
  rowspan = 1;

  /** called when the component is clicked and the cell can sort */
  onSortChange() {}

  /** @propery {string} sortByString - the current sort string, expected to follow JSONAPI spec */
  sortByString = '';

  /** @propery {boolean} canSort - whether or not this cell can sort */
  canSort = false;

  /** @propery {boolean} inverse - whether or not this cell sorts backwars */
  inverse = false;

  /** @propery {string} key -  the key this cell could sort */
  key = null;

  /** @propery {string} [icon] - the key this cell could sort by */
  icon = null;

  /** @propery {string} [icon] - the optional label for this cell */
  label = null;

  /** @property {boolean} */
  showLabel = true;

  /** @property {boolean} */
  resizable = false;

  /** @type {boolean} */
  hideable = false;

  /** @type {boolean} */
  visible = true;

  /** @property {TableManager} */
  tableManager = null;

  /** @property {Object} Set once on element insertion based on values passed into component at creation time */
  defaultColumnValues = {};

  /** @property {string} label for the column will either use the provided label or capitalize the key */
  @computed('label', 'key')
  get _label() {
    const label = this.get('label');
    const key = this.get('key');

    return label || capitalize(key || '');
  }

  /** @propery {boolean} isAsc - if this cell is currently sorting and in ascending */
  @computed('sortByString')
  get isActive() {
    return this.get('isAsc') || this.get('isDesc');
  }

  /** @propery {boolean} isAsc - if this cell is currently sorting and in ascending */
  @computed('sortByString')
  get isAsc() {
    const canSort = this.get('canSort');
    const sortByKeys = this.get('sortByString').split(',');

    return canSort && sortByKeys.includes(this.get('key'));
  }

  /** @propery {boolean} isDesc- if this cell is currently sorting and in descending */
  @computed('sortByString')
  get isDesc() {
    const canSort = this.get('canSort');
    const sortByKeys = this.get('sortByString').split(',');
    const desc = `-${this.get('key')}`;

    return canSort && sortByKeys.includes(desc);
  }

  /** @property {number} Often we want to directly set the width of this cell */
  width = null;

  /**
   * So before you get made about presentation being in the markup, often times
   * we want to force a single column to a set width, this column will _never_ get reused
   * so no sense putting it in a css file.
   */
  didInsertElement() {
    super.didInsertElement(...arguments);
    const width = this.get('width');

    const defaultColumnValues = this.get('defaultColumnValues');

    if (width) {
      this.$().css('width', `${width}px`);
    }

    const tableManager = this.get('tableManager');

    if (tableManager) {
      const column = tableManager.getColumnForHeaderCell(this);
      const resizable = this.get('resizable');

      //TODO: Probably move this column initialization into the tableManagers registerHeaderCell function
      column.width = !resizable || column.width === null ? width : column.width;
      column.visible = column.visible === null ? defaultColumnValues.visible : column.visible;
      column.order = column.order === null ? defaultColumnValues.order : column.order;
      column.minWidth = this.get('minWidth'); //Always set the minWidth and maxWidth from above.
      column.maxWidth = this.get('maxWidth');
    }

    run.scheduleOnce('afterRender', this, (_) => this.recalculateCellSize());
  }

  /** @override */
  willDestroyElement() {
    super.willDestroyElement(...arguments);
    const tableManager = this.get('tableManager');
    tableManager && tableManager.unregisterHeaderCell(this);
  }

  recalculateCellSize() {
    if (!this.get('isRegistered')) {
      return '';
    }

    const headerCells = this.get('tableManager.headerCells') || [];
    const columnCell = this.get('tableManager').getColumnForHeaderCell(this);

    const width = columnCell.width;

    const minWidth = columnCell.minWidth || width;
    const maxWidth = columnCell.maxWidth || width;

    const percentage = 1 / headerCells.length;

    const lastVisibleColumn = this.get('tableManager').getLastVisibleColumn();

    const isLastVisibleColumn = columnCell === lastVisibleColumn;
    const lastColumnAlwaysFluid = this.get('lastColumnAlwaysFluid');

    const forceFluid = lastColumnAlwaysFluid && isLastVisibleColumn;

    let style;

    if (forceFluid) {
      style = `flex-basis: ${percentage}%`;
    } else if (width) {
      style = `max-width: ${Math.min(width, maxWidth)}px; min-width: ${Math.max(
        width,
        minWidth
      )}px;`;
    } else {
      style = `flex-basis: ${percentage}%; min-width: ${
        minWidth ? minWidth + 'px' : 'auto'
      }; max-width: ${maxWidth ? maxWidth + 'px' : 'auto'};`;
    }

    if (!columnCell.visible) {
      style = `${style} display: none !important;`;
    }

    const prevCalculatedComponentStyle = this.get('calculatedComponentStyle');

    if (prevCalculatedComponentStyle !== style) {
      this.set('componentStyle', htmlSafe(style));
      this.set('calculatedComponentStyle', style);
    }
  }

  notifyVisibilityChange() {
    if (this.get('lastColumnAlwaysFluid')) {
      this.get('tableManager').recalculateCellSizes();
    } else {
      this.recalculateCellSize();
    }
  }

  /**
   * Handle click events and do some sorting maybe
   */
  click() {
    if (this.get('canSort')) {
      let sortByKeys = this.get('sortByString').split(',');

      let asc;
      let desc;

      if (this.get('inverted')) {
        asc = `-${this.get('key')}`;
        desc = this.get('key');
      } else {
        asc = this.get('key');
        desc = `-${asc}`;
      }
      // asc sorted so lets make it desc
      if (sortByKeys.includes(asc)) {
        sortByKeys = sortByKeys
          // filter out desc
          .filter((_) => _ !== asc);

        sortByKeys.push(desc);
      }

      // desc sorted so lets make it asc
      else if (sortByKeys.includes(desc)) {
        sortByKeys = sortByKeys
          // filter out asc
          .filter((_) => _ !== desc);
      }

      // not sorted by this key yet, so let's append this one
      else {
        sortByKeys.push(asc);
      }

      const retval = sortByKeys
        .filter((_) => _ !== '') // filter empty strings
        .join(',');

      this.onSortChange(retval);
    }
  }

  drawSplitLine() {
    const $parentTable = this.$().closest('.fde-ordered-table');
    const $splitLine = $('<div class="fde-ordered-table_split-line"></div>');
    $parentTable.append($splitLine);

    this.set('$splitLine', $splitLine);
  }

  removeSplitLine() {
    const $splitLine = this.get('$splitLine');
    $splitLine.remove();
  }

  onMouseMove(e) {
    this.positionLineToPageX(e.pageX);
  }

  onMouseUp(e) {
    this.removeSplitLine();
    this.resizeColumnToPageX(e.pageX);
    this.unbindMouseDragEvents();
  }

  onMouseLeave() {
    this.removeSplitLine();
    this.unbindMouseDragEvents();
  }

  resizeColumnToPageX(pageX) {
    const orderedTableOffset = this.$().closest('.fde-ordered-table').offset();
    const orderedTableWidth = this.$().closest('.fde-ordered-table').outerWidth();

    const headerCellLeftOffset = this.$().offset().left - orderedTableOffset.left;

    const width = pageX - orderedTableOffset.left - headerCellLeftOffset;

    // Always have at least 5 pixels of padding left or right
    const constrainedWidth = Math.min(Math.max(width, 5), orderedTableWidth - 5);

    const tableManager = this.get('tableManager');

    tableManager.setColumnWidth(this, constrainedWidth);
  }

  positionLineToPageX(pageX) {
    const orderedTableOffset = this.$().closest('.fde-ordered-table').offset();
    const orderedTableWidth = this.$().closest('.fde-ordered-table').outerWidth();

    const relX = pageX - orderedTableOffset.left;

    let constrainedRelX = Math.min(Math.max(relX, 0), orderedTableWidth);

    this.get('$splitLine').css('left', constrainedRelX);
  }

  bindMouseDragEvents() {
    // bind these so we can attach them to the body
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    // We listen to the body for some mouse events
    $('body').on('mousemove', this.onMouseMove);
    $('body').on('mouseleave', this.onMouseLeave);
    $('body').on('mouseup', this.onMouseUp);
  }

  unbindMouseDragEvents() {
    $('body').off('mousemove', this.onMouseMove);
    $('body').off('mouseleave', this.onMouseLeave);
    $('body').off('mouseup', this.onMouseUp);
  }

  @action
  handleResizerMouseDown(e) {
    //Prevents selection of text.
    e.preventDefault();

    this.drawSplitLine();
    this.bindMouseDragEvents();

    this.positionLineToPageX(e.pageX);
  }
}
