import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default class IndexRoute extends Route {
  /** @type {AjaxService} */
  @service('ajax')
  ajaxService;

  async model() {
    return RSVP.hash({
      limits: this.store.createRecord('integration').salesforceLimits(),
      logs: this.store.query('integration-log-record-by-integration-name', {
        page: { limit: 300 }
      })
    });
  }
}
