import Fixture, {
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class SfOrdersModalsAlertFixture extends Fixture {
  constructor(name) {
    super('data-test-sf-modal', name);
  }
}

export const sfOrdersModalsAlert = getElementDecoratorFor(SfOrdersModalsAlertFixture);
export const sfOrdersModalsAlertWithValue = getElementWithValueDecoratorFor(
  SfOrdersModalsAlertFixture
);
