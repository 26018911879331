import classic from 'ember-classic-decorator';
import Clock from 'ember-cli-clock/services/clock';

/**
 * @class Tock
 * Minute Observer
 */
@classic
export default class TockService extends Clock {
  interval = 60000;
}
