import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { not } from '@ember/object/computed';
import { action } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('fde-collapsible-segment')
@classNameBindings('expanded', 'collapsed', 'color', 'isLoading:loading')
export default class CollapsibleSegment extends Component {
  /** @type {string} color of the header */
  color = '';

  /** @type {string} icon for this segment */
  icon = '';

  /** @type {string} title of this segment */
  title = '';

  /** @type {string} subtitle of this segment */
  subTitle = '';

  /** @type {boolean} whether or not this segment is expanded */
  expanded = false;

  /** @type {boolean} whether or not this segment is expanded from above */
  forceExpanded = false;

  /** @type {boolean} whether or not this segment is expanded */
  @not('expanded')
  collapsed;

  @action
  toggle() {
    this.toggleProperty('expanded');
  }
}
