import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import MF from 'ember-data-model-fragments';
import CompositeLocation from '../decorators/composite-location';

export default MF.Fragment.extend(CompositeLocation, {
  name: attr('string'),
  rawAddress: attr('string'),
  addressLine1: attr('string'),
  addressLine2: attr('string'),
  street: attr('string'),
  addressCode: attr('string'),

  city: attr('string'),
  country: attr('string'),
  province: attr('string'),

  building: attr('string'),
  buzzer: attr('string'),
  unitNumber: attr('string'),
  floor: attr('string'),

  latitude: attr('number'),
  longitude: attr('number'),

  /** @type {string} */
  label: computed('name', 'humanize', function () {
    const name = this.get('name');
    const humanize = this.get('humanize');
    return name ? `${name}: ${humanize}` : humanize;
  }),

  /** @override */
  identityKeys() {
    return [
      'building',
      'street',
      'city',
      'province',
      'country',
      'addressCode',
      'unitNumber',
      'floor',
      'buzzer'
    ];
  }
});
