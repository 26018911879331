import Fixture, {
  element,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';
import { sfTabViewTabGroupTab } from 'star-fox/features/components/sf/tab-view/tab-group/tab/fixture-chai';
import { sfTabViewTabPane } from 'star-fox/features/components/sf/tab-view/tab-pane/fixture-chai';

export default class SfOrdersOrderDetailsFixture extends Fixture {
  constructor() {
    super('data-test-sf-orders-order-details');
  }
  @sfTabViewTabGroupTab('', 'clientFees') clientFees;
  @sfTabViewTabGroupTab('', 'restaurantFees') restaurantFees;

  @sfTabViewTabPane('', 'clientFees') clientFeesPane;
  @sfTabViewTabPane('', 'restaurantFees') restaurantFeesPane;

  @element serviceFeeType;
  @element customizeServiceFeeAmount;

  enterClientFeesTab() {
    return this.clientFees.click();
  }

  enterRestaurantFeesTab() {
    return this.restaurantFees.click();
  }
}

export const sfOrdersOrderDetails = getElementDecoratorFor(SfOrdersOrderDetailsFixture);
export const sfOrdersOrderDetailsWithValue = getElementWithValueDecoratorFor(
  SfOrdersOrderDetailsFixture
);
