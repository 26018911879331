import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-forms-meal-planning-form-for-template-form-for')
export default class MealPlanningFormForTemplateFormFor extends Component {
  /** @type {MealPlan} */
  mealPlan = null;

  /** @type {Function} */
  addMeal(day) {
    day.addMeal();
  }

  /** @type {Function} */
  removeMeal(meal, day) {
    day.removeMeal(meal);
  }

  /** @type {Function} */
  addSlot(meal) {
    meal.addSlot();
  }

  /** @type {Function} */
  removeSlot(slot, meal) {
    meal.removeSlot(slot);
  }
}
