import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import { action } from '@ember/object';

@classic
export default class PaymentCardsController extends Controller {
  @action
  resetNewPaymentCard() {
    this.set(
      'newPaymentCard',
      this.store.createRecord('payment-card', {
        user: this.get('model.user'),
        country: 'CA'
      })
    );
  }
}
