import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import EmberObject, { action } from '@ember/object';
import moment from 'moment-timezone';

@classic
export default class ArchiveController extends Controller {
  /**
   * @type {Object} holder for archive props
   */
  archive = null;

  init() {
    super.init(...arguments);

    this.archive = EmberObject.create({
      startDate: new Date(),
      endDate: new Date()
    });
  }

  @action
  fetchArchive() {
    const format = (mo) => moment(mo).format('YYYY-MM-DD');

    window.location.assign(
      `/api/v3/archived-orders/${format(this.get('archive.startDate'))}/${format(
        this.get('archive.endDate')
      )}`
    );
  }
}
