import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { capitalize } from '@ember/string';

@classic
@tagName('')
export default class AvailabilityDay extends Component {
  form = null;

  /** @type {string} monday-sunday */
  day = '';

  /** @type {DriverWeek} */
  model = null;

  /** @type {boolean} whether or not this day is available for breakfast */
  @computed('model', 'day')
  get breakfastKey() {
    const day = capitalize(this.get('day'));
    return `available${day}Breakfast`;
  }

  /** @type {boolean} whether or not this day is available for breakfast */
  @computed('model', 'day')
  get lunchKey() {
    const day = capitalize(this.get('day'));
    return `available${day}Lunch`;
  }

  /** @type {boolean} whether or not this day is available for breakfast */
  @computed('model', 'day')
  get dinnerKey() {
    const day = capitalize(this.get('day'));
    return `available${day}Dinner`;
  }
}
