import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { task } from 'ember-concurrency';

import { SubscriptionPlanPlanTypes } from 'star-fox/models/subscription-plan';

export default Component.extend({
  model: null,
  newBenefit: null,
  hideCustomPlanSwitch: false,
  store: service(),
  didSubmit() {},
  planTypes: Object.values(SubscriptionPlanPlanTypes),

  init() {
    this._super();
    this.get('fetchPlanFeaturesTask').perform();
    this.get('fetchSubscriptionAppConfigTask').perform();
  },

  fetchPlanFeaturesTask: task(function* () {
    return yield this.get('store').query('feature-flag-description', {
      page: { limit: 200 },
      filter: { isPlanFeature: true },
      include: 'subscription-plans'
    });
  }),

  fetchSubscriptionAppConfigTask: task(function* () {
    const subscriptionAppConfig = yield this.get('store')
      .query('app-configuration', {
        filter: { key: 'subscription' }
      })
      .then((_) => _.get('firstObject'));

    const currentJsonClientOverride = this.get('model.jsonClientOverride');

    const jsonClientOverride = Object.assign(
      {},
      subscriptionAppConfig.get('preferences.defaultClientAttributesForSubscription'),
      currentJsonClientOverride
    );

    this.get('model').set('jsonClientOverride', jsonClientOverride);
    return jsonClientOverride;
  }),

  planFeatures: alias('fetchPlanFeaturesTask.lastSuccessful.value'),
  subscriptionPlanAppConfig: alias('fetchSubscriptionAppConfigTask.lastSuccessful.value'),

  toggleFeatureTask: task(function* (toggleState, featureFlagDescription) {
    if (toggleState) {
      featureFlagDescription.get('subscriptionPlans').pushObject(this.model);
    } else {
      featureFlagDescription.get('subscriptionPlans').removeObject(this.model);
    }

    yield featureFlagDescription.save();
  }),

  toggleFeature: action(function (featureFlagDescription, toggleState) {
    this.get('toggleFeatureTask').perform(toggleState, featureFlagDescription);
  }),

  addBenefit: action(function () {
    this.set(
      'newBenefit',
      this.get('store').createRecord('feature-flag-description', {
        subscriptionPlans: [this.get('model')],
        isPlanFeature: true
      })
    );
  }),

  deleteNewBenefit: action(function () {
    this.get('newBenefit').destroyRecord();
  }),

  deleteBenefit: action(function (benefit) {
    if (
      window.confirm(
        'Deleting this benefit, will remove it as an option for all subscription plans. Are you sure you want to do this?'
      )
    ) {
      benefit.destroyRecord();
    }
  })
});
