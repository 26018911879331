import { attr } from 'renard/models/foodee';
import MF from 'ember-data-model-fragments';

export default MF.Fragment.extend({
  accountingNotes: attr('string'),
  courierNotes: attr('string'),
  clientNotes: attr('string'),
  clientReferenceNumber: attr('string'),
  internalNotes: attr('string'),
  restaurantNotes: attr('string'),
  eaterNotes: attr('string')
});
