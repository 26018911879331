import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import EmberObject, { action } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('fde-menus-menu-form-menu-option-group')
export default class MenuOptionGroup extends Component {
  /** @type {Service} */
  @service
  formFor;

  /** @type {boolean} */
  readonly = false;

  /** @type {MenuItem} */
  menuItem = null;

  /** @type {MenuOptionGroup} */
  menuOptionGroup = null;

  /** @type {DietaryTag[]} */
  availableDietaryTags = null;

  /** @type {string[]} collection of legal verbs */
  verbs = ['pick', 'add', 'remove', 'substitute', 'includes'];

  /** @type {Object} */
  menuPreviewStub = null;

  /** @type {boolean} */
  hasNewMenuOptions = false;

  /**
   * @type {function} callback for selecting a new menu option group
   */
  onSelectMenuOptionGroup() {}

  /**
   * @type {function} callback for creating menu option groups
   */
  createMenuOptionGroup() {}

  /**
   * @type {function} callback for creating menu option items
   */
  createMenuOptionItem() {}

  init() {
    super.init(...arguments);

    this.set('menuPreviewStub', EmberObject.create());
  }

  get isDirty() {
    return [
      this.menuOptionGroup.hasDirtyAttributes,
      this.menuItem.menuOptionGroups.isAny('hasDirtyAttributes')
    ].any((e) => Boolean(e));
  }

  @action
  reorderMenuOptionGroups(reorderedItems) {
    // Updates the item's new position's value
    reorderedItems.map((item, index) => (item.position = index + 1)); // Don't start the list with 0
    // Saves the new list order
    this.set('menuItem.menuOptionGroups', reorderedItems);
    this.menuItem.save();
  }

  @action
  reorderMenuOptions(reorderedItems) {
    // Updates the item's new position's value
    reorderedItems.map((item, index) => (item.position = index + 1)); // Don't start the list with 0
    // Saves the new list order
    this.set('menuOptionGroup.menuOptionItems', reorderedItems);
    this.menuOptionGroup.save();
  }
}
