import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';

@classic
export default class ShowRoute extends Route {
  queryParams = {
    runLogsPageOffset: { refreshModel: true },
    runLogsSearchText: { refreshModel: true }
  };

  async model({ runLogsPageOffset, runLogsSearchText, rule_id }) {
    const rule = await this.store.find('rules-rule', rule_id);
    const controller = this.controllerFor('logged-in.admins.rules.show');

    controller.set('isSearching', true);

    return RSVP.hash({
      rule,
      runLogs:
        rule &&
        rule.query('runLogs', {
          page: {
            limit: 20,
            offset: runLogsPageOffset
          },

          filter: {
            search: runLogsSearchText
          },

          sort: '-createdAt'
        })
    }).finally(() => controller.set('isSearching', false));
  }
}
