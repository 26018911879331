import classic from 'ember-classic-decorator';
import StorageObject from 'ember-local-storage/local/object';

@classic
class Storage extends StorageObject {}

// Uncomment if you would like to set initialState
Storage.reopenClass({
  initialState() {
    return {
      /** @type{number} **/
      max_uses_per_meal: 1,

      /** @type{number} **/
      max_uses_per_day: 1,

      /** @type{number} **/
      max_uses_per_week: 1,

      /** @type{number} **/
      fav_coefficient: 5,

      /** @type{number} **/
      new_coefficient: 5,

      /** @type{number} **/
      last_week_coefficient: 5,

      /** @type{number} **/
      last_week_offset: 7
    };
  }
});

export default Storage;
