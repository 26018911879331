import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { guidFor } from '@ember/object/internals';
import Component from '@ember/component';
import { computed } from '@ember/object';

@classic
@classNames('form-control fde-radio-group-control')
export default class RadioGroupControl extends Component {
  /** @type {*} currently selected value */
  value = null;

  /** @type {*[]} collection of radio group values */
  values = null;

  /** @type {string} identifier of radio-group */
  @computed
  get group() {
    return guidFor(this);
  }

  /** @type {function} callback function when the selected value is changed */
  onChange() {}

  /** @type {boolean} If radio-group needs a none option */
  hasNoneOption = false;

  /** @type {boolean} Should apply the classes to create columns */
  hasColumns = false;

  /** @type {Object} Fake object to use for optional none option in radio group */
  noneOption = null;

  /** @type {string} none option label */
  noneOptionLabel = 'None';

  /** @type {string} Generated selector for targeting purposes */
  noneOptionSelector = `${guidFor(this)}-radio`;
}
