import classic from 'ember-classic-decorator';
import EmberObject from '@ember/object';
import { copy } from '@ember/object/internals';

/**
 * @typedef {Object} Column
 * @property {Number} width
 * @property {Number} minWidth
 * @property {Number} maxWidth
 * @property {Number} order
 * @property {boolean} visible
 */

/**
 * @class TableManager
 * Table manager registers and manages components of a table component at a high level.
 * A table manager instance is created for every table component.
 *
 * It's purpose is to coordinate distant elements so that parent and sibling components can
 * communicate more directly
 */
@classic
export default class TableManager extends EmberObject {
  /** @override */
  init() {
    super.init(...arguments);

    this.setProperties({
      rows: [],
      headerCells: [],
      columns: {},
      cellRowMap: {},
      defaultValuesForColumns: {}
    });

    if (this.get('shouldStoreColumnConfig')) {
      this.restoreColumnConfig();
    }
  }

  /** @type {?Component[]} */
  rows = null;

  /** @type {object} */
  columns = null;

  /** @type {?Component[]} */
  headerCells = null;

  /** @type { { rowIndex: Component[] } } */
  cellRowMap = null;

  /** @type {?Component} */
  actionMenu = null;

  /** @type {?String} */
  storageKey = null;

  /** @type {boolean} */
  shouldStoreColumnConfig = false;

  /** @type {boolean} */
  tableIsRendering = false;

  /**
   * Registers the action menu component with the table manager - ensures only 1 action menu can exist in
   * the table header
   * @param {Component} actionMenu
   */
  registerActionMenu(actionMenu) {
    const currentActionMenu = this.get('actionMenu');
    if (currentActionMenu) {
      console.assert(false, 'You can not register more than one action menu per table.');
    } else {
      this.set('actionMenu', actionMenu);
    }
  }

  /**
   * Unregisters the action menu
   * @param {Component} actionMenu
   */
  unregisterActionMenu(actionMenu) {
    const currentActionMenu = this.get('actionMenu');

    console.assert(actionMenu === currentActionMenu, 'Wrong action menu. Can not unregister.');
    this.set('actionMenu', null);
  }

  /**
   * Registers the row with the table manager - should only register on didInsertElement
   * @param {Component} row
   */
  registerRow(row) {
    this.get('rows') && this.get('rows').push(row);
  }

  /**
   * Unregisters the row to the table manager - should only unregister on willDestroyElement
   * @param {Component} row
   */
  unregisterRow(row) {
    this.get('rows') && this.get('rows').removeObject(row);
  }

  /**
   * Registers the row with the table manager - should only register on didInsertElement
   * @param {Component} cell
   * @param {number} rowIndex
   */
  registerCell(cell, rowIndex) {
    if (cell.get('isRegistered')) {
      this.unregisterCell(cell, rowIndex, true);
    }

    const cellRowMap = this.get('cellRowMap');
    cellRowMap[rowIndex] = cellRowMap[rowIndex] || [];
    cellRowMap[rowIndex].push(cell);
    this.set('cellRowMap', copy(cellRowMap));

    cell.set('isRegistered', true);
  }

  /**
   * Unregisters the row to the table manager - should only unregister on willDestroyElement
   * @param {Component} row
   */
  unregisterCell(cell, rowIndex) {
    if (!cell.get('isRegistered')) {
      return;
    }

    const cellRowMap = this.get('cellRowMap');
    cellRowMap[rowIndex].removeObject(cell);
    !cellRowMap[rowIndex].length && delete cellRowMap[rowIndex];
    this.set('cellRowMap', copy(cellRowMap));
    cell.set('isRegistered', false);
  }

  /**
   * Registers the header cell with the table manager
   * @param {Component} cell
   * @param {Object} defaultColumnValues
   */
  registerHeaderCell(cell, defaultColumnValues) {
    const headerCells = this.get('headerCells') || [];

    const defaultValuesForColumns = this.get('defaultValuesForColumns');
    defaultValuesForColumns[cell.get('key') || headerCells.length] = defaultColumnValues;

    const columns = this.get('columns');
    const column = columns[cell.get('key') || headerCells.length];

    const columnKey = cell.get('key') || headerCells.length;

    columns[columnKey] = column || {
      width: null,
      minWidth: null,
      maxWidth: null,
      order: headerCells.length,
      visible: null
    };

    columns[columnKey].key = cell.get('key') || headerCells.length;

    const newHeaderCells = headerCells.toArray();
    newHeaderCells.push(cell);

    this.set('headerCells', newHeaderCells);

    cell.set('isRegistered', true);
  }

  /**
   * Unregisters the row to the table manager - should only unregister on willDestroyElement
   * @param {Component} row
   */
  unregisterHeaderCell(cell) {
    const headerCells = this.get('headerCells') || [];

    const columns = this.get('columns');
    const indexOfCell = headerCells.indexOf(cell);

    delete columns[cell.get('key') || indexOfCell];

    const newHeaderCells = headerCells.toArray();
    newHeaderCells.removeObject(cell);

    this.set('headerCells', newHeaderCells);

    cell.set('isRegistered', false);
  }

  /**
   * Gets the header cell index
   * @param {Component} headerCell
   * @returns {Number}
   */
  getHeaderCellIndex(headerCell) {
    const headerCells = this.get('headerCells') || [];
    return headerCells.indexOf(headerCell);
  }

  /**
   * returns header cell component by key string
   * @param {string} key
   * @returns {Component}
   */
  getHeaderCellByKey(key) {
    return this.get('headerCells').findBy('key', key);
  }

  /**
   * Returns a column definition for a given cell component
   * @param {Component} cell
   * @returns {Column}
   */
  getColumnForCell(cell) {
    const cellRowMap = this.get('cellRowMap');
    const headerCells = this.get('headerCells');
    const columns = this.get('columns');

    const indexOfCell = cellRowMap[cell.get('rowIndex')].indexOf(cell);
    const columnHeader = headerCells[indexOfCell];

    return columns[columnHeader.get('key') || indexOfCell];
  }

  /**
   * Returns a column definition for a given headerCell component
   * @param {Component} cell
   * @returns {Column}
   */
  getColumnForHeaderCell(headerCell) {
    const headerCellIndex = this.getHeaderCellIndex(headerCell);
    const columns = this.get('columns');
    return columns[headerCell.get('key') || headerCellIndex];
  }

  /**
   * Sets the column width for a given header cell
   * @param {Component} headerCell
   * @param {Number} width
   */
  setColumnWidth(headerCell, width) {
    const cellRowMap = this.get('cellRowMap');

    const columnIndex = this.getHeaderCellIndex(headerCell);
    const columnKey = headerCell.get('key') || columnIndex;

    const columns = this.get('columns');
    columns[columnKey].width = width;

    Object.keys(cellRowMap).forEach((index) =>
      cellRowMap[index][columnIndex].recalculateCellSize()
    );

    headerCell.recalculateCellSize();

    if (this.get('shouldStoreColumnConfig')) {
      this.storeColumnConfig();
    }

    this.get('table').recalculateMinWidth();

    this.notifyPropertyChange('columns');
  }

  toggleColumnVisibility(headerCell) {
    const cellRowMap = this.get('cellRowMap');

    const columnIndex = this.getHeaderCellIndex(headerCell);
    const columnKey = headerCell.get('key') || columnIndex;
    const columns = this.get('columns');

    columns[columnKey].visible = !columns[columnKey].visible;

    Object.keys(cellRowMap).forEach((index) =>
      cellRowMap[index][columnIndex].notifyVisibilityChange()
    );

    headerCell.notifyVisibilityChange();

    if (this.get('shouldStoreColumnConfig')) {
      this.storeColumnConfig();
    }

    this.get('table').recalculateMinWidth();

    this.notifyPropertyChange('columns');
  }

  /** Triggers a recalculation of cell sizes for the entire table. */
  recalculateCellSizes() {
    const cellRowMap = this.get('cellRowMap');
    const headerCells = this.get('headerCells');

    Object.keys(cellRowMap).forEach((index) =>
      cellRowMap[index].forEach((_) => _.recalculateCellSize())
    );

    headerCells.forEach((_) => _.recalculateCellSize());
  }

  /** Sends action directly to all selected row components */
  sendActionToSelectedRows() {
    this.get('rows').forEach((row) => {
      row.get('isSelected') && row.send(...arguments);
    });
  }

  /**
   * Returns the selected rows
   * @returns {Component}
   */
  getSelectedRows() {
    return this.get('rows').filter((_) => _.get('isSelected'));
  }

  /**
   * Returns hideable header cells
   * @returns {Component}
   */
  getHideableHeaderCells() {
    return this.get('headerCells').filter((_) => _.get('hideable'));
  }

  restoreColumnConfig() {
    const storageKey = this.get('storageKey');

    if (storageKey) {
      const columnConfigStringData = localStorage.getItem(`${storageKey}.column-config`);
      const columnConfig = columnConfigStringData ? JSON.parse(columnConfigStringData) : {};
      this.set('columns', columnConfig);
    }
  }

  beginRenderingRows() {
    this.set('isRenderingRows', true);
  }

  endRenderingRows() {
    this.set('isRenderingRows', false);
  }

  /**
   * Returns the last visible column. Useful for forcing behaviour (such as fluidity) on the last visible column.
   * @returns {Object}
   */
  getLastVisibleColumn() {
    const headerCells = this.get('headerCells') || [];

    return headerCells.reduce((lastVisible, cell) => {
      const column = this.getColumnForHeaderCell(cell);
      return column.visible ? column : lastVisible;
    }, null);
  }

  storeColumnConfig() {
    const storageKey = this.get('storageKey');
    const columns = this.get('columns');

    if (storageKey && columns) {
      localStorage.setItem(`${storageKey}.column-config`, JSON.stringify(columns));
    }
  }

  /** Resets all the columns to default values set during registration and recalculates the cell sizes */
  resetAllColumnsToDefault() {
    const defaultValuesForColumns = this.get('defaultValuesForColumns');
    const columns = this.get('columns');

    Object.keys(columns).forEach((key) => {
      Object.assign(columns[key], defaultValuesForColumns[key]);
    });

    this.recalculateCellSizes();
  }

  /** @override Here to make sure people remember to unbind any event handlers */
  destroy() {
    super.destroy(...arguments);
    this.setProperties({
      rows: undefined,
      actionMenu: undefined
    });
  }
}
