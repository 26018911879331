import classic from 'ember-classic-decorator';
import RESTAdapter from '@ember-data/adapter/rest';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';

@classic
export default class GiftbitPointsAction extends RESTAdapter.extend(DataAdapterMixin) {
  authorizer = 'authorizer:devise';

  /**
   * @param type
   * @param id
   * @param {Snapshot} record
   * @returns {string}
   */
  buildURL(type, id, { record }) {
    const action = record.get('type') === 'reward' ? 'reward-points' : 'redeem-points-without-gift';

    return `/api/v3/giftbit-cards/${record.get('giftbitCard.id')}/${action}`;
  }
}
