import classic from 'ember-classic-decorator';
import { attributeBindings } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@attributeBindings('style')
export default class EmptyWrapper extends Component {
  /** @type {string} an html safe string **/
  style = null;
}
