import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import layout from './template';

export default Component.extend({
  layout,
  rule: null,
  actions: alias('rule.actions'),
  predicates: alias('rule.predicates'),
  form: null
});
