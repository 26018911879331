import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

/** Outlet component for */
@classic
@tagName('')
export default class TableHeaderActionMenu extends Component {
  /** @type {string} */
  wormholeId = null;

  /** @type {TableManager} */
  tableManager = null;

  /** @override */
  didInsertElement() {
    super.didInsertElement(...arguments);
    this.get('tableManager').registerActionMenu(this);
  }

  /** @override */
  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.get('tableManager').unregisterActionMenu(this);
  }
}
