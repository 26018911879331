import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import RSVP from 'rsvp';
import moment from 'moment-timezone';

@classic
export default class NewOrderController extends Controller {
  /** @type {Service} */
  @service
  orderService;

  /** @type {Service} */
  @service
  notify;

  /** @type {Cookies} */
  @service
  cookies;

  queryParams = [
    'areaId',
    'clientId',
    'restaurantId',
    'deliverAt',
    'hasMainDetails',
    'bookmark',
    'isGroupOrder'
  ];

  /** @type {boolean} */
  hasMainDetails = false;

  /** @type {?number} */
  areaId = null;

  /** @type {?number} */
  restaurantId = null;

  /** @type {?number} */
  clientId = null;

  /** @type {?string} */
  deliverAt = null;

  /** @type {?string} */
  bookmark = null;

  /** @type {boolean} */
  isGroupOrder = false;

  /** @type {Area[]} */
  @alias('model.areas')
  areas;

  /** @type {Order} */
  @alias('model.order')
  order;

  /** @type {Restaurant[]} */
  restaurants = null;

  /** @type {Client[]} */
  clients = null;

  /** @type {Area} */
  previousArea = null;

  /** @type {Object} */
  @computed('hasMainDetails', 'order.isGroupOrder', 'bookmark')
  get validationOptions() {
    const options = ['area', 'deliverAt'];
    const bookmark = this.get('bookmark');
    const hasMainDetails = this.get('hasMainDetails');
    const isGroupOrder = this.get('order.isGroupOrder');

    if (bookmark && !hasMainDetails) {
      options.push('bookmarkName', 'restaurant', 'numberOfPeople', 'bookmarkExpiresAt');
    }

    if (hasMainDetails) {
      options.push('restaurant', 'name', 'numberOfPeople');
    }

    if (hasMainDetails) {
      options.push('client');
    }

    if (isGroupOrder) {
      options.push('perPersonBudget');
    }

    return { only: options };
  }

  /**
   * @param {Area} area
   */
  _getClientsAndRestaurantsForArea(area) {
    const city = area && area.get('city');
    if (!city) {
      return RSVP.resolve();
    }

    return RSVP.hash({
      clients: this.store.query('client', { filter: { area: city } }),
      restaurants: this.store.query('restaurant', {
        filter: { area: city, active: true },
        page: { limit: 200 },
        fields: {
          restaurants: 'name,service-times,active-menu',
          'service-times': [
            'weekday',
            'delivery-start-time',
            'delivery-end-time',
            'pickup-start-time',
            'pickup-end-time'
          ].join(',')
        },
        sort: 'name',
        include: 'service-times'
      })
    }).then((_) => this.setProperties(_));
  }

  /**
   * If a deliverAt isn't set, it returns 21 hours in the future
   * It is highly unlikely that any Orders will be created for instant delivery
   * @returns {string}
   * @private
   */
  _getDefaultDeliverAt() {
    let deliverAt = this.get('deliverAt');
    const twentyOneHoursAhead = moment().endOf('day').add(21, 'hours').startOf('hour');
    const tomorrow = moment().add(1, 'day').startOf('day');

    if (!deliverAt) {
      deliverAt = twentyOneHoursAhead.isAfter(tomorrow) ? twentyOneHoursAhead : tomorrow;
    } else {
      deliverAt = moment(deliverAt);
    }

    return deliverAt.toDate();
  }

  /** Checks for queryParams, and sets the values to the order */
  _setOrderData() {
    const { areaId, clientId, restaurantId } = this.getProperties(
      'areaId',
      'clientId',
      'restaurantId'
    );

    const area = areaId ? this.store.findRecord('area', areaId) : null;

    const client =
      clientId && clientId !== 'undefined'
        ? this.store.findRecord('client', clientId, {
            include: 'owner,admin-contacts,admins,delivery-locations.location,payment-cards',
            reload: true
          })
        : null;

    const restaurant =
      restaurantId && restaurantId !== 'undefined'
        ? this.store.findRecord('restaurant', restaurantId, {
            include: 'pickup-locations.location,closures',
            reload: true
          })
        : null;

    return RSVP.hash({
      area,
      client,
      restaurant
    }).then((properties) => {
      this.set('previousArea', properties.area);

      properties.deliverAt = this._getDefaultDeliverAt();
      const order = this.get('order');
      const orderService = this.get('orderService');

      if (this.get('isGroupOrder')) {
        properties.isGroupOrder = true;
        properties.perPersonBudget = '2000';
      }

      order.setProperties(properties);

      return RSVP.hash({
        orderWithClientDefaults:
          properties.client &&
          orderService.updateOrderDetailsWithClientDefaults(order, properties.client),
        orderWithRestaurantDefaults:
          properties.restaurant &&
          orderService.updateOrderDetailsWithRestaurantDefaults(order, properties.restaurant),
        getClientsAndRestaurantsForArea: this._getClientsAndRestaurantsForArea(properties.area)
      });
    });
  }

  /**
   * Resets all queryParams to default values
   */
  _resetController() {
    this.setProperties({
      areaId: null,
      clientId: null,
      restaurantId: null,
      deliverAt: null,
      bookmark: null,
      isLoading: false,
      hasMainDetails: false,
      isTransitioning: false
    });
  }

  /** Sets the new order default area id cookie so that future orders default with that  */
  _setCookieForDefaultAreaId() {
    this.get('cookies').write(
      'ee.food.starfox.new-order-default-area-id',
      this.get('order.area.id')
    );
  }

  @action
  handleIsGroupOrderChange(isGroupOrder) {
    const orderProperties = {
      isGroupOrder
    };

    if (isGroupOrder) {
      orderProperties.perPersonBudget = '2000';
    } else {
      orderProperties.isMealPlanOrder = false;
      orderProperties.perPersonBudget = null;
    }

    const order = this.get('order');
    order.setProperties(orderProperties);
    this.set('isGroupOrder', isGroupOrder);
    order.adjustTimesRelativeToDeliverAt();
  }

  @action
  handleDeliverAtChange(deliverAt) {
    const order = this.get('order');
    order.set('deliverAt', deliverAt);
    order.adjustTimesRelativeToDeliverAt();
  }

  @action
  areaDidChange(area) {
    const previousArea = this.get('previousArea');

    this.set('areaId', area && area.get('id'));

    const deliverAt = this.get('order.deliverAt');
    const timeZone = area.get('isoTimeZone');

    const currentTimeStringWithoutTZ = moment
      .tz(deliverAt, previousArea.get('isoTimeZone'))
      .format('YYYY-MM-DDTHH:mm:ss');

    this.set(
      'order.deliverAt',
      moment.tz(currentTimeStringWithoutTZ, 'YYYY-MM-DDTHH:mm:ss', timeZone).toDate()
    );

    //Fetch the latest area closures
    area.query('areaClosures', {
      sort: 'startBlock',
      filter: { startsAfter: new Date().toISOString() }
    });

    this._getClientsAndRestaurantsForArea(area);

    this.set('previousArea', area);
  }

  @action
  changeQueryParam(queryParam, model) {
    const value = (model && model.get('id')) || null;
    this.set(queryParam, value);
  }

  @action
  searchForClients(search) {
    const area = this.get('order.area.city');

    return this.store
      .query('client', {
        filter: { search, area }
      })
      .then((clients) => this.set('searchedClients', clients));
  }

  @action
  backToMainDetails() {
    this.set('hasMainDetails', false);
  }

  @action
  enterAdditionalInformation() {
    const order = this.get('order');

    if (order.validate(this.get('validationOptions'))) {
      this.set('hasMainDetails', true);
    } else {
      const budgetError = order.get('errors').findBy('attribute', 'perPersonBudget');

      if (budgetError) {
        this.get('notify').error(budgetError.message[0]);
      }
    }
  }

  @action
  ignoreErrorsAndCreate() {
    const order = this.get('order');

    order.validate({ only: [] });
    order.save().then((_) => this.send('handleDidSave'));
  }

  @action
  handleDidSave() {
    this.set('isTransitioning', true);

    this._setCookieForDefaultAreaId();

    if (this.get('bookmark')) {
      this.transitionToRoute('logged-in.bookmarks.edit', this.get('order.id'));
      return;
    }

    this.transitionToRoute('logged-in.orders.edit.index', this.get('order.id'));
  }

  @action
  setOrderClientDefaults(client) {
    this.set('isLoading', true);
    const order = this.get('order');
    const orderService = this.get('orderService');

    if (client) {
      this.store
        .findRecord('client', client.get('id'), {
          include: 'owner,admin-contacts,admins,delivery-locations.location,payment-cards',
          reload: true
        })
        .then((client) => orderService.updateOrderDetailsWithClientDefaults(order, client))
        .finally((_) => this.set('isLoading', false));
    } else {
      orderService.updateOrderDetailsWithClientDefaults(order, null);
      this.set('isLoading', false);
    }
  }

  @action
  setOrderRestaurantDefaults(restaurant) {
    this.set('isLoading', true);
    const order = this.get('order');
    const orderService = this.get('orderService');

    if (restaurant) {
      this.store
        .findRecord('restaurant', restaurant.get('id'), {
          include: 'pickup-locations.location,closures',
          reload: true
        })
        .then((restaurant) =>
          orderService.updateOrderDetailsWithRestaurantDefaults(order, restaurant)
        )
        .finally((_) => this.set('isLoading', false));
    } else {
      orderService.updateOrderDetailsWithRestaurantDefaults(order, null);
      this.set('isLoading', false);
    }
  }

  @action
  buildAutoQuote() {
    const order = this.get('order');
    if (order.validate(this.get('validationOptions'))) {
      this.set('isQuoting', true);
      order
        .save()
        .then((_) => _.quote())
        .then((_) => {
          this.set('isTransitioning', true);

          this._setCookieForDefaultAreaId();

          return this.transitionToRoute('logged-in.orders.edit.cart', this.get('order.id'));
        })
        // TODO do a thing with this.
        .catch((_) => {
          this.get('notify').error(
            'Could not auto quote that order, please create the order and then try and build it manually.'
          );
        })
        .finally(() => this.set('isQuoting', false));
    }
  }

  @action
  handleDidSaveDeliveryLocation(newDeliveryLocation) {
    this.set('isLoading', true);
    const loc = newDeliveryLocation.get('location') || newDeliveryLocation;
    return this.locationOutOfZone(loc)
      .then((ooz) => {
        this.setProperties({
          'order.clientLocation': loc,
          'order.outOfZone': ooz
        });
        this.didSaveDeliveryLocation();
      })
      .finally(() => this.set('isLoading'), false);
  }
}
