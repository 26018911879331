import classic from 'ember-classic-decorator';
import FeedItemBuilder from './base';

@classic
export default class ChargeBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);
    feedItem.icon = 'dollar sign';

    const chargeType = version.getValue('type');
    const symbol = version.getValue('type') === 'value' ? '$' : '%';
    const value = `${version.getValue('amount')} ${symbol}`;

    feedItem.meta.unshift(`${chargeType}: ${version.get('itemId')}`);

    if (
      chargeType === 'RestaurantDiscount' ||
      chargeType === 'ClientDiscount' ||
      chargeType === 'PromoDiscount'
    ) {
      this._processDiscount(value, feedItem, version);
    } else {
      feedItem.subject = `${version.get('whodunnit')} ${version.get(
        'verb'
      )} a ${chargeType} for ${value}`;
    }

    return feedItem;
  }

  _processDiscount(value, feedItem, version) {
    if (version.get('event') === 'create') {
      feedItem.subject = `${version.get('whodunnit')} added a ${version.getValue(
        'type'
      )} for ${value}`;
    } else if (version.get('event') === 'update') {
      feedItem.subject = `${version.get('whodunnit')} changed a ${version.getValue(
        'type'
      )} to ${value}`;
    } else {
      feedItem.subject = `${version.get('whodunnit')} removed a ${version.getValue('type')}`;
    }
  }
}
