import Fixture, {
  element,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

import { sfOrdersModalsConfirm } from 'star-fox/features/components/sf/orders/modals/confirm/fixture-chai';
import { sfOrdersModalsAlert } from 'star-fox/features/components/sf/orders/modals/alert/fixture-chai';

export default class SfControlsActionReservationStateButtonFixture extends Fixture {
  constructor() {
    super('data-test-meal-planning-action-reservation-state-button');
  }

  @element dynamicButton;
  @element publishButton;
  @sfOrdersModalsConfirm({ selector: 'sf-modals-confirm' }) confirmModal;
  @sfOrdersModalsAlert({ selector: 'sf-modals-alert' }) alertModal;

  async publishPlanOrReservation() {
    return this.publishButton.click();
  }

  async toggleStateMenu() {
    return this.dynamicButton.click();
  }
}

export const sfControlsActionOrderStateButton = getElementDecoratorFor(
  SfControlsActionReservationStateButtonFixture
);
export const sfControlsActionOrderStateButtonWithValue = getElementWithValueDecoratorFor(
  SfControlsActionReservationStateButtonFixture
);
