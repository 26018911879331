export function applyError(model, errors) {
  if (model._internalModel) {
    model._internalModel.adapterDidInvalidate(errors);
  } else {
    model.set('errors', errors);
  }
}

export default {
  applyError: applyError
};
