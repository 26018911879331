import classic from 'ember-classic-decorator';
import OrderRoute from 'star-fox/features/logged-in/orders/edit/route';

@classic
export default class EditOrderRoute extends OrderRoute {
  /** @override */
  templateName = 'logged-in.orders.edit';

  orderEditRoute = 'logged-in.restaurants.show.capacities.edit-order';
}
