import classic from 'ember-classic-decorator';
import { attributeBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { alias, bool } from '@ember/object/computed';

@classic
@classNames('fde-forms-meal-planning-form-for-meal-form-for ui green segment')
@attributeBindings('index:data-test-meal-planning-meal-form-for')
export default class MealFormFor extends Component {
  @service appConfiguration;

  /** @type {Area} */
  area = null;

  /** @type {MealPlanMeal} */
  meal = null;

  /** @type {MealPlanMealDay} */
  day = null;

  /** @type {MealPlanInstance|MealPlanTemplate} */
  mealPlan = null;

  /** @type {Restaurant[]} */
  restaurants = null;

  /** @type {boolean} */
  readonly = false;

  onRestaurantChange() {}

  @alias('appConfiguration.mealPlanning.preferences.sameDayDeadlineRanges')
  sameDayDeadlineRanges = [];

  @computed('sameDayDeadlineRanges.@each.deadlineTime', 'meal.deliverAt')
  get deadlineTimeForSameDaySlots() {
    const deadlineTime = this.sameDayDeadlineRanges.reduce((time, range) => {
      if (time) {
        return time;
      }
      const { startTime, endTime, deadlineTime } = range;
      const start = moment(startTime, 'HH:mm');
      const end = moment(endTime, 'HH:mm');
      const deadline = moment(deadlineTime, 'HH:mm');
      const deliveryTime = moment(this.meal.deliverAt.toString(), 'HH:mm:ss');
      if (deliveryTime.isSameOrAfter(start) && deliveryTime.isBefore(end)) {
        return `${this.day.shortName} ${deadline.format('h:mm a')}`;
      }
    }, null);
    return deadlineTime;
  }

  @bool('deadlineTimeForSameDaySlots') isDeliveryTimeInSameDayDeadlineRanges;

  @computed('readonly', 'meal.inProgress')
  get mealDetailsFrozen() {
    return this.get('readonly') || this.get('meal.inProgress');
  }

  /** @type {Date} */
  @computed('mealPlan.startOfWeek', 'day', 'meal')
  get mealDeliverAtDate() {
    const startOfWeek = this.get('mealPlan.startOfWeek');
    const day = this.get('day');
    const area = this.get('area');
    return this.get('meal').getDeliverAtDate(startOfWeek, day, area);
  }

  @computed('day.dayOfWeek', 'meal.leadTime')
  get leadTimeDayAndTime() {
    const meal = this.get('meal');
    return moment(meal.get('deliverAt.moment'))
      .day(this.get('day.dayOfWeek') + 1)
      .subtract(meal.get('leadTime'), 'hours')
      .format('ddd h:mm a');
  }

  /** @type {boolean} */
  @computed('meal.reservations.@each.state')
  get hasOpenReservations() {
    const reservations = this.get('meal.reservations') || [];
    return (
      reservations.get('length') &&
      reservations.filter((_) => _).some((_) => _.get('state') !== 'cancelled')
    );
  }

  selectedRestaurantConstraint = null;
  onSelectRestaurantConstraint = null;

  @task(function* () {
    return yield this.get('area')
      .planReservations({
        meal_planning_reservation_ids: this.get('meal.slots').mapBy('reservationId')
      })
      .catch((_) => this.get('notify').error(_));
  })
  requestNewRestaurantTask;

  @action
  handleHasSameDayReservationsChange(value) {
    if (value) {
      this.meal.numberOfSameDayReservations = 1;
    } else {
      this.meal.numberOfSameDayReservations = 0;
    }
  }

  @action
  checkSameDayEligibilityAndReset() {
    if (!this.isDeliveryTimeInSameDayDeadlineRanges) {
      this.meal.hasSameDayReservations = false;
      this.meal.numberOfSameDayReservations = 0;
    }
  }

  /** @type {?Function} */
  removeMeal() {}

  /** @type {?Function} */
  addSlot() {}

  /** @type {?Function} */
  removeSlot() {}

  /** @type {boolean} */
  canReserve = false;
}
