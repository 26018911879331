import classic from 'ember-classic-decorator';
import Helper from '@ember/component/helper';

/**
 * This helper injects meta tags into the template
 * Use this helper to inject meta tags for crawlers
 * External NPM modules that use ember-cli-head (pretty much all of them)
 * Conflict with other modules used in this project
 */
export function metaTag(params) {
  let [prop, val, content] = params;

  const oldMeta = document.querySelector(`meta[${prop}="${val}"]`);
  oldMeta && oldMeta.parentNode.removeChild(oldMeta);

  const meta = document.createElement('meta');
  meta.setAttribute(prop, val);
  meta['content'] = content;

  const head = document.getElementsByTagName('head')[0];
  const firstHeadChild = head.children[0];
  //Important to have meta tags at the beginning of head for social sharing services
  head.insertBefore(meta, firstHeadChild);
}

@classic
export default class MetaTag extends Helper {
  compute = metaTag;
}
