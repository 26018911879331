import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-controls-date-buttons ui buttons')
export default class DateButtons extends Component {
  /** @type {function} the callback to change to last week **/
  goToLast = null;

  /** @type {function} the callback to change to next week **/
  goToNext = null;
}
