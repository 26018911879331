import classic from 'ember-classic-decorator';
import Service, { inject as service } from '@ember/service';

/** Triggers push notifications on FleetFox */
@classic
export default class DriverNotificationService extends Service {
  /** @type {Ember.Service} */
  @service
  ajax;

  /**
   * Sends the order confirmation push notification to a driver
   *
   * @param {string|number} driverId
   * @returns {Promise}
   */
  notifyDriver(driverId) {
    return this.get('ajax').post(`/api/v3/users/${driverId}/notify-driver`);
  }

  /**
   * Sends the order confirmation push notification to all drivers
   * who have outstanding confirmations
   *
   * @param {string[]|number[]} couriers - Array of courier ids to notify
   * @returns {Promise}
   */
  notifyCouriers(couriers) {
    return this.get('ajax').post('/api/v3/couriers/notify-drivers', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      data: JSON.stringify({ couriers })
    });
  }
}
