import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

@classic
@tagName('')
export default class PlanningForm extends Component {
  selectedRestaurantConstraint = null;
  readonly = false;
  canReserve = false;
  confirmOnMealRemove = false;
  activeTab = 'Plan';
  showLogs = false;

  didDestroy() {}

  @task(function* (mealPlan) {
    if (mealPlan.get('orderTemplate.content')) {
      yield mealPlan
        .get('orderTemplate.content')
        .reloadWith(
          [
            'owner',
            'client.delivery-locations.location',
            'contact',
            'client-location',
            'payment-card',
            'area',
            'teams',
            'client-discounts',
            'menu-filter.requirement-constraints.meal-types',
            'menu-filter.requirement-constraints.food-types',
            'menu-filter.requirement-constraints.dietary-tags'
          ].join(',')
        );
    }
  })
  loadOrderTemplateTask;

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.get('loadOrderTemplateTask').perform(this.planOrTemplate);

    if (this.planOrTemplate.calculateWarnings) {
      this.planOrTemplate.calculateWarnings.perform();
    }
  }

  @action
  changeActiveTab(tab) {
    this.set('activeTab', tab);
  }

  @action
  handleOrderTemplateDidSave(instance) {
    this.setProperties({
      instanceToSaveBackToTempalte: instance,
      shouldSaveBackToTemplateModal: true
    });
  }

  @action
  handleSelectedConstraint(constraint) {
    this.set('selectedRestaurantConstraint', constraint);
  }

  @action
  reloadLogRecord() {
    return this.get('planOrTemplate').hasMany('logRecords').reload();
  }
}
