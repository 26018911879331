import classic from 'ember-classic-decorator';
import { copy } from '@ember/object/internals';
import Helper from '@ember/component/helper';
import { getOwner } from '@ember/application';
import { dasherize } from '@ember/string';

/**
 * Search action helper looks up a search action class in the `search-actions` directory, runs the search method of the
 * specified class and then sets the results on the specified property name of the target.
 *
 * The returning action takes a query string, and search options can be optionally specified to be passed to the search
 * action at definition time.
 * @param {Array} params
 * @returns {Function}
 */
@classic
export default class SearchAction extends Helper {
  compute([searchActionName, propertyName, target, resource = null], options = {}) {
    return function (query) {
      let dasherizedSearchActionName = dasherize(searchActionName);

      // Note: If the relationship to the 'resource' param corresponds to a 'hasMany' type with a pluralized name,
      // custom search actions may fail to execute correctly (e.g., resource=area, searchActionName=restaurant).
      // Recommend the 'searchActionName' parameter to be passed as a plural form to avoid these issues.
      const customSearchAction = getOwner(target).lookup(
        `search-action:${dasherizedSearchActionName}`
      );
      if (customSearchAction) {
        return customSearchAction
          .search(query, options, resource)
          .then((results) => target.set(propertyName, results));
      }

      const baseSearchAction = getOwner(target).lookup(`search-action:base`);
      return baseSearchAction
        .defaultSearch(searchActionName, query, 'search', [], copy(options), resource)
        .then((results) => target.set(propertyName, results));
    };
  }
}
