import classic from 'ember-classic-decorator';
import { oneWay } from '@ember/object/computed';
import { action } from '@ember/object';
import Controller from '@ember/controller';
import { run } from '@ember/runloop';

@classic
export default class PromoCodesController extends Controller {
  /** @type {number} */
  @oneWay('model.meta.recordCount')
  recordCount;

  /** @type {number} records per page */
  pageLimit = 20;

  /** @type {number} current page */
  pageOffset = 0;

  /** @type {boolean} whether or not to show the expired promo codes or not */
  showExpired = false;

  /** @type {string} sorting string */
  sort = '';

  /** @type {Area[]} collection of available areas */
  areas = null;

  /** @type {Restaurant[]} collection of available restos */
  restaurants = null;

  /** @type {Restaurant[]} results of searching for restos */
  searchedRestaurants = null;

  /** @type {DiscountCodes[]} result of all DiscountCodes */
  discountCodes = null;

  doSearch() {
    this.setProperties({
      searchText: this.get('_searchText'),
      pageOffset: 0
    });
  }

  @action
  toggleShowExpired() {
    this.setProperties({
      pageOffset: 0,
      showExpired: !this.get('showExpired')
    });
  }

  @action
  onSearchChange(searchText) {
    this.set('_searchText', searchText);

    // prevent multiple search
    run.debounce(this, this.doSearch, 400);
  }

  @action
  searchForRestaurants(searchText) {
    return this.store
      .query('restaurant', { filter: { search: searchText } })
      .then((restaurants) => this.set('searchedRestaurants', restaurants));
  }
}
