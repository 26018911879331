import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

/**
 * Simple component for committing fields
 */
@classic
@classNames('ui buttons fde-prev-next-buttons')
export default class NextPrevButtons extends Component {
  /** @type {string} prevText text to display in prev button */
  prevText = 'Prev';

  /** @type {string} nextText text to display in next button */
  nextText = 'Next';

  /** @type {string} nowText text to display in now button */
  nowText = 'Now';

  /** @type {boolean} performingNextAction if the next button is acting */
  performingNextAction = false;

  /** @type {boolean} performingPrevAction if the prev button is acting */
  performingPrevAction = false;

  /** @type {boolean} performingNowAction if the now button is acting */
  performingNowAction = false;

  /** @type {boolean} canNext if the next is enabled */
  canNext = true;

  /** @type {boolean} canPrev if the prev is enabled */
  canPrev = true;

  /** @type {boolean} canPrev if the now is enabled */
  canNow = true;

  /** @type {function} onNext handler for when the next button is pressed **/
  onNext() {}

  /** @type {function} onPrev handler for when the prev button is press */
  onPrev() {}

  /** @type {function} onPrev handler for when the prev button is press */
  onNow() {}
}
