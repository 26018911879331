import classic from 'ember-classic-decorator';
import { oneWay } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { run } from '@ember/runloop';

@classic
export default class IndexController extends Controller {
  /** @property {string} searchText - text for searching the back end with */
  searchText = '';

  /** @property {number} sort - sorting string */
  sort = 'name';

  /** @property {number} */
  @oneWay('model.restaurants.meta.recordCount')
  recordCount;

  /** @property {number} pageLimit - records per page */
  pageLimit = 20;

  /** @property {number} pageOffset - current page */
  pageOffset = 0;

  queryParams = [
    'pageLimit',
    'pageOffset',
    'searchText',
    'sort',
    'areas',
    'cuisines',
    'dietary_tag_ordering',
    'can_team_order',
    'active',
    'coming_soon',
    'is_new',
    'can_asap_order',
    'available_at',
    'can_foodee_plus_team_order',
    'can_same_day_foodee_plus_team_order'
  ];

  /** @type {FilterCollection} */
  filterCollection = null;

  /** @type {Area[]} */
  @computed('model.areas')
  get filterAreas() {
    // get the areas query string
    const areas = this.get('areas');

    // return the areas that are in the filter string
    return this.get('model.areas').filter((area) => areas.includes(area.get('city')));
  }

  /** Sets the searchText query param */
  doSearch() {
    this.setProperties({
      searchText: this.get('_searchText'),
      pageOffset: 0
    });
  }

  @action
  onSearchChange(searchText) {
    this.set('_searchText', searchText);

    // prevent multiple search
    run.debounce(this, this.doSearch, 400);
  }

  @action
  handleFilterApply() {
    this.send('setQueryParamsFromFilterCollection');
  }
}
