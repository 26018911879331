import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-order-contact-form')
export default class ContactFormFor extends Component {
  /** @type {Contact} */
  contact = null;

  /** @type {Function} */
  didSaveContact() {}
}
