import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { action } from '@ember/object';
import Component from '@ember/component';

/**
 * Sortable Table configured for restaurant admins
 */
@classic
@classNames('fde-tables-restaurant-admins')
export default class RestaurantAdmins extends Component {
  /** @type {Service} */
  @service
  notify;

  /** @type {boolean} */
  readonly = false;

  /** @type {Restaurant} */
  restaurant = null;

  /** @type {boolean} */
  editEmail = false;

  /** @type {User[]} */
  @alias('restaurant.admins')
  admins;

  @action
  async removeUserFromRestaurant(user) {
    this.restaurant.admins.removeObject(user);

    try {
      await this.restaurant.save();
      await user.save();
      this.notify.success(`Successfully removed user from this restaurant`);
    } catch (_err) {
      this.notify.error(`Unable to remove the user from this restaurant`);
    }
  }

  @action
  setNewOwner(user) {
    const notify = this.get('notify');
    const userName = user.get('fullName');

    if (!user.get('active')) {
      return notify.error("Can't set an inactive user as the owner");
    }

    this.set('restaurant.owner', user);
    this.get('restaurant')
      .save()
      .then((_) => notify.success(`Successfully set ${userName} as the new owner`))
      .catch((_) => notify.error(`Unable to set ${userName} as the owner`));
  }
}
