import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('fde-lists-sortable-list-list-item')
@classNameBindings('isSelected:is-selected', 'isSelectable:is-selectable')
export default class ListItem extends Component {
  /** @type {boolean} */
  readonly = false;

  /** @type {boolean} whether or not the current list item is selectable */
  isSelectable = false;

  /** @type {*} The currently selected item */
  selected = null;

  /** @type {boolean} whether or not the current list item is selected */
  @computed('selected', 'item')
  get isSelected() {
    return this.get('selected') === this.get('item');
  }

  /** @type {*} The item which this list item is backed by */
  item = null;

  /**
   * Callback for when this item is selected
   *
   * @param _item
   */
  onItemSelect(_item) {}

  click() {
    this.onItemSelect(this.get('item'));
  }
}
