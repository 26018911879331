import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNameBindings('isContainer:container')
@classNames('ui padded fde-feature-view')
export default class FeatureView extends Component {
  /** @type {boolean} */
  hasControls = true;

  /** @type {string} route to navigate back to */
  backRoute = null;

  /** @type {string} label for the back text */
  backText = null;

  /** @type {boolean} Whether or not to render column for header */
  hasHeader = true;

  /** @type {?Function} */
  onSearchChange = null;

  /** @type {?Object} */
  filterByOptions = null;

  /** @type {?Object} */
  filterBySelections = null;

  /** @type {?string} */
  newLinkTo = null;

  /** @type {?string} */
  newToolTip = null;

  /** @type {?string} */
  newText = null;
}
