import config from 'star-fox/config/environment';
import moment from 'moment-timezone';

export function initialize(appInstance) {
  if (config.environment === 'development' || config.isStaging) {
    let pusherService = appInstance.lookup('service:pusher');
    const superSetup = pusherService.setup;
    pusherService.setup = function () {
      superSetup.apply(pusherService, arguments);
      overridePusherSubscribe(pusherService.pusher);
    };
  }
}

export function overridePusherSubscribe(pusher) {
  const superSubscribe = pusher.subscribe;
  const superUnsubscribe = pusher.unsubscribe;

  /**
   * Extracts the pusher channel for development / staging namespaces
   *
   * @param {string} channel
   * @returns {string}
   */
  function extractChannelName(channel) {
    let actualChannel;

    // pusher users private and presence prefixes to trigger authentication
    // in order to make this actually work in dev / staging we need to rewire
    // those channel names to still have our dev / staging namespace AND the prefix
    if (channel.startsWith('private-') || channel.startsWith('presence-')) {
      actualChannel = channel.replace(/(private|presence)-/, `$1-${config.pusherPrefix}-`);
    } else {
      actualChannel = `${config.pusherPrefix}-${channel}`;
    }
    return actualChannel;
  }

  pusher.subscribe = function (channel) {
    const channelName = extractChannelName(channel);

    console.debug(`${moment().format('hh:mm:ss ')} [pusher] subscribe ${channelName}`);

    return superSubscribe.call(pusher, channelName);
  };

  pusher.unsubscribe = function (channel) {
    const channelName = extractChannelName(channel);

    console.debug(`${moment().format('hh:mm:ss ')} [pusher] unsubscribe ${channelName}`);

    return superUnsubscribe.call(pusher, channelName);
  };
}

export default {
  name: 'ember-pusher-namespace-patch',
  initialize: initialize
};
