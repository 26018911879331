import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import Chart from 'chart.js/auto';

@classic
@classNames('restaurants-ranking-card ui card')
export default class RankingCard extends Component {
  title = null;

  /** @type {String} */
  numeratorKey = '';

  /** @type {String} */
  denominatorKey = '';

  /** @type {String} */
  percentageKey = '';

  /** @type {String} */
  areaRankKey = '';

  /** @type {String} */
  platformRankKey = '';

  /** @type {String} */
  areaPercentageRankKey = '';

  /** @type {RestaurantRanking[]} */
  rankings = null;

  didInsertElement() {
    super.didInsertElement();
    const ele = this.$('.chart canvas')[0];

    this.chart = new Chart(ele, {
      type: 'line',
      data: this.get('data'),
      options: this.get('options')
    });
  }

  /** @type {RestaurantRanking} */
  @computed('rankings.[]')
  get currentRanking() {
    return this.get('rankings.lastObject');
  }

  /** @type {RestaurantRanking} */
  @computed('rankings.[]')
  get lastRanking() {
    return this.get('rankings').slice(-2, -1)[0];
  }

  /** @type {boolean} */
  @computed('currentRanking', 'lastRanking')
  get hasTrend() {
    const currentRanking = this.get(`currentRanking.${this.get('areaRankKey')}`);
    const lastRanking = this.get(`lastRanking.${this.get('areaRankKey')}`);

    return currentRanking && lastRanking && currentRanking !== lastRanking;
  }

  /** @type {String} */
  @computed('currentRanking', 'lastRanking')
  get trendIcon() {
    const currentRanking = this.get(`currentRanking.${this.get('areaRankKey')}`);
    const lastRanking = this.get(`lastRanking.${this.get('areaRankKey')}`);

    return currentRanking < lastRanking ? 'up arrow' : 'down arrow';
  }

  /** @type {String} */
  @computed('currentRanking', 'lastRanking')
  get trendColor() {
    const currentRanking = this.get(`currentRanking.${this.get('areaRankKey')}`);
    const lastRanking = this.get(`lastRanking.${this.get('areaRankKey')}`);

    // small numbers are better here
    return currentRanking < lastRanking ? 'green' : 'red';
  }

  /** @type {String} */
  @computed('currentRanking')
  get rankEmoji() {
    const currentRanking = this.get(`currentRanking.${this.get('areaPercentageRankKey')}`);

    if (currentRanking <= 10) {
      return '🏆';
    } else if (currentRanking <= 40) {
      return '🥇';
    } else if (currentRanking <= 70) {
      return '🥈';
    } else {
      return '🥉';
    }
  }

  /** @type {CharJsData} */
  @computed('rankings.[]')
  get data() {
    let data = this.get('rankings').map((ranking) => ranking.get(this.get('areaRankKey')));
    return {
      labels: this.get('rankings').mapBy('month'),
      datasets: [
        {
          data: data,
          lineTension: 0,
          fill: 'start'
        }
      ]
    };
  }

  /** @type {CharJsOptions} */
  @computed('rankings.[]')
  get options() {
    return {
      responsive: true,
      legend: {
        display: false
      },
      tooltips: {
        display: true
      },
      scales: {
        yAxes: {
          display: false,
          ticks: {
            reverse: true,
            min: 0,
            max: Math.max(
              ...this.get('rankings').map((ranking) => ranking.get('areaRestaurantCount'))
            )
          }
        },
        xAxes: {
          display: false
        }
      }
    };
  }
}
