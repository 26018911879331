import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import $ from 'jquery';
import { action } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('fde-color-control')
export default class ColorControl extends Component {
  /** @type {String[]} */
  colors = [
    'red',
    'orange',
    'yellow',
    'olive',
    'green',
    'blue',
    'teal',
    'violet',
    'pink',
    'brown',
    'purple'
  ];

  value = null;
  readonly = false;
  $input = null;

  _rgbaToHex(color) {
    const RGB_REGEX = /(\d+), (\d+), (\d+)/;
    // stupid jquery only returns a string, so we match it out with a regex
    const match = color.match(RGB_REGEX);

    // destructuring doesn't quite work
    const r = match[1];
    const g = match[2];
    const b = match[3];

    const hexRgb = [r, g, b].reduce((acc, color) => {
      color = parseInt(color, 10);
      color = color.toString(16);

      if (color.length === 1) {
        color = '0' + color;
      }

      return acc + color;
    }, '');

    return `#${hexRgb.toUpperCase()}`;
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    const $input = this.$('.ui.input input');
    const value = this.get('value');

    this.set('$input', $input);

    if (value) {
      this._setColor(value);
    }
  }

  /**
   * @param {string} color
   * @private
   */
  _setColor(color) {
    this.get('$input').css('background-color', color).css('color', 'white');
  }

  _clearColor() {
    this.get('$input').css('background-color', 'white').css('color', 'black');
  }

  onChange() {}

  @action
  setColor(e) {
    let $target = $(e.target);

    // If you hit the paint brush in the middle of the button.
    if ($target.hasClass('paint')) {
      $target = $target.parent();
    }
    const color = $target.css('background-color');
    const hexValue = this._rgbaToHex(color);
    this._setColor(hexValue);
    this.onChange(hexValue);
  }

  @action
  handleHexChange(hexValue) {
    const hasValidHex = hexValue && hexValue.length === 7;

    if (hasValidHex) {
      this._setColor(hexValue);
      this.onChange(hexValue.toUpperCase());
    } else {
      this._clearColor();
    }
  }
}
