import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import config from 'star-fox/config/environment';
import { inject as service } from '@ember/service';
import { alias, and } from '@ember/object/computed';
import { inject as controller } from '@ember/controller';
import { action, computed } from '@ember/object';
import isSameAs from 'star-fox/utils/is-same-as';
import { tracked } from '@glimmer/tracking';
import { ORDER_EDIT_INCLUDE } from './route';
import moment from 'moment-timezone';

@classic
export default class EditController extends Controller {
  /** @override */
  pusherModelQueryParams = {
    EmailMessage: {
      include: 'order'
    },
    Order: {
      include: ORDER_EDIT_INCLUDE
    },
    OrderItem: {
      include: 'order.restaurant,group-order-member,menu-option-items'
    },
    GroupOrderMember: {
      include: 'user,order.invoice,invoice,user,order-items.menu-option-items'
    }
  };

  config = config;

  queryParams = ['editMealEvent'];

  @tracked editMealEvent = false;

  /** @type {Modal} */
  @service
  modals;

  /** @type {Controller} */
  @controller('application')
  applicationController;

  /** @type {Controller} */
  @controller('logged-in.orders')
  ordersController;

  /** @type {string} displaying areas when in split pane view */
  @alias('ordersController.areaNames')
  areaNames;

  /** @type {Service} */
  @service
  notify;

  /** @type {Order} Order model */
  @alias('model.order')
  order;

  @alias('order.client')
  client;

  /** @type {Order.[]} Orders */
  @alias('model.orders')
  orders;

  /** @type {string} Order state */
  @alias('order.state')
  state;

  /** @type {boolean} */
  isLoading = false;

  /** @type {boolean} loading boolean if duplicating */
  isDuplicating = false;

  /** @type {boolean} If order is flagged or not */
  @alias('order.flag')
  flag;

  /** @type {Restaurant} */
  originalRestaurant = null;

  /** @type {boolean} */
  @isSameAs('order.restaurant.id', 'originalRestaurant.id')
  hasSameRestaurant;

  /** @type {?Object} */
  @computed('order.state.isDraft')
  get orderValidationOptions() {
    return this.get('order.state.isDraft') ? { only: ['area'] } : {};
  }

  /** @type {string} */
  @computed('ordersController.displayDate', 'ordersController.sameDayAs', 'order.deliverAt')
  get displayDate() {
    const orderDisplayDate = moment
      .tz(this.get('order.deliverAt'), this.get('order.area.isoTimeZone'))
      .format('dddd, MMMM Do');
    return this.get('ordersController.sameDayAs')
      ? orderDisplayDate
      : this.get('ordersController.displayDate');
  }

  /**
   * @type {string} the name of the channel that we use for presence,
   * defaults to ee.food.orders.{id}.model-events
   */
  @computed('order')
  get pusherChannels() {
    const orderId = this.get('order.id');

    return [`ee.food.orders.${orderId}.model-events`];
  }

  @computed('session.user')
  get useNewPubSub() {
    return this.session.user.hasFeatureEnabled('newPubSub');
  }

  /** @type {string} Order state in human-readable format: no _ and upper case */
  @computed('state')
  get actionButtonLabel() {
    return this.get('state').toUpperCase().replace('_', ' ');
  }

  /** @type {string} Order state is associated with representative color */
  @computed('state')
  get actionButtonColor() {
    return this.get('state');
  }

  /** @type {boolean} */
  @and('order.restaurant.id', 'order.restaurantLocation')
  canEditCart;

  /**
   * @function removes all order items, and deletes them.
   */
  _emptyOrderCart() {
    this.get('order.orderItems').forEach((order) => order.destroyRecord());
  }

  /**
   * Opportunity to validate order based on state event change.
   * @param {OrderStateEvent} stateEvent
   */
  _validateStateEvent(stateEvent) {
    if (stateEvent.isPublish) {
      return this.get('order').validate();
    }
    return true;
  }

  /**
   * @param {boolean} whether or not the duplicate the order items or just the details
   */
  @action
  duplicateOrder(e, duplicateOrderItems = false, keepMealEvent = false) {
    this.set('isDuplicating', true);

    this.get('order')
      .duplicate(duplicateOrderItems, keepMealEvent)
      .then((order) => {
        // command click
        if (e.metaKey) {
          window.open(`/orders/${order.get('id')}`, '_blank');
        } else {
          // TODO: This isn't exactly right, needs the current path.
          this.transitionToRoute(this.orderEditRoute, order.get('id'));
        }
      })
      .catch((response) => this._displayError(response.errors, null))
      .finally(() => this.set('isDuplicating', false));
  }

  /**
   * When a payment card is removed, it stays attached to all the Orders it's been used on
   * This removes it on active Orders so that admins get prompted to add a new one.
   */
  removeDeadPaymentCard() {
    const order = this.get('order');

    if (order.get('paymentCard.removed') && !order.get('state.isClosed')) {
      order.set('paymentCard', null);
    }
  }

  /**
   * Displays errors from the API in toasters, with some custom messages in specific cases.
   * @param  {Array} errors the errors returned by the API
   */
  _displayError(errors, closeAfter = 10000) {
    const order = this.get('order');
    this.get('notify').error(`Unable to save Order #${order.get('identifier')}`, { closeAfter });

    (errors || []).forEach((error) => {
      let message = error.detail || (error.message && error.message.join());

      if (message.match(/restaurant-capacity/)) {
        message = order.get('isGroupOrder')
          ? `${message} — number of people`
          : `${message} — too many order items`;
      }

      this.get('notify').error(message, { closeAfter });
    });
  }

  @action
  setStateEvent(stateEvent) {
    const order = this.order;

    if (!this._validateStateEvent(stateEvent)) {
      this._displayError(order.errors);
      return;
    }

    order.stateEvent = stateEvent;

    return order
      .save()
      .then((_) => this.notify.success(`Succesfully set the order to "${stateEvent}"`))
      .catch((response) => this._displayError(response.errors))
      .finally((_) => (order.stateEvent = null));
  }

  @action
  flagOrder() {
    const order = this.get('order');
    order.toggleProperty('flag');
    order.save();
  }

  @action
  handleDidFail(errorObject) {
    this._displayError(errorObject.errors);
  }

  @action
  handleDidSave() {
    this.get('notify').success(`Saved Order #${this.get('order.identifier')}`);

    if (!this.get('hasSameRestaurant')) {
      this._emptyOrderCart();
      this.set('originalRestaurant', this.get('order.restaurant'));
    }
  }

  @action
  duplicateOrderAndItems(e) {
    this.duplicateOrder(e, true, false);
  }

  @action
  duplicateOrderKeepMealEvent(e) {
    this.duplicateOrder(e, false, true);
  }

  @action
  duplicateOrderAndItemsKeepMealEvent(e) {
    this.duplicateOrder(e, true, true);
  }

  @action
  reprocessInvoice() {
    return this.get('order').reprocessInvoice();
  }

  @action
  processPayment() {
    return this.get('order').processPayment();
  }

  @action
  handleCapacityLimitOverrideRequestClick() {
    this.get('order')
      .requestCapacityOverride()
      .then((_) => this.get('notify').success('Request sent!'));
  }

  @action
  async updateOrders() {
    await this.get('order.event.content').updateOrders();
    await this.send('refreshModel');
  }

  @action
  handleEventDeliverAtTimeChange() {
    const event = this.get('order.event.content');
    const orderTemplate = this.get('order.event.orderTemplate.content');
    const deliverAt = moment(event.get('deliverAt'));
    const deadlineAt = moment(orderTemplate.get('deadlineAt'));

    if (deadlineAt.isAfter(deliverAt)) {
      orderTemplate.set('deadlineAt', deliverAt.subtract(1, 'hours').toDate());
    }
  }

  @action
  handleMealEventConfirmSubmit() {
    return window.confirm(
      'Are you sure you want to update the meal event? This will override each order with all the settings you see here.'
    );
  }

  @tracked showCreateRedeliveryOrderModal;

  @action
  async createAddonOrder() {
    const orderId = await this.modals.open(
      'sf/orders/modals/create-addon-order',
      { order: this.order },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false
        }
      }
    );

    if (orderId) {
      this.transitionToRoute(this.orderEditRoute, orderId);
    }
  }
}
