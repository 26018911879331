import classic from 'ember-classic-decorator';
import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class MealPlanningInstanceRoute extends Route.extend(CapabilityRouteMixin) {
  queryParams = {
    pageLimit: { refreshModel: true },
    sort: { refreshModel: true }
  };

  /** @type {Number} */
  pageLimit = 20;

  /** @type {String} */
  capability = 'CLIENT_MEALPLAN_SHOW';

  /**
   * @param {Object} params
   *
   * @returns {Promise} Client and mealPlanningInstances
   */
  model(_params) {
    const client = this.modelFor('logged-in.clients.edit');

    return RSVP.hash({
      mealPlanningInstances: client.query('mealPlanningInstances', {
        page: {
          limit: this.get('pageLimit')
        },

        sort: 'startOfWeek'
      })
    });
  }
}
