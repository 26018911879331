import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class NewRoute extends Route {
  async model() {
    return this.store.createRecord('subscription-plan', { isCustom: true });
  }

  afterModel(model) {
    const parentController = this.controllerFor('logged-in.admins.subscription-plans');
    parentController.set('selectedValues', [model]);
  }
}
