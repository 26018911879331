import classic from 'ember-classic-decorator';
import { alias, equal } from '@ember/object/computed';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';
import { computed, get } from '@ember/object';
import { modelAction } from 'ember-custom-actions';

@classic
export default class AccountingLedgerItem extends Model {
  @attr('date')
  updatedAt;

  @attr('date')
  createdAt;

  @attr('date')
  issueDate;

  @attr('string')
  identifier;

  @attr('string')
  status;

  @attr('string')
  currency;

  @attr('number')
  netAmount;

  @attr('number')
  taxAmount;

  @attr('number')
  totalAmount;

  @attr('string')
  description;

  @attr('date')
  dueDate;

  @attr('string')
  xeroId;

  @attr('string')
  ledgerType;

  @attr('boolean')
  canRefund;

  @attr('number')
  refundableAmount;

  @attr('array')
  taxTotals;

  @attr('object')
  aggregatedLedger;

  @attr('string')
  chargeId;

  @attr('string')
  acctSeedBillingId;

  @attr('string')
  acctSeedPayableId;

  @attr('string')
  acctSeedCashReceiptId;

  @attr('boolean')
  hasAccountingSeedsIssues;

  @attr('string')
  acctSeedBillingCashReceiptId;

  log = null;

  @hasMany('accounting-line-item', { inverse: 'ledgerItem' })
  lineItems;

  @hasMany('accounting-ledger-item')
  children;

  @hasMany('integration-log-record')
  logRecords;

  @belongsTo('accounting-consolidation-configuration')
  run;

  @alias('aggregatedLedger.fees')
  fees;

  @alias('aggregatedLedger.discounts')
  discounts;

  @alias('aggregatedLedger.payments')
  payments;

  @computed('ledgerType')
  get isInvoice() {
    return ['Accounting::Invoice', 'Accounting::ConsolidatedInvoice'].includes(
      this.get('ledgerType')
    );
  }

  @computed('ledgerType')
  get isBilling() {
    return ['Accounting::Bill', 'Accounting::ConsolidatedBill'].includes(this.get('ledgerType'));
  }

  @computed('ledgerType', 'identifier')
  get title() {
    return `${this.get('typeName')}: ${this.get('identifier')}`;
  }

  @computed('ledgerType')
  get typeName() {
    return `${(get(this, 'ledgerType') ?? 'Accounting::Invoice').split('::')[1]}`;
  }

  @computed('ledgerType', 'identifier')
  get readonly() {
    return (
      this.get('isPayment') ||
      this.get('isRefund') ||
      this.get('isCreditNote') ||
      this.get('isBill') ||
      this.get('status') === 'closed'
    );
  }

  @computed('isInvoice', 'status')
  get canCharge() {
    return this.get('isInvoice') && this.get('status') === 'closed';
  }

  @computed('isInvoice', 'status')
  get canClose() {
    return this.get('isInvoice') && this.get('status') !== 'closed';
  }

  @equal('ledgerType', 'Accounting::Payment')
  isPayment;

  @equal('ledgerType', 'Accounting::Refund')
  isRefund;

  @equal('ledgerType', 'Accounting::CreditNode')
  isCreditNote;

  @equal('ledgerType', 'Accounting::Bill')
  isBill;

  sync = modelAction('sync', { method: 'POST' });
  charge = modelAction('charge', { method: 'POST' });
  refund = modelAction('refund', { method: 'POST' });
  close = modelAction('close', { method: 'POST' });

  reload(include = '') {
    return this.store.findRecord('accounting-ledger-item', this.get('id'), {
      reload: true,
      include: include
    });
  }

  validations = {
    xeroId: {
      format: {
        with: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
        allowBlank: true,
        message: 'must be a valid uuid'
      }
    }
  };
}
