import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class Contact extends Model {
  /*
   * Attributes
   */
  @attr('string')
  firstName;

  @attr('string')
  lastName;

  @attr('string')
  email;

  @attr('phone-number')
  phoneNumber;

  @attr('string')
  extension;

  @attr('phone-number')
  smsNumber;

  /*
   * Relationships
   */
  // user serialize is set to false in contact serializer.
  @belongsTo('user')
  user;

  /*
   * Computed Properties
   */
  /** @type {string} */
  @computed('firstName', 'lastName')
  get name() {
    return `${this.get('firstName')} ${this.get('lastName')}`;
  }

  /** @type {string} */
  @alias('id')
  contactId;

  /** @type {string} */
  @computed('name', 'email', 'phoneNumber', 'extension')
  get humanize() {
    return [this.get('name'), this.get('email'), this.get('phoneNumber'), this.get('extension')]
      .filter((_) => !!_)
      .join(' – ');
  }

  get asFragment() {
    return this.store.createFragment('fragments/contact', this.toJSON());
  }

  /*
   * Validations
   */
  validations = {
    phoneNumber: {
      custom: {
        validation: function (key, value, _model) {
          if (value && value.match(/999.*999.*999/)) {
            return true;
          }

          return intlTelInputUtils.isValidNumber(value);
        },
        message: 'Please provide a valid phone number'
      }
    },

    firstName: { presence: true },
    lastName: { presence: true },
    email: { presence: true }
  };
}
