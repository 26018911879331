import classic from 'ember-classic-decorator';
import AdminsRulesRoute from 'star-fox/features/logged-in/admins/rules/route';

@classic
export default class RulesRoute extends AdminsRulesRoute {
  templateName = 'logged-in.admins.rules';

  get owner() {
    return this.modelFor('logged-in.clients.edit');
  }
}
