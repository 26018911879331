import classic from 'ember-classic-decorator';
import Helper from '@ember/component/helper';
import { set } from '@ember/object';

@classic
export default class CopyMut extends Helper {
  compute([property, context]) {
    return function (value) {
      set(context, property, value.copy());
    };
  }
}
