import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

/**
 * Reusable component for building a reorderable table with a create form
 */
@classic
@classNames('fde-reorderable-table')
export default class ReorderableTable extends Component {
  /** @type {boolean} */
  readonly = false;

  /** @type {boolean} whether or not the form is showing */
  showForm = false;

  /** @type {DS.Model} the model which is the source of the collection */
  model = null;

  /** @type {string} the key which points to the collection on the model */
  collectionKey = '';

  /** @type {string} Title rendered above the table */
  title = '';
}
