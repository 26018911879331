import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('ui grid fde-invoices-order-item-option-row')
export default class OrderItemOptionRow extends Component {
  /** @type {Object} Line item */
  orderItemOption = null;

  /** @type {string} notes on an order item */
  notes = null;

  /** @type {boolean} whether or not the notes are custom notes */
  isCustomNote = false;
}
