import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias, not, notEmpty } from '@ember/object/computed';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';

import { htmlSafe } from '@ember/template';
import { isBlank } from '@ember/utils';
import { TYPES } from '../features/components/sf/forms/requirement-form-for/constraints-form-for/component';
import { modelAction } from 'ember-custom-actions';
import { task } from 'ember-concurrency';

@classic
export default class MealPlanningRequirement extends Model {
  /*
   * Attributes
   */
  @attr('string')
  name;

  @attr('number', { defaultValue: 1 })
  numberOfOptions;

  @attr('dollars')
  maxPrice;

  @attr('dollars')
  minPrice;

  @belongsTo('meal-planning-preference-profile')
  preferenceProfile;

  @belongsTo('meal-planning-requirement-group', { inverse: 'requirements' })
  requirementGroup;

  @belongsTo('meal-planning-restaurant-constraint')
  restaurantConstraint;

  @belongsTo('order')
  order;

  @belongsTo('requireable', { polymorphic: true })
  parent;

  @hasMany('meal-planning-requirement-constraint')
  requirementConstraints;

  @hasMany('tag')
  tags;

  @attr('boolean')
  active;

  @attr('string')
  label;

  @attr('string')
  imageUrl;

  @attr('boolean')
  topCuisine;

  @hasMany('area', { inverse: 'mealPlanningRequirements' })
  areas;

  query = null;

  /*
   * Computed Properties
   */

  get cuisineTags() {
    return this.tags.rejectBy('tagType', 'diversity');
  }

  set cuisineTags(cuisineTags) {
    // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts
    this.tags = cuisineTags.concat(this.diversityTags);
  }

  get diversityTags() {
    return this.tags.filterBy('tagType', 'diversity');
  }

  set diversityTags(diversityTags) {
    // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts
    this.tags = diversityTags.concat(this.cuisineTags);
  }

  @alias('requirementGroup.menuItems')
  menuItems;

  @alias('name')
  humanize;

  @attr('object')
  areaRestaurantCounts;

  @notEmpty('tags')
  hasTags;

  @not('isValid')
  isNotValid;

  @computed(
    'menuItems.[]',
    'useNormalizedPrice',
    'maxPrice',
    'requirementConstraints.@each.tagCollection',
    'requirementConstraints.@each.constraintType',
    'requirementConstraints.@each.isSaving'
  )
  get matchingMenuItems() {
    const menuItems = this.get('menuItems');
    return menuItems ? menuItems.filter((mi) => this.apply(mi)) : null;
  }

  @computed('matchingMenuItems.[]', 'priceKey')
  get averagePrice() {
    const priceKey = this.get('priceKey');
    const menuItems = this.get('matchingMenuItems');
    const length = this.get('matchingMenuItems.length');

    return length > 0 ? menuItems.reduce((acc, mi) => acc + mi.get(priceKey), 0) / length : 0;
  }

  @computed('requirementGroup.portionSize')
  get priceKey() {
    return this.get('requirementGroup.priceKey') || 'clientPriceCents';
  }

  @computed('areas.[]')
  get activeAreas() {
    return this.areas?.length || 0;
  }

  @computed(
    'requirementConstraints.@each.description',
    'query',
    'maxPrice',
    'minPrice',
    'numberOfOptions',
    'tags.[]'
  )
  get description() {
    const groupedConstraints = this.get('groupedConstraints');

    if (Object.keys(groupedConstraints).length === 0) {
      return '';
    }

    const dietaryDescription = (this.get('dietaryTagConstraints') || [])
      .map((_) => _.get('description'))
      .concat((this.get('allergyTagConstraints') || []).map((_) => _.get('description')))
      .join(' and ');

    const mealTypeDescription = (this.get('mealTypeConstraints') || [])
      .map((_) => _.get('description'))
      .join(' and ');
    const foodTypeDescription = (this.get('foodTypeConstraints') || [])
      .map((_) => _.get('description'))
      .join(' and ');

    const mealTypesAreAllEmpty = (this.get('mealTypeConstraints') || []).every((_) =>
      _.get('isEmpty')
    );

    let stringBuffer = [];

    const numberOfOptions = this.get('numberOfOptions');
    if (numberOfOptions > 1) {
      stringBuffer.push(`${numberOfOptions} choices of`);
    } else {
      stringBuffer.push(`${numberOfOptions} choice of`);
    }

    if (mealTypesAreAllEmpty) {
      stringBuffer.push(`any ${dietaryDescription} meal type`);
    } else {
      stringBuffer.push(dietaryDescription);
      stringBuffer.push(mealTypeDescription);
    }

    stringBuffer.push(foodTypeDescription);

    const maxPrice = this.get('maxPrice');
    const minPrice = this.get('minPrice');

    if (maxPrice && (!minPrice || minPrice === 0)) {
      stringBuffer.push(` under $${maxPrice}`);
    }

    if (maxPrice && minPrice) {
      stringBuffer.push(`between $${minPrice} and $${maxPrice}`);
    }

    if (this.get('hasTags')) {
      stringBuffer.push(`(tagged as ${this.get('tags').mapBy('name').join(', ')})`);
    }

    let query = this.get('query');
    if (query) {
      stringBuffer.push(`(matching: ${query})`);
    }

    return htmlSafe(stringBuffer.join(' '));
  }

  @computed('requirementConstraints.[]')
  get groupedConstraints() {
    return this.get('requirementConstraints').reduce((acc, c) => {
      const key = c.get('tagType');
      acc[key] = acc[key] || [];
      acc[key].push(c);

      return acc;
    }, {});
  }

  @computed('requirementConstraints.@each.includedTags', 'tags.[]')
  get includedTags() {
    return (
      this.get('tags')
        ?.map((tag) => tag.name)
        .filter((tagName) => !!tagName) ?? []
    )
      .concat(
        this.get('requirementConstraints')
          ?.map((c) => c.get('includedTags'))
          .filter((includedTags) => !!includedTags) ?? []
      )
      .join(', ');
  }

  @alias('groupedConstraints.MEAL')
  mealTypeConstraints;

  @alias('groupedConstraints.FOOD')
  foodTypeConstraints;

  @alias('groupedConstraints.DIETARY')
  dietaryTagConstraints;

  @alias('groupedConstraints.ALLERGY')
  allergyTagConstraints;

  @task(function* (area) {
    return yield this.search({ area: area.get('id') });
  })
  searchTask;

  /** @type {{restaurants: []}} */
  @alias('searchTask.lastSuccessful.value')
  searchResults;

  search = modelAction('search', {
    method: 'GET',
    pushToStore: false
  });

  async save() {
    // Top cuisine form requires children to be manually saved
    if (this.topCuisine) {
      await this.get('requirementConstraints').forEach(
        async (constraint) => await constraint.save()
      );
    }
    return super.save(...arguments);
  }

  reset() {
    this.setProperties({
      maxPrice: null,
      query: null,
      useNormalizedPrice: false,

      requirementConstraints: [
        this.store.createRecord('meal-planning-requirement-constraint', {
          tagType: 'MEAL',
          constraintType: 'ALL'
        }),
        this.store.createRecord('meal-planning-requirement-constraint', {
          tagType: 'FOOD',
          constraintType: 'ALL'
        }),
        this.store.createRecord('meal-planning-requirement-constraint', {
          tagType: 'DIETARY',
          constraintType: 'ALL'
        })
      ]
    });
  }

  /**
   * Takes a menu item, and returns true or false. Effectively seeing if the constraints of the requirements match the
   * menu item.
   * @return {boolean}
   */
  apply(menuItem) {
    const minPrice = this.get('minPrice') || 0;
    const maxPrice = this.get('maxPrice');
    const price = menuItem.get(this.get('priceKey'));
    // TODO figure out a better way to deal with this
    const matchesPrice = (minPrice * 100 <= price && price <= maxPrice * 100) || isBlank(maxPrice);

    const query = this.get('query');
    const queryRegExp = new RegExp(query, 'ig');

    const queryMatches =
      (menuItem.get('name') || '').match(queryRegExp) ||
      (menuItem.get('description') || '').match(queryRegExp) ||
      (menuItem.get('menuGroup.name') || '').match(queryRegExp) ||
      (menuItem.get('menuGroup.description') || '').match(queryRegExp) ||
      isBlank(query);

    const allConstraintsApply = this.get('requirementConstraints').every((_) => _.apply(menuItem));

    return (
      menuItem.get('active') &&
      menuItem.get('menuGroup.active') &&
      matchesPrice &&
      queryMatches &&
      allConstraintsApply
    );
  }

  /**
   * This is to perform a query on the restaurant tag search endpoint
   * @return {object}
   */
  toQuery(
    options = { area: null, client: null, deliverAt: null, restaurant: null, locationId: null }
  ) {
    const minPrice = this.get('minPrice');
    const maxPrice = this.get('maxPrice');

    const ret = {
      query: isBlank(this.query) ? '*' : this.query,

      area: options.area ? options.area.get('id') : null,
      deliverAt: options.deliverAt ? options.deliverAt : null,

      clientId: options.client ? options.client.get('id') : null,
      restaurantId: options.restaurant ? options.restaurant : null,
      // this.canAsapOrder and this.canGroupOrder are not persistent fields but
      // temporary fields set from restaurant select control to handle serach
      canAsapOrder: this.canAsapOrder ? true : null,
      canTeamOrder: this.canTeamOrder ? true : null,

      locationId: options.locationId ? options.locationId : null,

      // Shouldn't have to multiple by 100, would prefer to use the wire format
      // but cannot currently
      max_price: !isBlank(maxPrice) ? maxPrice * 100 : null,
      min_price: !isBlank(minPrice) ? minPrice * 100 : null,

      numberOfOptions: this.numberOfOptions ? this.numberOfOptions : null,

      mealTypes: this.toQueryConstraintByTagType('MEAL'),
      foodTypes: this.toQueryConstraintByTagType('FOOD'),
      dietaryTags: this.toQueryConstraintByTagType('DIETARY')
    };

    return Object.entries(ret)
      .filter(([_, v]) => !isBlank(v))
      .reduce((obj, [k, v]) => {
        obj[k] = v;

        return obj;
      }, {});
  }

  toQueryConstraintByTagType(tagType) {
    const constraints = this.get('requirementConstraints');
    const constraintsByTagType = constraints.filter((_) => _.get('tagType') === tagType);

    return constraintsByTagType.reduce((acc, c) => {
      let type = c.get('constraintType');

      if (type === TYPES.ANY) {
        type = 'in';
      }

      // collect same same
      acc[type.toLowerCase()] = (acc[type.toLowerCase()] || []).concat(c.toQuery()).toArray();

      return acc;
    }, {});
  }

  toSolverJSON() {
    const dietaryTagsConstraints = this.get('requirementConstraints').filter(
      (_) => _.get('tagType') === 'DIETARY'
    );
    const tags = dietaryTagsConstraints
      .mapBy('dietaryTags')
      .reduce((acc, tags) => tags.toArray().concat(acc), []);

    return {
      id: this.get('id'),
      number_of_options: this.get('numberOfOptions'),
      menu_items: this.get('matchingMenuItems').reduce(
        (acc, mi) => acc.concat(mi.buildOptionsForTags(tags)),
        []
      )
    };
  }

  validations = {
    numberOfOptions: {
      custom: {
        validation: function (key, value, model) {
          const matchingMenuItems = model.get('matchingMenuItems');
          if (matchingMenuItems) {
            return value <= matchingMenuItems.length;
          } else {
            return true;
          }
        },
        message: function (value, model) {
          return `The number of options must be less than the number of matching menu items (${model.get(
            'matchingMenuItems.length'
          )})`;
        }
      }
    },

    maxPrice: {
      custom: {
        validation(key, value, model) {
          const requirementGroup = model.get('requirementGroup');
          const requirements = requirementGroup.get('requirements');

          if (requirements && requirementGroup && value) {
            const budget = requirementGroup.get('budget');
            const total = requirements.reduce((acc, r) => (r.get('maxPrice') || 0) + acc, 0);

            return budget >= total;
          } else {
            return true;
          }
        },
        message(_key, _value, _model) {
          return `The sum of all menu item requirement max costs must be less than the per person budget.`;
        }
      }
    }
  };
}
