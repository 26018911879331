import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class DriverPayrollRun extends Model {
  /*
   * Attributes
   */
  @attr()
  amounts;

  @attr()
  approvals;

  /*
   * Relationships
   */
  @belongsTo('driver', { async: false })
  driver;

  @belongsTo('payroll-run', { async: false })
  payrollRun;

  /*
   * Computed Properties
   */
  @computed('amounts')
  get aggregates() {
    const amountsObject = this.get('amounts');

    if (amountsObject) {
      const amounts = Object.values(amountsObject);

      const { week1, week2 } = amounts.reduce(
        (acc, amount, index) => {
          const key = index < 7 ? 'week1' : 'week2';
          acc[key] += parseInt(amount);
          return acc;
        },
        { week1: 0, week2: 0 }
      );

      const total = week1 + week2;

      return {
        week1,
        week2,
        total
      };
    }

    return { week1: 0, week2: 0, total: 0 };
  }

  @computed('approvals')
  get isApproved() {
    const approvals = this.get('approvals');
    const amounts = this.get('amounts');

    return Object.keys(approvals).reduce(
      (areAllApproved, timestamp) =>
        areAllApproved && (approvals[timestamp] || !amounts[timestamp]),
      true
    );
  }
}
