import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr } from 'renard/models/foodee';

@classic
export default class DiscountCode extends Model {
  /*
   * Attributes
   */
  @attr('string')
  code;

  @attr('string')
  description;

  @attr('string')
  discountType;

  /** @type {Number} Numerical representation of discountCode. Promo Codes expects this value as an integer */
  @computed('code')
  get codeInteger() {
    return parseInt(this.get('code'), 10);
  }
}
