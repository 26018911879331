import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { PredicateTypeConversionMap } from '../../../../../../models/rules-predicate';

@classic
@tagName('')
@classNames('')
export default class PredicateConfigurator extends Component {
  @service
  store;

  @service
  notify;

  @computed
  get predicateTypes() {
    return Object.keys(PredicateTypeConversionMap);
  }

  predicate = null;
  rule = null;

  @action
  addPredicate() {
    this.get('store').createRecord('rules-predicate', { predicate: this.get('predicate') });
  }

  @action
  deletePredicate() {
    this.get('predicate')
      .destroyRecord()
      .then(() => this.set('predicate', null));
  }

  @action
  addFact() {
    this.get('store').createRecord('rules-fact', { predicate: this.get('predicate') });
  }
}
