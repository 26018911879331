import { helper as buildHelper } from '@ember/component/helper';

export function formatTeamOrderCounts([team = null, teamCounts = null]) {
  if (team && teamCounts) {
    const teamId = team.get('id');
    const teamCount = teamCounts[teamId];

    if (teamCount) {
      return `${teamCount['membersOrderedCount']}/${teamCount['totalMemberCount']}`;
    }
  }
}

export default buildHelper(formatTeamOrderCounts);
