import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@classNames('form-control fde-menu-item-description-control')
export default class MenuItemDescriptionControl extends Component {
  /** @type {boolean} whether or not the showInternalDescription button has been pressed */
  showInternalDescription = false;

  /** @type {boolean} Returns true if internalDescription exists, or if set to true. */
  @computed('showInternalDescription', 'value.internalDescription')
  get shouldShowInternalDescription() {
    return !!this.get('value.internalDescription') || this.get('showInternalDescription');
  }

  controlId = null;

  @action
  toggleShowInternalDescription() {
    this.toggleProperty('showInternalDescription');
  }

  @action
  handleChange(key, value) {
    this.onChange(Object.assign({}, this.get('value'), { [key]: value }));
  }
}
