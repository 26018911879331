import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class UsersRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'RESTAURANT_USERS_SHOW';

  model(_params) {
    const restaurant = this.modelFor('logged-in.restaurants.show').restaurant;

    return RSVP.hash({
      restaurant: restaurant,
      admins: restaurant.query('admins', {
        page: {
          limit: 80
        }
      })
    });
  }
}
