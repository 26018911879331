import classic from 'ember-classic-decorator';
import { oneWay } from '@ember/object/computed';
import { action } from '@ember/object';
import Controller from '@ember/controller';
import { run } from '@ember/runloop';

@classic
export default class IndexController extends Controller {
  /** @property {string} searchText - text for searching the back end with */
  searchText = '';

  /** @property {number} sort - sorting string */
  sort = 'name';

  /** @property {number} */
  @oneWay('model.meta.recordCount')
  recordCount;

  /** @property {number} pageLimit - records per page */
  pageLimit = 30;

  /** @property {number} pageOffset - current page */
  pageOffset = 0;

  queryParams = ['pageLimit', 'pageOffset', 'searchText', 'sort'];
  _searchText = '';

  doSearch() {
    this.setProperties({
      searchText: this.get('_searchText'),
      pageOffset: 0
    });
  }

  @action
  onSearchChange(searchText) {
    this.set('_searchText', searchText);

    // prevent multiple search
    run.debounce(this, this.doSearch, 400);
  }
}
