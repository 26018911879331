import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class IndexRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'RESTAURANT_SHOW';

  /**
   * @param {Object} params
   * @returns {{restaurant: Restaurant}}
   */
  model(_params) {
    return this.modelFor('logged-in.restaurants.show');
  }

  /**
   * Tags should not block entering the route
   *
   * @param controller
   * @param model
   */
  setupController(controller, model) {
    super.setupController(controller, model);

    this.store
      .findAll('tag') // My solution for now is to query all of the tags so we get simple search
      .then((tags) => controller.set('tags', tags));

    this.store
      .query('area', {
        page: {
          limit: 100,
          offset: 0
        }
      })
      .then((areas) => controller.set('areas', areas));

    const { restaurant } = model;

    controller.set('newPickupLocation', this.store.createRecord('pickup-location', { restaurant }));
    controller.set('newRestaurantBrand', this.store.createRecord('restaurants-brand'));
  }
}
