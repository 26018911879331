import EmberObject, { action, computed, set } from '@ember/object';
import { alias, oneWay } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import moment from 'moment-timezone';
import { OrderState } from 'star-fox/models/order';
import { camelize, capitalize } from '@ember/string';

export default Controller.extend({
  queryParams: ['pageLimit', 'pageOffset', 'sort', 'states', 'deliverOn', 'filterByDate'],

  /** @type {boolean} Whether or not to include deliver at in query params */
  filterByDate: false,

  applicationController: controller('application'),

  /** @type {Object} client */
  client: alias('model.client'),

  /** @type {Order[]} Orders that meet the requirements in the query params */
  orders: alias('model.orders'),

  /** @type {Order} order object */
  order: null,

  /** @type {number} records per page */
  pageLimit: 20,

  /** @type {number} of order records */
  recordCount: oneWay('model.orders.meta.recordCount'),

  /** @type {number} current page */
  pageOffset: 0,

  /** @type {string} sorting string */
  sort: '-pickupAt',

  /** @type {boolean} */
  isLoading: false,

  /** @type {string} filtering by states */
  states: '',

  /** @type {OrderState[]} selectable order states to filter orders by */
  orderStates: Object.keys(OrderState).map((key, index) => {
    const queryString = OrderState[key];
    return EmberObject.create({
      id: index + 1,
      label: capitalize(camelize(queryString))
        .replace(/([A-Z])/g, ' $1')
        .trim(),
      queryString: queryString
    });
  }),

  /** @type {string} displaying date when in split pane view */
  displayDate: computed('deliverOn', function () {
    return moment
      .tz(this.get('deliverOn'), this.get('order.area.isoTimeZone'))
      .format('dddd, MMMM Do');
  }),

  /** @type {string} formatted YYYY-MM-DD of the deliver at date */
  _today: null,

  /** @type {string} formatted YYYY-MM-DD of the deliver at date */
  deliverOn: null,

  /** @type {date} Start of the week of the selected date */
  startOfWeek: computed('deliverOn', function () {
    // isoweek instead of startOfWeek starts the week on a monday :)
    return moment(this.get('deliverOn')).startOf('isoweek');
  }),

  daysOfWeek: computed('startOfWeek', 'orders', function () {
    const startOfWeek = this.get('startOfWeek');
    const weekOrderDates = this.get('orders.meta.weekOrderDates');

    return new Array(7).fill('').map((_, i) => {
      const day = moment(startOfWeek).add(i, 'day').format();

      const dayKey = moment(day).format('YYYYMMDD');
      const dayMeta = weekOrderDates[dayKey] || 0;

      return EmberObject.create({
        date: moment(day),
        info: dayMeta
      });
    });
  }),

  init() {
    this._super(...arguments);

    this._today = moment(new Date()).format('YYYY-MM-DD');
    set(this, 'deliverOn', moment(new Date()).format('YYYY-MM-DD'));
  },

  selectDate: action(function (value) {
    this.set('deliverOn', moment(value).format('YYYY-MM-DD'));
  }),

  nextWeek: action(function () {
    const startOfWeek = this.get('startOfWeek');
    const value = moment(startOfWeek).add(7, 'days').toDate();

    this.set('deliverOn', moment(value).format('YYYY-MM-DD'));
  }),

  lastWeek: action(function () {
    const startOfWeek = this.get('startOfWeek');
    const value = moment(startOfWeek).subtract(1, 'days').toDate();

    this.set('deliverOn', moment(value).format('YYYY-MM-DD'));
  }),

  commitDate: action(function () {
    console.debug('Commited the date, huh?');
  }),

  changeWeek: action(function (newDate) {
    console.debug('Changing the week, huh?', newDate);
  }),

  setFilterByDate: action(function (bool) {
    this.set('filterByDate', bool);
  }),

  toggleFilterByDate: action(function (shouldFilterByDate) {
    const properties = {
      deliverOn: moment(new Date()).format('YYYY-MM-DD'),
      filterByDate: shouldFilterByDate
    };

    if (shouldFilterByDate) {
      properties.pageOffset = 0;
      properties.pageLimit = 20;
    }

    this.setProperties(properties);
  })
});
