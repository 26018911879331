import classic from 'ember-classic-decorator';
import JSONSerializer from '@ember-data/serializer/json';
import { dasherize } from '@ember/string';
import { underscore } from '@ember/string';
import { get } from '@ember/object';

@classic
export default class Fragment extends JSONSerializer {
  keyForAttribute(attr, _method) {
    return dasherize(attr);
  }

  /**
   * Ensure that all underscored fields are normalized to their dasherized version. Seems that Fragments has an issue
   * mapping underscored values, and prefers dasherized ones for ingestion. Value objects, defined as such,
   * on the backend force the JSONB columns to be underscored.
   * @param {string} typeClass
   * @param {object} hash
   * @returns {*}
   */
  normalize(typeClass, hash) {
    let fields = get(typeClass, 'fields');

    fields.forEach(function (type, field) {
      let underscoredField = underscore(field);

      if (field === underscoredField) {
        return;
      }

      // convert any underscored fields to dasherized fields
      if (hash[underscoredField]) {
        hash[dasherize(field)] = hash[underscoredField];
        delete hash[underscoredField];
      }
    });

    return super.normalize(...arguments);
  }
}
