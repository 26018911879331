import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed, get } from '@ember/object';

const DELIVERY_STATES_OR_AFTER = [
  'driver_confirmed',
  'driver_at_restaurant',
  'picked_up',
  'driver_at_client',
  'delivered',
  'ready_to_eat',
  'reported',
  'payment_processing',
  'payment_failed',
  'closed'
];

@classic
export default class DeliveryEstimate extends Component {
  /** @type {string} */
  order = null;

  /** @type {boolean} */
  live = false;

  /** @type {object} */
  @alias('order.deliveryEstimate')
  estimate;

  @computed('order')
  get beforeDelivery() {
    return !DELIVERY_STATES_OR_AFTER.includes(this.get('order.state'));
  }

  /** @type {string} */
  @computed('order', 'order.deliveryTimeInMinutes', 'estimate')
  get warning() {
    const totalTimeRounded = this.get('estimate.totalTimeRounded');
    const deliveryTime = this.get('order.deliveryTimeInMinutes');
    const shouldWarn = totalTimeRounded > deliveryTime;

    return shouldWarn
      ? `Estimate of ${totalTimeRounded} min is greater than then scheduled ${deliveryTime} min`
      : null;
  }

  /** @type {string} */
  @computed
  get tooltip() {
    const estimate = this.get('estimate');
    const ret = [];

    ret.push(`Client P&L: ${get(estimate, 'clientParkingAndLoading')} mins`);
    ret.push(`Restaurant P&L: ${get(estimate, 'restaurantParkingAndLoading')} mins`);

    const drivingTime = get(estimate, 'travelEstimate.durationInTraffic.text');
    if (drivingTime) {
      const distance = get(estimate, 'travelEstimate.distance.text');
      ret.push(`DT (${distance}): ${drivingTime}`);
    }

    if (get(estimate, 'largeOrder')) {
      ret.push(`LO: ${get(estimate, 'largeOrderLoadTime')} mins`);
    }

    return ret.join(' ');
  }

  @action
  reload() {
    return this.order.arrivalEstimate.reload();
  }
}
