import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { camelizeObject } from 'renard/transforms/object';
import $ from 'jquery';

@classic
export default class FastForwardWidget extends Component {
  didInsertElement() {
    super.didInsertElement(...arguments);
    $('.fde-fast-forward-widget [data-html]').popup({ on: 'hover' });
  }

  /** @type {Menu} the currently active menu for the restaurant */
  activeMenu = null;

  /** @type {Object} the diff for this order if the new menu is used */
  diff = null;

  /** @type {Boolean} */
  isLoading = false;

  /** @type {Boolean} */
  @computed('orderMenu', 'activeMenu')
  get canFastForward() {
    return this.get('order').checkFastForward();
  }

  /** @type {Boolean} indicates if the order needs fast-forward */
  @computed('orderMenu', 'activeMenu')
  get needsFastForward() {
    return this.get('orderMenu.id') !== this.get('activeMenu.id');
  }

  /** @type {Order} the current order */
  order = null;

  /** @type {Menu} the current order menu */
  orderMenu = null;

  /** @type {Function} function that refreshes all the model keys needed for the page */
  refreshModel() {}

  /**
   * Computed properties
   */

  /** @type {Boolean} false if order is a draft */
  @computed('order.state')
  get orderPublished() {
    return this.get('order.state') !== 'draft' && this.get('order.state') !== 'pre_quote';
  }

  @action
  openConfirmationModal() {
    this.set('isLoading', true);

    this.get('order')
      .checkFastForward()
      .then((diff) => {
        this.set('diff', camelizeObject(diff));
        $('#fde-orders-fast-forward-modal').modal('show');
      })
      .finally(() => this.set('isLoading', false));
  }
}
