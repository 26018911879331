import classic from 'ember-classic-decorator';
import { alias, oneWay } from '@ember/object/computed';
import { attr, belongsTo, hasMany } from 'renard/models/foodee';
import RSVP from 'rsvp';
import { computed, get } from '@ember/object';
import RestaurantValidator from 'star-fox/models/validators/restaurant';
import Owner from './owner';
import { dasherize } from '@ember/string';
import { fragment } from 'ember-data-model-fragments/attributes';
import moment from 'moment-timezone';
import { modelAction, resourceAction } from 'ember-custom-actions';
import { Time } from 'renard/transforms/time';
import { getArcticFoxUrl, getSwiftFoxUrl } from 'renard/utils/url';
import ENV from 'star-fox/config/environment';

function serviceTimesForDayComputed(day) {
  return computed('serviceTimes.[]', function () {
    return this.serviceTimesForDay(day);
  });
}

@classic
export default class Restaurant extends Owner.extend(RestaurantValidator) {
  /*
   * Attributes
   */
  @attr('boolean')
  active;

  @attr('boolean')
  internal;

  @attr('boolean')
  isExclusive;

  @attr('boolean')
  isNewRestaurant;

  @attr('distance')
  distance;

  @attr('boolean')
  comingSoon;

  @attr('object')
  features;

  @fragment('fragments/accounting/charge')
  adminFee;

  @fragment('fragments/accounting/charge')
  waivedAdminFee;

  @attr('boolean')
  receivesDailyOrderSummary;

  @attr('boolean')
  receivesSms;

  @attr('string')
  legalName;

  @attr('string')
  name;

  @alias('name')
  label;

  @attr('string')
  subtitle;

  @attr('string')
  enterpriseRequest;

  @attr('string')
  pickupNotes;

  @attr('string')
  marketingTitle;

  @attr('string')
  marketingDescription;

  @attr('object')
  completionProgress;

  @attr('string')
  coverImageUrl;

  @attr('string')
  thumbnailImageUrl;

  @attr('string')
  slug;

  @attr('object')
  dietaryTagsCounts;

  @attr('number')
  restaurantStoryId;

  @attr('boolean')
  canTeamOrder;

  @attr('boolean')
  canAsapOrder;

  // deprecated
  @attr('boolean')
  canAsapTeamOrder;

  @attr('number')
  minimumOrder;

  @attr('number')
  minimumOrderInDollars;

  @attr('boolean')
  canFoodeePlusTeamOrder;

  @attr('boolean')
  canSameDayFoodeePlusTeamOrder;

  @attr('number')
  teamOrderLeadTime;

  @attr('number', { defaultValue: 16 })
  leadTime;

  @attr('number', { defaultValue: 2 })
  asapLeadTime;

  @attr('string')
  uuid;

  // accounting
  @attr('date')
  updatedAt;

  @attr('string')
  xeroContactId;

  @attr('date')
  xeroSynchronizedAt;

  @attr('string')
  salesforceId;

  /*
   * Relationships
   */
  @hasMany('area', { async: false })
  areas;

  @hasMany('restaurantCapacityTranche')
  restaurantCapacityTranches;

  @hasMany('menu')
  menus;

  @belongsTo('menu')
  activeMenu;

  @hasMany('order', { inverse: 'restaurant' })
  orders;

  @hasMany('restaurantClosures')
  closures;

  @belongsTo('user')
  owner;

  @hasMany('user')
  admins;

  @hasMany('contact')
  adminContacts;

  @hasMany('user-invite')
  userInvite;

  @hasMany('meal-planning-reservation')
  mealPlanningReservations;

  @hasMany('pickupLocation')
  pickupLocations;

  @belongsTo('pickupLocation')
  defaultPickupLocation;

  @belongsTo('location', { async: false })
  billingLocation;

  @belongsTo('contact')
  billingContact;

  @hasMany('serviceTimes', { async: false })
  serviceTimes;

  @hasMany('tag')
  tags;

  @hasMany('historian-version')
  allRestaurantVersions;

  @hasMany('historian-version')
  versions;

  @hasMany('dietary-tag')
  dietaryTags;

  @hasMany('food-type')
  foodTypes;

  @hasMany('meal-type')
  mealTypes;

  @hasMany('restaurant-ranking')
  rankings;

  @belongsTo('restaurants-brand')
  brand;

  /*
   * Computed Properties
   */
  @serviceTimesForDayComputed(0)
  sundayServiceTimes;

  @serviceTimesForDayComputed(1)
  mondayServiceTimes;

  @serviceTimesForDayComputed(2)
  tuesdayServiceTimes;

  @serviceTimesForDayComputed(3)
  wednesdayServiceTimes;

  @serviceTimesForDayComputed(4)
  thursdayServiceTimes;

  @serviceTimesForDayComputed(5)
  fridayServiceTimes;

  @serviceTimesForDayComputed(6)
  saturdayServiceTimes;

  @oneWay('areas.firstObject.isoTimeZone')
  isoTimeZone;

  @hasMany('notification-log', { inverse: 'receiver' })
  notificationLogs;

  @alias('firstPickupLocation.center')
  center;

  get cuisineTags() {
    return this.tags.rejectBy('tagType', 'diversity');
  }

  set cuisineTags(cuisineTags) {
    this.tags = cuisineTags.concat(this.diversityTags);
  }

  get diversityTags() {
    return this.tags.filterBy('tagType', 'diversity');
  }

  set diversityTags(diversityTags) {
    this.tags = diversityTags.concat(this.cuisineTags);
  }

  get firstPickupLocation() {
    return this.get('defaultPickupLocation').then((defaultPickupLocation) =>
      defaultPickupLocation
        ? defaultPickupLocation.get('location')
        : this.get('pickupLocations').then((_) => _.get('firstObject.location'))
    );
  }

  /** @type {string} */
  @computed('name', 'slug')
  get suggestedSlug() {
    return this.get('slug') ? this.get('slug') : dasherize(this.get('name'));
  }

  /** @type {Location[]} Maps the location models and assigns names to each from the wrapping PickupLocations object */
  @computed('pickupLocations.@each.location')
  get pickupLocationsLocations() {
    const locations = this.get('pickupLocations')
      .mapBy('location.content')
      .filter((_) => _);
    locations.forEach((_, index) =>
      _.set('name', this.get('pickupLocations').objectAt(index).get('name'))
    );
    return locations;
  }

  @computed('uuid')
  get dashboardUrl() {
    return `${getArcticFoxUrl()}/${this.get('uuid')}`;
  }

  @computed('areas', 'slug`')
  get menuUrl() {
    const swiftfoxUrl = getSwiftFoxUrl();
    const areaSlug = this.areas?.firstObject?.slug;
    const restoSlug = this.slug;
    if (!swiftfoxUrl || !areaSlug || !restoSlug) {
      return '';
    }
    return `${swiftfoxUrl}/restaurants/${areaSlug}/${restoSlug}`;
  }

  /*
   * Functions
   */

  fetchAllTags() {
    return RSVP.hash({
      tags: this.get('tags'),
      mealTypes: this.get('mealTypes'),
      foodTypes: this.get('foodTypes'),
      dietaryTags: this.get('dietaryTags')
    });
  }

  /**
   * @param {date} day
   * @returns {Object[]}
   */
  serviceTimesForDay(day) {
    const serviceTimes = this.get('serviceTimes') || [];
    return serviceTimes.filterBy('weekday', day);
  }

  /**
   * Returns true if dateTime is possible
   * @type {boolean}
   */
  isDateTimePossible(dateTime, withLeadTime) {
    return (
      this.isWithinDeliveryTime(dateTime) &&
      this.isOutsideOfClosures(dateTime) &&
      this.isOutsideAreaClosures(dateTime) &&
      (!withLeadTime || this.hasEnoughLeadTime(dateTime))
    );
  }

  /**
   * @param  {Date} dateTime - Can be a date object, or date string
   * @param {string} type Can be 'delivery' or 'pickup'
   * @return {Boolean} - Returns whether the provided datetime is within the regular service hours
   */
  _isWithinServiceTime(dateTime, type) {
    const isoTimeZone = this.get('isoTimeZone');
    const dateObject = moment.tz(dateTime, isoTimeZone);
    const timeObj = new Time(dateObject.format('HH:mm:ss'));

    const weekday = dateObject.weekday();
    const serviceTimes = this.serviceTimesForDay(weekday);

    return serviceTimes.some(
      (time) =>
        timeObj.isSameOrAfter(time.get(`${type}StartTime`)) &&
        timeObj.isSameOrBefore(time.get(`${type}EndTime`))
    );
  }

  /**
   * @param  {Date} dateTime - Can be a date object, or date string
   * @return {Boolean} - Returns whether the provided datetime is within the regular delivery hours
   */
  isWithinDeliveryTime(dateTime) {
    return this._isWithinServiceTime(dateTime, 'delivery');
  }

  /**
   * @param  {Date} dateTime - Can be a date object, or date string
   * @return {Boolean} - Returns whether the provided datetime is within the regular pickup hours
   */
  isWithinPickupTime(dateTime) {
    return this._isWithinServiceTime(dateTime, 'pickup');
  }

  /**
   * Checks to see if the provided dateTime param is outside the closure of a restaurant
   * @param  {Date}  dateTime - expects a datetime object
   * @return {Boolean}
   */
  isOutsideOfClosures(dateTime) {
    const closures = this.get('closures.content');
    if (!closures.get('length')) {
      return true;
    }

    const isoTimeZone = this.get('isoTimeZone');

    const dateString = moment.tz(dateTime, isoTimeZone).format('YYYYMMDD');

    return !closures.any((closureDate) => {
      const closureDateString = moment(closureDate.get('date')).format('YYYYMMDD');
      return dateString === closureDateString;
    });
  }

  /**
   * Returns boolean if the dateTime param provided is outside of the restaurant's area closures. If no date time
   * is provided, the date that will be compared against will be today's.
   * @param {(Date|string)=}
   * @returns {boolean}
   */
  isOutsideAreaClosures(dateTime) {
    if (!this.get('areas.firstObject')) {
      return true;
    }

    return !this.get('areas').any((area) => !area.isOutsideAreaClosures(dateTime));
  }

  /**
   * Returns true if the provided dateTime param is equal to or beyond the lead time (leadTime).
   * @param {(Date|string)=}
   * @returns {boolean}
   */
  hasEnoughLeadTime(dateTime) {
    const isoTimeZone = this.get('isoTimeZone');

    return (
      moment.tz(dateTime, isoTimeZone).diff(moment.tz(moment(), isoTimeZone), 'hours') >=
      this.get('leadTime')
    );
  }

  /**
   * Returns true if the provided feature is enabled for the client
   * @param {string} feature
   * @return {boolean}
   */
  hasFeatureEnabled(feature) {
    return (get(this, 'features') ?? {})[feature];
  }

  /**
   * Returns true if the restaurant can service pm the provided weekday number
   * @param {number}
   * @returns {boolean}
   */
  servicesDayOfWeek(weekday) {
    return !!this.serviceTimesForDay(weekday).length;
  }

  /**
   * Checks to see if this restaurant is available for a given date time on the backend
   * @params {Date}
   * @returns {Promise.<Boolean>}
   */
  checkAvailability(dateTime) {
    return this.store
      .query('restaurant', {
        filter: {
          active: true,
          comingSoon: false,
          slug: this.slug,
          available_at: moment(dateTime).format()
        }
      })
      .then((response) => !!response.length);
  }

  bulkUpdateTranches = modelAction('restaurant-capacity-tranches/bulk-update', {
    method: 'POST'
  });

  searchRestaurants = resourceAction('search', { method: 'GET', pushToStore: false });

  updateActiveMenu = modelAction('update-active-menu', {
    method: 'POST'
  });

  /**
   * Applies the default restaurant properties to the provided order
   *
   * @param {Order} order
   */
  applyDefaultsTo(order) {
    return this.reloadWith('closures,service-times,pickup-locations.location').then((_) =>
      order.setProperties({
        restaurantLocation: this.get('pickupLocations.firstObject.location')
      })
    );
  }

  /**
   * TODO generalize this to all records
   *
   * @param {string} include
   */
  reloadWith(include) {
    return this.store.findRecord('restaurant', this.get('id'), {
      reload: true,
      include: include
    });
  }

  /**
   * Returns the link for a redirect URL to the salesforce record
   * @return {String}
   */
  get salesforceLink() {
    return `${ENV.salesforceHost}/${this.salesforceId}`;
  }

  validations = {
    xeroContactId: {
      format: {
        with: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
        allowBlank: true,
        message: 'must be a valid uuid'
      }
    }
  };
}
