import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@ember/component';

/**
 * Component for rendering an ordered table.
 */
@classic
export default class PaymentCards extends Component {
  /** @type {NotifyService} */
  @service
  notify;

  /** @type {boolean} */
  readonly = false;

  /** @type {string} */
  collectionKey = 'paymentCards';

  resetNewPaymentCard() {}

  @action
  updateNickname(paymentCard) {
    const notify = this.get('notify');
    const cardLabel = paymentCard.get('selectLabel');

    paymentCard
      .save({ update: true })
      .then((_) => notify.success(`Updated nickname for ${cardLabel}`))
      .catch((_) => notify.success(`Unable to update nickname for ${cardLabel}`));
  }
}
