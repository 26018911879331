import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed, get } from '@ember/object';
import { getOwner } from '@ember/application';

@classic
@classNames('fde-filters-controls-model-search')
export default class ModelSearch extends Component {
  /** @type {*} */
  value = undefined;

  /** @type {Model} Base resource to drive the search off of*/
  resourceBase = null;

  /** @type {Array} */
  values = [];

  /** @type {Array} */
  searchedModels = [];

  /** @type {Filter} */
  filter = null;

  /** @type {boolean} */
  limitFieldsByResourceLabel = true;

  /** @type {object} Optional search options to be passed into search action helper */
  @computed('limitFieldsByResourceLabel', 'filter.resourceRequiredFields')
  get resourceRequiredFields() {
    const filter = this.get('filter');

    const resourceRequiredFields = filter.get('resourceRequiredFields');
    const resourceValueLabel = filter.get('resourceValueLabel');
    const limitFieldsByResourceLabel = this.get('limitFieldsByResourceLabel');
    const searchAction = getOwner(this).lookup(`search-action:${filter.get('resourceName')}`);

    if (searchAction && (resourceRequiredFields || limitFieldsByResourceLabel)) {
      const fields = {};
      fields[searchAction.get('modelName')] = resourceRequiredFields || resourceValueLabel;

      return fields;
    }

    return undefined;
  }

  @action
  handleModelSelectChange(model) {
    console.debug(model);

    const resourceValueKey = this.get('filter.resourceValueKey');
    const resourceValueLabel = this.get('filter.resourceValueLabel');
    const shouldUseValueText = this.get('filter.valueText') && resourceValueLabel;

    const filterValue = this.get('filter.value').toArray();
    const filterValueText = shouldUseValueText && this.get('filter.valueText').toArray();

    const valueToAdd = get(model, resourceValueKey);
    const valueTextToUse = get(model, resourceValueLabel);

    if (!filterValue.includes(valueToAdd)) {
      filterValue.push(valueToAdd);
      shouldUseValueText && filterValueText.push(valueTextToUse);
    }

    const filter = this.get('filter');
    filter.setValue(filterValue, filterValueText);
  }

  @action
  handleValueDelete(value) {
    console.debug(value);

    const resourceValueLabel = this.get('filter.resourceValueLabel');
    const shouldUseValueText = this.get('filter.valueText') && resourceValueLabel;

    const filterValue = this.get('filter.value').toArray();
    const filterValueText = shouldUseValueText && this.get('filter.valueText').toArray();

    const indexOf = filterValue.indexOf(value);

    if (indexOf > -1) {
      filterValue.splice(indexOf, 1);
      shouldUseValueText && filterValueText.splice(indexOf, 1);
    }

    const filter = this.get('filter');
    filter.setValue(filterValue, filterValueText);
  }
}
