import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';

/**
 * Sortable Table configured for locations
 */
@classic
@classNames('fde-tables-organization-locations')
export default class OrganizationLocations extends Component {
  /** @type {boolean} */
  readonly = false;

  /** @type {DeliveryLocation|PickupLocation} */
  newLocation = null;

  /** @type {Object} bounds of the delivery zone */
  deliveryZone = null;

  /** @type {string} */
  collectionKey = '';

  /** @type {string} Title rendered above the table */
  title = '';

  @equal('collectionKey', 'deliveryLocations')
  showDeliveryRadius;

  @equal('collectionKey', 'pickupLocations')
  showPhoneNumber;

  resetLocation() {}
}
