import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import AjaxService from 'ember-ajax/services/ajax';
import config from 'star-fox/config/environment';

@classic
export default class _AjaxService extends AjaxService {
  /** @type {UserSession} */
  @service
  userSession;

  host = config.APP.API_HOST;

  /**
   * Headers that will be sent, includes the SimpleAuth Authtoken, and user information and
   * session id.
   * @type {Object}
   */
  @computed('userSession.userId', 'userSession.sessionId')
  get headers() {
    let headers = {};

    if (this.userSession.session.isAuthenticated) {
      let { email, token } = this.userSession.session.data.authenticated;
      headers['Authorization'] = `Token token="${token}", email="${email}"`;
    }

    headers.SESSION_ID = this.get('userSession.sessionId');
    headers.USER_ID = this.get('userSession.userId');

    return headers;
  }

  postJSON(uri, data) {
    return this.request(uri, {
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        accept: 'application/vnd.api+json'
      },
      data: JSON.stringify(data)
    });
  }

  /**
   * POST a JSONAPI formatted document to master fox.
   * @type {function}
   */
  postJSONAPI(uri, data) {
    return this.request(uri, {
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        accept: 'application/vnd.api+json'
      },
      data: JSON.stringify({
        data: data
      })
    });
  }

  deleteJSONAPI(uri, data) {
    return this.request(uri, {
      method: 'DELETE',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        accept: 'application/vnd.api+json'
      },
      data: JSON.stringify({
        data: data
      })
    });
  }

  getJSONAPI(uri) {
    return this.request(uri, {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        accept: 'application/vnd.api+json'
      }
    });
  }
}
