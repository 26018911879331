import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class ShowRoute extends Route {
  userShowRoute = 'logged-in.users.show';
  backRoute = 'logged-in.users';
  hasPreferenceProfile = true;

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setProperties({
      userShowRoute: this.userShowRoute,
      backRoute: this.backRoute,
      hasPreferenceProfile: this.hasPreferenceProfile
    });
  }

  /**
   * @param {Object} params
   * @returns {User}
   */
  async model(params) {
    const user = await this.store.findRecord('user', params.user_id, {
      reload: true,
      include: ['roles', 'restaurants', 'clients', 'couriers', 'communication-preference'].join(',')
    });

    await user.roles;
    await user.restaurants;
    await user.restaurants?.firstObject?.owner;

    return user;
  }
}
