import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@ember/component';
import isSameAs from 'star-fox/utils/is-same-as';

@classic
@classNames('field fde-radio-button-control')
export default class RadioButton extends Component {
  /** @type {string} radio-group id */
  group = '';

  /** @type {string} radio-button display label */
  label = '';

  /** @type {*} radio-button value */
  value = null;

  /**
   * Computed property which represents the selected state of the component.
   * @type {boolean}
   */
  @isSameAs('checkedValue', 'value')
  isSelected;

  /** @type {string} radio-button that is selected in radio-group */
  checkedValue = null;

  /** @type {string} identifier of radio-button */
  @computed
  get id() {
    return `${guidFor(this)}-radio`;
  }

  /** @type {*|string} either the selected value or the radio-button label */
  @computed('value')
  get _value() {
    return this.get('value');
  }

  /** @type {string} if true radio-button is selected */
  @isSameAs('_value', 'checkedValue')
  checked;

  /** @type {function} sends new value to radio-group-component */
  click() {
    this.onChange(this.get('_value'));
  }
}
