import classic from 'ember-classic-decorator';
// app/transforms/object.js
import Transform from '@ember-data/serializer/transform';

import EmberObject from '@ember/object';

const PhonesObj = {
  none: EmberObject.create({
    icon: '',
    id: 'none',
    label: 'None'
  }),
  android: EmberObject.create({
    icon: 'android',
    id: 'android',
    label: 'Android'
  }),
  ios: EmberObject.create({
    icon: 'apple',
    id: 'ios',
    label: 'iOS'
  })
};

// Normally I would Object#keys this but that doesn't appear to be available
export const Phones = ['android', 'ios'].map((_) => PhonesObj[_]);

/**
 * Phone is a type of either iOS/Android/None we make sure that it uses None
 */
@classic
export default class Phone extends Transform {
  deserialize(value) {
    return PhonesObj[value];
  }

  serialize(value) {
    return value && value.id ? value.id : 'none';
  }
}
