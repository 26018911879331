import classic from 'ember-classic-decorator';
import Transform from '@ember-data/serializer/transform';
import { FactTypeConversionMap } from '../models/rules-fact';

@classic
export default class FactType extends Transform {
  deserialize(value) {
    const ret = Object.entries(FactTypeConversionMap).find((e) => e[1] == value);

    if (!ret) {
      throw new Error(`${value} is not a valid fact type when deserializing`);
    }

    return ret[0];
  }

  serialize(value) {
    const ret = FactTypeConversionMap[value];

    if (!ret) {
      throw new Error(`${value} is not a valid fact type when serializing`);
    }

    return ret;
  }
}
