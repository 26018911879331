import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';

@classic
export default class DevRoute extends Route.extend(CapabilityRouteMixin, LoadingRouteMixin) {
  orderEditRoute = 'logged-in.orders.edit';
  capability = 'ORDER_EDIT_DEV';

  /** @type {boolean} */
  bubblesLoadingEvent = true;

  /** @override */
  model(_params) {
    const { order } = this.modelFor(this.get('orderEditRoute'));

    if (this.get('isIndexTypeRouteRefreshing')) {
      return this.getPrevModel();
    }

    return RSVP.hash({
      order: order
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
