import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import moment from 'moment-timezone';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class NewRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'PROMO_CODE_EDIT';

  model() {
    return RSVP.hash({
      promoCode: this.store.createRecord('promo-code', {
        // default promocodes for one day
        startsAt: moment().toDate(),
        expiresAt: moment().add(1, 'days').toDate()
      }),
      areas: this.store.query('area', {
        page: {
          limit: 100,
          offset: 0
        }
      }),
      restaurants: this.store.findAll('restaurant')
    });
  }
}
