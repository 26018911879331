import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames(null)
@tagName('div')
export default class RepeatNTimes extends Component {
  /** @type {number} Number used as n for repeat given element*/
  times = 0;

  /** @type {number} Number used as n for repeat given element*/
  @computed('times')
  get numberOfTimes() {
    return new Array(parseInt(this.get('times')));
  }
}
