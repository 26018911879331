import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { arg } from 'ember-arg-types';
import { array, func, string, bool } from 'prop-types';
import { action } from '@ember/object';

export default class SfListsSelectionComponent extends Component {
  @arg(string.required)
  name;

  @arg(array.required)
  items;

  @arg(bool)
  active = true;

  @arg(string)
  labelKey = 'label';

  @arg
  reset;

  @arg(string.required)
  sortBy;

  @arg(string.required)
  groupBy;

  @tracked
  selected = [];

  @arg(func)
  onChange = () => {};

  @action
  toggleItem(item) {
    if (this.selected.includes(item)) {
      this.selected.removeObject(item);
    } else {
      this.selected.addObject(item);
    }

    this.onChange(this.selected);
  }

  @action
  selectAll() {
    this.selected = this.items.toArray();
    this.onChange(this.selected);
  }

  @action
  deselectAll() {
    this.selected = [];
    this.onChange(this.selected);
  }

  @action
  updateSelection(selection) {
    this.selected = selection;
  }
}
