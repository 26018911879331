import classic from 'ember-classic-decorator';
import Service from '@ember/service';
import { copy } from '@ember/object/internals';

@classic
export default class SetMenuServiceService extends Service {
  /**
   * Creates a set menu object
   * @param {string} name Name of the set menu
   * @param {Menu} menu Menu to use.
   * @return {SetMenu}
   */
  create(name, menuId, setMenuItems = {}) {
    return {
      name,
      menuId: menuId,
      setMenuItems: copy(setMenuItems)
    };
  }

  /**
   * Checks to see if a set menu is empty.
   * @param {SetMenu}
   * @return {boolean}
   */
  isEmpty(setMenu) {
    return Object.keys(setMenu).length === 0;
  }
}

/**
 * @typedef {Object} SetMenu
 * @property {string} name
 * @property {string} menuId
 * @property {Object.<String, Number>} setMenuItems
 */
