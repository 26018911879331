import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class EditRoute extends Route {
  async model({ id }) {
    return this.store.findRecord('subscription-plan', id, {
      include: 'feature-flag-descriptions'
    });
  }

  afterModel(model) {
    const parentController = this.controllerFor('logged-in.admins.subscription-plans');
    parentController.set('selectedValues', [model]);
  }
}
