import classic from 'ember-classic-decorator';
import RSVP from 'rsvp';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import moment from 'moment-timezone';

@classic
export default class ClosuresRoute extends Route {
  queryParams = {
    month: { refreshModel: true }
  };

  activate() {}

  deactivate() {}

  model(params) {
    const restaurant = this.modelFor('logged-in.restaurants.show').restaurant;

    return RSVP.hash({
      restaurant: restaurant,
      closures: restaurant.query('closures', {
        page: {
          limit: 33 // I know. 31 days is the max number of days in a Gregorian month, but better safe than sorry.
        },
        filter: {
          month: params.month || moment().format('YYYY-MM-DD')
        }
      })
    });
  }

  /**
   * Because we are dealing with a query and not a find, destroying / creating records
   * won't actually trigger a redraw, so we copy the results to an array on the controller
   * and manage the inclusion in that array manually (see the toggleDay method)
   * @param controller
   * @param model
   */
  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setProperties({
      fetchingPrev: false,
      fetchingNow: false,
      fetchingNext: false,
      closures: model.closures.toArray()
    });

    // Subscribe to pusher events,
    // the channelName is defined in the controller.
    controller.setupPusher();
  }

  // Unsubscribe from the pusher channels we've been listening to.
  // Clean up when we leave. We probably don't want  to still be receiving events.
  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);

    controller.teardownPusher();
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
