import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@tagName('')
export default class JsonControl extends Component {
  value = null;
  mode = 'code';
  onChange(_value) {}
}
