import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Service from '@ember/service';
import RSVP from 'rsvp';

/**
 * Tracks outstanding promises and their labels and defers method calls until all promises (or of a certain type) are
 * settled.
 * @class SettledPromiseQueue
 */
@classic
export default class SettledPromiseQueueService extends Service {
  /** @type {String[]} A list of promise labels / GUIDs */
  outstandingPromises = [];

  /** @type { regExp: {func: {Function}, key: {String}}[] } Map of regular expressions to watch and their queues. */
  queues = {};

  /**
   * A list of regular expressions represented by string to track
   * @type Array<String>
   */
  @computed
  get regExpWatchList() {
    return Object.keys(this.get('queues'));
  }

  // isEmpty: computed('outstandingPromises.length',function() {
  //   return this.get('outstandingPromises.length') === 0;
  // }),

  /**
   * Returns true if the promise label exists.
   * @param {String} label
   * @returns {boolean}
   */
  promiseLabelExists(label) {
    return this.get('outstandingPromises').includes(label);
  }

  /**
   * Returns a count of the outstanding promises that match the regular expression string.
   * @param {String} regExp
   * @returns {number}
   */
  promiseLabelMatchCount(regExp) {
    return this.get('outstandingPromises').reduce((count, promiseLabel) => {
      return promiseLabel.match(new RegExp(regExp)) !== null ? count + 1 : count;
    }, 0);
  }

  /** Starts the promise watch, should be called at the beginning of the app */
  startPromiseWatch() {
    RSVP.configure('instrument', true);
    RSVP.on('created', this.onPromiseCreated.bind(this));
    RSVP.on('fulfilled', this.onPromiseCompleted.bind(this));
    RSVP.on('rejected', this.onPromiseCompleted.bind(this));
  }

  /** Stops the promise watch */
  stopPromiseWatch() {
    RSVP.configure('instrument', false);
  }

  /**
   * Handler for a given promise created event. Used to track promises throughout the app lifecycle.
   * @param {Event} event
   */
  onPromiseCreated(event) {
    this.addOutstandingPromise(event);
  }

  /**
   * Handler for a given promise completion event.
   * @param {Event} event
   */
  onPromiseCompleted(event) {
    this.removeOutstandingPromise(event);

    const label = event.label || '';

    const regExpWatchList = this.get('regExpWatchList');

    const queuesToManage = regExpWatchList.filter(
      (regExp) => label.match(new RegExp(regExp)) !== null
    );

    queuesToManage.forEach((regExp) => this.executeAndEmptyQueueForRegExp(regExp));
  }

  /**
   * Takes a regular expression label, finds the queue of function-key pairs using the label, executes all functions in
   * the queue, and deletes the queue for the given regular expression label.
   * @param {String} regExp
   */
  executeAndEmptyQueueForRegExp(regExp) {
    const queues = this.get('queues');
    const count = this.promiseLabelMatchCount(regExp);
    const queue = queues[regExp];

    if (count === 0 && queue) {
      queue.forEach((_) => _.func());
      delete queues[regExp];
    }
  }

  /**
   * Adds a singly tracked outstanding promise for a given RSVP instrumentation event.
   * @param {Event} event
   */
  addOutstandingPromise(event) {
    this.get('outstandingPromises').push(event.label || event.guid);
  }

  /**
   * Removes a singly tracked outstanding promise for a given RSVP instrumentation event.
   * @param {Event} event
   */
  removeOutstandingPromise(event) {
    const outstandingPromises = this.get('outstandingPromises');
    const indexToRemove = outstandingPromises.indexOf(event.label || event.guid);
    if (indexToRemove !== -1) {
      outstandingPromises.removeAt(indexToRemove);
    }
  }

  /**
   * Adds the function key pair to the resulting queue
   * @param {String} regExp
   * @param {Function} func
   * @param {?String} key
   */
  addToQueue(regExp, func, key) {
    const queue = this.get('queues')[regExp];
    const foundKey = queue.find((_) => _.key === key);

    if (!key || !foundKey) {
      queue.push({ func, key });
      this.notifyPropertyChange('regExpWatchList');
    }
  }

  /**
   * Creates a queue labelled with the regular expression string, and adds the function key pair to the resulting queue
   * @param {String} regExp
   * @param {Function} func
   * @param {?String} key
   */
  createAndAddToQueue(regExp, func, key) {
    const queues = this.get('queues');
    queues[regExp] = [{ func, key }];

    this.notifyPropertyChange('regExpWatchList');
  }

  /**
   * Pushes the function onto a queue labelled with a string representation of a regular expression. If a key is passed
   * multiple pushes of the same function in a 50ms period will be squashed into 1 entry.
   *
   * @param {String} regExp
   * @param {Function} func
   * @param {?String} key Optional key. Allows for squashing of similar function-key pairs in a short period (50ms)
   */
  push(regExp, func, key) {
    regExp = regExp || '';

    const queues = this.get('queues');
    const queueExistsForRegExp = !!queues[regExp];

    if (queueExistsForRegExp) {
      this.addToQueue(regExp, func, key);
    } else {
      this.createAndAddToQueue(regExp, func, key);
    }

    /**
     *  It takes 50 ms for the events from recently created promises to be seen by this class (an implementation detail
     *  of RSVP.) If after 50ms there are no promise labels that match the regular expression, execute functions inside
     *  the queue immediately and empty the queues for the given regular expression
     *
     *  We also decide to take advantage of this delay to allow multiple function-key pairs to be squashed (sort of debounced)
     *  in a 50ms period.
     */
    setTimeout((_) => {
      if (this.promiseLabelMatchCount(regExp) === 0) {
        this.executeAndEmptyQueueForRegExp(regExp);
      }
    }, 50);
  }
}
