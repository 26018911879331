import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { guidFor } from '@ember/object/internals';

/**
 * Contextual Component for rendering a table head
 */
@classic
@tagName('thead')
@classNames('inverted')
export default class TableHeader extends Component {
  /** @type {string} */
  sortByString = '';

  /** @type {boolean} */
  isSelectable = false;

  /** @type {boolean} */
  isExpanded = false;

  /** @type {TableManager} */
  tableManager = null;

  /** @type {boolean} */
  resizable = false;

  /** @type {boolean} */
  hideableColumns = false;

  /** @type {boolean} Ensures that the last column is always fluid all proceeding ones are hidden */
  lastColumnAlwaysFluid = true;

  /** @type {string} */
  @computed
  get actionMenuWormholeId() {
    return `${guidFor(this)}-action-menu-wormhole`;
  }

  @computed
  get hideableHeaderCells() {
    return this.get('tableManager').getHideableHeaderCells();
  }

  onSortChange() {}
  onSelectAllChange() {}
  onSelectAll() {}
  onDeselectAll() {}
  onExpandAll() {}
  onCollapseAll() {}

  @action
  handleSelectAllChange(value) {
    this.onSelectAllChange(value);
    value ? this.onSelectAll() : this.onDeselectAll();
  }

  @action
  handleColumnHideShowClick(headerCell) {
    this.get('tableManager').toggleColumnVisibility(headerCell);
  }

  @action
  handleResetAllColumnsToDefaultClick() {
    this.get('tableManager').resetAllColumnsToDefault();
  }
}
