import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

import { action } from '@ember/object';

@classic
@classNames('fde-nav-menu ui vertical attached fluid menu')
export default class NavMenu extends Component {
  /** @type {boolean} */
  isLoading = false;

  /** @type {Model[]} array of models to display as navigation */
  models = null;

  /** @type {string} key on the model to display as an icon */
  iconKey = null;

  /** @type {string} key on the model to display as a label */
  nameKey = 'name';

  /** @type {string} key on the model to display as a label */
  subNameKey = null;

  /** @type {string} text to display when there are no items in the nav */
  emptyText = 'None';

  /** @type {*} optional current filter value */
  filterValue = null;

  /** @type {Array.<*>} optional filtering values */
  filterValues = null;

  /** @type {string}  */
  groupByKey = null;

  /** @type {function} optional filtering function */
  onFilter = null;

  onSearch = null;

  /** @type {string} */
  commandName = '';

  /** @type {string} */
  selectName = '';

  /**
   * Optional command button that attaches to the bottom of the menu
   */
  onCommand = null;

  /**
   * Optional button that attaches to the bottom of the menu
   */
  onSelect = null;

  didDestroy() {}

  @action
  handleOnAdd() {
    this.set('isAdding', true);
    this.onAdd().finally((_) => this.set('isAdding', false));
  }

  @action
  handleOnCommand() {
    this.set('isCommanding', true);
    this.onCommand().finally((_) => this.set('isCommanding', false));
  }

  @action
  handleOnSelect() {
    this.set('isSelecting', true);
    this.onSelect().finally((_) => this.set('isSelecting', false));
  }
}
