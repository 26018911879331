import classic from 'ember-classic-decorator';
import DeviseAuthenticator from 'ember-simple-auth/authenticators/devise';
import config from 'star-fox/config/environment';

@classic
export default class Devise extends DeviseAuthenticator {
  serverTokenEndpoint = `${config.APP.API_HOST}/${config.APP.apiV3Namespace}/users/sign_in`;
  tokenAttributeName = 'token';
  identificationAttributeName = 'email';
}
