import { helper as buildHelper } from '@ember/component/helper';

/** Helper that checks whether a value is present in an errors object
 * @param {Object} errors
 * @param {string} value
 */
export function valueHasErrors([errors = null, value = null]) {
  if (errors && value) {
    return errors.indexOf(value) > -1;
  }
}

export default buildHelper(valueHasErrors);
