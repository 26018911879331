import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias, or, not } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { dasherize } from '@ember/string';

import { Commands } from 'star-fox/models/meal-planning-reservation';

import { task } from 'ember-concurrency';

const ModalTypes = {
  Reservation: 'reservation',
  MealPlan: 'meal plan'
};

@classic
@tagName('')
export default class ActionReservationStateButton extends Component {
  @service
  tick;

  @service
  modals;

  init() {
    super.init(...arguments);
    this.errors = [];
  }

  /** @type {Service} */
  @service
  notify;

  /** @type {Store} */
  @service
  store;

  /** @type {Store} */
  @service
  router;

  /** @type {Reservation} Reservation model */
  reservation = null;

  /** @type {MealPlan} MealPlan model */
  mealPlan = null;

  /** @type {MealPlan|Reservation} Commandable Model */
  @or('reservation', 'mealPlan')
  planable;

  /** @type {String} The order's state */
  @alias('planable.state')
  state;

  Commands = Commands;

  readonly = false;

  disabled = false;

  isInline = true;

  /** @type {Function} Handler for failure cases */
  handleDidFail = null;

  showState = false;

  /** @type {Reservation[]}  */
  selectedReservations = null;

  /** @type {boolean} */
  @alias('planable.ignoresCapacityLimit')
  ignoresCapacityLimit;

  /** @type {string} Builds a css class based on order state*/
  @computed('state')
  get stateCssClass() {
    return `fde-reservation-state-color_${dasherize(this.get('state') || '')}`;
  }

  @computed('planable.allWarningsCount')
  get hasInstanceWarnings() {
    return this.get('planable.allWarningsCount') > 0;
  }

  @computed('reservation.hasSoftWarnings')
  get hasReservationWarnings() {
    return this.get('reservation.softWarningCount') > 0;
  }

  @computed('planable.allErrorsCount')
  get hasInstanceErrors() {
    return this.get('planable.allErrorsCount') > 0;
  }

  @computed('reservation.warnings')
  get hasReservationErrors() {
    return this.get('reservation.hasWarnings') && !this.get('reservation.isCapacityErrorIgnored');
  }

  get hasAnyWarningsOrErrors() {
    return (
      this.hasInstanceWarnings ||
      this.hasInstanceErrors ||
      this.hasReservationWarnings ||
      this.hasReservationErrors
    );
  }

  /** @type {boolean} */
  @not('reservation.sameDayDeadlineTime') disableToggleSameDay;

  /** @type {string} Builds a css class based on order state*/
  @computed('showState', 'commandPlanableTask.isRunning', 'requestNewRestaurantTask.isRunning')
  get classes() {
    const ret = ['fde-reservation-state-button'];

    if (this.get('showState')) {
      ret.push('show-state');
      ret.push('tiny');
    }

    if (this.get('commandPlanableTask.isRunning')) {
      ret.push('loading');
    }

    if (this.get('requestNewRestaurantTask.isRunning')) {
      ret.push('loading');
    }

    return ret.join(' ');
  }

  /** @type {String[]} */
  budgetTypeValues = ['money', 'hide_prices'];

  /** @type {string} */
  @computed('showState')
  get icon() {
    return this.get('showState') ? 'down caret icon' : 'vertical ellipsis icon';
  }

  /** Calls a command on the planable */
  @task(function* (command) {
    try {
      yield this.get('planable')[command]();
    } catch (e) {
      if (e.errors?.length > 0) {
        e.errors.forEach((_) => {
          this.get('notify').error(_.title);
        });
      } else {
        this.get('notify').error(`Something went wrong: ${e.toString()}`);
      }
    }
  })
  commandPlanableTask;

  /** Approves the capacity override */
  @task(function* () {
    yield this.get('planable').approveCapacityOverride();
  })
  approveCapacityOverrideTask;

  /** Reverses the approvale of the capacity override */
  @task(function* () {
    yield this.get('planable').denyCapacityOverride();
  })
  denyCapacityOverrideTask;

  @task(function* () {
    return yield this.get('planable').requestNewRestaurant();
  })
  requestNewRestaurantTask;

  @action
  async openStateChangeModal(hasError, entity) {
    const basePath = 'sf/orders/modals/';
    const modalPath = hasError ? `${basePath}alert` : `${basePath}confirm`;
    const headers = {
      header: hasError ? 'You cannot proceed.' : 'Do you want to proceed?',
      body: hasError
        ? `This ${entity} has errors (shown in red). Until you have resolved these errors, this ${entity} cannot move into “Quoted”.`
        : `This ${entity} has warnings (shown in orange), are you sure you want to ignore those and move this ${entity} into “Quoted”?`
    };

    return this.get('modals').open(modalPath, headers);
  }

  @action
  async handlePublishConfirmations() {
    const entityType = this.isInline ? ModalTypes.Reservation : ModalTypes.MealPlan;
    const errorCheck = this.isInline ? this.hasReservationErrors : this.hasInstanceErrors;
    const warningCheck = this.isInline ? this.hasReservationWarnings : this.hasInstanceWarnings;

    if (errorCheck) {
      return await this.openStateChangeModal(true, entityType);
    } else if (warningCheck) {
      return await this.openStateChangeModal(false, entityType);
    }
  }

  @action
  async runStateCommand(command) {
    if (
      command === this.Commands.Publish &&
      this.hasAnyWarningsOrErrors &&
      !(await this.handlePublishConfirmations())
    ) {
      return;
    }

    try {
      await this.get('commandPlanableTask').perform(command);
    } catch (error) {
      console.error('Failed to execute command:', error);
    }
  }

  @action
  openOrdersToEdit() {
    const orders = this.get('planable.events')
      .map((_) => _.get('reservations').toArray())
      .flat()
      .map((_) => _.get('order.content'));

    this.get('router').transitionTo('logged-in.orders', {
      queryParams: { id: orders.map((_) => `${_.get('id')}::${_.get('identifier')}`).join(';') }
    });
  }

  @action
  destroyReservation() {
    if (confirm('Are you sure you want to destroy this reservation?')) {
      this.get('reservation')
        .destroyRecord()
        .then((_) => this.didDestroy());
    }
  }

  @action
  async handleToggleSameDay() {
    if (!this.reservation) {
      return;
    }
    if (
      this.reservation.deadlineIsSameDay ||
      !this.reservation.restaurant ||
      !this.reservation.restaurant.content ||
      this.reservation.restaurant.get('canSameDayFoodeePlusTeamOrder')
    ) {
      this.reservation.deadlineIsSameDay = !this.reservation.deadlineIsSameDay;
      await this.reservation.save();
      return;
    }
    const isConfirmed = await this.modals.open(
      'sf/orders/modals/confirm',
      {
        header: 'Warning',
        body: 'The current restaurant does not allow same-day deadlines.<br />Do you wish to continue?'
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false
        }
      }
    );

    if (isConfirmed) {
      this.reservation.deadlineIsSameDay = true;
      await this.reservation.save();
      return;
    }
  }

  @action
  handleConvertButtonClick() {
    this.get('convertSelectedReservationsTask').perform();
  }

  @action
  requestCapacityOverride() {
    this.get('planable')
      .requestCapacityOverride()
      .then((_) => this.get('notify').success('Request sent.'));
  }

  @action
  approveOverride() {
    this.get('approveCapacityOverrideTask').perform();
  }

  @action
  denyOverride() {
    this.get('denyCapacityOverrideTask').perform();
  }

  @action
  requestNewRestaurant() {
    return this.get('requestNewRestaurantTask').perform();
  }
}
