import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class CommunicationPreference extends Model {
  /*
   * Attributes
   */
  @attr('boolean')
  sms;

  @attr('boolean')
  emailFeedback;

  @belongsTo()
  user;

  validations = {};
}
