import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';

export default Mixin.create({
  /** @type {boolean} */
  useNewPubSub: computed('userSession.session.user', function () {
    return this.userSession.session.user?.hasFeatureEnabled('newPubSub') ?? false;
  }),

  /** @type {string} */
  pubSub: computed('useNewPubSub', function () {
    return this.useNewPubSub
      ? { service: this.actionCable, name: 'cable' }
      : { service: this.pusher, name: 'pusher' };
  }),

  init() {
    if (this.PUSHER_SUBSCRIPTIONS) {
      Object.keys(this.PUSHER_SUBSCRIPTIONS).forEach((channelName) => {
        let events = this.PUSHER_SUBSCRIPTIONS[channelName];
        this.pubSub.service.wire(this, channelName, events);
      });
    }

    return this._super(...arguments);
  },

  willDestroy() {
    if (this.PUSHER_SUBSCRIPTIONS) {
      Object.keys(this.PUSHER_SUBSCRIPTIONS).forEach((channelName) => {
        this.pubSub.service.unwire(this, channelName);
      });
    }

    return this._super(...arguments);
  },

  _pusherEventsId() {
    return this.toString();
  }
});
