import { capitalize } from '@ember/string';
export class Enum extends String {
  value;

  constructor(string) {
    super(string);
    this.value = string;

    if (this.constructor.valueFor(string)) {
      throw `The enum ${this.constructor.name} does not have an enumerated type for ${string}`;
    }
  }

  valueOf() {
    return this.value;
  }

  get id() {
    return this;
  }

  get label() {
    return capitalize(this);
  }

  static get values() {
    return Object.keys(this).map((key) => this[key]);
  }

  static valueFor(key) {
    // string ember need a squish compare.
    return this.values.find((_) => _.value === key);
  }
}

/**
 * Decorator for enums that lets you make is attributes that reflec what state they are in.
 *
 * usage
 *
 * class Enum {
 *   static SomeType = new Enum('some_type');
 *
 *   @is isSomeType;
 * }
 */
export function is(target, key, _descriptor) {
  return {
    get() {
      return this === target.constructor[key.slice(2)];
    }
  };
}

/**
 * Decorator for enums that lets you make is attributes that reflec what state they are in.
 *
 * usage
 *
 * class Enum {
 *   static SomeType = new Enum('some_type');
 *
 *   @is isSomeType;
 * }
 */
export function isNot(target, key, _descriptor) {
  return {
    get() {
      return this !== target.constructor[key.slice(5)];
    }
  };
}
