import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

@classic
export default class TaxRatesRoute extends Route.extend(AuthenticatedRouteMixin) {
  model() {
    return RSVP.hash({
      taxRates: this.store.query('invoicing-tax-rate', {
        include: 'areas',
        page: { limit: 100, offset: 0 }
      }),
      areas: this.store.query('area', { page: { limit: 100, offset: 0 } })
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
