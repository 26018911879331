import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import EmberObject, { action } from '@ember/object';

import { FailureSource, FailureType } from 'star-fox/services/mock-card-service';

export default Controller.extend({
  mockCardService: service(),

  order: EmberObject.create({
    clientNotes: 'Hodor',
    courierNotes: 'Jon Snow',
    accountingNotes: 'Daenerys Stormborn',
    restaurantNotes: 'Ygritte the Wildling',
    internalNotes: 'Arya Stark',
    haiku:
      'For all the breathing' + '\n' + 'I have done in different rooms' + '\n' + 'I am no wiser'
  }),

  dietaryTag: EmberObject.create({
    name: 'vegan',
    slug: 'vegan',
    abbreviation: 'vg',
    color: '#104C5C'
  }),

  dietaryTagSelectable: EmberObject.create({
    name: 'vegan',
    slug: 'vegan',
    abbreviation: 'vg',
    color: '#104C5C',
    isSelected: true,
    isSelectable: true
  }),

  get newCard() {
    const card = this.store.createRecord('payment-card');
    card.set('cardService', this.get('mockCardService'));
    return card;
  },

  cards: [],
  card: null,

  behaviors: [
    'success',
    'stripe_expiry_failure',
    'stripe_cvc_failure',
    'stripe_card_failure',
    'stripe_card_declined',
    'masterfox_expiry_failure',
    'masterfox_cvc_failure',
    'masterfox_card_failure',
    'masterfox_card_declined'
  ],

  toggleDietaryTag: action(function (dietaryTag) {
    if (dietaryTag.isSelectable) {
      dietaryTag.toggleProperty('isSelected');
    }
  }),

  handleFailureSelectChange: action(function (key, value) {
    const mockCardService = this.get('mockCardService');

    switch (value) {
      case 'success':
        mockCardService.setToSucceed();
        break;
      case 'stripe_expiry_failure':
        mockCardService.setToFail(FailureType.EXPIRY, FailureSource.ON_TOKEN_SAVE);
        break;
      case 'stripe_cvc_failure':
        mockCardService.setToFail(FailureType.CVC, FailureSource.ON_TOKEN_SAVE);
        break;
      case 'stripe_card_failure':
        mockCardService.setToFail(FailureType.NUMBER, FailureSource.ON_TOKEN_SAVE);
        break;
      case 'stripe_card_declined':
        mockCardService.setToFail(FailureType.DECLINED, FailureSource.ON_TOKEN_SAVE);
        break;
      case 'masterfox_expiry_failure':
        mockCardService.setToFail(FailureType.EXPIRY, FailureSource.ON_CARD_SAVE);
        break;
      case 'masterfox_cvc_failure':
        mockCardService.setToFail(FailureType.CVC, FailureSource.ON_CARD_SAVE);
        break;
      case 'masterfox_card_failure':
        mockCardService.setToFail(FailureType.NUMBER, FailureSource.ON_CARD_SAVE);
        break;
      case 'masterfox_card_declined':
        mockCardService.setToFail(FailureType.DECLINED, FailureSource.ON_CARD_SAVE);
        break;
    }
  })
});
