import attr from 'ember-data/attr';
import { fragment } from 'ember-data-model-fragments/attributes';
import MF from 'ember-data-model-fragments';

export default class FragmentsLogisticsEstimateLog extends MF.Fragment {
  @attr('luxon-date') timestamp;
  @attr('luxon-date') arrivalEstimate;
  @attr('string') distanceEstimate;
  @fragment('fragments/logistics/ping') driverPosition;
}
