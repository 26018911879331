import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';

@classic
export default class SubscriptionRoute extends Route {
  model(_params) {
    const client = this.modelFor('logged-in.clients.edit');
    return RSVP.hash({
      client,
      subscriptionHistory: client.query('versions', {
        sort: '-created-at',
        page: { limit: 200 },
        filter: { propertyChanged: 'subscription_plan_id' }
      }),
      subscriptionPlans: this.store
        .query('subscription-plan', {
          filter: { isCustom: false },
          include: 'feature-flag-descriptions',
          page: { limit: 200 }
        })
        .then((_) => _.toArray()),
      customSubscriptionPlans: this.store
        .query('subscription-plan', {
          filter: { isCustom: true },
          include: 'feature-flag-descriptions',
          page: { limit: 200 }
        })
        .then((_) => _.toArray())
    });
  }
}
