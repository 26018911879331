import classic from 'ember-classic-decorator';
import SearchAction from './base';

@classic
export default class Driver extends SearchAction {
  /** @override */
  modelName = 'user';

  /** @override */
  filterSearchName = 'search';

  /** @override Force is_driver to be true */
  defaultSearch(modelName, query, filterSearchName, defaultIncludes, queryOptions, resource) {
    queryOptions.filter = queryOptions.filter || {};
    queryOptions.filter.is_driver = true;
    return super.defaultSearch(
      modelName,
      query,
      filterSearchName,
      defaultIncludes,
      queryOptions,
      resource
    );
  }
}
