import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import { action, computed } from '@ember/object';
import Component from '@ember/component';
import { next, run } from '@ember/runloop';
import moment from 'moment-timezone';
import { fromLocalTimeIn, toLocalTimeIn } from 'star-fox/utils/date';

/**
 * Wrapper around "semantic-ui-daterangepicker#~1.3.21",
 */
@classic
@classNames('fde-date-select ui left icon input')
@classNameBindings('readonly:corner', 'readonly:labeled', 'isFluid:fluid')
export default class DateControl extends Component {
  /** @type {boolean}  */
  isFluid = true;

  /** @type {boolean} whether or not this a range selector */
  isSingleDatePicker = true;

  /** @type {boolean} whether or not this time selector */
  hasTimePicker = false;

  /** @type {boolean} whether or not this is a time only selector */
  timeOnly = false;

  /** @type {boolean} */
  hasLinkedCalendars = false;

  /** @type {date} initial date (user in single selection mode) */
  value = null;

  /** @type {number} amount that the minute drop down increments by */
  timePickerIncrement = 15;

  /** @type {string} */
  format = 'dddd, MMMM Do YYYY';

  /** @type {string} */
  timeZone = '';

  /** @type {?string} */
  drops = undefined;

  /** @type {string} A localized, and momentized alias of the value */
  @computed('timezone', 'value')
  get date() {
    const tz = this.get('timeZone');
    return toLocalTimeIn(isNaN(Date.parse(this.value)) ? moment() : moment(this.value), tz);
  }

  showFacade = true;

  /**
   * @callback changeHandler
   * @type {string} startDate as a string
   * @type {string} [endDate] as a string
   *
   * @type {changeHandler}
   */
  onChange() {}

  /** @type {function} */
  onHide() {}

  /** @type {function} */
  onShow() {}

  /** @type {function} */
  onApply() {}

  /**
   * @type {daterangepicker}
   * @private
   */
  _dateRangePicker = null;

  /**
   * @param {number} value time in ms since 1970
   * @private
   */
  _onChange(value) {
    this.onChange(fromLocalTimeIn(value, this.get('timeZone')));
  }

  /**
   * @type {daterangepicker}
   * @private
   */
  _updateDatePicker() {
    if (this._dateRangePicker) {
      const date = this.get('date');

      this._dateRangePicker.setStartDate(date);
      this._dateRangePicker.setEndDate(date);
    }
  }

  didDestroyElement() {
    super.didDestroyElement(...arguments);
    if (this._dateRangePicker) {
      this._dateRangePicker.remove();
      this._$dateRangePickerElement.off();
    }
  }

  @action
  removeFacade(_event) {
    this.set('showFacade', false);

    next(() => {
      let tz = this.get('timeZone');

      const startDate = toLocalTimeIn(
        this.get('date') || this.get('startDate') || moment().toDate(),
        tz
      );
      const endDate = toLocalTimeIn(
        this.get('date') || this.get('endDate') || moment().toDate(),
        tz
      );

      const options = {
        autoApply: true,

        timeOnly: this.get('timeOnly'),

        singleDatePicker: this.get('isSingleDatePicker'),

        timePicker: this.get('hasTimePicker'),

        timePickerIncrement: this.get('timePickerIncrement'),

        linkedCalendars: this.get('hasLinkedCalendars'),

        startDate: startDate,

        endDate: endDate,

        format: this.get('format'),

        drops: this.get('drops')
      };

      const dateRangePickerElement = this.$('.dateSelect')
        .daterangepicker(options, (start, end, _label) => this._onChange(start, end))
        .on('show.daterangepicker', () => {})
        // on hiding the drop down
        .on('hide.daterangepicker', () => {
          this.onHide();
        })
        .on('apply.daterangepicker', () => {
          this.onApply();
        });

      this._dateRangePicker = dateRangePickerElement.data('daterangepicker');
      this._$dateRangePickerElement = dateRangePickerElement;

      this._updateDatePicker();

      this.$('.dateSelect').click();
    });
  }

  @observes('value')
  onFilterDateChange() {
    run.next((_) => this._updateDatePicker());
  }
}
