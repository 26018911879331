import Fixture, {
  // element,
  // elementWithValue,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class SfTabViewFixture extends Fixture {
  constructor() {
    super('data-test-sf-tab-view');
  }
}

export const sfTabView = getElementDecoratorFor(SfTabViewFixture);
export const sfTabViewWithValue = getElementWithValueDecoratorFor(SfTabViewFixture);
