import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import RSVP from 'rsvp';
import { action, computed } from '@ember/object';

import { task } from 'ember-concurrency';

@classic
@classNames('fde-forms-meal-planning-form-for')
export default class MealPlanningFormFor extends Component {
  /** @type {NotifyService} */
  @service
  notify;

  /** @type {Store} */
  @service
  store;

  /** @type {UserSession} */
  @service('user-session')
  userSession;

  @alias('userSession.user')
  currentUser;

  @service
  modals;

  /** @type {MealPlan} */
  area = null;

  /** @type {MealPlanningInstance|MealPlanningTemplate} */
  mealPlan = null;

  /** @type {Restaurant[]} */
  restaurants = null;

  /** @type {Tag[]} */
  tags = null;

  /** @type {Array<{id: string, label: string}>} */
  constraintTypes = [
    { id: 'ge', label: 'At Least' },
    { id: 'le', label: 'At Most' },
    { id: 'eq', label: 'Exactly' }
  ];

  /** @type {function} */
  didDestroy() {}

  /** @type {boolean} */
  canReserve = false;

  /** @type {boolean} */
  readonly = false;

  @computed('mealPlan.state')
  get canSendClientReviewEmail() {
    return this.get('mealPlan.state') != 'draft';
  }

  /** @type {boolean} */
  isCollapsible = false;

  /** @type {boolean} */
  isExpanded = true;

  /** @type {boolean} */
  isEditing = false;

  /** @type {boolean} */
  confirmOnMealRemove = false;

  /** @type {RestaurantConstraint} */
  selectedRestaurantConstraint = null;

  /** @type {?function} */
  onSelectRestaurantConstraint = null;

  /** @type {?function} */
  handleRestaurantChanged = null;

  /** @type {?function} */
  onHandle = null;

  /** @type {number} */
  changeCount = 0;

  log = null;

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.set(
      'log',
      this.get('store').createRecord('meal-planning-log-record', { planable: this.get('mealPlan') })
    );

    const clientId = this.mealPlan.get('client.id');
    if (clientId) {
      this.store.findRecord('client', clientId, { reload: true });
    }
  }

  /** @type {Function} */
  @action
  addMeal(day) {
    const plan = this.get('mealPlan.plan');

    const numberOfPeople = this.get('mealPlan.defaultNumberOfPeople');
    const contact = plan.get('contact');
    const location = plan.get('location');

    day.addMeal(numberOfPeople, contact, location);
  }

  /** @type {Function} */
  @action
  removeMeal(meal, day) {
    const confirmed = this.get('confirmOnMealRemove')
      ? confirm('Are you sure you want to remove this meal?')
      : true;
    if (confirmed) {
      day.removeMeal(meal);
    }
  }

  /** @type {Function} */
  @action
  addSlot(meal) {
    meal.addSlot();
  }

  /** @type {Function} */
  @action
  removeSlot(slot, meal) {
    meal.removeSlot(slot);
  }

  toggleSelection() {}

  onEdit() {}

  onDoneEdit() {}

  @task(function* () {
    yield this.get('mealPlan').fixitAndReload();
  })
  fixitTask;

  @action
  handleEditClick() {
    this.set('readonly', false);
    this.onEdit();
  }

  @action
  handleDoneEditClick() {
    this.set('readonly', true);
    this.onDoneEdit();
  }

  @action
  quoteOrders() {
    if (
      !confirm(
        'Are you sure want to quote all the orders in this meal plan? This will add carts to all the orders based on their requirements.'
      )
    ) {
      return;
    }

    return RSVP.all(
      this.get('mealPlan.mealPlanningReservations').map((reservation) =>
        reservation
          .get('order')
          .then((_) => _.quote())
          .catch((error) => {
            // notify
            this.get('notify').error(error.errors.get('firstObject.title'));

            throw error;
          })
      )
    );
  }

  @action
  handleRestaurantChange() {
    this.handleRestaurantChanged();
    this.incrementProperty('changeCount');
  }

  @action
  async showExplainModal() {
    await this.modals.open(
      'sf/forms/meal-planning-form-for/change-explanation-modal',
      {
        log: this.store.createRecord('meal-planning-log-record', {
          planable: this.mealPlan
        }),
        changeCount: this.changeCount
      },
      { focusTrapOptions: { clickOutsideDeactivates: false } }
    );
    this.set('changeCount', 0);
  }
}
