import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import config from 'star-fox/config/environment';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import { KEYS } from 'renard/models/app-configuration';

@classic
export default class LoggedInRoute extends Route.extend(AuthenticatedRouteMixin) {
  @service
  session;

  @service
  store;

  @service
  pusher;

  @service
  appConfiguration;

  hasInitializedPusher = false;
  authenticationRoute = 'login';

  async model() {
    // guard, TODO: make this a bit more sane
    if (!this.get('hasInitializedPusher')) {
      // pusher does some weird stuff when multiple tests are running
      if (config.environment !== 'test') {
        const options = Object.assign({}, config.pusher.options);
        options.auth.headers.Authorization = `Token token="${this.get(
          'session.data.authenticated.token'
        )}", email="${this.get('session.data.authenticated.email')}"`;

        this.get('pusher').setup(config.pusher.key, options);
        this.set('hasInitializedPusher', true);
      }
    }

    const userId = this.get('session.data.authenticated.user_id');

    const appConfiguration = this.get('appConfiguration');
    await appConfiguration.appConfigurationFetch;

    const capabilities = appConfiguration[KEYS.capabilities];

    // grab the capabilities from the preferences object
    this.set('session.capabilities', capabilities?.get('preferences'));

    // fetch the current user
    return this.store
      .findRecord('user', userId, {
        include: 'roles'
      })
      .then((user) => {
        this.get('session').setProperties({ user });
        return user;
      })
      .catch((_) => {
        console.error(_);
        this.get('session').invalidate();
      });
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    // Subscribe to pusher events,
    // the channelName is defined in the controller.
    controller.setupPusher();

    this.store
      .query('area', {
        page: {
          limit: 100,
          offset: 0
        }
      })
      .then((_) => controller.set('areas', _));
  }

  // Unsubscribe from the pusher channels we've been listening to.
  // Clean up when we leave. We probably don't want  to still be receiving events.
  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);

    controller.teardownPusher();
  }

  @action
  loading(transition, _originRoute) {
    const controller = this.controllerFor('logged-in');
    controller.set('currentlyLoading', transition.targetName);
    transition.promise.finally(function () {
      controller.set('currentlyLoading', null);
    });

    return true;
  }

  @action
  error(response) {
    if (response.errors && response.errors.match && response.errors.match(/not authorized/)) {
      this.get('session').invalidate();
    } else {
      return true;
    }
  }
}
