import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias, union } from '@ember/object/computed';
import { run } from '@ember/runloop';
import { action, computed } from '@ember/object';
import RSVP from 'rsvp';

@classic
export default class EditController extends Controller {
  @service
  notify;

  /** @type {?Array} */
  affectedOrders = null;

  /** @type {?Array} */
  obsoleteOrders = null;

  /**
   * Inhibits editing after a draft has been published by someone else.
   *
   * @type {boolean}
   */
  frozen = false;

  /** @type {?MenuOptionGroup} */
  menuOptionGroup = null;

  /** @type {boolean} */
  isCheckingAffectedOrders = false;

  /** @type {boolean} */
  isDiscarding = false;

  /** @type {boolean} */
  isPublishing = false;

  /**
   * Computed properties
   */

  /** @type {invoicingTaxRate[]} the area's invoicing tax rates */
  @union('model.areas.firstObject.invoicingTaxRates', 'model.globalTaxRates')
  areaTaxRates;

  @computed('affectedOrders.[]')
  get affectedOrdersPresent() {
    return this.get('affectedOrders.length');
  }

  @alias('model.menu')
  menu;

  @alias('model.parentMenu')
  parentMenu;

  @alias('model.restaurant')
  restaurant;

  @alias('model.dietaryTags')
  dietaryTags;

  @alias('model.foodTypes')
  foodTypes;

  @alias('model.mealTypes')
  mealTypes;

  /** @type {invoicingTaxRate} the area's default tax rate */
  @computed('areaTaxRates', 'areaTaxRates.[]')
  get defaultTaxRate() {
    const areaTaxRates = this.get('areaTaxRates');

    return areaTaxRates && areaTaxRates.find((taxRate) => this._isDefaultTaxRate(taxRate));
  }

  /** @override */
  pusherEvents = ['published'];

  /**
   * @override
   * @type Array.[{string}] the name of the channels we subscribe to,
   * currently only ee.food.menus.{id}.model-events
   */
  @computed('menu')
  get pusherChannels() {
    const menuId = this.get('menu.id');

    return [`ee.food.menus.${menuId}.model-events`];
  }

  /** @type {boolean} */
  @computed('isCheckingAffectedOrders')
  get shouldDisableMenu() {
    return this.get('isCheckingAffectedOrders');
  }

  /**
   * Internal functions
   */

  /**
   * Checks what orders were affected by current menu changes.
   */
  _checkAffectedOrders() {
    this.set('isCheckingAffectedOrders', true);
    const parentMenu = this.get('parentMenu');

    if (parentMenu) {
      RSVP.all([
        this.store.query('order', {
          filter: {
            affectedOrders: parentMenu.get('id')
          }
        }),
        this.store.query('order', {
          filter: {
            ordersWithMenu: parentMenu.get('id')
          }
        })
      ])
        .then(([affectedOrders, obsoleteOrders]) => {
          this.set('affectedOrders', affectedOrders);
          const index = affectedOrders.reduce(
            (acc, order) => (acc[order.get('id')] = order && acc),
            {}
          );
          this.set(
            'obsoleteOrders',
            obsoleteOrders.filter((o) => !index[o.get('id')])
          );
        })
        .finally(() => this.set('isCheckingAffectedOrders', false));
    }
  }

  /**
   * @param {MenuItem} menuItem
   * @returns {MenuOptionGroup}
   * @private
   */
  _createMenuOptionGroup(menuItem) {
    return this.store
      .createRecord('menu-option-group', {
        verb: 'pick',
        name: 'A Topping',
        menuItem
      })
      .save();
  }

  /**
   * @param {MenuOptionGroup} menuOptionGroup
   * @returns {MenuOptionItem}
   * @private
   */
  _createMenuOptionItem(menuOptionGroup) {
    return this.store
      .createRecord('menu-option-item', {
        name: 'New Option Item',
        menuOptionGroup,
        clientPriceCents: 0,
        restaurantPriceCents: 0,
        retailPriceCents: 0
      })
      .save();
  }

  /** @type {boolean} whether or not a given tax rate is the area's default */
  _isDefaultTaxRate(taxRate) {
    return taxRate.get('isDefault');
  }

  @action
  checkAffectedOrders() {
    this._checkAffectedOrders();
  }

  @action
  handleOpenMenuOptionItemModal(menuItem) {
    if (!menuItem.get('menuOptionGroups.length')) {
      return this._createMenuOptionGroup(menuItem)
        .then((menuOptionGroup) => this.set('menuOptionGroup', menuOptionGroup))
        .then((_) => this._createMenuOptionItem(this.get('menuOptionGroup')))
        .then((_) => this.set('menuOptionGroup', menuItem.get('menuOptionGroups.firstObject')));
    } else {
      return new RSVP.Promise((resolve) => {
        resolve(this.set('menuOptionGroup', menuItem.get('menuOptionGroups.firstObject')));
      });
    }
  }

  @action
  handleCreateMenuOptionGroup(menuItem) {
    return this._createMenuOptionGroup(menuItem)
      .then((menuOptionGroup) => {
        return this.set('menuOptionGroup', menuOptionGroup);
      })
      .then((_) => {
        return this._createMenuOptionItem(this.get('menuOptionGroup'));
      });
  }

  @action
  handleCreateMenuOptionItem(menuOptionGroup) {
    return this._createMenuOptionItem(menuOptionGroup);
  }

  @action
  addGroup() {
    const menu = this.get('menu');

    this.store
      .createRecord('menu-group', {
        name: 'Your Name Here',
        description: 'Your Description Here',
        menu
      })
      .save();
  }

  @action
  duplicateEntity(entity) {
    return run((_) => {
      return entity
        .duplicate()
        .then((_) => this.get('notify').success(`Duplicated ${entity.get('humanize')}`));
    });
  }

  @action
  createMenuItem(menuGroup) {
    console.debug('RestaurantMenu Controller #createMenuItem', menuGroup);

    this.store
      .createRecord('menu-item', {
        menuGroup,
        name: 'Your Name Here',
        description: 'Your Description Here',
        taxRate: this.get('defaultTaxRate')
      })
      .save();
  }

  @action
  publish() {
    if (confirm('This menu will become LIVE. Do you want to proceed?')) {
      this.set('isPublishing', true);

      this.get('menu')
        .publish()
        .then(() => this.get('notify').success('The menu has been published'))
        .then(() =>
          this.transitionToRoute(
            'logged-in.restaurants.show.menus.index',
            this.get('restaurant.id')
          )
        )
        .catch((errorInstance) => {
          const notifier = this.get('notify');

          (errorInstance.errors || []).forEach((error) => {
            notifier.error(error.detail);
          });
        })
        .finally(() => this.set('isPublishing', false));
    }
  }

  @action
  published() {
    this.set('frozen', true);
  }

  @action
  destroyItem() {
    if (
      confirm(
        'This will delete the draft and ALL YOUR CHANGES WILL BE LOST. Do you want to proceed?'
      )
    ) {
      this.set('isDiscarding', true);

      this.get('menu')
        .destroyRecord()
        .then(() =>
          this.transitionToRoute(
            'logged-in.restaurants.show.menus.index',
            this.get('restaurant.id')
          )
        )
        .finally(() => this.set('isDiscarding', false));
    }
  }
}
