import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';
import PaymentCardValidator from 'star-fox/models/validators/payment-card';
import $ from 'jquery';

export const CardTypeClassMap = {
  visa: 'visa',
  mastercard: 'mastercard',
  amex: 'american express',
  dinersclub: 'diners club',
  discover: 'discover',
  jcb: 'japan credit bureau'
};

@classic
export default class PaymentCard extends Model.extend(PaymentCardValidator) {
  /*
   * Attributes
   */
  @attr('string')
  cardToken;

  @attr('string')
  nameOnCard;

  @attr('string')
  nickname;

  @attr('string')
  billingAddress;

  @attr('string')
  number;

  @attr('string')
  cvc;

  @attr('number')
  expiryMonth;

  @attr('number')
  expiryYear;

  @attr('string')
  addressLine_1;

  @attr('string')
  addressLine_2;

  @attr('string')
  city;

  @attr('string')
  province;

  @attr('string')
  addressCode;

  @attr('string')
  country;

  @attr('string')
  cardType;

  @attr('number')
  lastFourDigits;

  @attr('boolean')
  shared;

  @attr('boolean')
  removed;

  @attr('boolean')
  expired;

  @attr('boolean')
  inUseAndInvalid;

  @attr('boolean')
  expiresOnFutureDelivery;

  /*
   * Relationships
   */
  @belongsTo('client', { inverse: 'paymentCards' })
  client;

  @hasMany('order')
  orders;

  @belongsTo('user', { inverse: 'paymentCards' })
  user;

  /*
   * Services
   */
  @service
  cardService;

  @service
  ajax;

  @service
  notify;

  /**
   * Override save function, helps coalesce the process of getting a Stripe token and
   * saving the credit card data into a single model. This save function calls on the
   * the cardService to save the model, and return a promise with to cache and swap out sensitive card data between save requests.
   * The only data we're going to be sending up is the token cardToken from stripe.
   */
  save(options) {
    const saveMethod = super.save;
    return this.get('cardService').saveCard(this, saveMethod, options);
  }

  /**
   * Override the destroyRecord function so the API can set PaymentCard#removed to true
   */
  destroyRecord() {
    const notify = this.get('notify');

    return this.get('ajax')
      .del(`api/v3/payment-cards/${this.get('id')}`)
      .then((_) => this.store.unloadRecord(this))
      .catch(({ payload }) => {
        if (payload.errors) {
          payload.errors.forEach((err) => notify.error(err.title));
        } else {
          notify.error('An unexpected error occurred');
        }
      });
  }

  get humanize() {
    return `card ${this.get('formattedCCNumber')}`;
  }

  /*
   * Computed properties
   */
  @computed('client.id')
  get ownershipType() {
    return this.get('client.id') ? 'Company Card' : 'Personal Card';
  }

  /** @type {string} */
  @computed('_cardType')
  get icon() {
    const cardClass = CardTypeClassMap[this.get('_cardType')];
    return cardClass ? cardClass : 'credit card alternative';
  }

  /** @type {string} */
  @computed('lastFourDigits')
  get formattedCCNumber() {
    return `•••• •••• •••• ${this.get('lastFourDigits')}`;
  }

  /** @type {string} */
  @computed('formattedCCNumber', 'default', 'nickname')
  get selectLabel() {
    const label = [];
    const nickname = this.get('nickname');

    if (nickname) {
      label.push(`${nickname} - ${this.get('lastFourDigits')}`);
    } else {
      label.push(this.get('formattedCCNumber'));
    }
    if (this.get('default')) {
      label.push('[Default]');
    }

    if (this.get('expired')) {
      label.push('[EXPIRED]');
    } else if (this.get('inUseAndInvalid')) {
      label.push('[INVALID]');
    }

    return label.join(' ');
  }

  /** @type {string} */
  @computed('expiryMonth', 'expiryYear')
  get formattedExpiry() {
    return `Exp ${this.get('expiryMonth')} /  ${this.get('expiryYear')}`;
  }

  /** @type {object} Returns an object that specifies the correct validation options for the card based on cardTypeDetected */
  @computed('cardTypeDetected')
  get validationOptions() {
    const cardHolder = this.get('client.content') || this.get('user.content');

    if (cardHolder && cardHolder.hasFeatureEnabled('amex')) {
      return this.get('cardTypeDetected') !== 'amex'
        ? { only: ['expiryMonth', 'expiryYear', 'cvc', 'number'] }
        : {};
    } else {
      return { only: ['expiryMonth', 'expiryYear', 'cvc', 'number'] };
    }
  }

  /** @type {string} */
  @computed('number')
  get cardTypeDetected() {
    return $.payment.cardType(this.get('number'));
  }
}
