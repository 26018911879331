import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import Component from '@ember/component';
import { action } from '@ember/object';
import RSVP from 'rsvp';

@classic
@classNames('fde-invoices')
export default class ClientAndRestaurantInvoices extends Component {
  /** @type {Order} */
  order = null;

  /** @type {string} */
  invoiceType = 'client';

  /** @type {ClientOrderInvoice || RestaurantOrderInvoice} */
  invoice = null;

  /** @type {boolean} Whether or not the client invoice tab is active */
  showingClientInvoice = true;

  /** @type {boolean} */
  isLoading = false;

  willInsertElement() {
    super.willInsertElement(...arguments);
    this._fetchInvoice('client');
  }

  @observes('order')
  orderDidChange() {
    this._fetchInvoice('client');
  }

  /**
   * @param {string}
   * Gets order.clientOrderInvoice or order.restaurantOrderInvoice based on invoiceType
   */
  _fetchInvoice(invoiceType) {
    if (!this.get('isDestroyed')) {
      this.set('isLoading', false);
    }

    const invoicePromise = this.get(`order.${invoiceType}OrderInvoice`) || RSVP.resolve(null);

    invoicePromise
      .then((invoice) => {
        if (this.get('isDestroyed')) {
          return;
        }
        this.setProperties({
          invoice: invoice,
          showingClientInvoice: invoiceType === 'client',
          invoiceType: invoiceType
        });
      })
      .finally(() => {
        if (!this.get('isDestroyed')) {
          this.set('isLoading', false);
        }
      });
  }

  @action
  setClientInvoice() {
    this._fetchInvoice('client');
  }

  @action
  setRestaurantInvoice() {
    this._fetchInvoice('restaurant');
  }
}
