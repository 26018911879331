import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action } from '@ember/object';
import $ from 'jquery';

@classic
export default class CustomItemModal extends Component {
  /** @type {?OrderItem[]} */
  orderItemToEdit = null;

  /** @type {?OrderItemOptions[]} */
  orderItemOptions = null;

  /** @type {boolean} */
  orderItemOptionsPresent = false;

  /** @type {?String} */
  customItemSelector = null;

  /** @type {?String} */
  customPriceMessage = null;

  /** @type {Function} */
  handleOrderItemOptionsSave() {}

  /** @type {Function} */
  handleOrderItemOptionsFailToSubmit() {}

  /** @type {Function} */
  afterCustomItemSave() {}

  /** @type {Function} */
  afterResetCustomItem() {}

  /** @type {Function} */
  toggleNotes() {}

  @action
  closeModal() {
    const modalSelector = this.get('customItemSelector').replace(/ /g, '.');
    $(`.${modalSelector}`).modal('hide');
  }
}
