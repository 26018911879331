import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class AccountingLineItem extends Model {
  @attr('number')
  quantity;

  @attr('number')
  unitAmount;

  @attr('number')
  netAmount;

  @attr('number')
  taxAmount;

  @attr('string')
  taxRate;

  @attr('number')
  totalAmount;

  @attr('string')
  name;

  @attr('string')
  notes;

  @attr('string')
  description;

  @attr('object')
  metaData;

  @belongsTo('accounting-ledger-item', { inverse: 'lineItems' })
  ledgerItem;
}
