import classic from 'ember-classic-decorator';
import { oneWay } from '@ember/object/computed';
import { action, get } from '@ember/object';
import Controller from '@ember/controller';
import RSVP from 'rsvp';

@classic
export default class IndexController extends Controller {
  /** @property {number} */
  @oneWay('model.meta.recordCount')
  recordCount;

  /** @property {number} pageLimit - records per page */
  pageLimit = 20;

  /** @property {number} pageOffset - current page */
  pageOffset = 0;

  queryParams = ['pageLimit', 'pageOffset', 'orderId'];
  orderId = null;

  @action
  clearResolved() {
    RSVP.all(
      (get(this, 'model') ?? []).filter((_) => _.get('isResolved')).map((_) => _.destroyRecord())
    ).then((_) => this.send('refreshModel'));
  }
}
