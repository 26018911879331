import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class FeedRoute extends Route {
  userShowRoute = 'logged-in.users.show';

  model() {
    return this.modelFor(this.userShowRoute);
  }
}
