import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from 'renard/models/foodee';
import moment from 'moment-timezone';

@classic
export default class DriverWeek extends Model {
  @attr('date')
  createdAt;

  @attr('date')
  updatedAt;

  @belongsTo('user')
  driver;

  @attr('date-only')
  monday;

  @computed('monday')
  get sunday() {
    return moment(this.get('monday')).add(6, 'days');
  }

  @attr('string')
  notes;

  @attr('boolean', { defaultValue: false })
  availableMondayBreakfast;

  @attr('boolean', { defaultValue: false })
  availableMondayLunch;

  @attr('boolean', { defaultValue: false })
  availableMondayDinner;

  @attr('boolean', { defaultValue: false })
  availableTuesdayBreakfast;

  @attr('boolean', { defaultValue: false })
  availableTuesdayLunch;

  @attr('boolean', { defaultValue: false })
  availableTuesdayDinner;

  @attr('boolean', { defaultValue: false })
  availableWednesdayBreakfast;

  @attr('boolean', { defaultValue: false })
  availableWednesdayLunch;

  @attr('boolean', { defaultValue: false })
  availableWednesdayDinner;

  @attr('boolean', { defaultValue: false })
  availableThursdayBreakfast;

  @attr('boolean', { defaultValue: false })
  availableThursdayLunch;

  @attr('boolean', { defaultValue: false })
  availableThursdayDinner;

  @attr('boolean', { defaultValue: false })
  availableFridayBreakfast;

  @attr('boolean', { defaultValue: false })
  availableFridayLunch;

  @attr('boolean', { defaultValue: false })
  availableFridayDinner;

  @attr('boolean', { defaultValue: false })
  availableSaturdayBreakfast;

  @attr('boolean', { defaultValue: false })
  availableSaturdayLunch;

  @attr('boolean', { defaultValue: false })
  availableSaturdayDinner;

  @attr('boolean', { defaultValue: false })
  availableSundayBreakfast;

  @attr('boolean', { defaultValue: false })
  availableSundayLunch;

  @attr('boolean', { defaultValue: false })
  availableSundayDinner;
}
