import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';
import { modelAction } from 'ember-custom-actions';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

@classic
export default class Team extends Model {
  /*
   * Attributes
   */
  @attr('string')
  name;

  @attr('boolean')
  isActive;

  @attr('boolean')
  isDefault;

  @fragmentArray('fragments/meal-planning/dietary-group', { default: [] })
  dietaryComposition;

  /*
   * Relationships
   */
  @belongsTo('client')
  client;

  @hasMany('user')
  members;

  @hasMany('user-invite')
  userInvite;

  @hasMany('order')
  orders;

  /*
   * Properties
   */
  /** @type {Service} */
  @service
  ajax;

  /*
   * Functions & Non Crud
   */

  /**
   * @param {TeamUser} teamUser
   * @returns {Promise}
   * Needed to customize the payload to remove related to_many record.
   */
  removeTeamUser(teamMember) {
    const team = this;
    const modelName = this.constructor.modelName;
    const adapter = this.store.adapterFor(modelName);
    const uri = `${adapter.buildURL(modelName, team.get('id'))}/relationships/members`;

    const teamMemberId = teamMember.get('id');
    const data = [{ type: 'users', id: teamMemberId }];

    return this.get('ajax')
      .deleteJSONAPI(uri, data)
      .then((_) => this.get('members').removeObject(teamMember));
  }

  /**
   * @param {User} existingUser
   * @returns {Promise} UserInvite
   */
  addTeamUser(existingUser) {
    const userEmail = [];
    userEmail.push(existingUser.get('email'));

    const data = { emails: userEmail };

    return this.inviteUsers({ data });
  }

  /**
   * @param {String[]} emails
   * @param {Boolean} inviteAsAdmin
   * @returns {Promise} UserInvites
   */
  bulkAddTeamUsers(emails, inviteAsAdmin) {
    const data = { emails, inviteAsAdmin };

    return this.inviteUsers({ data });
  }

  inviteUsers = modelAction('members', {
    method: 'POST'
  });

  /*
   * Validations
   */
  validations = {
    name: {
      presence: {}
    }
  };
}
