import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { alias } from 'macro-decorators';
import { string } from 'prop-types';
import { arg } from 'ember-arg-types';
import { Enum, is, isNot } from 'renard/utils/enum';

class JobStatus extends Enum {
  static Done = new JobStatus('done');
  static Queued = new JobStatus('queued');
  static Working = new JobStatus('working');
  static ErrorWillRetry = new JobStatus('error_will_retry');
  static ErrorNoRetry = new JobStatus('error_no_retry');

  @is isDone;
  @is isQueued;
  @is isWorking;
  @is isErrorWilLRetry;
  @is isErrorNoRetry;

  @isNot isNotDone;
  @isNot isNotQueued;
  @isNot isNotWorking;
  @isNot isNotErrorWillRetry;
  @isNot isNotErrorNoRetry;

  get isError() {
    return this.isErrorWilLRetry || this.isErrorNoRetry;
  }
}

export default class SfJobStatusComponent extends Component {
  @arg(string.required) model;
  @arg(string.required) title;
  @arg(string) subTitle;
  @service ajax;
  @service actionCable;
  @service store;

  @alias('model.jid') jid;

  @tracked status = {};
  @tracked jobStatus = 'queued';
  @tracked percentComplete = 0;

  @tracked abort = false;

  constructor(...args) {
    super(...args);
    this.wire();
  }

  get complete() {
    return this.percentComplete === '100';
  }

  @action
  async wire() {
    this.actionCable.wire(this, `ee.food.job-status.${this.jid}`, [
      'at',
      'done',
      'error_will_retry',
      'error_no_retry'
    ]);
    this.status = await this.ajax.request(`/job-status/${this.jid}`);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.actionCable.unwire(this, `ee.food.job-status.${this.jid}`, [
      'at',
      'done',
      'error_will_retry',
      'error_no_retry'
    ]);
  }

  @action
  async cancel() {
    await this.ajax.request(`/job-status/${this.jid}`, { method: 'DELETE' });
    this.model.jid = null;
    await this.model.save();
  }

  @action
  async at(status) {
    console.info(`[job-status]`, status);
    this.updateFromStatus(status);
  }

  @action
  async done(payload) {
    if (this.model.jobDone) {
      this.model.jobDone();
    }
    console.info(`[job-status] done: `, payload);
    if (payload) {
      this.store.pushPayload(payload);
    } else {
      this.model.reload();
    }
  }

  @action
  async errorWillRetry(error) {
    console.info(`[error-will-retry] ${error}`);
    this.updateFromStatus({
      status: JobStatus.ErrorWillRetry,
      message: error
    });
  }

  @action
  async errorNoRetry(error) {
    console.info(`[error-no-retry] ${error}`);
    this.updateFromStatus({
      status: JobStatus.ErrorNoRetry,
      message: error
    });
  }

  updateFromStatus(status) {
    this.status = status;
    this.percentComplete = status.pct_complete ?? '0';
    this.jobStatus = JobStatus.valueFor(status.status) ?? status.status;
  }
}
