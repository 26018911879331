import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@tagName('')
export default class OrderCard extends Component {
  /** @type {DS.Model} **/
  record = null;

  /** @type {Order} **/
  @alias('record')
  order;
}
