import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { inject as controller } from '@ember/controller';
import { action, computed } from '@ember/object';
import resourceTimeLinePlugin from '@fullcalendar/resource-timeline';
import shiftLuminance from '../../../../utils/shift-luminance';

const COLORS = {
  draft: '#DCDDDE',
  group_building: '#084ed0',
  group_locked: '#fd00ff',
  pre_quote: '#BBBBBB',
  quoted: '#FF5C5C',
  client_rejected: '#BB0000',
  submitted: '#FF8B30',
  finalized: '#F8D91C',
  confirmed: '#699F52',
  reported: '#8466C4',
  restaurant_rejected: '#BB0000',
  rejected: '#BB0000',
  scheduled: '#981C5B',
  driver_confirmed: '#8DD029',
  driver_at_restaurant: '#7AB9D2',
  picked_up: '#7AB9D2',
  driver_at_client: '#6D4F48',
  delivered: '#6D4F48',
  ready_to_eat: '#6D4F48',
  closed: '#AB6D17',
  cancelled: '#141617',
  payment_processing: '#1FC6BC',
  payment_failed: '#F01300'
};

@classic
export default class CalendarController extends Controller {
  @service
  notify;

  /** @type {boolean} **/
  plan = 'All';

  /** @type {string} **/
  slotMinTime = '06:00:00';

  /** @type {string} **/
  slotMaxTime = '23:00:00';

  /** @type {integer} **/
  defaultSlotMinWidth = 50;

  /** @type {integer} **/
  currentSlotMinWidth = this.defaultSlotMinWidth;

  /** @type {array} **/
  fullCalendarPlugins = [resourceTimeLinePlugin];

  /** @type {boolean} **/
  readonlyActionStateButton = false;

  /** @type {Controller} **/
  @controller('logged-in.planning')
  parent;

  pusherModelQueryParams = {
    MealPlanningReservation: {
      include: 'restaurant,order.restaurant'
    }
  };

  /**
   * @type {string[]}
   */
  @computed('area')
  get pusherChannels() {
    const area = this.get('area');

    let ret = [];

    if (area) {
      ret = [`ee.food.areas.${area}.model-events`];
    }

    return ret;
  }

  /** @type {Area} **/
  @alias('model.area')
  area;

  /** @type {Object[]} **/
  @computed('model.records')
  get restaurants() {
    return this.get('model.records')
      .filter((reservation) => reservation.get('restaurant.active'))
      .map((reservation) => ({
        id: reservation.get('restaurant.id'),
        title: reservation.get('restaurant.name')
      }))
      .uniqBy('id');
  }

  /** @type {Object[]} **/
  @computed('model.records.@each.updatedAt', 'selectedRecord', 'currentSlotMinWidth', 'plan')
  get events() {
    return (
      this.get('model.records')
        // filter cancelled and converted
        .filter((reservation) => !['converted', 'cancelled'].includes(reservation.get('state')))
        .filter((reservation) => {
          const plan = this.get('plan');

          return plan === 'All' || reservation.get('client.plan') === plan.toLowerCase();
        })
        .map((reservation) => ({
          start: reservation.get('pickupAt'),
          end: reservation.get('deliverAt'),
          title: reservation.get('identifier'),
          resourceId: reservation.get('restaurant.id'),
          type: reservation.get('constructor.modelName'),
          id: reservation.get('id'),
          color:
            reservation === this.get('selectedRecord')
              ? shiftLuminance(COLORS[reservation.get('state')], -10)
              : COLORS[reservation.get('state')],
          borderColor:
            reservation === this.get('selectedRecord') ? 'black' : COLORS[reservation.get('state')]
        }))
    );
  }

  @action
  selectEvent(calendarEvent) {
    const reservation = this.store.peekRecord(
      calendarEvent.event.extendedProps.type,
      calendarEvent.event.id
    );

    this.set('selectedRecord', reservation);
  }

  @action
  created(data) {
    super.created(data);

    if (data.type === 'MealPlanningReservation' || data.type === 'Order') {
      // load the record and add it to our array
      this.store
        .findRecord(data.type, data.id, {
          include: 'client',
          fields: {
            clients: 'account-name,plan'
          }
        })
        .then((_) => this.get('model.records').pushObject(_));
    }
  }

  @action
  zoomIn() {
    // changing property here is a function from Ember object
    this.incrementProperty('currentSlotMinWidth', 10);
  }

  @action
  zoomOut() {
    // changing property here is a function from Ember object
    this.decrementProperty('currentSlotMinWidth', 10);
  }

  @action
  resetZoom() {
    this.set('currentSlotMinWidth', this.defaultSlotMinWidth);
  }
}
