import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';

@classic
export default class CapabilitiesController extends Controller {
  /** @type {boolean} whether or not a new action has been added since the save */
  hasAddedAction = false;

  /** @type {boolean} whether or not an action has been deleted since the save */
  hasDeletedAction = false;

  /** @type {string} value for new actions */
  newActionName = null;

  /** @type {string[]} list of roles by name */
  @computed('model.roles')
  get roles() {
    return this.get('model.roles')
      .mapBy('name')
      .filter((_) => !['client', 'meal_plan_user'].includes(_));
  }

  /** @type {string[]} list of action keys from the preferences sorted alphabetically */
  @computed('model.configuration.preferences')
  get actionKeys() {
    const prefs = this.get('model.configuration.preferences');

    return Object.keys(prefs).sort((a, b) => a.localeCompare(b));
  }

  @action
  unifyKeysAndActions() {
    const prefs = this.get('model.configuration.preferences');
    const newPref = Object.values(prefs).reduce((acc, capability) => {
      acc[capability.action] = capability;

      return acc;
    }, {});

    this.set('model.configuration.preferences', newPref);

    return true;
  }

  @action
  addAction() {
    const newActionName = this.get('newActionName');
    this.set(`model.configuration.preferences.${newActionName}`, {
      action: newActionName,
      roles: []
    });

    this.setProperties({
      newActionName: null,
      hasAddedAction: true
    });

    // force a recompute of action keys
    this.notifyPropertyChange('model.configuration.preferences');
  }

  @action
  removeAction(actionName) {
    const prefs = this.get(`model.configuration.preferences`);
    delete prefs[actionName];

    // force a recompute of action keys
    this.notifyPropertyChange('model.configuration.preferences');

    this.set('hasDeletedAction', true);
  }

  @action
  resetForms() {
    this.setProperties({
      hasDeletedAction: false,
      hasAddedAction: false,
      newActionName: null
    });
  }
}
