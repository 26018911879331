import classic from 'ember-classic-decorator';
import Transform from '@ember-data/serializer/transform';

@classic
export default class Dollars extends Transform {
  deserialize(serialized) {
    return serialized ? serialized / 100 : null; // returns cents
  }

  serialize(deserialized) {
    return deserialized ? deserialized * 100 : null; // returns cents
  }
}
