import { next } from '@ember/runloop';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';

export default Controller.extend({
  /**
   * Query params for controller.
   */
  queryParams: ['area', 'monday'],

  // TODO figure out a nice way to bundle this up into one thing
  /** @type {number} */
  area: null,

  /** @type {Area} */
  areaModel: computed('area', {
    get: function () {
      return this.get('area') ? this.store.peekRecord('area', this.get('area')) : null;
    },
    set: function (key, value) {
      next(() => this.set('area', value ? value.get('id') : null));
    }
  }),

  /** @type {Date} */
  monday: moment().startOf('isoWeek').add(1, 'week').toDate(),

  title: computed('monday', function () {
    return this.monday
      ? `Planning week of ${moment(this.monday).format('MMMM Do YYYY')}`
      : 'Planning';
  }),

  thisMonday: computed('monday', function () {
    return moment().startOf('week').add(1, 'days').format('YYYY-MM-DD');
  }),

  prevMonday: computed('monday', function () {
    return moment(this.get('monday')).subtract(7, 'days').format('YYYY-MM-DD');
  }),

  nextMonday: computed('monday', function () {
    return moment(this.get('monday')).add(7, 'days').format('YYYY-MM-DD');
  }),

  selectDate: action(function (date) {
    this.set('monday', moment(date).startOf('isoWeek').format('YYYY-MM-DD'));
  })
});
