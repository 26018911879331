import classic from 'ember-classic-decorator';
import Model, { attr } from 'renard/models/foodee';
import { computed } from '@ember/object';
import moment from 'moment-timezone';

@classic
export default class IntegrationLogRecordByIntegrationName extends Model {
  /*
   * Attributes
   */

  @attr('number')
  total;

  @attr('number')
  success;

  @attr('number')
  failure;

  @attr('number')
  successPercentage;

  @attr('number')
  failurePercentage;

  @attr('number')
  standardDeviation;

  @attr('number')
  configuredThreshold;

  @attr('number')
  expectedMinimumThreshold;

  @attr('string')
  integrationName;

  @attr('date')
  createdAt;

  @computed('createdAt')
  get formattedDate() {
    return moment.tz(this.createdAt, 'UTC').format('MMMM Do');
  }
}
