import classic from 'ember-classic-decorator';
import { copy } from '@ember/object/internals';
import RSVP from 'rsvp';
import { set } from '@ember/object';
import SearchAction from './base';

@classic
export default class OrderAndAdminContact extends SearchAction {
  /** @override */
  search(query, options, resource) {
    const queryOptions = copy(options);

    queryOptions.filter = queryOptions.filter || {};
    queryOptions.page = options.page || { offset: 0, limit: 8 };
    queryOptions.sort = options.sort || '-updatedAt';
    queryOptions.reload = true;

    set(queryOptions.filter, 'name', query);

    return RSVP.Promise.all([
      resource.query('orderContacts', copy(queryOptions)),
      resource.query('adminContacts', copy(queryOptions))
    ]).then(([orderContacts, adminContacts]) => {
      return orderContacts.toArray().concat(adminContacts.toArray()).uniq();
    });
  }
}
