import Fixture, {
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class SfLogisticsExpandedRowEstimateLogFixture extends Fixture {
  constructor() {
    super('data-test-expanded-row-estimate-log');
  }
}

export const sfLogisticsExpandedRowEstimateLog = getElementDecoratorFor(
  SfLogisticsExpandedRowEstimateLogFixture
);
export const sfLogisticsExpandedRowEstimateLogWithValue = getElementWithValueDecoratorFor(
  SfLogisticsExpandedRowEstimateLogFixture
);
