import { Promise } from 'rsvp';

/**
 * Allows RSVP's catch method to take any number of Error type constructors preceding the fn and
 * label params. This allows you to filter your catches based on error type
 * allowing for better control flow with promises.
 * e.g. promise.catch(ErrorType1, ErrorType2, function() {...})
 */
function rsvpCustomCatch() {
  let _catch = Promise.prototype.catch;

  Promise.prototype.catch = function (fn, label) {
    let errorType;

    if (arguments[0].prototype instanceof Error) {
      errorType = arguments[0];
      fn = arguments[1];
      label = arguments[2];

      return _catch.call(
        this,
        function (error) {
          if (!(error instanceof errorType)) {
            throw error;
          } else {
            return fn.apply(this, arguments);
          }
        },
        label
      );
    } else {
      fn = arguments[0];
      label = arguments[1];
      return _catch.call(this, fn, label);
    }
  };
}

export function initialize() {
  rsvpCustomCatch();
}

export default {
  name: 'rsvp-custom-catch',
  initialize: initialize
};
