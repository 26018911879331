import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class Company extends Application {
  attrs = {
    clientCount: {
      serialize: false
    }
  };
}
