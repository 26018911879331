import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Model, { attr, hasMany } from 'renard/models/foodee';
import ConstraintTagMixin from 'star-fox/mixins/constraint-tag-mixin';

/**
 * @class DietaryTag
 * @implements ConstraintTag
 */
@classic
export default class DietaryTag extends Model.extend(ConstraintTagMixin) {
  /*
   * Attributes
   */

  /** @type {string} */
  @attr('string')
  name;

  /** @type {string} */
  @attr('string')
  foodLabelName;

  /** @type {string} */
  @attr('string')
  slug;

  /** @type {string} */
  @attr('string', { defaultValue: '' })
  abbreviation;

  /** @type {string} */
  @attr('string', { defaultValue: '#000000' })
  color;

  /** @type {string} */
  @attr('string')
  tagType;

  /**
   * Computed Properties
   */
  @computed('code')
  get humanize() {
    return `Dietary Tag '${this.get('name')}'`;
  }

  @computed('abbreviation')
  get text() {
    const abbreviation = this.get('abbreviation');
    return abbreviation === 'fv' ? '♥' : abbreviation.toUpperCase();
  }

  @alias('name')
  label;

  /*
   * Relationships
   */
  @hasMany('menu-option-item')
  menuOptionItems;

  /** @override Implementing ConstraintTag matches method */
  matches(menuItem) {
    return (
      menuItem.get('dietaryTags').includes(this) ||
      menuItem.get('menuOptionItemDietaryTags').includes(this)
    );
  }

  /*
   * Validations
   */
  validations = {
    name: {
      presence: {}
    },
    slug: {
      presence: {}
    },
    abbreviation: {
      presence: {}
    },
    color: {
      length: 7,
      presence: {},
      format: {
        with: /^#[a-fA-F0-9]+$/,
        allowBlank: true,
        message: 'must be a valid hex colour (0-F)'
      }
    }
  };
}
