import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import $ from 'jquery';
import Component from '@ember/component';
import RSVP, { all } from 'rsvp';

@classic
@tagName('')
export default class MenuForm extends Component {
  @service
  session;

  /** @type {boolean} */
  readonly = false;

  /** @type {Service} */
  @service
  formFor;

  /** @type {string} the key for the menuGroups relationship */
  menuGroupsKey = 'menuGroups';

  /** @type {DietaryTag[]} */
  availableDietaryTags = null;

  /** @type {MealType[]} */
  availableMealTypes = null;

  /** @type {FoodType[]} */
  availableFoodTypes = null;

  /** @type {MenuItem} */
  menuItemToEdit = null;

  /** @type {MenuOptionGroup} */
  menuOptionGroup = null;

  /** @type {Object} Ember jQuery object representing the menu options modal */
  menuOptionModal = {};

  /** @type {boolean} whether or not a group is being dragged **/
  isDraggingGroup = false;

  /** @type {function} checks what orders are affected by menu changes */
  checkAffectedOrders() {}

  /** @type {function} callback for when the add menu-group is pressed */
  onAddGroup() {}

  /** @type {function} callback for when the menu-group sorting changes */
  onMenuGroupChange() {}

  onCreateMenuOptionGroup() {}
  onCreateMenuOptionItem() {}
  onOpenMenuOptionItemModal() {}

  /** @type {Function} Sets a new modal for a menu option groups and items */
  _setMenuOptionsModal() {
    this.set(
      'menuOptionModal',
      $('#edit-menu-item-options-modal').modal({
        closable: false,

        context: '.ember-application',

        onHide: () => {
          const isDirty = this._isMenuOptionGroupDirty();

          if (isDirty) {
            this._notifyMenuOptionGroupIsDirty();
          }

          return !isDirty;
        },

        onHidden: () => {
          next((_) => {
            this.setProperties({
              menuItemToEdit: null,
              menuOptionGroup: null
            });
          });
        },
        duration: 200
      })
    );
  }

  /**
   * Opens the menu options edit modal
   *
   * @returns {RSVP.Promise}
   */
  _openMenuItemOptionsModal() {
    return new RSVP.Promise((resolve) => {
      resolve(next((_) => this.get('menuOptionModal').modal('show')));
    });
  }

  /**
   * Closes the menu options edit modal
   *
   * @returns {RSVP.Promise}
   */
  _closeMenuItemOptionsModal() {
    return new RSVP.Promise((resolve) => {
      resolve(next((_) => this.get('menuOptionModal').modal('hide')));
    });
  }

  /**
   * Destroys the menu item and all the menu option groups associations
   *
   * @returns {RSVP.Promise}
   */
  _destroyAllMenuOptions() {
    const formFor = this.get('formFor');
    const menuOptionGroups = this.get('menuItemToEdit.menuOptionGroups');

    return formFor
      .confirmDestroy(menuOptionGroups.get('firstObject'))
      .then(() => all(menuOptionGroups.invoke('destroyRecord')))
      .then(() => this._closeMenuItemOptionsModal())
      .then(() => this.checkAffectedOrders())
      .catch((error) => console.debug(error));
  }

  /**
   * Checks if the currently selected menu option group has dirty attributes
   *
   * @returns {boolean}
   */
  _isMenuOptionGroupDirty() {
    return this.get('menuOptionGroup.hasDirtyAttributes');
  }

  /**
   * Deploys a toaster message for unsaved changes
   */
  _notifyMenuOptionGroupIsDirty() {
    const formFor = this.get('formFor');
    formFor.notifyError(`You have unsaved changes.`);
  }

  /**
   * Destroys a single menu option group
   *
   * @param {MenuOptionGroup}
   * @returns {RSVP.Promise}
   */
  _destroyMenuOptionGroup(menuOptionGroup) {
    const formFor = this.get('formFor');

    return formFor
      .confirmDestroy(menuOptionGroup)
      .then((_) => this.set('menuOptionGroup', null))
      .catch((_) => console.debug(_));
  }

  didRender() {
    super.didRender(...arguments);
    this._setMenuOptionsModal();
  }

  didDestroyElement() {
    super.didDestroyElement(...arguments);

    next((_) => {
      // Need to remove a modal when exiting this component or
      // else, on reentry, another modal is created and causes
      // an unpleasant chaos.
      $('#edit-menu-item-options-modal').remove();
    });
  }

  @action
  handleDestroyAllMenuOptions() {
    return this._destroyAllMenuOptions();
  }

  @action
  handleCreateMenuOptionGroup(menuItem) {
    const isDirty = this._isMenuOptionGroupDirty();
    return isDirty ? this._notifyMenuOptionGroupIsDirty() : this.onCreateMenuOptionGroup(menuItem);
  }

  @action
  handleEditMenuItem(menuItem) {
    this.set('menuItemToEdit', menuItem);

    return this.onOpenMenuOptionItemModal(menuItem)
      .then((_) => this._openMenuItemOptionsModal())
      .catch((error) => console.debug(error));
  }

  @action
  handleSelectMenuOptionGroup(menuOptionGroup) {
    const isDirty = this._isMenuOptionGroupDirty();

    // Allows user to delete the currently selected menu option group, even if dirty
    if (isDirty && menuOptionGroup.get('id') != this.get('menuOptionGroup.id')) {
      return this._notifyMenuOptionGroupIsDirty();
    }

    this.set('menuOptionGroup', menuOptionGroup);

    if (event.target.className == 'icon trash') {
      this._destroyMenuOptionGroup(menuOptionGroup);
    }
  }

  @action
  finishEditing() {
    return this._closeMenuItemOptionsModal();
  }

  @action
  handleDragStart() {
    this.toggleProperty('isDraggingGroup');
  }

  @action
  handleDragStop() {
    this.toggleProperty('isDraggingGroup');
  }
}
