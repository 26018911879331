import classic from 'ember-classic-decorator';
import Transform from '@ember-data/serializer/transform';
import { ActionTypeConversionMap } from '../models/rules-action';

@classic
export default class ActionConfiguration extends Transform {
  deserialize(value) {
    const ret = Object.entries(ActionTypeConversionMap).find((e) => e[1] == value);

    if (!ret) {
      throw new Error(`${value} is not a valid action configuration when deserializing`);
    }

    return ret[0];
  }

  serialize(value) {
    const ret = ActionTypeConversionMap[value];

    if (!ret) {
      throw new Error(`${value} is not a valid action configuration when serializing`);
    }

    return ret;
  }
}
