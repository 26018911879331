import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-order-teams-team-member')
@tagName('')
export default class TeamMember extends Component {
  /** @type {User} */
  teamMember = null;

  /** @type {Order} */
  order = null;

  /** @type {Team} */
  orderTeam = null;

  /** @type {boolean} */
  canAddGroupOrderMember = false;

  /** @type {boolean} */
  enableReminder = false;

  /** @type {Object} */
  gomMeta = null;

  handleAddGroupOrderMemberClick() {}
  triggerShareNotification() {}
}
