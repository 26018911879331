import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import EmberObject, { action } from '@ember/object';
import Controller from '@ember/controller';

const SAML = 'saml';

@classic
export default class MealPlanController extends Controller {
  /**
   * Container for tranche configuration
   * @type {Object}
   */
  csv = null;

  isDeleting = false;
  nullModel = null;

  @alias('model.mealPlan')
  mealPlan;

  @alias('mealPlan.customFields')
  customFields;

  @alias('mealPlan.client')
  client;

  passwordType = 'password';

  init() {
    super.init(...arguments);

    this.csv = EmberObject.create({
      date: new Date()
    });

    this.nullModel = EmberObject.create();
  }

  @action
  didCancel() {
    const mealPlan = this.get('model.mealPlan');

    if (mealPlan.get('isNew')) {
      mealPlan.deleteRecord();
    }

    this.transitionToRoute('logged-in.clients.edit', this.get('model.client.id'));
  }

  @action
  downloadTrancheCSV() {
    const date = this.get('csv.date');
    this.get('model.mealPlan').fetchMealPlanCSV(date);
  }

  @action
  fetchSAMLSettings() {
    const mealPlan = this.get('model.mealPlan');

    return mealPlan.fetchSAMLSettings().then(() => mealPlan.reload());
  }

  @action
  removeSAMLSettings() {
    if (
      confirm(
        'This will delete the SAML settings and MAKE THE USERS UNABLE TO USE SSO, do you want to proceed?'
      )
    ) {
      this.set('isDeleting', true);
      const mealPlan = this.get('model.mealPlan');

      mealPlan.set('samlSettings', {});
      mealPlan.set('ssoType', 'none');
      mealPlan.save().finally(() => this.set('isDeleting', false));
    }
  }

  @action
  uploadSAMLSettings() {
    const mealPlan = this.get('model.mealPlan');
    mealPlan.set('ssoType', SAML);
    return mealPlan.save();
  }
}
