import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';

@classic
export default class IndexRoute extends Route.extend(AuthenticatedRouteMixin, LoadingRouteMixin) {
  bubblesLoadingEvent = true;
  orderEditRoute = 'logged-in.orders.edit';

  /** @override */
  model() {
    return this.modelFor(this.get('orderEditRoute'));
  }

  afterModel() {
    return this.controllerFor(`${this.get('orderEditRoute')}.index`).fetchDiscountCodes();
  }

  /** @override */
  setupController(controller, model) {
    super.setupController(controller, model);

    if (!this.get('isIndexTypeRouteRefreshing')) {
      /*
       * These functions query the order's email messages,
       * allowing us to paginate and separate them by messageType.
       * They have to be called in a promise chain, otherwise the model
       * caches and doesn't send both requests.
       */
      controller.fetchNotificationLogs();
      controller.fetchAreaClients();
      controller.fetchClientAdmins();
      controller.fetchClientContacts();
      controller.fetchDeliveryLocations();
      controller.fetchAreaRestaurants();
    }

    //Always setup pusher on a setup since the teardown is always happening on reset
    controller.setupPusher();
  }

  // Unsubscribe from the pusher channels we've been listening to.
  // Clean up when we leave. We probably don't want  to still be receiving events.
  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);

    controller.teardownPusher();
  }
}
