import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias, map, notEmpty } from '@ember/object/computed';
import { guidFor } from '@ember/object/internals';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@tagName('')
export default class ReviewEmail extends Component {
  /** @type {Notify} */
  @service
  notify;

  /** @type {NotificationLogsService} */
  @service('notification-logs')
  notificationLogsService;

  /** @type {Boolean} If there's a notification log then we show a warning */
  @notEmpty('notificationLogs.[]')
  hasEmailBeenSent;

  /** @type {NotificationLog.[]} */
  notificationLogs = [];

  /** @type {string} Finds the unique Id of the ember modal. Ex Ember-177-client-mail-modal */
  mealPlanningReviewEmailModalId = `${guidFor(this)}-meal-planning-email-options-modal`;

  /** @type {String} Message sent along with meal planning review email */
  mealPlanningReviewMessage = '';

  /** @type {String} Message sent when informing team members of a live portal email */
  mealPlanningLivePortalMessage = '';

  /** @type {String[]} Array of email addresses */
  mealPlanningReviewSendToEmails = [];

  /** @type {?String} A properly formatted email, hopefully. */
  customEmail = null;

  /** @type {Client} This instance's client */
  @alias('instance.client')
  client;

  /** @type {User[]} List of all the client admins */
  @alias('client.admins')
  admins;

  /** @type {String[]} List of all the admin emails */
  @map('admins', function (admin) {
    return admin.get('email');
  })
  adminEmails;

  /** @type {Boolean} Set to true when awaiting for a response */
  isLoading = false;

  /** @type {Boolean} Set to true when notification logs exit and
   * the proper passphrase hasn't been entered.
   */
  @computed('notificationLogs', 'passphraseEntered')
  get isSendingEmailDisabled() {
    return (
      this.get('notificationLogs.length') != 0 &&
      this.get('passphraseEntered') != this.get('passphrase')
    );
  }

  /** @type {MealPlanningInstance} */
  instance = null;

  /** @type {Boolean} When true the email modal is showing */
  isShowing = false;

  /** @type {Boolean} Determines if the button that opens the email modal is clickable */
  isDisabled = false;

  /** @type {String} */
  passphrase = 'I know this email was already sent, thanks.';

  /** @type {String} */
  passphraseEntered = '';

  /** @type {Boolean} Checks if the entered passphrase matches the required passphrase */
  @computed('passphraseEntered')
  get passphraseMatch() {
    return this.get('passphraseEntered') === this.get('passphrase');
  }

  /**
   * Calls notificationLogsService.fetch().
   * @param uniquenessContext
   * @param receiver
   * @returns {Promise}
   * @private
   */
  _makeNotificationLogsRequest(uniquenessContext, receiver) {
    this.set('isLoading', true);

    return this.get('notificationLogsService')
      .fetch(uniquenessContext, receiver)
      .then((notificationLogs) => this.set('notificationLogs', notificationLogs))
      .catch((error) => this._handleNotificationFetchError(error))
      .finally(() => this.set('isLoading', false));
  }

  /**
   * Called after an unsuccessful response from notificationLogsService.fetch().
   * Notifies the user of the encountered error.
   *
   * @private
   */
  _handleNotificationFetchError(error) {
    console.debug('Error fetching notification logs', error.error || error);
    this.get('notify').error(
      "Error fetching notification logs, we don't know if this email has already been sent."
    );
  }

  /**
   * Uses the instance's model action sendReviewEmail
   * to make a request that includes emails and the message
   *
   * @returns {Promise}
   */
  _sendMealPlanningReviewEmail() {
    const emails = [this.get('mealPlanningReviewSendToEmails'), this.get('customEmail')].flat();
    const message = this.get('mealPlanningReviewMessage');

    this.set('isLoading', true);

    return this.get('instance')
      .sendReviewEmail({ emails: emails.flat(), message: message })
      .then((_) => this._handleSendingEmailsSuccess())
      .catch((error) => this._handleSendingEmailsError(error))
      .finally((_) => this._handleSendingEmailsCleanUp());
  }

  /**
   * Uses the instance's model action sendOrdersAreLiveEmail
   * to make a request to send an email to all invited team
   * members associated to all orders for the current week.
   *
   * @returns {Promise}
   */
  _sendOrdersAreLiveEmail() {
    this.set('isLoading', true);

    return this.get('instance')
      .sendOrdersAreLiveEmail({ message: this.get('mealPlanningLivePortalMessage') })
      .then((_) => this._handleSendingEmailsSuccess())
      .catch((error) => this._handleSendingEmailsError(error))
      .finally((_) => this._handleSendingEmailsCleanUp());
  }

  /**
   * Called after a successful response from sending emails.
   *
   * @private
   */
  _handleSendingEmailsSuccess() {
    console.debug('Successfully sent review email');

    this.get('notify').success('Your message has been sent');
    this.set('isShowing', false);
    this._resetForms();
  }

  /**
   * Called after an unsuccessful response from _sendMealPlanningReviewEmail.
   * Notifies the user of the encountered error.
   *
   * @private
   */
  _handleSendingEmailsError(error) {
    console.debug('Error sending review email', error.error || error);
    this.get('notify').error('We were unable to send your message');
  }

  /**
   * Called after any response from _sendMealPlanningReviewEmail.
   * Sets isLoading to false so in the event of an error the user
   * isn't left with a never-ending spinning loader.
   *
   * @private
   */
  _handleSendingEmailsCleanUp() {
    this.set('isLoading', false);
  }

  /**
   * Calls methods that reset component properties.
   *
   * @private
   */

  _resetForms() {
    this._resetMealPlanningReviewEmails();
    this._resetOrdersAreLiveEmail();
  }

  /**
   * Resets component properties for the meal planning review email.
   *
   * @private
   */
  _resetMealPlanningReviewEmails() {
    this.setProperties({
      mealPlanningReviewMessage: '',
      mealPlanningReviewSendToEmails: [],
      customEmail: null,
      passphraseEntered: ''
    });
  }

  /**
   * Resets component properties for the "portal is live" email.
   *
   * @private
   */
  _resetOrdersAreLiveEmail() {
    this.setProperties({
      mealPlanningLivePortalMessage: '',
      passphraseEntered: ''
    });
  }

  @action
  fetchNotificationLogsForWeekMealPlan() {
    this.setProperties({
      notificationLogs: [],
      isLoading: true
    });

    const receiver = `${this.get('client.id')},Client`;
    const uniquenessContext = this.get('instance.id');

    this._makeNotificationLogsRequest(uniquenessContext, receiver);
  }

  @action
  fetchNotificationLogsForPortalLive() {
    this.setProperties({
      notificationLogs: [],
      isLoading: true
    });

    const receiver = `${this.get('instance.id')},MealPlanning::Instance`;
    const uniquenessContext = this.get('instance.id');

    this._makeNotificationLogsRequest(uniquenessContext, receiver);
  }

  @action
  sendMealPlanningReviewEmail() {
    return this._sendMealPlanningReviewEmail();
  }

  @action
  sendOrdersAreLiveEmail() {
    return this._sendOrdersAreLiveEmail();
  }

  @action
  closeMealPlanningEmailOptionsModal() {
    this._resetForms();
    this.setProperties({
      isShowing: false,
      notificationLogs: [],
      passphraseEntered: ''
    });
  }

  @action
  showModal() {
    this.set('isShowing', true);
  }
}
