import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class RulesConfiguratorsActionsAssignCourierConfiguratorComponent extends Component {
  @arg form;
  @service store;
  @tracked couriers;

  @action
  async fetchCouriers() {
    this.couriers = await this.store.query('courier', { page: { limit: 200 } });
  }
}
