import classic from 'ember-classic-decorator';
import Model, { attr } from 'renard/models/foodee';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { inject as service } from '@ember/service';
import { DateTime } from 'luxon';

@classic
export default class LogisticsArrivalEstimate extends Model {
  @service
  tock;

  @service
  appConfiguration;

  @service
  secondaryStore;

  @attr('luxon-date')
  createdAt;

  @attr('luxon-date')
  updatedAt;

  @fragment('fragments/logistics/estimate-log')
  deliveryEstimate;

  @fragment('fragments/logistics/estimate-log')
  pickupEstimate;

  @fragmentArray('fragments/logistics/estimate-log')
  deliveryEstimates;

  @fragmentArray('fragments/logistics/estimate-log')
  pickupEstimates;

  // If the ETA is in the past relative to the current time then the eta is
  // likely stale and we should just ujsee the wall current wall time.
  get virtualETA() {
    const diff = this.latestEstimate.arrivalEstimate.diff(this.wallTime);
    return diff > 0 ? this.latestEstimate.arrivalEstimate : this.wallTime;
  }

  get latestEstimate() {
    return [this.pickupEstimates.lastObject, this.deliveryEstimates.lastObject]
      .compact()
      .sortBy('timestamp').lastObject;
  }

  get isStale() {
    return this.ageOfEstimate.minutes > (this.appConfiguration.logistics?.staleAgeInMinutes ?? 5);
  }

  get wallTime() {
    return DateTime.fromJSDate(this.tock.date);
  }

  get ageOfEstimate() {
    return this.wallTime.diff(this.latestEstimateDateTime, 'minutes');
  }

  get latestEstimateDateTime() {
    return this.latestEstimate?.timestamp ?? DateTime.now();
  }

  reload() {
    return this.secondaryStore.findRecord('logistics-arrival-estimate', this.id, { reload: true });
  }
}
