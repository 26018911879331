import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@tagName('')
export default class ClientIndex extends Component {
  /** @type {Service} */
  @service
  router;

  /** @type {Client} */
  client = null;

  /** @type {boolean} */
  isLoading = false;

  /** @type {Owner} */
  @alias('client.owner')
  owner;

  /** @type {string} */
  @computed('owner')
  get urlSafeExtension() {
    const extension = this.get('owner.extension');
    return extension ? `;ext=${extension}` : '';
  }

  @action
  makeNewOrder() {
    const client = this.get('client');
    const clientId = client.get('id');
    const router = this.get('router');
    this.set('isLoading', true);

    client.get('defaultArea').then((area) =>
      router.transitionTo('logged-in.new-order', {
        queryParams: { clientId, areaId: area.get('id') }
      })
    );
  }
}
