import classic from 'ember-classic-decorator';
import OrderRoute from 'star-fox/features/logged-in/orders/edit/manage-teams/route';

@classic
export default class ManageTeamsRoute extends OrderRoute {
  /** @override */
  templateName = 'logged-in.orders.edit.manage-teams';

  orderEditRoute = 'logged-in.restaurants.show.capacities.edit-order';
}
