import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';

import { action } from '@ember/object';

@classic
export default class GiftbitController extends Controller {
  _toggleGift(key, gift) {
    const configuration = this.get('model.giftbitConfiguration');
    const prefs = configuration.get('preferences');

    const id = gift.get('id');

    if (prefs[key].includes(id)) {
      prefs[key].removeObject(id);
    } else {
      prefs[key].push(id);
    }
    configuration.save();
  }

  @action
  toggleAmericanGift(gift) {
    this._toggleGift('americanGiftIds', gift);
  }

  @action
  toggleCanadianGift(gift) {
    this._toggleGift('canadianGiftIds', gift);
  }
}
