import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias, oneWay } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@classNames('sf-orders-restaurant-details-restaurant-poll')
export default class RestaurantPoll extends Component {
  /** @type {Store} */
  @service
  store;

  /** @type {NotificationService} */
  @service
  notify;

  /** @type {object} The form object passed to the component. */
  form = null;

  /** @type {Order} */
  order = null;

  /** @type {Restaurant[]} */
  restaurants = null;

  /** @type {Restaurant} */
  @oneWay('order.restaurant')
  selectedRestaurant;

  /** @type {?string} */
  pickupAtError = null;

  /** @type {boolean} */
  isLoading = false;

  /** @type {Object[]} */
  @computed('order.restaurantVotes.@each.restaurant')
  get groupedVoteResults() {
    return this.get('order.restaurantVotes').reduce((acc, vote) => {
      const restaurant = vote.get('restaurant.name');
      const existingResto = acc.findBy('restaurant', restaurant);

      if (existingResto) {
        existingResto.votes = existingResto.votes + 1;
      } else {
        acc.pushObject({ restaurant, votes: 1 });
      }

      return acc;
    }, []);
  }

  /** @type {Number} */
  @alias('order.restaurantVotes.length')
  totalRestaurantVotes;
}
