import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';
import FilterRouteMixin from 'star-fox/mixins/filter-route-mixin';
import moment from 'moment-timezone';

@classic
export default class OrdersRoute extends Route.extend(
  AuthenticatedRouteMixin,
  LoadingRouteMixin,
  FilterRouteMixin
) {
  /** @type {OrderService} */
  @service
  orderService;

  /** @type {UserSession} */
  @service
  userSession;

  isIndexTypeRoute = true;

  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    sort: { refreshModel: true },
    filterDate: { refreshModel: true },
    search: { refreshModel: true },
    id: { refreshModel: true },
    state: { refreshModel: true },
    reject_state: { refreshModel: true },
    flag: { refreshModel: true },
    areas: { refreshModel: true },
    restaurant_id: { refreshModel: true },
    client_id: { refreshModel: true },
    client_actual_segment: { refreshModel: true },
    client_meal_plan: { refreshModel: true },
    user_id: { refreshModel: true },
    managed_by_id: { refreshModel: true },
    driver_id: { refreshModel: true },
    deliver_on: { refreshModel: true },
    deliver_on_range: { refreshModel: true },
    deliver_at_after: { refreshModel: true },
    managed_by_me: { refreshModel: true },
    is_draft: { refreshModel: true },
    is_foodee_served: { refreshModel: true },
    is_client_demo: { refreshModel: true },
    is_restaurant_demo: { refreshModel: true },
    is_before_submitted: { refreshModel: true },
    has_accounting_seeds_issues: { refreshModel: true },
    not_synced_with_accounting_seeds: { refreshModel: true },
    total_greater_than: { refreshModel: true }
  };

  filters = [
    {
      key: 'id',
      icon: 'cart',
      label: 'Orders',
      resourceName: 'order',
      resourceValueKey: 'id',
      resourceValueLabel: 'identifier',
      valueTextDelimiter: '::'
    },
    {
      key: 'state',
      icon: 'tags',
      label: 'Include Order State',
      resourceValueLabel: 'label'
    },
    {
      key: 'reject_state',
      icon: 'tags',
      label: 'Exclude Order State',
      resourceValueLabel: 'label'
    },
    {
      key: 'flag',
      icon: 'flag',
      label: 'Is Order Flagged'
    },
    {
      key: 'areas',
      icon: 'map',
      label: 'Area',
      resourceName: 'area',
      resourceValueLabel: 'city',
      resourceValueKey: 'city'
    },
    {
      key: 'restaurant_id',
      icon: 'utensils',
      label: 'Restaurant',
      resourceName: 'restaurant',
      resourceValueLabel: 'name',
      valueTextDelimiter: '-'
    },
    {
      key: 'client_id',
      icon: 'building',
      label: 'Client',
      resourceName: 'client',
      resourceValueLabel: 'name',
      valueTextDelimiter: '-'
    },
    {
      key: 'client_actual_segment',
      icon: 'sort alphabet down',
      label: `Client Segment`
    },
    {
      key: 'client_meal_plan',
      icon: 'list alternate outline',
      label: `Client Meal Plan Type`
    },
    {
      key: 'user_id',
      icon: 'user',
      label: `Users' Orders`,
      resourceName: 'user',
      resourceValueLabel: 'email',
      valueTextDelimiter: '::'
    },
    {
      key: 'managed_by_id',
      icon: 'user circle',
      label: 'Managed By Account Manager',
      resourceName: 'account-manager',
      resourceValueLabel: 'fullName',
      resourceRequiredFields: 'first-name,last-name',
      valueTextDelimiter: '::'
    },
    {
      key: 'driver_id',
      icon: 'car',
      label: `Drivers' Orders`,
      resourceName: 'driver',
      resourceValueLabel: 'email',
      valueTextDelimiter: '::'
    },
    {
      key: 'deliver_on',
      icon: 'calendar',
      label: 'Delivery Day',
      valueTextDelimiter: '::'
    },
    {
      key: 'deliver_on_range',
      icon: 'calendar alternate',
      label: 'Delivery Day Range'
    },
    {
      key: 'deliver_at_after',
      icon: 'calendar outline',
      label: 'Delivery Day After',
      valueTextDelimiter: '::'
    },
    {
      key: 'deliver_at_before',
      icon: 'calendar outline',
      label: 'Delivery Day Before',
      valueTextDelimiter: '::'
    },
    {
      key: 'managed_by_me',
      icon: 'user circle',
      label: 'Managed By Me'
    },
    {
      key: 'is_draft',
      icon: 'file outline',
      label: 'Draft Orders Only'
    },
    {
      key: 'is_foodee_served',
      icon: 'birthday cake',
      label: 'Foodee Served Orders Only'
    },
    {
      key: 'is_client_demo',
      icon: 'file outline',
      label: 'Client Demos'
    },
    {
      key: 'is_restaurant_demo',
      icon: 'building',
      label: 'Restaurant Demos'
    },
    {
      key: 'is_before_submitted',
      icon: 'utensils',
      label: `Orders Before Submitted`
    },
    {
      key: 'has_accounting_seeds_issues',
      icon: 'window close outline',
      label: 'Orders with Accounting Seeds Issues'
    },
    {
      key: 'not_synced_with_accounting_seeds',
      icon: 'window close outline',
      label: 'Orders that are not synced with Accounting Seeds'
    },
    {
      key: 'total_greater_than',
      icon: 'dollar sign',
      label: `Total Greater Than`
    }
  ];

  defaultJRFilters = {
    isTemplate: false,
    isBookmark: false
  };

  /** @override */
  model(params) {
    super.model(...arguments);

    const oldModel = this.modelFor('logged-in.orders');
    const fetchedAreas = oldModel && oldModel.areas;

    this.setupDefaultFilters();

    //No longer use raw search in filter mode; too confusing
    delete params.search;

    return RSVP.hash({
      // if we've been here before we don't need to reload areas
      areas:
        fetchedAreas ||
        this.store.query('area', {
          page: {
            limit: 100,
            offset: 0
          }
        }),
      orders: this.get('orderService').filterOrders(params, this.get('filterCollection.asJR'))
    });
  }

  /** Sets up the default filters (today onward) if none are setup when activating route */
  setupDefaultFilters(force) {
    const filterCollection = this.get('filterCollection');
    const hasActivated = this.get('hasActivated');
    const hasFilters = filterCollection.get('selectedFilters.length');

    if ((!hasActivated && !hasFilters) || force) {
      filterCollection.addFilter(
        filterCollection.createFilter({
          key: 'deliver_at_after',
          value: [moment().format('YYYY-MM-DD')],
          valueText: [moment().format('MMMM Do YYYY')]
        })
      );
    }
  }

  /** @override */
  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setupPusher();
  }

  /** @override */
  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.set('rightPaneSize', 0);
    }

    controller.teardownPusher();
  }

  @action
  refreshModel() {
    this.refresh();
  }

  @action
  openOrdersInNewTabs(selectedOrders) {
    const controller = this.controllerFor('logged-in.orders');
    selectedOrders = selectedOrders.shift();
    selectedOrders.forEach((order) =>
      window.open(this.get('router').generate(controller.get('editRoute'), order.get('id')))
    );
  }

  @action
  workOnSpecificOrdersInNewTab(selectedOrders) {
    window.open(
      this.get('router').generate('logged-in.orders', {
        queryParams: {
          id: selectedOrders.map((_) => `${_.get('id')}::${_.get('identifier')}`).join(';')
        }
      })
    );
  }
}
