import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { KEYS } from 'renard/models/app-configuration';

@classic
export default class CapabilitiesRoute extends Route {
  model(_params) {
    return RSVP.hash({
      roles: this.store.query('role', {
        page: {
          limit: 100
        }
      }),
      configuration: this.store
        .query('app-configuration', { filter: { key: KEYS.capabilities } })
        .then((appConfiguration) => {
          return (
            appConfiguration.get('firstObject') ||
            this.store.createRecord('app-configuration', {
              key: KEYS.capabilities,
              preferences: {
                VIEW_AREAS: {
                  action: 'VIEW_AREAS',
                  roles: []
                }
              }
            })
          );
        })
    });
  }
}
