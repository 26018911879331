import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';

@classic
export default class ManageTeamsRoute extends Route.extend(
  CapabilityRouteMixin,
  LoadingRouteMixin
) {
  bubblesLoadingEvent = true;

  queryParams = {
    teamId: { refreshModel: true },
    joinedUsersPageLimit: { refreshModel: true },
    joinedUsersPageOffset: { refreshModel: true },
    orderTeamsPageLimit: { refreshModel: true },
    orderTeamsPageOffset: { refreshModel: true }
  };

  orderEditRoute = 'logged-in.orders.edit';

  /** @override */
  model(params) {
    if (this.get('isIndexTypeRouteRefreshing')) {
      return this.getPrevModel();
    }

    const {
      joinedUsersPageLimit,
      joinedUsersPageOffset,
      orderTeamsPageLimit,
      orderTeamsPageOffset
    } = params;

    const order = this.modelFor(this.get('orderEditRoute')).order;

    const orderPromise = this.store.findRecord('order', order.get('id'), {
      reload: true
    });

    const client = order.get('client');

    const joinedUsersQueryOptions = {
      filter: {},
      page: {
        limit: joinedUsersPageLimit,
        offset: joinedUsersPageOffset
      }
    };

    const orderTeamQueryOptions = {
      page: {
        limit: orderTeamsPageLimit,
        offset: orderTeamsPageOffset
      }
    };

    return RSVP.hash({
      order: orderPromise,
      client: client,
      clientTeams: client.get('teams'),
      groupOrderMembers: order.query('groupOrderMembers', { reload: true }),
      orderTeams: order.query('teams', orderTeamQueryOptions, { reload: true }),
      joinedUsers: order.query('joinedUsers', joinedUsersQueryOptions, { reload: true })
    }).then((result) => {
      result.gomMeta = order.get('meta.teams');
      result.teamCounts = order.get('meta.teamCounts');
      result.joinedUsersCounts = order.get('meta.joinedUsersCounts');
      return result;
    });
  }

  afterModel(model, _transition) {
    const order = model.order;
    if (order.get('orderType') !== 'team') {
      this.transitionTo('logged-in.orders.edit', order.get('id'));
    }
  }

  /** @override */
  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setDefaultTeamUsers();
  }

  /** @override */
  resetController(controller, isExiting, _transition) {
    super.resetController.apply(this, arguments);

    if (isExiting) {
      controller.set('selectedTeam', null);
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
