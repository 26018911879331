import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import SessionCan from 'star-fox/utils/session-can';

/**
 * A helper that returns true iff the currently logged-in user does NOT have the provided capability
 *
 * Usage
 *
 * {{#if (user-cant 'FOO')}}
 *   No Foo for you
 * {{/if}}
 */
@classic
export default class UserCant extends Helper {
  @service
  session;

  /**
   * @param {string[]} capability
   * @returns {boolean}
   */
  compute([capability]) {
    if (!capability) {
      return true;
    }

    return !SessionCan(this.get('session'), capability);
  }
}
