import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';

@classic
export default class IndexRoute extends Route.extend(LoadingRouteMixin) {
  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    searchText: { refreshModel: true },
    sort: { refreshModel: true }
  };

  model(params) {
    const { pageLimit, pageOffset, searchText, sort } = params;

    return RSVP.hash({
      restaurantBrands: this.store.query('restaurants-brand', {
        page: {
          limit: pageLimit,
          offset: pageOffset
        },
        filter: {
          search: searchText ?? undefined
        },
        sort: sort
      })
    });
  }
}
