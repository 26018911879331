import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { defineProperty } from '@ember/object';
import { run } from '@ember/runloop';
import moment from 'moment-timezone';
import { fromLocalTimeIn, toLocalTimeIn } from 'star-fox/utils/date';

/**
 * Wrapper around "semantic-ui-daterangepicker#~1.3.21",
 */
@classic
@classNames('fde-date-range-select ui fluid left icon input')
@classNameBindings('readonly:corner', 'readonly:labeled')
export default class DateRangeControl extends Component {
  /** @type {boolean} whether or not this time selector */
  hasTimePicker = false;

  /** @type {boolean} */
  hasLinkedCalendars = false;

  /** @type {object} initial value */
  value = null;

  /** @type {number} amount that the minute drop down increments by */
  timePickerIncrement = 15;

  /** @type {string} */
  format = 'dddd, MMMM Do YYYY';

  /** @type {string} */
  timeZone = '';

  /** @type {date} */
  startDate = null;

  /** @type {date} */
  endDate = null;

  /** @type {boolean} */
  readonly = false;

  /** @type {boolean} */
  disabled = false;

  /** @type {string} */
  drops = 'down';

  /**
   * @callback changeHandler
   * @type {string} startDate as a string
   * @type {string} [endDate] as a string
   *
   * @type {changeHandler}
   */
  onChange() {}

  /**
   * @type {daterangepicker}
   * @private
   */
  _dateRangePicker = null;

  /**
   * @param {number} start time in ms since 1970
   * @param {number} end time in ms since 1970!
   * @private
   */
  _onChange(start, end) {
    const startDate = this._fromLocalTimeIn(start);
    const endDate = this._fromLocalTimeIn(end);
    const [startDateKey, endDateKey] = Object.keys(this.get('value'));
    const values = {
      [startDateKey]: startDate,
      [endDateKey]: endDate
    };

    console.debug(`Date Picker Changed (${startDate}, ${endDate})`);

    this.onChange(values);
  }

  /**
   * @type {daterangepicker}
   * @private
   */
  _updateDatePicker() {
    if (this._dateRangePicker) {
      this._dateRangePicker.setStartDate(this._toLocalTimeIn('startDate'));
      this._dateRangePicker.setEndDate(this._toLocalTimeIn('endDate'));
    }
  }

  /**
   * @param {Function} method
   * @param {Date} time
   * @returns {Date}
   * @private
   */
  _getLocalTime(method, time) {
    const tz = this.get('timeZone');

    return method(time, tz);
  }

  /**
   * @param {string} key
   * @returns {Date}
   * @private
   */
  _toLocalTimeIn(key) {
    const date = this.get(key) || moment().toDate();

    return this._getLocalTime(toLocalTimeIn, date);
  }

  /**
   * @param {Date} date
   * @returns {Date}
   * @private
   */
  _fromLocalTimeIn(date) {
    return this._getLocalTime(fromLocalTimeIn, date);
  }

  init() {
    super.init(...arguments);

    const [startDateKey, endDateKey] = Object.keys(this.get('value'));

    defineProperty(this, 'startDate', alias(`value.${startDateKey}`));
    defineProperty(this, 'endDate', alias(`value.${endDateKey}`));
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    const startDate = this._toLocalTimeIn('startDate');
    const endDate = this._toLocalTimeIn('endDate');

    const options = {
      autoApply: true,
      singleDatePicker: false,
      timePicker: this.get('hasTimePicker'),
      timePickerIncrement: this.get('timePickerIncrement'),
      linkedCalendars: this.get('hasLinkedCalendars'),
      startDate,
      endDate,
      format: this.get('format'),
      drops: this.get('drops')
    };

    const dateRangePickerElement = this.$('.dateSelect')
      .daterangepicker(options, (start, end, _label) => this._onChange(start, end))
      .on('hide.daterangepicker', (_) => {})
      .on('apply.daterangepicker', (_) => {});

    this._dateRangePicker = dateRangePickerElement.data('daterangepicker');
    this._$dateRangePicker = dateRangePickerElement;

    run.next((_) => this._updateDatePicker());
  }

  @observes('startDate', 'endDate')
  onFilterDateChange() {
    run.next((_) => this._updateDatePicker());
  }

  didDestroyElement() {
    super.didDestroyElement(...arguments);
    this._dateRangePicker.remove();

    // nuclear option
    delete this._$dateRangePicker;
  }
}
