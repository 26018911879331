import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';
import moment from 'moment-timezone';

@classic
export default class RestaurantCapacityTranche extends Model {
  @attr('number')
  capacity;

  @attr('date')
  startTime;

  @attr('date')
  endTime;

  @attr('number')
  orderMax;

  /*
   * relationships
   */

  @belongsTo('restaurant')
  restaurant;

  @hasMany('restaurantUtilization')
  restaurantUtilizations;

  /*
   * Computed properties
   */

  /* start time parsed as a UTC time */
  @computed('startTime')
  get startTimeUTC() {
    return moment.utc(this.get('startTime')).format('HH:mmA');
  }

  /* end time parsed as a UTC time */
  @computed('endTime')
  get endTimeUTC() {
    return moment.utc(this.get('endTime')).format('HH:mmA');
  }
}
