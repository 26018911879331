import Component from '@glimmer/component';
import { alias } from 'macro-decorators';
import { arg } from 'ember-arg-types';
import { object, func } from 'prop-types';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const TYPES = {
  Redelivery: 'Redelivery',
  Compensation: 'Compensation'
};

export default class SfOrdersModalsCreateRedeliveryOrderComponent extends Component {
  @arg(object.required) data;
  @arg(func.required) close;

  @alias('data.order')
  order;

  itemsToReplace = [];

  @tracked
  type = TYPES.Redelivery;

  types = Object.values(TYPES);

  get isGroupOrder() {
    return this.order.groupOrderMembers.length > 0;
  }

  get groupBy() {
    return this.isGroupOrder ? 'groupOrderMember.name' : 'menuGroupName';
  }

  get sortBy() {
    return this.isGroupOrder ? 'groupOrderMember.name' : 'menuGroupIndex';
  }

  @action
  async loadOrderTask() {
    return this.order.loadOrderItems();
  }

  @action
  async replaceOrder() {
    const params = { only_order_item_ids: this.itemsToReplace.map((i) => Number(i.id)) };

    const newOrder = await (this.type === TYPES.Redelivery
      ? this.order.redeliver(params)
      : this.order.compensateRestaurant(params));
    this.close(newOrder.data.id);
  }
}
