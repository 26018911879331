import classic from 'ember-classic-decorator';
import { isArray } from '@ember/array';
import FeedItemBuilder from './base';

@classic
export default class OrderItemBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);
    let misc = version.get('misc.menuOptionItems') || '';
    // just in case someone put a string in here
    misc = isArray(misc) ? misc : misc.split(',');

    feedItem.icon = 'shopping cart';
    feedItem.meta = misc;
    feedItem.meta.unshift(`OrderItem: ${version.get('itemId')}`);

    const groupOrderMemberId = version.getValue('groupOrderMemberId');
    if (groupOrderMemberId) {
      feedItem.meta.unshift(`GroupOrderMember: ${groupOrderMemberId}`);
    }

    const event = version.get('event');
    let verb = '';
    let preposition = '';

    if (event === 'create') {
      verb = 'added';
      preposition = 'to';
    } else if (event === 'update') {
      verb = 'changed';
      preposition = 'in';
    } else {
      verb = 'removed';
      preposition = 'from';
    }

    // grab either the misc data menuItem or the menu item id for backwards compat
    const menuOptionItem =
      version.get('misc.menuOptionItem') || `MenuItem: ${version.getValue('menuOptionItemId')}`;

    const groupOrderMember = version.get('misc.groupOrderMember');
    // TODO: expose better change displays (ie: change only in quantity, change without any object changes is likely a moi change)
    if (groupOrderMember) {
      feedItem.subject = `${version.get(
        'whodunnit'
      )} ${verb} '${menuOptionItem}' ${preposition} ${groupOrderMember}'s cart`;
    } else {
      feedItem.subject = `${version.get(
        'whodunnit'
      )} ${verb} '${menuOptionItem}' ${preposition} the order`;
    }

    return feedItem;
  }
}
