import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Model, { attr, belongsTo, hasMany, fragment } from 'renard/models/foodee';
import { computed, get } from '@ember/object';
import { modelAction } from 'ember-custom-actions';

/**
 * @typedef User
 * @type {Model}
 */
@classic
export default class User extends Model {
  /*
   * Attributes
   */
  @attr('string')
  globalId;

  @attr('string')
  email;

  @attr('string')
  salesforce_email;

  @attr('string')
  firstName;

  @attr('string')
  lastName;

  @attr('string')
  phoneNumber;

  @attr('string')
  extension;

  @attr('phone-number')
  smsNumber;

  @attr('boolean')
  phoneCanSms; // deprecated

  @attr('phone')
  phoneType;

  @attr('boolean')
  active;

  @attr('date')
  lastSignInAt;

  @attr('date')
  currentSignInAt;

  @attr('date')
  lastSeen;

  @fragment('fragments/notification-preferences', { defaultValue: {} })
  notificationPreferences;

  @attr('boolean')
  isClientAdmin;

  @attr('boolean')
  isFoodeeEmployee;

  @attr('boolean')
  isExclusivelySwiftFoxUser;

  @attr('boolean')
  isRestaurantUser;

  @attr('boolean')
  isPending;

  @attr('boolean')
  isPendingReset;

  @attr('boolean')
  notInvited;

  @attr('string')
  password;

  @attr('string')
  passwordConfirmation;

  @attr('string')
  resetPasswordToken;

  @attr('date')
  invitationSentAt;

  @attr('date')
  invitationAcceptedAt;

  @attr('string')
  invitationToken;

  @attr('object')
  features;

  @attr('string')
  profilePictureUrl;

  // Driver specific stuff
  @attr('vehicle')
  driverVehicleType;

  @attr('number')
  driverSpeedRating;

  @attr('object')
  driverDeviceInfo;

  @attr('boolean')
  isActiveDriver;

  @attr('date')
  lastDriverNotificationSentAt;

  @attr('boolean')
  onPayroll;

  @attr('boolean')
  clientAlwaysCall;

  // Payroll
  @attr('string')
  driverFileNumber;

  @attr('string')
  state;

  @fragment('fragments/user-storage', {
    defaultValue: {}
  })
  storage;

  @attr('boolean')
  alwaysRetainInDd;

  /*
   * Relationships
   */
  @hasMany('area')
  areas;

  @hasMany('courier', { inverse: 'drivers' })
  couriers;

  @belongsTo('client', { inverse: 'users' })
  client;

  @hasMany('team', { inverse: 'members' })
  teams;

  @hasMany('restaurant', { inverse: 'admins' })
  restaurants;

  @belongsTo('communication-preference', { async: false })
  communicationPreference;

  @belongsTo('meal-planning-preference-profile')
  preferenceProfile;

  @hasMany('driver-ping')
  driverPings;

  @hasMany('driverWeek')
  driverWeeks;

  @hasMany('delivery-case')
  deliveryCases;

  @hasMany('desk-case')
  deskCases;

  @hasMany('payment-card')
  cards;

  @belongsTo('contact')
  contact;

  @hasMany('payment-card', { inverse: 'user' })
  paymentCards;

  @hasMany('role')
  roles;

  @belongsTo('user-invite')
  userInvite;

  @hasMany('order', { inverse: 'creator' })
  createdOrders;

  @hasMany('order', { inverse: 'driver' })
  deliveredOrders;

  @hasMany('order', { inverse: 'owner' })
  orders;

  @hasMany('historian-version')
  allUserVersions;

  @hasMany('historian-version')
  versions;

  @hasMany('notification-log', { inverse: 'receiver' })
  notificationLogs;

  /*
   * Computed Properties, Instance Variables
   */
  /** @type {object} */
  currentPosition = null;

  @alias('fullName')
  label;

  /** @type {string} */
  @computed('firstName', 'lastName')
  get fullName() {
    const firstName =
      this.get('firstName') == 'invitationPending' ? 'invitation' : this.get('firstName');
    const lastName = this.get('lastName') == 'invitationPending' ? 'pending' : this.get('lastName');

    return `${firstName} ${lastName}`;
  }

  get isDeveloper() {
    return this.roles.mapBy('name').includes('developer');
  }

  get driverName() {
    // Remove the parenthetical form the first name
    return this.fullName.replace(/\s*\([^()]*\)/g, '').trim();
  }

  /** @type {string} */
  @computed('firstName', 'lastName')
  get initials() {
    return `${this.get('firstName').charAt(0)}${this.get('lastName').charAt(0)}`.toUpperCase();
  }

  /** @type {string} */
  @computed('isPending')
  get passwordStatus() {
    return this.get('isPending') ? 'Pending' : 'Completed';
  }

  /** @type {number} */
  @computed('paymentCards.[]')
  get numberOfCards() {
    return this.get('paymentCards').rejectBy('id', null).get('length');
  }

  /** @type {string} */
  @computed('fullName', 'email')
  get nameAndEmail() {
    return `${this.get('fullName')} - ${this.get('email')}`;
  }

  /** @type {boolean} */
  @computed('invitationToken')
  get invited() {
    return Boolean(this.get('invitationToken'));
  }

  /** @type {Service} */
  @service
  notify;

  /** @type {Service} */
  @service
  ajax;

  /** @Creates the preference profile */
  createPreferenceProfile = modelAction('create-preference-profile', {
    method: 'POST',
    pushToStore: true
  });

  /** Creates a new invitation */
  createInvitation = modelAction('create-invitation', {
    method: 'POST',
    pushToStore: true
  });

  /** Resets the invitation status */
  resetInvitationStatus = modelAction('reset-invitation-status', {
    method: 'POST',
    pushToStore: true
  });

  /*
   * Functions, and observers
   */
  updatePosition(position) {
    this.setProperties({
      previousPosition: this.get('currentPosition'),
      currentPosition: position
    });
  }

  /**
   * Resends an invitation to a User
   * @param {?Client} client
   * @returns {Promise}
   */
  async resendInvite(client = this.get('client')) {
    if (!client.isFufilled) {
      await client;
    }

    const notify = this.get('notify');
    const store = this.get('store');
    const userEmail = this.get('email');
    const courier = await this.get('couriers.firstObject');
    const restaurant = await this.get('restaurants.firstObject');

    let userInvite = store.peekRecord('user-invite', this.get('id'));

    if (!userInvite) {
      userInvite = store.createRecord('user-invite', {
        id: this.get('id'),
        email: this.get('email'),
        phoneNumber: this.get('phoneNumber'),
        firstName: this.get('firstName'),
        lastName: this.get('lastName'),
        client,
        courier,
        restaurant,
        user: this
      });

      userInvite.send('willCommit');
      userInvite.send('didCommit');
    }

    return userInvite
      .save()
      .then((_) => notify.success(`Resent invitation to ${userEmail}`))
      .catch((e) => {
        console.error(`Server Error: ${e}`);
        notify.error(
          `Failed to resend invitation to ${userEmail}. Error: ${e?.errors.map((e) => e.detail)}`
        );
      });
  }

  /**
   * Resends an invitation to a User
   * @param {Team} team
   * @returns {Promise}
   */
  resendTeamInvite(team) {
    const notify = this.get('notify');
    const store = this.get('store');
    const userEmail = this.get('email');

    let userInvite = store.peekRecord('user-invite', this.get('id'));

    if (!userInvite) {
      userInvite = store.createRecord('user-invite', {
        id: this.get('id'),
        email: userEmail,
        team: team,
        user: this
      });

      userInvite.send('willCommit');
      userInvite.send('didCommit');
    } else {
      userInvite.set('team', team);
    }

    return userInvite
      .save()
      .then((_) => notify.success(`Resent invitation to ${userEmail}`))
      .catch((e) => {
        console.error(`Server Error: ${e}`);
        notify.error(
          `Failed to resend invitation to ${userEmail}. Error: ${e?.errors.map((e) => e.detail)}`
        );
      });
  }

  /**
   * Sends a Foodee+ invite to an Admin
   * @param {Client} client
   * @returns {Promise}
   */
  sendFoodeePlusInvite(client) {
    const notify = this.get('notify');
    const store = this.get('store');
    const userEmail = this.get('email');

    let userInvite = store.peekRecord('user-invite', this.get('id'));

    if (!userInvite) {
      userInvite = store.createRecord('user-invite', {
        id: this.get('id'),
        email: userEmail,
        client: client,
        inviteAsAdmin: true,
        user: this
      });

      userInvite.send('willCommit');
      userInvite.send('didCommit');
    } else {
      userInvite.set('inviteAsAdmin', true);
    }

    return userInvite
      .save()
      .then((_) => notify.success(`Sent invitation to ${userEmail}`))
      .catch((e) => {
        console.error(`Server Error: ${e}`);
        notify.error(
          `Failed to send invitation to ${userEmail}. Error: ${e?.errors.map((e) => e.detail)}`
        );
      });
  }

  /**
   * Returns true if the provided feature is enabled for the user
   * @param {string} feature
   * @return {boolean}
   */
  hasFeatureEnabled(feature) {
    return !!(get(this, 'features') ?? {})[feature];
  }

  /**
   * Promotes a member of the default team to a client admin
   @ @param {User} teamUser
   * @param {Number} clientId
   * @returns {Promise}
   */
  promoteToAdmin(teamUser, clientId) {
    const userId = teamUser.get('id');

    return this.get('ajax').post(`/api/v3/clients/${clientId}/admins`, {
      data: { type: 'users', user_id: userId }
    });
  }

  /**
   * Demotes an admin to a member of the default team
   @ @param {User} teamUser
   * @param {Number} clientId
   * @returns {Promise}
   */
  demoteAdminToTeamMember(teamUser, clientId) {
    const userId = teamUser.get('id');

    return this.get('ajax').delete(`/api/v3/clients/${clientId}/admins`, {
      data: { type: 'users', user_id: userId }
    });
  }

  /**
   * Sends a password reset email to the User
   * @returns {Promise}
   */
  resetPassword() {
    const notify = this.get('notify');
    const email = this.get('email');

    return this.get('ajax')
      .post('/api/v3/users/password', {
        data: { user: { email } }
      })
      .then((_) => notify.success(`Sent reset password notice to ${email}`))
      .catch((e) => {
        console.error(`Server Error: ${e}`);
        notify.error(
          `Unable to reset password for ${email}. Error: ${e.errors.map((e) => e.detail)}`
        );
      });
  }

  /**
   * Checks if a given client is administered by the user
   * @param {Client}
   */
  isUserAdminOf(client) {
    if (!client) {
      return false;
    }

    const userClient = this.get('client.content');
    const isClientAdmin = this.get('isClientAdmin');
    const clientOwner = client.get('owner.content');

    const isClientUserClient = userClient === client;
    const isClientOwnerUser = clientOwner === this;

    return (isClientUserClient && isClientAdmin) || isClientOwnerUser;
  }

  /** @returns {Promise} */
  sendSmsOptIn = modelAction('send-sms-opt-in', { method: 'POST' });

  /** @returns {Promise} */
  latestPosition = modelAction('latest-position', { method: 'GET' });

  /*
   * Validations
   */
  validations = {
    firstName: {
      presence: {}
    },
    lastName: {
      presence: {}
    },
    phoneNumber: {
      presence: {}
    },
    email: {
      presence: true,
      email: true
    }
  };
}

/**
 * Used to set user invite defaults. Note that SwiftFox depends on these being the defaults.
 * @enum {string}
 */
export const UserInviteDefaults = {
  FIRST_NAME: 'invitationPending',
  LAST_NAME: 'invitationPending',
  PHONE_NUMBER: '9999999999'
};
