import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';

@classic
export default class OrdersRoute extends Route {
  @service
  orderService;

  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    sort: { refreshModel: true },
    states: { refreshModel: true },
    filterByDate: { refreshModel: true },
    pickupAt: { refreshModel: true }
  };

  model(params) {
    const restaurant = this.modelFor('logged-in.restaurants.show').restaurant;

    const { pageLimit, pageOffset, states, sort, pickupAt, filterByDate } = params;

    const queryOptions = {
      filter: {
        //may filter by state or pickupAt
      },
      page: {
        limit: pageLimit,
        offset: pageOffset
      },
      sort: sort,
      include: 'client,contact'
    };

    if (states !== null && states !== '') {
      queryOptions.filter.state = states;
    }

    if (filterByDate && pickupAt !== null) {
      queryOptions.filter.deliverOn = pickupAt;
    }

    return RSVP.hash({
      restaurant: restaurant,

      orders: restaurant.query('orders', queryOptions)
    });
  }
}
