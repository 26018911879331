import classic from 'ember-classic-decorator';
import { Enum } from 'renard/utils/enum';
import AbstractEnumTransform from 'renard/transforms/abstract-enum-transform';
import { decamelize, dasherize, capitalize } from '@ember/string';

export class ActionType extends Enum {
  static SetAlertStatus = new ActionType('Rules::Actions::Logistics::SetAlertStatus');
  static FlagDelivery = new ActionType('Rules::Actions::Logistics::FlagDelivery');
  static AssignCourier = new ActionType('Rules::Actions::Logistics::AssignCourier');
  static PostToSlack = new ActionType('Rules::Actions::Communications::PostToSlack');
  static SendSMS = new ActionType('Rules::Actions::Communications::SendSMS');
  static SendRoboCall = new ActionType('Rules::Actions::Communications::SendRoboCall');
  static SendEmail = new ActionType('Rules::Actions::Communications::SendEmail');
  static SendDesktopNotification = new ActionType(
    'Rules::Actions::Communications::SendDesktopNotification'
  );
  static CreateCase = new ActionType('Rules::Actions::Communications::CreateCase');

  get category() {
    return this.split('::')[2];
  }

  get configurator() {
    return `rules/configurators/actions/${dasherize(this.split('::')[3])}-configurator`;
  }

  get label() {
    return capitalize(decamelize(this.split('::')[3])).replaceAll('_', ' ');
  }
}

@classic
export default class ActionTypeTransform extends AbstractEnumTransform {
  enumClass = ActionType;
}
