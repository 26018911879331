import classic from 'ember-classic-decorator';
import Service, { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import config from 'star-fox/config/environment';

//For integration tests
const appConfigurationsMock = [
  {
    key: 'third_party_logistics_settings',
    preferences: {
      courtesyCalls: {
        types: ['Courtesy Call to Customer', 'Courtesy Call to Restaurant'],
        caseReasons: [
          'Restaurant late',
          'Driver late',
          'Internal Error',
          'NOA',
          'Previous Restaurant Issue',
          'Previous Driver Issue'
        ],
        caseLateEstimates: [
          'N/A',
          '5 mins',
          '10 mins',
          '15 mins',
          '20 mins',
          '25 mins',
          '30 mins',
          '35 mins',
          '40 mins',
          '45 mins',
          '50 mins',
          '55 mins',
          '60+ mins'
        ]
      }
    }
  }
];

@classic
export default class AppConfigurationService extends Service {
  fetched;

  @service
  store;

  /** @type {Promise<boolean>} **/
  appConfigurationFetch = null;

  init() {
    super.init(...arguments);
    this.set('appConfigurationFetch', this.fetchAndUpdate());
  }

  async fetchAndUpdate() {
    // the app configuration keys are set on the object itself, so you can use it like:
    // appConfiguration.thirdPartyLogistics.preferences

    const appConfigurations = await this.fetchAppConfigurations();

    appConfigurations.map((appConfiguration) =>
      this.set(camelize(appConfiguration.key), appConfiguration)
    );
  }

  async fetchAppConfigurations() {
    if (config.environment === 'test') {
      return appConfigurationsMock;
    } else {
      this.fetched =
        this.fetched ||
        (await this.get('store').query('app-configuration', {
          page: {
            limit: 200
          }
        }));
      return this.fetched;
    }
  }
}
