import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { action } from '@ember/object';
import config from 'star-fox/config/environment';

const { Animation, Circle, Map, Marker } = google.maps;

const key = 'ee.food.maxRadius';

@classic
@classNames('fde-form-controls-delivery-radius')
export default class DeliveryRadiusControl extends Component {
  /** @type {{localDeliveryRadius: number, deliveryRadius: number, extendedDeliveryRadius: number}} */
  value = null;

  onChange() {}

  /** @type {{lat: number, lng: number}} */
  center = null;

  /** @type {number} */
  @alias('value.localDeliveryRadius')
  localDeliveryRadius;

  /** @type {Circle} */
  localDeliveryRadiusCircle = null;

  /** @type {number} */
  @alias('value.deliveryRadius')
  deliveryRadius;

  /** @type {Circle} */
  deliveryRadiusCircle = null;

  /** @type {number} */
  @alias('value.extendedDeliveryRadius')
  extendedDeliveryRadius;

  /** @type {Circle} */
  extendedDeliveryRadiusCircle = null;

  /** @type {Array<Object>} */
  restaurants = null;

  /** @type {Object} */
  highlightedRestaurant = null;

  /** @type {number} */
  maxRadius = null;

  /** @type {bool} */
  isDev = config.environment === 'development';

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.set('maxRadius', parseInt(localStorage.getItem(key) || 60));
    this.initMap();
    this.initMarkers();
  }

  @action
  commitRadius() {
    localStorage.setItem(key, this.get('maxRadius').toString());
  }

  initMap() {
    const $canvas = this.element.querySelector('.g-map-canvas');
    let center = this.get('center');

    if (
      center === null ||
      !this.get('localDeliveryRadius') ||
      !this.get('deliveryRadius') ||
      !this.get('extendedDeliveryRadius')
    ) {
      return;
    }

    center = {
      lat: center.lat || center.latitude,
      lng: center.lng || center.long || center.longitude
    };

    const map = new Map($canvas, {
      zoom: 12,
      center: center,
      streetViewControl: false,
      mapTypeControl: false
    });

    const localDeliveryRadiusCircle = new Circle({
      strokeWeight: 1,
      fillColor: '#00FF80',
      fillOpacity: 0.4,
      map,
      center: center,
      radius: this.get('deliveryRadius') * 1000
    });

    const deliveryRadiusCircle = new Circle({
      strokeWeight: 1,
      fillColor: '#80FF80',
      fillOpacity: 0.3,
      map,
      center: center,
      radius: this.get('deliveryRadius') * 1000
    });

    const extendedDeliveryRadiusCircle = new Circle({
      strokeWeight: 1,
      fillColor: '#FF0000',
      fillOpacity: 0.2,
      map,
      center: center,
      radius: this.get('extendedDeliveryRadius') * 1000
    });

    this.set(
      'marker',
      new Marker({
        position: center,
        zIndex: 1,
        label: 'C',
        map: map
      })
    );

    this.setProperties({
      map,
      localDeliveryRadiusCircle,
      deliveryRadiusCircle,
      extendedDeliveryRadiusCircle
    });

    // maybe put this in a timeout
    this.get('map').fitBounds(this.get('extendedDeliveryRadiusCircle').getBounds());
  }

  @observes('restaurants')
  reinitMarkers() {
    this.initMarkers();
  }

  @observes('center')
  reinitMap() {
    this.initMap();
    this.initMarkers();
  }

  @observes('localDeliveryRadius')
  updateLocalDeliveryRadiusCircle() {
    const localDeliveryRadiusCircle = this.get('localDeliveryRadiusCircle');
    if (localDeliveryRadiusCircle) {
      localDeliveryRadiusCircle.setRadius(this.get('localDeliveryRadius') * 1000);
    }
  }

  @observes('deliveryRadius')
  updateDeliveryRadiusCircle() {
    const deliveryRadiusCircle = this.get('deliveryRadiusCircle');
    if (deliveryRadiusCircle) {
      deliveryRadiusCircle.setRadius(this.get('deliveryRadius') * 1000);
    }
  }

  @observes('extendedDeliveryRadius')
  updateExtendedDeliveryRadiusCircle() {
    const extendedDeliveryRadiusCircle = this.get('extendedDeliveryRadiusCircle');
    if (extendedDeliveryRadiusCircle) {
      extendedDeliveryRadiusCircle.setRadius(this.get('extendedDeliveryRadius') * 1000);
    }
  }

  /**
   *  Bounces a highlighted restaurant
   */
  @observes('highlightedRestaurant')
  bounceSelectedMarker() {
    const bouncingMarker = this.get('bouncingMarker');
    if (bouncingMarker) {
      bouncingMarker.setAnimation(null);
    }

    if (this.get('highlightedRestaurant')) {
      const location = this.get('highlightedRestaurant.location');

      const marker = this.get('markers').find((marker) => {
        const position = marker.getPosition();
        return position.lat() === location.lat && position.lng() === location.lng;
      });

      if (marker) {
        marker.setAnimation(Animation.BOUNCE);
        this.set('bouncingMarker', marker);
      }
    }
  }

  /**
   * Init the markers for all of the passed in restaurants, lets also delete
   * any old markers
   */
  initMarkers() {
    const oldMarkers = this.get('markers');

    if (oldMarkers) {
      oldMarkers.forEach((marker) => marker.setMap(null));
    }

    const map = this.get('map');

    const allLocations = (this.get('restaurants') || []).mapBy('location');

    const markers = allLocations.map(
      (loc) =>
        new Marker({
          position: loc,
          zIndex: 1,
          label: 'R',
          map: map
        })
    );

    this.set('markers', markers);
  }

  _fireOnChange(localDeliveryRadius, deliveryRadius, extendedDeliveryRadius) {
    this.onChange({ localDeliveryRadius, deliveryRadius, extendedDeliveryRadius });
  }

  @action
  updateCircleRadius() {
    this.onChange(this.get('center'));
  }

  @action
  updateLocation(center) {
    this.set('center', center);
    this.get('circle').setCenter(center);

    let marker = this.get('marker');
    if (marker) {
      marker.setMap(null);
    }

    marker = new Marker({
      position: location.get('center'),
      zIndex: 1,
      label: 'C',
      map: this.get('map')
    });

    this.set('marker', marker);
  }

  @action
  handleLocalDeliveryRadiusChange(localDeliveryRadius) {
    const deliveryRadius = this.get('deliveryRadius');
    const extendedDeliveryRadius = this.get('extendedDeliveryRadius');

    this._fireOnChange(
      localDeliveryRadius,
      deliveryRadius > localDeliveryRadius ? deliveryRadius : localDeliveryRadius,
      extendedDeliveryRadius > localDeliveryRadius ? extendedDeliveryRadius : localDeliveryRadius
    );
  }

  @action
  handleDeliveryRadiusChange(deliveryRadius) {
    const localDeliveryRadius = this.get('localDeliveryRadius');
    const extendedDeliveryRadius = this.get('extendedDeliveryRadius');

    this._fireOnChange(
      localDeliveryRadius < deliveryRadius ? localDeliveryRadius : deliveryRadius,
      deliveryRadius,
      extendedDeliveryRadius > deliveryRadius ? extendedDeliveryRadius : deliveryRadius
    );
  }

  @action
  handleExtendedDeliveryRadiusChange(extendedDeliveryRadius) {
    const localDeliveryRadius = this.get('localDeliveryRadius');
    const deliveryRadius = this.get('deliveryRadius');

    this._fireOnChange(
      localDeliveryRadius < extendedDeliveryRadius ? localDeliveryRadius : extendedDeliveryRadius,
      deliveryRadius < extendedDeliveryRadius ? deliveryRadius : extendedDeliveryRadius,
      extendedDeliveryRadius
    );
  }
}
