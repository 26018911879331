import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import $ from 'jquery';
import { next } from '@ember/runloop';
import RSVP from 'rsvp';
import moment from 'moment-timezone';

@classic
export default class LoggedInController extends Controller {
  get pusherChannels() {
    return [
      'ee.food.internal.global.model-events',
      `ee.food.push-notifications.user.${this.session.user?.email ?? 'none'}`,
      ...this.session.user.roles.map((_) => `ee.food.push-notifications.role.${_.name}`)
    ];
  }

  /** @type {strings[]} */
  pusherEvents = [
    'pusher:subscription_succeeded',
    'updated',
    'created',
    'destroyed',
    'desktopNotification'
  ];

  /** @type {Service} */
  @service
  session;

  /** @type {Service} */
  @service
  formFor;

  /** @type {Service} */
  @service
  notify;

  /** @type {function} function to call resolving the destruction of the model */
  resolveDestroy() {}

  /** @type {function} function to call rejecting the destruction of the model */
  rejectDestroy() {}

  /** @type {DS.Model} model that we want to destroy */
  modelToDestroy = null;

  /** @type {boolean} */
  isDestroyingRecord = false;

  /**
   * Shows the destruction confirmation modal
   * @returns {RSVP.Promise}
   * @private
   */
  _showModal() {
    return new RSVP.Promise((resolve, _reject) => {
      next(() => {
        // this is couply as eff.
        $('#confirm-destroy-modal')
          .modal({
            closable: false,
            keyboardShortcuts: true,
            allowMultiple: true,
            context: '.ember-application',
            onVisible() {
              resolve();
            }
          })
          .modal('show');
      });
    }, 'Showing Confirmation Modal');
  }

  /**
   * Hides the destruction confirmation moddal
   * @returns {RSVP.Promise}
   * @private
   */
  _hideModal() {
    return new RSVP.Promise((resolve, _reject) => {
      $('#confirm-destroy-modal')
        .modal({
          onHidden() {
            resolve();
          }
        })
        .modal('hide');
    }, 'Hiding Confirmation Modal');
  }

  init(...args) {
    super.init(...args);
    this._initFormForm();
  }

  _initFormForm() {
    // here we are mounting confirm destroy to the form for service
    // this prevents us from having multiple instances of the confirm destroy
    // modal in cases where we have a list of items.
    this.get('formFor').confirmDestroy = (modelToDestroy, message = null) => {
      const modelLabel = modelToDestroy.get('humanize') || modelToDestroy;
      const confirmDestroyMessage = message
        ? message
        : `Are you sure you want to destroy ${modelLabel}?`;

      this.setProperties({
        modelToDestroy,
        confirmDestroyMessage
      });

      this._showModal();

      return new RSVP.Promise((resolve, reject) => {
        this.set('resolveDestroy', resolve);
        this.set('rejectDestroy', reject);
      }, 'Maybe Destroy a record').then(() => this._hideModal());
    };
  }

  @action
  confirmDestroy() {
    const model = this.get('modelToDestroy');
    const notify = this.get('notify');

    if (model && model.destroyRecord) {
      this.set('isDestroyingRecord', true);
      model
        .destroyRecord()
        .then((_) => this.get('resolveDestroy')(true))
        .catch((error) => {
          if (error.errors) {
            error.errors.forEach((err) => notify.error(err.title));
          } else {
            notify.error('An unexpected error occurred');
          }
        })
        .finally((_) => this.set('isDestroyingRecord', false));
    } else {
      this.get('resolveDestroy')(true);
    }
  }

  @action
  abortDestroy() {
    this.get('rejectDestroy')(true);
  }

  @action
  async desktopNotification({ subject, body, id }) {
    navigator.locks.request('desktopNotifications', async () => {
      const permission = await Notification.requestPermission();

      if (permission === 'granted' && this.hasNotReceivedMessage(id)) {
        this.recordReceiptOfMessage(id);

        new Notification(subject || 'Hello From The Rules Engine', { body: body });
        this.expireReceipts();
      }
    });
  }

  expireReceipts() {
    const receipts = Object.keys({ ...window.localStorage }).filter((_) =>
      _.startsWith('ee.food.desktopNotifications')
    );

    receipts.forEach((k) => {
      if (moment().diff(moment(localStorage.getItem(k)), 'minutes') > 1) {
        localStorage.removeItem(k);
      }
    });
  }

  recordReceiptOfMessage(id) {
    localStorage.setItem(`ee.food.desktopNotifications.${id}`, new Date().toISOString());
  }

  hasNotReceivedMessage(id) {
    return !localStorage.getItem(`ee.food.desktopNotifications.${id}`);
  }
}
