import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import EmberObject, { action } from '@ember/object';

@classic
export default class IndexController extends Controller {
  index = null;
  model = null;

  @service
  ajax;

  date = new Date();

  init() {
    super.init(...arguments);

    this.index = new Array(10).fill('');
    this.model = EmberObject.create({
      key: new Date()
    });
  }

  @action
  disableOrderAdvancer() {
    return this.get('ajax').request('/api/v3/app-configurations/disable-order-advancer', {
      method: 'POST'
    });
  }

  @action
  enableOrderAdvancer() {
    return this.get('ajax').request('/api/v3/app-configurations/enable-order-advancer', {
      method: 'POST'
    });
  }

  @action
  flushPageCache() {
    return this.get('ajax').request('/api/v3/app-configurations/flush-page-cache', {
      method: 'POST'
    });
  }

  @action
  flushFlipperCache() {
    return this.get('ajax').request('/api/v3/app-configurations/flush-flipper-cache', {
      method: 'POST'
    });
  }

  @action
  exportXero() {
    return this.get('ajax').postJSON('/api/v3/accounting-ledger-items/send-xero-export', {
      date: this.get('date')
    });
  }
}
