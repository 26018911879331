import classic from 'ember-classic-decorator';
import Model, { attr, hasMany } from 'renard/models/foodee';

@classic
export default class Company extends Model {
  /*
   * Attributes
   */
  @attr('string')
  name;

  @attr('number')
  clientCount;

  /*
   * Relationships
   */
  @hasMany('client')
  clients;
}
