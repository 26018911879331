import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';

import { action } from '@ember/object';

/**
 * Wrapper around "model-select-control",
 */
@classic
@classNames('fde-model-select-or-create-control')
@classNameBindings('icon:fde-has-icon')
export default class ModelSelectOrCreateControl extends Component {
  /** @type {Object} */
  form = null;

  /** @type {boolean} */
  showCreateForm = false;

  /** @type {boolean} */
  allowNoneSelection = false;

  /*
   * Pass-through values
   */
  /** @type {boolean} whether or not the model select component is in fluid mode */
  isFluid = true;

  /** @type {boolean} whether or not the model select component is in readonly mode */
  readonly = false;

  /** @type {boolean} */
  allowAdditions = false;

  /** @type {boolean} whether or not to sort selectable options alphabetically */
  orderByLabel = true;

  /** @type {string} The key on a value to use as the id */
  placeholder = 'None';

  /** @type {boolean} Can be set from above if 'none' should not be a selectable option */
  showPlaceholder = true;

  /** @type {Model[]} */
  values = null;

  /** @type {Model[]} */
  searchedValues = null;

  /** @type {Model[]|Model} */
  value = null;

  /** @type {?string} An optional relationship on the chosen selection to set as the new value */
  valuePath = null;

  /** @type {string} The key on a value to use as the id */
  idKey = 'id';

  /** @type {string} The key on a value to use as the label */
  labelKey = 'label';

  /** @type {string} The key on a value to use as the icon */
  iconKey = 'icon';

  /** @type {string} icon to render beside the control */
  icon = null;

  /** @type {?string} */
  noneText = 'None';

  /** @type {?function} */
  onEnter = null;

  /** @type {?function} Runs an optional callback after a user has finished typing */
  onSearchChange = null;

  onChange() {}
  onAdd() {}

  @action
  handleDidCreate(newValue) {
    this.onChange(newValue);
    this.set('showCreateForm', false);
  }
}
