import classic from 'ember-classic-decorator';
import Model, { attr, hasMany } from 'renard/models/foodee';

/**
 * @typedef Roles
 * @type {Model}
 */
@classic
export default class Role extends Model {
  /*
   * Attributes
   */
  /** @property {string} */
  @attr('string')
  name;

  /*
   * Relationships
   */
  /** @type {User} */
  @hasMany('user', { inverse: 'roles' })
  users;
}
