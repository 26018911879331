import classic from 'ember-classic-decorator';
import ApplicationAdapter from './application';

@classic
export default class Client extends ApplicationAdapter {
  resetApiToken(model) {
    const modelName = model.get('constructor.modelName');
    const url = `${this.buildURL(modelName, model.get('id'))}/reset-api-token`;

    model.set('isResettingApiToken', true);

    return this.ajax(url, 'POST')
      .then((_) => model.reload())
      .finally((_) => {
        model.set('isResettingApiToken', false);

        return _;
      });
  }
}
