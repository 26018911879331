import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class PlanningRoute extends Route.extend(CapabilityRouteMixin) {
  queryParams = {
    area: { refreshModel: true },
    monday: { refreshModel: true }
  };

  model(_params) {
    return RSVP.hash({
      areas: this.store.query('area', {
        page: { limit: 100 },
        fields: { areas: 'city,delivery-lead-time,iso-time-zone' },
        filter: { active: true }
      })
    });
  }
}
