import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action } from '@ember/object';

@classic
@classNames('fde-new-team-user-form-for')
export default class NewClientTeamFormFor extends Component {
  /** @type {Service} */
  @service
  store;

  /** @type {Service} */
  @service
  notify;

  /** @type {boolean} */
  readonly = false;

  /** @type {UserInvite} */
  newTeam = null;

  /** @type {Client} */
  client = null;

  /** @type {boolean} */
  showNewTeamForm = false;

  addNewTeam() {}

  /** Creates a new User for the Client */
  @action
  resetTeam() {
    this.setProperties({
      showNewTeamForm: false,
      newTeam: this.get('store').createRecord('team', {
        client: this.get('client')
      })
    });
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.resetTeam();
  }
}
