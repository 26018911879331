import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-controls-action-button-action-option item')
@classNameBindings('disabled:disabled')
export default class ActionOption extends Component {
  /** @type {string} the text to display in the button */
  label = null;

  /** @type {boolean} */
  disabled = false;

  /** @type {string} an optional icon to show to the left of the option */
  icon = null;

  click() {}
}
