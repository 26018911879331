import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { filterBy, not } from '@ember/object/computed';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';

import MenuGroupValidator from 'star-fox/models/validators/menu-group';

@classic
export default class MenuGroup extends Model.extend(MenuGroupValidator) {
  /*
   * Attributes
   */
  @attr('boolean', { defaultValue: false })
  active;

  @attr('boolean', { defaultValue: false })
  isCutlery;

  @attr('date')
  createdAt;

  @attr('string')
  description;

  @attr('boolean', { defaultValue: false })
  internal;

  @not('internal')
  external;

  @attr('number')
  maximumQuantity;

  @attr('number')
  minimumQuantity;

  @attr('number', { defaultValue: 1 })
  maximumServing;

  @attr('number', { defaultValue: 1 })
  minimumServing;

  @attr('string')
  name;

  @computed('code')
  get humanize() {
    return `Menu Group: '${this.get('name')}'`;
  }

  @computed('code')
  get position() {
    const menu = this.get('menu');
    return menu ? menu.positionOf(this) : 1;
  }

  /*
   * Relationships
   */
  @belongsTo('menu', { async: false })
  menu;

  @hasMany('menu-items', { async: false })
  menuItems;

  @hasMany('food-type', { async: false, inverse: 'menuGroups' })
  foodTypes;

  @hasMany('meal-type', { async: false, inverse: 'menuGroups' })
  mealTypes;

  /**
   * Computed
   */
  @filterBy('menuItems', 'active', true)
  activeMenuItems;

  /*
   * Functions
   */

  matches(requirement) {
    return this.get('menuItems')
      .toArray()
      .some((_) => _.matches(requirement));
  }

  /**
   * Duplicates the MenuGroup that this method is called on
   * @return {MenuGroup} Returns the newly made MenuGroup
   */
  duplicate() {
    const modelName = this.constructor.modelName;
    const adapter = this.store.adapterFor(modelName);

    this.set('isDuplicating', true);

    return adapter
      .duplicate(this, 'menu-items.menu-option-groups')
      .then((_) => {
        const menu = this.get('menu');
        const menuGroups = menu.get('menuGroups').toArray();
        const index = menuGroups.indexOf(this);

        menuGroups.removeObject(_);
        menuGroups.insertAt(index + 1, _);

        menu.set('menuGroups', menuGroups);
        menu.save();

        return _;
      })
      .finally((_) => this.set('isDuplicating', false));
  }
}
