import classic from 'ember-classic-decorator';
import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class MealPlanningReservationRoute extends Route.extend(CapabilityRouteMixin) {
  /** @type {String} */
  capability = 'CLIENT_MEALPLAN_SHOW';

  /**
   * @param {Object} params
   *
   * @returns {Promise} Client and MealPlanningTemplates
   */
  model(_params) {
    const client = this.modelFor('logged-in.clients.edit');

    return RSVP.hash({
      client: client,
      restaurants: this.store.query('restaurant', { filter: { area: 'Vancouver' } }),
      reservations: client.query('mealPlanningReservations', { include: 'restaurant,client,area' })
    });
  }
}
