import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

@classic
export default class CreateCaseConfigurator extends Component {
  @service
  appConfiguration;

  @alias('appConfiguration.salesforce.preferences.courtesyCalls')
  salesforceConfiguration;

  @alias('salesforceConfiguration.types')
  caseTypes;

  @alias('salesforceConfiguration.caseReasons')
  caseReasons;

  @alias('salesforceConfiguration.caseLateEstimates')
  caseEstimates;
}
