/**
 * Groups an Array of Ember Objects by a given attr
 * @param  {Array} arr - An array of Ember Objects
 */
export function flatten(arr) {
  return arr.reduce((_, acc) => acc.concat(_), []);
}

/**
 * Groups an Array of Ember Objects by a given attr into sections
 * @param {Array} values
 * @param {String} sectionByPath
 */
export function section(values, sectionByPath) {
  const groupedValues = values.reduce((acc, value) => {
    const key = value.get(sectionByPath);
    const section = acc[key] || (acc[key] = { title: key, values: [] });

    section.values.push(value);

    return acc;
  }, {});

  return Object.values(groupedValues);
}
