import classic from 'ember-classic-decorator';
import { classNameBindings, classNames, tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@ember/component';

@classic
@tagName('tr')
@classNames('fde-couriers-courier-row ui form')
@classNameBindings('isEditing::fde-can-click', 'isEditing::fde-highlight')
export default class CourierRow extends Component {
  /** @type {Service} */
  @service
  router;

  /** @type {Service} */
  @service
  appConfiguration;

  /** @type {boolean} */
  isEditing = false;

  /** @type {Courier} */
  courier = null;

  /** @type {Area[]} */
  areas = null;

  onDiscardChanges() {}
  onSaveCourier() {}

  click() {
    if (!this.get('isEditing')) {
      this.get('routing').transitionTo('logged-in.logistics.couriers.show', [
        this.get('courier.id')
      ]);
    }
  }

  @action
  discardChanges() {
    this.set('isEditing', false);
    this.onDiscardChanges();
    return false;
  }
}
