import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias, notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import RSVP from 'rsvp';
import { action, computed } from '@ember/object';
import PusherBindingsMixin from 'star-fox/mixins/pusher-bindings-mixin';
import moment from 'moment-timezone';
import ENV from 'star-fox/config/environment';

@classic
@classNames('fde-accounting-ledger-item')
export default class LedgerItem extends Component.extend(PusherBindingsMixin) {
  /** @type {Store} */
  @service
  store;

  /** @type {PusherService} **/
  @service
  pusher;

  /** @type {ActionCableService} **/
  @service
  actionCable;

  /** @type {UserSessionService} **/
  @service
  userSession;

  /* Pusher events we listen to */
  pusherEvents = ['ledger-processing', 'ledger-processed', 'pusher:subscription_succeeded'];

  /**
   * @type {string} the name of the channel that we use for presence,
   * defaults to ee.food.orders.{id}.model-events
   */
  @computed('ledger')
  get channelName() {
    return `ee.food.accounting.ledgers.${this.ledger.id}.events`;
  }

  /** @type {boolean} */
  @computed('userSession.session.user')
  get useNewPubSub() {
    return this.userSession.session.user?.hasFeatureEnabled('newPubSub') ?? false;
  }

  /** @type {string} */
  @computed('useNewPubSub')
  get pubSub() {
    return this.useNewPubSub
      ? { service: this.actionCable, name: 'cable' }
      : { service: this.pusher, name: 'pusher' };
  }

  didInsertElement() {
    super.didInsertElement(...arguments);

    try {
      this.pubSub.service.wire(this, this.channelName, this.pusherEvents);

      console.log(
        `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Connecting to ${this.channelName}`
      );
    } catch (e) {
      console.log(
        `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Could not wire from ${
          this.channelName
        }`
      );
    }
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);

    try {
      this.pubSub.service.unwire(this, this.channelName, this.pusherEvents);
    } catch (e) {
      console.log(
        `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Could not unwire from ${
          this.channelName
        }`
      );
    }
  }

  /** @type {boolean} */
  readonly = false;

  /** @type {AccountingLedgerItem|InvoicingLedgerItem} */
  ledger = null;

  /** @type {Client} */
  client = null;

  /** @type {PaymentCard} */
  @alias('run.configuration.paymentCard')
  paymentCard;

  /** @type {boolean} */
  @notEmpty('paymentCard')
  canCharge;

  /** @type {string} */
  groupBy = 'GroupOrderMember';

  @computed('ledger.aggregatedLedger.lineItemsByMenuGroup.[]')
  get itemsGroupedByMenuGroup() {
    return Object.entries(
      this.get('ledger.aggregatedLedger.lineItemsByMenuGroup.firstObject') || {}
    ).map(([name, lineItem]) => ({ label: name, values: lineItem }));
  }

  @computed('ledger.aggregatedLedger.lineItemsByGroupOrderMember.[]')
  get itemsGroupedByGroupOrderMember() {
    return Object.entries(
      this.get('ledger.aggregatedLedger.lineItemsByGroupOrderMember') || {}
    ).map(([_, values]) => ({ label: values.name, values: values.items }));
  }

  @computed('groupBy', 'itemsGroupedByMenuGroup.[]', 'itemsGroupedByGroupOrderMember.[]')
  get groupedItems() {
    return this.get('groupBy') === 'MenuGroup'
      ? this.get('itemsGroupedByMenuGroup')
      : this.get('itemsGroupedByGroupOrderMember');
  }

  @computed
  get colspan() {
    return (
      2 +
      [2 * !this.get('hideTax'), !this.get('hideDescription'), !this.get('hideUnitAmount')].reduce(
        (acc, i) => acc + i
      )
    );
  }

  /**
   * Perform search on both types of ledger legacy && nnew
   *
   * @param {String} search
   * @return {Promise<Ledger[]>}
   */
  @action
  async doSearch(search) {
    const ledgers = await RSVP.all([
      this.get('store').query('accounting-ledger-item', {
        filter: {
          search,
          not_ledger_type: 'Accounting::ConsolidatedInvoice',
          without_parent: true,
          same_recipient_as: this.get('ledger.id')
        }
      }),
      this.get('store').query('invoicing-ledger-item', {
        filter: {
          search,
          ledger_type: 'ClientInvoice',
          without_parent: true,
          same_recipient_as: this.get('ledger.id')
        }
      })
    ]);

    return ledgers.flatMap((_) => _.toArray());
  }

  didCharge() {}
  didCredit() {}

  @action
  async charge() {
    await this.get('ledger').charge({ card_id: this.get('paymentCard.id') });

    this.didCharge();
  }

  @action
  pusher__subscriptionSucceeded() {
    // eslint-disable-next-line no-console
    console.log(
      `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Connected to ${this.channelName}`
    );
  }

  @action
  refreshLedger() {
    this.get('ledger').reload();
  }

  ledgerProcessing() {}

  @action
  ledgerProcessed() {
    this.get('ledger').reload();
  }

  @action
  closeLedger() {
    return this.get('ledger').close();
  }

  @action
  async syncLedger(ledger) {
    const ret = await ledger.sync();
    await ledger.reload();
    ledger.set('log', ret.log);
  }

  get stripeHost() {
    return ENV.stripeHost;
  }

  get salesforceHost() {
    return ENV.salesforceHost;
  }
}
