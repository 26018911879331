import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

/**
 * Container for examples of component use - see http://concierge.food.ee/starfox/dev-team
 */
@classic
@classNames('fde-component-example')
export default class ComponentExample extends Component {}
