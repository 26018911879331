import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import FilterRouteMixin from 'star-fox/mixins/filter-route-mixin';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';

export default class ShowRoute extends Route.extend(FilterRouteMixin, LoadingRouteMixin) {
  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    status: { refreshModel: true },
    searchText: { refreshModel: true },
    created_at_range: { refreshModel: true }
  };

  filters = [
    {
      key: 'status',
      icon: 'tags',
      label: 'Status'
    },
    {
      key: 'created_at_range',
      icon: 'calendar',
      label: 'Created Between'
    }
  ];

  async model(params) {
    const { pageLimit, pageOffset, searchText, status, created_at_range, integration_name } =
      params;

    return RSVP.hash({
      integrationName: integration_name,
      logRecords: this.store.query('integration-log-record', {
        page: {
          limit: pageLimit,
          offset: pageOffset
        },
        filter: {
          search: searchText,
          status: status,
          created_at_range: created_at_range,
          integration_name: integration_name
        }
      })
    });
  }
}
