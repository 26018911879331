import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class LateNotification extends Model {
  /*
   * Attributes
   */
  @attr('string')
  message;

  /*
   * Relationships
   */
  @belongsTo('order')
  order;

  @belongsTo('contact')
  contact;

  /*
   * Validations
   */
  validations = {
    message: {
      presence: {
        message: "Message can't be blank"
      }
    },
    contact: {
      presence: {
        message: 'No contact specified'
      }
    }
  };
}
