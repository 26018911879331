import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class ShowRoute extends Route.extend(CapabilityRouteMixin, LoadingRouteMixin) {
  capability = 'LOGISTICS_COURIER_SHOW';
  bubblesLoadingState = true;

  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    sort: { refreshModel: true },
    searchText: { refreshModel: true }
  };

  /**
   * @param {Object} params
   * @returns {{courer: Courier, drivers: User[]}}
   */
  model(params) {
    const { pageLimit, pageOffset, sort, searchText } = params;

    const courier = this.store.findRecord('courier', params.courier_id);

    return RSVP.hash({
      courier,
      drivers: courier.then((courier) =>
        courier.query('drivers', {
          page: {
            limit: pageLimit,
            offset: pageOffset
          },
          filter: {
            name: searchText
          },
          sort
        })
      )
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
