import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@tagName('')
export default class MenuGroup extends Component {
  @service
  session;

  areaTaxRates = [];

  /** @type {MenuGroup} the MenuGroup being displayed **/
  menuGroup = null;

  /** @type {string} the key for the menuItems relationship **/
  menuItemsKey = 'menuItems';

  /** @type {boolean} whether or not a group is being dragged **/
  isDraggingGroup = false;

  /** @type {boolean} whether or not an item is being dragged **/
  isDraggingItem = false;

  /** @type {function} checks what orders are affected by menu changes */
  checkAffectedOrders() {}

  /** @type {function} callback for when we duplicate a menu-group **/
  onDuplicate() {}

  /** @type {function} callback for when we create a new menu-item **/
  onCreateMenuItem() {}

  /** @type {function} callback for when we create edit a menu-item **/
  onEditMenuItem() {}

  /** @type {function} the callback after sorting the menu items **/
  onMenuItemsChange() {}

  onDragStart() {}
  onDragStop() {}

  /** @type {MealType[]} =*/
  @computed('availableMealTypes.[]', 'menuGroup.mealTypes.[]')
  get selectableMealTypes() {
    const mealTypes = this.get('menuGroup.mealTypes') || [];
    const anyExclusive = mealTypes.any((_) => _.get('isExclusive'));
    const availableMealTypes = this.get('availableMealTypes') || [];

    return anyExclusive
      ? availableMealTypes.rejectBy('isExclusive').concat(mealTypes).uniq()
      : availableMealTypes;
  }

  @action
  handleDragStart() {
    this.toggleProperty('isDraggingGroup');
    this.onDragStart();
  }

  @action
  handleDragStop() {
    this.toggleProperty('isDraggingGroup');
    this.onDragStop();
  }

  @action
  handleItemDragStart() {
    this.toggleProperty('isDraggingItem');
  }

  @action
  handleItemDragStop() {
    this.toggleProperty('isDraggingItem');
  }
}
