import classic from 'ember-classic-decorator';
import Model, { attr } from 'renard/models/foodee';

/**
 * @class Currency
 */
@classic
export default class CurrenciesCurrency extends Model {
  @attr('string')
  name;

  @attr('string')
  symbol;
}
