import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import { computed } from '@ember/object';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';

import { capitalize } from '@ember/string';

@classic
export default class MenuOptionGroup extends Model {
  /*
   * Attributes
   */
  @attr('boolean', { defaultValue: false })
  isRequired;

  @attr('boolean', { defaultValue: false })
  isSingleOpt;

  @attr('boolean', { defaultValue: false })
  isPackagedSeparately;

  @attr('string')
  name;

  @attr('string')
  verb;

  @attr('number')
  position;

  /*
   * Relationships
   */
  @belongsTo('menu-item', { async: false, inverse: 'menuOptionGroups' })
  menuItem;

  @hasMany('menu-option-item', { async: false, inverse: 'menuOptionGroup' })
  menuOptionItems;

  /*
   * Computed Properties
   */

  /** @type {boolean} */
  @computed('verb')
  get requiresName() {
    return this.get('verb') !== 'includes';
  }

  /** @type {boolean} */
  @computed('verb')
  get isChoosable() {
    return this.get('verb') !== 'includes';
  }

  /** @type {string} */
  @computed('name', 'verb')
  get labelName() {
    return `${capitalize(this.get('verb'))} ${this.get('name').toLowerCase()}`;
  }

  /** @type {string} */
  @computed('labelName')
  get humanize() {
    return `Option group: '${capitalize(this.get('labelName').toLowerCase())}'`;
  }

  /** @type {DietaryTag[]} */
  @computed('menuOptionItems.[]')
  get dietaryTags() {
    return this.get('menuOptionItems')
      .mapBy('dietaryTags')
      .reduce((acc, tags) => acc.concat(tags.toArray()), []);
  }

  @observes('verb')
  verbDidChange() {
    const verb = this.get('verb');

    this.set('isSingleOpt', verb === 'pick' || verb === 'substitute' || verb === 'includes');

    if (verb === 'includes') {
      this.set('name', '');
    }
  }

  @computed('menuOptionItems.[]')
  get combinations() {
    if (!this.get('isChoosable')) {
      return [];
    }
    const menuOptionItems = this.get('menuOptionItems');

    if (this.get('isSingleOpt')) {
      return [[]].concat(menuOptionItems.map((_) => [_]));
    } else {
      return new Array(Math.pow(2, menuOptionItems.length)).fill('').map((_, index) => {
        // Generate a binary string that represents the assignment (or non assignement)
        // of a MOI
        const assignmentString = index.toString(2).padStart(menuOptionItems.length, '0');

        return assignmentString
          .split('')
          .map((a, i) => (a === '1' ? menuOptionItems.objectAt(i) : null))
          .filter((_) => !!_);
      });
    }
  }
}
