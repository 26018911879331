import { attr } from '@ember-data/model';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import MF from 'ember-data-model-fragments';

import { fragmentArray } from 'ember-data-model-fragments/attributes';

import { Time } from '../../../transforms/time';
import moment from 'moment-timezone';

export default MF.Fragment.extend({
  /*
   * Attributes
   */
  dayOfWeek: attr('number'),
  name: attr('string'),
  meals: fragmentArray('fragments/meal-planning/meal'),
  isTemplate: true,

  /*
   * Computed Properties
   */

  /** @type {boolean} */
  canAddMeal: computed('meals.length', function () {
    return this.get('meals.length') < 4;
  }),

  /** @type {boolean} */
  // isEmpty: computed('slots.[]', function () {
  //   return this.get('slots.length') === 0;
  // }),

  restaurantConstraintIds: computed('meals.@each.restaurantConstraintCount', function () {
    return this.get('meals').mapBy('restaurantConstraintIds').flat();
  }),

  restaurantConstraintCount: alias('restaurantConstraintIds.length'),

  shortName: computed('name', function () {
    return this.name && this.name.slice(0, 3);
  }),

  /**
   * Adds a meal to the day-fragment's meals fragmentArray
   *
   * @param {number} numberOfPeople
   * @param {FragmentContact} contact
   * @param {FragmentLocation} location
   * @param {number} numberOfSlots
   * @param {number} leadTime
   */
  addMeal(numberOfPeople, contact, location, numberOfSlots = 1, leadTime = 21) {
    return this.get('meals').createFragment({
      name: 'New Meal',
      numberOfPeople: numberOfPeople,
      leadTime: leadTime,
      deliverAt: new Time('12:00 PM'),
      // re: pickupAt
      // this should probably be a calculation handled by MF
      // but there's an issue with meal slots/reservations
      // needing access to the pickup at
      pickupAt: new Time('11:40 AM'),
      slots: new Array(numberOfSlots).fill({})
    });
  },

  /**
   * Removes a meal from the day-fragment's meals fragmentArray
   *
   * @param {MealFragment} meal
   */
  removeMeal(meal) {
    this.set('meals', this.get('meals').without(meal));
  },

  /**
   * Gets the date of the day given a start of week date.
   * @param {Date} startOfWeek
   */
  getDate(startOfWeek) {
    return moment(startOfWeek).add(this.get('dayOfWeek'), 'days').toDate();
  }
});
