import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { htmlSafe } from '@ember/template';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { filter } from 'macro-decorators';

@classic
@classNames('fde-preference-profile-form-for')
export default class PreferenceProfileFormFor extends Component {
  /** @type {boolean} */
  autoSubmit = true;

  /** @type {Service} */
  @service
  store;

  /** @type {Service} */
  @service
  restaurantSearch;

  /** @type {boolean} */
  showGroups = false;

  /** @type {boolean} */
  hideTastes = false;

  /** @type {boolean} */
  hideRestaurantSearch = false;

  /** @type {boolean} */
  leadTime = null;

  /** @type {string} */
  activeTab = 'Team';

  /** @type {Preferee | Promise<Preferee>>} */
  preferee = null;

  /** @type {Client} */
  client = null;

  /** @type {MealPlanningPreferenceProfile} */
  preferenceProfile = null;

  /** @type {MealPlanningInstance} */
  instance = null;

  /** @type {MealPlanningRequirementGroup} */
  selectedRequirementGroup = null;

  /** @type {boolean} */
  mealPlanning = false;

  /** @type {boolean} */
  forTeamOrder = true;

  location = null;

  @filter('searchResults.restaurants', (r) => r.distance?.inDeliveryRadius)
  restaurantsInDeliveryRadius;

  @filter('searchResults.restaurants', (r) => r.distance?.inExtendedRadius)
  restaurantsInExtendedRadius;

  @filter('searchResults.restaurants', (r) => r.distance?.beyondExtendedRadius)
  restaurantsBeyondExtendedRadius;

  @computed('location', 'location.content')
  get deliveryLocation() {
    return this.get('location.content') || this.get('location');
  }

  didCreatePreferenceProfile() {}

  /** @type {Section[]} */
  @computed('preferenceProfile.requirementGroups.[]')
  get requirementGroupsSectionList() {
    return [
      {
        title: 'Requirement Groups',
        infoTitle: `What's a requirement group?`,
        infoContent: htmlSafe(`
<p>
  A requirement group collects requirements with respect to a single type of person's needs - its most common use
  case would be when building combinations of items for distinct groups within an order.
</p>

<p>
  An example of a requirement group is a: '10 x An entree that is gluten free, a side that is gluten free, and a
  drink that are all under 20 dollars'
</p>
      `),
        data: this.get('preferenceProfile.requirementGroups')
      }
    ];
  }

  /** @type {Restaurant[]} */
  restaurants = [];

  /** @type {Restaurant[]} */
  searchedRestaurants = [];

  /** Event that emits the requirement selected */
  onRequirementSelect() {}

  /** Event that emits the requirement group selected */
  onRequirementGroupSelect() {}

  onTabChange() {}

  /** @override */
  didInsertElement() {
    super.didInsertElement(...arguments);
    this.get('loadTask').perform();
    this.selectProfile(this.forTeamOrder ? 'Team' : 'Regular');
  }

  /** @override */
  didUpdateAttrs() {
    super.didUpdateAttrs();
    this.get('loadTask').perform();
  }

  @task(function* () {
    const preferee = yield this.get('preferee');
    this.set('_preferee', preferee);
    this.set('preferenceProfile', yield preferee.get('preferenceProfile'));

    this.get('loadSelectedRequirementGroupTask').perform();

    if (!this.get('hideRestaurantSearch')) {
      this.get('searchTask').perform();
      this.get('fetchAreasTask').perform();
    }

    if (!this.get('hideTastes')) {
      this.get('loadRestaurantsForClientTask').perform();
      this.get('loadCuisineTypeTask').perform();
    }

    const deliveryLocations = yield preferee.get('deliveryLocations');

    if (deliveryLocations) {
      const locations = deliveryLocations.mapBy('location');
      this.setProperties({
        locations,
        location: locations.get('firstObject')
      });
    }
  })
  loadTask;

  @task(function* () {
    const areas = yield this.get('_preferee.areas');
    const areaIds = (areas || []).map((_) => _.get('id'));
    return yield this.get('store').query('restaurant', {
      filter: { areas: areaIds },
      page: { limit: 300, offset: 0 }
    });
  })
  loadRestaurantsForClientTask;

  @task(function* () {
    return yield this.get('store').query('tag', {
      filter: { tagType: 'cuisine' },
      page: { limit: 300, offset: 0 }
    });
  })
  loadCuisineTypeTask;

  /** @type {task} */
  @(task(function* () {
    const preferenceProfile = yield this.get('preferenceProfile');

    if (!preferenceProfile) {
      return;
    }

    this.selectFirstRequirement();

    const selectedRequirementGroup = this.get('selectedRequirementGroup');
    const isReloadable =
      selectedRequirementGroup &&
      !selectedRequirementGroup.get('isNew') &&
      !selectedRequirementGroup.get('isDeleted') &&
      !selectedRequirementGroup.get('markReloaded');

    if (isReloadable) {
      yield selectedRequirementGroup.reload();
      selectedRequirementGroup.set('markReloaded', true);
      return selectedRequirementGroup;
    } else {
      return Promise.resolve(selectedRequirementGroup);
    }
  }).restartable())
  loadSelectedRequirementGroupTask;

  /** @type {task} */
  @(task(function* () {
    return yield this.get('store').query('area', {
      filter: { active: true },
      page: { limit: 100 }
    });
  }).restartable())
  fetchAreasTask;

  /** @type {task} */
  @(task(function* () {
    yield timeout(300);

    const searchable = this.get('useInstanceToSearch')
      ? this.get('instance')
      : this.get('preferenceProfile');

    const query = {
      forTeamOrder: this.get('forTeamOrder'),
      locationId: this.get('location.locationId')
    };

    if (this.area) {
      query.area = this.get('area.id');
    }

    if (this.get('activeTab') === 'Team' && this.get('mealPlanning')) {
      query.forFoodeePlusTeamOrder = true;
    }

    if (this.get('leadTime')) {
      query.leadTime = this.get('leadTime');
    }

    return yield searchable &&
      searchable.search &&
      this.restaurantSearch.searchRecordRestaurants(searchable, query);
  }).restartable())
  searchTask;

  /** @type {{restaurants: []}} */
  @alias('searchTask.lastSuccessful.value')
  searchResults;

  /** @type {Area} */
  area = null;

  /** @type {Area[]} */
  @alias('fetchAreasTask.lastSuccessful.value')
  areas;

  selectFirstRequirement() {
    const preferenceProfile = this.get('preferenceProfile');

    if (!this.get('selectedRequirementGroup')) {
      this.set('selectedRequirementGroup', preferenceProfile.get('requirementGroups.firstObject'));
    }
  }

  selectLastRequirement() {
    const preferenceProfile = this.get('preferenceProfile');

    if (!this.get('selectedRequirementGroup')) {
      this.set('selectedRequirementGroup', preferenceProfile.get('requirementGroups.lastObject'));
    }
  }

  /** @type {task} */
  @task(function* () {
    const requirementGroup = yield this.get('preferenceProfile').createRequirementGroup();
    this.set('selectedRequirementGroup', requirementGroup);
    yield this.get('loadSelectedRequirementGroupTask').perform();
    const searchTask = this.get('searchTask');

    if (searchTask) {
      searchTask.perform();
    }
  })
  addRequirementGroupTask;

  /** @type {task} */
  @task(function* () {
    const selectedRequirementGroup = this.get('selectedRequirementGroup');
    yield selectedRequirementGroup && selectedRequirementGroup.destroyRecord();
    this.set(
      'selectedRequirementGroup',
      this.get('preferenceProfile.requirementGroups.lastObject')
    );
    yield this.get('loadSelectedRequirementGroupTask').perform();

    const searchTask = this.get('searchTask');

    if (searchTask) {
      searchTask.perform();
    }
  })
  destroySelectedRequirementGroupTask;

  /** @type {MealPlanningRequirementGroup} */
  @alias('loadSelectedRequirementGroupTask.lastSuccessful.value')
  loadedSelectedRequirementGroup;

  /** @type {task} */
  @(task(function* () {
    yield this.get('_preferee').createPreferenceProfile();
    yield this.get('_preferee').belongsTo('preferenceProfile').reload();
    this.didCreatePreferenceProfile(this.get('_preferee.preferenceProfile.content'));
    this.get('loadTask').perform();
  }).drop())
  createPreferenceProfileTask;

  /** @type {task} */
  @task(function* () {
    yield this.get('preferenceProfile').createRequirement();
    yield this.get('loadTask').perform();
    const searchTask = this.get('searchTask');

    if (searchTask) {
      searchTask.perform();
    }
  })
  addRequirementTask;

  @action
  selectProfile(name) {
    if (name === 'Team') {
      this.set('forTeamOrder', true);
    }
    if (name === 'Regular') {
      this.set('forTeamOrder', false);
    }
    this.set('activeTab', name);
    this.get('searchTask').perform();
    this.onTabChange(name);
  }

  @action
  selectArea(area) {
    this.set('area', area);
    this.get('searchTask').perform();
  }

  @action
  handleRequirementGroupSelect(requirementGroup) {
    this.set('selectedRequirementGroup', requirementGroup);
    this.get('loadSelectedRequirementGroupTask').perform();
    this.onRequirementGroupSelect(requirementGroup);
  }

  @action
  handleAddGroupClick() {
    this.get('addRequirementGroupTask').perform();
  }

  @action
  handleRemoveGroupClick() {
    this.get('destroySelectedRequirementGroupTask').perform();
  }

  @action
  handleCreatePreferenceProfileClick() {
    this.get('createPreferenceProfileTask').perform();
  }

  @action
  handleAddRequirementClick() {
    this.get('addRequirementTask').perform();
  }

  @action
  updateLocation(location) {
    this.set('location', location);
    this.get('searchTask').perform();
  }
}
