import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';

import { action } from '@ember/object';

@classic
export default class NewController extends Controller {
  @action
  goBackToIndex() {
    this.send('refreshModel');
    this.transitionToRoute('logged-in.tags.top-cuisine');
  }

  @action
  handleCancelNewTopCuisine() {
    const { topCuisine } = this.get('model');
    if (topCuisine) {
      if (!topCuisine.id) {
        topCuisine.destroyRecord();
      } else {
        topCuisine.rollbackAttributes();
      }
    }
    this.transitionToRoute('logged-in.tags.top-cuisine');
  }

  @action
  scrollIntoView() {
    const topCuisineEditor = document.querySelector('.top-cuisine-editor');

    if (topCuisineEditor) {
      topCuisineEditor.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest'
      });
    }
  }

  get isTopCuisineSaved() {
    return this.model.topCuisine?.get('id');
  }
}
