import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';

@classic
export default class AccountingRoute extends Route {
  orderEditRoute = 'logged-in.orders.edit';

  /** @override */
  queryParams = {
    memberPageLimit: { refreshModel: true },
    memberPageOffset: { refreshModel: true },
    groupOrderMemberSearchQuery: { refreshModel: true }
  };

  /** @override */
  model({ memberPageLimit, memberPageOffset, groupOrderMemberSearchQuery }) {
    const { order } = this.modelFor(this.get('orderEditRoute'));

    return RSVP.hash({
      order: order,
      client: order.get('client'),
      restaurant: order.get('restaurant'),

      // old
      clientInvoice: order.get('clientInvoice'),
      restaurantInvoice: order.get('restaurantInvoice'),

      // new
      invoice: order.get('invoice'),
      restaurantBill: order.get('restaurantBill'),
      courierBill: order.get('courierBill'),
      ledgers: order.query('ledgerItems'),
      groupOrderMembers: order.query('groupOrderMembers', {
        page: {
          limit: memberPageLimit,
          offset: memberPageOffset
        },
        filter: {
          search: groupOrderMemberSearchQuery
        },
        sort: 'name'
      })
    });
  }

  /** @override */
  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('orderEditRoute', this.get('orderEditRoute'));
    controller.setupPusher();
    controller.set('ledgers', [model.invoice, model.restaurantBill, model.courierBill]);
    controller.set('legacyLedgers', [model.clientInvoice, model.restaurantInvoice].filter(Boolean));
  }

  /** @override */
  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);

    controller.teardownPusher();
  }

  @action
  reloadLedgers() {
    return this.refresh();
  }
}
