import Fixture, {
  element,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class SfControlsActionOrderStateButtonFixture extends Fixture {
  @element({ selector: 'data-test-sf-controls-action-button__label' }) label;
  @element({ selector: 'data-test-sf-controls-action-option', value: 'Driver at Restaurant' })
  arriveAtRestaurant;
  @element({ selector: 'data-test-sf-controls-action-option', value: 'Cancel' })
  cancel;

  constructor() {
    super('[data-test-sf-controls-action-order-state-button]');
  }

  // TODO: flesh all the way out and verify will be hard to test the async things as they require stubbing aciton cable
  clickOption(optionName) {
    this.click();
    this[optionName].click();
  }

  shouldBeLoading() {
    this.should.have.class('loading');
  }

  shouldNotBeLoading() {
    this.should.not.have.class('loading');
  }

  shouldHaveLabel(label) {
    this.label.should.textContent(label);
  }
}

export const sfControlsActionOrderStateButton = getElementDecoratorFor(
  SfControlsActionOrderStateButtonFixture
);
export const sfControlsActionOrderStateButtonWithValue = getElementWithValueDecoratorFor(
  SfControlsActionOrderStateButtonFixture
);
