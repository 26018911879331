import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias, and, or } from '@ember/object/computed';
import { later } from '@ember/runloop';
import Component from '@ember/component';
import RSVP from 'rsvp';
import { action, computed } from '@ember/object';
import { task } from 'ember-concurrency';

@classic
@classNames('fde-forms-meal-planning-form-for-restaurant-constraint-form-for')
export default class RestaurantConstraintsFormFor extends Component {
  /** @type {Store} */
  @service store;

  /** @type {MealPlanningRestaurantConstraint} */
  templateOrInstance = null;

  /** @type {MealPlanningRestaurantConstraint} */
  mealOrEvent = null;

  /** @type {boolean} */
  readonly = false;

  /** @type {?Function} */
  onSelect = null;

  /** @type {RestaurantConstraint} */
  selection = null;

  /** @type {boolean} */
  shouldValidate = true;

  /** @type {{restaurants: Restaurant[]}} */
  @alias('loadingTask.lastSuccessful.value')
  model;

  /** @type {RestaurantConstraints[]} */
  @or('constrainable.weeklyRestaurantConstraints', 'constrainable.restaurantConstraints')
  restaurantConstraints;

  /** @type {RestaurantConstraints[]} */
  @computed('templateOrInstance', 'mealOrEvent')
  get constrainable() {
    return this.get('mealOrEvent') || this.get('templateOrInstance');
  }

  /** @type {boolean} */
  @and('onSelect', 'readonly')
  allowsSelection;

  init(...args) {
    super.init(...args);
    this.get('loadingTask').perform();
  }

  @task(function* () {
    const area = yield this.get('templateOrInstance.area');
    return yield RSVP.hash({
      restaurants: area.query('restaurants', {
        page: { limit: 300 },
        fields: { restaurants: 'name' },
        filter: { active: true }
      })
    });
  })
  loadingTask;

  @action
  validate(constraint) {
    later(() => constraint.validate(), 250);
  }

  @action
  handleOnSelect(constraint) {
    if (constraint.isEventConstraint) {
      return;
    }

    if (this.get('allowsSelection')) {
      // Toggle
      const selection = this.get('selection') !== constraint ? constraint : null;

      this.onSelect(selection);
    }
  }

  @action
  addConstraint() {
    const constraint = this.get('store').createRecord('meal-planning-restaurant-constraint');
    const mealOrEvent = this.get('mealOrEvent');
    const templateOrInstance = this.get('templateOrInstance');
    const constrainable = mealOrEvent || templateOrInstance;

    return constrainable.addRestaurantConstraint(constraint, templateOrInstance);
  }

  @action
  removeConstraint(constraint) {
    const mealOrEvent = this.get('mealOrEvent');
    const templateOrInstance = this.get('templateOrInstance');
    const constrainable = mealOrEvent || templateOrInstance;
    constrainable.removeRestaurantConstraint(constraint, templateOrInstance);
  }

  @action
  createRequirement(constraint) {
    return constraint.createRequirement();
  }

  @action
  performSearch(constraint) {
    return constraint.get('searchTask').perform(this.get('templateOrInstance.area'));
  }
}
