import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-context-menu-menu-item')
export default class MenuItem extends Component {
  /** @type {String} */
  label = null;

  /** @type {boolean} */
  isChecked = false;

  /** @type {boolean} */
  preventCloseOnClick = false;

  /**
   * Handles the click on the element.
   * @param {Event} e
   */
  click(e) {
    this.onClick();
    if (this.get('preventCloseOnClick')) {
      e.stopPropagation();
    }
  }

  /** @type {Function} */
  onClick() {}
}
