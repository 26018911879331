import classic from 'ember-classic-decorator';
import Model, { attr, hasMany } from 'renard/models/foodee';

import HasManyQuery from 'ember-data-has-many-query';

const { ModelMixin } = HasManyQuery;

@classic
export default class GiftbitCard extends Model.extend(ModelMixin) {
  @attr('object')
  balance;

  @attr('array')
  transactions;

  @hasMany('giftbit-points-action')
  giftbitPointsActions;

  rewardPoints(amount, reason) {
    return this.store.adapterFor(this.constructor.modelName).rewardPoints(this, amount, reason);
  }

  redeemPoints(amount, reason) {
    return this.store.adapterFor(this.constructor.modelName).redeemPoints(this, amount, reason);
  }
}
