import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';

@classic
export default class IndexRoute extends Route.extend(LoadingRouteMixin) {
  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    searchText: { refreshModel: true },
    sort: { refreshModel: true }
  };

  /**
   * @param {Object} params
   * @returns {{areas: Area[], restaurants: Restaurant[]}}
   */
  model(params) {
    const { pageLimit, pageOffset, searchText } = params;

    return this.store.query('user', {
      page: {
        limit: pageLimit,
        offset: pageOffset
      },
      filter: {
        search: searchText
      }
    });
  }
}
