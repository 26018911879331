import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';

@classic
export default class OverridesController extends Controller {
  @alias('model.tranches.meta.utilizationByDay')
  utilizations;

  /** Approves capacity override */
  @task(function* () {
    yield this.get('model.capacityUtilizer').approveCapacityOverride();
  })
  approveCapacityOverrideTask;

  /** Denies capacity override */
  @task(function* () {
    yield this.get('model.capacityUtilizer').denyCapacityOverride();
  })
  denyCapacityOverrideTask;

  /** @type {Object} */
  @computed('utilizations')
  get todaysUtilizations() {
    const utilizations = this.get('utilizations');

    return utilizations.reduce((acc, utilization) => {
      acc[utilization.id] = {
        sum: utilization.utilizationSum,
        count: utilization.utilizationCount
      };

      return acc;
    }, {});
  }

  /** @type {String} */
  @computed
  get day() {
    const capacityUtilizer = this.get('model.capacityUtilizer');
    const restaurant =
      capacityUtilizer.get('restaurant.content') || capacityUtilizer.get('restaurant');
    const areas = restaurant.get('areas').toArray();

    return moment
      .tz(capacityUtilizer.get('deliverAt'), areas.get('firstObject.isoTimeZone'))
      .format('D');
  }

  /** @type {String} */
  @alias('model.capacityUtilizer.restaurant.areas.firstObject.isoTimeZone')
  isoTimeZone;

  @action
  approveOverride() {
    this.get('approveCapacityOverrideTask').perform();
  }

  @action
  denyOverride() {
    this.get('denyCapacityOverrideTask').perform();
  }
}
