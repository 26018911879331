import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';

@classic
export default class FormsRoute extends Route {
  model() {
    return RSVP.hash({
      location: this.store.createRecord('location'),
      contact: this.store.createRecord('contact'),
      order: this.store.createRecord('order'),
      restaurant: this.store.createRecord('restaurant')
    });
  }

  setupController(controller, model) {
    controller.set(
      'discount',
      this.store.createRecord('clientDiscount', {
        order: model.order,
        amountType: 'value'
      })
    );
  }
}
