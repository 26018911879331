import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import moment from 'moment-timezone';

@classic
export default class OverridesRoute extends Route.extend(AuthenticatedRouteMixin) {
  model(params) {
    const model = params.utilizer_model === 'reservation' ? 'meal-planning-reservation' : 'order';
    return RSVP.hash({
      user: this.store.findRecord('user', params.user_id),
      capacityUtilizer: this.store.findRecord(model, params.utilizer_id, {
        include: 'restaurant.areas,client'
      }),
      utilizerModel: params.utilizer_model,
      tranches: null
    });
  }

  afterModel(model) {
    const capacityUtilizer = model.capacityUtilizer;

    const restaurant =
      capacityUtilizer.get('restaurant.content') || capacityUtilizer.get('restaurant');
    const areas = restaurant.get('areas').toArray();

    const day = moment
      .tz(capacityUtilizer.get('deliverAt'), areas.get('firstObject.isoTimeZone'))
      .format('YYYY-MM-DD');

    return restaurant
      .query('restaurantCapacityTranches', {
        filter: { dateRangeWithRestaurant: `${day},${day},${restaurant.get('id')}` }
      })
      .then((_) => (model.tranches = _));
  }
}
