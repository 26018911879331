import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { guidFor } from '@ember/object/internals';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

/**
 *  This component provides a styled, configurable button or link
 *  to open a modal. The modal yields any content you feed it,
 *  like a form-for. The modal also allows you to feed
 *  accept/deny actions and will provide buttons for those actions
 */
@classic
@classNames('fde-modal-control')
export default class ModalControl extends Component {
  /** css classes that can be passed to the action button */
  buttonClasses = '';

  /** Modal service */
  @service
  modal;

  /** Function called before modal is opened */
  modalWillOpen() {}

  /** Function called after modal is visible */
  modalDidOpen() {}

  /** Function called before modal closes */
  modalWillClose() {}

  /** Function called after modal is closed */
  modalDidClose() {}

  /** Unique id for the modal, in case multiple modals exist at the same time */
  @computed
  get id() {
    return `${guidFor(this)}-modal`;
  }

  /** Unique selector using unique id */
  @computed('id')
  get selector() {
    return `ui modal fde-modal-content ${this.get('id')}`;
  }

  /** Icon for the modal button */
  @computed
  get modalButtonIcon() {
    return `${this.get('openModalIcon')} icon`;
  }

  /** If actions are fed into the component, this returns true */
  @computed('onDeny', 'onApprove')
  get actionsPresent() {
    // If an action is passed into modal then show
    // the appropriate action button(s)
    return this.get('onDeny') || this.get('onApprove');
  }

  // Options for modal
  /** @type {String} Icon for action button component, requires 'icon' to be included */
  icon = null;

  /** @type {String} Semantic UI color */
  color = null;

  /** @type {String} Modal button label */
  label = null;

  /** @type {String} Title of modal */
  title = null;

  /** @type {String} Title inside of modal */
  contentTitle = null;

  /** @type {String} URL for image */
  imageURL = null;

  /** @type {Boolean} Set to false if you need to force a choice */
  closable = true;

  /** @type {Function} Action that's called with the deny button */
  onDeny = null;

  /** @type {String} Deny button label */
  denyLabel = 'Nope';

  /** @type {String} Deny button icon */
  denyIcon = 'remove';

  /** @type {String} Deny button color, Semantic UI color */
  denyColor = 'black';

  /** @type {Function} Action that's called with the approve button */
  onApprove = null;

  /** @type {String} Approval button label */
  approveLabel = 'Ok';

  /** @type {String} Approval button icon */
  approveIcon = 'checkmark';

  /** @type {String} Approval button color, Semantic UI color */
  approveColor = 'green';

  /** @type {Boolean} If you want to open the modal from a link instead of a button */
  isLink = false;

  /** @type {String} If modal button is a link you can add an icon */
  linkIcon = '';

  @action
  openModal(event) {
    const params = {
      selector: this.get('selector'),

      closable: this.get('closable'),

      onDeny: this.get('onDeny'),
      onApprove: this.get('onApprove'),

      onHide: this.get('modalWillClose'),
      onHidden: this.get('modalDidClose')
    };

    this.modalWillOpen();
    this.get('modal').openModal(event, params);
    this.modalDidOpen();
  }
}
