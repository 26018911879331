import Fixture, {
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class SfOrdersModalsConfirmFixture extends Fixture {
  constructor(name) {
    super('data-test-sf-modal', name);
  }
}

export const sfOrdersModalsConfirm = getElementDecoratorFor(SfOrdersModalsConfirmFixture);
export const sfOrdersModalsConfirmWithValue = getElementWithValueDecoratorFor(
  SfOrdersModalsConfirmFixture
);
