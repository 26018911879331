import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import { KEYS } from 'renard/models/app-configuration';

@classic
export default class ThirdPartyLogisticsRoute extends Route.extend(AuthenticatedRouteMixin) {
  model() {
    return this.store
      .query('app-configuration', { filter: { key: KEYS.thirdPartyLogisticsSettings } })
      .then((appConfigurations) => {
        return (
          appConfigurations.get('firstObject') ||
          this.store.createRecord('app-configuration', {
            key: KEYS.thirdPartyLogisticsSettings,
            preferences: {
              deliveryNotes: '',
              pickupNotes: ''
            }
          })
        );
      });
  }
}
