import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

/**
 * Repeats n times a (block) operation.
 */
@classic
@tagName('')
export default class TimesDo extends Component {
  /** @type {number} the number of iterations passed */
  iterations = 0;

  /** @type {Array} array used to iterate in the Ember template */
  @computed('iterations')
  get iterable() {
    return Array(this.get('iterations')).fill(1);
  }
}
