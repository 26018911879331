import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';

import { modelAction } from 'ember-custom-actions';

@classic
export default class MealPlanningEvent extends Model {
  @attr('date')
  createdAt;

  @attr('date')
  updatedAt;

  @attr('string')
  name;

  @attr('string')
  identifier;

  @attr('number')
  numberOfPeople;

  @attr('number')
  leadTime;

  @attr('date')
  deliverAt;

  @attr('string', { defaultValue: 'draft' }) initialState;

  @attr('date')
  pickupAt;

  @attr('number', { defaultValue: 0 })
  numberOfSameDayReservations;

  @attr('string')
  uuid;

  @belongsTo('meal-planning-instance')
  instance;

  @hasMany('order', { inverse: 'event' })
  orders;

  @belongsTo('order', { inverse: 'eventAsTemplate' })
  orderTemplate;

  @hasMany('meal-planning-reservation')
  reservations;

  @hasMany('meal-planning-restaurant-constraint')
  restaurantConstraints;

  addRestaurantConstraint(constraint, _mealPlan) {
    constraint.set('event', this);
    return constraint.save();
  }

  removeRestaurantConstraint(constraint, _mealPlan) {
    return constraint.destroyRecord();
  }

  @computed
  get humanize() {
    return this.get('name');
  }

  /** @type {Boolean} */
  @computed('reservation.@each.state')
  get canRequestNewRestaurants() {
    return this.get('reservations').every((_) => _.state.isDraft || _.state.isQuoted);
  }

  @computed('reservations.@each.state')
  get inProgress() {
    return !this.get('reservations').every((_) => _.state.isDraft);
  }

  updateOrders = modelAction('update-orders', { method: 'POST' });
}
