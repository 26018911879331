import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action } from '@ember/object';
import { run } from '@ember/runloop';

@classic
export default class CommaSeparatedStringsControl extends Component {
  /** @type {string[]} All values that have been added to the collection */
  value = null;

  /** @type {string} semantic ui icon class*/
  icon = 'envelope';

  /** @type {string} temporary value of the input */
  inputValue = '';

  /** @type {?string} */
  customPillClasses = '';

  /** @type {?string} */
  placeholder = '';

  /** @type {number} */
  debounce = 100;

  /** @type {Regex} criteria for splitting the input values. Default is space OR comma */
  splitOn = /[ ,]+/;

  /** @type {Object} */
  errors = null;

  /**
   * @param {string} rawValue
   * @private
   */
  _addPill(rawValue) {
    const newPills = rawValue.split(this.get('splitOn'));
    const value = (this.get('value') || []).toArray();

    newPills.reduce((acc, val) => {
      if (!val.match(/^\s*$/) && !value.includes(val)) {
        acc.push(val.trim());
      }

      return acc;
    }, value);

    this.set('_newValue', value);
    run.debounce(this, this._commitValues, this.get('debounce'));
  }

  /**
   * Debounces the change event and input reset so the input is actually cleared
   * @private
   */
  _commitValues() {
    this.onChange(this.get('_newValue'));
    this.set('inputValue', '');
  }

  onChange() {}

  @action
  removePill(pillValue) {
    const value = this.get('value').toArray();
    value.removeObject(pillValue);

    this.onChange(value);
  }

  @action
  handleOnBlur(textValue) {
    this._addPill(textValue);
  }

  @action
  handleInputChange(textValue) {
    this.set('inputValue', textValue);
    const newPills = textValue.split(this.get('splitOn'));

    if (newPills.length > 1) {
      this._addPill(textValue);
    }
  }
}
