import classic from 'ember-classic-decorator';
import { oneWay } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { run } from '@ember/runloop';

@classic
export default class IndexController extends Controller {
  queryParams = ['pageLimit', 'pageOffset', 'searchText', 'sort', 'areas'];

  /** @type {string} text for searching the back end with */
  searchText = '';

  /** @type {number} sorting string */
  sort = 'name';

  /** @type {number} */
  @oneWay('model.clients.meta.recordCount')
  recordCount;

  /** @type {number} records per page */
  pageLimit = 20;

  /** @type {number} current page */
  pageOffset = 0;

  /** @type {string} */
  areas = '';

  /** @type {Area[]} */
  @computed('model.areas')
  get filterAreas() {
    // get the areas query string
    const areas = this.get('areas');

    // return the areas that are in the filter string
    return this.get('model.areas').filter((area) => areas.includes(area.get('city')));
  }

  doSearch() {
    this.setProperties({
      searchText: this.get('_searchText'),
      pageOffset: 0
    });
  }

  @action
  onFilterChange(newAreas) {
    const areas = newAreas.mapBy('city').join(',');

    this.setProperties({
      areas: areas,
      pageOffset: 0
    });
  }

  @action
  onSearchChange(searchText) {
    this.set('_searchText', searchText);

    // prevent multiple search
    run.debounce(this, this.doSearch, 400);
  }

  @action
  searchForTags(searchText) {
    this.store
      .query('tag', {
        filter: {
          name: searchText
        }
      })
      .then((tags) => {
        this.set('searchedTags', tags);
      });
  }
}
