import { helper as buildHelper } from '@ember/component/helper';

export function formattedAmount(params) {
  let [amount = 0, amountType = 'percentage', digits = null] = params;

  return amountType === 'percentage'
    ? `${digits ? amount.toFixed(digits) : amount}%`
    : `$${amount.toFixed(digits)}`;
  // using toFixed for amount too since we can specify the precision of the amount
}

export default buildHelper(formattedAmount);
