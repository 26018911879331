import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import Component from '@ember/component';
import '@ember/object';
import $ from 'jquery';

@classic
@tagName('')
export default class TabItem extends Component {
  /** @type {boolean} */
  isIndented = false;

  /** @type {string} route for this tab */
  route = '';

  /** @type {object} query params for a potential route */
  queryParams = undefined;

  /** @type {boolean} whether or not the tab is full screen */
  isFullScreen = false;

  @observes('isFullScreen')
  isFullScreenDidChange() {
    $('.fde-tab-nav-tab-item').toggleClass('is-full-screen', this.get('isFullScreen'));
  }
}
