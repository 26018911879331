import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action } from '@ember/object';

@classic
export default class SendRoboCallConfigurator extends Component {
  receiverType;

  @action
  setReceiverType(type) {
    this.receiverType = type;
  }
}
