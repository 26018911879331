import classic from 'ember-classic-decorator';
import Controller from 'star-fox/features/application/abstract-controller';
import { action } from '@ember/object';
import { LOGS_ORDER_FIELDS, LOGS_ORDER_INCLUDES } from '../index/route';

@classic
export default class DeliveriesController extends Controller {
  pusherModelQueryParams = {
    Order: {
      include: LOGS_ORDER_INCLUDES,
      fields: LOGS_ORDER_FIELDS
    },
    LogisticsDelivery: {
      include: 'area,driver,courier'
    }
  };

  backRoute = 'logged-in.logistics.deliveries';

  @action
  deliveryAllocated(_data) {
    // we don't do anything on this event
  }
}
