import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('fde-pill-list-control')
export default class PillList extends Component {
  /** @type {array} All values that will be represented as pills */
  values = null;

  /** @type {string} One value that is represented as a pill */
  value = null;

  /** @type {string} */
  customCssClasses = '';

  /** @type {Object} Error passed via a form that uses this control */
  errors = null;

  onRemovePill() {}
}
