import { helper as buildHelper } from '@ember/component/helper';
import moment from 'moment-timezone';

export function timeIn(params) {
  let [time = moment(), tz = 'America/New_York', format = 'h:mm:ss A'] = params;

  return moment(time).tz(tz).format(format);
}

export default buildHelper(timeIn);
