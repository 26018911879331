import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class MenuItem extends Application {
  attrs = {
    taxRate: {
      serialize: true
    },
    menuOptionGroups: {
      serialize: true
    },
    foodTypes: {
      serialize: true
    },
    mealTypes: {
      serialize: true
    },
    actualMaximumQuantity: {
      serialize: false
    },
    actualMinimumQuantity: {
      serialize: false
    },
    actualMaximumServing: {
      serialize: false
    },
    actualMinimumServing: {
      serialize: false
    },
    budgetPrice: {
      serialize: false
    },
    balancedPrice: {
      serialize: false
    },
    bountifulPrice: {
      serialize: false
    }
  };
}
