import classic from 'ember-classic-decorator';
import FeedItemBuilder from './base';
import { capitalize, dasherize } from '@ember/string';

import { OrderState } from 'renard/transforms/order-state';

@classic
export default class OrderBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);

    const state = version.get('objectChanges.state');
    feedItem.icon = 'shopping cart';

    if (state) {
      const [_firstState, secondState] = state;
      feedItem.color = `fde-order-state-color_${dasherize(secondState)}`;
      this._processStateChange(version, feedItem);
    } else if (version.get('createdBySystem')) {
      this._processSystemChange(version, feedItem);
    }

    return feedItem;
  }

  /**
   * @param {HistorianVersion} version
   * @param {FeedItem} feedItem
   * @private
   */
  _processSystemChange(version, feedItem) {
    const invoiceChangeKeys = [
      'updatedAt',
      'clientInvoiceId',
      'restaurantInvoiceId',
      'totalAmount',
      'restaurantTotalAmount'
    ];

    // if all of the change keys is in the invoice changeKeys
    const isAnInvoiceUpdate = version
      .get('changeKeys')
      .every((key) => invoiceChangeKeys.includes(key));

    if (isAnInvoiceUpdate) {
      feedItem.icon = 'dollar sign';
      feedItem.subject = `${version.get(
        'whodunnit'
      )} updated the invoice total to (C: ${version.getValue('totalAmount')} R: ${version.getValue(
        'restaurantTotalAmount'
      )}) for Order:${version.get('itemId')}`;
    }
  }

  /**
   * @param {HistorianVersion} version
   * @param {FeedItem} feedItem
   * @private
   */
  _processStateChange(version, feedItem) {
    const previousState = OrderState.valueFor(version.get('objectChanges.state.firstObject'));
    const currentState = OrderState.valueFor(version.get('objectChanges.state.lastObject'));

    // TODO: extract better verbs per state transition tuple, ie: draft -> pre_quote / drag -> group_building verb: published
    feedItem.subject = `${version.get('whodunnit')} changed the order state from '${capitalize(
      previousState.replace(/_/g, ' ')
    )}' to '${capitalize(currentState.replace(/_/g, ' '))}'`;

    if (currentState.isLogisticsState) {
      feedItem.icon = 'truck';
    } else if (currentState.isAccountingState) {
      feedItem.icon = 'dollar sign';
    }

    if (currentState === 'closed') {
      feedItem.subject = `${version.get('whodunnit')} closed the order`;
    } else if (currentState === 'cancelled') {
      feedItem.subject = `${version.get('whodunnit')} cancelled the order`;
    }
  }
}
