import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

@classic
export default class UserInviteRoute extends Route {
  @service('user-session')
  session;

  model(params) {
    return this.store.query('user', { filter: { invitationToken: params.token } }).then((users) => {
      const user = users.get('firstObject');

      if (user) {
        user.set('invitationToken', params.token);
        user.set('firstName', null);
        user.set('lastName', null);

        return user;
      } else {
        return null;
      }
    });
  }
}
