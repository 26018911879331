import classic from 'ember-classic-decorator';
import { classNames, attributeBindings } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@classNames('fde-tab-view')
@attributeBindings('data-test-sf-tab-view')
export default class TabView extends Component {
  @computed('tabViewName')
  get 'data-test-sf-tab-view'() {
    return this.tabViewName || '';
  }

  tabViewName = null;
  /**
   * @type {string|null} Active tab, controls and reflects the active tabs by
   * tabName downstream
   */
  activeTab = null;

  /** @type {string} Class names for tab group */
  tabGroupClassNames = '';

  /** @type {string} Class names for the individual tabs */
  tabClassNames = '';

  /** @type {string} Class name for active tab. */
  activeTabClass = '';

  /** @type {string} Class names for tab pane. */
  tabPaneClassNames = '';

  /** onTabChange event */
  onTabChange() {}

  /**
   * Sets the active tab and emits a onTabChange action with the new
   * active tab and the previous active tab name as parameters
   * @params {string|number}
   */
  setActiveTab(nextTab) {
    const activeTab = this.get('activeTab');

    this.set('activeTab', nextTab);
    this.onTabChange(nextTab, activeTab);
  }

  /**
   * Handles a tab select action, and sets the active tab
   * @params {string|number}
   */
  @action
  handleTabSelect(tab) {
    this.setActiveTab(tab);
  }
}
