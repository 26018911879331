import { Promise } from 'rsvp';
import Controller from '@ember/controller';
import EmberObject, { action, computed } from '@ember/object';
import { later, run } from '@ember/runloop';
import { isArray } from '@ember/array';
import moment from 'moment-timezone';
import { Time } from 'renard/transforms/time';

const dietaryTags = [
  {
    id: 'vegan',
    name: 'vegan',
    slug: 'vegan',
    abbreviation: 'vg',
    color: '#104C5C'
  },
  {
    id: 'dairy-free',
    name: 'dairy free',
    slug: 'dairy-free',
    abbreviation: 'df',
    color: '#353585'
  },
  {
    id: 'vegetarian',
    name: 'vegetarian',
    slug: 'vegetarian',
    abbreviation: 'v',
    color: '#3BB76C'
  },
  {
    id: 'gluten-free',
    name: 'gluten free',
    slug: 'gluten-free',
    abbreviation: 'gf',
    color: '#F68B29'
  }
].map((_) => EmberObject.create(_));

const order = EmberObject.create({
  state: 'submitted'
});

export default Controller.extend({
  testRoute: 'logged-in.dev-team.components.controls',
  numberOfRunQueries: 0,
  actionButtonLabel: 'Default State',

  actionOrderButtonLabel: computed('actionOrderButtonOrder', function () {
    return this.get('actionOrderButtonOrder.state');
  }),

  actionOrderButtonOrder: order,
  toggleButtonValue: false,
  startOfWeek: moment().startOf('week'),
  selectedDate: moment().startOf('day'),

  daysOfWeek: computed('startOfWeek', function () {
    const startOfWeek = this.get('startOfWeek');

    return Array.from(new Array(7), (_, i) => {
      const totalOrders = Math.ceil(Math.random() * 12);

      return {
        date: moment(startOfWeek).add(i + 1, 'day'),
        info: `${totalOrders - 1}/${totalOrders}`
      };
    });
  }),

  geoJson: {
    latLongs: [
      [
        [-123.19168018798831, 49.2387756133012],
        [-123.09168018798829, 49.2387756133012],
        [-123.09168018798829, 49.338775613301195],
        [-123.19168018798831, 49.338775613301195],
        [-123.19168018798831, 49.2387756133012]
      ]
    ]
  },

  center: { lng: -123.19168018798831, lat: 49.2387756133012 },
  localDeliveryRadius: 1,
  deliveryRadius: 2,
  extendedDeliveryRadius: 4,
  emailsList: ['hello', 'pigrit'],

  examplePrices: {
    retailPrice: 5,
    restaurantPrice: 4.25,
    clientPrice: 5.75
  },

  restaurants: [
    { location: { lng: -123.19168018798831, lat: 49.2387756133012 } },
    { location: { lng: -123.09168018798829, lat: 49.2387756133012 } },
    { location: { lng: -123.09168018798829, lat: 49.338775613301195 } },
    { location: { lng: -123.19168018798831, lat: 49.338775613301195 } },
    { location: { lng: -123.19168018798831, lat: 49.2387756133012 } }
  ],

  disableRetailPrice: false,
  disableRestaurantPrice: true,
  disableClientPrice: true,
  showRetailPrice: true,
  showRestaurantPrice: true,
  showClientPrice: true,
  showTaxRates: true,
  clientPercentage: 1.15,
  restaurantPercentage: 0.85,

  areaTaxRates: [
    {
      id: 1,
      name: 'OPP'
    },
    {
      id: 2,
      name: 'HST'
    },
    {
      id: 3,
      name: 'WTF'
    }
  ].map((_) => EmberObject.create(_)),

  models: [
    {
      id: 1,
      label: 'Foo'
    },
    {
      id: 2,
      label: 'Bar'
    },
    {
      id: 3,
      label: 'Baz'
    }
  ].map((_) => EmberObject.create(_)),

  otherModels: [
    {
      id: 4,
      label: 'Qux'
    },
    {
      id: 5,
      label: 'Quux'
    },
    {
      id: 6,
      label: 'Quuz'
    }
  ].map((_) => EmberObject.create(_)),

  modelSelectControl: {
    value: EmberObject.create({
      id: 1,
      label: 'Foo'
    }),
    values: [
      EmberObject.create({
        id: 1,
        label: 'Foo'
      })
    ]
  },

  modelSelectLog: [],

  fakeModelSearchAction: action(function () {
    return new Promise((resolve) => {
      later((_) => {
        resolve(this.get('otherModels'));
      }, 2000);
    });
  }),

  showOtherModels: false,
  searchQueryMessage: 'Query the muffins',

  currentModels: computed('showOtherModels', function () {
    return this.get('showOtherModels') ? this.get('otherModels') : this.get('models');
  }),

  selectedModel: computed('models.[]', function () {
    return this.get('models')[0];
  }),

  selectedModels: computed('models.[]', function () {
    return [this.get('models')[0]];
  }),

  dietaryTags: dietaryTags,
  selectedDietaryTags: [dietaryTags[0]],

  dietaryTagSelectable: EmberObject.create({
    name: 'vegan',
    slug: 'vegan',
    abbreviation: 'vg',
    color: '#104C5C',
    isSelected: true,
    isSelectable: true
  }),

  singleDate: new Date(),
  singleDateTime: new Date(),
  singleTime: Time.startOfDay,
  timeZone: 'America/Los_Angeles',
  format: 'h:mm a',

  //properties for pagination-search-control

  paginationRecordCount: 20,

  paginationPageLimit: 10,
  paginationPageOffset: 0,
  paginationSearchTerm: '',
  paginationSearchText: '',
  isPaginationSearchLoading: false,

  timeRange: EmberObject.create({
    startTime: Time.startOfDay,
    endTime: Time.startOfDay.add(3, 'hours')
  }),

  stateEventText: 'State event transition:',

  onDenyAction: function () {
    return alert('Denied!');
  },

  onApproveAction: function () {
    return alert('Approved!');
  },

  onPaginationChange() {
    const message = `Query function called with search query "${this.get(
      'paginationSearchTerm'
    )}". Too bad it's a dummy function and nothing is really happening...`;

    this.setProperties({
      searchQueryMessage: message,
      paginationSearchTerm: '',
      paginationSearchText: '',
      isPaginationSearchLoading: false
    });
  },

  updateSearchQuery(searchText) {
    // sets the search term based on the building search query
    this.set('paginationSearchTerm', searchText);
    this.onPaginationChange();
  },

  doPaginationSearch: action(function (searchText) {
    this.set('isPaginationSearchLoading', false);
    // An action like this allows you to set the search term when the user stops typing
    run.debounce(this, this.updateSearchQuery(searchText), 400);
  }),

  clearPaginationSearch: action(function () {
    // clear query params and call query function. May also include pagination queries
    // searchQueryMessage: 'Search cleared',
    this.setProperties({
      paginationSearchTerm: '',
      paginationSearchText: '',
      isPaginationSearchLoading: false
    });

    this.onPaginationChange();
  }),

  setStateEvent: action(function (stateEvent) {
    const text = `State event transition: "${stateEvent}" was sent.`;
    this.set('stateEventText', text);
  }),

  runTypeAheadQuery: action(function () {
    this.incrementProperty('numberOfRunQueries');
  }),

  changeActionButton: action(function (actionButtonLabel, actionButtonColor) {
    this.setProperties({
      actionButtonLabel,
      actionButtonColor
    });
  }),

  changeWeek: action(function (direction) {
    const week = moment(this.get('startOfWeek'));
    if (direction === 'last') {
      week.subtract(1, 'week');
    } else {
      week.add(1, 'week');
    }
    this.set('startOfWeek', week);
  }),

  changeListAndValueOfModelSelect: action(function () {
    this.toggleProperty('showOtherModels');
    this.set('selectedModel', this.get('currentModels')[0]);
  }),

  changeValueOfModelSelectToLast: action(function () {
    this.set('selectedModel', this.get('currentModels')[2]);
  }),

  handleContextMenuItemClick: action(function (string) {
    alert(`Clicked ${string}!`);
  }),

  handleModelSelectOnChange: action(function (value) {
    const newValue = isArray(value) ? value : [value];
    this.get('modelSelectLog').pushObject(
      'model-select onChange event fired with models: ' +
        newValue.map((_) => (_ ? _.get('label') : 'null')).join(',')
    );
  }),

  handleModelSelectOnAdd: action(function (value) {
    this.get('modelSelectLog').pushObject(
      'model-select onAdd event fired with model: ' + (value ? value.get('label') : 'null')
    );
  })
});
