import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias, not, oneWay, sum } from '@ember/object/computed';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';
import { isPresent } from '@ember/utils';
import moment from 'moment-timezone';
import { modelAction } from 'ember-custom-actions';
import { modelActionWithErrors } from 'renard/utils/model-actions';
import { fragment } from 'ember-data-model-fragments/attributes';
import { OrderState } from '../transforms/order-state';
import { action } from '@ember/object';
import formatMoney from 'accounting/format-money';

@classic
export default class Order extends Model {
  /*
   * Attributes
   */
  @attr('boolean', { defaultValue: true })
  allowsGuests;

  @attr('string')
  globalId;

  @attr('string')
  urlSafeId;

  @attr('string')
  orderType;

  @attr('string')
  identifier;

  @attr('string')
  duplicatedFromId;

  @attr('boolean')
  ignoresCapacityLimit;

  @attr('boolean')
  isFoodhall;

  @attr('boolean')
  isGroupOrder;

  @attr('boolean')
  isAsap;

  @attr('boolean')
  lastMinute;

  @attr('number')
  perPersonBudget;

  @attr('budget-type')
  budgetType;

  @attr('number')
  maxNumberOfPeople;

  @attr('string')
  name;

  @attr('number')
  bowtieServiceFee;

  @attr('number', {
    defaultValue: 100
  })
  payOutOfPocketFee;

  @attr('boolean')
  allowsPayOutOfPocket;

  @fragment('fragments/order-notes', { defaultValue: {} })
  notes;

  @attr('string')
  monitoringNotes;

  @attr('string')
  thirdPartyLogisticsException;

  @attr('date')
  createdAt;

  @attr('date')
  updatedAt;

  @attr('date')
  deliverAt;

  @attr('object')
  deliveryEstimate;

  @attr('boolean')
  overridePickupAtAdjustment;

  @attr('date')
  pickupAt;

  @attr('date')
  deliveredAt;

  @attr('date')
  opensAt;

  @attr('date-only')
  deliverOn;

  @attr('date')
  pickedUpAt;

  @attr('date')
  expiresAt;

  @attr('date')
  deadlineAt;

  @attr('boolean')
  deadlineIsSameDay;

  @attr('date')
  restaurantPollDeadlineAt;

  @attr('order-state', { defaultValue: OrderState.Draft.valueOf() })
  state;

  @attr('string')
  stateEvent;

  @attr('string')
  clientInvoicePdf;

  @attr('string')
  restaurantSummaryPdfUrl;

  @attr('string')
  restaurantSummaryPdfShortUrl;

  @attr('string')
  restaurantConfirmationShortUrl;

  @attr('string')
  restaurantEmailVersion;

  @attr('string')
  numberOfPeople;

  @attr('number')
  totalAmount;

  @attr('number')
  restaurantTotalAmount;

  @fragment('fragments/accounting/client-total', {
    defaultValue: {}
  })
  clientTotal;

  @fragment('fragments/accounting/restaurant-total', {
    defaultValue: {}
  })
  restaurantTotal;

  @attr('boolean')
  flag;

  @attr('alert-status', { defaultValue: 'none' })
  alertStatus;

  @attr('string')
  alertReason;

  @attr('object')
  setMenu;

  @attr('string')
  uuid;

  @attr('array')
  orderImages;

  @attr('boolean')
  isTemplate;

  @attr('boolean')
  isClientDemo;

  @attr('boolean')
  isRestaurantDemo;

  @attr('boolean')
  isDemoOrder;

  @attr('distance')
  distance;

  @attr('boolean')
  isDeliverable;

  @attr('boolean')
  isRedelivery;

  @attr('boolean')
  isCompensation;

  @attr('boolean')
  isFoodeeServed;

  @attr('boolean')
  isBookmark;

  @attr('string')
  bookmarkName;

  @attr('date')
  bookmarkExpiresAt;

  @attr('boolean')
  isMealPlanOrder;

  @attr('boolean')
  isAutosave;

  @attr('boolean')
  largeOrder;

  @attr('boolean')
  outOfZone;

  @attr('boolean')
  outsideHours;

  @attr('number')
  ordersInTranche;

  @attr('string')
  tranche;

  @attr('number')
  requestedCapacity;

  @attr('cutlery-preference')
  cutleryPreference;

  @attr('number')
  remainingMinimumAmount;

  @fragment('fragments/ordering/storage')
  storage;
  @alias('storage.feedbackCount') feedbackCount;
  @alias('feedbackCount.scoreCount') scoreCount;
  @alias('feedbackCount.reasonCount') reasonCount;
  @alias('feedbackCount.descriptionCount') descriptionCount;

  @fragment('fragments/accounting/charge')
  driverTip;

  @fragment('fragments/accounting/charge')
  ghostTip;

  @fragment('fragments/accounting/charge', {
    defaultValue: {}
  })
  waivedDeliveryFee;

  @fragment('fragments/accounting/charge', {
    defaultValue: {}
  })
  waivedServiceFee;

  @fragment('fragments/accounting/charge')
  waivedDriverTip;

  @fragment('fragments/accounting/charge')
  waivedRetailDeliveryFee;

  @fragment('fragments/accounting/charge')
  waivedRestaurantAdminFee;

  @fragment('fragments/accounting/charge')
  restaurantDeliveryFee;

  @fragment('fragments/accounting/charge')
  WaivedClientMinimumOrderBalanceFee;

  @fragment('fragments/accounting/service-fee', { defaultValue: {} })
  serviceFee;

  @fragment('fragments/accounting/delivery-fee', { defaultValue: {} })
  deliveryFee;

  @fragment('fragments/accounting/charge', {
    defaultValue: {}
  })
  groupOrderMaximumDiscount;

  /* 3rd party */
  @attr('string')
  thirdPartyLogisticsDropOffId;

  @attr('string')
  thirdPartyLogisticsPickUpId;

  @attr('string')
  thirdPartyLogisticsTrackingUrl;

  @attr('string')
  logisticsType;

  @attr('date')
  syncedWithThirdPartyLogisticsAt;

  @attr('string')
  stripeError;

  @attr('number')
  lateEstimateInMinutes;

  @attr('string')
  thirdPartyLogisticsDashboardUrl;

  /*
   * Relationships
   */
  @belongsTo('area', { inverse: 'orders', async: false })
  area;

  @belongsTo('user', { async: false })
  owner;

  @belongsTo('user')
  creator;

  @hasMany('salesforce-cases')
  salesforceCases;

  @belongsTo('user')
  salesSupport;

  @belongsTo('client', { async: false })
  client;

  @belongsTo('courier')
  courier;

  @belongsTo('restaurant', { async: false })
  restaurant;

  @belongsTo('menu', { inverse: 'order' })
  menu;

  @belongsTo('invoicing-ledger-item')
  clientInvoice;

  @belongsTo('invoicing-ledger-item')
  restaurantInvoice;

  @belongsTo('location', { async: false })
  restaurantLocation;

  @belongsTo('location', { async: false })
  customLocation;

  @belongsTo('location', { async: false })
  clientLocation;

  @belongsTo('contact', { async: false })
  contact;

  @belongsTo('user', {
    inverse: 'deliveredOrders',
    async: false
  })
  driver;

  @hasMany('accounting-ledger-item', { polymorphic: true })
  ledgerItems;

  @belongsTo('accounting-ledger-item')
  invoice;

  @belongsTo('accounting-ledger-item')
  restaurantBill;

  @belongsTo('accounting-ledger-item')
  courierBill;

  @hasMany('order-item', { inverse: 'order' })
  orderItems;

  @hasMany('groupOrderMember', { inverse: 'order' })
  groupOrderMembers;

  @hasMany('groupOrderMember', { inverse: 'orderedOrder' })
  orderedGroupOrderMembers;

  @belongsTo('giftbit-error')
  giftbitError;

  @belongsTo('payment-card', { async: false })
  paymentCard;

  @belongsTo('promo-code')
  promoCode;

  @hasMany('email-message')
  emailMessages;

  @hasMany('delivery-case')
  deliveryCases;

  @hasMany('desk-case')
  deskCases;

  @hasMany('client-discount')
  clientDiscounts;

  @hasMany('restaurant-discount')
  restaurantDiscounts;

  @hasMany('team')
  teams;

  //users who belong to a team associated with the order and who are themselves also associated with the order.
  @hasMany('user')
  allUsers;

  //users that have joined an order
  @hasMany('user')
  joinedUsers;

  //users that are associated with teams applied to the order
  @hasMany('user')
  teamMembers;

  @hasMany('historian-version')
  allOrderVersions;

  @hasMany('restaurant')
  pollableRestaurants;

  @hasMany('restaurant-vote')
  restaurantVotes;

  @hasMany('notification-log')
  notificationLogs;

  @belongsTo('meal-planning-requirement')
  menuFilter;

  @belongsTo('logistics-arrival-estimate')
  arrivalEstimate;

  @belongsTo('meal-planning-event')
  event;

  @belongsTo('meal-planning-event')
  eventAsTemplate;

  @hasMany('orderingFeedback') feedbacks;

  /*
   * Properties
   */
  @service
  notify;

  /** @type {Service} */
  @service
  ajax;

  @service
  appConfiguration;

  /** @type {number} - updated through the fetchTax service - includes tax on delivery fee and owner's items */
  companyTaxTotal = 0;

  /** @type {number} - updated through the fetchTax service - total tax that members pay on group orders */
  membersTaxTotal = 0;

  @alias('id')
  orderId;

  /*
   * Computed Properties
   */

  /** @type {boolean} */
  @not('isGroupOrder')
  isStandardOrder;

  /** @type {boolean} */
  @alias('allowsPayOutOfPocket')
  isPoop;

  /** @type {number} */
  @computed('deliverAt', 'pickupAt')
  get deliveryTimeInMinutes() {
    return Math.abs(moment(this.get('deliverAt')).diff(moment(this.get('pickupAt')), 'minutes'));
  }

  /** @type {number} */
  @computed('orderItems.[]')
  get itemsTotal() {
    return this.get('orderItems').reduce(
      (sum, item) => (!item.get('isDeleted') ? sum + item.get('clientTotalPriceCents') : sum),
      0
    );
  }

  /** @type {?string} */
  @computed('pollableRestaurants.[]', 'deliverAt', 'restaurantPollDeadlineAt')
  get pollDeadlineWarning() {
    const hasRestaurantOptions = this.get('pollableRestaurants.length');
    const { restaurantPollDeadlineAt, deliverAt } = this.getProperties(
      'restaurantPollDeadlineAt',
      'deliverAt'
    );
    const isAfterDeliverAt = moment(restaurantPollDeadlineAt).isAfter(moment(deliverAt));
    const isInThePast = moment(restaurantPollDeadlineAt).isBefore(moment());
    let message;

    if (hasRestaurantOptions && isAfterDeliverAt) {
      message = 'Your polling deadline needs to be before the delivery time';
    } else if (hasRestaurantOptions && isInThePast) {
      message = 'Your polling deadline is in the past, no one will be able to vote anymore.';
    }

    return message;
  }

  /** @type {number} */
  @computed('groupOrderMembers.[]')
  get adminFeesTotal() {
    return this.get('groupOrderMembers').reduce(
      (sum, member) =>
        !member.get('isAdmin') && member.get('overBudgetSubtotal') > 0
          ? sum + this.get('payOutOfPocketFee')
          : sum,
      0
    );
  }

  /** @type {number} */
  @sum('itemsTotal', 'area.deliveryFee', 'adminFeesTotal')
  subtotal;

  /** @type {number} - total amount of tax owed on the order (dependant on fetchTax service) */
  @sum('companyTaxTotal', 'membersTaxTotal')
  grandTaxTotal;

  /** @type {number} - real total */
  @sum('subtotal', 'grandTaxTotal')
  grandTotal;

  /** @type {boolean} indicates if the order is already on the road (the driver started to work on it) */
  @computed('state')
  get isNotOnTheRoad() {
    return this.state.isNotOnTheRoad;
  }

  /**
   * Using hasBeen to avoid conflict with Controls/ActionOrderStateButton#isDelivered
   * @type {boolean}
   */
  @computed('state')
  get hasBeenPickedUp() {
    return this.state.isPickedUp;
  }

  /**
   * Using hasBeen to avoid conflict with Controls/ActionOrderStateButton#isDelivered
   * @type {boolean}
   */
  @computed('state')
  get hasBeenDelivered() {
    return this.state.hasBeenDelivered;
  }

  /** @type {boolean} */
  @computed('state')
  get inTransit() {
    return this.state.inTransit;
  }

  /** @type {boolean} */
  @computed('state')
  get canAddTeamsAndUsers() {
    return !(this.state.inTransit || this.state.isDelivered);
  }

  /** @type {boolean} */
  @computed('state')
  get enableTeamsAndUsersReminder() {
    return this.state.isGroupBuilding;
  }

  /** @type {string} */
  @oneWay('promoCode.code')
  promoCodeName;

  /** @type {boolean} */
  @computed('promoCodeName', 'promoCode.code')
  get hasNewPromoCode() {
    return (this.get('promoCodeName') || '') !== (this.get('promoCode.code') || '');
  }

  /** @type {boolean} */
  @computed('setMenu.setMenuItems.[]')
  get hasSetMenu() {
    const setMenuItems = this.get('setMenu.setMenuItems');
    return isPresent(setMenuItems) && Object.keys(setMenuItems).length !== 0;
  }

  /** @type {string} */
  @computed('deliverOn')
  get deliverOnFormatted() {
    return moment(this.get('deliverOn')).format('ddd, MMM Do YY');
  }

  @computed('totalAmount')
  get formattedTotalAmount() {
    return formatMoney(this.get('totalAmount'));
  }

  @computed('restaurantTotalAmount')
  get formattedRestaurantTotalAmount() {
    return formatMoney(this.get('restaurantTotalAmount'));
  }

  /** @type {string} type of order */
  @computed('isFoodhall', 'isPoop', 'isMealPlanOrder', 'orderType', 'isAsap', 'lastMinute')
  get typeInitials() {
    const type = [];

    if (this.isFoodeeServed) {
      type.push('FS');
    }

    if (this.isClientDemo) {
      type.push('CD');
    }

    if (this.isRestaurantDemo) {
      type.push('RD');
    }

    if (this.get('isFoodhall')) {
      type.push('FH');
    } else if (this.get('isPoop')) {
      type.push('OP');
    } else if (this.get('isMealPlanOrder')) {
      type.push('MP');
    } else if (this.get('orderType') === 'team') {
      type.push('TO');
    } else if (this.get('orderType') === 'group') {
      type.push('GO');
    } else {
      type.push('RG');
    }

    if (this.get('isAsap')) {
      type.push('ASAP');
    }

    if (this.get('lastMinute')) {
      type.push('LM');
    }

    return type.join('/');
  }

  @computed('serviceFee.type', 'client.isEnterprise')
  get serviceFeeTypeLabel() {
    if (this.serviceFee.isConditional) {
      return 'Conditional Service Fee';
    }
    const subscriptionType = this.client.isEnterprise ? 'Plus' : 'Basic';
    return `Service Fee (${subscriptionType})`;
  }

  @action
  setDefaultFeeSettings() {
    this.serviceFee.amount =
      this.serviceFee.isConditional || !this.client.isEnterprise
        ? this.serviceFee.defaultValues.BaseRate
        : this.serviceFee.defaultValues.PlusRate;
    this.deliveryFee.active = this.serviceFee.isConditional || !this.client.isEnterprise;
  }

  /**
   * Reloads the record with included order items and delivery cases
   *
   * @returns {Promise.<Order>}
   */
  loadOrderItems() {
    return this.store.findRecord('order', this.get('id'), {
      reload: true,
      include: [
        'menu.menu-groups',
        'order-items.group-order-member',
        'order-items.menu-item.menu-group.menu',
        'order-items.menu-option-items.menu-option-group',
        'delivery-cases',
        'desk-cases',
        'owner.communication-preference'
      ].join(',')
    });
  }

  /**
   * Reloads the record with included order items and delivery cases
   *
   * @returns {Promise.<Order>}
   */
  loadSalesforceCases() {
    return this.get('store').query('salesforceCase', {
      filter: { orderId: this.get('id'), origin: 'Starfox' }
    });
  }

  /**
   * TODO generalize this to all records
   *
   * @param {string} include
   */
  reloadWith(include) {
    return this.store.findRecord('order', this.get('id'), {
      reload: true,
      include: include
    });
  }

  /**
   * Takes the promoCodeName, and checks to see if it a real promo code
   * If it is, it applies the new promo code to the promoCode relationship
   */
  applyPromoCode() {
    if (!this.get('hasNewPromoCode')) {
      return;
    }
    const promoCodeName = this.get('promoCodeName');
    const notify = this.get('notify');

    if (isPresent(promoCodeName)) {
      this.get('store')
        .query('promo-code', { filter: { code: promoCodeName } })
        .then((promoCodes) => {
          const promoCode = promoCodes.get('firstObject');

          if (promoCode) {
            notify.success(`Promo code has been applied`);
            this.set('promoCode', promoCode);
          } else {
            notify.error(`There is no promo code by that name`);
          }
        })
        .catch((_) => notify.error(_));
    } else {
      notify.success(`Promo Code has been removed`);
      this.set('promoCode', null);
    }
  }

  /**
   * @param {string[]}
   */
  shareTeamOrderLink(emails) {
    const orderId = this.get('id');
    const modelName = this.constructor.modelName;
    const adapter = this.store.adapterFor(modelName);
    const uri = `${adapter.buildURL(modelName, orderId)}/share-team-order-link`;

    const data = { emails: emails };

    return this.get('ajax').postJSONAPI(uri, data);
  }

  /**
   * @param {User}
   * @returns {Promise}
   */
  addUser(user) {
    const emails = [];
    const teams = [];

    emails.push(user.get('email'));

    const data = { emails: emails, teams: teams };

    return this.createShare({ data });
  }

  /**
   * @param {User}
   * @returns {Promise}
   */
  removeUser(user) {
    const emails = [];
    const teams = [];

    emails.push(user.get('email'));

    const data = { emails: emails, teams: teams };

    return this.deleteShare({ data });
  }

  /**
   * @param {User}
   * @returns {Promise}
   */
  addGroupOrderMember(user) {
    const groupOrderMember = this.get('store').createRecord('group-order-member', {
      order: this,
      email: user.get('email'),
      name: user.get('fullName'),
      phoneNumber: user.get('phoneNumber'),
      user: user
    });

    return groupOrderMember.save();
  }

  /**
   * @param {Team}
   * @returns {Promise}
   */
  addTeam(team) {
    const emails = [];
    const teams = [];

    teams.push(team.get('id'));

    const data = { emails: emails, teams: teams };

    return this.createShare({ data });
  }

  /**
   * @param {Team}
   * @returns {Promise}
   */
  removeTeam(team) {
    const emails = [];
    const teams = [];

    teams.push(team.get('id'));

    const data = { emails: emails, teams: teams };

    return this.deleteShare({ data });
  }

  /**
   * @param {User}
   * @returns {Promise}
   */
  removeGroupOrderMembers(users) {
    users.forEach((user) => {
      const groupOrderMember = this.get('groupOrderMembers').findBy('email', user.get('email'));

      if (groupOrderMember) {
        return groupOrderMember.destroyRecord();
      }
    });
  }

  /**
   * Adjusts the pickupAt, deadlineAt and expiresAt time to be sane values relative to the deliverAt
   * @param {Number} deadlineAtShift shift the deadline at by a certain amount in minutes instead of setting it based
   * on the restaurantLeadTime
   */
  adjustTimesRelativeToDeliverAt(deadlineAtShift) {
    const deliverAt = this.get('deliverAt');

    const areaLeadTimeInMinutes = this.get('area.deliveryLeadTime') || 30;

    const pickupAt = moment(deliverAt).subtract(areaLeadTimeInMinutes, 'minutes').toDate();

    let expiresAt = this.get('expiresAt');
    let deadlineAt = this.get('deadlineAt');

    if (this.get('isGroupOrder')) {
      const restaurantLeadTimeInHours = this.get('isAsap')
        ? 0
        : this.get('restaurant.leadTime') || 21;

      expiresAt = moment(deliverAt).subtract(1, 'seconds').toDate();

      if (deadlineAtShift) {
        deadlineAt = moment(deadlineAt).add(deadlineAtShift, 'minutes').toDate();
      } else {
        deadlineAt = moment(deliverAt).subtract(restaurantLeadTimeInHours, 'hours').toDate();
      }
    }

    this.setProperties({
      pickupAt,
      expiresAt,
      deadlineAt
    });
  }

  /**
   * Resets the deadlineAt to a the current restaurant lead time, or if sameday 4 hours, or if neither exists, 21 hours
   */
  resetDeadlineAtRelativeToDeliverAtAndRestaurant() {
    if (!this.get('isGroupOrder')) {
      return;
    }

    const deliverAt = this.get('deliverAt');
    const restaurantLeadTimeInHours = this.get('isAsap')
      ? 0
      : this.get('restaurant.leadTime') || 21;

    this.set('deadlineAt', moment(deliverAt).subtract(restaurantLeadTimeInHours, 'hours').toDate());
  }

  /*
   * Non-Crud Actions
   */

  clearCart = modelAction('cart', { method: 'DELETE' });

  createShare = modelAction('share', {
    method: 'POST'
  });

  deleteShare = modelAction('share', {
    method: 'DELETE'
  });

  remindStragglers = modelAction('remind_stragglers', {
    method: 'POST'
  });

  quote = modelAction('quote', {
    method: 'POST'
  });

  /**
   * Triggers the reprocessing of the inoice
   * @return {Promise}
   */
  reprocessInvoice = modelAction('reprocess-invoice', {
    method: 'POST',
    pushToStore: false
  });

  /**
   * Triggers the processing of payment
   * @return {Promise}
   */
  processPayment = modelAction('process-payment', {
    method: 'POST',
    pushToStore: false
  });

  /**
   * Triggers the synchronization process with salesforce
   * @return {Promise}
   */
  syncToSalesforce = modelAction('sync-to-salesforce', {
    method: 'POST',
    pushToStore: false
  });

  /**
   * Triggers the synchronization process with Accounting Seeds
   * @return {Promise}
   */
  syncToAccounting = modelAction('sync-to-accounting', {
    method: 'POST',
    pushToStore: false
  });

  /** Adds a menu filter (requirement) to the order */
  addMenuFilter = modelAction('add-menu-filter', {
    method: 'POST',
    pushToStore: true
  });

  /**
   * Duplicates the order that this method is called on
   * TODO refactor this to use ember-custom-actions
   * @param {boolean} duplicateOrderItems whether or not to duplciatte the order items
   * @return {Order} Returns the newly made Order
   */
  duplicate(duplicateOrderItems, keepMealEvent) {
    const modelName = this.constructor.modelName;
    const adapter = this.store.adapterFor(modelName);
    return adapter.duplicate(
      this,
      '',
      `duplicate_order_items=${duplicateOrderItems}&duplicate_inside_meal_event=${keepMealEvent}`
    );
  }

  redeliver = modelAction('redeliver', { method: 'POST' });
  compensateRestaurant = modelAction('compensate-restaurant', { method: 'POST' });

  /**
   * Checks if an order can be fast-forwarded and returns a diff preview of the changes
   */
  checkFastForward = modelAction('check-fast-forward', {
    method: 'GET'
  });

  /**
   * Fast-forwards an order to the latest menu for the restaurant
   */
  fastForward = modelAction('fast-forward', {
    method: 'PATCH'
  });

  allocateDelivery = modelAction('allocate-delivery', {
    method: 'PATCH'
  });

  deallocateDelivery = modelAction('deallocate-delivery', {
    method: 'PATCH'
  });
  requestCapacityOverride = modelAction('request-capacity-override', { method: 'POST' });
  approveCapacityOverride = modelAction('approve-capacity-override', {
    method: 'POST',
    pushToStore: true
  });
  denyCapacityOverride = modelAction('deny-capacity-override', {
    method: 'POST',
    pushToStore: true
  });
  emailsOnOrder = modelAction('emails-on-order', { method: 'GET', pushToStore: false });

  /**
   * Accounting
   */

  creditOrder = modelAction('credit-order', { method: 'POST' });

  /**
   * Misc
   */
  removeFromBounceList = modelAction('remove-from-bounce-list', {
    method: 'POST',
    pushToStore: false
  });

  /**
   * A copy constructor for this order, only copies _some_ of the attributes
   * @return {*}
   */
  copy() {
    const attrs = this.getProperties(
      'area',
      'client',
      'clientLocation',
      'contact',
      'paymentCard',
      'name',
      'notes',
      'deliverAt',
      'owner',
      'numberOfPeople',
      'restaurant',
      'restaurantLocation',
      'isAsap',
      'isGroupOrder',
      'teams',
      'deadlineAt',
      'perPersonBudget',
      'isFoodhall',
      'isMealPlanOrder',
      'payOutOfPocketFee',
      'waivedDeliveryFee',
      'waivedServiceFee'
    );

    return this.store.createRecord('order', attrs);
  }

  _fireStateEvent = modelAction('fire-state-event', {
    method: 'POST'
  });

  overrideState = modelAction('override-state', {
    method: 'PUT',
    pushToStore: true
  });

  // State Transitions
  arrivedAtRestaurant = modelActionWithErrors('arrived-at-restaurant', {
    method: 'POST',
    pushToStore: true
  });
  pickup = modelActionWithErrors('pickup', { method: 'POST', pushToStore: true });
  arrivedAtClient = modelActionWithErrors('arrived-at-client', {
    method: 'POST',
    pushToStore: true
  });
  deliver = modelActionWithErrors('deliver', { method: 'POST', pushToStore: true });
  cancel = modelActionWithErrors('cancel', {
    method: 'POST',
    pushToStore: true
  });

  async _runStateEvent(stateEvent) {
    this.stateEvent = stateEvent;
    try {
      await this.save();
    } catch (e) {
      console.error(`[order-state]${e}`);
      throw e;
    } finally {
      this.stateEvent = null;
    }
  }

  /** @type {number} */
  @computed('orderItems.@each.quantity')
  get orderSize() {
    return this.get('orderItems')
      .mapBy('quantity')
      .reduce((a, b) => a + b, 0);
  }

  @computed
  get label() {
    return this.get('identifier');
  }

  /*
   * Validations
   */
  validations = {
    name: {
      custom: {
        validation(key, value, order) {
          // TODO Change to regular presence: true validation after MF scuttling
          // When making regular order via MF user cannot enter an order name
          // and causes issues when editing from SF
          const isNew = order.get('isNew');

          return (isNew && isPresent(value)) || !isNew;
        },

        message: `Event name required.`
      }
    },
    bookmarkName: {
      custom: {
        validation(key, value, order) {
          return order.get('isBookmark') ? order.get('bookmarkName') : true;
        },

        message: 'Please provide a bookmark name.'
      }
    },
    area: { presence: { message: `Please select an area for the order.` } },
    client: { presence: { message: `Client required.` } },
    clientLocation: { presence: { message: `Delivery location required.` } },
    owner: { presence: { message: `Owner required.` } },
    contact: { presence: { message: `Contact required.` } },
    numberOfPeople: { presence: { message: `Number of people required.` } },

    perPersonBudget: {
      custom: [
        {
          validation(key, value) {
            return value ? parseFloat(value) >= 0 : true;
          },

          message: `Per person budget cannot be negative!`
        }
      ]
    },

    payOutOfPocketFee: {
      custom: [
        {
          validation(key, value, order) {
            return order.get('allowsPayOutOfPocket') ? value >= 0 : true;
          },

          message: `Top up fee should be above negative`
        }
      ]
    },
    restaurantLocation: {
      custom: [
        {
          /** Custom presence validation */
          validation(key, value, order) {
            const isNew = order.get('isNew') || order.get('state.isDraft');

            return isNew || isPresent(value);
          },

          message: `Pickup location required.`
        }
      ]
    },

    restaurant: {
      custom: [
        {
          /** Validates restaurant's relationship to Order#deliverAt only when creating a new order */
          validation(key, value, order) {
            if (order.get('isBookmark')) {
              return true;
            }

            const restaurant = value;
            const deliverAt = order.get('deliverAt');

            const isNew = order.get('isNew') || order.get('state.isDraft');
            const isAsap = order.get('isAsap');

            const lastCall = moment().add(order.get('restaurant.leadTime'), 'h');

            return !restaurant || !isNew || isAsap || moment(deliverAt).isAfter(lastCall);
          },

          message(key, value, order) {
            return `The order must be made at least ${order.get(
              'restaurant.leadTime'
            )} hours in advance for this restaurant, or be labeled as a ASAP order`;
          }
        },
        {
          /** Validates restaurant's area's relationship to deliverAt only when creating a new order */
          validation(key, value, order) {
            if (order.get('isBookmark')) {
              return true;
            }

            const restaurant = value;
            const deliverAt = order.get('deliverAt');

            const isNew = order.get('isNew') || order.get('state.isDraft');

            return !restaurant || !isNew || restaurant.isOutsideOfClosures(deliverAt);
          },
          message: `Restaurant is closed during this time.`
        }
      ]
    },

    deadlineAt: {
      custom: [
        {
          /** Ensure deadlineAt meets the lead time logic */
          validation(key, value, order) {
            const deadlineAt = value;
            const state = order.get('state');
            const deliverAt = order.get('deliverAt');
            const perPersonBudget = order.get('perPersonBudget');
            const isAsap = order.get('isAsap');
            const leadTime = order.get('restaurant.leadTime');
            const latestPossible = moment(deliverAt).subtract(leadTime, 'hours');
            const isBeforeLatest = moment(deadlineAt).isSameOrBefore(latestPossible);
            return isAsap || isBeforeLatest || !perPersonBudget || !leadTime || !state.isDraft;
          },

          message(key, value, order) {
            return `The deadline must be made at least ${order.get(
              'restaurant.leadTime'
            )} hours in advance for this restaurant. Select 'ASAP' to override this.`;
          }
        }
      ]
    },

    pickupAt: {
      custom: [
        {
          /** Ensure pickup time can not be after delivery time */
          validation(key, value, order) {
            const pickupAt = value;
            const deliverAt = order.get('deliverAt');
            return !order.get('perPersonBudget') || moment(pickupAt).isBefore(deliverAt);
          },

          message: `Pickup time can not be after delivery time.`
        }
      ]
    },

    bookmarkExpiresAt: {
      custom: [
        {
          /** Ensure orders aren't made in the past */
          validation(key, deliverAt, order) {
            return order.get('isBookmark') ? moment(deliverAt).isAfter(moment()) : true;
          },

          message: `Have you built a time machine!? That date is in the past!`
        }
      ]
    },

    deliverAt: {
      presence: true,

      date: {
        if: function (key, value, _this) {
          return !!_this.changedAttributes()[key];
        },

        after: new Date(),

        message: `Have you built a time machine!? That date is in the past!`
      },

      custom: [
        {
          /** Ensure that on new orders concierge is warned about area closures */
          validation(key, deliverAt, order) {
            const area = order.get('area');

            const isNew = order.get('isNew');

            return !area || !isNew || area.isOutsideAreaClosures(deliverAt);
          },

          message: `The area is closed for this time.`
        },
        {
          /** Warn when an order has been scheduled and is getting set to the past */
          validation(key, deliverAt, order) {
            const isDeliverAtDirty = !!order.changedAttributes().deliverAt;
            let isValid = true;

            // if the deliverAt attribute is dirty we try to validate, otherwise there's no need.
            if (isDeliverAtDirty) {
              const deliverAtAfterNow = moment(deliverAt).isAfter(moment());
              const isPastPlanningStates = [
                'scheduled',
                'driver_at_restaurant',
                'picked_up',
                'driver_at_client',
                'delivered',
                'ready_to_eat',
                'payment_processing',
                'payment_failed',
                'closed',
                'reported',
                'cancelled'
              ].includes(order.get('state'));

              const isInPlanningState = !isPastPlanningStates;
              const isOrderScheduledForDeliveryAfterNow = isPastPlanningStates && deliverAtAfterNow;

              isValid = isInPlanningState || isOrderScheduledForDeliveryAfterNow;
            }

            return isValid;
          },

          message:
            'A driver has already been scheduled. Are you sure you want to set the date to the past?'
        },
        {
          /** Ensure delivery time can not be before pickup time */
          validation(key, deliverAt, order) {
            const pickupAt = order.get('pickupAt');
            return moment(deliverAt).isAfter(pickupAt);
          },

          message: `Delivery time can not be before pickup time.`
        },
        {
          /** Ensure events don't have orders delivered on different dates */
          validation(key, deliverAt, order) {
            const event = order.get('event');
            if (
              !order.get('isDeliverable') ||
              !event ||
              !event.get('orders') ||
              event.get('orders').length <= 1
            ) {
              return true;
            }

            const deliverAtMoment = moment(deliverAt);
            return event
              .get('orders')
              .every((otherOrder) =>
                moment(otherOrder.get('deliverAt')).isSame(deliverAtMoment, 'day')
              );
          },

          message: `Delivery dates of orders in the same meal event have to be the same.`
        }
      ]
    }
  };
}
