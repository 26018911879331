import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, hasMany } from 'renard/models/foodee';

@classic
export default class Tag extends Model {
  /*
   * Attributes
   */
  @attr('string')
  emoji;

  @attr('string')
  name;

  @attr('string', { defaultValue: 'cuisine' })
  tagType;

  /*
   * Relationships
   */
  @hasMany('restaurant')
  restaurants;

  /*
   * Computed Properties
   */
  @computed('name')
  get humanize() {
    return `Tag '${this.get('name')}'`;
  }

  @computed('name', 'emoji')
  get label() {
    return `${this.get('name')} ${this.get('emoji') || ''}`;
  }

  @computed('name', 'emoji')
  get reverseLabel() {
    return `${this.get('emoji') || ''} ${this.get('name')}`;
  }

  /*
   * Validations
   */
  validations = {
    name: {
      presence: {}
    }
  };
}
