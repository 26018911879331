import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';
import { action } from '@ember/object';

@classic
export default class NewRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'TAG_EDIT';

  newTopCuisine() {
    return this.populateTopCuisine(
      this.store.createRecord('meal-planning-requirement', { topCuisine: true })
    );
  }

  /**
   * Ensure top cuisine has all constraint records
   */
  populateTopCuisine(topCuisine) {
    // eslint-disable-next-line no-unused-vars
    for (const tagType of ['MEAL', 'FOOD', 'DIETARY']) {
      if (topCuisine.requirementConstraints.isAny('tagType', tagType)) {
        continue;
      }
      topCuisine.requirementConstraints.addObject(
        this.store.createRecord('meal-planning-requirement-constraint', {
          tagType,
          constraintType: 'ANY',
          isReadonly: false,
          requirement: topCuisine
        })
      );
    }
    return topCuisine;
  }

  async model({ top_cuisine_id }) {
    const topCuisine =
      top_cuisine_id && top_cuisine_id !== 'new'
        ? this.populateTopCuisine(
            await this.store.findRecord('meal-planning-requirement', top_cuisine_id, {
              include: [
                'requirement-constraints',
                'requirement-constraints.meal-types',
                'requirement-constraints.food-types',
                'requirement-constraints.dietary-tags'
              ].join(',')
            })
          )
        : this.newTopCuisine();
    return { topCuisine };
  }

  @action
  willTransition() {
    this.modelFor('logged-in.tags.top-cuisine.new').topCuisine?.rollbackAttributes();
    return true;
  }

  @action
  didTransition() {
    const controller = this.controllerFor(this.routeName);
    controller?.scrollIntoView();
  }
}
