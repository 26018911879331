import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { isPresent } from '@ember/utils';
import moment from 'moment-timezone';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class NewOrderRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'ORDER_CREATE';

  /** @type {Service} */
  @service
  userSession;

  /** @type {Cookies} */
  @service
  cookies;

  model(params) {
    const minutesFromNowToFifteen = 15 - (moment().minute() % 15);

    const nextDayClosestFifteen = moment()
      .add(1, 'day')
      .add(minutesFromNowToFifteen, 'minutes')
      .seconds(0)
      .toDate();

    const deliverAt = params.deliverAt ? params.deliverAt : nextDayClosestFifteen;

    const order = this.store.createRecord('order', {
      deliverAt,
      restaurantPollDeadlineAt: moment().toDate(),
      creator: this.get('userSession.user'),
      bookmarkExpiresAt: params.bookmark ? deliverAt : undefined,
      isBookmark: isPresent(params.bookmark),
      isGroupOrder: params.isGroupOrder,
      budgetType: 'money'
    });
    order.adjustTimesRelativeToDeliverAt();

    return RSVP.hash({
      areas: this.store.query('area', {
        page: {
          limit: 100,
          offset: 0
        }
      }),
      order: order
    });
  }

  async afterModel(model, transition) {
    const controller = this.controllerFor('logged-in.new-order');

    controller.setProperties(transition.to.queryParams);

    if (!transition.to.queryParams.areaId) {
      controller.set('areaId', this._getDefaultAreaId(model.areas));
    }
    const area = this.store.peekRecord('area', controller.areaId);
    await area.query('areaClosures', {
      sort: 'startBlock',
      filter: { startsAfter: new Date().toISOString() }
    });

    controller.set('model', model);

    return controller._setOrderData();
  }

  /** Reset the route on activation */
  deactivate() {
    const controller = this.controllerFor('logged-in.new-order');
    controller._resetController();
  }

  /**
   * Returns the default area, first tries to find the default area based on weather or not a cookie has been set.
   * Afterwards it tries to use the guessed timezone
   * @private
   */
  _getDefaultAreaId(areas) {
    const guessedTimeZone = moment.tz.guess();
    const guessedArea = areas.findBy('isoTimeZone', guessedTimeZone);
    const firstArea = areas.get('firstObject');

    const guessedAreaId = guessedArea ? guessedArea.get('id') : null;
    const cookiedAreaId = this.get('cookies').read('ee.food.starfox.new-order-default-area-id');
    const validCookiedAreaId = areas.findBy('id', cookiedAreaId) ? cookiedAreaId : null;
    const firstAreaId = firstArea.get('id');

    return validCookiedAreaId || guessedAreaId || firstAreaId;
  }
}
