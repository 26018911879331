import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { copy } from '@ember/object/internals';
import EmberObject, { set } from '@ember/object';

// Base has to be an ember object until 2.16 when we can use regular classes
// all subclasses can be POJOs
@classic
export default class Base extends EmberObject {
  /** @type {Store} */
  @service
  store;

  /** @type {string} Must override if implemented */
  modelName = null;

  /** @type {string} */
  filterSearchName = 'search';

  /** @type {string[]} */
  defaultIncludes = null;

  /**
   * Default search method. Not to override, but to be used for generic use.
   * @param {string} modelName
   * @param {string} query
   * @param {string} filterSearchName
   * @param {string[]} defaultIncludes
   * @param {object} queryOptions
   * @returns {Promise.<*[]>}
   */
  defaultSearch(modelName, query, filterSearchName, defaultIncludes, queryOptions, resource) {
    queryOptions.filter = queryOptions.filter || {};
    queryOptions.includes = queryOptions.includes || defaultIncludes;

    set(queryOptions.filter, filterSearchName, query);

    return (resource || this.get('store')).query(modelName, queryOptions);
  }

  /**
   * Search function that all search actions can implement. If not implemented, it will use the defaultSearch method.
   * @param {string} query Query string to be passed search object
   * @param {object?} options Optional options to be used to configure search
   * @returns {Promise.<*[]>}
   */
  search(query, options, resource) {
    const modelName = this.get('modelName');
    const filterSearchName = this.get('filterSearchName');
    const defaultIncludes = this.get('defaultIncludes') || [];
    const queryOptions = copy(options);

    return this.defaultSearch(
      modelName,
      query,
      filterSearchName,
      defaultIncludes,
      queryOptions,
      resource
    );
  }
}
