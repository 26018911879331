import classic from 'ember-classic-decorator';
import ApplicationAdapter from './application';

@classic
export default class GiftbitError extends ApplicationAdapter {
  /**
   * Hits the retry action for a salesforce sync error
   *
   * @param {GiftbitError} model
   * @returns {Promise.<GiftbitError>}
   */
  retry(model) {
    const modelName = model.get('constructor.modelName');
    const url = `${this.buildURL(modelName, model.get('id'))}/retry`;

    model.set('isRetrying', true);

    return this.ajax(url, 'POST')
      .then((payload) => {
        model.store.pushPayload(modelName, payload);

        return model.store.peekRecord(modelName, payload.data.id);
      })
      .finally(() => model.set('isRetrying', false));
  }
}
