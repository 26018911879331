import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias, or } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';
import { dasherize } from '@ember/string';
import { OrderState } from 'renard/transforms/order-state';
import { OrderStateEvent } from 'renard/transforms/order-state-event';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

@classic
@tagName('')
export default class ActionOrderStateButton extends Component {
  @service tick;
  @service notify;
  @service actionCable;
  @service ajax;
  @service store;

  /** @type {String} The job id */
  @tracked
  jid = null;

  @tracked
  jobStatus = null;

  /** @type {boolean} */
  @tracked
  isLoading = false;

  /** @type {boolean} */
  @tracked
  isOverriding = false;

  jobEvents = ['job_done'];

  /** @type {boolean} */
  readonly = false;

  /** @type {boolean} */
  hasAdminOverride = true;

  /** @type {boolean} */
  showState = true;

  /** @type {Order} Order model */
  order = null;

  /** @type {boolean} Function that's passed from the controller */
  setStateEvent() {}

  orderStateEvent = OrderStateEvent;
  orderState = OrderState;

  /** @type {Function} Handler for failure cases */
  handleDidFail = null;

  /** @type {String} The order's identifier. E.g.: VAN-1234 */
  @alias('order.identifier')
  identifier;

  /** @type {String} The order's state */
  @alias('order.state')
  state;

  /** @type {Bool} Check if order state is for account managers to muddle with */
  @or('state.isClosed', 'state.isCancelled')
  isOptionless;

  /** @type {string} */
  @computed('showState')
  get icon() {
    if (!this.get('isCaretShowing')) {
      return '';
    }

    return this.get('showState') ? 'down caret icon' : 'vertical ellipsis icon';
  }

  /** @type {boolean} Will return true if the order is within 24 hours. */
  @computed('tick.time')
  get isWithinTwentyFourHours() {
    const order = this.get('order');
    const deliverAt = moment(order.get('deliverAt'));
    const now = moment();

    const hoursUntilDelivery = deliverAt.diff(now, 'hours');

    return hoursUntilDelivery < 24;
  }

  /** @type {boolean} Decides if caret should show per user permission */
  @computed('isOptionless')
  get isCaretShowing() {
    // For now...
    return !this.isOptionless;
  }

  /** @type {string} Order state in human-readable format: no _ and upper case */
  @computed('order.state')
  get label() {
    return this.get('order.state').toUpperCase().replace('_', ' ');
  }

  /** @type {function} Builds a css class based on order state*/
  @computed('state')
  get stateCssClass() {
    const showStateClasses = this.showState ? ' show-state tiny ' : '';
    const dasherizedState = dasherize(this.state) ?? '';

    return `fde-controls-action-order-state-button${showStateClasses} fde-order-state-color_${dasherizedState}`;
  }

  /** @type {function} Builds a css class based on order state*/
  @computed('state', 'showState', 'isLoading', 'runOrderMethod.isRunning')
  get cssClasses() {
    const showStateClasses = this.showState ? ' show-state tiny ' : '';
    const isLoadingClass = this.isLoading || this.runOrderMethod.isRunning ? ' loading ' : '';

    return `--fde-order-state-button${showStateClasses}${isLoadingClass} fde-controls-action-order-state-button`;
  }

  @action
  async handleSetStateEvent(state) {
    if (confirm(`Are you sure you want to '${state}' ${this.identifier}?`)) {
      this.isLoading = true;
      await this.setStateEvent(state);
      this.isLoading = false;
    }
  }

  @action
  async handleOverrideState(state) {
    if (confirm(`Are you sure you want to set #${this.identifier} to '${state}'?`)) {
      this.isOverriding = true;

      this.order
        .overrideState({ state: state.value })
        .then((_) => this.notify.success(`Successfully set the state to '${state}'`))
        .catch((errorObject) => {
          this.handleDidFail(errorObject);
          this.order.rollbackAttributes();
        })
        .finally((_) => (this.isOverriding = false));
    }
  }

  @task
  async runOrderMethod(method) {
    await method.bind(this.order).call();
  }
}
