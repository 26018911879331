import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('fde-amount-and-number-of-people')
@classNameBindings('isInline:fde-is-inline')
export default class AmountAndNumberOfPeople extends Component {
  /** @type {Order} */
  order = null;

  /** @type {Boolean} */
  showAmount = false;

  /** @type {string} shorthand of the order type  */
  @computed
  get type() {
    const order = this.get('order');
    const type = [];

    if (!order) {
      return '';
    }

    if (order.get('isFoodhall')) {
      type.push('FH');
    } else if (order.get('isPoop')) {
      type.push('OP');
    } else if (order.get('isMealPlanOrder')) {
      type.push('MP');
    } else if (order.get('orderType') === 'team') {
      type.push('TO');
    } else if (order.get('orderType') === 'group') {
      type.push('GO');
    } else {
      type.push('RG');
    }

    if (order.isFoodeeServed) {
      type.push('FS');
    }

    if (order.isClientDemo) {
      type.push('CD');
    }

    if (order.isRestaurantDemo) {
      type.push('RD');
    }

    if (order.get('isAsap')) {
      type.push('ASAP');
    }

    if (order.get('lastMinute')) {
      type.push('LM');
    }

    return type.join('/');
  }

  /** @type {string} human friend version of the order type  */
  @computed
  get typeTooltip() {
    const order = this.get('order');

    if (!order) {
      return '';
    }

    const tooltip = [];

    if (order.get('isAsap')) {
      tooltip.push('ASAP');
    }

    if (order.get('lastMinute')) {
      tooltip.push('Last Minute');
    }

    if (order.isFoodeeServed) {
      tooltip.push('Foodee Served');
    }

    if (order.isClientDemo) {
      tooltip.push('Client Demo');
    }

    if (order.isRestaurantDemo) {
      tooltip.push('Restaurant Demo');
    }

    if (order.get('isFoodhall')) {
      tooltip.push('virtual foodhall');
    } else if (order.get('isPoop')) {
      tooltip.push('top up team');
    } else if (order.get('isMealPlanOrder')) {
      tooltip.push('meal plan');
    } else if (order.get('orderType') === 'team') {
      tooltip.push('team');
    } else if (order.get('orderType') === 'group') {
      tooltip.push('group');
    } else {
      tooltip.push('regular');
    }

    tooltip.push('order');

    return tooltip.join(' ');
  }
}
