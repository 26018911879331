import classic from 'ember-classic-decorator';
import SearchAction from './base';

@classic
export default class Client extends SearchAction {
  /** @override */
  modelName = 'client';

  /** @override */
  filterSearchName = 'search';
}
