import classic from 'ember-classic-decorator';
import $ from 'jquery';
import RSVP from 'rsvp';
import Service from '@ember/service';

/**
 * This modal service leverages Semantic-UI modals.
 */
@classic
export default class ModalService extends Service {
  /**
   * @private {function}
   * Private function that opens modal
   */
  _openModal(params) {
    const selector = `.${params.selector.split(' ').join('.')}`;

    return new RSVP.Promise((resolve, _reject) => {
      $(selector)
        .modal({
          closable: params.closable,
          context: '.ember-application',
          allowMultiple: false,

          className: {
            active: 'active',
            scrolling: 'scrolling'
          },

          onVisible: () => resolve(),

          onHide: () => params.onHide && params.onHide(),
          onHidden: () => params.onHidden && params.onHidden(),

          onApprove: () => params.onApprove && params.onApprove(),
          onDeny: () => params.onDeny && params.onDeny(),

          inverted: true
        })
        .modal('show');
    }, 'show modal');
  }

  /**
   * Temporary hack to remove all modals from the DOM and kill dupes
   * @private {Function}
   */
  _nukeAllModals() {
    $('.ui.modals').remove();
  }

  /**
   * @private {function}
   * Private function for hiding modal
   */
  _hideModal(selectorString) {
    const selector = `.${selectorString.split(' ').join('.')}`;

    return new RSVP.Promise((resolve, _reject) => {
      $(selector)
        .modal({
          onHidden: () => resolve()
        })
        .modal('hide');
    }, 'hide modal').then(() => {
      // Test will complain if we use `set` on destroyed object
      if (!this.get('isDestroyed')) {
        this.set('selector', null);
      }
    });
  }

  /**@type {function} Uses unique id to identify modal to close */
  closeModal(event, selectorString) {
    return this._hideModal(selectorString);
  }

  /**@type {function} Uses unique id when opening modal */
  openModal(event, params) {
    return this._openModal(params);
  }
}
