import classic from 'ember-classic-decorator';
import Model, { attr, hasMany } from 'renard/models/foodee';

@classic
export default class GiftbitGift extends Model {
  @attr('boolean')
  isActive;

  @attr('string')
  name;

  @attr('string')
  description;

  @attr('string')
  imageUrl;

  @attr('string')
  tax;

  @attr('string')
  currencyisocode;

  @attr('number')
  maxPriceInCents;

  @attr('number')
  minPriceInCents;

  @attr('number')
  maxFoodeePriceInCents;

  @attr('number')
  minFoodeePriceInCents;

  @hasMany('client')
  clients;
}
