import classic from 'ember-classic-decorator';
import { attributeBindings, classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import RSVP from 'rsvp';
import { action, computed } from '@ember/object';
import { typeOf } from '@ember/utils';
import moment from 'moment-timezone';
import { task } from 'ember-concurrency';

@classic
@classNames('fde-forms-event-planning-form-for-event-form-for ui green segment')
@attributeBindings('index:data-test-meal-planning-event-form-for')
export default class EventPlanDayEventFormFor extends Component {
  @service
  notify;

  @service
  store;

  /** @type {Area} */
  area = null;

  /** @type {EventPlanEvent} */
  event = null;

  /** @type {EventPlanEventDay} */
  day = null;

  /** @type {EventPlanInstance|EventPlanTemplate} */
  mealPlan = null;

  /** @type {Restaurant[]} */
  restaurants = null;

  /** @type {boolean} */
  readonly = false;

  /** @type {Restaurant[]} */
  highlightRestaurants = null;

  selectedRestaurantConstraint = null;

  onSelectRestaurantConstraint = null;

  onRestaurantChange() {}

  @computed('readonly', 'event.inProgress')
  get eventDetailsFrozen() {
    return this.get('readonly') || this.get('event.inProgress');
  }

  /** @type {Date} */
  @computed('eventPlan.startOfWeek', 'day', 'event')
  get eventDeliverAtDate() {
    const startOfWeek = this.get('eventPlan.startOfWeek');
    const day = this.get('day');
    const area = this.get('area');
    return this.get('event').getDeliverAtDate(startOfWeek, day, area);
  }

  @computed('day.dayOfWeek', 'event.leadTime')
  get leadTimeDayAndTime() {
    return moment(this.get('event.deliverAt'))
      .subtract(this.get('event').get('leadTime'), 'hours')
      .format('ddd h:mm a');
  }

  /** @type {boolean} */
  @computed('event.reservations.@each.state')
  get hasOpenReservations() {
    const reservations = this.get('event.reservations') || [];
    return (
      reservations.get('length') &&
      reservations.filter((_) => _).some((_) => _.get('state') !== 'cancelled')
    );
  }

  @task(function* () {
    return yield (this.get('area.content') || this.get('area'))
      .planReservations({
        meal_planning_reservation_ids: this.get('event.reservations').mapBy('id')
      })
      .catch((error) => {
        const errorDetail = error.errors?.[0]?.detail || '';
        this.get('notify').error('An error has happened: ' + errorDetail);
      });
  })
  requestNewRestaurantTask;

  async redistributeNumberOfPeopleAndSave() {
    const event = this.get('event');
    const numberOfPeople = event.get('numberOfPeople');

    if (typeOf(numberOfPeople) !== 'number') {
      return;
    }
    const otherReservations = this.get('event.reservations').filterBy('isNotCancelled').toArray();

    const newNumberOfPeople = Math.ceil(numberOfPeople / otherReservations.length);

    await RSVP.Promise.all(
      otherReservations.map((_) => {
        _.set('numberOfPeople', newNumberOfPeople);
        return _.save();
      })
    );
  }

  @action
  async handleNumberOfPeopleValueCommit() {
    await this.redistributeNumberOfPeopleAndSave();
    await this.checkRestaurantAvailability();
  }

  @action
  async addReservation() {
    this.set('isAddingReservation', true);

    const mealPlan = this.get('mealPlan');
    const event = this.get('event');
    const area = this.get('area');

    try {
      this.get('store').createRecord('meal-planning-reservation', {
        mealPlanningInstance: mealPlan,
        client: mealPlan.get('client'),
        area: area,
        event: this.get('event'),
        deliverAt: event.get('deliverAt'),
        pickupAt: event.get('pickupAt')
      });

      await this.redistributeNumberOfPeopleAndSave();
    } catch (e) {
      this.get('notify').error('Something went wrong: ' + e.message);
    } finally {
      this.set('isAddingReservation', false);
    }
  }

  @action
  handleDidDestroy() {
    this.get('day.events').removeObject(this.get('event'));
  }

  @action checkRestaurantAvailability() {
    RSVP.Promise.all(
      this.event.reservations.map((reservation) => {
        return reservation.checkRestaurantAvailability();
      })
    );
  }

  @action async didCommitValue() {
    this.checkRestaurantAvailability();
    await this.event.reservations.reload();
  }
}
