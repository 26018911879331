import classic from 'ember-classic-decorator';
import { observes } from '@ember-decorators/object';
import Component from '@ember/component';
import '@ember/object';

@classic
export default class RangeSelect extends Component {
  /** @type{number} **/
  min = 0;

  /** @type{number} **/
  max = 0;

  /** @type{number} **/
  value = 0;

  /** @type{number} **/
  step = 0;

  /** @type{function} **/
  onChange() {}

  @observes('value')
  valueDidChange() {
    this.$('.range').range('set value', this.get('value'));
  }

  @observes('min')
  minDidChange() {
    this._initRangeSelect();
  }

  @observes('max')
  maxDidChange() {
    this._initRangeSelect();
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    this._initRangeSelect();
  }

  _initRangeSelect() {
    const onChange = this.get('onChange');

    this.$('.range').range({
      min: this.get('min'),
      max: this.get('max'),
      step: this.get('step'),
      start: this.get('value'),
      onChange(val) {
        onChange(val);
      }
    });
  }
}
