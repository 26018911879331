import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';

@classic
export default class ImportOrdersController extends Controller {
  @service
  ajax;

  deliverAt = new Date();
  text = null;

  @alias('model.clientId')
  clientId;

  orders = null;

  reset() {
    this.setProperties({
      text: '',
      orders: null
    });
  }

  save() {
    this.set('isSaving', true);

    return this.get('ajax')
      .postJSON('/api/v3/orders/import', {
        text: this.get('text'),
        client_id: this.get('clientId'),
        deliver_at: this.get('deliverAt')
      })
      .then((_) => {
        this.setProperties({
          orders: _.orders,
          error: _.error
        });
      })
      .finally((_) => this.set('isSaving', false));
  }
}
