import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import moment from 'moment-timezone';

@classic
export default class AccountingController extends Controller {
  selectedGroupOrderMember = null;
  queryParams = ['memberPageLimit', 'memberPageOffset', 'groupOrderMemberSearchQuery'];
  memberPageLimit = 20;
  memberPageOffset = 0;

  /** @type {strings[]} */
  pusherChannels = ['ee.food.accounting.events'];

  /** @type {strings[]} */
  pusherEvents = ['pusher:subscription_succeeded', 'invoice_processed'];

  ledgers = null;
  legacyLedgers = null;
  isSyncingToAccounting = false;
  isSyncingToSalesforce = false;
  isReprocessingInvoice = false;

  reloadInvoice(data) {
    const { invoice_id, at } = data;

    console.info(
      `${moment().format('hh:mm:ss ')} [${
        this.pubSub
      }] Handling invoice_processed ${invoice_id} @ ${at}`
    );

    const record = this.store.peekRecord('accounting-ledger-item', invoice_id);

    if (record && record.get('updatedAt') < Date.parse(at)) {
      record.reload();
    }
  }

  @service
  notify;

  @action
  syncInvoice(invoice) {
    const order = this.get('model.order');
    return invoice
      .sync()
      .then((_) =>
        RSVP.all([
          order.query('clientInvoice', { reload: true }),
          order.query('restaurantInvoice', { reload: true }),
          _
        ])
      )
      .then(([_restaurrantInvoice, _clientInvoice, _]) => invoice.setProperties(_));
  }

  @action
  processInvoice() {
    return this.get('selectedGroupOrderMember').processInvoice();
  }

  @action
  invoiceProcessed(data) {
    this.reloadInvoice(data);
  }

  @action
  invoiceClosed(data) {
    this.reloadInvoice(data);
  }

  @action
  invoiceCancelled(data) {
    this.reloadInvoice(data);
  }

  @action
  selectGroupOrderMember(groupOrderMember) {
    this.set('selectedGroupOrderMember', groupOrderMember);
  }

  @action
  async addCreditNote() {
    const order = this.get('model.order');
    await order.creditOrder({
      amount: 1.23,
      accounting_code: 1234,
      reason: 'because'
    });
    this.send('refreshModel');
  }

  @action
  syncToSalesforce() {
    this.set('isSyncingToSalesforce', true);
    this.get('model.order')
      .syncToSalesforce()
      .then(() => this.get('notify').success('Synced to Salesforce'))
      .catch((response) => this.get('notify').error(response.errors[0].detail))
      .finally(() => this.set('isSyncingToSalesforce', false));
  }

  @action
  syncToAccounting() {
    this.set('isSyncingToAccounting', true);
    this.get('model.order')
      .syncToAccounting()
      .then(() => this.get('notify').success('Scheduled to Sync to Accounting'))
      .catch((response) => this.get('notify').error(response.errors[0].detail))
      .finally(() => this.set('isSyncingToAccounting', false));
  }

  @action
  reprocessInvoice() {
    this.set('isReprocessingInvoice', true);
    return this.get('model.order')
      .reprocessInvoice()
      .then(() => this.get('notify').success('Scheduled to Reprocess the Invoice'))
      .finally(() => this.set('isReprocessingInvoice', false));
  }
}
