import classic from 'ember-classic-decorator';
import FeedItemBuilder from './base';
import moment from 'moment-timezone';

@classic
export default class TeamOrderBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);

    const event = version.get('event');
    const startBlock = moment(version.getValue('startBlock')).format('MMMM Do YYYY, h:mm:ss a');
    const endBlock = moment(version.getValue('endBlock')).format('MMMM Do YYYY, h:mm:ss a');

    if (event === 'create') {
      feedItem.subject = `${version.get(
        'whodunnit'
      )} closed the area from ${startBlock} to ${endBlock}`;
    } else if (event === 'update') {
      feedItem.subject = `${version.get(
        'whodunnit'
      )} updated an area to closure ${startBlock} to ${endBlock}`;
    } else {
      feedItem.subject = `${version.get('whodunnit')} removed a closure from the area`;
    }

    feedItem.icon = 'ban';

    return feedItem;
  }
}
