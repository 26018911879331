import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';

@classic
export default class VersionsTable extends Component {
  /** @type {Version[]} */
  versions = null;

  /** @type {String} */
  route = '';

  /**
   * @type{Object[]} an array of versions in a more palatable form
   */
  @computed('versions.[]')
  get simplifiedVersions() {
    return this.get('versions').map((version) => {
      const changeKeys = Object.keys(version.get('objectChanges')).filter(
        (key) => key !== 'updatedAt'
      );

      const changes = changeKeys.map((changeKey) => {
        const [oldValue, newValue] = version.get(`objectChanges.${changeKey}`);
        return {
          changeKey,
          oldValue: JSON.stringify(oldValue, null, 2),
          newValue: JSON.stringify(newValue, null, 2)
        };
      });

      return EmberObject.create({
        whodunnit: version.get('whodunnit'),
        createdAt: version.get('createdAt'),
        changes: changes
      });
    });
  }
}
