import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';

import { action } from '@ember/object';

@classic
export default class ShowController extends Controller {
  @action
  retry() {
    this.get('model').retry();
  }

  @action
  handleDidDestroy() {
    this.transitionToRoute('logged-in.admins.giftbit-errors');
  }
}
