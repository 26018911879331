import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

import { action } from '@ember/object';

@classic
export default class ReleaseNotesRoute extends Route {
  model(_params) {
    return this.store.query('release-note', {
      sort: '-createdAt'
    });
  }

  @action
  reloadReleaseNotes() {
    return this.refresh();
  }
}
