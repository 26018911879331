import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias, union } from '@ember/object/computed';
import { run } from '@ember/runloop';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import RSVP from 'rsvp';

@classic
export default class ShowController extends Controller {
  @service
  notify;

  @alias('model.menu')
  menu;

  @alias('model.restaurant')
  restaurant;

  @alias('model.dietaryTags')
  dietaryTags;

  /** @type {?MenuOptionGroup} */
  menuOptionGroup = null;

  /** @type {?Order[]} */
  activeOrders = null;

  /** @type {boolean} */
  isCheckingMenuDependencies = false;

  /** @type {?Order[]} */
  menuOrders = null;

  /** @type {boolean} whether or not a given tax rate is the area's default */
  _isDefaultTaxRate(taxRate) {
    return taxRate.get('isDefault');
  }

  /** @type {invoicingTaxRate[]} the area's invoicing tax rates */
  @union('model.areas.firstObject.invoicingTaxRates', 'model.globalTaxRates')
  areaTaxRates;

  /** @type {invoicingTaxRate} the area's default tax rate */
  @computed('areaTaxRates', 'areaTaxRates.[]')
  get defaultTaxRate() {
    const areaTaxRates = this.get('areaTaxRates');

    return areaTaxRates && areaTaxRates.find((taxRate) => this._isDefaultTaxRate(taxRate));
  }

  /** @type {boolean} */
  @computed('menuOrders', 'isCheckingMenuDependencies')
  get shouldDisableMenu() {
    return this.get('menuOrders') || this.get('isCheckingMenuDependencies');
  }

  /**
   * @param {MenuItem} menuItem
   * @returns {MenuOptionGroup}
   * @private
   */
  _createMenuOptionGroup(menuItem) {
    return this.store
      .createRecord('menu-option-group', {
        verb: 'pick',
        name: 'A Topping',
        menuItem
      })
      .save();
  }

  /**
   * @param {MenuOptionGroup} menuOptionGroup
   * @returns {MenuOptionItem}
   * @private
   */
  _createMenuOptionItem(menuOptionGroup) {
    return this.store
      .createRecord('menu-option-item', {
        name: 'New Option Item',
        menuOptionGroup,
        clientPriceCents: 0,
        restaurantPriceCents: 0,
        retailPriceCents: 0
      })
      .save();
  }

  @action
  handleOpenMenuOptionItemModal(menuItem) {
    if (!menuItem.get('menuOptionGroups.length')) {
      return this._createMenuOptionGroup(menuItem)
        .then((menuOptionGroup) => this.set('menuOptionGroup', menuOptionGroup))
        .then((_) => this._createMenuOptionItem(this.get('menuOptionGroup')))
        .then((_) => this.set('menuOptionGroup', menuItem.get('menuOptionGroups.firstObject')));
    } else {
      return new RSVP.Promise((resolve) => {
        resolve(this.set('menuOptionGroup', menuItem.get('menuOptionGroups.firstObject')));
      });
    }
  }

  @action
  handleCreateMenuOptionGroup(menuItem) {
    return this._createMenuOptionGroup(menuItem)
      .then((menuOptionGroup) => {
        return this.set('menuOptionGroup', menuOptionGroup);
      })
      .then((_) => {
        return this._createMenuOptionItem(this.get('menuOptionGroup'));
      });
  }

  @action
  handleCreateMenuOptionItem(menuOptionGroup) {
    return this._createMenuOptionItem(menuOptionGroup);
  }

  @action
  addGroup() {
    const menu = this.get('menu');

    this.store
      .createRecord('menu-group', {
        name: 'Your Name Here',
        description: 'Your Description Here',
        menu
      })
      .save();
  }

  @action
  duplicate(model) {
    return run((_) => {
      return model
        .duplicate()
        .then((_) => this.get('notify').success(`Duplicated ${model.get('humanize')}`));
    });
  }

  @action
  duplicateMenu() {
    const menu = this.get('menu');
    menu
      .duplicate()
      .then((newMenu) =>
        this.transitionToRoute(
          'logged-in.restaurants.show.menus.show',
          menu.get('restaurant.id'),
          newMenu.get('id')
        )
      );
  }

  @action
  createMenuItem(menuGroup) {
    console.debug('RestaurantMenu Controller #createMenuItem', menuGroup);

    this.store
      .createRecord('menu-item', {
        menuGroup,
        name: 'Your Name Here',
        description: 'Your Description Here',
        taxRate: this.get('defaultTaxRate')
      })
      .save();
  }
}
