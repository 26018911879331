import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { action, computed } from '@ember/object';
import Controller from '@ember/controller';
import PusherHandlersMixin from 'star-fox/mixins/pusher-handlers-mixin';

@classic
export default class ManageTeamsController extends Controller.extend(PusherHandlersMixin) {
  queryParams = [
    'joinedUsersPageLimit',
    'joinedUsersPageOffset',
    'orderTeamsPageLimit',
    'orderTeamsPageOffset'
  ];

  /** @type {Notify} */
  @service
  notify;

  /** @type {Team} */
  selectedTeam = null;

  /** @type {Order} */
  @alias('model.order')
  order;

  /** @type {User[]} */
  defaultTeamUsers = null;

  /** @type {boolean} */
  @alias('model.order.canAddTeamsAndUsers')
  canAddGroupOrderMember;

  /** @type {boolean} */
  @alias('model.order.enableTeamsAndUsersReminder')
  enableReminder;

  /** @type {string} */
  _userSearchText = '';

  /** @type {number} */
  @alias('model.orderTeams.meta.recordCount')
  orderTeamsRecordCount;

  /** @type {number} */
  orderTeamsPageOffset = 0;

  /** @type {number} */
  orderTeamsPageLimit = 10;

  /** @type {number} */
  joinedUsersPageLimit = 10;

  /** @type {number} */
  joinedUsersPageOffset = 0;

  /** @type {boolean} */
  remindAllUsersIsLoading = false;

  /** @type {boolean} */
  isTeamsSearching = false;

  /** @type {number} */
  @alias('model.joinedUsers.meta.recordCount')
  joinedUsersRecordCount;

  @computed('model.clientTeams.[]', 'model.orderTeams.[]')
  get availableTeams() {
    const orderTeams = this.get('model.orderTeams');

    return this.get('model.clientTeams').filter(
      (clientTeam) => !orderTeams.findBy('id', clientTeam.get('id'))
    );
  }

  /** @type {boolean} Whether or not records will be listed over more than one page. */
  @computed('orderTeamsRecordCount', 'orderTeamsPageLimit', 'model.orderTeams.[]')
  get orderTeamsHasPages() {
    const recordCount = this.get('orderTeamsRecordCount');
    const pageLimit = this.get('orderTeamsPageLimit');
    return recordCount > pageLimit;
  }

  /** @type {boolean} Whether or not records will be listed over more than one page. */
  @computed('joinedUsersRecordCount', 'joinedUsersPageLimit', 'model.joinedUsers.[]')
  get joinedUsersHasPages() {
    const recordCount = this.get('joinedUsersRecordCount');
    const pageLimit = this.get('joinedUsersPageLimit');
    return recordCount > pageLimit;
  }

  searchedUsers = null;

  setDefaultTeamUsers() {
    const defaultTeamId = this.get('model.clientTeams.firstObject.id');

    const queryOptions = {
      filter: {
        teamId: defaultTeamId
      }
    };

    this.store.query('user', queryOptions).then((users) => this.set('defaultTeamUsers', users));
  }

  @action
  triggerShareNotification(user) {
    const enableReminder = this.get('enableReminder');

    if (!enableReminder) {
      return;
    } else {
      const order = this.get('model.order');
      const notify = this.get('notify');
      const email = user.get('email');

      order
        .shareTeamOrderLink([email])
        .then((_) => notify.success(`Reminder was sent to ${user.get('fullName')}`))
        .catch((_) => notify.error(`Unable to send reminder to ${user.get('fullName')}`));
    }
  }

  @action
  handleAddGroupOrderMemberClick(user) {
    const order = this.get('model.order');
    const notify = this.get('notify');
    const canAddGroupOrderMember = this.get('canAddGroupOrderMember');

    if (!canAddGroupOrderMember) {
      return;
    } else {
      return order
        .addGroupOrderMember(user)
        .then((_) => this.send('refreshModel'))
        .then((_) => notify.success(`${user.get('fullName')} was added to cart`))
        .catch((_) => notify.error(`Could not add ${user.get('fullName')} to cart`));
    }
  }

  @action
  handleOrderTeamsPaginationChange(pageOffset) {
    return this.set('orderTeamsPageOffset', pageOffset);
  }

  @action
  handleJoinedUsersPaginationChange(pageOffset) {
    return this.set('joinedUsersPageOffset', pageOffset);
  }

  @action
  searchForDefaultTeamUsers(search) {
    const teamId = this.get('model.client.teams').findBy('isDefault', true).get('id');

    return this.store
      .query('user', { filter: { search, teamId } })
      .then((_) => this.set('searchedUsers', _));
  }

  @action
  handleSelectTeam(team) {
    if (this.get('selectedTeam') == team) {
      this.set('selectedTeam', null);
    } else {
      this.set('selectedTeam', team);
    }
  }

  @action
  handleAddTeam(team) {
    const order = this.get('order');
    const notify = this.get('notify');
    this.set('isTeamsSearching', true);

    return order
      .addTeam(team)
      .then((_) => this.get('model.orderTeams').pushObject(team))
      .then((_) => this.send('refreshModel'))
      .then((_) => notify.success(`${team.get('name')} is now ordering`))
      .catch((_) => notify.error(`Could not add ${team.get('name')}`))
      .finally((_) => this.set('isTeamsSearching', false));
  }

  @action
  handleRemoveTeam(team) {
    const order = this.get('order');
    const notify = this.get('notify');
    const teamMembers = team.get('members');
    let newPageOffset = 0;

    if (this.get('orderTeamsHasPages')) {
      const formerRecordCount = this.get('orderTeamsRecordCount');

      const formerPageOffset = this.get('orderTeamsPageOffset');

      const isLastRecordOnLastPage = formerRecordCount === formerPageOffset + 1;

      if (isLastRecordOnLastPage) {
        newPageOffset = formerPageOffset - this.get('orderTeamsPageLimit');
      } else {
        newPageOffset = this.get('orderTeamsPageOffset');
      }
    }

    return order
      .removeTeam(team)
      .then((_) => order.removeGroupOrderMembers(teamMembers))
      .then((_) => this.send('refreshModel'))
      .then((_) => this.send('handleOrderTeamsPaginationChange', newPageOffset))
      .then((_) => notify.success(`${team.get('name')} has been removed`))
      .catch((_) => notify.error(`Could not remove ${team.get('name')}`));
  }

  @action
  handleAddUser(user) {
    const order = this.get('order');
    const notify = this.get('notify');

    return order
      .addUser(user)
      .then((_) => this.get('model.joinedUsers').pushObject(user))
      .then((_) => this.send('refreshModel'))
      .then((_) => notify.success(`${user.get('fullName')} is now ordering`))
      .catch((_) => notify.error(`Could not add ${user.get('email')}`));
  }

  @action
  handleRemoveUser(user) {
    const order = this.get('order');
    const notify = this.get('notify');
    const formerRecordCount = this.get('joinedUsersRecordCount');
    let newPageOffset = 0;

    if (this.get('joinedUsersHasPages')) {
      const formerPageOffset = this.get('joinedUsersPageOffset');

      const isLastRecordOnLastPage = formerRecordCount === formerPageOffset + 1;

      if (isLastRecordOnLastPage) {
        newPageOffset = formerPageOffset - this.get('joinedUsersPageLimit');
      } else {
        newPageOffset = this.get('joinedUsersPageOffset');
      }
    }

    return order
      .removeUser(user)
      .then((_) => order.removeGroupOrderMembers([user]))
      .then((_) => this.send('refreshModel'))
      .then((_) => this.send('handleJoinedUsersPaginationChange', newPageOffset))
      .then((_) => notify.success(`${user.get('email')} has been removed`))
      .catch((_) => notify.error(`Could not remove ${user.get('email')}`));
  }

  @action
  remindAllUsers() {
    const order = this.get('model.order');
    const notify = this.get('notify');
    this.set('remindAllUsersIsLoading', true);

    order
      .remindStragglers()
      .then((_) => notify.success('Reminders were sent'))
      .catch((_) => notify.error('Something went wrong'))
      .finally((_) => this.set('remindAllUsersIsLoading', false));
  }
}
