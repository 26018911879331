import { helper as buildHelper } from '@ember/component/helper';
import { camelize, capitalize } from '@ember/string';

export function TitleizeSnakeCase(snakeCaseString = 'default_string') {
  return capitalize(camelize(snakeCaseString[0]))
    .replace(/([A-Z])/g, ' $1')
    .trim();
}

export default buildHelper(TitleizeSnakeCase);
