import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class DriverPayment extends Model {
  /*
   * Attributes
   */
  @attr('number')
  amount;

  @attr('date')
  deliveryDate;

  @attr('boolean')
  manuallyAdded;

  @attr('string')
  notes;

  @attr('number')
  orderId;

  @attr('string')
  orderIdentifier;

  @attr('string')
  ruleName;

  /*
   * Relationships
   */
  @belongsTo('driver')
  driver;

  @belongsTo('driver-payroll-run')
  driverPayrollRun;

  /*
   * Validations
   */
  validations = {
    amount: { presence: { message: `Please provide an amount.` } },
    ruleName: { presence: { message: `Please provide an event name.` } }
  };
}
