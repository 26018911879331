import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@classNames('amount-control form-control')
export default class AmountControl extends Component {
  /** {string} **/
  @alias('value.amountType')
  amountType;

  /** {number} **/
  @alias('value.amount')
  amount;

  /** {number} **/
  max = null;

  /** {number} **/
  min = 0;

  /** {number} **/
  step = 'any';

  /** {boolean} **/
  readonly = false;

  /** {boolean} **/
  disabled = false;

  /** {boolean} **/
  disableAmountToggle = false;

  @computed('amountType')
  get isPercentage() {
    return this.amountType === 'percentage';
  }

  @computed('max', 'amountType')
  get _max() {
    return this.isPercentage ? 100 : this.max;
  }

  /**
   * Icon mapping for the current amount type
   *
   * {string}
   **/
  @computed('amountType')
  get amountTypeIcon() {
    return this.get('amountType') === 'percentage' ? 'percent' : 'dollar';
  }

  /**
   * Returns the type label for the current amount type
   */
  @computed('amountType')
  get amountTypeLabel() {
    return this.get('amountType') === 'percentage' ? '%' : '$';
  }

  /**
   * Next amount type to be toggled to, this could be extended to include
   * configurable keys
   *
   * {string}
   **/
  @computed('amountType')
  get nextAmountType() {
    return this.get('amountType') === 'percentage' ? 'value' : 'percentage';
  }

  /**
   * @callback changeHandler
   * @property {changeHandler}
   */
  onChange() {}

  /**
   * Notify change handlers of the change
   *
   * @private
   */
  _sendChange(change) {
    this.onChange(Object.assign({}, this.get('value'), change));
  }

  /** Handles a change type event */
  @action
  handleChange(e) {
    let value = e.target.value;

    // if min is greater then or equal to zero ABS the value
    // turns out the input field does not actually enforce this
    // rule.
    if (this.get('min') >= 0) {
      value = Math.abs(value);
    }

    if (this._max && value >= this._max) {
      value = this._max;
    }

    value = parseFloat(value.toFixed(2));

    this._sendChange({ amount: value });
  }

  @action
  toggleAmountType() {
    if (this.disableAmountToggle) {
      return;
    }
    const amountType = this.get('nextAmountType');
    this._sendChange({ amountType });
  }

  @action
  handleMouseUp(e) {
    this.handleChange(e);
  }
}
