import classic from 'ember-classic-decorator';
import Helper from '@ember/component/helper';
import moment from 'moment-timezone';

/**
 * Formats a time to show the chosen time range a delivery window may be.
 * Our current delivery windows are within 15 minutes
 * @param {string} date
 * @param {string} format
 * @param {string} isoTimeZone
 * @returns {string}
 */
export function formatDeliveryWindow([date, format = 'lll', isoTimeZone = moment.tz.guess()]) {
  const deliverAt = moment.tz(date, isoTimeZone);
  const startTime = deliverAt.format(format);

  const endTime = deliverAt.clone().add(15, 'minutes').format(format);

  return `${startTime} - ${endTime}`;
}

@classic
export default class FormattedDeliveryWindow extends Helper {
  compute = formatDeliveryWindow;
}
