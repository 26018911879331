import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

/**
 * Returns whether the expectedPath is the currentRouteName
 * @param {string} expectedPath
 * @returns {boolean}
 */
@classic
export default class RouteIs extends Helper {
  @service
  router;

  compute([expectedPath]) {
    return this.get('router.currentRouteName') === expectedPath;
  }
}
