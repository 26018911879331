import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';

@classic
export default class LogoutController extends Controller {
  @service('session')
  session;

  @computed('email', 'password')
  get canLogin() {
    return !!this.get('email') && !!this.get('password');
  }

  @action
  async logout() {
    await this.get('session').invalidate();
    this.transitionToRoute('login');
  }

  @action
  cancel() {
    this.transitionToRoute('logged-in');
  }
}
