import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('ui grid fde-invoices-header-row')
@classNameBindings('isMainTitle')
export default class HeaderRow extends Component {
  /** @type {string} */
  title = null;

  /** @type {boolean} */
  isMainTitle = false;

  /** @type {MenuGroup | GroupOrderMember | Team} */
  groupByRecord = null;

  /** @type {string} */
  icon = 'user';
}
