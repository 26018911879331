import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';

@classic
export default class UserInviteController extends Controller {
  /** @type {Service} */
  @service('session')
  session;

  /** @type {boolean} canLogin  */
  @computed('model.password', 'model.passwordConfirmation')
  get canLogin() {
    return (
      this.get('model.password.length') >= 8 &&
      this.get('model.password') === this.get('model.passwordConfirmation')
    );
  }

  @action
  login() {
    this.get('session')
      .authenticate('authenticator:devise', this.get('model.email'), this.get('model.password'))
      .then(() => this.transitionToRoute('logged-in.profile'));
  }
}
