import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import EmberObject, { action } from '@ember/object';
import Component from '@ember/component';

const TYPES = {
  Credit: 'credit',
  Refund: 'refund'
};

@classic
@classNames('fde-accounting-credit-note-modal')
export default class CreditNoteModal extends Component {
  /** @type {boolean} */
  readonly = false;

  /** @type {AccountingLedgerItem|InvoicingLedgerItem} */
  ledger = null;

  /** @type {object} */
  credit = null;

  /** @type {TYPES} */
  type = TYPES.Credit;

  /** @type {NotificationService} */
  @service
  notify;

  /** @type {boolean} */
  showModal = false;

  didCredit() {}

  @action
  async handleSubmit() {
    const credit = this.get('credit');

    try {
      await this.get('ledger')[this.get('type')]({
        amount: credit.get('amount'),
        accounting_code: credit.get('accountingCode.code'),
        reason: credit.get('reason')
      });

      this.didCredit();

      this.set('showModal', false);
    } catch (e) {
      this.get('notify').error(e.errors[0].message);
    }
  }

  @action
  async applyCredit() {
    this.set(
      'credit',
      EmberObject.create({
        accountingCode: this.get('discountCodes.firstObject'),
        amount: this.get('ledger.refundableAmount'),
        reason: ''
      })
    );
    this.set('showModal', true);
  }
}
