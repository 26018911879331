import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';

import { action } from '@ember/object';

@classic
export default class DevController extends Controller {
  @action
  refreshQueue() {
    this.send('refreshModel');
  }
}
