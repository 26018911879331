/* eslint-disable ember/no-computed-properties-in-native-classes */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { alias, union } from '@ember/object/computed';
import { arg } from 'ember-arg-types';
import { action, get } from '@ember/object';

export default class FormControlsChargeControl extends Component {
  @service
  appConfiguration;

  @alias('appConfiguration.discountCodes.preferences.clientDiscounts')
  clientDiscounts;

  @alias('appConfiguration.discountCodes.preferences.restaurantDiscounts')
  restaurantDiscounts;

  @union('clientDiscounts', 'restaurantDiscounts')
  discountCodes;

  @arg
  showAccountingCode = true;

  @arg
  showCaseId = true;

  @arg
  showActiveOn = false;

  @arg
  showDescription = false;

  @arg
  renderInline = false;

  @arg
  discountCodesKey = null;

  @arg
  showTooltip = false;

  constructor(...args) {
    super(...args);

    this.appConfiguration.fetchAndUpdate();
  }

  get model() {
    return this.args.value?.copy() || {};
  }

  get description() {
    const ret = [];

    if (this.model.name) {
      ret.push(`${this.model.name}: `);
    }

    if (this.model.description) {
      ret.push(this.model.description);
    }

    if (this.active) {
      if (this.model.caseId) {
        ret.push(`Case Id: ${this.model.caseId}`);
      }
      if (this.model.accountingCode) {
        ret.push(`Acct Code: ${this.model.accountingCode}`);
      }
    }

    return ret.join(', ');
  }

  get _discountCodes() {
    return this.discountCodesKey ? get(this, this.discountCodesKey) : this.discountCodes;
  }

  @arg
  onChange = (_value) => {};

  @action
  handleDidSubmit() {
    this.onChange(this.model.copy());
  }

  @action
  updateDescription(discountCode) {
    const model = this.model.copy();
    model.description = this._discountCodes.findBy('code', discountCode).description;
    this.onChange(model);
  }
}
/* eslint-enable ember/no-computed-properties-in-native-classes */
