import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';

import { action } from '@ember/object';

@classic
export default class NewController extends Controller {
  @action
  handleDidSave() {
    this.send('refreshModel');
    this.transitionToRoute('logged-in.tags.restaurant');
  }

  @action
  handleCancelNewTag() {
    const { tag } = this.get('model');
    tag && tag.destroyRecord();
    this.transitionToRoute('logged-in.tags.restaurant');
  }
}
