import classic from 'ember-classic-decorator';
import Component from '@ember/component';

import { action } from '@ember/object';

@classic
export default class MessageView extends Component {
  /** @property {boolean} */
  showing = true;

  /** @property {boolean} */
  isCloseable = true;

  /** @property {boolean} */
  isOpenable = false;

  init(...args) {
    super.init(...args);

    const storageKey = this.get('storageKey');
    if (storageKey) {
      const val = localStorage.getItem(storageKey);

      if (val) {
        this.showing = val === 'true';
      }
    }
  }

  setShowing(showing) {
    this.set('showing', showing);
    const storageKey = this.get('storageKey');
    if (storageKey) {
      localStorage.setItem(storageKey, showing);
    }
  }

  @action
  close() {
    this.setShowing(false);
  }

  @action
  open() {
    this.setShowing(true);
  }
}
