import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class EditRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'MENU_EDIT';

  /**
   * getDraftMenuWithDependencies - fetches the draft menu for this restaurant
   * from the API, together with all its dependencies needed to edit it
   *
   * @param  {Restaurant} restaurant the current restaurant
   * @return {Menu} the fetched menu
   */
  getDraftMenuWithDependencies(restaurant) {
    return this.store
      .query('menu', {
        include: [
          'area',
          'menu-groups.food-types',
          'menu-groups.meal-types',
          'menu-groups.menu-items.food-types',
          'menu-groups.menu-items.meal-types',
          'menu-groups.menu-items.dietary-tags',
          'menu-groups.menu-items.tax-rate',
          'menu-groups.menu-items.menu-option-groups.menu-option-items.dietary-tags'
        ].join(','),
        filter: {
          draft: true,
          restaurantId: restaurant.id
        }
      })
      .then((menus) => menus.get('firstObject'));
  }

  getParentMenu(restaurant) {
    return this.store
      .query('menu', {
        filter: {
          active: true,
          restaurantId: restaurant.id
        }
      })
      .then((menus) => menus.get('firstObject'));
  }

  /**
   * createDraftFromClone - fetches and clones a menu, then fetches again
   * the new menu to get its dependencies
   *
   * @param  {Restaurant} restaurant the current restaurant
   * @return {Menu} the new menu in draft mode
   */
  createDraftFromClone(restaurant) {
    return this.store
      .query('menu', {
        filter: {
          active: true,
          restaurantId: restaurant.id
        }
      })
      .then((menus) => menus.get('firstObject').duplicate())
      .then(() => this.getDraftMenuWithDependencies(restaurant));
  }

  model() {
    const { restaurant } = this.modelFor('logged-in.restaurants.show');

    return RSVP.hash({
      restaurant,
      area: restaurant.get('area'),
      areas: restaurant.get('areas', { include: 'invoicing-tax-rates' }),
      dietaryTags: this.store.query('dietary-tag', { page: { limit: 100 } }),
      foodTypes: this.store.query('food-type', { page: { limit: 200 } }),
      mealTypes: this.store.query('meal-type', { page: { limit: 200 } }),
      globalTaxRates: this.store.query('invoicing-tax-rate', {
        filter: {
          global: true
        }
      }),

      menu: this.getDraftMenuWithDependencies(restaurant).then((menu) => {
        if (menu) {
          return menu;
        }

        return this.createDraftFromClone(restaurant);
      }),

      parentMenu: this.getParentMenu(restaurant)
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setupPusher();

    if (model.parentMenu) {
      controller._checkAffectedOrders();
    }
  }

  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);

    controller.set('frozen', false);
    controller.teardownPusher();
  }

  @action
  willTransition(transition) {
    const menu = this.modelFor('logged-in.restaurants.show.menus.edit').menu;

    if (
      menu.get('needsSave') &&
      !confirm('You have unsaved changes, are you sure you want to leave?')
    ) {
      transition.abort();
    } else {
      menu.rollbackAttributes();
      return true;
    }
  }
}
