import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class EditRoute extends Route {
  model(params) {
    return this.store.findRecord('client', params.client_id, {
      reload: true,
      include: [
        'billing-location',
        'billing-contact',
        'sales-support',
        'company',
        'admins',
        'owner',
        'teams',
        'areas',
        'admin-contacts',
        'order-contacts',
        'delivery-locations.location',
        'delivery-locations.area',
        'client-discounts',
        'subscription-plan'
      ].join(',')
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    // Subscribe to pusher events,
    // the channelName is defined in the controller.
    controller.setupPusher();
  }

  // Unsubscribe from the pusher channels we've been listening to.
  // Clean up when we leave. We probably don't want  to still be receiving events.
  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);

    controller.teardownPusher();
  }
}
