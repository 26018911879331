import classic from 'ember-classic-decorator';
import $ from 'jquery';
import Controller from '@ember/controller';
import { action } from '@ember/object';

@classic
export default class ListsController extends Controller {
  sections = [
    {
      title: 'Foos',
      data: [
        { title: 'Foo', subtitle: 'Description', icon: 'lock', avatar: 'url' },
        { title: 'Foo', subtitle: 'Description', icon: 'lock', avatar: 'url' },
        { title: 'Foo', subtitle: 'Description', icon: 'lock', avatar: 'url' },
        { title: 'Foo', subtitle: 'Description', icon: 'lock', avatar: 'url' },
        { title: 'Foo', subtitle: 'Description', icon: 'lock', avatar: 'url' },
        { title: 'Foo', subtitle: 'Description', icon: 'lock', avatar: 'url' }
      ]
    },
    {
      title: 'Bars',
      data: [
        { title: 'Bar', subtitle: 'Bar Description', icon: 'lock', avatar: 'url' },
        { title: 'Bar', subtitle: 'Bar Description', icon: 'lock', avatar: 'url' },
        { title: 'Bar', subtitle: 'Bar Description', icon: 'lock', avatar: 'url' },
        { title: 'Bar', subtitle: 'Bar Description', icon: 'lock', avatar: 'url' },
        { title: 'Bar', subtitle: 'Bar Description', icon: 'lock', avatar: 'url' },
        { title: 'Bar', subtitle: 'Bar Description', icon: 'lock', avatar: 'url' }
      ]
    },
    {
      title: 'Bazes',
      data: [
        { title: 'Baz', subtitle: 'Baz Description', icon: 'lock', avatar: 'url' },
        { title: 'Baz', subtitle: 'Baz Description', icon: 'lock', avatar: 'url' },
        { title: 'Baz', subtitle: 'Baz Description', icon: 'lock', avatar: 'url' },
        { title: 'Baz', subtitle: 'Baz Description', icon: 'lock', avatar: 'url' },
        { title: 'Baz', subtitle: 'Baz Description', icon: 'lock', avatar: 'url' },
        { title: 'Baz', subtitle: 'Baz Description', icon: 'lock', avatar: 'url' }
      ]
    },
    {
      title: 'Quxes',
      data: [
        { title: 'Qux', subtitle: 'Qux Description', icon: 'lock', avatar: 'url' },
        { title: 'Qux', subtitle: 'Qux Description', icon: 'lock', avatar: 'url' },
        { title: 'Qux', subtitle: 'Qux Description', icon: 'lock', avatar: 'url' },
        { title: 'Qux', subtitle: 'Qux Description', icon: 'lock', avatar: 'url' },
        { title: 'Qux', subtitle: 'Qux Description', icon: 'lock', avatar: 'url' },
        { title: 'Qux', subtitle: 'Qux Description', icon: 'lock', avatar: 'url' }
      ]
    }
  ];

  selected = null;

  @action
  handleOnSelect(item) {
    this.set('selected', item);
  }

  @action
  handleItemAction(item) {
    alert(item.title);
  }

  @action
  focusList() {
    $('.fde-lists-section-list').focus();
  }

  @action
  blurList() {
    $('.fde-lists-section-list').blur();
  }
}
