import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import EmberObject, { action, computed } from '@ember/object';

@classic
export default class ShouldBe extends Component {
  /** @type {Filter} */
  filter = null;

  /** @type {boolean} All potential values for filters should be a string because they get parsed from query params */
  trueValue = 'true';

  /** @type {boolean} All potential values for filters should be a string because they get parsed from query params */
  falseValue = 'false';

  /** @type {?String} */
  trueValueLabel = null;

  /** @type {?String} */
  falseValueLabel = null;

  /** @type {boolean} */
  includeFalseValue = false;

  /** @override */
  init() {
    super.init(...arguments);
    if (this.get('useDefaultValue')) {
      this.setProperties({
        'filter.value': [this.get('trueValue')],
        'filter.valueText': [this.get('trueValueLabel') || this.get('trueValue')]
      });
    }
  }

  /** @type {Object} */
  @computed('filter.value.[]', 'radioGroupValues.[]')
  get selectedRadioGroupValue() {
    const value = this.get('filter.value.firstObject');
    return this.get('radioGroupValues').findBy('value', value);
  }

  /** @type {Object[]} */
  @computed('trueValue', 'falseValue', 'includeFalseValue')
  get radioGroupValues() {
    const radioValues = this.get('includeFalseValue')
      ? [this.get('trueValue'), this.get('falseValue')]
      : [this.get('trueValue')];
    const trueValue = this.get('trueValue');

    return radioValues.map((value, index) =>
      EmberObject.create({
        id: index,
        value: value,
        name:
          value === trueValue
            ? `${this.get('filter.label')}: ${this.get('trueValueLabel') || this.get('trueValue')}`
            : `${this.get('filter.label')}: ${
                this.get('falseValueLabel') || this.get('falseValue')
              }`
      })
    );
  }

  @action
  handleRadioButtonChange(object) {
    console.debug(object);
    const trueValue = this.get('trueValue');
    const falseValue = this.get('falseValue');
    const trueValueLabel = this.get('trueValueLabel');
    const falseValueLabel = this.get('falseValueLabel');

    const isTrueValue = trueValue === object.get('value');

    const valueLabel = isTrueValue ? trueValueLabel || trueValue : falseValueLabel || falseValue;

    const filter = this.get('filter');
    filter.setValue([object.get('value')], [valueLabel]);
  }
}
