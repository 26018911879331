import { inject as service } from '@ember/service';
import { DateTime } from 'luxon';
import { alias, filterBy, oneWay, sort, union } from '@ember/object/computed';
import Component from '@ember/component';
import { isEmpty } from '@ember/utils';
import { on } from '@ember/object/evented';
import { action, computed, observer } from '@ember/object';
import PusherBindingsMixin from 'star-fox/mixins/pusher-bindings-mixin';
import moment from 'moment-timezone';

export default Component.extend(PusherBindingsMixin, {
  classNames: ['fde-logistics-expanded-row fde-logistics-order-row_expanded-row-border'],
  classNameBindings: ['isSuperSized:is-super-sized'],

  /** @type {Service} */
  appConfiguration: service(),

  pusher: service(),
  actionCable: service(),
  userSession: service(),
  store: service(),
  currentUser: alias('userSession.user'),

  /** @type {string} channels take the form of ee.food.drivers.<driver-id>.pings */
  channelName: null,

  /** @type {DeskCase} new DeskCase obj */
  deskCase: null,

  driverUnavailable: null,

  /** @type {boolean} is an loading order items */
  isLoading: false,

  /** @type {Order} */
  order: null,

  /* Pusher events we listen to */
  pusherEvents: ['ping', 'pusher:subscription_succeeded'],

  /** @type {boolean} */
  showLateCallButton: false,

  /** @type {string[]} sorting key for sortedCases */
  sortingKey: ['createdAt'],

  historicDriverPosition: null,

  isSuperSized: false,

  /**
   * Computed properties
   */

  /** @type {boolean} */
  useNewPubSub: computed('userSession.session.user', function () {
    return this.userSession.session.user?.hasFeatureEnabled('newPubSub') ?? false;
  }),

  /** @type {string} */
  pubSub: computed('useNewPubSub', function () {
    return this.useNewPubSub
      ? { service: this.actionCable, name: 'cable' }
      : { service: this.pusher, name: 'pusher' };
  }),

  /** @type {string?} the type of API, if any, that can be used to schedule with this courier */
  courierApiType: alias('order.courier.content.apiType'),

  /** @type {Array.<DeliveryCase>} Delivery cases on an order */
  deliveryCases: alias('order.deliveryCases'),

  /** @type {Array.<DeskCase>} Delivery cases on an order */
  deskCases: alias('order.deskCases'),

  /** @type {?Array.<SalesforceCase>} Salesforce cases on an order */
  salesforceCases: null,

  /** @type {Array.<SalesforceCase>} sorted SalesforceCases */
  sortedSalesforceCases: sort('salesforceCases', 'sortingKey'),

  /** @type {Array.<DeskCase|DeliveryCase>} DeliveryCases and DeskCases */
  unionedCases: union('deliveryCases', 'deskCases'),

  /** @type {boolean} */
  showModal: false,

  /** @type {Array.<DeskCase|DeliveryCase>} sorted DeliveryCases and DeskCases */
  sortedCases: sort('unionedCases', 'sortingKey'),

  /** @type {Array.<DeskCase|DeliveryCase>} cases excluding unsaved cases */
  sortedSavedCases: filterBy('sortedCases', 'isNew', false),

  /** @type {function} */
  resetDeskCase() {},

  /** @type {Object.<string>} Resto information */
  restaurant: computed('order', function () {
    return {
      name: this.get('order.restaurant.name'),
      addressLine_1: this.get('order.restaurantLocation.addressLine_1'),
      addressLine_2: this.get('order.restaurantLocation.addressLine_2')
    };
  }),

  /** @type {Object.<string>} Client information */
  client: computed('order', function () {
    let client = {
      name: this.get('order.client.name'),
      addressLine_1: this.get('order.clientLocation.addressLine_1'),
      addressLine_2: this.get('order.clientLocation.addressLine_2')
    };

    if (isEmpty(client.addressLine_1)) {
      client.addressLine_1 = this.get('order.customLocation.addressLine_1');
      client.addressLine_2 = this.get('order.customLocation.addressLine_2');
    }

    return client;
  }),

  /** @type {User} */
  driver: oneWay('order.driver'),

  /** @type {User} */
  user: alias('userSession.user'),

  onDriverChange: on(
    'init',
    observer('order.driver', function () {
      const driver = this.order.driver;

      if (this.channelName) {
        this.pubSub.service.unwire(this, this.channelName, this.pusherEvents);
      }

      if (driver) {
        this.set('channelName', `ee.food.drivers.${driver.id}.pings`);
      } else {
        this.set('channelName', `ee.food.drivers.all.pings`);
      }

      this.pubSub.service.wire(this, this.channelName, this.pusherEvents);
    })
  ),

  async didInsertElement() {
    this._super(...arguments);

    // little trick to prevent the scroll wheel from bubbling to the parent document
    let $scroll = this.$('.fde-logistics-expanded-row_overflow');
    $scroll.on('mousewheel', function (e) {
      const scrollingDown = e.originalEvent.deltaY > 0;
      const scrolledToBottom = this.scrollTop === this.scrollHeight - this.offsetHeight;
      const scrolledToTop = this.scrollTop === 0;

      if ((scrolledToBottom && scrollingDown) || (scrolledToTop && !scrollingDown)) {
        e.preventDefault();
      }
    });

    this.set('isLoading', true);

    const order = this.get('order');

    await this._loadSalesforceCases(order);

    return order
      .loadOrderItems()
      .then(() => {
        const inTransit = order.get('inTransit');
        const owner = order.get('owner');
        const showLateCallButton = !!(
          inTransit &&
          owner.get('communicationPreference.sms') &&
          owner.get('smsNumber')
        );

        this.set('showLateCallButton', showLateCallButton);
      })
      .finally((_) => this.set('isLoading', false));
  },

  _loadSalesforceCases(order) {
    return order.loadSalesforceCases().then((cases) => this.set('salesforceCases', cases));
  },

  willDestroyElement() {
    this._super(...arguments);

    this.pubSub.service.unwire(this, this.channelName, this.pusherEvents);

    this.$('.fde-logistics-expanded-row_overflow').off('mousewheel');
  },

  loadSalesforceCases: action(function (order) {
    return this._loadSalesforceCases(order);
  }),

  pusher__subscriptionSucceeded: action(function () {
    console.info(
      `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Connected to ${this.channelName}`
    );
  }),

  ping: action(function (data) {
    console.info(
      `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Received Ping on ${this.channelName}`
    );

    const driver = this.get('order.driver');

    if (driver) {
      driver.updatePosition({
        created_at: DateTime.now().toISO(),
        latitude: data.latitude,
        longitude: data.longitude
      });
    }
  }),

  setHistoricDriverPosition: action(function (driverPosition) {
    this.set('historicDriverPosition', driverPosition);
  })
});
