import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('ui segment blue')
export default class AttributesTable extends Component {
  /** @type {string} title - the title of the header **/
  title = 'Attributes';
}
