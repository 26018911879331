import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { DateTime } from 'luxon';

@classic
@classNames('sf-logistics-order-map')
export default class OrderMap extends Component {
  customOptions = {
    draggable: true,
    disableDefaultUI: true,
    disableDoubleClickZoom: false,
    fullscreenControl: false
  };

  @service
  tock;

  @service
  appConfiguration;

  order = null;

  historicDriverPosition = null;

  didInsertElement() {
    super.didInsertElement(...arguments);
    const driver = this.get('order.driver');

    if (driver) {
      driver
        .latestPosition()
        .then((latestPosition) => driver.set('currentPosition', latestPosition));
    }
  }

  get driverUnavailableTime() {
    return this.appConfiguration.logistics?.eta?.driverUnavailableTime ?? 10;
  }

  @computed('order.driver.currentPosition')
  get driverUnavailable() {
    return (
      !this.ping ||
      this.wallTime.diff(DateTime.fromISO(this.ping.created_at), 'minutes').minutes >
        this.driverUnavailableTime
    );
  }

  get wallTime() {
    return DateTime.fromJSDate(this.tock.date);
  }

  get ping() {
    return this.get('order.driver.currentPosition');
  }

  updateDriver() {
    const driver = this.get('order.driver.content');
    driver && driver.reload();
  }

  init() {
    super.init(...arguments);
    this.updateDriver();
  }
}
