import Fixture, {
  element,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class SfRestaurantsMultiRestaurantModalsConfirmFixture extends Fixture {
  constructor() {
    super('data-test-sf-modal="confirm');
  }

  @element({ selector: 'data-test-sf-modal-header' }) header;
  @element({ selector: 'data-test-sf-modal-content' }) content;
  @element({ selector: 'data-test-sf-modal-actions' }) actions;
}

export const SfRestaurantsMultiRestaurantModalsConfirm = getElementDecoratorFor(
  SfRestaurantsMultiRestaurantModalsConfirmFixture
);
export const SfRestaurantsMultiRestaurantModalsConfirmWithValue = getElementWithValueDecoratorFor(
  SfRestaurantsMultiRestaurantModalsConfirmFixture
);
