import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { htmlSafe } from '@ember/template';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { run } from '@ember/runloop';
import { Promise } from 'rsvp';
import { task } from 'ember-concurrency';

@classic
@classNames('fde-requirement-group-form-for')
export default class RequirementGroupFormFor extends Component {
  /** @type {boolean} */
  autoSubmit = true;

  /** @type {Service} */
  @service
  store;

  /** @type {MealPlanningRequirementGroup} */
  requirementGroup = null;

  /** @type {boolean} */
  hideDetails = false;

  /** @type {boolean} */
  showIndexLabels = false;

  /** @type {Section[]} */
  @computed('requirementGroup.requirements.[]', 'requirementGroup.portionSize')
  get requirementsSectionList() {
    return [
      {
        title: 'Menu Item Requirements',
        data: this.get('requirementGroup.requirements'),
        infoTitle: `What's a requirement?`,
        infoContent: htmlSafe(`
<p>
A requirement represents a client's dietary & budgetary requirements for a single menu item.
</p>
<p>
An example of a requirement is '3 options for an entree that isn't a salad but is gluten free and is less than 12
dollars'.
</p>
      `)
      }
    ];
  }

  /** @type {MealPlanningRequirement} */
  selectedRequirement = null;

  /** @type {?MealPlanningRequirement} */
  @alias('loadSelectedRequirementTask.lastSuccessful.value')
  loadedSelectedRequirement;

  /** @type {task} */
  @(task(function* () {
    const selectedRequirement = this.get('selectedRequirement');
    const isReloadable =
      selectedRequirement &&
      !selectedRequirement.get('isNew') &&
      !selectedRequirement.get('isDeleted') &&
      !selectedRequirement.get('markReloaded');

    if (isReloadable) {
      yield selectedRequirement.reload();
      selectedRequirement.set('markReloaded', true);
      return selectedRequirement;
    } else {
      return Promise.resolve(selectedRequirement);
    }
  }).restartable())
  loadSelectedRequirementTask;

  /** @type {task} */
  @task(function* () {
    const budget = this.get('requirementGroup.budget');
    const requirement = yield this.get('requirementGroup').createRequirement({
      // TODO This should migrate to this.store.createRecord('requirement', parent: requirementGroup);
      max_price: budget ? budget * 100 : null
    });

    run.next(() => requirement.validate());

    this.set('selectedRequirement', requirement);
    yield this.get('loadSelectedRequirementTask').perform();

    const searchTask = this.get('searchTask');

    if (searchTask) {
      searchTask.perform();
    }
  })
  addRequirementTask;

  /** @type {task} */
  @task(function* () {
    const selectedRequirement = this.get('selectedRequirement');
    yield selectedRequirement && selectedRequirement.destroyRecord();

    this.set('selectedRequirement', this.get('requirementGroup.requirements.lastObject'));
    yield this.get('loadSelectedRequirementTask').perform();

    const searchTask = this.get('searchTask');

    if (searchTask) {
      searchTask.perform();
    }

    this.onRequirementSelect(null);
  })
  destroySelectedRequirementTask;

  /** @override */
  init() {
    super.init(...arguments);
    if (!this.get('selectedRequirement')) {
      this.set('selectedRequirement', this.get('requirementGroup.requirements.lastObject'));
    }
  }

  /** @override */
  didInsertElement() {
    super.didInsertElement(...arguments);
    this.get('loadSelectedRequirementTask').perform();
  }

  /** @override */
  didUpdateAttrs() {
    super.didUpdateAttrs();
    this.get('loadSelectedRequirementTask').perform();
  }

  /** Event that emits the requirement selected */
  onRequirementSelect /* requirement */() {}

  @action
  handleRequirementSelect(requirement) {
    this.set('selectedRequirement', requirement);
    this.get('loadSelectedRequirementTask').perform();
    this.onRequirementSelect(requirement);
  }

  @action
  handleAddRequirementClick() {
    this.get('addRequirementTask').perform();
  }

  @action
  handleRemoveRequirementClick() {
    this.get('destroySelectedRequirementTask').perform();
  }
}
