import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { action } from '@ember/object';
import { RootRecord } from '../path-select/component';
import { inject as service } from '@ember/service';
import { decamelize } from '@ember/string';

@classic
export default class HandlebarsControl extends Component {
  lines = 10;
  mode = '';
  value = null;
  _value = null;

  options = {
    enableBasicAutocompletion: true,
    enableSnippets: true,
    enableLiveAutocompletion: false
  };

  recordTypes = ['order:Order'];

  @service
  store;

  get rootTypes() {
    return this.recordTypes.map((recordType) => {
      const chunks = recordType.split(':');
      if (chunks.length === 1) {
        return {
          value: decamelize(recordType),
          caption: decamelize(recordType),
          meta: recordType
        };
      } else {
        return {
          value: chunks[0],
          caption: chunks[0],
          meta: chunks[1]
        };
      }
    });
  }

  @action
  suggestCompletions(editor, session, { row, column }) {
    const OPEN_HANDLEBARS = '{{';
    const CLOSE_HANDLEBARS = '}}';

    const currentToken = session.getTokenAt(row, column);
    let tokens = session.getTokens(row);
    let currentTokenIndex = tokens.indexOf(currentToken);
    tokens = tokens.slice(0, currentTokenIndex);

    const lastHandlebarsBlock = tokens.findLast(
      (_) => _.value === OPEN_HANDLEBARS || _.value === CLOSE_HANDLEBARS
    );
    const insideHandlebarsBlock = lastHandlebarsBlock?.value === OPEN_HANDLEBARS;

    if (insideHandlebarsBlock) {
      const justOpenedHandlebarsBlock = tokens.at(currentTokenIndex - 1) === lastHandlebarsBlock;
      if (justOpenedHandlebarsBlock) {
        return this.rootTypes;
      } else {
        const pathTokens = tokens.slice(tokens.indexOf(lastHandlebarsBlock) + 1).mapBy('value');
        const first = pathTokens.at(0);
        const rootRecordType = this.rootTypes.find((_) => _.value === first);

        // If we haven't correctly started this block with the order type and a period
        // return no suggestions
        if (!rootRecordType && pathTokens.at(1) !== '.') {
          return [];
        }

        // pop the record name and first period off of the path.
        pathTokens.shift();
        pathTokens.shift();

        const currentPath = pathTokens.join('');
        const properties = RootRecord.pathProperties(currentPath, rootRecordType.meta, this.store);
        const lastProperty = properties.lastObject.selected ?? properties.lastObject.relationship;

        return lastProperty.values.map((value) => ({
          value: value.name,
          caption: value.name,
          meta: value.type
        }));
      }
    } else {
      return [];
    }
  }

  onChange() {}

  didInsertElement() {
    super.didInsertElement(...arguments);
    super.didInsertElement(...arguments);
    const $textarea = this.$('.ace_editor textarea');
    this.set('_value', this.get('value'));

    $textarea.on('blur', () => {
      this.onChange(this.get('_value'));
    });
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    const $textarea = this.$('.ace_editor textarea');
    $textarea.off();
  }
}
