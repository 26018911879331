import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import moment from 'moment-timezone';
import FilterRouteMixin from 'star-fox/mixins/filter-route-mixin';

export const NUMBER_OF_LOADED_ORDERS = 20;

export const LOGS_ORDER_INCLUDES = [
  'area',
  'driver',
  'courier',
  'contact',
  'restaurant',
  'client',
  'restaurant-location',
  'client-location',
  'arrival-estimate'
].join(',');

export const LOGS_ORDER_FIELDS = {
  clients: 'name,actual-segment,very-late-score',
  restaurants: 'name',
  areas: 'city',
  couriers: 'name',
  users: 'first-name,last-name,email',
  orders: [
    LOGS_ORDER_INCLUDES,
    'alert-status',
    'allows-pay-out-of-pocket',
    'identifier',
    'deliver-at',
    'delivered-at',
    'deliver-on',
    'delivery-estimate',
    'flag',
    'is-foodhall',
    'is-meal-plan-order',
    'is-asap',
    'is-foodee-served',
    'is-client-demo',
    'is-restaurant-demo',
    'last-minute',
    'logistics-type',
    'order-type',
    'number-of-people',
    'pickup-at',
    'picked-up-at',
    'state',
    'synced-with-third-party-logistics-at',
    'third-party-logistics-exception',
    'third-party-logistics-drop-off-id',
    'third-party-logistics-tracking-url',
    'total-amount',
    'order-images',
    'client-total',
    'restaurant-total',
    'driver-tip',
    'ghost-tip'
  ].join(','),
  'logistics-arrival-estimates': [
    'pickup-estimate',
    'pickup-estimates',
    'delivery-estimate',
    'delivery-estimates'
  ].join(',')
};

let areas;
let couriers;

@classic
export default class IndexRoute extends Route.extend(AuthenticatedRouteMixin, FilterRouteMixin) {
  queryParams = {
    areas: { refreshModel: true },
    couriers: { refreshModel: true },
    deliverOn: { refreshModel: true },
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    expanded: { refreshModel: false },
    hide: { refreshModel: false }
  };

  /**
   * Formats the url params to JSONAPI Resources compatible query params for the order endpoint.
   * @params {object} params Parameters
   * @returns {object} A JSONAPI Resources compatible query params object
   */
  formatQueryParams({ deliverOn, pageLimit, pageOffset, id }) {
    const { areas, couriers } = this.filterCollection.asJR;

    const ret = {
      filter: {
        id,
        areas,
        courierName: couriers,
        deliverOn: deliverOn,
        rejectState: 'draft,cancelled'
      },

      page: {
        limit: pageLimit,
        offset: pageOffset
      },

      sort: 'deliverAt,id',

      include: LOGS_ORDER_INCLUDES,

      fields: LOGS_ORDER_FIELDS
    };

    return ret;
  }

  filters = [
    {
      key: 'areas',
      icon: 'map',
      label: 'Area',
      resourceName: 'area',
      resourceValueLabel: 'city',
      resourceValueKey: 'city'
    },
    {
      key: 'couriers',
      icon: 'car',
      label: `Courier`,
      resourceName: 'courier',
      resourceValueLabel: 'name',
      resourceValueKey: 'name'
    },
    {
      key: 'hide',
      icon: 'eye slash',
      label: `Hide`
    }
  ];

  beforeModel() {
    // Instantiates controller early
    const controller = this.controllerFor('logged-in.logistics.index');

    controller.setProperties({
      isLoading: true,
      isRemainingOrdersLoading: true
    });
  }

  model(params) {
    this.firstPageOfOrders = this.fetchFirstPageOfOrders(params);

    // We only need to make this query once
    areas =
      areas ??
      this.store.query('area', {
        filter: { active: true },
        fields: {
          areas: 'city'
        }
      });

    // We only need to make this query once
    couriers =
      couriers ??
      this.store.query('courier', {
        include: 'areas',
        page: {
          limit: 100
        },
        fields: {
          couriers: 'name,areas,api-type,active',
          areas: 'city'
        }
      });

    const modelPromise = RSVP.hash({
      areas: areas,
      couriers: couriers,
      firstPageOfOrders: this.firstPageOfOrders.then((orders) =>
        this._processExpanded(orders, params.expanded)
      )
    });

    const remainingOrdersPromise = this.fetchRemainingOrders(params);

    // Wait for all orders to be loaded to setup pusher
    RSVP.Promise.all([modelPromise, remainingOrdersPromise]).then(() =>
      this.controllerFor('logged-in.logistics.index').setupPusher()
    );

    return modelPromise;
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    const { firstPageOfOrders } = model;

    // Note: controller.areas and model.areas are different
    controller.setProperties({
      firstPageOfOrders,
      fetchedOrders: [],
      meta: firstPageOfOrders.get('meta'),
      isLoading: false
    });

    // setup a new desk-case which can be created from here.
    controller.set(
      'newDeskCase',
      this.store.createRecord('desk-case', {
        caseStatus: 'open'
      })
    );
  }

  resetController(controller, isExiting, transition) {
    super.resetController(controller, isExiting, transition);

    try {
      controller.teardownPusher();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        `${moment().format('hh:mm:ss ')} [${
          controller.pubSub.name
        }] Had some trouble tearing down pusher`
      );
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  getCurrentQueryParams() {
    return this.get('controller').getProperties(
      'areas',
      'couriers',
      'deliverOn',
      'pageLimit',
      'pageOffset'
    );
  }

  /*
   * Fetch first page of orders to help load the interface faster.
   */
  fetchFirstPageOfOrders(params) {
    params.pageOffset = 0;
    params.pageLimit = NUMBER_OF_LOADED_ORDERS;
    return this.store.query('order', this.formatQueryParams(params));
  }

  /*
   * If the remainingOrders promise beats the model promise, the controller may not have been
   * initiated (first page load), wait for the transition to complete to ensure controller's
   * instantiation and only then should you set remainingOrders on the controller.
   */
  fetchRemainingOrders(params) {
    const controller = this.controllerFor('logged-in.logistics.index');
    params.pageOffset = NUMBER_OF_LOADED_ORDERS;
    params.pageLimit = controller.get('pageLimit') - NUMBER_OF_LOADED_ORDERS;

    /*
     * Has to be cleared early, because fetch remaining is outside the normal
     * model -> setupController flow (main loader could finish before old remaining orders are
     * cleared)
     */
    controller.set('remainingOrders', []);

    this.remainingOrders = this.store.query('order', this.formatQueryParams(params));

    this.remainingOrders.then((orders) => {
      this._processExpanded(orders, params.expanded);
      controller.setProperties({
        remainingOrders: orders,
        isRemainingOrdersLoading: false
      });
    });
  }

  /**
   * @type {string} id
   * When an order is created through pusher, the order created action calls this method to fetch
   * the order with the current query filter params and append it onto the fetchedOrders array.
   */
  fetchOrderWithQueryParams(id) {
    const controller = this.get('controller');
    const params = this.getCurrentQueryParams();

    params.id = id;

    this.store.query('order', this.formatQueryParams(params)).then((orders) => {
      controller.get('fetchedOrders').pushObjects(orders.toArray());
    });
  }

  _processExpanded(orders, expanded) {
    return orders.forEach((order) => order.set('expandRow', expanded.includes(order.get('id'))));
  }

  @action
  refreshOrder(id) {
    this.fetchOrderWithQueryParams(id);
  }

  @action
  async refreshModel() {
    const controller = this.controllerFor('logged-in.logistics.index');
    controller.set('refreshingOrders', true);
    await RSVP.Promise.all([
      this.firstPageOfOrders.content.update(),
      this.remainingOrders.content.update()
    ]);

    controller.set('refreshingOrders', false);
  }
}
