import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias, oneWay } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';

@classic
export default class IndexController extends Controller {
  /**
   * Query params for controller.
   */
  queryParams = [
    'areas',
    'filterDate',
    'pageLimit',
    'pageOffset',
    'reverseSort',
    'sortBy',
    'sort',
    'search'
  ];

  @controller('application')
  applicationController;

  /** @type {number} */
  @oneWay('model.orders.meta.recordCount')
  recordCount;

  /** @type {number} records per page */
  pageLimit = 20;

  /** @type {number} current page */
  pageOffset = 0;

  /** @type {string} filtering by areas */
  areas = '';

  /** @type {string} filtering by bookmark name */
  search = '';

  /** @type {Area[]} */
  @alias('model.areas')
  allAreas;

  /** @type {boolean} denotes is the route is in a loading state */
  isLoading = false;

  /** @type {string} displaying areas when in split pane view */
  @computed('areas')
  get areaNames() {
    const areas = this.get('areas').replace(',', ', ') || 'All areas';
    return areas;
  }

  /** @type {Order} order object */
  order = null;

  /** @type {boolean} True when the current route is edit route */
  @computed('applicationController.currentRouteName')
  get narrowMode() {
    return this.get('applicationController.currentRouteName').includes('logged-in.orders.edit');
  }
}
