import Fixture, {
  element,
  elementWithValue,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class FormControlModelSelectControlFixture extends Fixture {
  constructor() {
    super('data-test-form-controls-model-select-control');
  }

  @elementWithValue item;
  @element({ selector: '[data-test-form-controls-model-select-control] input' }) searchInput;

  async search(searchedValue) {
    await this.click();
    await this.searchInput.typeIn(searchedValue);
  }

  async selectFirstValue() {
    this.searchInput.triggerKeyEvent('keydown', 'ArrowDown');
    this.searchInput.triggerKeyEvent('keydown', 'Enter');
  }
}

export const modelSelectControl = getElementDecoratorFor(FormControlModelSelectControlFixture);

export const modelSelectControlWithValue = getElementWithValueDecoratorFor(
  FormControlModelSelectControlFixture
);
