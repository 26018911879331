import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';

@classic
export default class OrdersRoute extends Route {
  @service
  orderService;

  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    sort: { refreshModel: true },
    states: { refreshModel: true },
    filterByDate: { refreshModel: true },
    deliverOn: { refreshModel: true }
  };

  model(params) {
    const client = this.modelFor('logged-in.clients.edit');

    const { pageLimit, pageOffset, states, sort, deliverOn, filterByDate } = params;

    const queryOptions = {
      filter: {
        //may filter by state or deliverOnZ
      },
      page: {
        limit: pageLimit,
        offset: pageOffset
      },
      sort: sort,
      include: 'client,contact,restaurant'
    };

    if (states !== null && states !== '') {
      queryOptions.filter.state = states;
    }

    if (filterByDate && deliverOn !== null) {
      queryOptions.filter.deliverOn = deliverOn;
    }

    return RSVP.hash({
      client: client,

      orders: client.query('orders', queryOptions)
    });
  }
}
