import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

/** similar to pagination-control, but with no search functionality. */
@classic
@classNames('fde-paginator-lite')
export default class PaginatorLite extends Component {
  /** @type {Function} updates pagination */
  onPaginationChange() {}

  /** @type {boolean} */
  isCompact = false;

  /** @type {number} */
  pageOffset = 0;

  /** @type {number} */
  pageLimit = 0;

  /** @type {string} */
  paginatorIcon = 'mail outline';

  /** @type {number} */
  recordCount = 0;

  /** @type {string} name of what you are listing for the subheader. */
  nameOfRecordType = 'records';

  /** @type {boolean} Whether or not records will be listed over more than one page. */
  @computed('recordCount', 'pageLimit')
  get hasPages() {
    const recordCount = this.get('recordCount');
    const pageLimit = this.get('pageLimit');
    return recordCount > pageLimit;
  }

  /** @type {number} Number of the last record shown on the page. */
  @computed('recordCount', 'pageOffset', 'pageLimit')
  get currentRecordsShowingEnd() {
    const recordCount = this.get('recordCount');
    const pageOffset = this.get('pageOffset');
    const pageLimit = this.get('pageLimit');

    const max = pageOffset + pageLimit;

    return Math.min(recordCount, max);
  }

  /** @type {number} The number of the first record on the current page. */
  @computed('recordCount', 'pageOffset', 'pageLimit')
  get currentRecordsShowingStart() {
    const pageOffset = this.get('pageOffset');
    return pageOffset > 0 ? pageOffset + 1 : 1;
  }

  /** @type {number} Returns current page number, minimum is one. */
  @computed('pageOffset', 'pageLimit')
  get currentPage() {
    const pageOffset = this.get('pageOffset');
    const pageLimit = this.get('pageLimit');
    const newPageNumberIsValid = pageOffset / pageLimit >= 1;

    return newPageNumberIsValid ? Math.ceil(pageOffset / pageLimit) : 1;
  }

  /** @type {number} Total number of pages. */
  @computed('recordCount', 'pageLimit')
  get totalPages() {
    const recordCount = this.get('recordCount');
    const pageLimit = this.get('pageLimit');
    const newPageNumberIsValid = recordCount / pageLimit >= 1;

    return newPageNumberIsValid ? Math.ceil(recordCount / pageLimit) : 0;
  }

  /** @type {boolean} Prev button is disabled if user is viewing the beginning of the list. */
  @equal('currentPage', 1)
  isPrevPageDisabled;

  /** @type {boolean} Next button is disabled if user is already viewing the end of the list. */
  @equal('currentRecordsShowingEnd', 'recordCount')
  isNextPageDisabled;

  @action
  handleNextPageClick() {
    const currentRecordsShowingEnd = this.get('currentRecordsShowingEnd');
    const recordCount = this.get('recordCount');

    if (currentRecordsShowingEnd < recordCount) {
      const currentOffset = this.get('pageOffset');
      const currentLimit = this.get('pageLimit');
      const newOffset = currentOffset + currentLimit;

      this.onPaginationChange(newOffset);
    }
  }

  @action
  handlePrevPageClick() {
    const pageOffset = this.get('pageOffset');
    const pageLimit = this.get('pageLimit');
    let newPageOffset;

    if (pageOffset >= pageLimit) {
      newPageOffset = pageOffset - pageLimit;
    } else {
      newPageOffset = 0;
    }

    this.onPaginationChange(newPageOffset);
  }
}
