import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { alias, equal, filter } from '@ember/object/computed';
import Component from '@ember/component';
import EmberObject, { action, computed, get } from '@ember/object';

export const ReceiverType = {
  CLIENT: 'Client',
  RESTAURANT: 'Restaurant',
  GROUP_ORDER_MEMBER: 'GroupOrderMember'
};

const STATUS = ['pending', 'delivered', 'open', 'click'];

@classic
class NotificationLogGroup extends EmberObject {
  /** @type{NotificationLog[]}*/
  logs = null;

  /** @type{boolean}*/
  isExpanded = false;

  /** @type{boolean}*/
  canExpand = true;

  /** @type{string}*/
  @computed('logs.@each.status')
  get status() {
    if (this.get('logs.length') > 0) {
      const maxStatusIndex = this.get('logs')
        .map((log) => STATUS.indexOf(log.get('status')))
        .reduce((max, i) => (max > i ? max : i), 0);
      return STATUS[maxStatusIndex];
    } else {
      return 'NONE';
    }
  }

  /** @type{boolean}*/
  @equal('status', 'pending')
  isPending;

  /** @type{boolean}*/
  @alias('logs.firstObject.reason', 'medium')
  medium;

  /** @type{string}*/
  @alias('logs.firstObject.reason')
  reason;

  /** @type{string}*/
  @alias('logs.firstObject.message')
  message;

  /** @type{Date}*/
  @alias('logs.firstObject.createdAt')
  createdAt;

  @alias('logs.firstObject.icon')
  icon;

  @alias('logs.firstObject.color')
  color;

  /** @type{string}*/
  @computed('logs.length')
  get sentTos() {
    return (get(this, 'logs') ?? []).mapBy('sentTos').join(',');
  }

  /** @type{Date}*/
  @computed('logs.length')
  get updatedAt() {
    return (get(this, 'logs') ?? []).mapBy('updatedAt').reduce((max, i) => (max > i ? max : i), 0);
  }
}

@classic
@classNames('fde-notification-logs')
export default class NotificationLogs extends Component {
  /** @type {Service} */
  @service
  ajax;

  /** @type {Service} */
  @service
  notify;

  isSending = false;
  email = null;
  emailModalId = 1;
  emailMessage = '';
  filterEmailsBy = '';

  @computed('order')
  get sendEmailUri() {
    return `api/v3/orders/${this.get('order.id')}/send-summary`;
  }

  @computed('selectedTab')
  get emailPlaceholderText() {
    return `Type your message to this order's ${this.get('selectedTab')}`;
  }

  @computed('selectedTab')
  get submitButtonText() {
    return `Send this email to the ${this.get('selectedTab')}`;
  }

  isEmailModalShowing = false;

  /** @type {boolean} */
  readonly = false;

  /** @type {boolean} */
  showClient = true;

  /** @type {boolean} */
  showRestaurant = true;

  /** @type {boolean} */
  showMember = true;

  /** @type {boolean} */
  showSummaryButton = true;

  order = null;
  isoTimeZone = null;
  notificationLogs = null;

  @filter('notificationLogs', function (log) {
    return log.get('receiverType') === ReceiverType.CLIENT;
  })
  clientLogs;

  @filter('notificationLogs', function (log) {
    return log.get('receiverType') === ReceiverType.RESTAURANT;
  })
  restaurantLogs;

  @filter('notificationLogs', function (log) {
    return log.get('receiverType') === ReceiverType.GROUP_ORDER_MEMBER;
  })
  groupOrderMemberLogs;

  selectedTab = 'client';

  @computed(
    'selectedTab',
    'filterEmailsBy',
    'clientLogs.[]',
    'restaurantLogs.[]',
    'groupOrderMemberLogs.[]'
  )
  get selectedLogs() {
    const logs = this.get(`${this.get('selectedTab')}Logs`);

    return logs
      .filter((log) => {
        const sentTos = log.get('sentTos').toString();
        return (
          sentTos.includes(this.get('filterEmailsBy')) ||
          log.get('reason').includes(this.get('filterEmailsBy'))
        );
      })
      .sort((logA, logB) => logB.get('createdAt') - logA.get('createdAt'));
  }

  @computed('selectedLogs.[]')
  get groupedLogs() {
    const groupedLogs = (this.get('selectedLogs') || []).reduce((acc, log) => {
      const key = log.get('provenanceId');
      acc[key] = acc[key] || [];
      acc[key].push(log);

      return acc;
    }, {});

    const nullProvenanceIdLogs = groupedLogs[null];
    delete groupedLogs[null];

    let ret = Object.values(groupedLogs).map((logs) =>
      logs.length === 1 ? logs[0] : NotificationLogGroup.create({ logs })
    );

    if (nullProvenanceIdLogs) {
      ret = ret
        .concat(nullProvenanceIdLogs)
        .sortBy('createdAt')
        .sort((logA, logB) => logB.get('createdAt') - logA.get('createdAt'));
    }

    return ret;
  }

  @action
  sendEmail() {
    const notify = this.get('notify');
    const message = this.get('emailMessage');
    const uri = this.get('sendEmailUri');

    const params = {
      type: 'email-messages',
      attributes: {
        message,
        'message-type': this.get('selectedTab')
      }
    };

    const post = this.get('ajax').postJSONAPI(uri, params);

    this.set('isSending', true);

    return post
      .then(() => {
        this.set('emailMessage', '');
        return notify.success('Message sent!');
      })
      .catch((error) => notify.error(`Mail wasn't sent! ${error}`))
      .finally(() => this.set('isSending', false));
  }

  @action
  removeFromBounceList(email) {
    this.get('order')
      .removeFromBounceList({ email })
      .then((_result) => {
        return this.get('notify').success(
          `Email Successfully removed user from the SendGrid bounce list: ${email}`
        );
      })
      .catch((error) => {
        // This is a little awkward, but due to the limitations of JSON:API Resources
        // any errors thrown in the above command will be wrapped in a 422 response,
        // so here we want to peek into the error messages to get the "real" message.
        const errors = error?.errors;

        if (errors.length) {
          const realError = errors.find((each) => each.status === '422');

          return this.get('notify').error(realError.detail);
        }

        return this.get('notify').error('An unexpected error has occurred.');
      });
  }
}
