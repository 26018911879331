import Mixin from '@ember/object/mixin';
import { run } from '@ember/runloop';

import FilterCollection from 'star-fox/supports/filters/filter-collection';
import Filter from 'star-fox/supports/filters/filter';
import { action } from '@ember/object';

/**
 * Mixin that facilitates the creation and management of FilterCollections on routes.
 */
export default Mixin.create({
  /** @type {FilterCollection} */
  filterCollection: null,

  /** @type {Object[]} Array of filter definitions that will be used in the construction of filter objects */
  filters: null,

  /** @override */
  init() {
    this._super(...arguments);
    const filters = this.get('filters');

    // Only way to be able to get the route name, this.get('routeName') isn't set on init.
    const routeName = this.toString().match(new RegExp('.*@route:(.*)::.*'))[1];

    this.set(
      'filterCollection',
      FilterCollection.create({
        storageKey: `ee.food.concierge:${routeName}-filter-collection`,
        filters: filters.map((_) => Filter.create(_)),
        onChange: (_) => this.setQueryParamsFromFilterCollection(),
        defaultJRFilters: this.get('defaultJRFilters')
      })
    );
  },

  /** @override */
  model(params) {
    this.get('filterCollection').restoreFilters(params);
    return this._super(...arguments);
  },

  /** @override */
  setupController(controller) {
    controller.set('filterCollection', this.get('filterCollection'));
    this._super(...arguments);
  },

  /** @override */
  resetController(controller, isExiting) {
    if (isExiting) {
      this.set('filterCollection.isRestored', false);
      controller.set('pageOffset', 0);

      const queryParams = this.get('filterCollection').get('asQueryParams');
      const resetQueryParams = Object.keys(queryParams).reduce((obj, key) => {
        obj[key] = undefined;
        return obj;
      }, {});

      controller.setProperties(resetQueryParams);
    }
  },

  /** @override */
  activate() {
    this._super(...arguments);

    // This ensures that when the FilterCollection has restored from storage that the query params are set in the url
    run.next((_) => {
      const controller = this.controllerFor(this.get('routeName'));
      controller.setProperties(this.get('filterCollection').get('asQueryParams'));
    });
  },

  /** Sets the query params onto the controller */
  setQueryParamsFromFilterCollection: action(function () {
    const controller = this.controllerFor(this.get('routeName'));
    controller.setProperties(this.get('filterCollection').get('asQueryParams'));
    controller.set('pageOffset', 0);
  })
});
