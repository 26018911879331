import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action } from '@ember/object';

@classic
@classNames('fde-forms-meal-planning-form-for-planner-settings-form-for')
export default class SolverSettingsFormFor extends Component {
  /** @type {MealPlanningInstance|MealPlanningTemplate} */
  mealPlan = null;

  @action
  handleReorder(values) {
    this.mealPlan.objectives.values = values;
    return this.savePlan();
  }

  @action
  savePlan() {
    return this.mealPlan.save();
  }
}
