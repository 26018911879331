import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

import { ActionType } from '../transforms/action-type';

@classic
export default class RulesAction extends Model {
  /*
   * Attributes
   */
  @attr('action-type', { defaultValue: ActionType.SetAlertStatus.value })
  actionType;

  @attr('object')
  configuration;

  @attr('string', { defaultValue: 'positive' })
  outcome;

  /*
   * Relationships
   */
  @belongsTo('rules-rule', { inverse: 'actions' })
  rule;
}
