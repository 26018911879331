import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class TopCuisinesRoute extends Route {
  async model(_params) {
    const allTopCuisines = await this.store.query('meal-planning-requirement', {
      filter: {
        topCuisine: true
      },
      page: {
        limit: 250
      }
    });
    await allTopCuisines?.areas;

    const area = await this.modelFor('logged-in.areas.edit');

    const topCuisines = area?.mealPlanningRequirements;

    const topCuisineIds = topCuisines?.map((cuisine) => cuisine.id) || [];
    const availableCuisines = allTopCuisines.filter(
      (cuisine) => topCuisineIds.indexOf(cuisine.id) === -1
    );

    return { area, allTopCuisines, availableCuisines };
  }
}
