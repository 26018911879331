import classic from 'ember-classic-decorator';
import Service, { inject as service } from '@ember/service';
import RSVP from 'rsvp';

/**
 * @class
 * @classdesc Wrapper for Google's places  / geocoder api
 * @extends {Service}
 * */
@classic
class LocationService extends Service {
  /** @type {AjaxService} */
  @service
  ajax;

  /**
   *
   * @typedef {object} PlaceSuggestion
   * @property {string} country
   * @property {string} description - 'rich' text highlighted results
   * @property {string} placeId - google place id
   *
   * Wrapper around the google places auto complete api that is proxied through Master Fox
   *
   * @param searchText
   * @returns {Promise.<PlaceSuggestion>}
   */
  suggestPlaces(searchText = '', city = '') {
    return this.get('ajax')
      .request(`/api/v3/locations/google?input=${searchText}&city=${city}`, {
        headers: {
          accept: 'application/vnd.api+json'
        }
      })
      .then((result) =>
        result.predictions.map(
          // we don't need all of the results, just the descritions
          (_) => ({
            country: _.description.includes('Canada') ? 'ca' : 'us',
            description: _.description.replace(
              new RegExp(searchText, 'i'),
              `<strong><em>${searchText}</em></strong>`
            ),
            placeId: _.place_id
          })
        )
      );
  }

  /**
   * Adapter around the google geocoder, takes in a place id and returns a Promise for a hash
   * of a FoodeeLocation.
   *
   * @param placeId
   * @returns {Promise.<Location>}
   */
  lookupAddressByPlaceId(placeId) {
    return new RSVP.Promise((resolve, reject) => {
      new google.maps.Geocoder().geocode({ placeId: placeId }, (results, status) => {
        if (status == 'OK' && results.length > 0) {
          resolve(this.mapGoogleAddressToFoodeeLocation(results[0]));
        } else {
          reject(`Could not lookup an address for placeId: ${placeId}`);
        }
      });
    });
  }

  /**
   * @typedef {object} Location
   * @property {string} building number (120 of 120 Columbia Street)
   * @property {string} street (Columbia of 123 Columbia Street)
   * @property {string} city
   * @property {string} province
   * @property {string} country
   * @property {string} addressCode (zip or postal code)
   * @property {number} latitude
   * @property {number} longitude
   *
   * Bit of a cheat for testing purposes, maps between a strange google api place definition
   * and the property names we use
   *
   * @param {object} googlePlace googlePlace from the google places api
   * @returns {Location}
   */
  mapGoogleAddressToFoodeeLocation(googlePlace) {
    // reduce the 'address_components' hash into something a bit more usable
    const location = googlePlace.address_components.reduce((acc, item) => {
      const type = item.types[0];

      // the following switch maps between google's place api
      // and our location hash.
      switch (type) {
        case 'street_number':
          acc.building = item.long_name;
          break;
        case 'route':
          acc.street = item.long_name;
          break;
        case 'locality':
          acc.city = item.long_name;
          break;
        case 'administrative_area_level_1':
          acc.province = item.short_name;
          break;
        case 'country':
          acc.country = item.short_name;
          break;
        case 'postal_code':
          acc.addressCode = item.long_name;
          break;
      }

      return acc;
    }, {});

    // also store the lat and long just cause
    location.latitude = googlePlace.geometry.location.lat();
    location.longitude = googlePlace.geometry.location.lng();

    return location;
  }
}

export default LocationService;
