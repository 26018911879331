import classic from 'ember-classic-decorator';
import { oneWay } from '@ember/object/computed';
import { action } from '@ember/object';
import Controller from '@ember/controller';

@classic
export default class DietaryController extends Controller {
  // doesn't seem to dasherize this property...
  @oneWay('model.tags.meta.recordCount')
  recordCount;

  /** @property {string} searchText - text for searching the back end with */
  searchText = '';

  /** @property {number} pageLimit - records per page */
  pageLimit = 30;

  /** @property {number} pageOffset - current page */
  pageOffset = 0;

  /** @property {number} sort - sorting string */
  sort = 'name';

  queryParams = ['pageLimit', 'pageOffset', 'searchText', 'sort'];

  @action
  onSearchChange(searchText) {
    // reset page offset on search text change due to model
    // changes
    this.setProperties({
      pageOffset: 0,
      searchText: searchText
    });
  }
}
