import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class Menu extends Application {
  attrs = {
    restaurant: {
      serialize: true
    },
    menuGroups: {
      serialize: true
    },
    order: {
      serialize: false
    }
  };
}
