import classic from 'ember-classic-decorator';
import EmberObject, { computed } from '@ember/object';
import { isArray } from '@ember/array';
import { isEmpty, typeOf } from '@ember/utils';
import Filter from './filter';

@classic
export default class FilterCollection extends EmberObject {
  /** @type {string} */
  storageKey = null;

  /**
   * List of filters that are applied
   * @type {Filter[]}
   */
  filters = null;

  /** @type {Filter[]} */
  selectedFilters = null;

  /**
   * Denotes if the list of filters should be treated as a static list, which can be toggled, or a dynamic list
   * which can grow or shrink. Static lists can contain multiple filters of the same key, in dynamic lists each
   * filter is expected to have a unique key.
   * @type {boolean}
   */
  isStaticFilterList = false;

  /** @type {boolean} Include an 'all' filter option */
  includeAllFilter = false;

  /** @type {boolean} Will restore from storage the filterCollection on init */
  restoreOnInit = true;

  /** @type {?String[]} A list of known keys used to map query pa*/
  queryParamKeys = null;

  /** @type {boolean} Set to true if the FilterCollection has been restored from a data source */
  isRestored = false;

  /** @type {object?} */
  defaultJRFilters = null;

  /**
   * Adds a filter. Meant for use with a non-static filter list.
   * @param {Filter} filter
   */
  addFilter(filter) {
    const prevSelectedFilters = this.get('selectedFilters').toArray();
    const newSelectedFilters = this.get('selectedFilters').toArray();
    newSelectedFilters.push(filter);

    filter.on('valueChange', this, this._handleValueChange);

    this.set('selectedFilters', newSelectedFilters);
    this._storeFilters();
    this.onChange(newSelectedFilters, prevSelectedFilters);
  }

  /**
   * Removes a filter. Meant for use with a non-static filter list.
   * @param filter
   */
  removeFilter(filter) {
    const prevSelectedFilters = this.get('selectedFilters').toArray();
    const newSelectedFilters = this.get('selectedFilters').toArray();
    newSelectedFilters.removeObject(filter);

    filter.off('valueChange', this, this._handleValueChange);

    this.set('selectedFilters', newSelectedFilters);
    this._storeFilters();
    this.onChange(newSelectedFilters, prevSelectedFilters);
  }

  /**
   * Returns true if a filter with a certain key is found.
   * @param key
   * @returns {boolean}
   */
  hasFilterForKey(key) {
    return !!this.get('filters').findBy('key', key);
  }

  /**
   * Returns true if a selected filter with a certain key is found.
   * @param key
   * @returns {boolean}
   */
  hasSelectedFilterForKey(key) {
    return !!this.get('selectedFilters').findBy('key', key);
  }

  /** @type {boolean} */
  @computed('selectedFilters.[]')
  get hasActiveFilter() {
    const selectedFilters = this.get('selectedFilters') || [];
    return this.get('includeAllFilter')
      ? !selectedFilters.includes(this.get('filters.firstObject'))
      : !!selectedFilters.length;
  }

  /** @type {String} */
  @computed('selectedFilters.[]')
  get filterText() {
    return 'Filter: ' + (this.get('selectedFilters') || []).map((_) => _.label).join(', ');
  }

  /** @override */
  init() {
    super.init(...arguments);
    this._setupFilters();
  }

  /**
   * Sets up the intital filters
   * @private
   */
  _setupFilters() {
    const filters = this.get('filters') || [];
    const selectedFilters = this.get('selectedFilters') || [];

    if (this.get('includeAllFilter')) {
      const allFilter = Filter.create({
        label: 'All',
        icon: 'bullhorn',
        single: true,
        key: 'all'
      });

      filters.unshift(allFilter);

      // if there are no selected filters, select the inert 'All' filter
      !isEmpty(selectedFilters) && this.set('selectedFilters', [allFilter]);
    }

    this.setProperties({
      filters,
      selectedFilters
    });

    this.get('restoreOnInit') && this._restoreFromStorage();

    this._bindAllSelectedFilters();
  }

  /**
   * Binds all the filters' valueChange events to the onFilterValueChange event for the FilterCollection
   * @private
   */
  _bindAllSelectedFilters() {
    this.get('selectedFilters').forEach((_) => _.on('valueChange', this, this._handleValueChange));
  }

  /**
   * Binds all the filters' valueChange events
   * @private
   */
  _unbindAllSelectedFilters() {
    this.get('selectedFilters').forEach((_) => _.off('valueChange', this, this._handleValueChange));
  }

  /**
   * Event handler relay for the valueChange event of a filter. Relays the valueChange event to the onFilterValueChange
   * event.
   * @param {*} value
   * @param {*} prevValue
   * @param {Filter} filter
   * @private
   */
  _handleValueChange(value, prevValue, filter) {
    this.onFilterValueChange(value, prevValue, filter);
  }

  /**
   * Sets up selected filters from query params.
   * @param {object} queryParams
   * @private
   */
  _setupSelectedFiltersFromQueryParams(queryParams) {
    const newSelectedFilters = this.get('isStaticFilterList')
      ? this._getMatchingFiltersFromQueryParams(queryParams)
      : this._createFiltersFromQueryParams(queryParams);

    this.set('selectedFilters', this._orderFiltersBySelectedFilters(newSelectedFilters));
  }

  /**
   * Returns a set of filters where the queryParam's keys match the filters' key.
   * @param {object} queryParams
   * @returns {*}
   * @private
   */
  _getMatchingFiltersFromQueryParams(queryParams) {
    const filters = this.get('filters');
    const queryParamKeys = Object.keys(queryParams);

    return queryParamKeys.reduce((acc, key) => {
      const filtersForKey = filters.filter((_) => _.key === key);
      const valueArray = isArray(queryParams[key]) ? queryParams[key] : [queryParams[key]];

      acc.concat(
        filtersForKey.filter((filter) => filter.value.filter((v) => valueArray.includes(v)).length)
      );
      return acc;
    }, []);
  }

  /**
   * Creates a set of filters from a query params object.
   * @param {object} queryParams
   * @private
   */
  _createFiltersFromQueryParams(queryParams) {
    const queryParamsKeys = Object.keys(queryParams);
    const filtersForQueryParams = this.get('filters').filter((_) =>
      queryParamsKeys.includes(_.get('key'))
    );

    return filtersForQueryParams
      .filter((_) => !!queryParams[_.get('key')])
      .map((_) =>
        this.createFilter({
          id: _.get('key'),
          key: _.get('key'),
          value: this._createValueArray(queryParams[_.get('key')])
        })
      );
  }

  /**
   * Orders the set of filters passed in, by the current set of selected filters. Handy for maintaining the order of
   * filters as a new set from queryParams is being ingested into the collection for a refresh.
   * @param {Filter} filters
   * @private
   */
  _orderFiltersBySelectedFilters(filters) {
    const selectedFilters = this.get('selectedFilters') || [];

    filters.sort((a, b) => {
      const similarFilterA = selectedFilters.findBy('key', a.get('key'));
      const similarFilterB = selectedFilters.findBy('key', b.get('key'));

      const aIndex = selectedFilters.indexOf(similarFilterA);
      const bIndex = selectedFilters.indexOf(similarFilterB);

      return aIndex - bIndex;
    });

    return filters;
  }

  /**
   * Returns true if any of the filters apply to this object
   * @param {*} object
   * @return {boolean}
   */
  filterObject(object) {
    return this.get('selectedFilters').any((_) => _.filter(object));
  }

  /**
   * Filters the list of objects by the selected filters
   * @param {*} object
   * @return {boolean}
   */
  filterObjects(objects) {
    return objects.filter((_) => this.filterObject(_));
  }

  /**
   * Callback when the selected filters have changed
   */
  onChange() {}

  /**
   * Callback when the selected filters' values' have changed
   */
  onFilterValueChange() {}

  /**
   * Toggles a filter in the selected filters
   * @param {object} filter
   * @returns {boolean}
   */
  toggleFilter(filter) {
    const prevSelectedFilters = this.get('selectedFilters').toArray();
    const selectedFilters = this.get('selectedFilters');

    if (selectedFilters.includes(filter)) {
      // can't toggle singles off
      if (!filter.single) {
        selectedFilters.removeObject(filter);

        if (selectedFilters.get('length') === 0) {
          selectedFilters.pushObject(this.get('filters.firstObject'));
        }
      }
    } else {
      if (!filter.single) {
        // add out filter
        selectedFilters.pushObject(filter);

        // remove any single filters
        this.set(
          'selectedFilters',
          selectedFilters.filter((_) => !_.single)
        );
      } else {
        this.set('selectedFilters', [filter]);
      }
    }

    this._storeFilters();
    this.onChange(this.get('selectedFilters'), prevSelectedFilters);
  }

  /**
   * Collapses this collection of filters into one JR representation
   */
  @computed('selectedFilters.@each.value', 'filters.@each.value', 'defaultJRFilters')
  get asJR() {
    const defaultJRFilters = Object.assign({}, this.get('defaultJRFilters'));
    return this.get('selectedFilters').reduce(
      (acc, filter) => filter.toJR(acc),
      defaultJRFilters || {}
    );
  }

  /**
   * Collapses this collection of filters into one query param object representation
   */
  @computed('selectedFilters.@each.value', 'filters.@each.value', 'filters.[]')
  get asQueryParams() {
    const filters = this.get('filters') || [];
    const baseQueryParamObject = filters.reduce((acc, filter) => {
      acc[filter.get('key')] = undefined;
      return acc;
    }, {});

    const selectedFilters = this.get('selectedFilters');

    const setQueryParams = selectedFilters.reduce((acc, filter) => filter.toQueryParam(acc), {});
    return Object.assign(baseQueryParamObject, setQueryParams);
  }

  /**
   * Restores filter configuration from stored params
   * @param {string} serializedString
   * @private
   */
  _restoreFromStorage(
    serializedString = this.get('storageKey') && localStorage.getItem(this.get('storageKey'))
  ) {
    if (!serializedString) {
      this.get('includeAllFilter') &&
        isEmpty(this.get('selectedFilters')) &&
        this.set('selectedFilters', [this.get('filters.firstObject')]);
      return false;
    }

    const filterObjectsToRestore = JSON.parse(serializedString);
    const storedFilterIds = filterObjectsToRestore.map((_) => _.id);

    const matchingFiltersFromStoredIds = this.get('filters').filter((_) =>
      storedFilterIds.includes(_.id)
    );

    this.set(
      'selectedFilters',
      this.get('isStaticFilterList')
        ? matchingFiltersFromStoredIds
        : filterObjectsToRestore.map((_) => this.createFilter(_))
    );
  }

  /**
   * Without arguments this returns the serialized representation of all the filters, optionally stores the filters
   * in localStorage if a storage key is set on the FilterCollection object. If asQueryParams argument is set to true,
   * it will return a query params objects instead and skip local storage.
   *
   * @param {boolean} asQueryParams
   * @returns {string|object}
   * @private
   */
  _storeFilters(asQueryParams) {
    if (asQueryParams) {
      return this.get('asQueryParams');
    }

    const storageKey = this.get('storageKey');
    const filterObjectsWithIds = this.get('selectedFilters').map((_) => {
      // Essentials
      const obj = {
        id: _.id || _.key,
        key: _.key,
        value: _.value
      };

      // Optionals
      _.label && (obj.label = _.label);
      _.valueText && (obj.valueText = _.valueText);
      _.single && (obj.single = _.single);
      return obj;
    });
    const serializedFilters = JSON.stringify(filterObjectsWithIds);

    if (storageKey) {
      localStorage.setItem(storageKey, serializedFilters);
    }

    return serializedFilters;
  }

  /**
   * Without arguments this returns the serialized representation of all the filters, optionally stores the filters
   * in localStorage if a storage key is set on the FilterCollection object. If asQueryParams argument is set to true,
   * it will return a query params objects instead and skip local storage.
   *
   * @param {boolean} asQueryParams
   * @returns {string|object}
   */
  storeFilters(asQueryParams) {
    return this._storeFilters(asQueryParams);
  }

  /**
   * Restores filters from
   * @param queryParams
   */
  restoreFilters(data) {
    switch (typeOf(data)) {
      case 'string':
        this.restoreFromStorage(data);
        break;
      case 'object':
        // If the object has no relevant keys with values use storage instead.
        if (Object.keys(data).any((_) => this.hasFilterForKey(_) && data[_])) {
          this.restoreFromQueryParams(data);
        } else {
          this.restoreFromStorage();
        }
        break;
      default:
        this.restoreFromStorage();
    }

    this.set('isRestored', true);
  }

  /**
   * Restores all the filters objects from a serialized string representation of all the filters. If no string is
   * provided and a storage key is set on the FilterCollection, it will use a serialized string found in local storage
   * if one is present.
   * @param {?string} serializedFilters
   */
  restoreFromStorage(serializedFilters) {
    this._restoreFromStorage(serializedFilters);

    this.set('isRestored', true);
  }

  /** If a storageKey is set, clears the filters from localStorage */
  clearStore() {
    const storageKey = this.get('storageKey');
    storageKey && localStorage.removeItem(storageKey);
  }

  /**
   * Sets up the state of filter collections from a queryParams object from an Ember route transition.
   * @param {object} queryParams
   */
  restoreFromQueryParams(queryParams) {
    this._setupSelectedFiltersFromQueryParams(queryParams);
    this._bindAllSelectedFilters();

    this.set('isRestored', true);
  }

  /**
   * Normalizes all value possibilities to a value array.
   * @param {*} value
   * @returns {Array.<String|Number|boolean|null>}
   * @private
   */
  _createValueArray(value) {
    switch (typeOf(value)) {
      case 'array':
        return value;
      case 'string':
        return value.split(';');
      default:
        return [value];
    }
  }

  /**
   * Creates a filter using filterObject as it's source for properties and coalesces them with properties found in the
   * `filters` array with a matching key.
   * @param {object} filterObj
   * @returns {Filter}
   * @private
   */
  createFilter(filterObj) {
    const filterDefinition = this.get('filters').findBy('key', filterObj.key);
    const filterDecorator = filterDefinition ? filterDefinition.toPOJO() : {};

    Object.keys(filterObj).forEach((_) => delete filterDecorator[_]);
    const filterProps = Object.assign({}, filterObj, filterDecorator);

    return Filter.create(filterProps);
  }

  /** @override */
  willDestroy() {
    this._unbindAllSelectedFilters();
    super.willDestroy(...arguments);
  }
}
