import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Controller from '@ember/controller';

@classic
export default class FeedController extends Controller {
  @computed('model')
  get versionChannels() {
    return [`ee.food.areas.${this.get('model.id')}`];
  }
}
