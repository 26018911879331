import { isPresent } from '@ember/utils';
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  validations: {
    expiryMonth: { presence: { message: `Expiry month can't be blank` } },
    expiryYear: { presence: { message: `Expiry year can't be blank` } },

    nameOnCard: {
      presence: { message: `Name on card can't be blank'` },

      custom: [
        {
          validation(_key, value) {
            const names = (value && value.trim().split(' ')) || [];

            return names.length > 1 && names.every((name) => isPresent(name));
          },

          message() {
            return 'Your full name is required as it appears on the credit card';
          }
        }
      ]
    },

    cvc: {
      presence: { message: `CVC can't be blank` },
      numericality: true,
      length: [3, 6]
    },

    number: {
      presence: { message: `Card number can't be blank` }
    },

    addressLine_1: {
      presence: { message: `Please provide address associated to the card` }
    },

    city: {
      presence: { message: `Please provide city of the address associated to the card` }
    },

    province: {
      presence: {
        message: `Please provide the state/province of the address associated to the card`
      }
    },

    addressCode: {
      length: [5, 6],
      presence: {
        message: `Please provide the zip/postal code of the address associated to the card`
      }
    },

    country: {
      presence: { message: `Please provide the country of the address associated to the card` }
    }
  }
});
