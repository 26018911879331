import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import EmberObject from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import config from 'star-fox/config/environment';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';

@classic
export default class LoginRoute extends Route.extend(UnauthenticatedRouteMixin) {
  routeIfAlreadyAuthenticated = 'logged-in';

  @service
  session;

  beforeModel() {
    if (this.get('session.isAuthenticated')) {
      this.transitionTo('logged-in');
    }
  }

  model() {
    return RSVP.hash({
      login: EmberObject.create({
        /** @type {string} email*/
        email: '',

        /** @type {string} password */
        password: '',

        /**
         * Prevents form-for from trying to submit when onEnter is triggered
         * and a password hasn't been provided yet
         * @returns {boolean}
         */
        validate() {
          return !!this.get('password') && !!this.get('email');
        }
      }),
      mealPlan: this.store.query('meal-plan', {
        filter: {
          email: config.ssoUser
        }
      })
    });
  }
}
