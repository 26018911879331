import { guidFor } from '@ember/object/internals';
import { get } from '@ember/object';
import MF from 'ember-data-model-fragments';
import config from 'star-fox/config/environment';

/**
 * Creates a unique identifier using one instances keys (schema) against a target's values.
 * @param {Object} schema
 * @param {Object} target
 * @returns {String}
 */
function identifyFromSchema(schema, target) {
  if (config.environment === 'test') {
    return;
  }

  return guidFor(
    schema
      .identityKeys()
      .map((k) => [k, get(target, k)])
      .filter(([_k, v]) => v !== null && v !== undefined)
      .map((k, v) => `${k}${v}`)
      .join('')
  );
}

function initialize() {
  MF.Fragment.reopen({
    get identifier() {
      return identifyFromSchema(this, this);
    },

    /** Produce an composite set of keys to represent fragment identity */
    identityKeys() {
      return Object.keys(this.toJSON());
    },

    /**
     * Allows value object to compare itself to another a model composed of similar attributes
     * @param {Model} model
     * @returns {boolean}
     */
    isEqualToModel(model) {
      return this.get('identifier') === identifyFromSchema(this, model);
    },

    /**
     * Allows value objects to compare itself to another value object or a model composed of similar attributes
     * @param {Model|Fragment} toCompare
     * @returns {boolean}
     */
    isEqual(toCompare) {
      if (toCompare instanceof MF.Fragment) {
        return this.get('identifier') === toCompare.get('identifier');
      } else {
        return this.isEqualToModel(toCompare);
      }
    }
  });
}

export default {
  name: 'fragment-equals-model',
  initialize
};
