import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import { computed, get } from '@ember/object';
import moment from 'moment-timezone';
import { modelAction } from 'ember-custom-actions';
import { attr, belongsTo, hasMany } from 'renard/models/foodee';
import Owner from './owner';
import { fragment } from 'ember-data-model-fragments/attributes';

@classic
export default class Area extends Owner {
  /*
   * Attributes
   */
  @attr('boolean')
  active;

  @attr('string')
  city;

  @attr('string')
  country;

  @attr('string')
  currency;

  @attr('number')
  deliveryLeadTime;

  @attr('number')
  parkAndLoadTime;

  @attr('number')
  deliveryFee;

  @attr('number')
  adminFee;

  @attr('string')
  district;

  @attr('string')
  isoTimeZone;

  @attr('string')
  prefix;

  @attr('string')
  province;

  @attr('string')
  slug;

  @attr('string')
  coverImageUrl;

  @attr('object')
  deliveryZone;

  @attr('boolean')
  isInternal;

  @attr('number')
  localDeliveryRadius;

  @attr('number')
  deliveryRadius;

  @attr('number')
  extendedDeliveryRadius;

  @attr('object')
  features;

  @attr('object')
  center;

  @fragment('fragments/accounting/charge')
  retailDeliveryFee;

  /*
   * Relationships
   */
  @hasMany('meal-planning-instance')
  mealPlanningInstance;

  @hasMany('meal-planning-requirement', { inverse: 'areas' })
  mealPlanningRequirements;

  @hasMany('courier')
  couriers;

  @hasMany('area-closure')
  areaClosures;

  @hasMany('restaurant')
  restaurants;

  @hasMany('client', { inverse: 'areas' })
  clients;

  @hasMany('order', { inverse: 'area' })
  orders;

  @hasMany('invoicing-tax-rate')
  invoicingTaxRates;

  @belongsTo('invoicing-tax-rate')
  deliveryFeeTaxRate;

  @hasMany('historian-version')
  versions;

  @hasMany('historian-version')
  allAreaVersions;

  // TODO: fix the orders/area relationship
  // through proper JSONAPI Resources relationships
  // orders: hasMany('order')

  /*
   * Computed Properties
   */
  @alias('city')
  label;

  get isCanada() {
    return this.country === 'CA';
  }

  get isUsa() {
    return this.country === 'US';
  }

  /** @type {?InvoicingTaxRate} */
  @computed('invoicingTaxRates.[]')
  get taxRate() {
    const invoicingTaxRates = this.get('invoicingTaxRates');
    return invoicingTaxRates ? invoicingTaxRates.findBy('isDefault', true) : null;
  }

  /**
   * This property is the Bounding Box of the geojson area. Useful for running some map views.
   *
   * @type {google.maps.LatLngBounds}
   * */
  @computed('geoJson')
  get latLngBound() {
    return (
      get(this, 'geoJson.features.firstObject.geometry.coordinates.firstObject') ?? []
    ).reduce((bounds, [lng, lat]) => {
      // geojson stores in lng lat for some insane reason
      bounds.extend(new google.maps.LatLng({ lat, lng }));

      return bounds;
    }, new google.maps.LatLngBounds());
  }

  /*
   * Functions
   */

  /**
   * Returns boolean if the dateTime param provided is outside of the area closures. If no date time
   * is provided, the date that will be compared against will be today's.
   * @param {(Date|string)=} dateTime
   * @returns {boolean}
   */
  isOutsideAreaClosures(dateTime) {
    dateTime = moment(dateTime);

    const areaClosures = this.get('areaClosures.content')
      ? this.get('areaClosures.content').toArray()
      : [];

    return areaClosures.reduce((prevValue, areaClosure) => {
      if (prevValue === false) {
        return false;
      }
      const isoTimeZone = this.get('isoTimeZone');

      const startBlock = moment.tz(areaClosure.get('startBlock'), isoTimeZone);
      const endBlock = moment.tz(areaClosure.get('endBlock'), isoTimeZone);

      return dateTime.isBefore(startBlock) || dateTime.isSameOrAfter(endBlock);
    }, true);
  }

  plan = modelAction('plan', { pushToStore: true, method: 'POST' });
  planReservations = modelAction('plan-reservations', { pushToStore: true, method: 'POST' });
  sendWeeklyReservationsEmails = modelAction('send-weekly-reservations-emails', { method: 'POST' });
  sendWeeklyReservationsEmailToRestaurant = modelAction(
    'send-weekly-reservations-email-to-restaurant',
    { method: 'POST' }
  );

  /*
   * Validations
   */
  validations = {
    city: {
      presence: {}
    },
    prefix: {
      presence: {}
    },
    deliveryLeadTime: {
      numericality: {
        greaterThan: 0
      }
    },
    deliveryFee: {
      numericality: {
        greaterThan: 0
      }
    }
  };
}
