import { computed, get, set } from '@ember/object';
import moment from 'moment-timezone';
import { section } from './array';

/**
 * A namespace of computed property macros to DRY up controllers and components
 */
export default {
  /**
   * Returns a computed property that aliases a string representation of a date
   * to a date representation of a date
   *
   * @param {String} path
   * @param {String}theFormat
   * @return {ComputedProperty.<String>}
   */
  date(path, theFormat = 'YYYY-MM-DD') {
    return computed(path, {
      get(_key) {
        return moment(get(this, path)).format(theFormat);
      },
      set(key, value) {
        set(this, path, moment(value).toDate());

        return value;
      }
    });
  },

  /**
   * Creates a computed property that is the result of filtering any values in
   * path whose value matches the optionally passes value
   *
   * @param {string} path
   * @param {string} property
   * @param {*} [value}
   * @return {ComputedProperty.<Array>}
   */
  filterBy(path, property, value = undefined) {
    return computed(path, `${path}.[]`, function () {
      return (get(this, path) ?? []).filterBy(property, value);
    });
  },

  /**
   * Creates a computed property that is the result of rejecting any values in
   * path whose value matches the optionally passes value
   *
   * @param {string} path
   * @param {string} property
   * @param {*} [value}
   * @return {ComputedProperty.<Array>}
   */
  rejectBy(path, property, value = undefined) {
    return computed(path, `${path}.[]`, function () {
      return (get(this, path) ?? []).rejectBy(property, value);
    });
  },

  /**
   * Returns a computed property that takes the array value at PATH and returns an array of
   * objects which contain all of the entries in the PATH array with the same value
   *
   * @param {string} path - path to an array to be grouped
   * @param {string} valuePath - path to watch for the group by value
   * @return {{title: String, values: Array}[]}
   */
  arrayGroupedBy(path, valuePath) {
    return computed(path, valuePath, {
      get(_key) {
        const values = get(this, path);
        const groupByValuePath = get(this, valuePath);

        if (groupByValuePath) {
          return section(values, groupByValuePath);
        } else {
          return [{ title: 'All', values: values }];
        }
      },
      set(_key, value) {
        return value;
      }
    });
  }
};
