import classic from 'ember-classic-decorator';
import { layout as templateLayout } from '@ember-decorators/component';
import Component from '@ember/component';
import { action } from '@ember/object';
import layout from './template';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { OrderState } from 'renard/transforms/order-state';
import { GroupOrderMemberState } from 'renard/transforms/group-order-member-state';
import { ClientState } from 'renard/transforms/client-state';
import { AlertStatus } from 'renard/transforms/alert-status';
import { CutleryPreference } from 'renard/transforms/cutlery-preference';
import { BudgetType } from 'renard/transforms/budget-type';
import { Country } from 'renard/transforms/country';

@classic
@templateLayout(layout)
export default class AbstractInequalityConfigurator extends Component {
  symbol = '';
  symbolText = '';
  form = null;
  fact = null;
  leftFieldType = 'Path';
  rightFieldType = 'Literals';
  fieldTypes = ['Literals', 'Path'];
  @tracked leftType;
  @tracked rightType;

  @service
  store;

  valuesMap = {
    'order-state': OrderState.values,
    'group-order-member-state': GroupOrderMemberState.values,
    'client-state': ClientState.values,
    'alert-status': AlertStatus.values,
    'cutlery-preference': CutleryPreference.values,
    'budget-type': BudgetType.values,
    country: Country.values,
    boolean: [
      {
        id: 'true',
        label: 'True',
        icon: 'green check'
      },
      {
        id: 'false',
        label: 'False',
        icon: 'red times'
      }
    ]
  };

  inputTypeMap = {
    number: 'number'
  };

  get inputType() {
    return this.inputTypeMap[this.leftType] ?? 'text';
  }

  get using() {
    if (this.values) {
      return 'value-select';
    } else {
      return (
        {
          date: 'date'
        }[this.leftType] ?? 'input'
      );
    }
  }

  get values() {
    return this.valuesMap[this.leftType];
  }

  get record() {
    return this.store.createRecord('order');
  }

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
    const fact = this.get('fact');

    const leftLiterals = fact.get('leftLiterals');
    const leftPath = fact.get('leftPath');
    const rightLiterals = fact.get('rightLiterals');
    const rightPath = fact.get('rightPath');

    if (!leftLiterals && leftPath) {
      this.set('leftFieldType', 'Path');
    }

    if (!rightLiterals && rightPath) {
      this.set('rightFieldType', 'Path');
    }
  }

  @action
  toggleFieldLabel(field) {
    const fact = this.get('fact');

    const currentFieldType = this.get(`${field}FieldType`);
    const currentValue = fact.get(`${field}${currentFieldType}`);

    const toggledFieldType = currentFieldType === 'Literals' ? 'Path' : 'Literals';

    this.set(`${field}FieldType`, toggledFieldType);

    fact.setProperties({
      [`${field}${currentFieldType}`]: null,
      [`${field}${toggledFieldType}`]: currentValue
    });
  }

  @action
  handleLeftTypeChange(type) {
    this.leftType = type;

    if (
      this.values &&
      !(
        this.values.includes(this.fact.rightLiterals) ||
        this.values.mapBy('id').includes(this.fact.rightLiterals)
      )
    ) {
      this.fact.rightLiterals = this.values.firstObject.id ?? this.values.firstObject;
    } else if (type === 'date') {
      this.fact.rightLiterals = new Date().toISOString();
    } else {
      this.fact.rightLiterals = '';
    }
  }

  @action
  handleRightTypeChange(type) {
    this.rightType = type;
  }
}
