import classic from 'ember-classic-decorator';
import { oneWay } from '@ember/object/computed';
import Controller from '@ember/controller';

@classic
export default class VersionsController extends Controller {
  /** @property {number} */
  @oneWay('model.meta.recordCount')
  recordCount;

  /** @property {number} pageLimit - records per page */
  pageLimit = 20;

  /** @property {number} pageOffset - current page */
  pageOffset = 0;

  queryParams = ['pageLimit', 'pageOffset'];
}
