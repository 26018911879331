import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import Route from '@ember/routing/route';

@classic
export default class RulesRoute extends Route {
  queryParams = {
    rulesPageOffset: { refreshModel: true },
    rulesSearchText: { refreshModel: true }
  };

  /** @type {string}
   * Other routes (clients, restaurants, area, etc.) will have
   * owner types that we'll need to set on the rule on creation
   * and fetching owned rules.
   */
  get ownerType() {
    const type = this.owner && this.owner.get('constructor.modelName');

    return this.owner ? type.charAt(0).toUpperCase() + type.slice(1) : null;
  }

  /** @type {number}
   * Other routes (clients, restaurants, area, etc.) will have
   * owner types that we'll need to set on the rule on creation
   * and fetching owned rules.
   */
  get ownerId() {
    return this.owner?.id;
  }

  model({ rulesPageOffset, rulesSearchText }) {
    return this.store.query('rules-rule', {
      reload: true,
      page: {
        limit: 10,
        offset: rulesPageOffset
      },

      filter: {
        search: rulesSearchText,
        ruleOwner: [this.get('ownerType'), this.get('ownerId')]
      }
    });
  }

  setupController(controller, model) {
    controller.owner = this.owner;

    super.setupController(controller, model);
  }

  @action
  refreshRules() {
    this.refresh();
  }
}
