import Mixin from '@ember/object/mixin';

export default Mixin.create({
  validations: {
    taxRate: {
      presence: {
        message: 'Tax rate must be set.'
      }
    }
  }
});
