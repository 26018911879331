import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';

@classic
@classNames('fde-credit-card-form-for field')
export default class CreditCardFormFor extends Component {
  /** @type {Service} */
  @service
  cardService;

  /** @type {Service} */
  @service
  notify;

  /** @type {PaymentCard} */
  card = null;

  /** @type {string} */
  labelSize = '200px';

  /** @type {string} */
  labelClasses = null;

  /** @type {string[]} */
  @computed
  get countries() {
    return ['CA', 'US'];
  }

  /** @type {Function} callback sent to a wrapping model-select-or-create form */
  didSaveForSelect() {}

  /**
   * @callback handleSubmit
   * @param {string} number
   *
   * If on submit is set, this will turn form from an autosave to a parent controlled save
   * @property {handleSubmit|null}
   */
  onSubmit = null;

  /** @type {string[]} 10 years from this year */
  @computed
  get years() {
    return Array.from(new Array(10), (x, i) => moment().add(i, 'year').format('YYYY'));
  }

  /** @type {string[]} All 12 months */
  @computed
  get months() {
    return Array.from(new Array(12), (x, i) => moment().set('month', i).format('MM'));
  }

  @action
  handleDidSave() {
    const newPaymentCard = this.get('card');
    this.didSave();
    this.didSaveForSelect(newPaymentCard);
  }

  @action
  handleDidFail(response) {
    const notify = this.get('notify');

    response.errors.forEach((_) => notify.error(_.detail));
  }
}
