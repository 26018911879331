import { inject as service } from '@ember/service';
import { alias, union } from '@ember/object/computed';
import Component from '@ember/component';
import { on } from '@ember/object/evented';
import { action, computed, observer } from '@ember/object';
import ServiceAreaCheckerMixin from 'star-fox/mixins/service-area-checker-mixin';
import { CutleryPreference } from 'renard/transforms/cutlery-preference';
import { task } from 'ember-concurrency';

let fetchedDiscountCodes = null;
const tabs = {
  CLIENT_FEES: 'clientFees',
  RESTAURANT_FEES: 'restaurantFees'
};

export default Component.extend(ServiceAreaCheckerMixin, {
  classNames: 'ui segment',

  tabs: tabs,

  activeTab: tabs.CLIENT_FEES,

  /** @type {Service} */
  cardService: service(),

  /** @type {Service} */
  store: service(),

  /** @type {Service} */
  orderService: service(),

  /** @type {Service} */
  session: service(),

  /** @type {Service} */
  modals: service(),

  /** @type {FormFor} The form object passed to the component. */
  form: null,

  /** @type {DS.Model} The model object from the order edit route. */
  model: null,

  /** @type {Client[]} */
  clients: null,

  /** @type {User[]} */
  admins: null,

  /** @type {Order} */
  order: alias('model.order'),

  /** @type {User} */
  owner: alias('order.owner'),

  /** @type {string}  Order owner email */
  ownerEmail: alias('owner.email'),

  /** @type {string} Order owner phone number */
  ownerPhoneNumber: alias('owner.phoneNumber'),

  /** @type {PaymentCard[]} */
  paymentCards: alias('order.client.paymentCards'),

  /** @type {PaymentCard} */
  newPaymentCard: null,

  /** @type {string} */
  currentPromoCode: '',

  /** @type {DiscountCode[]} */
  discountCodes: null,

  /** @type {DeliveryLocation} */
  newDeliveryLocation: null,

  /** @type {boolean} whether or not to show the delivery location form */
  showDeliveryLocationForm: false,

  /** @type {?Client[]} */
  searchedClients: null,

  /** @type {User[]} */
  clientContacts: null,

  /** @type {?User[]} */
  searchedContacts: null,

  /** @type {boolean} whether or not to show the bowtie service fee form */
  isShowingBowtieServiceFee: false,

  /** @type {PaymentCard[]} */
  orderOwnerAndClientPaymentCards: union('order.client.paymentCards', 'order.owner.paymentCards'),

  /** @type {Function} callback fired when a clients dropdown is changed */
  onClientSearchChange() {},

  onDeliveryLocationSearchChange() {},

  onContactSearchChange() {},

  /** @type {Function} callback fired when a new delivery location is saved  */
  didSaveDeliveryLocation() {},

  /** @type {Function} callback fired when a new discount is saved  */
  didSaveDiscount() {},

  /** @type {Function} callback fired when a discount is destroyed  */
  didDestroyDiscount() {},

  /** @type {String[]} */
  cutleryPreferenceTypes: CutleryPreference.values,

  serviceFeeMapping: {
    'serviceFee.amount': 'amount',
    'serviceFee.amountType': 'amountType'
  },

  /** @override */
  didInsertElement() {
    this._super(...arguments);
    if (!fetchedDiscountCodes && !this.get('discountCodes')) {
      this.get('loadDiscountCodesTask').perform();
    }

    this.set('discountCodes', this.get('discountCodes') || fetchedDiscountCodes);
  },

  /** Fetches the DiscountCodes and caches them in the component  */
  loadDiscountCodesTask: task(function* () {
    fetchedDiscountCodes = yield this.get('store').findAll('discount-code');
    this.set('discountCodes', fetchedDiscountCodes.toArray());
  }),

  /** @type {boolean} */
  promoCodeIsApplied: computed('order.promoCode.code', 'currentPromoCode', function () {
    return this.get('currentPromoCode') === (this.get('order.promoCode.code') || '');
  }),

  /** @type {boolean} */
  showLateCallButton: computed(
    'order.inTransit',
    'order.owner.communicationPreference.sms',
    'order.owner.smsNumber',
    function () {
      const inTransit = this.get('order.inTransit');
      const owner = this.get('order.owner');

      return inTransit && owner.get('communicationPreference.sms') && owner.get('smsNumber');
    }
  ),

  /**
   * Sets `newPaymentCard` to a new PaymentCard
   */
  _setupNewPaymentCard() {
    const client = this.get('order.client');
    const newPaymentCard = this.get('store').createRecord('payment-card', {
      client,
      country: 'CA'
    });
    this.setProperties({ newPaymentCard });
  },

  /**
   * Sets `newDeliveryLocation` to a new DeliveryLocation
   */
  _setupNewDeliveryLocation() {
    const client = this.get('order.client');
    const newDeliveryLocation = this.get('store').createRecord('delivery-location', {
      client
    });
    this.setProperties({ newDeliveryLocation });
  },

  /**
   * Sets `newContact` to a new Contact
   */
  _setupNewContact() {
    this.setProperties({
      newContact: this.get('store').createRecord('contact')
    });
  },

  onClientChange() {},
  clientDidChange() {},

  createDiscount(discountType) {
    const discount = this.get('store').createRecord(`${discountType}-discount`, {
      amountType: 'value'
    });

    discount.set('order', this.get('order'));
  },

  /**
   * Sets up NEW models for the current client for creating
   * associated new payment-card/delivery-location/contact 's
   */
  _onClientChange: on(
    'init',
    observer('order.client', function () {
      this._setupNewPaymentCard();
      this._setupNewDeliveryLocation();
      this._setupNewContact();
    })
  ),

  onRemoveDiscount: action(function (discount) {
    discount.destroyRecord();
  }),

  destroyBowtieServiceFee: action(function () {
    this.setProperties({
      isShowingBowtieServiceFee: false,
      'order.bowtieServiceFee': null
    });
  }),

  selectCreditCard: action(function (field, paymentCard) {
    this.set('order.paymentCard', paymentCard);
  }),

  handleDidChangeOwner: action(function (owner) {
    if (!owner) {
      return;
    }

    this.set('isLoading', true);

    this.get('store')
      .findRecord('user', owner.get('id'), {
        include: 'payment-cards',
        reload: true
      })
      .finally((_) => {
        this.set('isLoading', false);
      });
  }),

  didSaveContact: action(function () {
    this._setupNewContact();
  }),

  didSaveNewPaymentCard: action(function () {
    this._setupNewPaymentCard();
  }),

  applyPromoCode: action(function () {
    this.get('order').applyPromoCode();
  }),

  handleDidSaveDeliveryLocation: action(function (newDeliveryLocation) {
    this.set('isLoading', true);
    const loc = newDeliveryLocation.get('location') || newDeliveryLocation;
    return this.locationOutOfZone(loc)
      .then((ooz) => {
        this.setProperties({
          'order.clientLocation': loc,
          'order.outOfZone': ooz
        });
        this.didSaveDeliveryLocation();
      })
      .finally(() => this.set('isLoading'), false);
  }),

  handleDidChangeDeliveryLocation: action(function (deliveryLocation) {
    this.set('isLoading', true);
    const addr = deliveryLocation.get('gmapsLocation');
    return this.addressOutOfZone(addr)
      .then((ooz) => this.set('order.outOfZone', ooz))
      .finally(() => this.set('isLoading', false));
  }),

  onAddDiscount: action(function (discountType) {
    this.createDiscount(discountType);
  }),

  editPerItemDiscounts: action(async function () {
    await this.modals.open(
      'sf/orders/modals/per-line-item-discount',
      { order: this.order },
      { focusTrapOptions: { clickOutsideDeactivates: false } }
    );
  }),

  changeActiveTab: action(function (tabName) {
    this.get('loadDiscountCodesTask').perform();
    this.set('activeTab', tabName);
  })
});
