import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';

@classic
@classNames('fde-meal-plan-custom-fields-custom-field-form-for')
export default class CustomFieldFormFor extends Component {
  customField = null;
  deleteCustomField() {}
  buttonElement = null;

  @action
  selectButton() {
    schedule('afterRender', () => {
      this.set('buttonElement', this.element.querySelector('button'));
    });
  }
}
