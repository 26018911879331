import Component from '@ember/component';
import { computed } from '@ember/object';
import moment from 'moment-timezone';

export default Component.extend({
  classNames: 'fde-controls-date-buttons-date-button ui button',

  classNameBindings: ['isSelected:fde-is-selected', 'isToday:fde-is-today'],

  /** @type {date} a day of a week **/
  date: null,

  /** @type {string} **/
  info: '-',

  /** @type {boolean} whether this is the current day, or the day of the week, and the date **/
  isSelected: false,

  /** @type {date} today's date **/
  today: moment().tz('America/Los_Angeles'),

  /** @type {?string} */
  dataPosition: null,

  /** @type {?string} */
  dataTooltip: null,

  /** @type {function} the action to run when selecting a new date **/
  onSelect() {},

  /** @type {boolean} whether this is the current day **/
  isToday: computed('date', function () {
    const date = this.get('date');
    return date && this.get('today').dayOfYear() === date.dayOfYear();
  }),

  /** @type {string} Shows whether this is the current day, or the day of the week, and the date **/
  label: computed('date', 'isToday', function () {
    const date = this.get('date');
    if (!date) {
      return;
    }

    return this.get('isToday') ? 'Today' : date.format('ddd D');
  }),

  click() {
    this.get('onSelect')(this.get('date').toDate());
  }
});
