import classic from 'ember-classic-decorator';
import { attributeBindings, classNameBindings, classNames } from '@ember-decorators/component';
import { intersect, not } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed, get } from '@ember/object';
import $ from 'jquery';
import { run } from '@ember/runloop';
import TableManager from './table-manager';
import { htmlSafe } from '@ember/template';

const ACTION_MENU_WIDTH = 34;

/**
 * Component for rendering an ordered table.
 */
@classic
@classNames('fde-ordered-table --fde-ordered-table')
@classNameBindings('stickyHeader', 'stickyFooter', 'noMargin', 'shouldFillParentContainer')
@attributeBindings('tableStyle:style')
export default class OrderedTable extends Component {
  init() {
    super.init(...arguments);

    const storageKey = this.get('storageKey');
    const shouldStoreColumnConfig = this.get('shouldStoreColumnConfig');
    const table = this;

    this.setProperties({
      tableManager: TableManager.create({
        table,
        storageKey,
        shouldStoreColumnConfig
      }),
      selectedValues: this.get('selectedValues') || [],
      expandedValues: this.get('expandedValues') || []
    });
  }

  /** @type {TableManager} */
  tableManager = null;

  /** @type {boolean} Changes table width to 400px. by checking for this in the tr template, you can render a different version designed for narrow width. */
  narrowMode = false;

  /** @type {boolean} sticks the header when scrolling*/
  stickyHeader = false;

  /** @type {boolean} sticks the footer when scrolling*/
  stickyFooter = false;

  /** @type {string} */
  sortByString = '';

  /** @type {Array} can either be an array of records, or an array of sections */
  data = null;

  /** @type {boolean} whether or not this table is loading */
  loading = false;

  /** @type {boolean} */
  isSelectable = false;

  /** @type {boolean} */
  isRowSelectable = false;

  /** @type {String} */
  selectableRowCursor = 'pointer';

  /** @type {boolean} */
  isEditable = false;

  /** @type {boolean} */
  allowActionMenu = false;

  /** @type {boolean} */
  noMargin = true;

  /** @type {Model[]} */
  selectedValues = null;

  /** @type {Model[]} */
  expandedValues = null;

  /** @type {boolean} */
  showLoaderInActionCell = false;

  /** @type {boolean} */
  showLoaderInSaveCell = true;

  /** @type {boolean} will stretch the table element to fit the container div with an invisible spacer row element */
  shouldFillParentContainer = false;

  /** @type {?String} */
  storageKey = null;

  /** @type {boolean} */
  shouldStoreColumnConfig = false;

  /** @type {boolean} */
  hideableColumns = false;

  /** @type {boolean} Ensures that the last column is always fluid all proceeding ones are hidden */
  lastColumnAlwaysFluid = true;

  /** @type {boolean} Checks if the data is grouped */
  @computed('data.[]', 'data.@each.values', 'data.@each.title')
  get isGroupedData() {
    return this.get('data.firstObject.values') && this.get('data.firstObject.title');
  }

  /** @type {boolean} */
  @not('isGroupedData')
  isNotGroupedData;

  recalculateMinWidth() {
    this.notifyPropertyChange('tableStyle');
  }

  /** @type {String} */
  @computed
  get tableStyle() {
    const columns = this.get('tableManager.columns');

    const unregisteredWidths = this.get('allowActionMenu') ? ACTION_MENU_WIDTH : 0;

    const tableMinWidth = Object.keys(columns).reduce((tableMinWidth, key) => {
      const column = columns[key];

      if (!column.visible) {
        return tableMinWidth;
      }

      const width = column.width;
      const minWidth = column.minWidth;

      let minColumnWidth = Math.max(width || 0, minWidth || 0);

      return tableMinWidth + minColumnWidth;
    }, unregisteredWidths);

    return tableMinWidth > 0 ? htmlSafe(`min-width:${tableMinWidth}px;`) : htmlSafe('');
  }

  /** @type {Model[]} A flat and unique array of all the models */
  @computed('data.[]', 'data.@each.values', 'isNotGroupedData')
  get flatData() {
    const data = this.get('data') || [];
    const isNotGroupedData = this.get('isNotGroupedData');

    return isNotGroupedData
      ? data.uniq()
      : data
          .reduce((acc, item) => {
            get(item, 'values').forEach((_) => acc.push(_));
            return acc;
          }, [])
          .uniq();
  }

  /** @type {boolean} whether or not all items in the table are selected */
  @computed('flatDataAndSelectedIntersect.length', 'flatData.length')
  get allSelected() {
    return (
      this.get('flatDataAndSelectedIntersect.length') === this.get('flatData.length') &&
      this.get('flatData.length')
    );
  }

  /** @type {Model[]} */
  @intersect('selectedValues', 'flatData')
  flatDataAndSelectedIntersect;

  /** @override */
  didInsertElement() {
    super.didInsertElement(...arguments);
    if (this.get('shouldFillParentContainer')) {
      this.setupResizeHandler();
      this.doResize();
    }

    run.scheduleOnce('afterRender', this, (_) => this.recalculateMinWidth());
  }

  /** @override */
  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.teardownResizeHandler();
    this.get('tableManager').destroy();
  }

  /** Setups the resize handler for when table stretching is enabled */
  setupResizeHandler() {
    const handleResize = this.doResize.bind(this);
    $(window).resize(handleResize);
    this.set('_handleResize', handleResize);
  }

  /** Tears down the resize handler for when table stretching is enabled */
  teardownResizeHandler() {
    $(window).off('resize', this.get('_handleResize'));
  }

  /** @override */
  didRender() {
    super.didRender(...arguments);
    if (this.get('shouldFillParentContainer')) {
      !this.get('_hanldeResize') && this.setupResizeHandler();
      this.doResize();
    } else {
      this.get('_handleResize') && this.teardownResizeHandler();
    }
  }

  /** Method for resizing the spacer column in the table element to the containing div of the element */
  doResize() {
    const $stretcherEl = this.$('.fde-ordered-table_table-stretcher');

    if (!$stretcherEl) {
      return;
    }

    $stretcherEl.hide();

    const tableHeight = this.$('table:first').height();
    const containerHeight = this.$().innerHeight();
    const diff = containerHeight - tableHeight;

    if (diff > 0) {
      $stretcherEl.find('td:first').css('height', diff);
      $stretcherEl.show();
    }
  }

  onSortChange() {}

  onSelect() {}

  onDeselect() {}

  onSelectAll() {}

  onDeselectAll() {}

  onNewSelection() {}

  onDidResetForm() {}

  onDidSubmitForm() {}

  onValueEdit() {}

  onExpand() {}

  onCollapse() {}

  onExpandAll() {}

  onCollapseAll() {}

  @action
  handleOnNewSelection(selection) {
    console.debug(selection);
    this.set('selectedValues', selection);

    this.onNewSelection(selection);
  }

  @action
  handleOnSelect(value) {
    console.debug(value);
    this.onSelect(value);
  }

  @action
  handleOnDeselect(value) {
    console.debug(value);

    this.onDeselect(value);
  }

  @action
  handleOnSelectAll() {
    console.debug();

    const newSelection = this.get('flatData').toArray();

    this.onSelectAll();
    this.set('selectedValues', newSelection);

    this.onNewSelection(newSelection);
  }

  @action
  handleDeselectAll() {
    console.debug();

    this.onDeselectAll();
    this.set('selectedValues', []);

    this.onNewSelection([]);
  }

  @action
  handleSelectAllChange(toggle) {
    console.debug(toggle);
    this.onSelectAllChange(toggle);
  }

  @action
  handleOnExpand(value) {
    this.get('expandedValues').pushObject(value);
    this.onExpand(value);
  }

  @action
  handleOnCollapse(value) {
    this.get('expandedValues').removeObject(value);
    this.onCollapse(value);
  }

  @action
  handleOnCollapseAll() {
    const currentExpandedValues = this.get('expandedValues');

    this.set('expandedValues', []);

    currentExpandedValues.forEach((_) => this.onCollapse(_));
    this.onCollapseAll();
  }

  @action
  handleOnExpandAll() {
    const allValues = this.get('flatData').toArray();
    const currentExpandedValues = this.get('expandedValues');
    const unExapendedValues = allValues.filter((_) => !currentExpandedValues.includes(_));

    this.set('expandedValues', allValues);

    unExapendedValues.forEach((_) => this.onExpand(_));
    this.onExpandAll();
  }
}
