import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@tagName('tr')
@classNames('fde-service-times-day')
export default class ServiceTimes extends Component {
  /** @type {string} Label for the day of the week.*/
  dayLabel = null;

  /** @type {array} Array of service times for a particular day.*/
  serviceTimes = null;

  /**
   * @type {boolean} Whether to display hours of operation.
   * they are the serviceTime's deliveryStartTime and deliveryEndTime
   */
  showHoursOfOperation = false;

  /**
   * @type {boolean} Whether to display pickup times.
   * they are the serviceTime's pickupStartTime and pickupEndTime
   */
  showPickupTimes = false;

  /** @type {?string} */
  timeZone = null;

  /**
   * @type {object} The first service time in the collection.
   * htmlbars currently doesn't provide the index in their each loop,
   * so this is needed for styling.
   */
  @computed('serviceTimes.[]')
  get firstServiceTime() {
    return this.get('serviceTimes.firstObject');
  }

  /**
   * @type {array} The first service times excluding the first service time in the array.
   * htmlbars currently doesn't provice the index in their each loop,
   * so this is needed for styling.
   */
  @computed('serviceTimes.[]')
  get remainingServiceTimes() {
    return this.get('serviceTimes').filter((_, index) => index > 0);
  }
}
