import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('ui segment')
export default class OrderNotes extends Component {
  form = null;
  client = {};
  get isManualNotificationEnabled() {
    return (
      this.client?.hasFeatureEnabled('manualDeliveryNotification') &&
      this.client?.storage.manualDeliveryNotificationEnabled
    );
  }

  get tooltipTextForEaterNotes() {
    if (!this.client) {
      return 'No client found. Need to have a client to add eater notes.';
    }
    if (!this.client?.hasFeatureEnabled('manualDeliveryNotification')) {
      return 'Manual Notification feature is not enabled for this client.';
    }
    if (!this.client?.storage.manualDeliveryNotificationEnabled) {
      return 'Client has not opted in Manual Delivery Notification.';
    }
    return 'Public to Eaters. Foodee staff can edit.';
  }
}
