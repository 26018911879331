import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class NewRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'AREA_CREATE';

  model() {
    return this.store.createRecord('area', {
      country: 'CA',
      geoJson: {
        type: 'Polygon',
        coordinates: [[]]
      },
      active: true
    });
  }

  /**
   * Loading tax rates should not block entering the route
   *
   * @param controller
   * @param model
   */
  setupController(controller, model) {
    super.setupController(controller, model);

    this.store
      .query('invoicing-tax-rate', { page: { limit: 200 } })
      .then((invoicingTaxRates) => controller.set('invoicingTaxRates', invoicingTaxRates));
  }
}
