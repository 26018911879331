import Fixture, {
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class SfFormsMealPlanningFormForChangeExplanationModalFixture extends Fixture {}

export const sfFormsMealPlanningFormForChangeExplanationModal = getElementDecoratorFor(
  SfFormsMealPlanningFormForChangeExplanationModalFixture
);
export const sfFormsMealPlanningFormForChangeExplanationModalWithValue =
  getElementWithValueDecoratorFor(SfFormsMealPlanningFormForChangeExplanationModalFixture);
