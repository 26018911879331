import Helper from '@ember/component/helper';

/**
 * Formats a string to be lower case
 * @param  {string} string
 * @return {string}
 */
const lowerCase = ([string]) => {
  return string && string.toLowerCase();
};

export default Helper.helper(lowerCase);
