import Controller from '@ember/controller';
import EmberObject, { action, computed } from '@ember/object';

import FilterCollection from 'star-fox/supports/filters/filter-collection';
import Filter from 'star-fox/supports/filters/filter';

export default Controller.extend({
  filterCollection: FilterCollection.create({
    filters: [
      {
        key: 'client',
        icon: 'building',
        label: 'Client',
        resourceName: 'client',
        resourceValueLabel: 'accountName',
        valueTextDelimiter: '-'
      },
      {
        key: 'restaurant',
        icon: 'utensils',
        label: 'Restaurant',
        resourceName: 'restaurant',
        resourceValueLabel: 'name',
        valueTextDelimiter: '-'
      },
      {
        key: 'area',
        icon: 'map',
        label: 'Area',
        resourceName: 'area',
        resourceValueLabel: 'city',
        valueTextDelimiter: '-'
      },
      {
        key: 'deliver_on',
        icon: 'calendar',
        label: 'Deliver On'
      },
      {
        key: 'deliver_on_range',
        icon: 'calendar',
        label: 'Deliver On Range'
      },
      {
        key: 'is_draft',
        icon: 'checkbox',
        label: 'Draft'
      }
    ].map((_) => Filter.create(_))
  }),

  areas: [
    {
      id: '7',
      city: 'Austin',
      country: 'US'
    },
    {
      id: '8',
      city: 'Toronto',
      country: 'CA'
    },
    {
      id: '9',
      city: 'Vancouver',
      country: 'CA'
    },
    {
      id: '16',
      city: 'Philadelphia',
      country: 'US'
    },
    {
      id: '17',
      city: 'Pittsburgh',
      country: 'CA'
    },
    {
      id: '18',
      city: 'Denver',
      country: 'CA'
    }
  ].map((_) => EmberObject.create(_)),

  serializedFiltersOutput: computed('filterCollection.filters.@each.value', function () {
    return JSON.parse(this.get('filterCollection').storeFilters());
  }),

  filtersAsJR: computed('filterCollection.asJR', function () {
    return this.get('filterCollection.asJR');
  }),

  filtersAsQueryParams: computed('filterCollection.asQueryParams', function () {
    return this.get('filterCollection.asQueryParams');
  }),

  renderCode: true,

  serializedFilters: `[
  {
    "id": 1,
    "key": "client",
    "value": ["1", "2", "3"],
    "valueText": ["Vancouver Client", "Austin Client", "Toronto Client"]
  },
  {
    "id": 2,
    "key": "restaurant",
    "value": ["4", "5", "6"],
    "valueText": ["Vancouver Restaurant", "Austin Restaurant", "Toronto Restaurant"]
  },
  {
    "id": 3,
    "key": "area",
    "value": ["7", "8", "9"],
    "valueText": ["Austin", "Toronto", "Vancouver"]
  },
  {
    "id": 4,
    "key": "deliver_on",
    "value": ["2018-06-29"]
  },
  {
    "id": 5,
    "key": "deliver_on_range",
    "value": ["2018-06-29", "2018-06-30"]
  }
]`,

  queryParamsFilters: `{
  "client": ["10-Vancouver Client", "11-Austin Client", "12-Toronto Client"],
  "restaurant": ["13-Vancouver Restaurant", "14-Austin Restaurant", "15-Toronto Client"],
  "area": ["16-Philadelphia", "17-Pittsburgh", "18-Denver"]
}`,

  restoreQueryFilters: action(function () {
    const filterCollection = this.get('filterCollection');
    filterCollection.restoreFromQueryParams(JSON.parse(this.get('queryParamsFilters')));
  }),

  restoreSerializedFilters: action(function () {
    const filterCollection = this.get('filterCollection');
    filterCollection.restoreFromStorage(this.get('serializedFilters'));
  })
});
