import Fixture, {
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

export default class BasicModalFixture extends Fixture {
  constructor() {
    super('data-test-sf-basic-modal');
  }
}

export const basicModal = getElementDecoratorFor(BasicModalFixture);

export const basicModalWithValue = getElementWithValueDecoratorFor(BasicModalFixture);
