import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { or } from '@ember/object/computed';
import { action } from '@ember/object';
import Component from '@ember/component';
import RSVP from 'rsvp';
import isSameAs from 'star-fox/utils/is-same-as';

@classic
@tagName('')
export default class OrderMember extends Component {
  /** @type {NotificationService} */
  @service
  notify;

  /** @type {GroupOrderMember} */
  groupOrderMember = null;

  /** @type {GroupOrderMember|null} the currently selected GroupOrderMember */
  selectedGroupOrderMember = null;

  /** @type {boolean} */
  expandAllMembers = null;

  /** @type {boolean} */
  @or('isSelected', 'expandAllMembers')
  isExpanded;

  /** @type {boolean} */
  @isSameAs('groupOrderMember.id', 'selectedGroupOrderMember.id')
  isSelected;

  /** @type {Function} */
  didDestroy() {}

  @action
  sendSummary() {
    this.get('groupOrderMember')
      .sendSummary()
      .then(() => this.get('notify').success('Summary Sent!'))
      .catch(() => this.get('notify').error('There was a problem sending the summary!'));
  }

  @action
  cancelIndividualPayment() {
    if (
      confirm(
        'Canceling the individual payment will remove all order items from the cart. Press OK to continue or Cancel to abort.'
      )
    ) {
      const groupOrderMember = this.get('groupOrderMember');
      const orderItemDestroys = groupOrderMember.get('orderItems').map((_) => _.destroyRecord());

      RSVP.all(orderItemDestroys)
        .then((_) => {
          groupOrderMember.set('stripeChargeToken', null);
          return groupOrderMember.save();
        })
        .then(() => this.get('notify').success('Individual payment order cancelled!'))
        .catch(() =>
          this.get('notify').error('There was a problem cancelling individual payment!')
        );
    }
  }

  @action
  async checkout() {
    await this.groupOrderMember.checkout();
    this.memberDidCheckout(this.groupOrderMember);
  }
}
