import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';

import { UserSessionEvent } from 'renard/utils/user-session-event';

@classic
export default class LoginController extends Controller {
  /** @type {UserSession} */
  @service
  userSession;

  /** @type {Service} */
  @service
  session;

  @alias('model.login')
  credentials;

  @alias('model.mealPlan.firstObject')
  mealPlan;

  /** @type {boolean} canLogin  */
  @computed('credentials.{email,password}')
  get canLogin() {
    return !!this.get('credentials.email') && !!this.get('credentials.password');
  }

  @action
  login() {
    const session = this.get('session');
    const userSession = this.userSession;
    const email = this.get('credentials.email');
    const password = this.get('credentials.password');

    // clear the error
    this.setProperties({
      loginError: '',
      'credentials.isSaving': true
    });

    return (
      email &&
      password &&
      session
        .authenticate('authenticator:devise', email, password)
        // logged in
        .then((_) => {
          // We prefer fetching the user from the session.
          if (this.session.user) {
            // `_` is `undefined`, so we fetch the user from the session.
            userSession.trigger(UserSessionEvent.UserIdentified, this.session.user);
          } else {
            // Sometimes even `this.session.user` is undefined, so we send just their email.
            userSession.trigger(UserSessionEvent.UserIdentified, email);
          }

          this.setProperties({
            'credentials.isSaving': false,
            password: ''
          });
        })
        // oh noes
        .catch((response) => {
          this.setProperties({
            'credentials.isSaving': false,
            loginError: response.statusText
          });
        })
    );
  }
}
