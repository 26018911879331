import classic from 'ember-classic-decorator';
import Helper from '@ember/component/helper';

export function qpForModelFilter([models, idProp = 'id', labelProp = 'label']) {
  return models.map((_) => `${_.get(idProp)}::${_.get(labelProp)}`).join(';');
}

@classic
export default class QpForModelFilter extends Helper {
  compute = qpForModelFilter;
}
