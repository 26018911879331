import classic from 'ember-classic-decorator';
import OrderRoute from 'star-fox/features/logged-in/orders/edit/accounting/route';

@classic
export default class AccountingRoute extends OrderRoute {
  /** @override */
  templateName = 'logged-in.orders.edit.accounting';

  orderEditRoute = 'logged-in.restaurants.show.capacities.edit-order';
}
