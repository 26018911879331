import classic from 'ember-classic-decorator';
import SearchAction from './base';

@classic
export default class AccountManager extends SearchAction {
  /** @override */
  modelName = 'user';

  /** @override */
  filterSearchName = 'search';

  /** @override Looking only for account*/
  defaultSearch(modelName, query, filterSearchName, defaultIncludes, queryOptions, resource) {
    queryOptions.filter = queryOptions.filter || {};
    queryOptions.filter.has_role = ['concierge'];
    return super.defaultSearch(
      modelName,
      query,
      filterSearchName,
      defaultIncludes,
      queryOptions,
      resource
    );
  }
}
