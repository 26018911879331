import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import { alias, filterBy } from '@ember/object/computed';
import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';

import MenuValidator from 'star-fox/models/validators/menu';
import { modelAction } from 'ember-custom-actions';

@classic
export default class Menu extends Model.extend(MenuValidator) {
  /*
   * Attributes
   */
  @attr('boolean')
  active;

  @attr('date')
  createdAt;

  @attr('object')
  dietaryTagsCounts;

  @attr('boolean')
  draft;

  @attr('string')
  name;

  @attr('number')
  parentId;

  /*
   * Relationships
   */
  @belongsTo('menu', { inverse: 'children' })
  parent;

  @hasMany('menu', { inverse: 'parent' })
  children;

  @hasMany('menu-group', { async: false })
  menuGroups;

  @belongsTo('restaurant', { inverse: 'menus' })
  restaurant;

  @belongsTo('area')
  area;

  @hasMany('order')
  orders;

  @belongsTo('order', { inverse: 'menu' })
  order;

  /*
   * Computed Properties
   */
  /** @type {string} */
  @alias('id')
  locationId;

  /** @type {string} */
  @computed('code')
  get humanize() {
    return `Menu: '${this.get('name')}'`;
  }

  /** @type {MenuItem[]} */
  @filterBy('menuItems', 'active', true)
  activeMenuItems;

  /** @type {MenuItem[]} */
  @computed('menuGroups.@each.menuItems')
  get menuItems() {
    return this.get('menuGroups').reduce(
      (menuItems, group) => menuItems.concat(group.get('menuItems').toArray()),
      []
    );
  }

  /*
   * Functions
   */

  affectedOrders = modelAction('affected-orders', {
    method: 'GET'
  });

  /**
   *
   * @param {string} include
   */
  reloadWith(include) {
    return this.store.findRecord('menu', this.get('id'), {
      reload: true,
      include: include
    });
  }

  /**
   * @return {Number}
   */
  positionOf(menuGroup) {
    return this.get('menuGroups').indexOf(menuGroup);
  }

  /**
   * Duplicates the Menu that this method is called on
   * @return {Menu} Returns the newly made Menu
   */
  duplicate() {
    this.set('isDuplicating', true);

    const modelName = this.constructor.modelName;
    const adapter = this.store.adapterFor(modelName);
    return adapter.duplicate(this, 'restaurant').finally((_) => this.set('isDuplicating', false));
  }

  /**
   * Removes the draft state, switches the current active menu to inactive
   * and makes this one active
   */
  publish = modelAction('publish', {
    method: 'PATCH'
  });
}
