import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import $ from 'jquery';

/**
 * Meant to be a scrollable view that occupies the remainder of the vertical space from where it is used.
 *
 * Note: FlexBox layouts would work way better for this, so lets consider this a stop gap measure to when we make
 * better tab views that couple with navigation.
 */
@classic
@classNames('fde-views-full-height-scroll-view')
export default class FullHeightScrollView extends Component {
  /**
   * @type {number} amount of padding to put on the bottom of this view
   */
  bottomPadding = 20;

  fillHeight() {
    const height = `${window.innerHeight - this.$().offset().top - this.get('bottomPadding')}px`;
    this.$().css({ height });
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.fillHeight = this.fillHeight.bind(this);
    this.fillHeight();

    $(window).bind('resize', this.fillHeight);
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    $(window).unbind('resize', this.fillHeight);
  }
}
