import { typeOf } from '@ember/utils';
import { run } from '@ember/runloop';
import { guidFor } from '@ember/object/internals';
import type from 'star-fox/utils/type';

const map = {};

/**
 * Removes the unique debounce entry; prevent memory leaks.
 * @param {string} guid
 */
function cleanup(guid) {
  delete map[guid];
}

/**
 * Creates a wrapper method, which will call the debounced method and cleanup the debounce entry after it gets called.
 * @param {*} target
 * @param {Function} method
 * @param {Array} args
 * @param {string} guid
 * @returns {Function}
 */
function createWrapMethod(target, method, args, guid) {
  return function () {
    const retVal = method.apply(target, args);

    cleanup(guid);

    return retVal;
  };
}

/**
 * Finds or creates a wrapper method.
 * @param {*} target
 * @param {Function} method
 * @param {Array} args
 * @returns {Function}
 */
function findWrapMethod(target, method, args) {
  let wrapMethod = Object.keys(map).reduce((wrapMethod, key) => {
    if (wrapMethod) {
      return wrapMethod;
    }

    const entry = map[key];
    const arrayEquals =
      entry.args.length === args.length &&
      entry.args.reduce((isEqual, arg, i) => {
        if (typeOf(arg) === 'instance') {
          return isEqual && args[i] === arg;
        } else {
          return isEqual && JSON.stringify(args[i]) === JSON.stringify(arg);
        }
      }, true);

    return (
      (entry.target === target && entry.method === method && arrayEquals && entry.wrapMethod) ||
      null
    );
  }, null);

  if (!wrapMethod) {
    const guid = guidFor({});
    wrapMethod = createWrapMethod(target, method, args, guid);
    map[guid] = { target, args, method, wrapMethod };
  }

  return wrapMethod;
}

/**
 * Creates a debounce who's signature isn't just the method, but the method with the set of arguments. This way every
 * debounce call with a unique set of args will create a debounce unique to that method and set of arguments together.
 *
 * @param {*} target
 * @param {Function} method
 * @returns {Array}
 */
export default function uniqueDebounce(target, method /* ...args*, wait, immediate */) {
  const args = [...arguments];

  let immediate = args.pop();
  let wait;

  if (type.isNumber(immediate) || type.isString(immediate)) {
    wait = immediate;
    immediate = false;
  } else {
    wait = args.pop();
  }

  args.splice(0, 2);

  const wrapMethod = findWrapMethod(target, method, args);

  const debounceArgs = [target, wrapMethod, ...args, wait, immediate];
  return run.debounce.apply(run.debounce, debounceArgs);
}
