import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from 'renard/models/foodee';
import RSVP from 'rsvp';
import { modelAction } from 'ember-custom-actions';
import moment from 'moment-timezone';
import download from 'star-fox/utils/download';

import { fragmentArray } from 'ember-data-model-fragments/attributes';

@classic
export default class MealPlan extends Model {
  validations = {
    vanityUrl: {
      presence: {},
      format: {
        with: /^[a-zA-Z0-9-]+$/,
        allowBlank: false,
        message: 'Vanity Url may only contain letters numbers and hyphens'
      }
    },
    brandColor: {
      length: 7,
      presence: {},
      format: {
        with: /^#[a-fA-F0-9]+$/,
        allowBlank: true,
        message: 'must be a valid hex colour (0-F)'
      }
    },
    logoUrl: {
      presence: {
        message: 'Meal plans must have client logos'
      }
    }
  };

  // Attributes
  @attr('string')
  displayName;

  @attr('string')
  nameLabel;

  @attr('string')
  nameLabelPlaceholder;

  @attr('string')
  vanityUrl;

  @attr('string')
  brandColor;

  @attr('string')
  customCss;

  @attr('string')
  logoUrl;

  @attr('string')
  emailDomain;

  @attr('string')
  password;

  @attr('number')
  defaultOpensAtLeadTime;

  @attr('boolean')
  alwaysRequestDepartment;

  @attr('string')
  departmentLabel;

  @attr('array')
  departmentOptions;

  @attr('boolean')
  isDepartmentRequired;

  // SSO
  @attr('string')
  ssoType;

  @attr('object')
  samlSettings;

  @attr('string')
  samlIdpMetadataXml;

  // Custom fields
  @fragmentArray('fragments/meal-plan/custom-field')
  customFields;

  /*
   * Computed Properties
   */

  @computed('ssoType')
  get ssoEnabled() {
    return this.get('ssoType') !== 'none';
  }

  // TODO: make this a common property some how
  @computed('ssoType')
  get ssoName() {
    return this.get('samlSettings.idpName');
  }

  // Relationships
  @belongsTo('client')
  client;

  fetchSAMLSettings = modelAction('fetch-saml-settings', {
    type: 'POST'
  });

  /**
   * Fetches a csv of a particular tranche of orders on this meal plan object
   *
   * @param {date} date
   */
  fetchMealPlanCSV(date) {
    const format = moment(date).format('YYYY-MM-DD');

    const urls = ['breakfast', 'lunch', 'dinner'].map(
      (_) => `/api/v3/meal-plans/${this.get('id')}/csv/${format}/${_}`
    );

    download(urls);
  }

  /**
   * Adds a custom-field fragment to the custom-fields's fragmentArray
   */
  addCustomField() {
    const customFields = this.get('customFields');
    return (
      (customFields && RSVP.Promise.resolve(customFields.createFragment())) ||
      this.addFirstCustomField()
    );
  }

  addFirstCustomField() {
    this.set('customFields', [this.store.createFragment('fragments/meal-plan/custom-field')]);
    return RSVP.Promise.resolve(this.get('customFields'));
  }

  /**
   * Removes a custom-field fragment from the custom-fields's fragmentArray
   */
  deleteCustomField(customField) {
    return RSVP.Promise.resolve(
      this.set(
        'customFields',
        this.get('customFields').filter((field) => field != customField)
      )
    );
  }
}
