import { helper as buildHelper } from '@ember/component/helper';

/**
 * Returns true if object has a property defined
 *
 * @param {(string|number|null)[]}
 * @returns {boolean}
 */
export function hasProp([object, prop]) {
  if (!object) {
    return false;
  }

  return object.hasOwnProperty(prop);
}

export default buildHelper(hasProp);
