import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';

@classic
export default class RuleController extends Controller {
  /** @property {number} pageLimit - records per page */
  pageLimit = 30;

  /** @property {number} pageOffset - current page */
  pageOffset = 0;

  queryParams = ['pageLimit', 'pageOffset'];

  @alias('model')
  rules;
}
