import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import { assert } from '@ember/debug';
import { isPresent } from '@ember/utils';

@classic
export default class RelativePath extends Helper {
  @service
  router;

  compute([routeName], { replaceIndex = true }) {
    assert('with-relative-path: Must provide a routeName', isPresent(routeName));

    let currentRouteName = this.get('router.currentRouteName');

    if (replaceIndex) {
      currentRouteName = currentRouteName.replace(/\.[^.]+$/, '');
    }

    return `${currentRouteName}.${routeName}`;
  }
}
