import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from 'renard/models/foodee';

function isValidTime(time) {
  return time != 'Invalid date' && time != 'undefined' && time != null;
}

@classic
export default class ServiceTime extends Model {
  @attr('number')
  weekday;

  @attr('time')
  deliveryStartTime;

  @attr('time')
  deliveryEndTime;

  @attr('time')
  pickupStartTime;

  @attr('time')
  pickupEndTime;

  @attr('string')
  weekdayLetter;

  @attr('string')
  weekdayName;

  // Relationships
  @belongsTo('restaurant')
  restaurant;

  // Computed Properties
  @computed('pickupStartTime', 'pickupEndTime', 'deliveryStartTime', 'deliveryEndTime')
  get humanize() {
    const pickupRange = [
      this.get('pickupStartTime').format('h:mm a'),
      this.get('pickupEndTime').format('h:mm a')
    ];
    const deliveryRange = [
      this.get('deliveryStartTime').format('h:mm a'),
      this.get('deliveryEndTime').format('h:mm a')
    ];

    let validRanges = [];

    if (pickupRange.filter(isValidTime).length === 2) {
      validRanges = validRanges.concat(` pickup times ${pickupRange.join(' — ')}`);
    }

    if (deliveryRange.filter(isValidTime).length === 2) {
      validRanges = validRanges.concat(` delivery times ${deliveryRange.join(' — ')}`);
    }

    return validRanges + ' ?';
  }
}
