import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@classNames('fde-order-teams-joined-user ui segment')
export default class JoinedUser extends Component {
  /** @type {User} */
  joinedUser = null;

  /** @type {boolean} */
  canAddGroupOrderMember = false;

  /** @type {boolean} */
  enableReminder = false;

  /** @type {Order} */
  order = null;

  /** @type {Object} */
  joinedUsersMeta = null;

  /** @type {string} Unique id to prevent multiple modals opening */
  @computed
  get modalId() {
    return `fde-notify-joined-user-modal ${this.get('elementId')}`;
  }

  removeUser() {}
  handleAddGroupOrderMemberClick() {}
  triggerShareNotification() {}

  @action
  closeModal(modal) {
    modal.get('modal').closeModal(modal, modal.get('id'));
  }
}
