import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';
import { modelAction } from 'ember-custom-actions';

/**
 * @class UserInvite
 */
@classic
export default class UserInvite extends Model {
  /*
   * Attributes
   */
  @attr('string')
  email;

  @attr('string')
  firstName;

  @attr('string')
  lastName;

  @attr('string')
  phoneNumber;

  @attr('string')
  extension;

  @attr('boolean')
  inviteAsAdmin;

  /*
   * Relationships
   */
  @belongsTo('client')
  client;

  @belongsTo('courier')
  courier;

  @belongsTo('restaurant')
  restaurant;

  @belongsTo('user')
  user;

  @belongsTo('team')
  team;

  /*
   * Validations
   */
  validations = {
    firstName: {
      presence: true
    },
    lastName: {
      presence: true
    },
    email: {
      presence: true
    },
    phoneNumber: {
      custom: {
        validation: function (key, value, _model) {
          if (value && value.match(/999.*999.*999/)) {
            return true;
          }

          return intlTelInputUtils.isValidNumber(value);
        },
        message: 'Please provide a valid phone number'
      }
    }
  };

  createInvitation = modelAction('user-invite', {
    method: 'POST',
    pushToStore: true
  });
}
