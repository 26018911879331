import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { intersect } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import $ from 'jquery';

/**
 * Displays a collection of data as rows with either a form, or
 * a row depending on if the row has been set to be edited
 */
@classic
@tagName('tbody')
export default class TableRows extends Component {
  /** @type {DS.Model[]} */
  values = null;

  /** @type {boolean} */
  isSelectable = false;

  /** @type {boolean} */
  isExpandable = false;

  /** @type {boolean} */
  isEditable = false;

  /** @type {Model[]} */
  selectedValues = [];

  /** @type {Model[]} */
  expandedValues = [];

  /** @type {Model[]} */
  @intersect('selectedValues', 'values')
  rowsSelectedValues;

  /** @type {boolean} */
  managesOwnSelected = false;

  /** @type {boolean} */
  allSelected = false;

  /** @type {boolean} */
  allowActionMenu = true;

  /** @type {TableManager} */
  tableManager = null;

  /** @type {Model} */
  valueToEdit = null;

  /** @type {FormFor} */
  isModelDirty = false;

  /** @type {boolean} */
  showLoaderInActionCell = false;

  /** @type {boolean} */
  showLoaderInSaveCell = true;

  /** @type {number} If a section creates this row, this will be the section index */
  sectionIndex = null;

  /** @type {boolean} */
  isRowSelectable = false;

  /** @type {String} */
  selectableRowCursor = 'pointer';

  /** @type {int} used to focus this object */
  tabIndex = 0;

  /** @type {boolean} Ensures that the last column is always fluid all proceeding ones are hidden */
  lastColumnAlwaysFluid = true;

  /** @type {String} */
  @computed
  get guid() {
    return guidFor(this);
  }

  isExpanded = true;

  /** @type {String} */
  @computed('guid')
  get expandedGuid() {
    return `expanded-row-${this.get('guid')}`;
  }

  onSelect() {}
  onDeselect() {}
  onNewSelection() {}
  onDidResetForm() {}
  onDidSubmitForm() {}
  onValueEdit() {}

  didInsertElement() {
    super.didInsertElement(...arguments);

    //If used in sections, this component does not have a tagName
    //TODO: Get this functionality into the table-sections
    if (this.get('tagName') && this.get('isRowSelectable')) {
      this.$().attr('tabindex', '0');
      this.element.addEventListener(
        'keydown',
        function (e) {
          this._dispatchKey(e.key, e);

          if (e.key === 'Tab') {
            e.stopPropagation();
          }

          return false;
        }.bind(this)
      );
    }
  }

  /**
   * Handler for key events
   *
   * @param {String} key
   * @private
   */
  _dispatchKey(key, event) {
    switch (key) {
      case 'ArrowDown':
        this._selectNext();
        break;
      case 'ArrowUp':
        this._selectPrev();
        break;
      case 'c':
        if (event.metaKey || event.ctrlKey) {
          this._addSelectionToClipBoard();
        }
        break;
    }
  }

  /**
   * Selects the next value from the first of the selection as the only value of the new selection
   * @private
   */
  _selectNext() {
    const firstSelection = this.get('selectedValues.firstObject');

    if (firstSelection) {
      const indexOf = this.get('values').indexOf(firstSelection);
      const valueOfNewSelection = this.get('values').toArray()[indexOf + 1];

      if (valueOfNewSelection) {
        this.onNewSelection([valueOfNewSelection]);
      }
    }
  }

  /**
   * Selects the previous value from the first of the selection as the only value of the new selection
   * @private
   */
  _selectPrev() {
    const firstSelection = this.get('selectedValues.firstObject');

    if (firstSelection) {
      const indexOf = this.get('values').indexOf(firstSelection);
      const valueOfNewSelection = this.get('values').toArray()[indexOf - 1];

      if (valueOfNewSelection) {
        this.onNewSelection([valueOfNewSelection]);
      }
    }
  }

  /**
   * Provided there's a set of values already selected, this will select from the first value of the current selection
   * to the passed in value. Will select from the first selection to the passed in value, in order.
   * @param {Model|Object} value
   * @private
   */
  _selectTo(value) {
    const values = this.get('values').toArray();
    const selectedValues = this.get('rowsSelectedValues').toArray();
    const managesOwnSelected = this.get('managesOwnSelected');

    const firstSelected = selectedValues[0];

    if (firstSelected) {
      let newSelection;

      const fromIndex = values.indexOf(firstSelected);
      const toIndex = values.indexOf(value);

      if (fromIndex < toIndex) {
        newSelection = values.slice(fromIndex, toIndex + 1);
      } else {
        newSelection = values.slice(toIndex, fromIndex + 1).reverse();
      }

      managesOwnSelected && this.set('selectedValues', newSelection.toArray());

      this.onNewSelection(newSelection);
    } else {
      this._selectThis(value);
    }
  }

  /**
   * Adds / removes a value from the selected list. Toggles if you do not pass in an explicit value for selection as
   * the second parameter.
   * @param {Model|Object} value
   * @param {boolean}
   * @private
   */
  _selectChange(value, shouldSelect) {
    const selectedValues = this.get('rowsSelectedValues').toArray();
    const managesOwnSelected = this.get('managesOwnSelected');

    if (shouldSelect === undefined) {
      shouldSelect = !selectedValues.includes(value);
    }

    if (shouldSelect) {
      selectedValues.push(value);
      this.onSelect(value);
    } else {
      selectedValues.removeObject(value);
      this.onDeselect(value);
    }

    managesOwnSelected && this.set('selectedValues', selectedValues);

    this.onNewSelection(selectedValues.toArray());
  }

  /**
   * Selects only the passed in value as the new selection.
   * @param {Model|Object} value
   * @private
   */
  _selectThis(value) {
    const newSelection = [value];
    const managesOwnSelected = this.get('managesOwnSelected');

    managesOwnSelected && this.set('selectedValues', newSelection);

    this.onNewSelection(newSelection);
  }

  /**
   * Iterates through all the selected rows, gets the text value from the HTML and copies it in to a tab separated list
   * for easy pasting into spreadsheets.
   * @private
   */
  _addSelectionToClipBoard() {
    let tsvText;

    const rows = this.get('tableManager').getSelectedRows();
    const $copyable = $('<textarea class="fde-ordered-table-table-rows_input"></textarea>');

    this.$().append($copyable);

    rows.forEach((row) => {
      row
        .get('tr')
        .$('td:visible')
        .each(function () {
          const cellString = $(this)
            .text()
            .replace(/(\r\n\t|\n|\r\t)/gm, '') //Get rid of all line escapes
            .replace(/\s+/g, ' ') //Get rid of superfluous spaces
            .trim();

          if (tsvText && tsvText[tsvText.length - 1] == '\n') {
            tsvText = `${tsvText}${cellString}`;
          } else if (tsvText) {
            tsvText = `${tsvText}\t${cellString}`;
          } else {
            tsvText = `${cellString}`;
          }
        });

      tsvText = tsvText + '\r\n';
    });

    $copyable.val(tsvText);
    $copyable[0].select();

    try {
      document.execCommand('copy');
    } finally {
      window.getSelection().removeAllRanges();
      $copyable.remove();
    }
  }

  @action
  handleRowViewClick(value, clickEvent) {
    if (this.get('isRowSelectable')) {
      this.get('tagName') && this.$().focus();

      if (clickEvent.ctrlKey || clickEvent.metaKey) {
        this._selectChange(value);
      } else if (clickEvent.shiftKey) {
        this._selectTo(value);
      } else {
        this._selectThis(value);
      }

      document.getSelection().removeAllRanges();
    }

    if (this.get('isEditable') && !this.get('valueToEdit.isModelDirty')) {
      this.set('valueToEdit', value);
      this.onValueEdit(value);
    }
  }

  @action
  handleSelectChange(value, shouldSelect) {
    this._selectChange(value, shouldSelect);
  }

  @action
  handleModelDirtyChange(value, isDirty) {
    value && value.set('isModelDirty', isDirty);
  }
}
