import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { action, computed } from '@ember/object';

@classic
@tagName('')
export default class MenuItem extends Component {
  @service
  session;

  /** @type {MenuItem} **/
  menuItem = null;

  /** @type {Component} */
  sortableMenuItemGroup = null;

  /** @type {InvoicingTaxRate[]} */
  areaTaxRates = null;

  /** @type {boolean} whether or not a group is being dragged */
  isDraggingGroup = false;

  /** @type {boolean} whether or not a menu item edit is being spawned */
  spawningMenuItemEdit = false;

  /** @type {function} checks what orders are affected by menu changes */
  checkAffectedOrders() {}

  /** @type {function} callback when clicking duplicate */
  onDuplicate() {}

  /** @type {function} callback when clicking edit / add options */
  onEditMenuItem() {}

  /** @type {MealType[]} =*/
  @computed('availableMealTypes.[]', 'menuItem.mealTypes.[]')
  get selectableMealTypes() {
    const mealTypes = this.get('menuItem.mealTypes') || [];
    const anyExclusive = mealTypes.any((_) => _.get('isExclusive'));
    const availableMealTypes = this.get('availableMealTypes') || [];

    return anyExclusive
      ? availableMealTypes.rejectBy('isExclusive').concat(mealTypes).uniq()
      : availableMealTypes;
  }

  onDragStart() {}
  onDragStop() {}

  @action
  handleEditMenuItem(menuItem) {
    this.set('spawningMenuItemEdit', true);

    return this.onEditMenuItem(menuItem).finally((_) => this.set('spawningMenuItemEdit', false));
  }
}
