import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class ClientDiscount extends Model {
  /*
   * Attributes
   */
  @attr('number')
  accountingCode;

  @attr('number')
  amount;

  @attr('string')
  amountType;

  @attr('string')
  caseId;

  @attr('string')
  description;

  /*
   * Relationships
   */
  @belongsTo('order')
  order;

  @belongsTo('client')
  owner;

  /*
   * Computed Properties
   */
}
