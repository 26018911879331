import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class PayrollRoute extends Route {
  userShowRoute = 'logged-in.users.show';

  model() {
    const user = this.modelFor(this.userShowRoute);
    return user;
  }
}
