import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class TopCuisineRoute extends Route.extend(LoadingRouteMixin, CapabilityRouteMixin) {
  capability = 'TAGS_INDEX';

  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    searchText: { refreshModel: true },
    sort: { refreshModel: true }
  };

  async model(params) {
    const { pageLimit, pageOffset, searchText, sort } = params;

    const topCuisines = await this.store.query('meal-planning-requirement', {
      page: {
        limit: pageLimit,
        offset: pageOffset
      },
      filter: {
        search: searchText,
        topCuisine: true
      },
      sort: sort,
      include: [
        'areas',
        'tags',
        'requirement-constraints',
        'requirement-constraints.dietary-tags',
        'requirement-constraints.allergy-tags',
        'requirement-constraints.packaging-tags',
        'requirement-constraints.food-types',
        'requirement-constraints.meal-types'
      ].join(',')
    });
    await topCuisines?.areas;
    await topCuisines?.tags;
    await topCuisines?.requirementConstraints;

    return RSVP.hash({ topCuisines });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
