import Model, { attr } from 'renard/models/foodee';
import { modelAction } from 'ember-custom-actions';

export default class TwilioMessageModel extends Model {
  @attr('boolean') active;
  @attr('string') domain;
  @attr('string') name;
  @attr('string') template;
  @attr('string') transport;
  @attr('string') recordType;
  @attr('string') preferenceKey;

  get icon() {
    return this.transport === 'RoboCall' ? 'phone' : 'envelope';
  }

  testMessage = modelAction('test-message');
}
