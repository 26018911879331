import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class GiftbitPointsAction extends Model {
  @attr('number')
  amount;

  @attr('string')
  reason;

  @attr('string')
  type;

  @belongsTo('giftbit-card')
  giftbitCard;

  validations = {
    reason: {
      presence: true
    },
    amount: {
      presence: true,
      numericality: {
        greaterThan: 0
      }
    }
  };
}
