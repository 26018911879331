import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { action, computed } from '@ember/object';
import Controller from '@ember/controller';
import { CutleryPreference } from 'renard/transforms/cutlery-preference';
import { MinimumOrderCostSetting } from 'renard/transforms/minimum-order-cost-setting';
import { FeeType } from 'renard/transforms/fee-type';

export const tabs = {
  DISCOUNT_TAB: 'discountTab'
};
@classic
export default class IndexController extends Controller {
  /** @type {Service} */
  @service
  session;

  /** @type {Client} */
  @alias('model')
  client;

  /** @type {?number} */
  originalTermDays = null;

  /** @type {Area[]} */
  areas = [];

  /** @type {InvoicingTaxRate[]} */
  taxRates = null;

  /** @type {boolean} */
  showNewLocationForm = false;

  /** @type {String[]} */
  cutleryPreferenceTypes = CutleryPreference.values;

  minimumOrderCostSettingTypes = MinimumOrderCostSetting.isSelectable;

  /** @type {DiscountCodes[]} result of all DiscountCodes */
  discountCodes = null;

  activeTab = tabs.DISCOUNT_TAB;

  tabs = tabs;

  serviceFeeMapping = {
    'serviceFee.amount': 'amount',
    'serviceFee.amountType': 'amountType'
  };

  feeTypeValues = [FeeType.Conditional, FeeType.Flat];

  @computed('client.serviceFee.type', 'client.isEnterprise')
  get canChangeServiceFee() {
    return this.get('client.serviceFee.isFlat') && this.get('client.isEnterprise');
  }

  @action
  changeActiveTab(tabName) {
    this.set('activeTab', tabName);
  }

  @action
  didCancel() {
    this.transitionToRoute('logged-in.clients');
  }

  @action
  resetNewLocation() {
    this.set(
      'newDeliveryLocation',
      this.store.createRecord('delivery-location', {
        client: this.get('model')
      })
    );
  }

  @action
  resetNewPaymentCard() {
    this.set(
      'newPaymentCard',
      this.store.createRecord('payment-card', {
        client: this.get('model'),
        country: 'CA'
      })
    );
  }

  @action
  resetTermDays(isTermsSelected) {
    const client = this.get('client');

    isTermsSelected
      ? client.setProperties({ terms: true, termDays: this.get('originalTermDays') })
      : client.setProperties({ terms: false, termDays: null });
  }

  get disableManualNotificationCheckbox() {
    return !(
      this.client.isSubscriptionPlanEnterprise &&
      this.client.hasFeatureEnabled('manualDeliveryNotification')
    );
  }

  get tooltipTextForManualNotification() {
    if (!this.client.hasFeatureEnabled('manualDeliveryNotification')) {
      return 'Manual Notification feature is not enabled for this client.';
    }
    if (!this.client.isSubscriptionPlanEnterprise) {
      return 'Manual Notification feature is only available for clients with Plus subscription.';
    }
    return null;
  }

  get minimumOrderCostSettings() {
    return MinimumOrderCostSetting.values.filter(
      (_) => this.client.isEnterprise || _.isCancelOrder
    );
  }
}
