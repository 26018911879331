import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class ShowRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'MENU_SHOW';

  model(params) {
    const { restaurant } = this.modelFor('logged-in.restaurants.show');

    return RSVP.hash({
      restaurant,
      area: restaurant.get('area'),
      areas: restaurant.get('areas', { include: 'invoicing-tax-rates' }),
      dietaryTags: this.store.findAll('dietary-tag'),
      globalTaxRates: this.store.query('invoicing-tax-rate', {
        filter: {
          global: true
        }
      }),

      menu: this.store.findRecord('menu', params.menu_id, {
        include: [
          'area',
          'menu-groups.menu-items.dietary-tags',
          'menu-groups.menu-items.tax-rate',
          'menu-groups.menu-items.menu-option-groups.menu-option-items.dietary-tags'
        ].join(',')
      })
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.set('isCheckingMenuDependencies', true);

    model.menu
      .query('orders', {
        page: {
          limit: 1,
          offset: 0
        }
      })
      .then((orders) => {
        controller.set('menuOrders', orders.toArray());
        return model.menu.query('orders', {
          page: {
            limit: 300,
            offset: 0
          },
          filter: {
            upcoming: true
          }
        });
      })
      .then((orders) => controller.set('activeOrders', orders.toArray()))
      .finally((_) => controller.set('isCheckingMenuDependencies', false));
  }

  @action
  willTransition(transition) {
    const menu = this.modelFor('logged-in.restaurants.show.menus.show').menu;

    if (
      menu.get('needsSave') &&
      !confirm('You have unsaved changes, are you sure you want to leave?')
    ) {
      transition.abort();
    } else {
      menu.rollbackAttributes();
      return true;
    }
  }
}
