import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { task } from 'ember-concurrency';

@classic
export default class MenuFilter extends Component {
  /** @type {Order} */
  order = null;

  /** @type {boolean} */
  isDisabled = false;

  /** @type {MealPlanningRequirement} */
  @alias('order.menuFilter.content')
  menuFilter;

  resetSearch() {}

  @task(function* () {
    yield this.get('order').belongsTo('menuFilter').reload();
  })
  openMenuFilerTask;

  @task(function* () {
    yield this.get('order').addMenuFilter();
    this.set('menuFilter', this.get('order.menuFilter.content'));
  })
  addMenuFilterTask;

  @task(function* () {
    yield this.get('menuFilter').destroyRecord();
    this.set('menuFilter', null);
  })
  removeMenuFilterTask;
}
