import Fixture, {
  element,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';

import {
  sfFeedEvent,
  sfFeedEventWithValue
} from 'star-fox/features/components/sf/activity-feed/feed-event/fixture';

import { sfFilterToggle } from 'star-fox/features/components/sf/filters/filter-toggle/fixture';

export default class SfActivityFeedFixture extends Fixture {
  constructor(_, value, scope) {
    super('data-test-sf-activity-feed', value, scope);
  }

  @element headerVersionSlug;
  @element headerVersionSlugIcon;
  @element filterByEmail;
  @element search;
  @element header;
  @element({ selector: 'data-test-sf-activity-feed__loading-more', scope: null }) loadingMore;
  @element({ selector: 'data-test-sf-activity-feed__all-caught-up', scope: null }) allCaughtUp;
  @element({ selector: 'data-test-sf-filters-filter-toggle' }) filterToggle;

  @sfFeedEvent feedEvent;
  @sfFeedEventWithValue({ selector: 'data-test-sf-activity-feed-event', scope: null })
  feedEventWithId;
  @sfFilterToggle filterToggle;
}

export const sfActivityFeed = getElementDecoratorFor(SfActivityFeedFixture);
export const sfActivityFeedWithValue = getElementWithValueDecoratorFor(SfActivityFeedFixture);
