import { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { alias, gt, not } from '@ember/object/computed';
import { computed } from '@ember/object';
import MF from 'ember-data-model-fragments';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import moment from 'moment-timezone';

export default MF.Fragment.extend({
  /*
   * Attributes
   */
  name: attr('string'),
  numberOfPeople: attr('number'),
  deliverAt: attr('time'),
  pickupAt: attr('time'),
  leadTime: attr('number'),
  hasSameDayReservations: attr('boolean', { defaultValue: false }),
  numberOfSameDayReservations: attr('number', { defaultValue: 0 }),
  slots: fragmentArray('fragments/meal-planning/slot'),
  contact: fragment('fragments/contact'),
  location: fragment('fragments/location'),
  restaurantConstraintIds: attr('array', { defaultValue: () => [] }),

  hasSlots: gt('slots.length', 0),
  hasNoSlots: not('hasSlots'),
  slotCount: alias('slots.length'),
  // isEmpty: computed.empty('slots'),

  notify: service(),

  restaurantConstraints: computed('restaurantConstraintCount', function () {
    return (this.get('restaurantConstraintIds') || []).map((id) =>
      this.store.peekRecord('meal-planning-restaurant-constraint', id)
    );
  }),

  restaurantConstraintCount: alias('restaurantConstraintIds.length'),

  async addRestaurantConstraint(constraint, template) {
    await constraint.save();
    const ids = this.get('restaurantConstraintIds');
    ids.pushObject(constraint.get('id'));
    this.set('restaurantConstraintIds', ids.toArray());
    await template.save();
    constraint.set('template', template);
  },

  async removeRestaurantConstraint(constraint, mealPlan) {
    await constraint.destroyRecord();
    this.get('restaurantConstraintIds').removeObject(constraint.get('id'));
    return mealPlan.save();
  },

  /**
   * Adds a slot to the meal-fragment's slots fragmentArray
   */
  addSlot(attributes = {}) {
    return this.get('slots').createFragment(attributes);
  },

  /**
   * Removes a slot to the meal-fragment's slots fragmentArray
   * @type {Slot}
   */
  removeSlot(slot) {
    if (this.get('slots.length') === 1) {
      this.get('notify').warning('A meal must have at least one slot!');
      return;
    }
    this.set('slots', this.get('slots').without(slot));
  },

  /**
   * Returns the meals deliverAt date for a given dayDate and area object.
   * @param {Date} dayDate
   * @param {Day} day
   * @param {Area} area
   */
  getDeliverAtDate(dayDate, day, area) {
    const dayDateString = moment(dayDate).add(day.get('dayOfWeek'), 'days').format('YYYY-MM-DD');
    const isoTimeZone = area.get('isoTimeZone');
    return moment.tz(`${dayDateString}T${this.get('deliverAt').toString()}`, isoTimeZone).toDate();
  },

  /**
   * Returns the meals pickupAt date for a given dayDate and area object.
   * @param {Date} dayDate
   * @param {Day} day
   * @param {Area} area
   */
  getPickupAtDate(dayDate, day, area) {
    const dayDateString = moment(dayDate).add(day.get('dayOfWeek'), 'days').format('YYYY-MM-DD');
    const isoTimeZone = area.get('isoTimeZone');
    return moment.tz(`${dayDateString}T${this.get('pickupAt').toString()}`, isoTimeZone).toDate();
  }
});
