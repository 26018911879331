import classic from 'ember-classic-decorator';
import { oneWay } from '@ember/object/computed';
import { action } from '@ember/object';
import Controller from '@ember/controller';

@classic
export default class FoodTypeController extends Controller {
  queryParams = ['pageLimit', 'pageOffset', 'searchText', 'sort'];

  /** @type {number} */
  @oneWay('model.tags.meta.recordCount')
  recordCount;

  /** @type {string} searchText - text for searching the back end with */
  searchText = '';

  /** @type {number} pageLimit - records per page */
  pageLimit = 30;

  /** @type {number} pageOffset - current page */
  pageOffset = 0;

  /** @type {number} sort - sorting string */
  sort = 'name';

  @action
  onSearchChange(searchText) {
    // reset page offset on search text change due to model
    // changes
    this.setProperties({
      pageOffset: 0,
      searchText: searchText
    });
  }

  @action
  handleSortChange(sort) {
    let newSort = sort.split(',');
    if (sort[0] === '') {
      newSort = ['name'];
    }
    this.set('sort', newSort.reverse().join(','));
  }
}
