import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@classNames('fde-logistics-monitoring-notes')
export default class MonitoringNotes extends Component {
  /** @type {Order} Order obj that's passed in from the expanded-row */
  order = null;

  /** @type {string} Monitoring notes on the order */
  @alias('order.monitoringNotes')
  monitoringNotes;

  /** @type {Object} validationOpts override default order validation */
  validationOptions = { only: [] };
}
