import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import $ from 'jquery';

@classic
export default class FastForwardModal extends Component {
  /** @type {Service} */
  @service
  notify;

  /** @type {Object} the diff for this order if the new menu is used */
  diff = null;

  /** @type {Boolean} indicates if the fast-forward is in process */
  isUpdating = false;

  /** @type {Function} function that refreshes all the model keys needed for the page */
  refreshModel() {}

  /** @type {Object} the current order */
  order = null;

  /**
   * Computed properties
   */

  /** @type {Object[]} items of the diff */
  @alias('diff.diffItems')
  diffItems;

  @alias('order.orderItems')
  orderItems;

  /** @type {MenuOptionItem[]} all menu option items used in the order, indexed by id */
  @computed('orderItems.[]')
  get menuOptionItemsByID() {
    return this.orderItems
      .mapBy('menuOptionItems')
      .map((_) => _.toArray())
      .flat()
      .reduce((map, menuOptionItem) => {
        map[`${menuOptionItem.get('id')}`] = menuOptionItem.get('content') || menuOptionItem;
        return map;
      }, {});
  }

  /** @type {MenuItem[]} all menu items used in the order, indexed by OrderItem id */
  @computed('orderItems.@each.menuItem')
  get menuItemsByOrderItemID() {
    return this.orderItems.reduce((map, orderItem) => {
      map[`${orderItem.id}`] = orderItem.get('menuItem.content') || orderItem.get('menuItem');
      return map;
    }, {});
  }

  /** @type {Boolean} indicates if updating the menu will change the order in any way */
  @computed('diffItems')
  get orderHasChanges() {
    return (
      this.get('diffItems') &&
      this.get('diffItems').filter(
        (diffItem) => Object.keys(diffItem.changeset).length || !diffItem.valid
      ).length > 0
    );
  }

  /** @type {Boolean} indicates if fast-forward is prevented by menu items that are currently used but have been deleted */
  @computed('diffItems')
  get orderHasConflicts() {
    return (
      this.get('diffItems') &&
      this.get('diffItems').filter((diffItem) => !diffItem.valid).length > 0
    );
  }

  @action
  close() {
    $('#fde-orders-fast-forward-modal').modal('hide');
  }

  @action
  fastForward() {
    if (!this.get('hasConflicts')) {
      this.set('isUpdating', true);

      this.get('order')
        .fastForward()
        .then(() => this.refreshModel())
        .catch((errorInstance) => {
          const notifier = this.get('notify');

          if (errorInstance.errors) {
            errorInstance.errors.forEach((error) => {
              notifier.error(error.detail, { closeAfter: 0 });
            });
          }
        })
        .finally(() => this.send('close') && this.set('isUpdating', false));
    }
  }
}
