import classic from 'ember-classic-decorator';
// app/transforms/object.js
import Transform from '@ember-data/serializer/transform';
import EmberObject from '@ember/object';

const VehicleObj = {
  none: EmberObject.create({
    icon: '',
    id: 'none',
    label: 'None'
  }),
  small_car: EmberObject.create({
    icon: 'car',
    id: 'small_car',
    label: 'Small Car'
  }),
  regular_car: EmberObject.create({
    icon: 'bus',
    id: 'regular_car',
    label: 'Regular Car'
  }),
  large_car: EmberObject.create({
    icon: 'shipping',
    id: 'large_car',
    label: 'Large Car'
  }),
  bicycle: EmberObject.create({
    icon: 'bicycle',
    id: 'bicycle',
    label: 'Bicycle'
  })
};

// Normally I would Object#keys this but that doesn't appear to be available
export const VehicleTypes = Object.keys(VehicleObj).map((_) => VehicleObj[_]);

/**
 * Phone is a type of either iOS/Android/None we make sure that it uses None
 */
@classic
export default class Vehicle extends Transform {
  deserialize(value) {
    return VehicleObj[value || 'none'];
  }

  serialize(value) {
    return value && value.id ? value.id : 'none';
  }
}
