import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@classNames('fde-invoices-display-invoice')
export default class DisplayInvoice extends Component {
  /** @type {ClientOrderInvoice ||  RestaurantOrderInvoice}*/
  invoice = null;

  @computed('invoice')
  get summary() {
    return this.get('invoice.summary.oldSummary') || this.get('invoice.summary');
  }

  /** @type {Order} */
  order = null;

  /** @type {boolean} */
  @equal('invoiceType', 'restaurant')
  isRestaurantInvoice;

  /** @type {boolean} */
  @computed('order', 'order.orderType', 'isRestaurantInvoice')
  get isShowingTeamInvoice() {
    const isTeamType = this.get('order.orderType') === 'team';
    const isClientInvoice = !this.get('isRestaurantInvoice');

    return isTeamType && isClientInvoice;
  }
}
