import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class PaymentCard extends Application {
  attrs = {
    billingAddress: {
      serialize: false
    },

    shared: {
      serialize: false
    },

    cardType: {
      serialize: false
    },

    cvc: {
      serialize: false
    },

    expiryMonth: {
      serialize: false
    },

    expiryYear: {
      serialize: false
    },

    lastFourDigits: {
      serialize: false
    },

    nameOnCard: {
      serialize: false
    },

    number: {
      serialize: false
    },
    addressLine_1: {
      serialize: false
    },
    addressLine_2: {
      serialize: false
    },
    city: {
      serialize: false
    },
    province: {
      serialize: false
    },
    country: {
      serialize: false
    },
    addressCode: {
      serialize: false
    },
    inUseAndInvalid: {
      serialize: false
    },
    expired: {
      serialize: false
    },
    expiresOnFutureDelivery: {
      serialize: false
    }
  };
}
