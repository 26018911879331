import $ from 'jquery';

/**
 * Downloads the provided urls
 *
 * @param {string[]} urls
 */
export default function (urls) {
  urls.forEach((url) => {
    const iframe = $('<iframe style="visibility: collapse;"></iframe>');
    $('body').append(iframe);

    const content = iframe[0].contentDocument;
    const form = `<form action="${url}" method="GET"></form>`;
    content.write(form);
    $('form', content).submit();

    setTimeout(
      (function (iframe) {
        return function () {
          iframe.remove();
        };
      })(iframe),
      10000
    );
  });
}
