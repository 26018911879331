import classic from 'ember-classic-decorator';
import Model, { attr } from 'renard/models/foodee';
@classic
export default class ReleaseNote extends Model {
  @attr('boolean')
  active;

  @attr('string')
  subject;

  @attr('string')
  body;

  @attr('string')
  author;

  @attr('date')
  updatedAt;

  @attr('date')
  createdAt;

  validations = {
    subject: {
      presence: true
    },
    body: {
      presence: true
    }
  };
}
