import config from 'star-fox/config/environment';

export function initialize(appInstance) {
  const actionCable = appInstance.lookup('service:action-cable');
  actionCable.cableHost = config.CABLE_HOST;
}

export default {
  initialize
};
