import Controller from 'star-fox/features/application/abstract-controller';
import { inject as service } from '@ember/service';
import { alias, sort, union } from '@ember/object/computed';
import $ from 'jquery';
import { isEqual } from '@ember/utils';
import EmberObject, { action, computed, observer } from '@ember/object';
import moment from 'moment-timezone';
import {
  LOGS_ORDER_FIELDS,
  LOGS_ORDER_INCLUDES
} from 'star-fox/features/logged-in/logistics/index/route';
import PusherHandlersMixin, { PUSHER_DEBOUNCE_TIME } from 'star-fox/mixins/pusher-handlers-mixin';
import uniqueDebounce from 'star-fox/utils/unique-debounce';
import { OrderState } from 'renard/transforms/order-state';
import { CableEvents } from 'renard/services/action-cable';

export default Controller.extend(PusherHandlersMixin, {
  pusherModelQueryParams: {
    Order: {
      include: LOGS_ORDER_INCLUDES,
      fields: LOGS_ORDER_FIELDS
    }
  },

  isOrderEditRoute: computed('router.currentRouteName', function () {
    return this.get('router.currentRouteName').includes('edit-order');
  }),

  isActive: computed('router.currentRouteName', function () {
    return this.get('router.currentRouteName').includes('logged-in.logistics.index');
  }),

  backRoute: 'logged-in.logistics',

  /** @type {Service} */
  ajax: service(),

  /** @type {Service} DriverNotificationService */
  driverNotification: service(),

  /** @type {Service} */
  notify: service(),

  /** @type {Service} */
  modal: service(),

  /** @type {Service} */
  router: service(),

  /** @type {Object} */
  meta: null,

  /**
   * Query params for controller.
   */
  queryParams: [
    'areas',
    'couriers',
    'deliverOn',
    'groupByDriver',
    'pageLimit',
    'pageOffset',
    'reverseSort',
    'sortBy',
    'expanded',
    'hide'
  ],

  /** @type {Number[]} ids of the expanded rows */
  expanded: [],

  /** @type {Number} number of records to offset list */
  pageOffset: 0,

  /** @type {Number} number to limit page records before a new page */
  pageLimit: 300,

  refreshingOrders: false,

  /** @type {String} Filter orders by courier */
  couriers: null,

  /** @type {String} Filter orders by courier */
  areas: null,

  /** @type {String} Filter orders by date */
  deliverOn: moment().format('YYYY-MM-DD'),

  /** @type {Area[]} */
  allAreas: alias('model.areas'),

  /** @type {string} */
  hide: '',

  hideable: [
    {
      id: 'isDelivered',
      value: 'isDelivered',
      label: 'Delivered'
    },
    {
      id: 'isFoodeeServed',
      value: 'isFoodeeServed',
      label: 'Foodee Served Order'
    },
    {
      id: 'isClientDemo',
      value: 'isClientDemo',
      label: 'Client Demo'
    },
    {
      id: 'isRestaurantDemo',
      value: 'isRestaurantDemo',
      label: 'Restaurant Demo'
    },
    {
      id: 'pin',
      value: 'pin',
      label: 'Pin'
    }
  ].sortBy('label'),

  /**
   * Will be true if the model is still loading.
   * @default false
   * @type {boolean}
   */
  isLoading: false,

  /**
   * Will be true if the remaining outstanding orders fetch is not complete. Default needs to be
   * true since the fetch is run parallele (and outside) of the model->setupController run loop.
   * @default true
   * @type {boolean}
   */
  isRemainingOrdersLoading: false,

  showRemainingOrdersLoading: computed('isLoading', 'isRemainingOrdersLoading', function () {
    return !this.get('isLoading') && this.get('isRemainingOrdersLoading');
  }),

  /**
   * @type {string[]}
   */
  pusherChannels: computed('model', function () {
    const areaFilter = (
      (this.filterCollection && this.filterCollection.selectedFilters) ??
      []
    ).findBy('id', 'areas');
    let channels;

    if (areaFilter && areaFilter.value) {
      const allAreas = this.get('allAreas') || [];
      channels = allAreas
        .filter((a) => areaFilter.value.includes(a.get('city')))
        .map((a) => [
          `ee.food.areas.${a.get('id')}.orders.model-events`,
          `ee.food.areas.${a.get('id')}.orders.order-events`
        ])
        .flat();
    } else {
      channels = ['ee.food.areas.all.orders.model-events', 'ee.food.areas.all.orders.order-events'];
    }
    return channels;
  }),

  pusherEvents: [
    'created',
    'updated',
    'delivery-allocated',
    'delivery-deallocated',
    'estimate-updated',
    CableEvents.Connected
  ],

  /**
   * First batch of orders to display, used to help make the page load as fast as possible.
   * @type {Order[]}
   */
  firstPageOfOrders: null,

  /**
   * The remaining batch of orders, loaded at the same time as the first batch, will get coalesced
   * with other orders
   * @type {Array}
   */
  remainingOrders: null,

  /**
   * Orders that were retreived as a result of pusher.io notifications
   * @type {Array}
   */
  fetchedOrders: null,

  /** @type {Array} */
  areasToNotify: [],

  /**
   * All orders from all sources coalesced
   * @type {Array}
   */
  allOrders: union('firstPageOfOrders', 'remainingOrders', 'fetchedOrders'),

  /**
   * Orders sorted and filtered
   * @type {Array}
   */
  sortedOrders: sort('allOrders', 'sortDefinition'),

  /**
   * Couriers sorted by name (asc)
   * @type {Couriers[]}
   */
  couriersSortingDesc: ['name:asc'],
  activeCouriers: computed('model.couriers.@each.active', function () {
    return this.get('model.couriers').filterBy('active', true);
  }),
  sortedActiveCouriers: sort('activeCouriers', 'couriersSortingDesc'),

  /**
   * Areas sorted by name (asc)
   * @type {Areas[]}
   */
  areasSortingDesc: ['city:asc'],
  sortedAreas: sort('model.areas', 'areasSortingDesc'),

  /**
   * Orders sorted and filtered
   * @type {Array}
   */
  sortedFilteredOrders: computed('sortedOrders', 'hide', 'sortedOrders.@each.state', function () {
    const hide = this.hide ?? '';
    const hasDelivered = hide.includes('isDelivered');
    const hideFoodeeServed = hide.includes('isFoodeeServed');
    const hideClientDemo = hide.includes('isClientDemo');
    const hideRestaurantDemo = hide.includes('isRestaurantDemo');

    return this.sortedOrders
      .filter((_) => (hasDelivered ? !_.state.hasBeenDelivered : true))
      .filter((_) => (hideFoodeeServed ? !_.isFoodeeServed : true))
      .filter((_) => (hideClientDemo ? !_.isClientDemo : true))
      .filter((_) => (hideRestaurantDemo ? !_.isRestaurantDemo : true));
  }),

  /**
   * Sort settings
   * @type {string}
   */
  sortBy: 'deliverAt',

  reverseSort: false,

  /** @property {boolean} Whether the orders should be grouped by which driver is doing them */
  groupByDriver: false,

  /** @type {boolean} Controls the Notify All modal */
  showNotifyAllModal: false,

  /** @type {DeskCase} new DeskCase obj */
  deskCase: null,

  /** @type {boolean} */
  isNotifyAllDisabled: computed('areasToNotify.[]', 'isLoading', function () {
    return this.get('areasToNotify.length') === 0 || this.get('isLoading');
  }),

  /** @type {string} */
  notifyAllButtonClasses: computed('isNotifyAllDisabled', function () {
    return this.get('isNotifyAllDisabled') ? 'disabled' : null;
  }),

  /**
   * Orders sorted and filtered by drivers
   * @type {Array}
   */
  ordersByDriver: computed(
    'sortedFilteredOrders.@each.driver',
    'sortedFilteredOrders.@each.courier',
    function () {
      const makeLabel = function (driverLabel, driverId = null, driverLastNotifiedAt = null) {
        return EmberObject.create({
          driverLabel,
          driverId,
          driverLastNotifiedAt
        });
      };

      const orders = this.get('sortedFilteredOrders');

      const unassigned = 'Unassigned';
      const ordersByLabel = orders.reduce((acc, order) => {
        const driverName = order.get('driver.fullName');
        let key;

        if (driverName) {
          key = driverName;
        } else {
          if (order.get('logisticsType') === 'auto') {
            key = order.get('courier.name');
          } else {
            key = unassigned;
          }
        }

        acc[key] = acc[key] ? acc[key] : [];
        acc[key].push(order);
        return acc;
      }, {});

      const groupedOrders = ordersByLabel[unassigned]
        ? [makeLabel(unassigned), ...ordersByLabel[unassigned]]
        : [];

      Object.keys(ordersByLabel).forEach((label) => {
        if (label !== unassigned) {
          const orders = ordersByLabel[label];
          const order = orders.get('firstObject');
          const driverId = order.get('driver.id');
          const driverLastNotifiedAt = order.get('driver.lastDriverNotificationSentAt');

          groupedOrders.push(
            makeLabel(label, driverId, moment(driverLastNotifiedAt).format('MMM D ddd h:mm A'))
          );

          groupedOrders.push(...orders);
        }
      });

      return groupedOrders;
    }
  ),

  sortDefinition: computed('sortBy', 'reverseSort', 'groupByDriver', function () {
    const sortBy = this.get('sortBy');
    const sortingArray = this.get('groupByDriver') ? ['driver.fullName'] : [];
    const sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
    sortingArray.push(`${sortBy}:${sortOrder}`);
    sortingArray.push(sortBy === 'deliverAt' ? 'id' : 'deliverAt');
    return sortingArray;
  }),

  /**
   * Collapse all orders on sort change
   */
  onSortDefinitionChange: observer('sortDefinition', function () {
    const orders = this.get('allOrders');
    orders.forEach((order) => {
      order.set('expandRow', false);
    });
  }),

  currentDate: computed('deliverOn', function () {
    return moment(this.get('deliverOn')).format('YYYY-MM-D');
  }),

  isToday: computed('deliverOn', function () {
    const today = moment().format('YYYY-MM-D');
    return this.get('currentDate') === today;
  }),

  /** @type {date} Start of the week of the selected date */
  startOfWeek: computed('deliverOn', function () {
    // isoweek instead of startOfWeek starts the week on a monday :)
    return moment(this.get('deliverOn')).startOf('isoweek');
  }),

  /** @type {Object[]} */
  daysOfWeek: computed('meta.orderWeekInfo', function () {
    const startOfWeek = this.get('startOfWeek');
    const orderWeekInfo = this.get('meta.orderWeekInfo');

    return new Array(7).fill('').map((_, i) => {
      const day = moment(startOfWeek).add(i, 'day').format();
      const dayMeta = orderWeekInfo[i.toString()];

      return EmberObject.create({
        date: moment(day),
        info: `${dayMeta.driverAssignedCount}/${dayMeta.allCount}`
      });
    });
  }),

  /**
   * Reset DeskCase obj after save and set caseStatus to 'open'
   */
  resetNewDeskCase() {
    this.set(
      'newDeskCase',
      this.store.createRecord('desk-case', {
        caseStatus: 'open'
      })
    );
  },

  _refreshOrder(id) {
    if (this.isActive) {
      this.send('refreshOrder', id);
    }
  },

  /**
   * @param {number} id
   * @private
   */
  _updated(id, message = 'Updated event send.') {
    console.debug(`${moment().format('hh:mm:ss ')} [${this.pubSub.name}] ${message}`, id);
    this._refreshOrder(id);
  },

  /**
   * @param {number} id
   * @private
   */
  _created(id) {
    this._refreshOrder(id);
  },

  fetchOrder: action(function (id) {
    this._refreshOrder(id);
  }),

  nextWeek: action(function () {
    const deliverOn = this.get('deliverOn');
    const value = moment(deliverOn).add(7, 'days').toDate();
    const nextWeek = moment(value).format('YYYY-MM-DD');

    this.set('deliverOn', nextWeek);
  }),

  lastWeek: action(function () {
    const deliverOn = this.get('deliverOn');
    const value = moment(deliverOn).subtract(7, 'days').toDate();
    const lastWeek = moment(value).format('YYYY-MM-DD');

    this.set('deliverOn', lastWeek);
  }),

  updated: action(function (data) {
    console.debug(
      `${moment().format('hh:mm:ss ')} [${this.pubSub.name}] Updated event received.`,
      data
    );

    if (data.type === 'Order') {
      uniqueDebounce(this, this._updated, data.id, PUSHER_DEBOUNCE_TIME);
    }
  }),

  created: action(function (data) {
    if (data.type === 'Order') {
      uniqueDebounce(this, this._created, data.id, PUSHER_DEBOUNCE_TIME);
    }
  }),

  changed: action(function (data) {
    // allOrders
    // eslint-disable-next-line no-console
    console.log(data);
  }),

  deliveryAllocated: action(function (data) {
    uniqueDebounce(this, this._updated, data.order_id, 'Delivery allocated', PUSHER_DEBOUNCE_TIME);
  }),

  deliveryDeallocated: action(function (data) {
    uniqueDebounce(
      this,
      this._updated,
      data.order_id,
      'Delivery Deallocated',
      PUSHER_DEBOUNCE_TIME
    );
  }),

  orderStateChanged: action(async function ({ order_id, state, picked_up_at, delivered_at }) {
    const order = this.store.peekRecord('order', order_id);

    if (order) {
      console.info(
        `${moment().format('hh:mm:ss ')} [${
          this.pubSub.name
        }] Updating Order State to ${state} for order ${order_id}`
      );
      order.state = OrderState.valueFor(state);
      order.pickedUpAt = picked_up_at;
      order.deliveredAt = delivered_at;
    }
  }),

  estimateUpdated: action(async function ({ order_id, pickup_estimate, delivery_estimate }) {
    const estimate = pickup_estimate || delivery_estimate;
    console.info(
      `${moment().format('hh:mm:ss ')} [${
        this.pubSub.name
      }] Estimate updated for Order: ${order_id}`,
      estimate
    );
    const order = this.store.peekRecord('order', order_id);

    if (order) {
      console.info(
        `${moment().format('hh:mm:ss ')} [${
          this.pubSub.name
        }] Reloading estimate for Order ${order_id}`
      );
      order.reloadWith('arrival-estimate');
    }
  }),

  setDate: action(function (date) {
    this.set('deliverOn', moment(date).format());
  }),

  sortByColumn: action(function (column) {
    if (isEqual(column, this.get('sortBy'))) {
      this.toggleProperty('reverseSort');
    }
    this.set('sortBy', column);
  }),

  collapseExpandedRows: action(function () {
    this.get('allOrders').setEach('expandRow', false);
  }),

  decrementDate: action(function () {
    const yesterday = moment(this.get('deliverOn')).subtract('1', 'days').format();
    this.set('deliverOn', yesterday);
  }),

  selectToday: action(function () {
    if (this.get('isToday')) {
      return;
    }

    const today = moment().format();
    this.set('deliverOn', today);
  }),

  selectDate: action(function (value) {
    this.set('deliverOn', moment(value).format('YYYY-MM-DD'));
  }),

  incrementDate: action(function () {
    const tomorrow = moment(this.get('deliverOn')).add('1', 'days').format();
    this.set('deliverOn', tomorrow);
  }),

  updateSelectedDriverOnOrder: action(function (driverId, order) {
    const driver = this.store.peekRecord('user', driverId);
    const courier = (driver && driver.get('couriers.firstObject')) || null;

    // lets get a clean copy of the order
    order.reload().then(() => {
      order.setProperties({
        driver,
        courier
      });

      const orderIdentifier = order.get('identifier');

      order
        .save()
        .then(() => {
          const message = driver
            ? `${driver.get('fullName')} assigned to ${orderIdentifier}.`
            : `Driver deassigned from ${orderIdentifier}.`;
          this.get('notify').success(message);
        })
        .catch(() =>
          this.get('notify').error(
            `There was a problem assigning driver ${
              driver && driver.get('fullName')
            } to ${orderIdentifier} please try again.`
          )
        );
    });
  }),

  resetDeskCase: action(function () {
    this.resetNewDeskCase();
  }),

  hideNotifyAllModal: action(function () {
    $('#logistics-notify-all').modal({ context: '.ember-application' }).modal('hide');
  }),

  addAreaToNotify: action(function (area) {
    this.get('areasToNotify').addObject(area);
  }),

  removeAreaToNotify: action(function (area) {
    this.get('areasToNotify').removeObject(area);
  }),

  triggerDriverNotification: action(function (driverData) {
    const { driverId, driverLabel } = driverData;
    const isLoading = this.get('isLoading');
    const notify = this.get('notify');

    if (!isLoading) {
      this.set('isLoading', true);

      this.get('driverNotification')
        .notifyDriver(driverId)
        .then(() => notify.success(`Push notification sent to ${driverLabel}`))
        .catch(() => notify.error(`Failed to send notification to ${driverLabel}`))
        .finally(() => this.set('isLoading', false));
    }
  }),

  triggerAreasNotification: action(function () {
    if (this.get('isLoading')) {
      return;
    }

    this.send('hideNotifyAllModal');
    this.set('isLoading', true);

    const selectedAreas = this.get('areasToNotify');
    const courierIds = selectedAreas.reduce((acc, area) => {
      const courierIdsArr = area.get('couriers').mapBy('id');

      courierIdsArr.forEach((id) => acc.push(id));
      return acc;
    }, []);

    const notify = this.get('notify');

    this.get('driverNotification')
      .notifyCouriers(courierIds)
      .then(() => notify.success('Push notifications were sent!'))
      .catch(() => notify.error('Failed to send push notifications'))
      .finally(() => {
        selectedAreas.clear();
        this.set('isLoading', false);
      });
  }),

  reloadDeliveries: action(function () {
    this.send('refreshModel');
  }),

  handleRowExpand: action(function (order) {
    this.get('expanded').pushObject(order.get('id'));
  }),

  handleRowCollapse: action(function (order) {
    this.get('expanded').removeObject(order.get('id'));
  }),

  displayNotifyAllModal: action(function () {
    $('#logistics-notify-all').modal({ context: '.ember-application' }).modal('show');
  }),

  handleFilterApply: action(function () {
    this.send('setQueryParamsFromFilterCollection');
  }),

  [CableEvents.Connected.methodName]: action(function (reconnected) {
    if (reconnected) {
      this.send('refreshModel');
    }
  })
});
