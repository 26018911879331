import classic from 'ember-classic-decorator';
import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class MealPlanningPreferenceProfileRoute extends Route.extend(CapabilityRouteMixin) {
  userShowRoute = 'logged-in.users.show';

  /**
   * @param {Object} _params
   *
   * @returns {Promise} Client and MealPlanningTemplates
   */
  model(_params) {
    const user = this.modelFor(this.userShowRoute);

    return RSVP.hash({ user });
  }

  afterModel(_model) {}

  /** @override */
  setupController(controller, model) {
    super.setupController(controller, model);
  }
}
