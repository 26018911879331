import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import $ from 'jquery';

/**
 * Repeats n times a (block) operation.
 */
@classic
@classNames('fde-layouts-split-view-divider-view')
@classNameBindings('_mouseDown:mouse-down')
export default class DividerView extends Component {
  dividingSide = 'left';

  /**
   * @type {boolean} whether or not the mouse has been pressed
   * needs to be tracked so we can scrub the view when we are no longer the mouse responder
   *
   **/
  _mouseDown = false;

  mouseDown(e) {
    //Prevent's the selection of text
    e.preventDefault();

    this.set('_mouseDown', true);
    this.set('splitView.isDragging', true);
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    // bind these so we can attach them to the body
    this._mouseMove = this._mouseMove.bind(this);
    this._mouseUp = this._mouseUp.bind(this);

    // We listen to the body for some mouse events
    $('body').on('mousemove', this._mouseMove);
    $('body').on('mouseleave', this._mouseUp);
    $('body').on('mouseup', this._mouseUp);
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    // clean up after our selves
    $('body').off('mousemove', this._mouseMove);
    $('body').off('mouseleave', this._mouseUp);
    $('body').off('mouseup', this._mouseUp);
  }

  /**
   * Used to connect with the split view to jump to the next stop
   */
  nextStop() {}

  /**
   * Used to connect with the split view to jump to the prev stop
   */
  prevStop() {}

  _mouseUp(_e) {
    this.set('_mouseDown', false);
    this.set('lastClientPos', null);
    this.set('splitView.isDragging', false);
    this.get('splitView').onDragComplete(this.get('splitView.value'));
  }

  _mouseMove(e) {
    if (!this.get('_mouseDown')) {
      return;
    }

    const lastClientPos = this.get('lastClientPos');
    const isVertical = this.get('splitView.isVertical');
    const clientPos = isVertical ? e.clientY : e.clientX;

    const inverseDeltaCoeff = this.get('dividingSide') === 'right' ? -1 : 1;

    if (lastClientPos) {
      const delta = (clientPos - lastClientPos) * inverseDeltaCoeff;

      // if we're doing percentage based views we need to do a bit of math
      // to figure out what percent of the view a change by one pixel actually
      // means, and then rationalize the change due to the current drag in terms
      // of percentage otherwise we can just use the pixel distance
      if (this.get('splitView.percentageMode')) {
        const $parent = this.$().parent();
        const boundingSize = isVertical ? $parent.height() : $parent.width();
        const pixelToPercentageRatio = (1 / boundingSize) * 100;

        this.get('splitView').dragDividerBy(delta * pixelToPercentageRatio);
      } else {
        this.get('splitView').dragDividerBy(delta);
      }
    }

    this.set('lastClientPos', clientPos);
  }
}
