import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';

@classic
export default class FeedRoute extends Route {
  orderEditRoute = 'logged-in.orders.edit';

  model(_params) {
    return this.modelFor(this.get('orderEditRoute')).order;
  }
}
