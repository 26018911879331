import { helper as buildHelper } from '@ember/component/helper';

export function joinedUserHasOrdered([user = null, joinedUsersMeta = null]) {
  if (user && joinedUsersMeta) {
    const userId = user.get('id');

    return joinedUsersMeta['usersWithOrders'].indexOf(parseInt(userId)) > -1;
  }
}

export default buildHelper(joinedUserHasOrdered);
