import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import { isArray } from '@ember/array';

@classic
@classNames('fde-filters-applied-filters')
@classNameBindings('isDark:fde-is-dark', 'hasFilters::fde-has-models')
export default class AppliedFilters extends Component {
  /** @type {boolean} */
  isDark = true;

  /** @type {?string} comma-separated list of filters */
  filterParam = null;

  /** @type {?Model[]} */
  selectedModels = null;

  /** @type {string} */
  labelKey = 'name';

  /** @type {?string[]} */
  @computed('filterParam')
  get appliedFilterParams() {
    const filterParam = this.get('filterParam');
    if (!filterParam) {
      return undefined;
    }

    return filterParam.split(',').reduce((acc, filter) => {
      if (isPresent(filter)) {
        acc.push(filter);
      }
      return acc;
    }, []);
  }

  /** @type {string[]|Model[]} */
  @computed('selectedModels.[]', 'appliedFilterParams.[]', 'hasFilters')
  get appliedItems() {
    return this.get('hasFilters') ? this.get('appliedFilterParams') : this.get('selectedModels');
  }

  /** @type {string[]|Model[]} */
  @computed('selectedModels', 'appliedFilterParams')
  get hasFilters() {
    const appliedFilterParams = this.get('appliedFilterParams');

    return isArray(appliedFilterParams);
  }

  onRemoveFilter() {}

  /**
   * @param {string} filter
   * @private
   */
  _removeFilter(filter) {
    console.debug('sf.filters.filter-select#_removeFilter', filter);

    const appliedFilterParams = this.get('appliedFilterParams') || [];
    const filterIndex = appliedFilterParams.indexOf(filter);
    appliedFilterParams.splice(filterIndex, 1);
    this.onRemoveFilter(appliedFilterParams.join(','));
  }

  /**
   * @param {Model} model
   * @private
   */
  _removeModel(model) {
    console.debug('sf.filters.filter-select#_removeModel', model);

    const selectedModels = this.get('selectedModels');
    selectedModels.removeObject(model);

    this.onRemoveFilter(selectedModels);
  }

  @action
  removeFilter(filter) {
    if (this.get('hasFilters')) {
      this._removeFilter(filter);
    } else {
      this._removeModel(filter);
    }
  }
}
