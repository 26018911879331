import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

import { action } from '@ember/object';

@classic
@tagName('')
export default class TableBody extends Component {
  /** @type {boolean} */
  readonly = false;

  /** @type {DS.Model} the model which is the source of the collection */
  model = null;

  /** @type {string} the key which points to the collection on the model */
  collectionKey = '';

  @action
  updateValueAndSave(values) {
    const model = this.get('model');

    model.set(this.get('collectionKey'), values);
    model.save();
  }
}
