import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@tagName('')
export default class ProfileImageControl extends Component {
  /** @type {string} url of cover image */
  value = null;

  /** Change handler */
  onChange() {}
}
