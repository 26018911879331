import Route from '@ember/routing/route';
import RSVP from 'rsvp';

export default class LoggedInRestaurantsShowUsersShowIndexRoute extends Route {
  /** @override */
  templateName = 'logged-in.users.show.index';

  model(_params) {
    const user = this.modelFor('logged-in.restaurants.show.users.show');

    return RSVP.hash({
      user,
      roles: this.store.findAll('role')
    });
  }
}
