import Helper from '@ember/component/helper';
import { htmlSafe } from '@ember/template';

import SprintfJS from 'sprintf-js';

const { sprintf } = SprintfJS;

export function sprintfHelper(args) {
  return htmlSafe(sprintf(...args));
}

export default Helper.helper(sprintfHelper);
