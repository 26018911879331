import { helper as buildHelper } from '@ember/component/helper';

/**
 * Helper to return the first truthy value.
 * @param Array.<*> params
 * returns {*}
 */
export function coalesce(params) {
  return params.reduce((prev, curr) => prev ?? curr, undefined);
}

export default buildHelper(coalesce);
