import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { object, func } from 'prop-types';
import { alias } from 'macro-decorators';
import { action } from '@ember/object';

export default class ConfirmComponent extends Component {
  @arg(object.isRequired) data;
  @arg(func.isRequired) close;

  @alias('data.header') header;
  @alias('data.body') body;

  @action
  confirm() {
    this.close(true);
  }
}
