import Controller from 'star-fox/features/application/abstract-controller';
import EmberObject, { action, computed } from '@ember/object';

export default Controller.extend({
  /** @type {boolean} */
  didReset: false,

  /**
   * Creates a false model for us to use in the form-for
   */
  credentials: EmberObject.create({
    /** @type {string} email*/
    email: '',

    /**
     * Prevents form-for from trying to submit when onEnter is triggered
     * and an email has not been provided
     * @returns {boolean}
     */
    validate() {
      return !!this.get('email');
    }
  }),

  /** @type {boolean} canLogin  */
  canReset: computed('credentials.email', function () {
    return !!this.get('credentials.email');
  }),

  reset: action(function () {
    return this.get('session')
      .forgotPassword(this.get('credentials.email'))
      .then(() => this.set('didReset', true));
  })
});
