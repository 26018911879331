import { computed } from '@ember/object';

/**
 * Checks whether the two computed attributes match
 * @param {string} firstAttr
 * @param {string} secondAttr
 * @returns {Ember.computed.<boolean>} A computed property
 */
export default function isSameAs(firstAttr, secondAttr) {
  return computed(firstAttr, secondAttr, function () {
    return this.get(firstAttr) === this.get(secondAttr);
  });
}
