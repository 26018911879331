import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

@classic
export default class Notify extends Helper {
  /** @type {Service} */
  @service
  notify;

  /**
   * @param {string} message the message to display
   * @param {object} messageType the type of message we're displaying
   */
  compute([message, messageType = 'info', duration = 4000]) {
    return (_) =>
      this.get('notify')[messageType](message, {
        closeAfter: duration
      });
  }
}
