import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';

import { action } from '@ember/object';

import { task } from 'ember-concurrency';

@classic
export default class MealPlanningPreferenceProfileController extends Controller {
  /** @type {Object} */
  model = null;

  activePreferenceTab = 'Team';
  queryParams = ['activePreferenceTab'];

  /** @type {task} */
  @(task(function* () {
    yield this.get('model.client').createPreferenceProfile();
    yield this.get('model.client').belongsTo('preferenceProfile').reload();

    this.set('model.preferenceProfile', this.get('model.client.preferenceProfile.content'));
  }).drop())
  createPreferenceProfileTask;

  @action
  handleCreatePreferenceProfileClick() {
    this.get('createPreferenceProfileTask').perform();
  }
}
