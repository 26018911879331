import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@tagName('')
export default class TabMenu extends Component {
  uiClasses = 'top attached tabular menu';

  /** @type {boolean} whether or not the tab is full screen */
  isFullScreen = false;

  toggleFullScreen() {}
}
