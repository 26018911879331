import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { notEmpty } from '@ember/object/computed';
import { guidFor } from '@ember/object/internals';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import moment from 'moment';

@classic
@tagName('')
export default class WeeklyReservationEmail extends Component {
  /** @type {Notify} */
  @service
  notify;

  /** @type {NotificationLogsService} */
  @service('notification-logs')
  notificationLogsService;

  /** @type {?Area} */
  area = null;

  /** @type {Restaurant[]} */
  restaurants = null;

  /** @type {?Restaurant} */
  selectedRestaurant = null;

  /** @type {String} */
  passphrase = 'I want to spam restaurants again';

  /** @type {String} */
  passphraseEntered = '';

  /** @type {Boolean} Checks if the entered passphrase matches the required passphrase */
  @computed('passphraseEntered')
  get passphraseMatch() {
    return this.get('passphraseEntered') === this.get('passphrase');
  }

  /** @type {Boolean} If there's a notification log then we show a warning */
  @notEmpty('notificationLogs.[]')
  hasEmailBeenSent;

  /** @type {NotificationLog.[]} */
  notificationLogs = [];

  @computed('hasEmailBeenSent', 'passphraseMatch')
  get cannotResendEmail() {
    return this.get('hasEmailBeenSent') && !this.get('passphraseMatch');
  }

  @computed('sendToAllRestaurants', 'selectedRestaurant')
  get cannotSendSingleEmail() {
    return !this.get('sendToAllRestaurants') && !this.get('selectedRestaurant');
  }

  @computed('cannotSendSingleEmail', 'cannotResendEmail', 'isLoading')
  get isSendingEmailDisabled() {
    return (
      this.get('cannotResendEmail') || this.get('isLoading') || this.get('cannotSendSingleEmail')
    );
  }

  /** @type {?Date} */
  monday = null;

  /** @type {?String} */
  @computed('monday')
  get weekRangeString() {
    const monday = this.get('monday');
    const dateFormat = 'MMM Do';

    return `${moment(monday).format(dateFormat)} and ${moment(monday)
      .add(6, 'days')
      .format(dateFormat)}`;
  }

  /** @type {string} Finds the unique Id of the ember modal. Ex Ember-177-client-mail-modal */
  mealPlanningWeeklyReviewEmailModalId = `${guidFor(this)}-meal-planning-weekly-review-email-modal`;

  /** @type {Boolean} Set to true when awaiting for a response */
  isLoading = false;

  /** @type {Boolean} When `true`, the email modal is showing */
  isShowing = false;

  /** @type {Boolean} Determines if the button that opens the email modal is clickable */
  isDisabled = false;

  /** @type {Boolean} Determines if emails are to be sent to all restaurants in the area */
  sendToAllRestaurants = true;

  /** @type {Boolean} Determines if we should show a confirmation message when sending out emails for the current week */
  @computed('monday')
  get isCurrentWeek() {
    const monday = moment(this.get('monday')).startOf('day');
    const sunday = moment(monday).add(6, 'days').endOf('day');
    return moment().isBetween(monday, sunday, undefined, '[]');
  }

  /** @type {Boolean} When `true`, the user has confirmed they would like to send out single-restaurant emails for the current week */
  isEmailsForCurrentWeekConfirmed = false;

  /** @type {String} Placeholder text for the restaurant model-select input */
  @computed('sendToAllRestaurants')
  get restaurantSelectMessage() {
    return this.get('sendToAllRestaurants')
      ? 'Sending to all restaurants in the area'
      : 'Select a restaurant';
  }

  /**
   * Called after a the weekly reservation modal is set to be shown
   *
   * @private
   */
  _fetchNotificationLogs() {
    this.set('isLoading', true);

    const areaId = this.get('area.id');
    const receiver = `${areaId},Area`;

    const uniquenessContext = moment(this.get('monday')).format('YYYY-MM-DD');

    return this.get('notificationLogsService')
      .fetch(uniquenessContext, receiver)
      .then((notificationLogs) => this.set('notificationLogs', notificationLogs))
      .catch((error) => this._handleNotificationFetchError(error))
      .finally(() => this.set('isLoading', false));
  }

  /**
   * Called after a successful response from _sendWeeklyReservationsEmails.
   * This sets various properties to empty strings and nulls to "clean up."
   *
   * @private
   */
  _handleSendingEmailsSuccess(message) {
    console.debug(message);
    this.get('notify').success(message);
    this.set('isShowing', false);
  }

  /**
   * Called after an unsuccessful response from _sendWeeklyReservationsEmails.
   * Notifies the user of the encountered error.
   *
   * @private
   */
  _handleSendingEmailsError(error) {
    const errorMessage = error.error || error;
    console.debug('Error sending review email', errorMessage);
    this.get('notify').error(`We were unable to send your message: ${errorMessage}`);
  }

  /**
   * Called after an unsuccessful response from _fetchNotificationLogs.
   * Notifies the user of the encountered error.
   *
   * @private
   */
  _handleNotificationFetchError(error) {
    console.debug('Error fetching notification logs', error.error || error);
    this.get('notify').error(
      "Error fetching notification logs, we don't know if this email has already been sent"
    );
  }

  /**
   * Called after any response from _sendWeeklyReservationsEmails.
   * Sets isLoading to false so in the event of an error the user
   * isn't left with a never-ending spinning loader.
   *
   * @private
   */
  _handleSendingEmailsCleanUp() {
    this.setProperties({
      isLoading: false,
      passphraseEntered: '',
      selectedRestaurant: null
    });
  }

  /**
   * Uses a modelAction to send a request that will aggregate all of the reservations
   * in an area for a specified week by restaurant then send an email to those restaurants
   * with a list of those reservations.
   *
   * @returns {Promise | undefined}
   * @private
   */
  _sendWeeklyReservationsEmailOrEmails() {
    const monday = moment(this.get('monday')).startOf('day');
    const sunday = moment(monday).add(6, 'days').endOf('day');

    // emails for the current week can still be sent, but must be confirmed with isCurrentWeekConfirmed.
    if (!this.isCurrentWeek && !this._validWeek(monday, sunday)) {
      this._handleSendingEmailsError(
        'You can only send restaurant review emails for the current or following week'
      );
      this._handleSendingEmailsCleanUp();
      return;
    } else if (this.isCurrentWeek && this.sendToAllRestaurants) {
      // note that this is technically not possible to reach as it's already blocked on the confirm stage if current week
      // added for 2nd layer of security.
      this._handleSendingEmailsError(
        'For the current week, you can only send a single restaurant review email'
      );
      this._handleSendingEmailsCleanUp();
      return;
    }

    const formattedMonday = this._formatDate(monday);
    const formattedSunday = this._formatDate(sunday);
    const restaurant = this.get('selectedRestaurant');

    this.set('isLoading', true);

    return this.get('area')
      .sendWeeklyReservationsEmails({
        start_date: formattedMonday,
        end_date: formattedSunday,
        selected_restaurant_id: restaurant?.get('id') ?? null
      })
      .then(() =>
        restaurant
          ? this._handleSendingEmailsSuccess(`Sent email to ${restaurant.get('name')}`)
          : this._handleSendingEmailsSuccess('Weekly restaurant review emails have been sent')
      )
      .catch((error) => this._handleSendingEmailsError(error))
      .finally(() => this._handleSendingEmailsCleanUp());
  }

  _formatDate(date) {
    return date.format('YYYY-MM-DD');
  }

  _validWeek(monday, sunday) {
    return moment().isBetween(
      monday.clone().subtract(1, 'week'),
      sunday.clone().subtract(1, 'week'),
      undefined,
      '[]'
    );
  }

  @action
  confirmEmailsForCurrentWeek() {
    if (!this.get('isLoading') && this.isCurrentWeek) {
      if (this.sendToAllRestaurants) {
        this._handleSendingEmailsError(
          'For the current week, you can only send a single restaurant review email'
        );
        this._handleSendingEmailsCleanUp();
        return;
      } else {
        this.set('isEmailsForCurrentWeekConfirmed', true);
      }
    }
  }

  @action
  sendWeeklyReservationsEmails() {
    if (!this.get('isLoading')) {
      this._sendWeeklyReservationsEmailOrEmails();
    }
  }

  @action
  handleModelSelectOnChange(value) {
    this.set('selectedRestaurant', value);
  }

  @action
  showModal() {
    this.setProperties({
      isShowing: true,
      isEmailsForCurrentWeekConfirmed: false,
      notificationLogs: [],
      selectedRestaurant: null
    });
    this._fetchNotificationLogs();
  }
}
