import Component from '@glimmer/component';
import { arg } from 'ember-arg-types';
import { string, func } from 'prop-types';
import { task } from 'ember-concurrency';

export default class SfModalComponent extends Component {
  constructor(...args) {
    super(...args);
    this.loadingTask.perform();
  }

  @arg
  model;

  @arg(string.isRequired)
  name;

  @arg(func.isRequired)
  close;

  @arg(func)
  onLoad = async () => {};

  @arg(func)
  onSubmit = async () => {};

  @task
  async loadingTask() {
    await this.onLoad();
  }

  @task
  async submitTask() {
    await this.onSubmit();
  }
}
