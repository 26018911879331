import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';
import SessionCan from 'star-fox/utils/session-can';

/**
 * Mixin guards navigation to a route by requiring that the current user has that capability
 * if they don't they are redirected to redirectTo
 */
export default Mixin.create({
  session: service(),

  notify: service(),

  /** @type {string} the redirect route, override for custom behavior */
  redirectTo: 'logged-in',

  /** @type {string} the provided capability that this route will require */
  capability: null,

  beforeModel(transition) {
    const capability = this.get('capability');

    // if there are capabilities and there are none of them are enabled for the user
    // bailout to logged-in
    if (capability && !SessionCan(this.get('session'), capability)) {
      transition.abort();
      console.error(`User did not have the required capability: ${this.get('capability')}`);
      this.transitionTo(this.get('redirectTo')).then(() =>
        this.get('notify').error(`You're not allowed in that section of the app.`)
      );
    } else {
      this._super(transition);
    }
  }
});
