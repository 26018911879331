import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { htmlSafe } from '@ember/template';

@classic
@tagName('td')
@classNames('fde-tables-ordered-table-table-rows-save-cell')
export default class SaveCell extends Component {
  /** @type {Component} */
  form = null;

  /** @type {Model} */
  value = null;

  /** @type {string} */
  @computed('value.errors.[]')
  get valueErrors() {
    const valueErrors = this.get('value.errors') || [];

    return htmlSafe(
      valueErrors.reduce(
        (errorString, error) =>
          errorString + `${errorString !== '' ? '<br/><br/>' : ''}${error.message}`,
        ''
      )
    );
  }
}
