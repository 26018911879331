import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';

@classic
@classNames('ui grid fde-invoices-tax-row')
export default class TaxRow extends Component {
  /** @type {Object} Line item */
  tax = null;
}
