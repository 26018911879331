import Helper from '@ember/component/helper';

/**
 * Checks to see if all of the passed in arguments are true
 * @param {boolean[]} values
 * @returns {boolean}
 */
export function hardLink([url, target = '_blank']) {
  return function () {
    window.open(url, target);
  };
}

export default Helper.helper(hardLink);
