import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';

@classic
export default class LedgerRoute extends Route {
  orderEditRoute = 'logged-in.orders.edit';

  model({ ledger_id }) {
    const { order } = this.modelFor(this.get('orderEditRoute'));
    return RSVP.hash({
      order: order,
      client: order.get('client'),
      ledger: this.store.findRecord('accounting-ledger-item', ledger_id),
      discountCodes: this.store.findAll('discount-code')
    });
  }
}
