import classic from 'ember-classic-decorator';
import Component from '@ember/component';

@classic
export default class ServiceTimesAndClosures extends Component {
  /** @type {Area} */
  area = null;

  /** @type {Restaurant} */
  restaurant = null;
}
