import { helper as buildHelper } from '@ember/component/helper';

/** Stringifies an object so it is easy to view in a template.
 * Very useful for building components or debugging.
 */
export function jsonPrettyPrint([someObject = null, replacer = undefined, space = 2]) {
  return JSON.stringify(someObject, replacer, space);
}

export default buildHelper(jsonPrettyPrint);
