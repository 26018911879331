import classic from 'ember-classic-decorator';
import { alias } from '@ember/object/computed';
import { action } from '@ember/object';
import Controller from '@ember/controller';
import { run } from '@ember/runloop';

@classic
export default class ShowController extends Controller {
  queryParams = ['runLogsPageOffset', 'runLogsSearchText'];

  @alias('model.rule')
  rule;

  @alias('model.runLogs')
  runLogs;

  @alias('runLogs.meta.recordCount')
  runLogsCount;

  runLogsPageLimit = 20;
  runLogsPageOffset = 0;
  runLogsSearchText = '';
  _runLogsSearchText = '';
  isSearching = false;

  doSearch() {
    this.setProperties({
      runLogsSearchText: this.get('_runLogsSearchText'),
      runLogsPageOffset: 0
    });
  }

  @action
  handlePaginationChange(pageOffset) {
    this.set('runLogsPageOffset', pageOffset);
  }

  @action
  onSearchChange(searchText) {
    this.set('_runLogsSearchText', searchText);

    // prevent multiple search
    run.debounce(this, this.doSearch, 500);
  }
}
