import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { oneWay } from '@ember/object/computed';
import Controller from '@ember/controller';

@classic
export default class IndexController extends Controller {
  queryParams = ['pageLimit', 'pageOffset', 'searchText', 'sort', 'areas'];

  /** @type {string} text for searching the back end with */
  searchText = '';

  /** @type {number} sorting string */
  sort = 'name';

  /** @type {number} */
  @oneWay('model.areas.meta.recordCount')
  recordCount;

  /** @type {number} records per page */
  pageLimit = 20;

  /** @type {number} current page */
  pageOffset = 0;

  /** @type {object} */
  @service
  tick;
}
