import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class NewRoute extends Route.extend(CapabilityRouteMixin) {
  capability = 'TAG_EDIT';

  model() {
    return RSVP.hash({
      tag: this.store.createRecord('tag')
    });
  }
}
