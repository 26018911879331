import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { alias, not } from '@ember/object/computed';
import Component from '@ember/component';
import { isArray } from '@ember/array';
import { action, computed, get } from '@ember/object';
import { run } from '@ember/runloop';
import { htmlSafe } from '@ember/template';

import { task, timeout } from 'ember-concurrency';
import { setReset, setLater } from 'star-fox/utils/ember-object-setters';

const INPUT_PADDING_SIZE = 45; //Described by Semantic UI CSS
const TEXT_SIZE_CANVAS = document.createElement('canvas');
import $ from 'jquery';

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const context = TEXT_SIZE_CANVAS.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

@classic
@classNames('fde-model-select-control')
export default class ModelSelectControl extends Component {
  /** @type {String} Hover over text */
  title = '';

  /** @type {Model|Model[]|*} Current value selected by model select control */
  value = null;

  /** @type {Model[]} Set of values available to be set by model select control */
  values = null;

  /** @type {Model[]} Available so the user can highlight a value with the keyboard without having chosen it yet */
  highlightedValue = null;

  /** @type {boolean} True when the user selects the none option with the keyboard */
  highlightingNone = false;

  /** @type {boolean} Denotes whether the dropdown is open or closed */
  isOpen = false;

  /** @type {boolean} Denotes whether the dropdown is marked as active (a semantic UI css state/class) */
  isActive = false;

  /** @type {boolean} Denotes whether the dropdown is searchable */
  isSearchable = true;

  /** @type {boolean} Denotes whether the dropdown is clearable to without a value */
  isClearable = false;

  /** @type {Boolean} Is the input disabled */
  isDisabled = false;

  /** @type {Boolean} Is the input fluid */
  isFluid = true;

  /** @type {?String} Placeholder text */
  groupBy = '';

  /** @type {?String} Placeholder text */
  placeholder = 'None';

  /** @type {?String} None Text */
  noneText = 'None';

  /** @type {?String} None Icon*/
  noneIcon;

  /** @type {boolean} Denotes whether or not a none option is available. Not compatible with isClearable */
  allowNoneSelection = false;

  /** @type {?String} Current directionality of the dropdown */
  directionality = 'downward';

  /** @type {?String} Force directionality of the  */
  forceDirectionality = null;

  /** @type {string} The key on a value to use as the id */
  idKey = 'id';

  /** @type {string} The key on a value to use as the label */
  labelKey = 'label';

  /** @type {string} The key on a value to use as the description */
  descriptionKey = null;

  /** @type {string} The key on a value to use as the label */
  sortBy = 'label';

  /** @type {string} The key on a value to use as the icon */
  iconKey = 'icon';

  /** @type {string} The key on a value to use as the icon color */
  iconColorKey = 'iconColor';

  /** @type {string} The key on a value to use as the color */
  color = 'color';

  menuAnimationClasses = '';

  /** @type {?Model} */
  justSelected = null;

  /** @type {number} */
  searchDebounce = 300;

  /** @type {boolean} */
  idAsValue = false;

  /** @type {boolean} */
  isMultiple = false;

  /** @type {?string} Questionable, probably a bad idea */
  valuePath = null;

  /** @type {boolean} */
  @computed('value')
  get _isMultiple() {
    return isArray(this.get('value')) || this.isMultiple;
  }

  /** @type {boolean} */
  @not('_isMultiple')
  _isSingle;

  /** @type {boolean} */
  @alias('searchTask.isRunning')
  isSearching;

  /** @type {boolean} */
  @alias('searchTask.isRunning')
  isLoading;

  /** @type {boolean} */
  isSelection = true;

  /** @type {Model[]} */
  @computed('value')
  get valueAsArray() {
    let value = this.get('value');
    value = value === undefined || value === null ? [] : value;
    return isArray(value) ? value : [value];
  }

  /** @type {Model[]} */
  @computed('valueAsArray.[]', 'values.[]', 'searchedValues.[]', 'isSearching', 'searchText')
  get selectableValues() {
    if (this.get('isSearching')) {
      return [];
    }

    const valueAsArray = this.get('valueAsArray');
    let selectableValues = this.get('values') || [];

    const usingFrontendSearch = this.get('searchText') && !this.get('onSearchChange');
    const usingBackendSearch = this.get('searchText') && this.get('onSearchChange');

    if (usingFrontendSearch) {
      selectableValues = this.filterValues(this.get('searchText'), selectableValues);
    } else if (usingBackendSearch) {
      selectableValues = this.get('searchedValues') || [];
    }

    const idKey = this.get('idKey');
    const valueAsArrayOfIds = valueAsArray.mapBy(idKey);

    selectableValues = selectableValues.filter((_) => !valueAsArrayOfIds.includes(get(_, idKey)));

    return selectableValues;
  }

  /** @type {String} */
  @computed('_isMultiple', 'readonly', 'isFluid', 'isActive', 'isSearchable', 'isOpen', 'isLoading')
  get dropdownClasses() {
    let classes = this.get('baseClasses') || ['search'];

    if (this.get('readonly')) {
      classes.push('readonly input corner labeled');
    }
    if (this.get('_isMultiple')) {
      classes.push('multiple');
    }
    if (this.get('isFluid')) {
      classes.push('fluid');
    }

    if (this.get('isActive')) {
      classes.push('active');
    }

    if (this.get('isSearchable')) {
      classes.push('search');
    }

    if (this.get('isLoading')) {
      classes.push('loading');
    }

    if (this.get('isSelection')) {
      classes.push('selection');
    }

    if (this.get('isOpen')) {
      classes.push(this.get('forceDirectionality') || this.get('directionality'));
    }

    return htmlSafe(classes.join(' '));
  }

  /** @type {Number} */
  @computed('searchText')
  get inputSize() {
    if (!this.get('searchText')) {
      return INPUT_PADDING_SIZE;
    }

    const input = this.$('input').get(0) ? this.$('input').get(0) : window.document.body;
    const searchTextWidth = getTextWidth(this.get('searchText'), getComputedStyle(input).font);
    return searchTextWidth + INPUT_PADDING_SIZE;
  }

  /** @type {Model[]} */
  searchedValues = null;

  /** @type {task} */
  @(task(function* () {
    yield timeout(this.get('searchDebounce'));
    const searchedValues = yield this.onSearchChange(this.get('searchText'));
    this.set('searchedValues', searchedValues);

    return searchedValues;
  }).restartable())
  searchTask;

  /** @type {Model} The model value to display */
  @computed('value', 'highlightedValue', 'values.[]', 'idKey', 'idAsValue')
  get valueToDisplay() {
    const value = this.get('value');
    const values = this.get('values') || [];

    const selectedValue = this.get('idAsValue') ? values.findBy(this.get('idKey'), value) : value;

    return this.get('highlightedValue') || selectedValue;
  }

  /** @type {boolean} Alias to valueIsEmpty */
  @alias('valueIsEmpty')
  valueIsNone;

  /** @type {boolean} Check to see that the chosen value is falsey or an empty array */
  @computed('value', 'value.length', 'value.isFulfilled', 'value.content')
  get valueIsEmpty() {
    if (this.get('value.length') === 0) {
      return true;
    }

    if (this.get('value.isFulfilled') && !this.get('value.content')) {
      return true;
    }

    return !this.get('value');
  }

  /** @type {String} */
  @computed('placeholder', 'noneText', 'allowNoneSelection')
  get defaultText() {
    if (this.get('allowNoneSelection')) {
      return this.get('noneText');
    } else {
      return this.get('placeholder');
    }
  }

  /**
   * Handler for document click. Deactivates the model select.
   * @param {Event} e
   */
  handleDocumentClick(e) {
    run((_) => {
      if (!$.contains(this.get('element'), e.target)) {
        this.markInactive();
        this.closeDropdown();
        this.resetSearchText();
        this.resetHighlightedValue();
      }
    });
  }

  /** @override */
  didInsertElement() {
    const handleDocumentClick = this.handleDocumentClick.bind(this);
    this.set('handleDocumentClick', handleDocumentClick);
    document.addEventListener('click', handleDocumentClick);

    super.didInsertElement(...arguments);
  }

  /** @override */
  willDestroyElement() {
    super.willDestroyElement(...arguments);
    document.removeEventListener('click', this.get('handleDocumentClick'));

    const $input = this.$('input');
    $input.off();
  }

  /** Opens the dropdown if it is not open */
  openDropdown() {
    if (!this.get('isOpen')) {
      this.onOpen();
      this.setDirectionality();

      this.set('isOpen', true);
      const directionality = this.get('forceDirectionality') || this.get('directionality');
      const direction = directionality === 'upward' ? 'up' : 'down';

      setReset(this, 'menuAnimationClasses', `animating slide ${direction} in`, 200, '');
    }
  }

  /** Closes the dropdown if it is open */
  closeDropdown() {
    if (this.get('isOpen')) {
      this.onClose();
      const directionality = this.get('forceDirectionality') || this.get('directionality');
      const direction = directionality === 'upward' ? 'up' : 'down';

      setReset(this, 'menuAnimationClasses', `animating slide ${direction} out`, 200, '');
      setLater(this, 'isOpen', false, 200);
    }
  }

  /** Toggles the dropdown open and closed */
  toggleDropdown() {
    if (!this.get('isOpen')) {
      this.openDropdown();
      this.markActive();
    } else {
      this.closeDropdown();
      this.markInactive();
    }
  }

  /** Focuses the input */
  focusInput() {
    this.$('input').focus();
  }

  /** Marks the dropdown as active. Maps to a semantic ui class */
  markActive() {
    this.set('isActive', true);
  }

  /** Marks the dropdown as inactive. Active removes faster than semantic ui's open state (hence why 100ms) */
  markInactive() {
    setLater(this, 'isActive', false, 100);
  }

  /** Resets the search text */
  resetSearchText() {
    if (!this.isDestroyed) {
      this.set('searchText', null);
    }
  }

  /** Resets the selected value */
  resetHighlightedValue() {
    if (!this.isDestroyed) {
      this.setProperties({
        highlightedValue: null,
        highlightingNone: false
      });
    }
  }

  /**
   * Takes a set of models an a query string and returns a filtered set of models against the label of the model set.
   * @param {String} query
   * @param {Model[]} values
   * @returns {Model[]}
   */
  filterValues(query, values) {
    if (!query) {
      return values;
    }
    const lowercaseQuery = query.toLowerCase();
    return values.filter((_) => {
      const label = get(_, this.get('labelKey'))?.toLowerCase();
      return label && label.indexOf(lowercaseQuery) > -1;
    });
  }

  /**
   * Handler for key events
   *
   * @param {Event} event
   * @private
   */
  dispatchKey(event) {
    const key = event.key;
    const currentHighlightedValue = this.get('highlightedValue');
    const isOpen = this.get('isOpen');
    const isMultiple = this.get('_isMultiple');
    const value = this.get('value');
    const searchText = this.get('searchText');
    const highlightingNone = this.get('highlightingNone');
    const valueIsNone = this.get('valueIsNone');

    switch (key) {
      case 'ArrowDown':
        if (isOpen) {
          this.highlightNext();
          event.preventDefault(); //prevents the cursor from moving around in the input box
        } else {
          this.markActive();
          this.openDropdown();
        }
        break;
      case 'ArrowUp':
        if (isOpen) {
          this.highlightPrev();
          event.preventDefault(); //prevents the cursor from moving around in the input box
        }
        break;
      case 'Enter':
        if (currentHighlightedValue) {
          this.highlightNext();
          this.chooseValue(currentHighlightedValue);
          this.resetSearchText();
        } else if (highlightingNone && !valueIsNone && !isMultiple) {
          this.rejectValue(value);
          this.resetSearchText();
          this.resetHighlightedValue();
        }
        break;
      case 'Escape':
        this.resetSearchText();
        this.closeDropdown();
        this.markInactive();
        this.resetHighlightedValue();
        break;
      case 'Backspace':
        if (isMultiple && value.length && !searchText) {
          this.rejectValue(get(value, 'lastObject'));
        }
        break;
    }
  }

  /** Selects the next value in the list. Used for eventually choosing a value with the keyboard */
  highlightNext() {
    const highlightedValue = this.get('highlightedValue');
    const highlightingNone = this.get('highlightingNone');
    const selectableValues = this.get('selectableValues') || [];
    const allowNoneSelection = this.get('allowNoneSelection');
    const valueIsNone = this.get('valueIsNone');

    const currentIndex = this.get('selectableValues').indexOf(highlightedValue);

    const newIndex = currentIndex + 1;
    const nextHighlighted = selectableValues[newIndex] || null;

    if (highlightingNone && valueIsNone) {
      this.highlightFirst();
    } else if (highlightingNone) {
      this.highlightCurrentValue();
    } else if (!nextHighlighted && allowNoneSelection) {
      this.highlightNone();
    } else {
      this.highlight(nextHighlighted);
    }

    this.scrollToHighlighted();
  }

  /** Selects the previous value in the list. Used for eventually choosing a value with the keyboard */
  highlightPrev() {
    const highlightedValue = this.get('highlightedValue');
    const highlightingNone = this.get('highlightingNone');
    const selectableValues = this.get('selectableValues') || [];
    const currentIndex = this.get('selectableValues').indexOf(highlightedValue);
    const allowNoneSelection = this.get('allowNoneSelection');
    const valueIsNone = this.get('valueIsNone');

    const newIndex = currentIndex - 1;
    const prevHighlighted = selectableValues[newIndex] || null;

    const isHighlightingCurrentValue =
      (!highlightingNone && !highlightedValue) || (!highlightedValue && valueIsNone);

    if (isHighlightingCurrentValue && allowNoneSelection && !valueIsNone) {
      this.highlightNone();
    } else if (highlightingNone || isHighlightingCurrentValue) {
      this.highlightLast();
    } else {
      this.highlight(prevHighlighted);
    }

    this.scrollToHighlighted();
  }

  /** Highlights what's currently set as the value, which is not in the selectable list */
  highlightCurrentValue() {
    this.setProperties({
      highlightedValue: null,
      highlightingNone: this.get('valueIsNone')
    });
  }

  /** Highlights the first element in the list */
  highlightFirst() {
    const selectableValues = this.get('selectableValues') || [];

    this.setProperties({
      highlightedValue: selectableValues.get('firstObject'),
      highlightingNone: false
    });
  }

  /** Highlights the last element in the list */
  highlightLast() {
    const selectableValues = this.get('selectableValues') || [];

    this.setProperties({
      highlightedValue: selectableValues.get('lastObject'),
      highlightingNone: false
    });
  }

  /** Highlights the none element */
  highlightNone() {
    this.setProperties({
      highlightedValue: null,
      highlightingNone: true
    });
  }

  /** Highlights a given value */
  highlight(value) {
    this.setProperties({
      highlightedValue: value,
      highlightingNone: this.get('valueIsNone') && !value
    });
  }

  /** Force scrolls the menu window to the highlighted item */
  scrollToHighlighted() {
    run.next((_) => {
      if (!this.get('isDestroyed')) {
        const selectedItem = this.get('element').querySelector('.item.selected');
        if (selectedItem) {
          selectedItem.parentNode.scrollTop = selectedItem.offsetTop;
        }
      }
    });
  }

  /**
   * Chooses the model that's selected to be (as) / (apart of) the value.
   * @param {Model} value
   */
  chooseValue(value) {
    if (this.get('isDestroyed')) {
      return;
    }
    if (this.get('_isMultiple')) {
      const valueArr = this.get('value').toArray();
      valueArr.pushObject(value);
      setReset(this, 'justSelected', value, 200, null);
      this.set('value', valueArr);
      this.onChange(valueArr);
      this.onAdd(value);
    } else {
      const hasAValuePath = this.valuePath || this.idAsValue;
      const valueToEmit = hasAValuePath ? get(value, this.valuePath || this.idKey) : value;

      this.set('value', valueToEmit);
      this.onChange(valueToEmit);
      this.closeDropdown();
      this.markInactive();
      this.resetHighlightedValue();
    }
  }

  /**
   * Rejects the model that's passed into the function (as) / (apart of) the value.
   * @param {Model} value
   */
  rejectValue(value) {
    if (this.get('_isMultiple')) {
      const valueArr = this.get('value').toArray();
      valueArr.removeObject(value);
      this.set('value', valueArr);
      this.onChange(valueArr);
    } else {
      this.set('value', null);
      this.onChange(null);
      this.closeDropdown();
      this.markInactive();
    }
  }

  /**
   * Sets the search text
   * @param {String} searchText
   */
  setSearchText(searchText) {
    this.set('searchText', searchText);
  }

  /** Performs the search task */
  startSearch() {
    if (this.get('onSearchChange')) {
      this.get('searchTask').perform();
    }
  }

  /** Emits an onChange event */
  onChange(/* @param {Model|Model[]} value */) {}

  /** Emits an onAdd event. Useful for knowing when the user added a model to the value collection */
  onAdd(/* @param {Model} value */) {}

  onOpen() {}

  onClose() {}

  /** Sets the directionality of the menu by measuring a hidden version of the menu before it opens */
  setDirectionality() {
    if (this.get('forceDirectionality') || !this.get('selectableValues.length')) {
      return;
    }

    const dropdownEl = this.get('element').querySelector('.ui.dropdown');
    const fakeMenuEl = this.createFakeMenuEl(dropdownEl);

    this.renderFakeMenuEl(fakeMenuEl);

    const dropdownOffset = dropdownEl.getBoundingClientRect();
    const top = dropdownOffset.top + dropdownEl.offsetHeight;

    if (fakeMenuEl.offsetHeight + top > window.innerHeight) {
      this.set('directionality', 'upward');
    } else {
      this.set('directionality', 'downward');
    }
  }

  createFakeMenuEl(dropdownEl) {
    const menuElContainer = document.createElement('div');
    menuElContainer.setAttribute(
      'class',
      `ui ${this.get('isSearchable') ? 'search' : ''} dropdown`
    );

    const menuEl = document.createElement('div');
    menuEl.setAttribute('class', 'menu transition visible');
    menuEl.setAttribute('style', `width:${dropdownEl.offsetWidth}px; position:static;`);

    this.get('selectableValues').forEach((_) => {
      const item = document.createElement('div');
      item.setAttribute('class', 'item');
      item.textContent = get(_, this.get('labelKey'));

      menuEl.appendChild(item);
    });

    if (this.get('allowNoneSelection') && !this.get('valueIsEmpty')) {
      const item = document.createElement('div');
      item.setAttribute('class', 'item');
      item.textContent = this.get('noneText');
      menuEl.appendChild(item);
    }

    menuElContainer.appendChild(menuEl);

    return menuElContainer;
  }

  renderFakeMenuEl(fakeMenuEl) {
    const existingDropdownSizer = document.getElementById('fde-dropdown-menu-sizer');
    const dropdownSizer = existingDropdownSizer || document.createElement('div');

    //Leaving the invisible dropdown sizer inside the page is what keeps it fast.
    if (!existingDropdownSizer) {
      dropdownSizer.setAttribute('id', 'fde-dropdown-menu-sizer');
      dropdownSizer.setAttribute(
        'style',
        `position: absolute; z-index:-1; top: 0px; left: 0px; height: 0px; width: 0px; opacity:0;`
      );
    } else {
      dropdownSizer.removeChild(dropdownSizer.children[0]);
    }

    dropdownSizer.appendChild(fakeMenuEl);

    !existingDropdownSizer && document.body.append(dropdownSizer);
  }

  @action
  iconClassForValue(value) {
    const iconClasses = get(value, this.iconKey) || '';
    const isCountryFlag = iconClasses.length >= 6 && iconClasses.includes('flag');

    return isCountryFlag ? iconClasses : `${iconClasses} icon`;
  }

  @action
  handleInputChange(event) {
    console.debug(event);
    const searchText = event.target.value;

    this.markActive();
    this.openDropdown();
    this.setSearchText(searchText);
    this.resetHighlightedValue();
    this.startSearch();
  }

  @action
  handleInpurBlur() {
    run.next(() => {
      if (this.get('preventResetSearchTextOnBlur')) {
        if (!this.isDestroyed) {
          this.set('preventResetSearchTextOnBlur', false);
        }
        return;
      }

      this.resetSearchText();
    });
  }

  @action
  handleItemClick(value) {
    if (this.get('_isMultiple')) {
      /*
       * Defer update until document click has executed, otherwise, the component will re-render the selectableValues
       * before the element can be detected inside the component.
       */
      run.next((_) => {
        this.chooseValue(value);
        this.resetHighlightedValue();
        this.resetSearchText();
      });
    } else {
      this.chooseValue(value);
      this.resetHighlightedValue();
      this.resetSearchText();
    }
  }

  @action
  handleItemMouseDown() {
    if (this.element.querySelector('input') === document.activeElement) {
      this.set('preventResetSearchTextOnBlur', true);
    }
  }

  @action
  handleItemDeleteClick(value) {
    this.rejectValue(value);
    this.resetHighlightedValue();
  }

  @action
  handleDropdownIconClick() {
    this.toggleDropdown();
  }

  @action
  handleDropdownClick() {
    this.markActive();
    this.openDropdown();
    this.focusInput();
  }

  @action
  handleInputKeydown(event) {
    this.dispatchKey(event);
  }
}
