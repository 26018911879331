import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, get } from '@ember/object';

@classic
@classNames('fde-filters-controls-model-select')
export default class ModelSelect extends Component {
  /** @type {*} */
  value = null;

  /** @type {Array} */
  values = [];

  /** @type {Filter} */
  filter = null;

  /** @type {boolean} */
  useDropdown = false;

  /**
   * Handles the addition of a value onto the filter through a model.
   * @param {Model|Object} model
   */
  addValue(model) {
    const resourceValueKey = this.get('filter.resourceValueKey');
    const resourceValueLabel = this.get('filter.resourceValueLabel');
    const shouldUseValueText = this.get('filter.valueText') && resourceValueLabel;

    const filterValue = (this.get('filter.value') || []).toArray();
    const filterValueText = shouldUseValueText && (this.get('filter.valueText') || []).toArray();

    const valueToAdd = get(model, resourceValueKey);
    const valueTextToUse = get(model, resourceValueLabel);

    if (!filterValue.includes(valueToAdd)) {
      filterValue.push(valueToAdd);
      shouldUseValueText && filterValueText.push(valueTextToUse);
    }

    const filter = this.get('filter');
    filter.setValue(filterValue, filterValueText);
  }

  /**
   * Handles the removal of a value onto the filter through a model.
   * @param {Model|Object} model
   */
  removeValue(model) {
    console.debug(model);

    const resourceValueKey = this.get('filter.resourceValueKey');
    const resourceValueLabel = this.get('filter.resourceValueLabel');
    const shouldUseValueText = this.get('filter.valueText') && resourceValueLabel;

    const filterValue = (this.get('filter.value') || []).toArray();
    const filterValueText = shouldUseValueText && (this.get('filter.valueText') || []).toArray();

    const valueToRemove = get(model, resourceValueKey);

    const indexOf = filterValue.indexOf(valueToRemove);

    if (indexOf > -1) {
      filterValue.splice(indexOf, 1);
      shouldUseValueText && filterValueText.splice(indexOf, 1);
    }

    const filter = this.get('filter');
    filter.setValue(filterValue, filterValueText);
  }

  @action
  handleModelSelectChange(model) {
    console.debug(model);
    this.addValue(model);
  }

  @action
  handleValueDelete(model) {
    console.debug(model);
    this.removeValue(model);
  }

  @action
  handleCheckboxChange(model, isChecked) {
    if (isChecked) {
      this.addValue(model);
    } else {
      this.removeValue(model);
    }
  }
}
