import classic from 'ember-classic-decorator';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { observes } from '@ember-decorators/object';
import Component from '@ember/component';
import '@ember/object';
import { run } from '@ember/runloop';
import $ from 'jquery';

@classic
@classNames('fde-range-control')
@classNameBindings('showValue:has-label')
export default class RangeControl extends Component {
  /** @type{number} **/
  hardMin = 0;

  /** @type{number} **/
  min = 0;

  /** @type{number} **/
  max = 0;

  /** @type{number} **/
  value = 0;

  /** @type{number} **/
  step = 1;

  /** @type{boolean} **/
  showValue = false;

  /** @type{string} **/
  valuePrefix = '';

  /** @type{string} **/
  valueSuffix = '';

  /** @type{boolean} **/
  changeOnSlide = false;

  /** @type{boolean} **/
  isSliding = false;

  /** @type{boolean} **/
  sliderValue = null;

  /** @type{function} **/
  onChange() {}

  /** @type{function} **/
  onSlide() {}

  @observes('value')
  valueDidChange() {
    this.$('.range').range('set value', this.get('value'));
  }

  @observes('min')
  minDidChange() {
    this._initRangeSelect();
  }

  @observes('max')
  maxDidChange() {
    this._initRangeSelect();
  }

  @observes('hardMin')
  hardMinDidChange() {
    this._initRangeSelect();
  }

  onBodyMouseUp() {
    run.next((_) => {
      const valueChanged = this.get('value') !== this.get('sliderValue');
      const changeOnSlide = this.get('changeOnSlide');
      const sliderValue = this.get('sliderValue');
      const isSliding = this.get('isSliding');

      if (valueChanged && !changeOnSlide && isSliding) {
        this.onChange(sliderValue);
        this.set('isSliding', false);
      }
    });
  }

  onSliderMouseDown() {
    run.next((_) => {
      this.set('isSliding', true);
    });
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    run.scheduleOnce('afterRender', () => {
      this._initRangeSelect();

      const onBodyMouseUp = this.onBodyMouseUp.bind(this);
      const onSliderMouseDown = this.onSliderMouseDown.bind(this);

      this.set('_onBodyMouseUp', onBodyMouseUp);
      this.set('_onSliderMouseDown', onSliderMouseDown);

      this.$('.ui.range').on('mousedown', onSliderMouseDown);
      $('body').on('mouseup', onBodyMouseUp);
    });
  }

  _initRangeSelect() {
    this.set('sliderValue', this.get('value'));

    this.$('.range').range({
      min: this.get('min'),
      hardMin: this.get('hardMin'),
      max: this.get('max'),
      step: this.get('step'),
      start: this.get('value'),
      onChange: (value) =>
        run.next((_) => {
          this.set('sliderValue', value);
          this.onSlide(value);
          this.get('changeOnSlide') && this.onChange(value);
        })
    });
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);
    this.$('.ui.range').off('mousedown', this.get('_onSliderMouseDown'));
    $('body').off('mouseup', this.get('_onBodyMouseUp'));
  }
}
