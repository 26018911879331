import classic from 'ember-classic-decorator';
import { tagName } from '@ember-decorators/component';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

@classic
@tagName('')
export default class RestaurantsItem extends Component {
  /** @type {Restaurant} */
  item = null;

  /** @type {Restaurant} */
  @alias('item')
  restaurant;
}
