import Controller from 'star-fox/features/application/abstract-controller';
import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import $ from 'jquery';
import { guidFor } from '@ember/object/internals';
import { isPresent } from '@ember/utils';
import EmberObject, { action, computed } from '@ember/object';
import RSVP from 'rsvp';
import OrderItemOptions from 'star-fox/utils/order-item-options';

@classic
export default class EditController extends Controller {
  /** @type {Notify} */
  @service
  notify;

  /** @type {Modal} */
  @service
  modal;

  /**
   * @type {string} the name of the channel that we use for presence,
   * defaults to ee.food.orders.{id}.model-events
   */
  @computed('order')
  get pusherChannels() {
    const orderId = this.get('order.id');
    return [`ee.food.orders.${orderId}.model-events`];
  }

  /** @type {Order} */
  @alias('model.order')
  order;

  /** @type {Menu} */
  @alias('model.activeMenu')
  activeMenu;

  /** @type {Menu} */
  @alias('model.menu')
  menu;

  /** @type {Array.<MenuOptionGroup>} */
  menuOptionGroups = null;

  /** @type {Object} */
  orderItemOptions = null;

  /** @type {boolean} */
  orderItemOptionsPresent = false;

  /** @type {Object} Requires a blank object to properly set the bindings on the form-for in the modals **/
  orderItemToEdit = null;

  init() {
    super.init(...arguments);
    this.orderItemToEdit = EmberObject.create();

    this.menuOptionGroups = [];
    this.orderItemOptions = {};
  }

  /**
   * Creates a unique hash map of the current orderItems for quicker lookup
   * @type {Object}
   */
  @computed('order.orderItems.[]')
  get orderItemIndex() {
    return this.get('order.orderItems').reduce((acc, orderItem) => {
      if (!(orderItem.get('notes') || orderItem.get('isDeleted'))) {
        const menuItemId = orderItem.get('menuItem.id');
        const groupOrderMemberId = orderItem.get('groupOrderMember.id');
        const key = this._orderItemIndexKey(menuItemId, groupOrderMemberId);
        acc[key] = orderItem;
      }

      return acc;
    }, {});
  }

  /** @type {string} Unique id for the custom item modal, in case multiple modals exist at the same time */
  @computed
  get _customItemModalId() {
    return `${guidFor(this)}-custom-item-modal`;
  }

  /** @type {string} Unique selector using unique id */
  @computed('_customItemModalId')
  get customItemSelector() {
    return `ui modal fde-custom-item-modal-content ${this.get('_customItemModalId')}`;
  }

  /**
   * Sends the event and params to the modal service
   * after setting the custom order item for the form.
   * Also provides sets orderItemOptions
   * @type {OrderItem} orderItemToEdit
   * @type {Event} event a click event
   * @type {Object} extraParams
   */
  _openCustomOrderModal(orderItemToEdit, event, extraParams = {}) {
    const orderItemOptions = OrderItemOptions.createFromOrderItem(orderItemToEdit);

    const params = {
      selector: this.get('customItemSelector'),
      orderItemToEdit
    };

    const orderItemOptionsPresent = !!Object.keys(orderItemOptions).length;

    $.extend(params, extraParams);

    this.setProperties({
      customItemName: orderItemToEdit.get('menuItem.name'),
      orderItemToEdit,
      orderItemOptions,
      orderItemOptionsPresent
    });

    this.get('modal')
      .openModal(event, params)
      .then((_) => {
        $('.fde-custom-item-modal-content').addClass('scrolling');
      });
  }

  /**
   * @param menuItemId
   * @returns {?OrderItem}
   * @private
   */
  _locateOrderItem(menuItemId) {
    const groupOrderMemberId = this.get('selectedGroupOrderMember.id');

    return this.get('orderItemIndex')[this._orderItemIndexKey(menuItemId, groupOrderMemberId)];
  }

  /**
   * Provides the key for the orderItemIndex hash
   * @param {string} menuItemId
   * @param {string} groupOrderMemberId
   * @returns {string}
   */
  _orderItemIndexKey(menuItemId, groupOrderMemberId = null) {
    const prefix = isPresent(groupOrderMemberId) ? `${groupOrderMemberId}-` : '';
    return `${prefix}${menuItemId}`;
  }

  /**
   * @param {OrderItem} orderItem
   */
  increaseOrderItem(orderItem) {
    console.info('[orders/edit/cart/controller]#increaseOrderItem', orderItem);
    if (orderItem.get('isSaving')) {
      return;
    }

    orderItem.incrementProperty('quantity');
  }

  /**
   * @param {OrderItem} orderItem
   * @returns {Promise.<OrderItem>}
   */
  _saveOrderItem(orderItem) {
    if (!orderItem.get('isSaving')) {
      return orderItem.save().catch((response) => {
        if (orderItem.get('isNew')) {
          orderItem.destroyRecord();
        } else {
          orderItem.rollbackAttributes();
        }

        this._dealWithOrderItemBudgetError(
          response,
          'LoggedInOrdersEditCartController#_saveOrderItem'
        );
      });
    }
  }

  /**
   * @param {MenuItem} menuItem
   * @param {string|null} notes
   * @return {Promise.<OrderItem>}
   */
  createOrderItem(menuItem, notes = null) {
    const groupOrderMember = this.get('selectedGroupOrderMember');
    const order = this.get('order');

    const orderItem = this.get('store').createRecord('order-item', {
      quantity: 1,
      order,
      menuItem,
      notes,
      groupOrderMember
    });

    return this._saveOrderItem(orderItem).catch((response) => {
      if (response.isAdapterError) {
        this._dealWithOrderItemBudgetError(response);
        orderItem.destroyRecord();
      }
    });
  }

  /**
   * Rollsback changes, or deletes OrderItem
   * @param {OrderItem} orderItem
   * @private
   */
  _revertOrderItem(orderItem) {
    if (orderItem.get('isNew')) {
      orderItem.destroyRecord();
    } else {
      orderItem.rollbackAttributes();
    }
  }

  /**
   * Reset menuOptionItems on orderItem
   * @returns {Promise.<OrderItem>}
   */
  _orderItemOptionsUpdate() {
    const orderItemToEdit = this.get('orderItemToEdit');
    const orderItemOptions = this.get('orderItemOptions');
    const notes = orderItemToEdit.get('notes');

    // People have the ability to submit blank notes so we should
    // explicitly set the notes attribute to null if none are present.
    if (notes && !notes.replace(/\s/g, '')) {
      orderItemToEdit.set('notes', null);
    }

    return OrderItemOptions.applyToOrderItem(orderItemToEdit, orderItemOptions);
  }

  @action
  refreshModel() {
    this.send('refreshModel');
  }

  @action
  toggleNotes(checkboxValue) {
    this.set('orderItemToEdit.notes', checkboxValue ? 'Add allergy information!' : null);
  }

  @action
  handleAfterResetCustomItem() {
    return this._orderItemOptionsUpdate();
  }

  @action
  handleOrderItemOptionsSave(form) {
    return RSVP.hash({
      parentSubmit: form.doSubmit(),
      orderItemOptionsSave: this._orderItemOptionsUpdate()
    });
  }

  @action
  openCustomCreateOrderModal(menuItem) {
    const orderItem = menuItem.customOrderItemFor(
      this.get('order'),
      this.get('selectedGroupOrderMember')
    );

    const params = {
      onHide: (_) => this.get('orderItemToEdit').destroyRecord()
    };

    this._openCustomOrderModal(orderItem, event, params);
  }

  @action
  openCustomEditOrderModal(orderItem) {
    const params = {
      onHide: (_) => this.get('orderItemToEdit').rollbackAttributes()
    };
    this._openCustomOrderModal(orderItem, event, params);
  }

  @action
  onOrderItemFail(orderItem, response, message) {
    if (response.errors.find((_) => _.detail.includes('budget'))) {
      this._dealWithOrderItemBudgetError(response, message);
    } else {
      this.get('notify').error(response.errors[0].title);
    }

    this._revertOrderItem(orderItem);
  }

  @action
  handleMenuItemClick(menuItem) {
    const preExistingOrderItem = this._locateOrderItem(menuItem.get('id'));

    if (preExistingOrderItem) {
      this.increaseOrderItem(preExistingOrderItem);
    } else {
      this.createOrderItem(menuItem);
    }
  }

  @action
  handleOrderItemSave(menuItem) {
    const preExistingOrderItem = this._locateOrderItem(menuItem.get('id'));

    if (preExistingOrderItem) {
      this._saveOrderItem(preExistingOrderItem);
    }
  }

  @action
  afterCustomItemSave() {
    this.get('modal').closeModal(event, this.get('customItemSelector'));
  }

  @action
  handleClearCart() {
    const order = this.get('order');
    const notify = this.get('notify');

    order
      .clearCart()
      .then((_) => {
        notify.info(`Cleared the cart of all order items`);

        order.get('orderItems').forEach((orderItem) => orderItem.deleteRecord());
        order.set('orderItems', []);
      })
      .catch((_) => notify.error(`Unable to clear cart`));
  }
}
