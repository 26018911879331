import classic from 'ember-classic-decorator';
import Model, { attr, belongsTo } from 'renard/models/foodee';

@classic
export default class DeskCase extends Model {
  // Attributes

  /**
   * enums: [
   *  'call_client',
   *  'call_restaurant'
   * ]
   * @type {string}
   */
  @attr('string')
  caseRequest;

  /**
   * enums: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
   * @type {number}
   */
  @attr('number')
  caseValue;

  @attr('number')
  caseId;

  @attr('string')
  caseMessage;

  @attr('string')
  caseStatus;

  @attr('date')
  createdAt;

  // Relationships
  @belongsTo('order')
  order;

  @belongsTo('user')
  user;

  /*
   * Validations
   */
  validations = {
    caseValue: { presence: { message: `Please select a value for the Desk case.` } },
    caseRequest: { presence: { message: `Please select a a requested action.` } }
  };
}
