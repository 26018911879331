import classic from 'ember-classic-decorator';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import LoadingRouteMixin from 'star-fox/mixins/loading-route-mixin';
import CapabilityRouteMixin from 'star-fox/mixins/capability-route-mixin';

@classic
export default class IndexRoute extends Route.extend(CapabilityRouteMixin, LoadingRouteMixin) {
  capability = 'LOGISTICS_COURIERS_INDEX';

  queryParams = {
    pageLimit: { refreshModel: true },
    pageOffset: { refreshModel: true },
    search: { refreshModel: true }
  };

  model(params) {
    const { pageLimit, pageOffset, search } = params;

    return RSVP.hash({
      areas: this.store.query('area', {
        page: {
          limit: 100,
          offset: 0
        }
      }),
      couriers: this.store.query('courier', {
        filter: {
          search: search
        },
        page: {
          limit: pageLimit,
          offset: pageOffset
        },
        include: 'owner,areas'
      })
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
