import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';

const FOURTEEN_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 14;

@classic
export default class AvailabilityController extends Controller {
  queryParams = ['monday'];
  monday = '';

  /** @type {boolean} */
  fetchingPrev = false;

  /** @type {boolean} */
  fetchingNow = false;

  /** @type {boolean} */
  fetchingNext = false;

  /** @type {string} message to show the driver when they have submitted thier availability */
  @computed('model.driver')
  get updateMessage() {
    const driver = this.get('model.driver');
    return `Thanks ${driver.get('firstName')}!`;
  }

  /** @type {string} whether or not the driver can see the next week (two weeks) */
  @computed('model.driverWeek.monday')
  get canNext() {
    const monday = moment(this.get('model.driverWeek.monday'));

    return moment().diff(monday) > -FOURTEEN_DAYS_IN_MS;
  }

  /** @type {string} whether or not the driver can see the prev week (two weeks) */
  @computed('model.driverWeek.monday')
  get canPrev() {
    const monday = moment(this.get('model.driverWeek.monday'));

    return moment().diff(monday) < FOURTEEN_DAYS_IN_MS;
  }

  @action
  prevWeek() {
    const prevMonday = moment(this.get('model.driverWeek.monday')).subtract(7, 'days');

    this.setProperties({
      fetchingPrev: true,
      monday: prevMonday.format('YYYY-MM-DD')
    });
  }

  @action
  thisWeek() {
    const thisMonday = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD');

    if (thisMonday !== this.get('monday')) {
      this.setProperties({
        fetchingNow: true,
        monday: thisMonday
      });
    }
  }

  @action
  nextWeek() {
    const nextMonday = moment(this.get('model.driverWeek.monday')).add(7, 'days');

    this.setProperties({
      fetchingNext: true,
      monday: nextMonday.format('YYYY-MM-DD')
    });
  }

  @action
  handleDidSave() {
    this.set('showSuccess', true);
  }
}
