import classic from 'ember-classic-decorator';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

@classic
@classNames('fde-image-control')
export default class ImageControl extends Component {
  /** @type {string[]} the formats supported by this upload */
  formats = ['png', 'jpeg'];

  /** @type {string} */
  value = null;

  /** @type {string[]} computed mimetypes (used for the filepicker that this is composed of) */
  @computed('formats.[]')
  get mimetypes() {
    return this.get('formats').map((_) => `image/${_}`);
  }

  /** @type {string[]} extension string for the template */
  @computed('formats.[]')
  get extensionString() {
    return this.get('formats')
      .map((_) => _.toUpperCase())
      .join(' or ');
  }

  /** @type {number} Size in megabytes */
  maxSize = 2;

  /** @type {?string} */
  widthConstraint = null;

  /** @type {?string} */
  heightConstraint = null;

  /** @type {?string} */
  @computed('widthConstraint', 'heightConstraint')
  get cropRatio() {
    const widthConstraint = this.get('widthConstraint');
    const heightConstraint = this.get('heightConstraint');

    return widthConstraint && heightConstraint ? `${widthConstraint}/${heightConstraint}` : null;
  }

  /** @type {function} callback function called when the file errored uploading */
  onError() {}

  /** @type {function} callback function called when the file has been uploaded */
  onChange() {}
}
