import classic from 'ember-classic-decorator';
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from 'renard/models/foodee';
import { capitalize } from '@ember/string';

@classic
export default class EmailMessage extends Model {
  /**
   * Attributes
   */
  @attr('number')
  messageId;

  @attr('array')
  recipients;

  @attr('string')
  subject;

  @attr('string')
  message;

  @attr('string')
  event;

  @attr('number')
  mailableId;

  @attr('string')
  messageType;

  @attr('date')
  createdAt;

  @attr('date')
  timestamp;

  /**
   * Relationships
   */
  @belongsTo('order')
  order;

  /** @type {string} */
  @computed('messageType')
  get mailerType() {
    const messageType = this.get('messageType') || '';
    return messageType
      .split('_')
      .map((_) => capitalize(_))
      .join(' ');
  }

  /**
   * Validations
   */
  validations = {
    messageType: {
      presence: true
    },
    message: {
      presence: true
    }
  };
}
