import classic from 'ember-classic-decorator';
import Transform from '@ember-data/serializer/transform';
import { RuleTypeConversionMap } from '../models/rules-rule';

@classic
export default class RuleType extends Transform {
  deserialize(value) {
    const ret = Object.entries(RuleTypeConversionMap).find((e) => e[1] == value);

    if (!ret) {
      throw new Error(`${value} is not a valid rule type when deserializing`);
    }

    return ret[0];
  }

  serialize(value) {
    const ret = RuleTypeConversionMap[value];

    if (!ret) {
      throw new Error(`${value} is not a valid rule type when serializing`);
    }

    return ret;
  }
}
