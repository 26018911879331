import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import $ from 'jquery';
import Route from '@ember/routing/route';

import config from 'star-fox/config/environment';
import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin';

@classic
export default class ApplicationRoute extends Route.extend(ApplicationRouteMixin) {
  routeAfterAuthentication = 'logged-in';

  @service
  session;

  @service
  actionCable;

  /**
   * Opportunity to setup any app wide services.
   * @private
   */
  _setupServices() {}

  /**
   * SF-13 in order to support single sign on with the legacy rails system
   * we need to deal with the cookie session store as well as the JWT system
   * this code fires off a sign_out request to clear the session on the server side
   * @private
   */
  _invalidateOldSessions() {
    this.get('session').on('invalidationSucceeded', () => {
      $.ajax({
        url: '/api/v2/users/sign_out',
        method: 'DELETE',
        xhrFields: {
          withCredentials: true
        }
      });
    });
  }

  init() {
    super.init(...arguments);

    this._setupServices();
    this._invalidateOldSessions();

    this.actionCable.wire(this, `ee.food.job-status`, [
      'job_queued',
      'job_started',
      'job_done',
      'job_cancelled'
    ]);
  }

  /**
   * Do not remove. This prevents breakages for some reason.
   */
  afterModel() {
    return super.afterModel(...arguments);
  }

  sessionInvalidated() {
    if (config.environment !== 'development') {
      undefined;
    }
  }

  @action
  jobQueued(payload) {
    console.info(`[job-status] job_queued: `, payload);
    this.store.pushPayload(payload);
  }

  @action
  jobStarted(payload) {
    console.info(`[job-status] job_start: `, payload);
    this.store.pushPayload(payload);
  }

  @action
  jobDone(payload) {
    console.info(`[job-status] job_done: `, payload);
    this.store.pushPayload(payload);
  }

  @action
  jobCancelled(payload) {
    console.info(`[job-status] job_cancelled: `, payload);
    this.store.pushPayload(payload);
  }
}
