import classic from 'ember-classic-decorator';
import { attributeBindings, classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { dasherize } from '@ember/string';
import { htmlSafe } from '@ember/template';

@classic
@classNames('fde-dietary-tag')
@classNameBindings(
  'isSelected:fde-is-selected:fde-is-inactive',
  'isSelectable:fde-is-selectable',
  'isSecondary:fde-is-secondary',
  'dietaryTagTypeClass'
)
@attributeBindings(
  'dietaryTagStyles:style',
  'tooltip:data-tooltip',
  'tooltipPosition:data-position'
)
export default class DietaryTag extends Component {
  /** @type {string} */
  tooltipPosition = 'top center';

  /** @type {DietaryTag} */
  value = null;

  /** @type {boolean} */
  isSecondary = false;

  /** @type {string} */
  @computed('value.name', 'isSeconday')
  get tooltip() {
    const name = this.get('value.name');
    return this.get('isSecondary') ? `Can be made ${name}` : name;
  }

  /** @type {string} */
  @computed('value.color')
  get dietaryTagStyles() {
    const style = `border-color: ${this.get('value.color')};`;
    return htmlSafe(`${style}`);
  }

  @computed('value.color')
  get dietaryLabelStyles() {
    const bgColor = this.get('isSecondary') ? 'white' : this.get('value.color');
    const style = `background-color: ${bgColor}`;
    return htmlSafe(`${style}`);
  }

  @computed('value.tagType')
  get dietaryTagTypeClass() {
    const tagType = this.get('value.tagType') || 'DIETARY';
    return `fde-dietary-tag__${dasherize(tagType)}`;
  }

  /** @type {boolean} whether or not the component is view-only */
  isSelectable = false;

  /** @type {boolean} whether or not the component is selected */
  isSelected = false;
}
