import classic from 'ember-classic-decorator';
import Application from './application';

@classic
export default class CommunicationPreference extends Application {
  attrs = {
    sms: {
      serialize: true
    },
    emailFeedback: {
      serialize: true
    }
  };
}
