import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class CommunicationsController extends Controller {
  queryParams = ['pageLimit', 'pageOffset', 'searchText', 'sort'];

  @tracked pageLimit = 30;
  @tracked pageOffset = 0;
  @tracked searchText = '';
  @tracked sort = '-updatedAt';
}
