import classic from 'ember-classic-decorator';
import { classNames, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { isArray } from '@ember/array';

@classic
@tagName('')
@classNames('')
export default class DrawerComponent extends Component {
  /** @type {boolean} */
  isOpen = false;

  /** @type {boolean} */
  isModal = false;

  /** @type {boolean} */
  isRight = true;

  /** @type {number} */
  width = null;

  @computed('classNames')
  get classes() {
    const classNames = this.get('classNames');
    return isArray(classNames) ? classNames.join(' ') : classNames;
  }

  close() {}
}
