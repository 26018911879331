import classic from 'ember-classic-decorator';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import config from 'star-fox/config/environment';

@classic
export default class InternalController extends Controller {
  /** @type {NotificationService} */
  @service
  notify;

  /** @type {AjaxService} */
  @service
  ajax;

  @service
  userSession;

  host = config.APP.API_HOST;

  @action
  notifySuccess() {
    this.get('notify').success('Successfully updated user to initial invite state.');
  }

  @action
  async resetInvitationStatus() {
    // Let's make sure this doesn't get pressed by accident or something.
    if (
      !window.confirm(
        'WARNING: This will completely wipe all invitation-related values from this user. Typically, this is only needed for older restaurant accounts.  Are you SURE that you want to proceed?'
      )
    ) {
      return;
    }

    this.get('model.user').resetInvitationStatus();
  }
}
