import Model, { attr, belongsTo, hasMany } from 'renard/models/foodee';

import { computed } from '@ember/object';
import { capitalize } from '@ember/string';
import { htmlSafe } from '@ember/template';
import { priceGetterAndSetter } from 'renard/utils/money';

export default Model.extend({
  /*
   * Attributes
   */
  name: attr('string'),

  clientPriceCents: attr('number'),
  restaurantPriceCents: attr('number'),
  retailPriceCents: attr('number'),
  deletedAt: attr('date', { defaultValue: null }),

  position: attr('number'),

  /*
   * Relationships
   */
  // taxRate: belongsTo('invoicing-tax-rate'),
  menuOptionGroup: belongsTo('menu-option-group', { async: false }),
  orderItem: hasMany('order-item', { async: false }),
  dietaryTags: hasMany('dietary-tag', { async: false }),

  /*
   * Computed Properties
   */
  clientPrice: computed('clientPriceCents', priceGetterAndSetter('clientPriceCents')),

  restaurantPrice: computed('restaurantPriceCents', priceGetterAndSetter('restaurantPriceCents')),

  retailPrice: computed('retailPriceCents', priceGetterAndSetter('retailPriceCents')),

  labelName: computed('name', 'clientPrice', 'dietaryTags.[]', function () {
    const name = capitalize(this.get('name').toLowerCase());
    const price = this.get('clientPriceCents') === 0 ? '' : ` +$${this.get('clientPrice')}`;
    const dietaryTags = this.get('dietaryTags').mapBy('abbreviation').join(',');

    const names = [name, price];

    if (dietaryTags) {
      names.push(` (${dietaryTags.toUpperCase()})`);
    }

    return names.join('');
  }),

  labelNameWithoutTags: computed('name', 'clientPrice', 'dietaryTags.[]', function () {
    const name = capitalize(this.get('name').toLowerCase());
    const price = this.get('clientPriceCents') === 0 ? '' : ` +$${this.get('clientPrice')}`;

    const names = [name, price];

    return names.join('');
  }),

  /** * @type {string} Label for displaying in the cart, expect to be displayed as rich text */
  cartLabel: computed('name', 'menuOptionGroup.{verb,name}', 'dietaryTags.[]', function () {
    if (this.isOptionDeleted()) {
      return `[deleted] ${this.get('name')}`;
    } else {
      const name = this.get('name');
      const verb = capitalize(this.get('menuOptionGroup.verb'));
      const mogName = this.get('menuOptionGroup.name');
      const dietaryTags = this.get('dietaryTags').mapBy('abbreviation').join(',');

      let ret;
      let formattedVerb = `<strong>${verb}</strong>`;

      // item and options names should not be transformed
      // this is because items can have names like "Coca-Cola" that should remain the same
      switch (verb) {
        case 'Add':
        case 'Includes':
        case 'Remove':
          ret = `${name}`;
          break;
        case 'Pick':
          ret = `${mogName} ${name}`;
          break;
        case 'Substitute':
          ret = `${mogName} with ${name}`;
          break;
      }

      const names = [formattedVerb, ret];

      if (dietaryTags) {
        names.push(` (${dietaryTags.toUpperCase()})`);
      }

      return htmlSafe(names.join(' '));
    }
  }),

  /** @type {string} Same as cartLabel except this includes the menu option item price */
  cartLabelWithPrice: computed('clientPriceCents', 'cartLabel', function () {
    const cartLabel = this.get('cartLabel');
    const price = this.get('clientPriceCents') === 0 ? '' : ` +$${this.get('clientPrice')}`;
    const label = [cartLabel, price];

    return htmlSafe(label.join(' '));
  }),

  /** @type {string} */
  humanize: computed('name', function () {
    return `Option: '${capitalize(this.get('name').toLowerCase())}'`;
  }),

  /** @type {boolean} Returns true if the menu option group or the menu option item is deleted */
  isOptionDeleted() {
    // Menu option items are considered deleted is either the deletedAt attribute
    // exists of the menu option group has been deleted
    return !!this.get('isDeleted') || !this.get('menuOptionGroup');
  }
});
