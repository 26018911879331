import Fixture, {
  element,
  getElementDecoratorFor,
  getElementWithValueDecoratorFor
} from 'renard/fixtures/index';
import BasicModalFixture from 'star-fox/features/components/sf/basic-modal/fixture-chai';
import { notify } from '../../../../application/fixture-chai';
import { modelSelectControl } from 'star-fox/features/components/form-controls/model-select-control/fixture-chai';

class WeeklyReservationEmailModal extends BasicModalFixture {
  constructor() {
    super('data-test-sf-basic-modal');
  }

  @element singleRestaurantButton;
  @element sendButton;
  @element confirmButton;

  async send() {
    await this.sendButton.click();
  }

  shouldBeOpen() {
    this.should.be.rendered;
    this.sendButton.should.be.rendered;
  }

  async selectSingleRestaurant() {
    await this.singleRestaurantButton.click();
  }

  async confirmCurrentWeek() {
    await this.confirmButton.click();
  }
}

const modal = getElementDecoratorFor(WeeklyReservationEmailModal);

export default class WeeklyReservationEmailFixture extends Fixture {
  constructor() {
    super('data-test-sf-modals-weekly-reservation-email');
  }

  @modal modal;
  @notify notify;
  @modelSelectControl restaurantDropdown;
}

export const weeklyReservationEmail = getElementDecoratorFor(WeeklyReservationEmailFixture);

export const weeklyReservationEmailWithValue = getElementWithValueDecoratorFor(
  WeeklyReservationEmailFixture
);
