import classic from 'ember-classic-decorator';
import FeedItemBuilder from './base';
import { capitalize } from '@ember/string';

@classic
export default class ConfirmationBuilder extends FeedItemBuilder {
  /**
   * @param {HistorianVersion} version
   * @returns {FeedItem}
   */
  build(version) {
    const feedItem = super.build(version);
    const affirmation = version.get('objectChanges.affirmation');

    if (affirmation) {
      const hasAffirmed = affirmation.get('lastObject');
      const confirmationType = `${capitalize(version.get('object.data.type'))}Confirmation`;
      const userAction = hasAffirmed ? 'approved' : 'rejected';
      feedItem.icon = hasAffirmed ? 'check circle' : 'times circle';
      feedItem.subject = `${version.get(
        'whodunnit'
      )} ${userAction} the ${confirmationType} for Order #${version.get('object.confirmableId')}`;
    } else {
      const confirmationType = `${capitalize(
        version.get('objectChanges.data.lastObject.type')
      )}Confirmation`;
      const orderId = version.get('objectChanges.confirmableId.lastObject');
      feedItem.icon = 'question circle';
      feedItem.subject = `${version.get('whodunnit')} ${version.get(
        'verb'
      )} the ${confirmationType} for Order #${orderId}`;
    }

    return feedItem;
  }
}
