export function isString(suspect) {
  return typeof suspect === 'string';
}

export function isNumber(suspect) {
  return typeof suspect === 'number';
}

export function isBoolean(suspect) {
  return typeof suspect === 'boolean';
}

export function isCoercableNumber(suspect) {
  return !isNaN(suspect - 0) && suspect !== null && suspect !== '' && suspect !== false;
}

export function isFunction(suspect) {
  return typeof suspect === 'function';
}

export function isPlainObject(suspect) {
  return Object(suspect) === suspect && Object.getPrototypeOf(suspect) === Object.prototype;
}

export function isArray(suspect) {
  return Object.prototype.toString.call(suspect) === '[object Array]';
}

export function isNull(suspect) {
  return suspect === null;
}

export function isUndefined(suspect) {
  return typeof suspect === 'undefined';
}

export default {
  isString: isString,
  isNumber: isNumber,
  isCoercableNumber: isCoercableNumber,
  isFunction: isFunction,
  isPlainObject: isPlainObject,
  isArray: isArray,
  isNull: isNull,
  isUndefined: isUndefined
};
